// vendors
import React, { FC, useState } from 'react';
import { Box, CircularProgress, Grid, StepLabel } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import jwt_decode from 'jwt-decode';

// Components

// Pages
import ScanCBCT from '../../../ImagesAndDocs/ScanCbct';

// Entities
import { PatientInformation } from '../../../../entities/AiReportOrder';

// Store
import { useMainStoreContext } from '../../../../stores/MainStore';

// API
import { PatientStore } from '../../../../api/PatientStore';
import { observer } from 'mobx-react';

import { Gateway } from '../../../../api/Gateway';
import { P2PConsentStep } from './P2PConsentStep';
import { P2PCalendlyStep } from './P2PCalendlyStep';
import { P2PTopicsAndQuestionsStep } from './P2PTopicsAndQuestionsStep';

const steps = ['Consent to Share Patient Information', 'Topics & Questions', 'Schedule Consultation', 'Confirmed'];

interface ConsultationData {
    clinicalAdvisor: any;
    clinicalAdvocate: any;
}
interface IP2PConsultationSteps {
    patient: PatientInformation;
    consultationsData?: any;
    onClosed: () => void;
}

export const P2PConsultationSteps: FC<IP2PConsultationSteps> = observer(({ patient, consultationsData, onClosed }) => {
    const { toggleAiReportOrderModal, loadData } = new PatientStore();

    const [activeStep, setActiveStep] = useState(0);
    const [stepsToUse, setStepsToUse] = useState<any>(steps);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [visitRe, setVisitRe] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const [openAdvisor, setOpenAdvisor] = useState(false);
    const [openAdvocate, setOpenAdvocate] = useState(false);
    const [consultationData, setConsultationData] = useState<ConsultationData>();

    const { saveTopics, resetConsultations, selectDateTime } = useMainStoreContext().calendlyConsultationsStore;

    console.log(
        '🚀 ~ file: P2PConsultationSteps.tsx:219 ~ constP2PConsultationSteps:FC<IP2PConsultationSteps>=observer ~ selectDateTime',
        selectDateTime,
    );

    const CONSENT = 0;
    const TOPICSANDQUESTIONS = 1;
    const CALENDLY = 2;
    const CONFIRM = 3;

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === CONSENT) {
            doNextStep();
        } else if (activeStep === TOPICSANDQUESTIONS) {
            doNextStep();
        } else if (activeStep === CALENDLY) {
            doNextStep();
        } else if (activeStep === CONFIRM) {
            onClosed();
            saveTopics();
            doNextStep();
        }
    };

    const doNextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    React.useEffect(() => {
        if (!consultationData) {
            var token = sessionStorage.getItem('token');
            let userID = 0;
            if (token) {
                var tokenData: { id } = jwt_decode(token);
                userID = tokenData.id;
            }
            Gateway.getOne(`consultations/${userID}`).then(data => {
                setConsultationData(data);
                setHasLoaded(true);
            });
        }
        return () => resetConsultations();
    }, []);

    const reportSteps = () => {
        return (
            <>
                {activeStep === CONSENT ? (
                    <Box>
                        <P2PConsentStep consultationData={consultationData} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}
                {activeStep === TOPICSANDQUESTIONS ? (
                    <Box>
                        <P2PTopicsAndQuestionsStep consultationsData={consultationData} patient={patient} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}
                {activeStep === CALENDLY ? (
                    <Box>
                        <P2PCalendlyStep schedulerLink="https://calendly.com/rockygarg/myoappointment-dev" />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}
                {activeStep === CONFIRM ? (
                    <Box>
                        <></>
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}
            </>
        );
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const closeWizard = () => {
        toggleAiReportOrderModal.bind(false);
    };

    return (
        <Box className="BoxDialog">
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {stepsToUse.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <hr />
            {activeStep === stepsToUse.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className="vivButton large" onClick={() => closeWizard}>
                            RETURN TO PATIENT PORTAL
                        </Button>
                    </Box>
                </React.Fragment>
            ) : (
                <>
                    {hasLoaded ? (
                        reportSteps()
                    ) : (
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center">
                                <Grid>Loading...</Grid>
                            </Grid>

                            <CircularProgress
                                sx={{
                                    width: '90%',
                                    height: '90%',
                                }}
                            />
                        </Grid>
                    )}
                    {hasLoaded ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                pt: 2,
                                marginLeft: '25px',
                                marginRight: '25px',
                            }}
                        >
                            {activeStep !== 0 && (
                                <Button
                                    className="vivButton large"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                            )}
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button
                                className="vivButton large"
                                onClick={event => {
                                    handleNext();
                                }}
                                disabled={activeStep === CALENDLY && !selectDateTime}
                            >
                                {activeStep === stepsToUse.length - 1 ? 'Finish' : 'Confirm & Continue'}
                            </Button>
                        </Box>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </Box>
    );
});
