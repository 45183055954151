import { Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Gateway } from "../../../api/Gateway";
import { Description } from '@mui/icons-material';
import { useCallback } from "react";
import { observer } from "mobx-react";
import { useMainStoreContext } from "../../../stores/MainStore";
import 'react-dropzone-uploader/dist/styles.css'

const MyDropzone = () => {

	const { currentPatient, setApplianceConstructionDiagramFilename, applianceConstructionDiagramFilename} = useMainStoreContext().prescriptionStore;

	const onDrop = useCallback(async (acceptedFiles) => {
		const file = acceptedFiles[0];
		const body = new FormData();
		const base64 = await fileToBase64(file);

		body.append('diagram', base64.substring(base64.indexOf(',') + 1));
		body.append('name', file.name);

		setApplianceConstructionDiagramFilename(file.name);
		Gateway.post(`/patient/appliances/${currentPatient.id}/diagram`, body);
	}, []);

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
	  
		  reader.onloadend = () => {
			if (reader.readyState === FileReader.DONE) {
			  const base64 = reader.result as string;
			  resolve(base64);
			}
		  };
	  
		  reader.onerror = () => {
			reject(new Error('Error reading file'));
		  };
	  
		  reader.readAsDataURL(file);
		});
	  }

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
	return (
	  <div {...getRootProps()} 
		  style={{
			display: 'flex',
			flexDirection: 'column',
			width: '150px',
			marginRight: '50px',
			cursor: 'pointer',
			borderStyle: 'dotted',
			borderColor: isDragActive ? '#2196f3' : '#000000',
			color: isDragActive ? '#2196f3' : '#000000',
			}}>
		<input {...getInputProps()} />
		{
			<Typography display='flex' variant='body2' textAlign='center'>
				  {applianceConstructionDiagramFilename ?
					applianceConstructionDiagramFilename : 'Upload Appliance Construction Diagram'}
			  </Typography>
		}
		<div style={{ width: '100%', display: 'flex', justifyContent:'center' }}>
		  <Description display='flex' fontSize='large' />
		</div>
		</div>
	);
};

export default observer(MyDropzone);