import { makeStyles } from '@material-ui/core/styles';
import { TeethCharting } from "../../../components/TeethCharting/TeethCharting";
import { boxStyles } from "./styles";
import { useMainStoreContext } from "../../../stores/MainStore";
import { Box, Button, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { Gateway } from '../../../api/Gateway';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

const StepThree = () => {
	const classes = styles();
	const boxClasses = boxStyles();
	const { setStep, currentPatient, selectedLab, selectedAppliance, fabricationTime } = useMainStoreContext().prescriptionStore;
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;
	const { saveTeethChart} = useMainStoreContext().teethChartStore;
	const [ isLoading, setIsLoading ] = useState(false);
	const handleBack = (): void => {
		setStep(1);
	};
	const handleNext = () => {
		setIsLoading(true);
		saveTeethChart().then(() => {
			setStep(3);
		}).catch(error => {
			console.error(error);
		}).finally(() => {
			setIsLoading(false);
		});
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={10}>
					<Box className={boxClasses.leftBox}>
						<TeethCharting existingPatient={currentPatient} />
					</Box>
				</Grid>
				<Grid item xs={2}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Lab:</b> {selectedLab.name}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> {fabricationTime}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							<Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>
							<LoadingButton
								variant="contained"
								color="primary"
								onClick={handleNext}
								loading={isLoading}
								disabled={selectedLab.id === -1}
								sx={{ borderRadius: '30px', margin: '20px' }}>
								Save + Continue
							</LoadingButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};
export default observer(StepThree);