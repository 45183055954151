import { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import { makeObservable, observable } from "mobx";
import { GridHeaderBL } from "../../../components/GridHeader/GridHeaderBL";
import {
    Typography,
    Box,
    styled,
    Avatar,
    lighten,
    alpha,
    IconButton,
    Grid,
    useTheme,
    Card,
    Stack,
    Divider,
    CardActionArea,
    Tooltip
} from '@mui/material';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';

const AvatarPageTitle = styled(Avatar)(
    ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);


const DotLegend = styled('span')(
    ({ theme }) => `
      border-radius: 22px;
      width: ${theme.spacing(1.8)};
      height: ${theme.spacing(1.8)};
      display: inline-block;
      margin-right: ${theme.spacing(0.8)};
      border: ${theme.colors.alpha.white[100]} solid 2px;
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
  
          .MuiTouchRipple-root {
            opacity: .2;
          }
    
          &:hover {
            .MuiCardActionArea-focusHighlight {
              opacity: 0;
            }
          }
    `
);

interface PageHeaderProps {
    numWithin7Days: number;
    numActivePatients: number;
    numCompletedPatients: number;
    onNewFilterClick: any;
    onActiveFilterClick: any;
    onCompletedFilterClick: any;
    newFilter: number;
    activeFilter: number;
    completedFilter: number;
    currentFilter: any;
}

@observer
export class PageHeader extends Component<PageHeaderProps> {
    @observable store = new GridHeaderBL();


    constructor(props: any) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <Box

                display="flex"
                alignItems={{ xs: 'stretch', md: 'center' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
            >
                <Box display="flex" flex='2' alignItems="center">
                    <AvatarPageTitle variant="rounded">
                        <PeopleTwoToneIcon fontSize="large" />
                    </AvatarPageTitle>
                    <Box>
                        <Typography variant="h3" component="h3" gutterBottom>
                            MyoCorrect
                        </Typography>
                        <Typography variant="subtitle2">
                            All patients can be found below.
                        </Typography>
                    </Box>
                </Box>
                <Box
                    py={0}
                    display="flex"
                    flex="2"
                    alignItems={{ xs: 'stretch', md: 'center' }}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent="right">
                    <Grid justifyContent="right" alignItems="center" container spacing={1}>
                        <Grid item xs={12} >
                            <Card
                                sx={{
                                    mt: { xs: 3, lg: 0 }
                                }}
                            >
                                <Stack
                                    direction="row"
                                    divider={
                                        <Divider
                                            sx={(theme) => ({
                                                background: `${theme.colors.alpha.black[10]}`,
                                            })}
                                            orientation="vertical"
                                            flexItem
                                        />
                                    }
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={0}
                                >
                                    <Box px={3} py={2} textAlign="center">
                                        <Grid container direction={'column'}>
                                            <Typography variant="h4">Patient</Typography>
                                            <Typography variant="h4">Status</Typography>
                                        </Grid>
                                    </Box>
                                    <Tooltip title={'Patients that have been assigned in the last 7 days.'}>
                                        <CardActionAreaWrapper onClick={this.toggleSelectedNew}>
                                            <Box px={3} py={2} textAlign="center">
                                                <Typography
                                                    variant="h4"
                                                    fontWeight="normal"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    New
                                                </Typography>
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <DotLegend
                                                        sx={(theme) => ({
                                                            background: `${theme.colors.info.main}`,
                                                            animation: `pulse 1s infinite`,
                                                        })}
                                                    />
                                                    <Typography color="text.primary" variant="h4">
                                                        {this.props.numWithin7Days}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </CardActionAreaWrapper>
                                    </Tooltip>
                                    <CardActionAreaWrapper onClick={this.toggleSelectedActive}>
                                        <Box px={3} py={2} textAlign="center">
                                            <Typography
                                                variant="h4"
                                                fontWeight="normal"
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                In-Progress
                                            </Typography>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <DotLegend
                                                    sx={(theme) => ({
                                                        background: `${theme.colors.success.main}`,
                                                        animation: `pulse 1s infinite`,
                                                    })}
                                                />
                                                <Typography color="text.primary" variant="h4">
                                                    {this.props.numActivePatients}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardActionAreaWrapper>
                                    <CardActionAreaWrapper onClick={this.toggleSelectedCompleted}>
                                        <Box px={3} py={2} textAlign="center">
                                            <Typography
                                                variant="h4"
                                                fontWeight="normal"
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                Completed
                                            </Typography>
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <DotLegend
                                                    sx={(theme) => ({
                                                        background: `${theme.colors.primary.main}`
                                                    })}
                                                />
                                                <Typography color="text.primary" variant="h4">
                                                    {this.props.numCompletedPatients}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardActionAreaWrapper>
                                </Stack>
                            </Card>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        );
    }

    @action
    toggleSelectedNew = () => {
        this.props.onNewFilterClick(!(this.props.newFilter === this.props.currentFilter));
    }
    @action
    toggleSelectedActive = () => {
        this.props.onActiveFilterClick(!(this.props.activeFilter === this.props.currentFilter));
    }
    @action
    toggleSelectedCompleted = () => {
        this.props.onCompletedFilterClick(!(this.props.completedFilter === this.props.currentFilter));
    }
}

export default PageHeader;
