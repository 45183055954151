import { Ethnicity } from '../entities/Enums';
import { Gateway } from './Gateway';
import * as _ from 'lodash';
import * as __ from 'lodash-contrib';
import axios from 'axios';
import DictionaryList from '../entities/DictionaryList';
import DictionaryListItem from '../entities/DictionaryListItem';

const MEDICALHX = 'MEDICALHX';
const DENTALHX = 'DENTALHX';
const CPAPMADHX = 'CPAPMADHX';
const ALLERGYHX = 'ALLERGYHX';
const MYOCORRECTSTATUS = 'MYOCORRECTSTATUS';
const THERAPYSTATUSES = 'THERAPYSTATUSES';
const MYOCORRECTTHERAPYSTATUS = 'MYOCORRECTTHERAPYSTATUS';
const AIREPORTSTATUSES = 'AIREPORTSTATUSES';
const ADDONTYPE = 'ADDONTYPE';
const GENDER = 'GENDER';
const ETHNICITY = 'ETHNICITY';
const INTRAORALIMAGES = 'INTRAORALIMAGETYPE';
const EXTRAORALIMAGES = 'EXTRAORALIMAGETYPE';
const GUARDIANRELATIONSHIPTYPE = 'GUARDIANRELATIONSHIPTYPE';
const INTAKEREPORTS = 'INTAKEREPORTS';
const DICTIONARYLISTS = 'DICTIONARYLISTS';
const MISSINGREASONS = 'MISSINGREASONS';
const PRESENTCONDITIONS = 'PRESENTCONDITIONS';
const CBCTMACHINEBRAND = 'CBCTMACHINEBRAND';
const OCCLUSION3DMACHINEBRAND = 'OCCLUSION3DMACHINEBRAND';
const PATIENTOCCLUSION = 'PATIENTOCCLUSION';
const PATIENTIMAGETYPES = 'PATIENTIMAGETYPES';
const AIREPORTPROCESSEDIMAGETYPES = 'AIREPORTPROCESSEDIMAGETYPES';
const SLEEPCONDITIONS = 'SLEEPCONDITIONS';
const EXTRAORALCONDITIONS = 'EXTRAORALCONDITIONS';
const INTRAORALCONDITIONS = 'INTRAORALCONDITIONS';
const CBCTDIAGNOSIS = 'CBCTDIAGNOSIS';
const TREATMENTPLANOPTIONSPRIMARY = 'TREATMENTPLANOPTIONSPRIMARY';
const TREATMENTPLANOPTIONSSUPPLEMENTAL = 'TREATMENTPLANOPTIONSSUPPLEMENTAL';
const TREATMENTPLANOPTIONSDENTAL = 'TREATMENTPLANOPTIONSDENTAL';
const TREATMENTPLANOPTIONSCOLLABORATION = 'TREATMENTPLANOPTIONSCOLLABORATION';
const TREATMENTPLANOPTIONSSURGERY = 'TREATMENTPLANOPTIONSSURGERY';
const TREATMENTPLANOPTIONSOTHER = 'TREATMENTPLANOPTIONSOTHER';
const VIVOSAPPLIANCES = 'VIVOSAPPLIANCES';
const MOLARRELATIONSHIPS = 'MOLARRELATIONSHIPS';
const AIREPORTTYPES = 'AIREPORTTYPES';
const CALENDLYTOPICS = 'CALENDLYTOPICS';
const TREATMENTOBJECTIVES = 'TREATMENTOBJECTIVES';
const CONSULTATIONSTATUS = 'CONSULTATIONSTATUS';
const HEADNECKPROPERTIES = 'HEADNECKPROPERTIES';
const MAPPINGTABLES = 'MAPPINGTABLES';
const PROPERTYTABLES = 'PROPERTYTABLES';
const PRIMARYCATEGORY = 'PRIMARYCATEGORY';
const SECONDARYCATEGORY = 'SECONDARYCATEGORY';
const PRIMARYCATEGORIES = 'PRIMARYCATEGORIES';
const SECONDARYCATEGORIES = 'SECONDARYCATEGORIES';
const VIVOSMEASUREMENTPROPERTIES = 'VIVOSMEASUREMENTPROPERTIES';
const CLAIMSTATUS = 'CLAIMSTATUS';
const PRODUCTIONSTATUS = 'PRODUCTIONSTATUS';
const REWORKREQUESTS = 'REWORKREQUESTS';
const CLAIMREVIEWDOCUMENTTYPE = 'CLAIMREVIEWDOCUMENTTYPE';
const ROLES = 'ROLES';
const PATIENTVISITSTATUS = 'VISITSTATUS';
const ORDERSTATUS = 'ORDERSTATUS';
const ADDRESSTYPE = 'ADDRESSTYPE';
const TEETHSET = 'TEETHSET';
const TEETHNUMBERINGSYSTEM = 'TEETHNUMBERINGSYSTEM';
const LINCARESTATUS = 'LINCARESTATUS';
const GUIDESERIES = 'GUIDESERIES';
const APPLIANCESIZES = 'APPLIANCESIZES';
const APPLIANCECOLORS = 'APPLIANCECOLORS';
const VERTICALTHICKNESS = 'VERTICALTHICKNESS';
const UNILATERALBITEBLOCK = 'UNILATERALBITEBLOCK';

export class GlobalUtils {
    static getAndCreateOptions = async () => {
        await Gateway.get('global/lists').then(respData => {
            var medicalhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === MEDICALHX;
            });
            sessionStorage.setItem(MEDICALHX, JSON.stringify(medicalhxlist));

            var dentalhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === DENTALHX;
            });
            sessionStorage.setItem(DENTALHX, JSON.stringify(dentalhxlist));

            var cpapMadhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === CPAPMADHX;
            });
            sessionStorage.setItem(CPAPMADHX, JSON.stringify(cpapMadhxlist));

            var allergyhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === ALLERGYHX;
            });
            sessionStorage.setItem(ALLERGYHX, JSON.stringify(allergyhxlist));

            var mycorrectStatusesList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === MYOCORRECTSTATUS;
            });
            sessionStorage.setItem(MYOCORRECTSTATUS, JSON.stringify(mycorrectStatusesList));

            var mycorrectTherapyStatusesList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === MYOCORRECTTHERAPYSTATUS;
            });
            sessionStorage.setItem(MYOCORRECTTHERAPYSTATUS, JSON.stringify(mycorrectTherapyStatusesList));

            var treatmentStatusesList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === THERAPYSTATUSES;
            });
            sessionStorage.setItem(THERAPYSTATUSES, JSON.stringify(treatmentStatusesList));

            var aiReportStatusList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === AIREPORTSTATUSES;
            });
            sessionStorage.setItem(AIREPORTSTATUSES, JSON.stringify(aiReportStatusList));

            var addOnTypeList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === ADDONTYPE;
            });
            sessionStorage.setItem(ADDONTYPE, JSON.stringify(addOnTypeList));

            var genderList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === GENDER) return item.dictionaryListItems;
            });
            sessionStorage.setItem(GENDER, JSON.stringify(genderList));

            var ethnicityList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === ETHNICITY) return item.dictionaryListItems;
            });
            sessionStorage.setItem(ETHNICITY, JSON.stringify(ethnicityList));

            var intraoralimageList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === INTRAORALIMAGES) return item.dictionaryListItems;
            });
            sessionStorage.setItem(INTRAORALIMAGES, JSON.stringify(intraoralimageList));

            var extraoralimageList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === EXTRAORALIMAGES) return item.dictionaryListItems;
            });
            sessionStorage.setItem(EXTRAORALIMAGES, JSON.stringify(extraoralimageList));

            var cbctMachineBrandList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CBCTMACHINEBRAND) return item.dictionaryListItems;
            });
            sessionStorage.setItem(CBCTMACHINEBRAND, JSON.stringify(cbctMachineBrandList));

            var machineBrand3DList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === OCCLUSION3DMACHINEBRAND) return item.dictionaryListItems;
            });
            sessionStorage.setItem(OCCLUSION3DMACHINEBRAND, JSON.stringify(machineBrand3DList));

            var patientOcclusionList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === PATIENTOCCLUSION) return item.dictionaryListItems;
            });
            sessionStorage.setItem(PATIENTOCCLUSION, JSON.stringify(patientOcclusionList));

            var patientImageTypesList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === PATIENTIMAGETYPES) return item.dictionaryListItems;
            });
            sessionStorage.setItem(PATIENTIMAGETYPES, JSON.stringify(patientImageTypesList));

            var relationshiptypeList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === GUARDIANRELATIONSHIPTYPE) return item.dictionaryListItems;
            });
            sessionStorage.setItem(GUARDIANRELATIONSHIPTYPE, JSON.stringify(relationshiptypeList));

            var missingReasonsList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === MISSINGREASONS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(MISSINGREASONS, JSON.stringify(missingReasonsList));

            var presendConditionsList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === PRESENTCONDITIONS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(PRESENTCONDITIONS, JSON.stringify(presendConditionsList));

            var intakeReportsList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === INTAKEREPORTS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(INTAKEREPORTS, JSON.stringify(intakeReportsList));

            var processedImageTypes = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === AIREPORTPROCESSEDIMAGETYPES) return item.dictionaryListItems;
            });
            sessionStorage.setItem(AIREPORTPROCESSEDIMAGETYPES, JSON.stringify(processedImageTypes));

            let sleepConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === SLEEPCONDITIONS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(SLEEPCONDITIONS, JSON.stringify(sleepConditions));

            let extraoralConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === EXTRAORALCONDITIONS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(EXTRAORALCONDITIONS, JSON.stringify(extraoralConditions));

            let intraoralConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === INTRAORALCONDITIONS) return item.dictionaryListItems;
            });

            sessionStorage.setItem(INTRAORALCONDITIONS, JSON.stringify(intraoralConditions));

            let cbctConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CBCTDIAGNOSIS) return item.dictionaryListItems;
            });

            sessionStorage.setItem(CBCTDIAGNOSIS, JSON.stringify(cbctConditions));

            let treatmentPlanPrimaryTherapy = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSPRIMARY) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTPLANOPTIONSPRIMARY, JSON.stringify(treatmentPlanPrimaryTherapy));

            let suplementalTherapy = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSSUPPLEMENTAL) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTPLANOPTIONSSUPPLEMENTAL, JSON.stringify(suplementalTherapy));

            let dentalTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSDENTAL) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTPLANOPTIONSDENTAL, JSON.stringify(dentalTherapies));

            let collaborationTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSCOLLABORATION) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTPLANOPTIONSCOLLABORATION, JSON.stringify(collaborationTherapies));

            let surgeryTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSSURGERY) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTPLANOPTIONSSURGERY, JSON.stringify(surgeryTherapies));

            let otherTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSOTHER) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTPLANOPTIONSOTHER, JSON.stringify(otherTherapies));

            let vivosAppliances = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === VIVOSAPPLIANCES) return item.dictionaryListItems;
            });

            sessionStorage.setItem(VIVOSAPPLIANCES, JSON.stringify(vivosAppliances));

            let molarRelationships = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === MOLARRELATIONSHIPS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(MOLARRELATIONSHIPS, JSON.stringify(molarRelationships));

            let calendlyTopics = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CALENDLYTOPICS) return item.dictionaryListItems;
            });
            sessionStorage.setItem(CALENDLYTOPICS, JSON.stringify(calendlyTopics));
            let treatmentObjectives = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTOBJECTIVES) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TREATMENTOBJECTIVES, JSON.stringify(treatmentObjectives));

            let consultationStatus = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CONSULTATIONSTATUS) return item.dictionaryListItems;
            });

            sessionStorage.setItem(CONSULTATIONSTATUS, JSON.stringify(consultationStatus));

            let primaryCategories = _.filter(respData, item => {
                if (__.strContains(item.listKey, PRIMARYCATEGORY)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(PRIMARYCATEGORIES, JSON.stringify(primaryCategories));

            let secondaryCategories = _.filter(respData, item => {
                if (__.strContains(item.listKey, SECONDARYCATEGORY)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(SECONDARYCATEGORIES, JSON.stringify(secondaryCategories));

            let vivosmeasurementprops = _.filter(respData, item => {
                if (__.strContains(item.listKey, VIVOSMEASUREMENTPROPERTIES)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(VIVOSMEASUREMENTPROPERTIES, JSON.stringify(vivosmeasurementprops));

            let claimStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMSTATUS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(CLAIMSTATUS, JSON.stringify(claimStatus));

            let orderStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, ORDERSTATUS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(ORDERSTATUS, JSON.stringify(orderStatus));

            let productionStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, PRODUCTIONSTATUS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(PRODUCTIONSTATUS, JSON.stringify(productionStatus));

            let reworkRequests = _.filter(respData, item => {
                if (__.strContains(item.listKey, REWORKREQUESTS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(REWORKREQUESTS, JSON.stringify(reworkRequests));

            let claimReviewDocumentTypes = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMREVIEWDOCUMENTTYPE)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(CLAIMREVIEWDOCUMENTTYPE, JSON.stringify(claimReviewDocumentTypes));

            let patientVisitStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, PATIENTVISITSTATUS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(PATIENTVISITSTATUS, JSON.stringify(patientVisitStatus));

            let addressType = _.filter(respData, item => {
                if (__.strContains(item.listKey, ADDRESSTYPE)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(ADDRESSTYPE, JSON.stringify(addressType));

            let teethSetType = _.filter(respData, item => {
                if (__.strContains(item.listKey, TEETHSET)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TEETHSET, JSON.stringify(teethSetType));

            let teethNumSystemType = _.filter(respData, item => {
                if (__.strContains(item.listKey, TEETHNUMBERINGSYSTEM)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(TEETHNUMBERINGSYSTEM, JSON.stringify(teethNumSystemType));

            let licareStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, LINCARESTATUS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(LINCARESTATUS, JSON.stringify(licareStatus));

            let guideSeries = _.filter(respData, item => {
                if (__.strContains(item.listKey, GUIDESERIES)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(GUIDESERIES, JSON.stringify(guideSeries));

            let applianceSizes = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESIZES)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(APPLIANCESIZES, JSON.stringify(applianceSizes));

            let applianceColors = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCECOLORS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(APPLIANCECOLORS, JSON.stringify(applianceColors));

            let applianceVerticalThickness = _.filter(respData, item => {
                if (__.strContains(item.listKey, VERTICALTHICKNESS)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(VERTICALTHICKNESS, JSON.stringify(applianceVerticalThickness));

            let applianceUnilateralBiteBlock = _.filter(respData, item => {
                if (__.strContains(item.listKey, UNILATERALBITEBLOCK)) return item.dictionaryListItems;
            });

            sessionStorage.setItem(UNILATERALBITEBLOCK, JSON.stringify(applianceUnilateralBiteBlock));
            // let mappingTables = _.filter(respData, item => {
            //     if (_.toUpper(item.listKey) === MAPPINGTABLES) return item.dictionaryListItems;
            // });

            // sessionStorage.setItem(MAPPINGTABLES, JSON.stringify(mappingTables));

            var lists: DictionaryList[] = [];
            _.forEach(respData, r => {
                if (r != null) {
                    lists.push(r);
                }
            });
            sessionStorage.setItem(DICTIONARYLISTS, JSON.stringify(lists));
        });
    };

    static getFormProperties = async () => {
        Gateway.get('questionnaire/form/properties/headneck').then(respData => {
            sessionStorage.setItem(HEADNECKPROPERTIES, JSON.stringify(respData));
        })
    }

    static getHeadNeckProperties = async () => {
        var hx: string | null = sessionStorage.getItem(HEADNECKPROPERTIES);
        if (hx === null || hx === '[]') {
            await this.getFormProperties();
            return JSON.parse(sessionStorage.getItem(HEADNECKPROPERTIES) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getMedicalHxOptions = async () => {
        var hx: string | null = sessionStorage.getItem(MEDICALHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(MEDICALHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getConsultationStatus() {
        let resp = sessionStorage.getItem(CONSULTATIONSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(CONSULTATIONSTATUS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getDentalHxOptions = async () => {
        var hx: string | null = sessionStorage.getItem(DENTALHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(DENTALHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getCpapMadHxOptions = async () => {
        var hx: string | null = sessionStorage.getItem(CPAPMADHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(CPAPMADHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getAllergyHxOptions = async () => {
        var hx: string | null = sessionStorage.getItem(ALLERGYHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(ALLERGYHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getMyoCorrectStatuses() {
        var resp: string | null = sessionStorage.getItem(MYOCORRECTSTATUS);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getMyoCorrectTherapyStatuses() {
        var resp: string | null = sessionStorage.getItem(MYOCORRECTTHERAPYSTATUS);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getTreatmentStatuses() {
        var resp: string | null = sessionStorage.getItem(THERAPYSTATUSES);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getAIReportStatuses() {
        let resp: string | null = sessionStorage.getItem(AIREPORTSTATUSES);
        if (resp === null) {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(AIREPORTSTATUSES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getAIReportTypes() {
        let resp: string | null = sessionStorage.getItem(AIREPORTTYPES);
        if (resp === null) {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(AIREPORTTYPES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getAddOnTypes() {
        var resp: string | null = sessionStorage.getItem(ADDONTYPE);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getAddOnTypeList() {
        let resp: string | null = sessionStorage.getItem(ADDONTYPE);
        if (resp === null) {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(ADDONTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getGenders() {
        var resp = sessionStorage.getItem(GENDER);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(GENDER) as string)[0].dictionaryListItems;
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getEthnicities() {
        var resp: string | null = sessionStorage.getItem(ETHNICITY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(ETHNICITY) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getIntraOralImages() {
        var resp: string | null = sessionStorage.getItem(INTRAORALIMAGES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(INTRAORALIMAGES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getExtraOralImages() {
        var resp: string | null = sessionStorage.getItem(EXTRAORALIMAGES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(EXTRAORALIMAGES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getCBCTMachineBrands() {
        var resp: string | null = sessionStorage.getItem(CBCTMACHINEBRAND);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(CBCTMACHINEBRAND) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static get3DMachineBrands() {
        var resp: string | null = sessionStorage.getItem(OCCLUSION3DMACHINEBRAND);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(OCCLUSION3DMACHINEBRAND) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPatientOcclusionImageTypes() {
        var resp: string | null = sessionStorage.getItem(PATIENTOCCLUSION);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(PATIENTOCCLUSION) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPatientImageTypes() {
        var resp: string | null = sessionStorage.getItem(PATIENTIMAGETYPES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(sessionStorage.getItem(PATIENTIMAGETYPES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getRelationshipTypes() {
        var resp = sessionStorage.getItem(GUARDIANRELATIONSHIPTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(GUARDIANRELATIONSHIPTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getMissingReasons() {
        var resp = sessionStorage.getItem(MISSINGREASONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(MISSINGREASONS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPresentConditions(): DictionaryListItem[] {
        var resp = sessionStorage.getItem(PRESENTCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(PRESENTCONDITIONS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getIntakeReports() {
        var resp = sessionStorage.getItem(INTAKEREPORTS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(INTAKEREPORTS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getProcessedImageTypes() {
        var resp = sessionStorage.getItem(AIREPORTPROCESSEDIMAGETYPES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(AIREPORTPROCESSEDIMAGETYPES) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getSleepConditions() {
        let resp = sessionStorage.getItem(SLEEPCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(SLEEPCONDITIONS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getExtraoralConditions() {
        let resp = sessionStorage.getItem(EXTRAORALCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(EXTRAORALCONDITIONS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getIntraoralConditions() {
        let resp = sessionStorage.getItem(INTRAORALCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(INTRAORALCONDITIONS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getCbctConditions() {
        let resp = sessionStorage.getItem(CBCTDIAGNOSIS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(CBCTDIAGNOSIS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPrimaryTherapy() {
        let resp = sessionStorage.getItem(TREATMENTPLANOPTIONSPRIMARY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTPLANOPTIONSPRIMARY) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getSuplementalTherapy() {
        let resp = sessionStorage.getItem(TREATMENTPLANOPTIONSSUPPLEMENTAL);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTPLANOPTIONSSUPPLEMENTAL) as string)[0]
                    .dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getDentalThearpy() {
        let resp = sessionStorage.getItem(TREATMENTPLANOPTIONSDENTAL);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTPLANOPTIONSDENTAL) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getCollaborationTherapy() {
        let resp = sessionStorage.getItem(TREATMENTPLANOPTIONSCOLLABORATION);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTPLANOPTIONSCOLLABORATION) as string)[0]
                    .dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static surgeryTherapy() {
        let resp = sessionStorage.getItem(TREATMENTPLANOPTIONSSURGERY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTPLANOPTIONSSURGERY) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getOtherTherapy() {
        let resp = sessionStorage.getItem(TREATMENTPLANOPTIONSOTHER);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTPLANOPTIONSOTHER) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getVivosAppliances() {
        let resp = sessionStorage.getItem(VIVOSAPPLIANCES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(VIVOSAPPLIANCES) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getMolarRelationships() {
        let resp = sessionStorage.getItem(MOLARRELATIONSHIPS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(MOLARRELATIONSHIPS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getTreatmentObjectives() {
        let resp = sessionStorage.getItem(TREATMENTOBJECTIVES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(TREATMENTOBJECTIVES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getMappingTables = async () => {
        let resp = sessionStorage.getItem(MAPPINGTABLES);
        if (resp === null || resp === '[]') {
            Gateway.get(`questionnaire/form/mappingtables`).then(resp => {
                sessionStorage.setItem(MAPPINGTABLES, JSON.stringify(resp));
                return JSON.stringify(resp);
            });
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getPrimaryCategories = async () => {
        let resp = sessionStorage.getItem(PRIMARYCATEGORIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(PRIMARYCATEGORIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getSecondaryCategories = async () => {
        let resp = sessionStorage.getItem(SECONDARYCATEGORIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(SECONDARYCATEGORIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getVivosMeasurementProperties = async () => {
        let resp = sessionStorage.getItem(VIVOSMEASUREMENTPROPERTIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(VIVOSMEASUREMENTPROPERTIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimStatus = async () => {
        let resp = sessionStorage.getItem(CLAIMSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(CLAIMSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getOrderStatus = async () => {
        let resp = sessionStorage.getItem(ORDERSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(ORDERSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getProductionStatus = async () => {
        let resp = sessionStorage.getItem(PRODUCTIONSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(PRODUCTIONSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getReworkRequests = async () => {
        let resp = sessionStorage.getItem(REWORKREQUESTS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(REWORKREQUESTS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimReviewDocumentTypes = async () => {
        let resp = sessionStorage.getItem(CLAIMREVIEWDOCUMENTTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(CLAIMREVIEWDOCUMENTTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getPropertyTables = async () => {
        let resp = sessionStorage.getItem(PROPERTYTABLES);
        if (resp === null || resp === '[]') {
            Gateway.get(`global/propertytables`).then(resp => {
                sessionStorage.setItem(PROPERTYTABLES, JSON.stringify(resp));
                return JSON.stringify(resp);
            });
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getRoles = async () => {
        let resp = sessionStorage.getItem(ROLES);
        if (resp === null || resp === '[]') {
            Gateway.get(`global/roles`).then(resp => {
                sessionStorage.setItem(ROLES, JSON.stringify(resp));
                return JSON.stringify(resp);
            });
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getPatientVisitStatus = async () => {
        let resp = sessionStorage.getItem(PATIENTVISITSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(PATIENTVISITSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getDictionaryLists = async () => {
        var resp = sessionStorage.getItem(DICTIONARYLISTS);
        if (resp === null || resp === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(DICTIONARYLISTS) as string);
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getCalendlyTopics() {
        let resp = sessionStorage.getItem(CALENDLYTOPICS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(CALENDLYTOPICS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static reloadDictionaryLists = async () => {
        sessionStorage.removeItem(DICTIONARYLISTS);
        return await this.getDictionaryLists();
    };

    static validateEmail = (email: string) => {
        return /^([a-zA-Z]*)([0-9]*)[\w.]+[\w-]+[\w_]+(@)([a-zA-Z]*)([0-9]*).([a-zA-Z]*)([0-9]*).([a-zA-Z]*)([0-9]*)$/.test(email);
    };

    static validateAlpha = (value: string) => {
        return /^[A-Za-z ]+$/.test(value);
    };

    static validateNumeric = (value: string) => {
        return /^[1-9]\d*$/.test(value);
    };

    static validateAlphanumeric = (value: string) => {
        return /[\w\s]*/.test(value);
    };

    static validatePhoneNumber = (value: string) => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value);
    };

    static validatePhoneCharacter = (value: string) => {
        return /^[0-9-\s\.()]{1}$/.test(value);
    };

    static getMyoCorrectUrl = (filename: string, id: string): string => {
        if (filename.startsWith('https')) {
            return filename;
        } else {
            return `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${id}/${process.env.REACT_APP_MYOCORRECT_CONTAINER}/${filename}`;
        }
    };

    static getVisitRecordUrl = (filename: string, vivosId: string, recordId): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (filename && vivosId && recordId) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${process.env.REACT_APP_VISITRECORD_CONTAINER}/${recordId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getAIReportUrl = (filename: string, vivosId: string, reportId): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (vivosId && reportId && filename) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${reportId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getClaimDocumentUrl = (filename: string, vivosId: string, prescriptionId, claimId): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (vivosId && prescriptionId && claimId && filename) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${process.env.REACT_APP_PRESCRIPTIONS_CONTAINER}/${prescriptionId}/${process.env.REACT_APP_REWORKCLAIMS_CONTAINER}/${claimId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getPatientProfileUrl = (filename: string, vivosId: string): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (filename && vivosId) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getProviderUrl = (filename: string, id: number): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (filename && id) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PROVIDER_CONTAINER}/${id}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getAddresstype = async () => {
        let resp = sessionStorage.getItem(ADDRESSTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(ADDRESSTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getTeethSetType() {
        let resp = sessionStorage.getItem(TEETHSET);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(TEETHSET) as string)[0].dictionaryListItems;
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getTeethNumSystemType() {
        let resp = sessionStorage.getItem(TEETHNUMBERINGSYSTEM);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions();
            return JSON.parse(sessionStorage.getItem(TEETHNUMBERINGSYSTEM) as string)[0].dictionaryListItems;
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLincareStatus = async () => {
        let resp = sessionStorage.getItem(LINCARESTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(LINCARESTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getGuideSeries = async () => {
        let resp = sessionStorage.getItem(GUIDESERIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(GUIDESERIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceSizes = async () => {
        let resp = sessionStorage.getItem(APPLIANCESIZES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(APPLIANCESIZES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceColors = async () => {
        let resp = sessionStorage.getItem(APPLIANCECOLORS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(APPLIANCECOLORS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getVerticalThickness = async () => {
        let resp = sessionStorage.getItem(VERTICALTHICKNESS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(VERTICALTHICKNESS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getUnilateralBiteBlock = async () => {
        let resp = sessionStorage.getItem(UNILATERALBITEBLOCK);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(sessionStorage.getItem(UNILATERALBITEBLOCK) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };
}
