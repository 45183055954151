// Vendors
import React from 'react';
import {
    Grid,
    Typography,
    Card,
    IconButton,
    styled,
    Box,
    Avatar,
    alpha,
    useTheme,
    Tooltip,
    Divider,
    Button
} from "@mui/material";
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
          background: ${theme.colors.alpha.white[100]};
          width: auto;
          height: ${theme.spacing(9)};
          padding: ${theme.spacing(1.8)};
          box-shadow: 0 0.180rem .3rem ${alpha(
        theme.colors.alpha.black[100],
        0.3
    )}, 0 .326rem 3rem ${alpha(theme.colors.alpha.black[100], 0.2)};
    `
);

const VivosDetails: React.FC = () => {
    const theme = useTheme();
    return (

        <Box
            pt={4}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <AvatarWrapper
                variant="rounded"
                alt="AIC"
                src="/logos/labs/AIC.png"
            />
            <Typography
                gutterBottom
                noWrap
                variant="h4"
                sx={{
                    pt: 3,
                    fontSize: `${theme.typography.pxToRem(17)}`
                }}
            >
                Address
            </Typography>
            <Typography noWrap variant="subtitle2">
                Appliance Return
            </Typography>
            <Typography noWrap variant="subtitle2">
                199 W Center St
            </Typography>
            <Typography noWrap variant="subtitle2">
                Orem, UT 84057
            </Typography>
            <Divider
                sx={{
                    mt: 3
                }}
                flexItem
            />
            <Box
                p={2}
                sx={{
                    width: '100%'
                }}
            >
                <Typography variant='h6' textAlign={'center'}>
                    Please note repairs are to be completed at the discretion of the lab who originally manufactured the
                    appliance. Only the lab who originally manufactured the appliance can make repairs.
                </Typography>
            </Box>
        </Box>
    );
};

export default VivosDetails;
