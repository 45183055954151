import moment from 'moment';
import { treatmentStatusArr } from '../imgs/status';
const LEGAL_ADULT_AGE: number = 18;

export function getAge(dateString: any) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && (today.getDate() < birthDate.getDate()))) {
        age--;
    }
    return age;
}

export function isMinor(dateString: any) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if(age < LEGAL_ADULT_AGE) {
        return true;
    }

    return false;
}

export function getAgeDisplayString(dateString: any) {
    var a = moment();
    var b = moment(dateString, 'MM-YYYY');
    var age = moment.duration(a.diff(b));
    var years = age.years();
    var months = age.months();

    var ageStr = years + ' yr(s), ' + months + ' mo';
    if (isMinor(dateString)) {
        return ageStr + ' (patient requires guardian)';
    }
    return ageStr;
}

export const getAgeDisplay = (dateString: any) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    const ageStr = age + ' years, ' + m + ' month';
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return ageStr;
};
