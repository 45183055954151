// Vendors
import React from 'react';
import {
    Grid,
} from "@mui/material";

// Components
import Header from './components/header';
import Sidebar from './components/sidebar';
import TopFaqContent from './content/topFaqContent';
import VivosAppliancesContent from './content/vivosAppliancesContent';
import MyoCorrectContent from './content/myoCorrectContent';
import ClinicalSupportContent from './content/clinicalSupportContent';
import NavigatingvCloudContent from './content/navigatingvCloudContent';
import EducationContent from './content/educationContent';
import PricingBillingContent from './content/pricingBillingContent';
import RxProcessingContent from './content/rxProcessingContent';
import AIReportContent from './content/aiReportContent';
import PatientRecordsContent from './content/patientRecordsContent';
import ProviderPracticeForms from './content/providerPracticeForms';
import OnDemandOrthoContent from './content/onDemandOrthoContent';


const faqTabs = [
    'Top FAQs',
    'Vivos Appliances',
    'MyoCorrect',
    'On Demand Orthodontist',
    'Provider & Practice Forms',
    'Patient Records',
    'Airway Intelligence Reports',
    'RX Processes',
    'Pricing and Billing',
    'Education',
    'Navigating VivosAire',
    'Clinical Support',
];



const KnowledgeBase: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(0);

    const handleChange = (newValue: number) => {
        setActiveTab(newValue);
    };

    const renderTabContent = () => {
        console.log("Rendering content for tab:", faqTabs[activeTab]);
        switch (faqTabs[activeTab]) {
            case 'Top FAQs':
                return <TopFaqContent />;
            case 'Vivos Appliances':
                return <VivosAppliancesContent />;
            case 'MyoCorrect':
                return <MyoCorrectContent />;
            case 'On Demand Orthodontist':
                return <OnDemandOrthoContent />;
            case 'Provider & Practice Forms':
                return <ProviderPracticeForms />;
            case 'Patient Records':
                return <PatientRecordsContent />;
            case 'Airway Intelligence Reports':
                return <AIReportContent />;
            case 'RX Processes':
                return <RxProcessingContent />;
            case 'Pricing and Billing':
                return <PricingBillingContent />;
            case 'Education':
                return <EducationContent />;
            case 'Navigating VivosAire':
                return <NavigatingvCloudContent />;
            case 'Clinical Support':
                return <ClinicalSupportContent />;
            // Add more cases as needed
            default:
                return null;
        }
    };

    return (
        <Grid container paddingX={5}>
            <Grid item xs={12}>
                <Header />
            </Grid>
            <Grid item xs={2}>
                <Sidebar
                    tabs={faqTabs}
                    activeTab={activeTab}
                    handleTabChange={handleChange}
                />
            </Grid>
            <Grid item xs={10} paddingTop={2} paddingLeft={2}>
                {renderTabContent()}
            </Grid>
        </Grid>

    );
};

export default KnowledgeBase;
