import { Grid, Button, Select, MenuItem, SelectChangeEvent, Tooltip, IconButton } from "@mui/material";
import { PersonRemoveAlt1 } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { Gateway } from '../../api/Gateway';
import { useMainStoreContext } from '../../stores/MainStore';
import DataGrid from '../../components/DataGrid/DataGrid';
import Item from "../../components/Item";
import User from "../../entities/UserMV";
import CssSelectField from '../../components/CssSelectField';

const defaultUser = {
    userID: 0,
    firstName: '',
    lastName: ''
}

function DetailPanelContentTeamList(props) {
    const [data, setData] = React.useState({});
    const [isLoading, setLoading] = React.useState(true);
    const [idUserSelected, setidUserSelected] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(new User(defaultUser));

    const { availableUserData, setAvailableUser, teamsData } = useMainStoreContext().teamAdminStore;

    const loadData = () => {
        setLoading(true);
        var id = props.row.id;
        Gateway.get('teams/users/' + id).then((respData) => {
            if (respData.length > 0) {
                setData(respData);
            }
            else {
                setLoading(false);
            }
        }).finally(() => { });
        Gateway.get('teams/users/withoutteam').then((respData) => {
            if (respData.length > 0) {
                setAvailableUser(respData);
                setidUserSelected(false);
                setSelectedUser({ userID: -1, firstName: "select user....", lastName: "" });
            }
        }).finally(() => { setLoading(false); });
    }

    const submitSupervisorToTeam = () => {
        if (selectedUser.userID > 0) {
            var requestData = {
                "teamID": props.row.id,
                "userID": selectedUser.userID,
                "isSupervisor": true,
                "firstName": selectedUser.firstName,
                "lastName": selectedUser.lastName
            }
            Gateway.postNonAudit("/teams/addsupervisor", requestData)
                .then((respData) => {
                    if (respData) {
                        loadData();
                    }

                });

        }

    }

    const submitAdvocateToTeam = () => {
        if (selectedUser.userID > 0) {
            var requestData = {
                "teamID": props.row.id,
                "userID": selectedUser.userID,
                "isSupervisor": false,
                "firstName": selectedUser.firstName,
                "lastName": selectedUser.lastName
            }
            Gateway.postNonAudit("/teams/addtherapist", requestData)
                .then((respData) => {
                    if (respData) {
                        loadData();
                    }

                });

        }

    }

    const handleChange = (patientData, newteamId) => {
        if (newteamId > 0) {
            var url = "/teams/updateteam/" + patientData.userID + "/" + newteamId;
            Gateway.put(url, undefined)
                .then((respData) => {
                    if (respData) {
                        loadData();
                    }

                }).then(() => {
                });
        }
    };

    const handleRemoveTeam = (currentRow) => {
        var url = "/teams/deleteuserteam";
        var requestData = {
            "teamID": currentRow.teamID,
            "userID": currentRow.userID,
            "isSupervisor": currentRow.isSupervisor,
            "firstName": currentRow.firstName,
            "lastName": currentRow.lastName
        }

        Gateway.delete(url, requestData)
            .then((respData) => {
                loadData();

            }).then(() => {
            });
    }

    useEffect(() => {
        loadData();
    }, [props.row]);

    const columns = [
        { field: 'firstName', with: '20px', headerName: 'First Name', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'lastName', with: '20px', headerName: 'Middle Name', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'isSupervisor', with: '5px', flex: 1, headerName: 'Is Supervisor', type: 'boolean', headerAlign: "center", headerClassName: "colHeader", renderCell: ({ value }) => <Tooltip title='Is Supervisor'><span className={'dot mc' + (value ? '3' : '0')} /></Tooltip> },
        {
            field: 'id', headerName: 'Team Assigment', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center",
            renderCell: (params) => {

                return (
                    <Grid>
                        <Grid container direction='row'>
                            <Grid>
                                <CssSelectField
                                    sx={{ border: "0px" }}
                                    defaultValue='0'
                                    onChange={(event: any) => {
                                        handleChange(params.row, event.target.value)
                                    }}
                                >
                                    <MenuItem key={0} value={0}>{"Change team..."}</MenuItem>
                                    {teamsData.map((th) =>
                                        (<MenuItem key={th.id} value={th.id}>{th.name}</MenuItem>))}
                                </CssSelectField>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        },
        {
            field: 'userId', headerName: 'Actions', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center",
            renderCell: (params) => {

                return (
                    <Grid>
                        <Grid>
                            <Tooltip title="Remove from team" ><IconButton onClick={() => { handleRemoveTeam(params.row) }}><PersonRemoveAlt1 /></IconButton></Tooltip>
                        </Grid>

                    </Grid>
                )
            }
        }

    ]


    return (
        <Grid xl={12} container direction="column" className='page'>
            <Grid>
                <Grid container direction="row">
                    <Grid>
                        <Grid container direction="row" className="formLabel" ><Grid>Select user</Grid><Grid sx={{ color: "red" }}>*</Grid></Grid>
                        <Select
                            className="DateBox"
                            id="user"
                            label="User Selection"
                            font-size='10px/13px'
                            size="small"
                            onChange={(event: SelectChangeEvent) => {
                                let index = availableUserData.findIndex(val => val.userID.toString() === event.target.value);
                                if (index > -1) {
                                    setSelectedUser(availableUserData[index]);
                                    setidUserSelected(true);
                                }
                                else {
                                    setidUserSelected(false);
                                    setSelectedUser({ userID: -1, firstName: "select user....", lastName: "" });
                                }
                            }}
                            value={selectedUser.userID.toString()}
                        >
                            <MenuItem key={-1} value={-1} >select user... </MenuItem>
                            {availableUserData.map((user) => (
                                <MenuItem key={user.userID} value={user.userID.toString()} >{user.firstName},{user.lastName} </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Item><Button className='vivButton normal' disabled={!idUserSelected} onClick={submitSupervisorToTeam}>Add Supervisor</Button></Item>
                    <Item><Button className='vivButton normal' disabled={!idUserSelected} onClick={submitAdvocateToTeam} >Add Advisor</Button></Item>
                </Grid>
            </Grid>
            <Grid className="grid">
                {
                    <DataGrid
                        getRowId={row => row["userID"]}
                        columns={columns}
                        data={data}
                        loading={isLoading} />
                }
            </Grid>
        </Grid>
    )
}

//DetailPanelContentTeamList.propTypes = {
//row: PropTypes.object.isRequired,
//};

export default DetailPanelContentTeamList;