import * as React from "react";
import ProductComponent from "./ProductComponent";
import { useMainStoreContext } from "../../stores/MainStore";
import { Product } from "shopify-buy";
import { observer } from "mobx-react";
import {
  Box,
  Grid,
  Pagination,
  Card,
  TextField,
  InputAdornment,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination
} from "@mui/material";
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { FC, useState, ChangeEvent, MouseEvent } from 'react';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';

const applyFilters = (products: Product[], query: string): Product[] => {
  return products.filter((product) => {
    let matches = true;

    if (query) {
      const properties = ['title'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (product[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  products: Product[],
  page: number,
  limit: number
): Product[] => {
  return products.slice(page * limit, page * limit + limit);
};

function Products() {
  const { products } = useMainStoreContext().shopifyStore;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(8);
  const [query, setQuery] = useState<string>('');
  const [toggleView, setToggleView] = useState<string | null>('grid_view');

  // const handleViewOrientation = (
  //   _event: MouseEvent<HTMLElement>,
  //   newValue: string | null
  // ) => {
  //   setToggleView(newValue);
  // };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const filteredProducts = applyFilters(products, query);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);

  if (products) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <TextField
              fullWidth
              onChange={handleQueryChange}
              value={query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              placeholder={'Search by product name...'}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography component="span" variant="subtitle1">
                Showing:
              </Typography>{' '}
              <b>
                {filteredProducts.length} {'products'}
              </b>
            </Box>
            <ToggleButtonGroup
            // value={toggleView}
            // exclusive
            // onChange={handleViewOrientation}
            >
              {/* <ToggleButton disableRipple value="list_view">
                <TableRowsTwoToneIcon />
              </ToggleButton> */}
              <ToggleButton disableRipple value="grid_view">
                <GridViewTwoToneIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={12} >
          {toggleView === 'grid_view' && (
            <>
              {paginatedProducts.length === 0 ? (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      py: 10
                    }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                  >
                    We couldn't find any products matching your search criteria
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid container columnSpacing={5} rowSpacing={3}>
                    {paginatedProducts
                      .map((product: Product) => {
                        return (
                          <Grid item xs={3} key={product.id}>
                            <ProductComponent
                              key={product.id}
                              product={product}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>


                </>
              )
              }
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              p: 2
            }}
          >
            <TablePagination
              component="div"
              count={filteredProducts.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[4, 8, 12]}
            />
          </Card>
        </Grid>
      </Grid>
      // <div style={{ height: '890px', overflow: 'scroll' }}>
      //   <Grid container columnSpacing={5} rowSpacing={3}>
      //     {products
      //       .map((product: Product) => {
      //         return (
      //           <Grid item xs={3} key={product.id}>
      //             <ProductComponent
      //               key={product.id}
      //               product={product}
      //             />
      //           </Grid>
      //         );
      //       })}
      //       <Grid item xs={12}>
      //         <Box
      //           sx={{
      //             display: "flex",
      //             justifyContent: 'right',
      //             width: "100%",
      //           }}>
      //             <Box
      //               sx={{
      //                 width: "200px"
      //               }}
      //               >
      //             </Box>
      //         </Box>
      //       </Grid>
      //   </Grid>
      // </div>
    );
  } else {
    return <p>Loading...</p>;
  }
}
export default observer(Products);
