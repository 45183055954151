// Vendors
import React, { useEffect, useMemo, useState } from "react";
import { Grid, styled, Box, Typography, IconButton } from '@mui/material';

// Components
import DocSleepUploader from "./DocSleepUploader";
import FilesIcon from '../../imgs/png/Miscellaneous-Files.png';

// Entities
import DictionaryListItem from "../../entities/DictionaryListItem";
import PatientImage from "../../entities/PatientImage";

// API
import { observer } from "mobx-react";
import { FileUploader } from "react-drag-drop-files";
import { GlobalUtils } from '../../api/GlobalUtils';
import _ from "lodash";


const fileTypes = ["PDF", "DOCX", "DOC", "PPT", "PPTX"];

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);

interface IUploaderProps {
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    typeOptions: any[];
    extFiles: any[];
}

const UploadSleepDocDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback, typeOptions, extFiles }) => {

        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [docs, setDocs] = React.useState<any[]>([]);
        const [files, setFiles] = React.useState<any[]>([]);
        const [options, setOptions] = React.useState<JSX.Element[]>([]);
        const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            setOptions(typeOptions);
            if (extFiles && extFiles.length > 0) {
                _.forEach(extFiles, (i, index) => {
                    i["extIndex"] = index;
                    if (i.imageType) {
                        selectedOptions.push(i.imageType.id);
                    }
                })
                setDocs(extFiles);
                setSelectedOptions(selectedOptions);
            }
            setHasLoaded(true);
        }, [])

        useMemo(() => {
            GlobalUtils.getIntakeReports().map(item => {
                setReportTypes(prevState => [...prevState, item]);
            });
        }, []);

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var img = {} as PatientImage;
            var image = URL.createObjectURL(file);
            img.data64String = await convertBase64(file) as unknown as string;
            img.data64Url = decodeURI(image);
            img.pathUrl = decodeURI(file.name);
            img["new"] = true;
            img["extIndex"] = extFiles.length;
            extFiles.push(img);
        }

        const handleImageSet = (uploadFiles) => {
            setIsPreview(false);
            var imglist: PatientImage[] = docs;
            var promises: any[] = [];
            _.forEach(uploadFiles, async (file) => {
                const promise = doWork(file, docs);
                promises.push(promise);
            })
            Promise.all(promises).then(() => {
                setDocs(imglist.length > 0 ? imglist : extFiles);
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (index: number) => {
            setDocs(docs.filter((_, i) => i !== index));
        }

        const handleOptionSelect = (event: any, oldOption: number, index: number) => {
            // var selOpts = selectedOptions;
            // _.remove(selOpts, (o) => { return o == oldOption });
            // selOpts.push(event.target.value);
            //setSelectedOptions(selOpts);
            var type = _.find(typeOptions, (t) => { return t.id == event.target.value });
            extFiles[docs[index]["extIndex"]].imageType = type;
            docs[index].imageType = type;
            docs[index]["edited"] = true;
            setKey(Math.floor(Math.random() * 42));
            if (!docs[index].data64String) {
                doWork(index, docs);
            }
            setDocs(docs);
            //  return selOpts;
        }

        return (
            hasLoaded ?
                <Grid container direction="row" alignItems={"center"} justifyContent={"flext-start"} justifyItems="flex-start">
                    <Grid xs={12} item>
                        <Grid container justifyContent={"center"}>


                            <FileUploader label="Upload or drop a image here" handleChange={handleImageSet} types={fileTypes}
                                multiple={true}
                                children={
                                    <BoxUploadWrapper width={"600px"}>
                                        <Grid container direction="column" alignItems={'center'} justifyItems={'center'}>
                                            <IconButton><img src={FilesIcon} className="uploadImg" /></IconButton>
                                            <Typography>Drag & Drop or</Typography>
                                            <Typography>Click to Select Sleep Test File(s)</Typography>
                                        </Grid ></BoxUploadWrapper>} name="file" /></Grid>
                    </Grid>
                    {
                        _.map(docs, (doc, i) => {
                            return <Grid xs={12} item key={i}>
                                <DocSleepUploader
                                    file={doc}
                                    fileName={doc.pathUrl}
                                    index={key + i}
                                    docIndex={i}
                                    parentCallback={parentCallback}
                                    handleOptionSelect={handleOptionSelect}
                                    handleRemove={handleRemove}
                                    typeOptions={typeOptions}
                                    selectedOptions={selectedOptions}
                                    setFiles={setDocs}
                                />
                            </Grid>
                        })
                    }


                </Grid> : null
        )
    }

export default observer(UploadSleepDocDragDrop);