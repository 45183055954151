import { action, makeAutoObservable, observable } from "mobx";
import { MainStore } from "./MainStore";

export default class MyoCorrectAdminStore {

	@observable mainStore: MainStore;
	@observable toggleShowFilterResults: boolean = false;
	@observable isLeftNavExpanded: boolean = false;
	@observable pageTitle = "Main Dashboard";
	@observable openCalendly: boolean = false;
	@observable openCalendlyChangeSession: boolean = false;
	@observable openRecurring: boolean = false;
	@observable openReschedule: boolean = false;
	@observable openRescheduleCalendly: boolean = false;
	@observable selectedVivosId: string = '';
	@observable selectedSessionNumber: number | undefined;
	@observable selectedSessionStatus: string = '';
	@observable calendlyRescheduleUri: string = '';
	@observable previousDateReschedule: string = '';
	@observable schedulerLink: string|null = null;

	constructor(mainstore: MainStore) {
	  makeAutoObservable(this);
	  this.mainStore = mainstore;
	}

	@action setOpenCalendly = (newVal: boolean) => this.openCalendly = newVal;
	@action setOpenCalendlyChangeSession = (newVal: boolean) => this.openCalendlyChangeSession = newVal;
	@action setSchedulerLink = (newVal: string|null) => this.schedulerLink = newVal;
	@action setOpenRecurring = (newVal: boolean) => this.openRecurring = newVal;
	@action setOpenReschedule = (newVal: boolean) => this.openReschedule = newVal;
	@action setOpenRescheduleCalendly = (newVal: boolean) => this.openRescheduleCalendly = newVal;
	@action toggleLeftNav = (isLeftNavExpanded: boolean) => {
		this.isLeftNavExpanded = isLeftNavExpanded;
	  }

	@action toggleFilterResultsExpand = () => {
		this.toggleShowFilterResults = !this.toggleShowFilterResults;
	}
	@action setSelectedVivosId = (selected:string) => {
		this.selectedVivosId = selected;
	}
	@action setSelectedSessionNumber = (selected:number) => {
		this.selectedSessionNumber = selected;
	}
	@action setSelectedSessionStatus = (selected:string) => {
		this.selectedSessionStatus = selected;
	}
	@action setPreviousDateReschedule = (date:string) => {
		this.previousDateReschedule = date;
	}
	@action setCalendlyRescheduleUri = (uri:string) => {
		this.calendlyRescheduleUri = uri;
	}
}