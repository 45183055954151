import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment/moment';

import "../../../scss/_PatientEdit.scss";

import Item from '../../Item';
import { Close } from '@mui/icons-material';
import CssTextField from '../../CssTextField';

import { P2PTopicsAndQuestionsStep } from './PatientConsultationWizard/P2PTopicsAndQuestionsStep';
import { useMainStoreContext } from '../../../stores/MainStore';
import { GlobalUtils } from '../../../api/GlobalUtils';
import CssSelectField from '../../CssSelectField';
import DictionaryListItem from '../../../entities/DictionaryListItem';

interface INotesInfo {
    consultationStatusName: string;
    patientName: string;
    patientVivosId: string;
    providerName: string;
    scheduleDateTime: string;
    vivosId?: string;
    provider?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
}

interface IConsultationModal {
    isOpen: boolean;
    onClose?: (value) => void;
    isViewOnly?: boolean;
    consultation?: any;
    consultationId?: number;
    currentConsultation?: any;
    notesInfo?: INotesInfo;
    isClinicalAdvocate?: boolean;
}

export const ConsultationNotes: FC<IConsultationModal> = observer(
    ({ isOpen, onClose, consultation, currentConsultation, notesInfo, isClinicalAdvocate }) => {
        const { saveConsultationNotes, notes, setNotes, setConsultationId, resetConsultationNotes, status, setStatus } =
            useMainStoreContext().patientConsultationStore;

        const [consultationStatus, setConsultationStatus] = useState<DictionaryListItem[]>([]);

        useEffect(() => {
            setConsultationId(currentConsultation?.consultationId);

            setConsultationStatus(GlobalUtils.getConsultationStatus());

            return () => {
                resetConsultationNotes();
            };
        }, [resetConsultationNotes]);

        return (
            <Dialog open={isOpen} onClose={onClose} className="dialogNotes">
                <DialogTitle className="consultationNotesTitle" id="patient.consultation.label.title">
                    <Grid>
                    <Grid container justifyContent="space-between" xs={12} alignItems="center">
                        <Grid item>Consultation Notes</Grid>
                        <Grid item>
                            <Grid>
                                <IconButton className="vivButton invert" onClick={onClose}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" xs={12} direction="column">
                        <Grid className="consultationNotesHeader" container>
                            <Grid className="notesDetails">
                                <Grid container alignItems="center">
                                    <Typography className="typography">Patient Name :</Typography>
                                    <Grid className="noteDetail">
                                        {notesInfo?.patientName ||
                                            notesInfo?.firstName +
                                                ' ' +
                                                notesInfo?.middleName +
                                                ' ' +
                                                notesInfo?.lastName}
                                    </Grid>
                                </Grid>
                                
                                <Grid container alignItems="center">
                                    <Typography className="typography">Patient ID :</Typography>
                                    <Grid className="noteDetail">
                                        {notesInfo?.patientVivosId || notesInfo?.vivosId}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="notesDetails">
                                <Grid container alignItems="center">
                                    <Typography className="typography"> Provider Name :</Typography>
                                    <Grid className="noteDetail">{notesInfo?.providerName || notesInfo?.provider}</Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Typography className="typography">Schedule Date :</Typography>
                                    <Grid className="noteDetail">
                                        {moment.utc(currentConsultation?.scheduledDateTime).local().format('MM/DD/yyyy hh:mmA')}  
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            <Grid className="notesDetails">
                                <Grid container alignItems="center">
                                    <Typography className="typography">Schedule Status</Typography>
                                    <Grid className="formBasics">
                                    <CssSelectField
                                        className="TextBox long"
                                        style={{width:"250px"}}
                                        value={
                                            status ||
                                            consultationStatus.find(
                                                item => item.name === currentConsultation?.consultationStatus,
                                            )?.id
                                        }
                                        disabled={!isClinicalAdvocate}
                                        onChange={e => setStatus(Number(e.target.value))}
                                    >
                                        {consultationStatus.map(item => (
                                            <MenuItem className="" value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </CssSelectField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    
                    </Grid>
                </DialogTitle>
                <DialogContent>                   
                    <Grid container alignItems="flex-start" xs={12} direction="column" style={{overflowX:"hidden"}}>

                        <P2PTopicsAndQuestionsStep
                            isViewOnly={true}
                            consultationsData={consultation}
                            patient={currentConsultation}
                            info={currentConsultation}
                        />

                        <Grid
                            container
                            xs={12}
                            direction="row"
                            paddingLeft={4}
                            columnSpacing={0}
                            className="formBasics"
                            marginTop={2}
                        >
                            <Grid container direction={'row'} className="formSectionLabel">
                                Notes
                            </Grid>
                            <Grid container xs={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Item>
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            autoComplete="new-password"
                                            size="medium"
                                            multiline={true}
                                            disabled={
                                                currentConsultation.consultationStatus === 'Notes Complete' ||
                                                !isClinicalAdvocate
                                            }
                                            rows={5}
                                            placeholder="Notes"
                                            name=""
                                            required={false}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setNotes(event.target.value);
                                            }}
                                            value={notes ? notes : currentConsultation.additionalNotes}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} className="footer" marginTop={4}>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        saveConsultationNotes().then(() => {
                                            if (onClose) {
                                                onClose(true);
                                            }
                                        });
                                    }}
                                    id="provider.btn.save"
                                    className="vivButton large"
                                >
                                    {!isClinicalAdvocate ? "Close" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    },
);
