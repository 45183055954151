// Vendors
import React, { FC, useEffect, useState } from 'react';
import {
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Checkbox
} from '@mui/material';
import CheckboxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

// API
import moment from "moment";
import * as _ from 'lodash';


interface IReportVisit {
    visits?: any;
}

export const AiReportSelectVisit: FC<IReportVisit> = ({ visits }) => {
    const { setVisit } = useMainStoreContext().visitRecordStore;
    //const { visit, setVisit } = useMainStoreContext().aiReportOrder;
    const [current, setCurrent] = useState<any>({
        value: 0,
        label: ""
    });

    useEffect(() => {
        setCurrent({ value: visits[0]?.id, label: moment(visits[0]?.createdOn).format("MM/DD/yyyy hh:mm:ss") });
        setVisit(visits[0]);
    }, []);

    const hasForms = (row: any): boolean => {

        if ((row.patientSleepStudies && row.patientSleepStudies.length > 0) ||
            (row.patientEpworths && row.patientEpworths.length > 0) ||
            (row.patientHeadNecks && row.patientHeadNecks.length > 0) ||
            (row.patientHeadacheImpacts && row.patientHeadacheImpacts.length > 0) ||
            (row.patientPerceivedStresses && row.patientPerceivedStresses.length > 0) ||
            (row.patientStopBangs && row.patientStopBangs.length > 0)
        ) {
            return true;
        }
        return false;
    }

    const hasImages = (row: any): boolean => {
        if (row.patientImages && row.patientImages.length > 0) {
            _.forEach(Object.keys(row.patientImages[0]), (key) => {
                if (row.patientImages[0][key] != "") {
                    return true;
                }
            });
        }
        return false;
    }

    const hasCBCT = (patientImages: any): boolean => {
        if (patientImages && patientImages.length > 0) {
            if (patientImages[0].cbctScan != null &&
                patientImages[0].cbctScan != "") {
                return true;
            }
        }
        return false;
    }

    const hasModels = (patientImages: any): boolean => {
        if (patientImages && patientImages.length > 0) {
            if ((patientImages[0].occlusion3dlowerArch && patientImages[0].occlusion3dlowerArch != "")
                || (patientImages[0].occlusion3dupperArch && patientImages[0].occlusion3dupperArch != "")
                || (patientImages[0].occlusion3dconstructedBite && patientImages[0].occlusion3dconstructedBite != "")) {
                return true;
            }
        }
        return false;
    }


    return (
        <Grid xs={12} padding={1}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                Select
                            </TableCell>
                            <TableCell align='center'>
                                Visit Date
                            </TableCell>
                            <TableCell padding="checkbox" align='center' sx={{ paddingRight: '10px' }}>
                                Forms
                            </TableCell>
                            <TableCell padding="checkbox" align='center' sx={{ paddingRight: '10px' }}>
                                Images
                            </TableCell>
                            <TableCell padding="checkbox" align='center' sx={{ paddingRight: '10px' }}>
                                CBCT
                            </TableCell>
                            <TableCell padding="checkbox" align='center' sx={{ paddingRight: '10px' }}>
                                Models
                            </TableCell>
                            <TableCell align="right">
                                {/* Title for the Right-aligned column */}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visits.map((currentVal) => (
                            <TableRow hover key={currentVal.id}>
                                <TableCell padding="checkbox" align='center'>
                                    <Checkbox
                                        sx={{
                                            padding: 0.5
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Box pb={0.5} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" noWrap>
                                            {`${moment(currentVal.createdOn).format("MM/DD/yyyy")}`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell padding="checkbox" align='center' sx={{ paddingRight: '10px' }}>
                                    {
                                        hasForms(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />
                                    }
                                </TableCell>
                                <TableCell padding="checkbox" align='center' sx={{ paddingRight: '10px' }}>
                                    {
                                        hasImages(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />
                                    }
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {
                                        hasCBCT(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />
                                    }
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {
                                        hasModels(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />
                                    }
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        // <Grid className="modalPageHeight"><Typography variant="h6" paddingLeft={3} paddingTop={1}>
        //         Select Visit
        //     </Typography>
        //     <Select
        //         id={'AiReport.selectVisit'}
        //         sx={{ width: '300px', height: '30px' }}
        //         onChange={(event: SelectChangeEvent<string>) => {
        //             var selected = event.target.value;
        //             setVisit(visits.filter(x => x.id.toString() === selected.toString())[0]);
        //             setCurrent({value: selected});
        //         }}
        //         value={current.value}
        //     >
        //         {visits.map((currentVal) => (
        //             <MenuItem value={currentVal.id}>{`${moment(currentVal.createdOn).format("MM/DD/yyyy hh:mm:ss")}`}</MenuItem>
        //         ))}
        //     </Select>
        // </Grid>
    );
};
