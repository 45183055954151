// @vendors
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { AppBar, Box, Fade, Grid, IconButton, Modal, Toolbar, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';

// @components
import { SurveyCreatorWidget } from '../SurveyCreator';

interface AddModalInterface {
    open: boolean;
    onClose: (onClose: boolean) => void;
    categories: any;
    mappingTables: any;
}

export const AddQuestionModal: React.FC<AddModalInterface> = observer(({ onClose, open, categories, mappingTables }) => {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleOnClick = () => {
        onClose(false);
    };

    return (
        <Modal open={isOpen} className="modalContainer"  style={{height:'100vh'}}>
            <Fade in={isOpen}>
                <Box className="modalQuestion">
                    <Grid container direction="row" justifyContent={'space-between'}>
                        <AppBar position="static" className="modalHeader" color="inherit">
                            <Toolbar>
                                <Typography
                                    id={'modal.addQuestion.title'}
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                    className="title"
                                >
                                    {'Add Question'}
                                </Typography>
                                <IconButton id="addQuestion.mod.btn.close" onClick={handleOnClick}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <SurveyCreatorWidget onClose={setOpen} categories={categories} mappingTables={mappingTables} />
                </Box>
            </Fade>
        </Modal>
    );
});
