import { Grid, Typography, TextField, MenuItem, Button } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import { useMainStoreContext } from "../../stores/MainStore";
import { Ethnicity, Gender } from '../../entities/Enums';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { observer } from "mobx-react";

const styles = makeStyles((theme) => ({
	root: {
	  flexGrow: 1,
	  margin: theme.spacing(2),
	},
	buttons: {
		backgroundColor: '#324c9d',
		color: '#fff',
		marginTop: '5px',
		marginBottom: '5px',
		borderRadius: '18px',
	},
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	},
	buttonsContainer: {
	  display: 'flex',
	  justifyContent: 'space-between',
	  marginTop: theme.spacing(2),
	},
	vivosButton: {
		height:'100px',
		marginBottom:'-30px',
		marginTop:'-30px'} 
  }));

const StepOne = () => {
	const classes = styles();
	const { isSavingPatient, currentPatient, setCurrentPatient, setStep } = useMainStoreContext().myoCorrectEnrollmentStore;
	const genders = Object.values(Gender).filter(v => typeof(v) === 'string');
	const ethnicities = Object.values(Ethnicity).filter(v => typeof(v) === 'string');

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.firstName = event.target.value;
		setCurrentPatient(patient);
	};
	const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.lastName = event.target.value;
		setCurrentPatient(patient);
	};
	const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.genderId = parseInt(event.target.value);
		setCurrentPatient(patient);
	};
	const handleEthnicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.ethnicityId = parseInt(event.target.value);
		setCurrentPatient(patient);
	};
	const handleDateOfBirthChange = (value: Date | null) => {
		if(!value) return;
		const patient = { ...currentPatient };
		patient.dateOfBirth = value;
		setCurrentPatient(patient);
	};
	const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.altPhone = event.target.value;
		setCurrentPatient(patient);
	};
	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.primaryPhone = event.target.value;
		setCurrentPatient(patient);
	}
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.emailAddress = event.target.value;
		setCurrentPatient(patient);
	};

	const getAge = (dateOfBirth : Date) : number => {
		const today = new Date();
		const date = new Date(dateOfBirth);
		let age = today.getFullYear() - date.getFullYear();
		const months = today.getMonth() - date.getMonth();
		if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
		  age--;
		}
		return age;
	};
	 
	const handleSaveChanges = async () => {
		//await updatePerson(currentPatient, patientId);
	};

	if(!currentPatient) return (<>Loading Patient...</>);

	return (
		<div className={classes.root}>
		  <Grid container spacing={3}>
			<Grid item xs={12}>
			  <Grid container spacing={3}>
				<Grid item xs={10}>
					<Typography variant="h5">Patient Information</Typography>
				</Grid>
				<Grid item xs={2} sx={{textAlign:'right'}}>
					<LoadingButton loading={isSavingPatient} size="small" variant='contained' sx={{borderRadius:'20px'}} onClick={handleSaveChanges}>Save Changes</LoadingButton>
				</Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
				  	id="first-name"
					label="First Name"
					fullWidth
					value={currentPatient.firstName} 
					onChange={handleFirstNameChange} />
				</Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
				  	id="last-name"
					label="Last Name"
					fullWidth
					value={currentPatient.lastName} 
					onChange={handleLastNameChange} />
				</Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
					id="gender"
					select
					label="Gender"
					fullWidth
					value={currentPatient.genderId}
					onChange={handleGenderChange}
				  >
					{genders.map((option) => (
					  <MenuItem key={Gender[option]} value={Gender[option]}>
						{option}
					  </MenuItem>
					))}
				  </TextField>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Grid container spacing={3}>
						<Grid item xs={8} sm={8}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
							  <DatePicker
							  	label="Date of Birth"
								value={currentPatient.dateOfBirth ?? ''}
								openTo="day"
								disableFuture={true}
								views={['year', 'month', 'day']}
								renderInput={(params) => <TextField {...params} id="date-of-birth" label="Date of Birth" fullWidth value={currentPatient.dateOfBirth ?? ''} />}
								onChange={handleDateOfBirthChange}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs={4} sm={4}>
					  		<TextField
								id="age"
								label="Age"
								fullWidth
								disabled 
								value={currentPatient.dateOfBirth ? getAge(currentPatient.dateOfBirth) : ''}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
					id="ethnicity"
					select
					label="Ethnicity"
					fullWidth
					value={currentPatient.ethnicityId}
					onChange={handleEthnicityChange}
				  >
					{ethnicities.map((option) => (
					  <MenuItem key={Ethnicity[option]} value={Ethnicity[option]}>
						{option}
					  </MenuItem>
					))}
				  </TextField>
				</Grid>
				<Grid item xs={12} sm={4}></Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
				  	id="email"
					label="Email"
					fullWidth
					value={currentPatient.emailAddress}
					onChange={handleEmailChange} />
				</Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
				  	id="mobile-phone"
					label="Mobile Phone"
					fullWidth
					value={currentPatient.altPhone}
					onChange={handleCellPhoneChange} />
				</Grid>
				<Grid item xs={12} sm={4}>
				  <TextField
				  	id="home-phone"
					label="Home Phone"
					fullWidth
					value={currentPatient.primaryPhone}
					onChange={handlePhoneChange} />
				</Grid>
			  </Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12} display='flex' flexDirection='row-reverse'>
						<Button onClick={() => setStep(1)} variant="contained" color="primary" className={classes.buttons}>Save and Continue</Button>
					</Grid>
				</Grid>
			</Grid>
		  </Grid>
		</div>
	  );
};

export default observer(StepOne);