import React, { useEffect } from 'react';
import {
    Button, FormControlLabel, FormGroup, Grid, Switch,
    Box,
    Card,
    Typography,
    Link,
    Badge,
    List,
    ListItem,
    ListItemText,
    Avatar,
    alpha,
    IconButton,
    Divider,
    LinearProgress,
    styled,
    useTheme,
    linearProgressClasses
} from '@mui/material';
import moment from 'moment';

import { observer } from 'mobx-react';
import * as _ from 'lodash';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Oval } from 'react-loader-spinner';

import ProviderPatient from '../../../entities/ProviderPatient';
import PatientEditBL from '../PatientEditBL';
import { PatientGeneralInfo } from './PatientGeneralInfo';
import { PatientGeneralInfoTwo } from './PatientGeneralInfoTwo';
import { PatientHealthHistory } from './PatientHealthHistory';
import { PatientLegalGuardian } from './PatientLegalGuardians';
import { useMainStoreContext } from '../../../stores/MainStore';
import { PatientHealthHistoryTwo } from './PatientHealthHistoryTwo';

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
    isClinicalAdvocate?: boolean;
}

const LinearProgressPrimary = styled(LinearProgress)(
    ({ theme }) => `
          height: 6px;
          border-radius: ${theme.general.borderRadiusLg};
  
          &.${linearProgressClasses.colorPrimary} {
              background-color: ${alpha(theme.colors.primary.main, 0.1)};
          }
          
          & .${linearProgressClasses.bar} {
              border-radius: ${theme.general.borderRadiusLg};
              background-color: ${theme.colors.primary.main};
          }
      `
);

const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(1.5)};
      top: ${theme.spacing(1.5)};
      z-index: 7;
    `
);

export const PatientDetailsTwo: React.FC<IGIProps> = observer(({ bl, patient, isClinicalAdvocate }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [guardians, setGuardians] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const { patientHistory, setPatientHistory, setHistory } = useMainStoreContext().patientEditStore;

    useEffect(() => {
        bl.patientData = patient;

        bl.loadDetails().then(() => {
            setPatientHistory(bl.patientHistory);
            setHistory(bl.patientHistory);
            setIsLoaded(true);
        });

        setExpanded('panel1');
    }, []);

    const handleChange = (current: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? current : false);
    };

    if (!isLoaded) {
        return (
            <Grid container sx={{ marginTop: '50px', justifyContent: 'center' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={3} className="patientDetailsTab">
                <Grid item xs={4}>
                    <Card
                        sx={{
                            position: 'relative',
                            p: 3,
                            mt: 5
                        }}
                    >
                        <Box mb={2} display="flex" alignItems="center" >
                            <Box
                                sx={{
                                    width: '100%'
                                }}
                                ml={1.5}
                            >
                                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom variant="h4">
                                            General Information
                                        </Typography>
                                    </Grid>
                                    {bl.isEditMode
                                        ? <Button onClick={bl.onEdit} variant="contained">
                                            Save
                                        </Button>
                                        : ''}
                                    <Switch edge="end" color="primary" onChange={bl.onEditChange} checked={bl.isEditMode} />
                                </Grid>
                            </Box>
                        </Box>
                        <Divider />
                        <PatientGeneralInfoTwo bl={bl} patient={patient} />
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Card
                        sx={{
                            position: 'relative',
                            p: 3,
                            mt: 5
                        }}
                    >
                        <Box mb={2} display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: '100%'
                                }}
                                ml={1.5}
                            >
                                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography gutterBottom variant="h4">
                                        Health History
                                    </Typography>
                                    <Switch edge="end" color="primary" onChange={bl.onEditChange} checked={bl.isEditMode} />
                                </Grid>

                            </Box>
                        </Box>
                        <Divider />
                        {/* <PatientHealthHistoryTwo bl={bl} patient={patient} /> */}
                        <PatientHealthHistoryTwo defaultEditMode={bl.isEditMode} />
                    </Card>
                </Grid>
            </Grid>
            // <Grid xs={12} className="patientDetailsTab">
            //     <Grid xs={12} container direction={'row'} justifyContent="flex-end">
            //         <FormGroup hidden={isClinicalAdvocate}>
            //             <FormControlLabel
            //                 className="switch"
            //                 control={<Switch onChange={bl.onEditChange} checked={bl.isEditMode} />}
            //                 label="Edit"
            //             />
            //         </FormGroup>
            //     </Grid>
            //     <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            //         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            //             <Grid xs={12} container direction={'row'} className="headerLine ">
            //                 <Grid className="label">
            //                     <Grid>GENERAL INFORMATION</Grid>
            //                 </Grid>
            //             </Grid>
            //         </AccordionSummary>
            //         <AccordionDetails>
            //             <Grid className="viewBody" xs={12} container direction={'column'}>
            //                 <PatientGeneralInfo bl={bl} patient={patient} />
            //             </Grid>
            //         </AccordionDetails>
            //     </Accordion>
            //     <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            //         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            //             <Grid xs={12} container direction={'row'} className="headerLine">
            //                 <Grid className="label">
            //                     <Grid>HEALTH HISTORY</Grid>
            //                 </Grid>
            //             </Grid>
            //         </AccordionSummary>
            //         <AccordionDetails>
            //             <PatientHealthHistory />
            //         </AccordionDetails>
            //     </Accordion>
            //     {moment().diff(patient.dateOfBirth, 'years') < 18 && (
            //         <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            //             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            //                 <Grid xs={12} container direction={'row'} className="headerLine">
            //                     <Grid className="label">
            //                         <Grid>LEGAL GUARDIANS</Grid>
            //                     </Grid>
            //                 </Grid>
            //             </AccordionSummary>
            //             <AccordionDetails>
            //                 {bl.legalGuardians.length > 0 && (
            //                     <PatientLegalGuardian bl={bl} guardian={bl.legalGuardians[0]} />
            //                 )}
            //                 {bl.legalGuardians.length > 1 && (
            //                     <PatientLegalGuardian bl={bl} guardian={bl.legalGuardians[1]} />
            //                 )}
            //                 {bl.legalGuardians.length > 2 && (
            //                     <PatientLegalGuardian bl={bl} guardian={bl.legalGuardians[2]} />
            //                 )}
            //                 <Grid container sx={{ justifyContent: 'flex-end' }}>
            //                     {bl.isEditMode && bl.legalGuardians.length < 3 && (
            //                         <Button
            //                             sx={{ width: '200px' }}
            //                             className="vivButton large invert"
            //                             onClick={() => { }}
            //                         >
            //                             Add Legal Guardian
            //                         </Button>
            //                     )}
            //                     {bl.isEditMode && bl.legalGuardians.length > 0 && (
            //                         <Button className="vivButton large invert" onClick={() => { }}>
            //                             Save
            //                         </Button>
            //                     )}
            //                 </Grid>
            //             </AccordionDetails>
            //         </Accordion>
            //     )}
            // </Grid>
        );
    }
});
