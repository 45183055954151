interface IScanData {
    title: string,
    scantype: string,
    imageUrl: string,
    notes: string,
    rdbVenus:string,
    rdbSelera: string,
    rdbOpen:string,
    rdbIncomp:string,
    rdbHead:string,
    rdbMicrogenia:string,
    rdbMacrogenia:string,
}

interface ScanData extends IScanData {}

class ScanData {
    constructor({ title, scantype, imageUrl, notes, rdbVenus, rdbSelera, rdbOpen, rdbHead,rdbIncomp, rdbMicrogenia,rdbMacrogenia  }: IScanData) {
        this.title = title;
        this.scantype = scantype;
        this.imageUrl = imageUrl;
        this.notes = notes;
        this.rdbVenus= rdbVenus;
        this.rdbSelera = rdbSelera;
        this.rdbOpen = rdbOpen;
        this.rdbHead = rdbHead;
        this.rdbIncomp = rdbIncomp;
        this.rdbMicrogenia = rdbMicrogenia;
        this.rdbMacrogenia = rdbMacrogenia;
    }

}
export default ScanData;