import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { boxStyles } from "./styles";
import { makeStyles } from '@material-ui/core/styles';
import { useMainStoreContext } from "../../../stores/MainStore";
import Cart from "../../../components/shopify/Cart";
import { useEffect } from "react";
import Products from "../../../components/shopify/Products";
import { RxAppliances } from "../../../entities/Enums";

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

const StepSeven = () => {
	const classes = styles();
	const boxClasses = boxStyles();
	const {
		setStep,
		currentPatient,
		selectedLab,
		selectedAppliance,
		versaTypeSelected,
		constructionBite,
		constructionBiteType,
		modelSource,
		shippingMethodSelected,
		setSelectedApplianceType,
		createPrescription
	} = useMainStoreContext().prescriptionStore;
	const { client, setProducts, setCartOpen, cart } = useMainStoreContext().shopifyStore;
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;

	useEffect(() => {
		if (client)
			client.product.fetchAll().then((products) => {
				setProducts(products);
				setCartOpen(true);
			});
	}, []);

	const handleBack = (): void => {
		setStep(5);
	};

	const openCheckout = (): void => {
		if (cart?.lineItems.length > 0) {
			const productId = cart?.lineItems[0].variant.product.id;
			client.product.fetch(productId).then((product) => {
				setSelectedApplianceType(product.productType);
			}).then(() => {
				createPrescription();
				window.open(cart.webUrl, "_blank");
			});
		}
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Products />
				</Grid>
				<Grid item xs={2}>
					<Cart />
				</Grid>
				<Grid item xs={4}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							{selectedAppliance !== RxAppliances.VERSA && 
								<Grid item xs={12}>
									<Typography>
										<b>Lab:</b> {selectedLab.name}
									</Typography>
								</Grid>
							}
							<Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> Standard
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>
							{selectedAppliance === RxAppliances.VERSA && <Grid item xs={12}>
								<Typography>
									<b>Versa Type Selected:</b> {versaTypeSelected}
								</Typography>
							</Grid>}
							<Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Bite Source:</b> {constructionBite}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Bite Type:</b> {constructionBiteType}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Model Source:</b> {modelSource}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Shipping:</b> {shippingMethodSelected}
								</Typography>
							</Grid>
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							<Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>
							<Button variant="contained" color="primary" onClick={openCheckout} sx={{ borderRadius: '30px', margin: '20px' }}>
								Checkout
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>);
};
export default observer(StepSeven);
