// @vendors
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress, Grid, IconButton, LinearProgress, Tab, Tabs } from '@mui/material';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import GroupsIcon from '@mui/icons-material/Groups';

// @components
import DataGrid from '../../components/DataGrid/DataGrid';
import Alert from '../../components/AlertDialog/AlertDialog';
import CancelModal from '../../components/ImagesAndDocs/CancelModal';
import { CustomFormModal } from '../../components/CustomForms/CustomFormsModal';
import { AddSectionsModal } from '../../components/CustomForms/AddSectionsModal';
import { EditSectionsModal } from '../../components/CustomForms/EditSectionsModal';
import { AddQuestionModal } from '../../components/CustomForms/AddQuestionModal';
import { AddCustomForm } from '../../components/CustomForms/AddCustomForm';
import TabPanel from '../../components/TabPanel';
import { EditCustomForm } from '../../components/CustomForms/EditCustomForm';
import { EditQuestionModal } from '../../components/CustomForms/EditQuestionModal';
import { CustomFormToProvidersPage } from '../../components/CustomForms/CustomFormToProviders';

// @stores
import { useMainStoreContext } from '../../stores/MainStore';

interface iCustomFormsProps { }

export const CustomForms = observer(() => {
    const store = useMainStoreContext().customFormStore;

    const customFormToProviderStore = useMainStoreContext().customFormToProviderStore;
    const formBL = useMainStoreContext().formBL;

    const [isProvider, setIsProvider] = useState(false);

    let decodedToken: any = {};

    useEffect(() => {
        var token = sessionStorage.getItem('token');

        if (token) {
            decodedToken = jwt_decode(token);
            var rolesDecoded = JSON.parse(decodedToken.roles);
            for (let role of rolesDecoded) {
                if (role.Name === process.env.REACT_APP_VIVOS_PROVIDER) {
                    setIsProvider(true);
                }
            }
        }

        store.loadQuestionCategories();
        store.loadMappingTables();
        store
            .loadQuestionnaireData()
            .then(() => {
                store.loadQuestionnaireCustomForm();
            })
            .then(() => {
                store.loadSectionData();
            })
            .then(() => {
                store.loadQuestionData();
            });
    }, []);

    const questionnaireColumns = [
        {
            field: 'questionnaireName',
            headerName: 'Name',
            flex: 1,
            type: 'string',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {!_.isNull(params.row.questionaireTitle) && !_.isUndefined(params.row.questionaireTitle) && (
                            <Button
                                id={'customForms.cell.toggleEditSectionModal.' + params.row.questionaireId}
                                variant="text"
                                onClick={() => {
                                    store.loadDetailedQuestionnaireData(params.row.questionaireId);
                                    store.setCurrentRow(params.row);
                                    store.toggleEditQuestionnaireModal();
                                }}
                            >
                                {params.row.questionaireTitle
                                    ? params.row.questionnaireName
                                    : params.row.questionnaireName}
                            </Button>
                        )}

                        {(_.isNull(params.row.questionaireTitle) || _.isUndefined(params.row.questionaireTitle)) && (
                            <Grid>
                                {params.row.questionaireTitle
                                    ? params.row.questionnaireName
                                    : params.row.questionnaireName}
                            </Grid>
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'questionaireId',
            flex: 0.25,
            headerName: 'Id',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
        },
        {
            field: 'questionnaireDescription',
            flex: 1,
            headerName: 'Description',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
        },
        {
            field: 'creator',
            flex: 1,
            headerName: 'Creator Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            hide: !store.isAdmin,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            hide: !store.isAdmin,

            renderCell: params => {
                return (
                    <Grid>
                        <IconButton
                            id={`customForms.cell.btn.moveUp.${params.row.questionaireId}`}
                            onClick={() => {
                                store.showModalCustomFormToProvider();
                                customFormToProviderStore.setCustomFormId(params.row.questionaireId);
                                customFormToProviderStore.loadProvidersByCustomForm();
                            }}
                        >
                            <GroupsIcon />
                        </IconButton>
                    </Grid>
                );
            },
        },
    ];

    const sectionColumns = [
        {
            field: 'sectionTitle',
            headerName: 'Name',
            flex: 1,
            type: 'string',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {!_.isNull(params.row.sectionTitle) && !_.isUndefined(params.row.sectionTitle) && (
                            <Button
                                id={'customForms.cell.toggleEditSectionModal.' + params.row.sectionId}
                                variant="text"
                                onClick={() => {
                                    store.loadDetailedSectionData(params.row.sectionId);
                                    store.setCurrentRow(params.row);
                                    store.toggleEditSectionModal();
                                }}
                            >
                                {params.row.sectionTitle}
                            </Button>
                        )}

                        {(_.isNull(params.row.sectionTitle) || _.isUndefined(params.row.sectionTitle)) && (
                            <Grid>{params.row.sectionTitle}</Grid>
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'sectionId',
            flex: .25,
            headerName: 'Id',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
        },
        {
            field: 'displayHeader',
            flex: 1,
            headerName: 'Display Header',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
        },
        {
            field: 'creator',
            flex: 1,
            headerName: 'Creator Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            hide: !store.isAdmin,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <div>
                        <IconButton
                            id={`customForms.cell.btn.delete.${params.row.sectionId}`}
                            onClick={() => {
                                store.deleteSection(params.row);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    const questionColumns = [
        {
            field: 'question',
            headerName: 'Name',
            flex: 1,
            type: 'string',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {!_.isNull(params.row.question) && !_.isUndefined(params.row.question) && (
                            <Button
                                id={'customForms.cell.toggleEditQuestionModal.' + params.row.questionId}
                                variant="text"
                                onClick={() => {
                                    store.loadDetailQuestion(params.row.questionId);
                                    store.setCurrentRow(params.row);
                                    store.toggleEditQuestionModal();
                                }}
                            >
                                {params.row.question}
                            </Button>
                        )}

                        {(_.isNull(params.row.question) || _.isUndefined(params.row.question)) && (
                            <Grid>{params.row.question}</Grid>
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'questionId',
            flex: .25,
            headerName: 'Id',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
        },
        {
            field: 'displayTypeGrid',
            flex: 1,
            headerName: 'Display Type',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
        },
        {
            field: 'category',
            flex: 1,
            headerName: 'Category',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            hide: !store.isAdmin,
        },
        {
            field: 'creator',
            flex: 1,
            headerName: 'Creator Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            hide: !store.isAdmin,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <div>
                        <IconButton
                            id={`customForms.cell.btn.delete.${params.row.questionId}`}
                            onClick={() => {
                                store.deleteQuestion(params.row);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    const questionnairesCustomFooter = () => {
        return (
            <GridFooterContainer>
                <IconButton
                    id="customForms.btn.addQuestionnary"
                    onClick={() => {
                        store.toggleAddQuestionnaireModal();
                    }}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
                <GridFooter
                    sx={{
                        border: 'none',
                    }}
                />
            </GridFooterContainer>
        );
    };

    const sectionsCustomFooter = () => {
        return (
            <GridFooterContainer>
                <IconButton
                    id="customForms.btn.addSection"
                    onClick={() => {
                        store.toggleAddSectionModal();
                    }}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
                <GridFooter
                    sx={{
                        border: 'none',
                    }}
                />
            </GridFooterContainer>
        );
    };

    const questionCustomFooter = () => {
        return (
            <GridFooterContainer>
                <IconButton
                    id="customForms.btn.addQuestion"
                    onClick={() => {
                        store.toggleAddQuestionModal();
                    }}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
                <GridFooter
                    sx={{
                        border: 'none',
                    }}
                />
            </GridFooterContainer>
        );
    };

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    return (
        <>
            <Grid xl={12} container direction="row" className="page">
                <Grid className={'content' + (store.isLeftNavExpanded ? ' small' : ' big')}>
                    {/* <LeftNav onToggle={store.toggleLeftNav} /> */}
                    {store.questionsData.length === 0 ? (
                        <Grid item sx={{
                            paddingLeft: '200px', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh'
                        }}
                            alignItems="center"
                            justifyContent="space-between">
                            <CircularProgress variant="determinate" value={store.loadingQuestionsProgress} />
                        </Grid>
                    ) :
                        <Grid>
                            <Tabs value={formBL.activeTab} onChange={formBL.setActiveTab}>
                                <Tab label="Custom Forms" sx={{ fontSize: '20px' }} {...a11yProps(0)} />
                                <Tab label="Sections" sx={{ fontSize: '20px' }} {...a11yProps(1)} disabled={store.questionsData.length === 0} />
                                <Tab label="Questions" sx={{ fontSize: '20px' }} {...a11yProps(2)} disabled={store.questionsData.length === 0} hidden={isProvider} />
                            </Tabs>
                            <TabPanel value={formBL.activeTab} index={0}>
                                <Grid className="grid">
                                    {store.isLoadingQuestionnaires && (
                                        <LinearProgress variant="determinate" value={store.loadingQuestionnairesProgress} />
                                    )}
                                    <DataGrid
                                        data={store.questionnairesData}
                                        columns={questionnaireColumns}
                                        getRowId={row => row.questionaireId}
                                        hideFooter={store.isLoadingQuestionnaires}
                                        customFooter={questionnairesCustomFooter}
                                        loading={store.isLoadingQuestionnaires}
                                    />
                                </Grid>
                            </TabPanel>

                            <TabPanel value={formBL.activeTab} index={1}>
                                <Grid className="grid">
                                    {store.isLoadingSections && (
                                        <LinearProgress variant="determinate" value={store.loadingQuestionsProgress} />
                                    )}
                                    <DataGrid
                                        data={store.sectionsData}
                                        columns={sectionColumns}
                                        getRowId={row => row.sectionId}
                                        hideFooter={store.isLoadingSections}
                                        customFooter={sectionsCustomFooter}
                                        loading={store.isLoadingSections}
                                    />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={formBL.activeTab} index={2}>
                                <Grid className="grid">
                                    {store.isLoadingQuestions && (
                                        <LinearProgress variant="determinate" value={store.loadingQuestionsProgress} />
                                    )}
                                    {isProvider ? (
                                        <DataGrid
                                            data={store.questionsData}
                                            columns={questionColumns}
                                            getRowId={row => row.questionId}
                                            hideFooter={store.isLoadingQuestions}
                                            loading={store.isLoadingQuestions}
                                        />
                                    ) : (
                                        <DataGrid
                                            data={store.questionsData}
                                            columns={questionColumns}
                                            getRowId={row => row.questionId}
                                            hideFooter={store.isLoadingQuestions}
                                            customFooter={questionCustomFooter}
                                            loading={store.isLoadingQuestions}
                                        />
                                    )}
                                </Grid>
                            </TabPanel>
                        </Grid>}
                </Grid>
            </Grid>

            <AddQuestionModal
                open={store.toggleShowAddQuestionModal}
                onClose={store.handleCancelClose.bind(this)}
                categories={store.questionCategories}
                mappingTables={store.mappingTables}
            />

            <EditQuestionModal
                open={store.toggleShowEditQuestionModal}
                onClose={store.handleCancelClose.bind(this)}
                question={store.detailQuestion}
                categories={store.questionCategories}
                mappingTables={store.mappingTables}
            />

            <AddSectionsModal
                open={store.toggleShowAddSectionModal}
                onClose={store.handleCancelClose.bind(this)}
                questions={store.questionsData}
            />

            <EditSectionsModal
                open={store.toggleShowEditSectionModal}
                onClose={store.handleCancelClose.bind(this)}
                section={store.detailedSectionData}
                questions={store.questionsData}
            />

            <AddCustomForm
                isOpen={store.toggleShowAddQuestionnaireModal}
                onClose={store.handleCancelClose.bind(this)}
                sections={store.sectionsDataQuestionnaire}
            />

            <EditCustomForm
                isOpen={store.toggleShowEditQuestionnaireModal}
                onClose={store.handleCancelClose.bind(this)}
                section={store.detailedQuestionnaireData}
                sections={store.sectionsDataQuestionnaire}
                isLoading={store.isLoadingQuestions}
            />

            <CustomFormModal
                isOpen={store.toggleShowCancelWarningModal}
                onClose={store.handleCancelClose.bind(this)}
                title="Warning"
            >
                <CancelModal CloseModal={store.handleCancelClose.bind(this)} />
            </CustomFormModal>

            <Alert
                open={store.isAlertDialog}
                close={store.closeAlertDialog}
                message={store.alertMessage}
                title={store.alertTitle}
            />

            <CustomFormToProvidersPage
                customFormId={customFormToProviderStore.customFormId}
                isOpen={store.toggleShowCustomFormToProvider}
                onClose={store.closeModalCustomFormToProvider}
            />
        </>
    );
});
