// Vendors
import * as React from 'react';
import { Grid, Tab, Tabs, Typography, Box } from '@mui/material';

// Components
import { AlertDialog } from '../AlertDialog';
import { VisitHeader } from './VisitHeader';
import ExtraoralImages from '../ImagesAndDocs/ExtraoralImages';
import { PatientVitalsAndMeasurements } from '../PatientProfile/PatientVisitRecordComponents/PatientVitalsAndMeasurements';
import IntraoralImages from '../ImagesAndDocs/IntraoralImages';
import ScanCbct from '../ImagesAndDocs/ScanCbct';
import ScanOcclusion from '../ImagesAndDocs/ScanOcclusion';
import Documents from '../ImagesAndDocs/Documents';
import { PatientCompliance } from './Compliance';
import { VisitTab } from './VisitTab';
import SleepTest from '../ImagesAndDocs/SleepTest';

// Entities
import VitalsAndMeasurments from '../../entities/VitalsAndMeasurments';
import PatientVisit from '../../entities/PatientVisit';

// Stores
import { useMainStoreContext } from '../../stores/MainStore';

// API
import { FC } from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { Gateway } from '../../api/Gateway';
import { GlobalUtils } from '../../api/GlobalUtils';

// Styling
import '../../scss/_VisitRecord.scss'

interface IGIProps {
    patient: any;
    visit?: any;
    onClose(): any;
}

interface AppointmentTypeOptionType {
    inputValue?: string;
    name: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
const tabs = [
    'Compliance',
    'Vitals & Measurements',
    'Extraoral Images',
    'Intraoral Images',
    'CBCT',
    'Impressions',
    'Sleep Test',
    'Documents',
];

const COMPLIANCE = 0;
const VITALS = 1;
const EXTRAORAL = 2;
const INTRAORAL = 3;
const CBCT = 4;
const IMPRESSIONS = 5;
const SLEEPTEST = 6;
const DOCUMENTS = 7;

export const VisitRecord: FC<IGIProps> = observer(({ patient, visit, onClose }) => {
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const { getVitalsAndMeasurements, setVitalsAndMeasurements, setVisitId, vitalsAndMeasurementsReset } = useMainStoreContext().vitalsAndMeasurementsStore;
    const { id, activeTab, setActiveTab, currentVisit, patientsImages, visitExtraoral3dimages, visitExtraoral2dimages, userId,
        getVisitId, loadVisit, resetVisit, setImages,
        setPatientVitalsAndMeasurements, setExtraoralImageTypes, setIntraoralImageTypes, setOcclusionImageTypes,
        setVisitPatientId, saveVisit, saveVitals, setVisit, saveCompliance, visitImagesToPatientImages, visitImagesToComplianceImages, visitOcclusion3dimages, visitOcclusion2dimages,
        patientUploadFiles, setPatientUploadFiles, setPatientFiles, visitIntraoral2dimages, deleteImages, deleteComplianceImages } = useMainStoreContext().visitRecordStore;
    const { updateCbctDetails } = useMainStoreContext().scanStore;
    const { setMolarRelationshipType, setMachineBrand3DType, setCbctMachineBrandType, setpatientImageType, setpatientOcclusionImageType } = useMainStoreContext().createPatientStore;
    const occlusionStore = useMainStoreContext().occlusionStore;

    React.useEffect(() => {
        resetStores();
        setActiveTab(COMPLIANCE);
        if (visit) {
            setVisit(visit);
            visitImagesToComplianceImages();
            visitImagesToPatientImages();
        }
        else {
            setVisit({} as PatientVisit)
        }
        setVisitPatientId(patient.id);
        setHasLoaded(true);
    }, []);


    React.useMemo(() => {
        setExtraoralImageTypes(GlobalUtils.getExtraOralImages());
        setIntraoralImageTypes(GlobalUtils.getIntraOralImages());
        setOcclusionImageTypes(GlobalUtils.getPatientOcclusionImageTypes());
        setMachineBrand3DType(GlobalUtils.get3DMachineBrands());
        setMolarRelationshipType(GlobalUtils.getMolarRelationships());
        setCbctMachineBrandType(GlobalUtils.getCBCTMachineBrands());
        setpatientImageType(GlobalUtils.getPatientImageTypes());
        setpatientOcclusionImageType(GlobalUtils.getPatientOcclusionImageTypes());
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const resetStores = () => {
        resetVisit();
        vitalsAndMeasurementsReset();
        setImages([]);
    }

    const handleVitalsSave = () => {
        let vitals = getVitalsAndMeasurements();
        vitals["patientId"] = patient.id;
        setPatientVitalsAndMeasurements(vitals);
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    setVisit(currentVisit);
                    setVisitId(currentVisit.id);
                    setVitalsAndMeasurements((currentVisit.patientVitalsAndMeasurements &&
                        currentVisit.patientVitalsAndMeasurements.length > 0) ? currentVisit.patientVitalsAndMeasurements[0] : {} as VitalsAndMeasurments);
                }
            });
        } else {
            vitals.visitId = getVisitId();
            saveVitals().then((resp) => {
                if (currentVisit) {
                    setVisit(currentVisit);
                    setVisitId(currentVisit.id);
                    setVitalsAndMeasurements((currentVisit.patientVitalsAndMeasurements &&
                        currentVisit.patientVitalsAndMeasurements.length > 0) ? currentVisit.patientVitalsAndMeasurements[0] : {} as VitalsAndMeasurments);
                }
            });
        }
    }

    const handleExtraoralImagesSave = () => {
        var promises: any[] = [];
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (resp) {
                    var currentV = resp as unknown as PatientVisit;
                    setVisit(currentV);
                    setVisitId(currentV.id);
                    _.forEach(visitExtraoral2dimages, (img) => {
                        promises.push(Gateway.post(`/patient/${patient.id}/${currentV.id}/${userId}/addpatientimage`, img).then(() => { }));
                    })

                    _.forEach(visitExtraoral3dimages, (img) => {
                        promises.push(Gateway.post(`/patient/${patient.id}/${currentV.id}/${userId}/addpatientimage`, img).then(() => { }));
                    })
                }
                Promise.all(promises).then(() => {
                    setImages([]);
                    loadVisit();
                });
            });


        } else {
            if (visit) {
                _.forEach(visitExtraoral2dimages, (img) => {
                    promises.push(Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img).then(() => { }));
                })

                _.forEach(visitExtraoral3dimages, (img) => {
                    promises.push(Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img).then(() => { }));
                })
                deleteImages(visit.id);
            }
            Promise.all(promises).then(() => {
                setImages([]);
                loadVisit();
            });
        }
    }


    const handleIntraloralImagesSave = () => {
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    _.forEach(visitIntraoral2dimages, (img) => {
                        Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img).then(
                            () => {
                                setImages([]);
                                loadVisit();
                            });
                    })
                }
            });

        } else {
            if (currentVisit) {
                _.forEach(visitIntraoral2dimages, (img) => {
                    Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img).then(
                        () => {
                            setImages([]);
                            loadVisit();
                        });
                })
                deleteImages(visit.id);
            }
        }
    }

    const handleCbctSave = () => {
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    _.forEach(patientsImages, (img) => {
                        Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img).then(
                            () => {
                                setImages([]);
                                loadVisit();
                            });
                    })
                }
            });

        } else {
            updateCbctDetails();
        }
    }


    const handleImpressionsSave = () => {
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    let images = visitOcclusion2dimages.concat(visitOcclusion3dimages);
                    _.forEach(images, (img) => {
                        Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img).then(
                            () => {
                                setImages([]);
                                loadVisit();
                            });
                    })
                }
            });

        } else {
            occlusionStore.setVisitId(visit.id);
            occlusionStore.updateOcclusionDetails(getVisitId());
            let images = visitOcclusion2dimages.concat(visitOcclusion3dimages);
            _.forEach(images, (img) => {
                Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientimage`, img);
            })
            deleteImages(visit.id);
        }
    }

    const handleDocumentsSave = () => {
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    _.forEach(patientUploadFiles, (doc) => {
                        Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientfile`, doc).then(
                            (resp) => {
                                setPatientUploadFiles([]);
                                setPatientFiles(resp);
                                loadVisit();
                            });
                    })
                }
            });

        } else {
            _.forEach(patientUploadFiles, (doc) => {
                Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientfile`, doc).then(
                    (resp) => {
                        setPatientUploadFiles([]);
                        setPatientFiles(resp);
                    }
                );
            })
        }
    }

    const handleSleepTestSave = () => {
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    _.forEach(patientUploadFiles, (doc) => {
                        Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientfile`, doc).then(
                            (resp) => {
                                setPatientUploadFiles([]);
                                setPatientFiles(resp);
                                loadVisit();
                            });
                    })
                }
            });

        } else {
            _.forEach(patientUploadFiles, (doc) => {
                Gateway.post(`/patient/${patient.id}/${visit.id}/${userId}/addpatientfile`, doc).then(
                    (resp) => {
                        setPatientUploadFiles([]);
                        setPatientFiles(resp);
                    }
                );
            })
        }
    }

    const handleComplianceSave = () => {
        if (getVisitId() <= 0) {
            saveVisit().then(() => {
                saveCompliance();
            });
        } else {
            deleteComplianceImages(visit.id);
            saveCompliance();
        }
    }


    return hasLoaded ? (
        <Grid container direction={"column"} xs={12} className="visitModalContainer" >
            <Grid xs={12} className="visitHeader">
                <VisitHeader
                    patient={patient}
                    tabs={tabs}
                    activeTab={activeTab}
                    onClick={setActiveTab}
                    onClose={onClose} />
            </Grid>
            <Grid xs={12} className="visitTabContainer">
                {activeTab === COMPLIANCE && <VisitTab onClick={handleComplianceSave}> <PatientCompliance patient={patient} visit={visit} parentCallback={handleComplianceSave} /></VisitTab>}
                {activeTab === VITALS && <VisitTab onClick={handleVitalsSave}> <PatientVitalsAndMeasurements visit={visit} /></VisitTab>}
                {activeTab === EXTRAORAL && <VisitTab onClick={handleExtraoralImagesSave}> <ExtraoralImages isIntake={false} isVisit={true} /></VisitTab>}
                {activeTab === INTRAORAL && <VisitTab onClick={handleIntraloralImagesSave}> <IntraoralImages isIntake={false} isVisit={true} /></VisitTab>}
                {activeTab === CBCT && <VisitTab onClick={handleCbctSave}> <ScanCbct isIntake={false} visit={visit} /></VisitTab>}
                {activeTab === IMPRESSIONS && <VisitTab onClick={handleImpressionsSave}> <Box paddingX={4}><ScanOcclusion isIntake={false} visit={visit} isRework={false} /></Box></VisitTab>}
                {activeTab === SLEEPTEST && <VisitTab onClick={handleSleepTestSave}>  <SleepTest isIntake={false} visit={visit} /></VisitTab>}
                {activeTab === DOCUMENTS && <VisitTab onClick={handleDocumentsSave}> <Documents isIntake={false} visit={visit} isPatientFormStep={false} /></VisitTab>}
            </Grid>
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    ) : (
        <></>
    );
});
