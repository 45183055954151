interface PatientHistoryData {
    id: number | null, 
	patientId: number,
    allergyOptions: number,
    allergyHxNotes: string,
    cpapMadOptions: number,
    cpapMadHxNotes:  string,
    dentalOptions: number,
    dentalHxNotes:  string,
    medicalOptions: number,
    medicalHxNotes: string,
    areasOfConcern: number,
    areasOfConcernOther: string,
    areasOfConcernNotes: string,
    headAndNeckExamUrl: string,
    healthIntakeFormUrl: string,
    notes: string,
    userId: number,
    createdBy?: number,
    createdOn?: Date,
    modifiedBy?: number,
    modifiedOn?: Date
}

interface PatientHistory extends PatientHistoryData {}

class PatientHistory {
    constructor({id, patientId, allergyOptions, allergyHxNotes, cpapMadOptions, cpapMadHxNotes, 
        dentalOptions, dentalHxNotes, areasOfConcern, areasOfConcernOther, areasOfConcernNotes, 
        medicalHxNotes, medicalOptions, notes, userId, headAndNeckExamUrl, healthIntakeFormUrl,
        createdBy, createdOn, modifiedBy, modifiedOn}: PatientHistoryData) {
            this.id = id; 
            this.patientId = patientId;
            this.allergyOptions = allergyOptions;
            this.allergyHxNotes = allergyHxNotes;
            this.cpapMadOptions = cpapMadOptions;
            this.cpapMadHxNotes = cpapMadHxNotes;
            this.dentalOptions = dentalOptions;
            this.dentalHxNotes = dentalHxNotes;
            this.medicalHxNotes = medicalHxNotes;
            this.medicalOptions = medicalOptions;
            this.areasOfConcern = areasOfConcern;
            this.areasOfConcernOther = areasOfConcernOther;
            this.areasOfConcernNotes = areasOfConcernNotes;
            this.notes = notes;
            this.userId = userId;
            this.headAndNeckExamUrl = headAndNeckExamUrl;
            this.healthIntakeFormUrl = healthIntakeFormUrl;
            this.createdBy = createdBy;
            this.createdOn = createdOn;
            this.modifiedBy = modifiedBy;
            this.modifiedOn = modifiedOn;
    }

    toObject(): PatientHistoryData {
        return {...this};
    }
}
export default PatientHistory;