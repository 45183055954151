import {
    action, computed,
    makeObservable,
    observable,

} from "mobx";

import {MainStore } from "./MainStore";

export class PendingCasesStore {

    mainStore: MainStore;
    @observable pageTitle = "Cases";
    @observable _isLeftNavExpanded: boolean = false;


    constructor(mainStore: MainStore) {
        makeObservable(this)
        this.mainStore = mainStore;
    }

    @computed
    get isLeftNavExpanded() {
        return this._isLeftNavExpanded;
    }

    set isLeftNavExpanded(value: any) {
        this._isLeftNavExpanded = value;
    }

    @action
    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this._isLeftNavExpanded = isLeftNavExpanded;
    };

}
