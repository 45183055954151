import { observer } from 'mobx-react';
import { FC } from 'react';
import { Divider, Grid } from '@mui/material';

import { SleepConditions } from './DiagnosisFromSleep';
import { ExtraoralConditions } from './DiagnosisFromExtraoral';
import { IntraoralConditions } from './DiagnosisFromIntraolal';
import { DiagnosisCBCT } from './DiagnosisFromCBCT';

interface IDiagnosisPage {
    patient?: any;
}

export const DiagnosisPage: FC<IDiagnosisPage> = observer(({ patient }) => {
    return (
        <Grid>
            <SleepConditions />
            <Divider />
            <ExtraoralConditions />
            <Divider />
            <IntraoralConditions />
            <Divider />
            <DiagnosisCBCT />
            <Divider />
        </Grid>
    );
});
