import { useEffect, useState } from "react";

const logicAppURL = process.env.REACT_APP_TESTPATIENTLOGICAPP;
const createPatientURL = process.env.REACT_APP_TESTPATIENTURL;
const ROUND_ROBIN = process.env.REACT_APP_ROUNDROBIN;

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};
const calendlyURL = ROUND_ROBIN
const CreateTestPatients = () => {
	const [ vivosId, setVivosId ] = useState('');
	const [ result, setResult ] = useState('');
	const [ showCalendly, setShowCalendly ] = useState(false);
	useEffect(()=> {
		const head = document.querySelector('head');
		if(showCalendly) {
			const script = document.createElement('script');
			script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
			script.setAttribute('id', 'calendly-script');
			if(head) {
				head.appendChild(script);
			}
		}
		else {
			if(head) {
				const script = head.querySelector('#calendly-script');
				if(script) {
					head.removeChild(script);
				}
			}
		}
	},[showCalendly]);
	const sendRequest = async () => {
		try{
		setResult(`Creating the patient`);
		const response = await fetch(`${createPatientURL}/${vivosId}`, {headers: headers});
		} catch(err){
			console.log(err)
		}
		setResult(`Running Logic App`);
		const r = await fetch(`${logicAppURL}`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({ vivosId: vivosId })
		});
		setResult(`Loading Calendly please remember to type ${vivosId} in the first question at the form, also you would need to put an advisor email "leighdavanzo@vivos.com", "ginetperez@vivos.com", "nataliestahl@vivos.com"`);
		setShowCalendly(true);
	}
	return (
	<>
		<div>
			<input onChange={(ev)=>setVivosId(ev.target.value)} />
			<button onClick={sendRequest}>Submit</button>
		</div>
		<div>
			{result}
		</div>
		<div>
			<div className="calendly-inline-widget" data-url={calendlyURL} style={{minWidth:'1000px',height:'700px'}}></div>
		</div>
	</>);
}
export default CreateTestPatients;
