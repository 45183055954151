import Patient from "../entities/Patient";
import { MainStore } from "./MainStore";
import { action, makeAutoObservable, observable } from "mobx";
import jwt_decode from 'jwt-decode';

export default class MyoCorrectEnrollment {
	mainStore: MainStore;
	userID: number = 0;
	constructor(mainstore: MainStore) {
		makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
        }
		this.mainStore = mainstore;
	}
	@observable currentPatient : Patient | null = null;
	@observable step = 0;
	@observable toggleShowMyoCorrectModal = false;
	@observable isSavingPatient = false;
	@observable selectedPlan: number = 2;
	@observable toggleFirstSession = false;
	@observable toggleCalendly = false;
	@action setCurrentPatient = (patient: any) => {
		this.currentPatient = patient;
	}
	@action setStep = (step: number) => {
		this.step = step;
	};
	@action setToggleShowMyoCorrectModal = (toggle: boolean) => {
		if(!toggle) {
			this.setCurrentPatient(null);
			this.setStep(0);
			this.setSelectedPlan(2);	
		}
		this.toggleShowMyoCorrectModal = toggle;
	}
	@action setIsSavingPatient = (toggle: boolean) => {
		this.isSavingPatient = toggle;
	}
	@action setSelectedPlan = (plan: number) => {
		this.selectedPlan = plan;
	}
	@action setToggleFirstSession = (toggle: boolean) => {
		this.toggleFirstSession = toggle;
	}
	@action setToggleCalendly = (toggle: boolean) => {
		this.toggleCalendly = toggle;
	}
}