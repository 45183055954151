// Vendor
import React, { Fragment, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    IconButton,
    LinearProgress,
    Button,
    Modal,
    TextareaAutosize,
    Typography,
    styled,
    MenuItem
} from '@mui/material';
import {
    GridFilterItem, GridFilterInputValue
} from '@mui/x-data-grid';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Close, FileDownload, FileUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import LoopTwoToneIcon from '@mui/icons-material/LoopTwoTone';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';

// Components
import DataGrid from '../../components/DataGrid/DataGrid';
import { DashboardInfoBox } from '../../components/DashboardInfoBox/DashboardInfoBox';
import RadiologySvg from '../../imgs/thumbnails/radiology report_tn.jpg';
import FrontalAnalysis from '../../imgs/thumbnails/Frontal-Analysis_tn.jpg';
import SassouniImg from '../../imgs/thumbnails/Sassouni_tn.jpg';
import Print3DSvg from '../../imgs/thumbnails/3d-printed-airway_tn.jpg';
import ComparisonSvg from '../../imgs/thumbnails/Comparison-AI-Report_tn.jpg';
import StandardSvg from '../../imgs/thumbnails/Standard-AI-Report_tn.jpg';
import ModalContainer from '../../components/ModalContainer';
import { AIReportEntry } from '../../modals/AIReportEntry/AIReportEntry';
import SearchBar from '../../components/UI/SearchBar';

// Entities
import { AIReportsFilters } from '../../entities/Enums';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

// API
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Gateway } from '../../api/Gateway';
import { GlobalUtils } from '../../api/GlobalUtils';
import Papa from 'papaparse';
import moment from 'moment';

// Styling
import '../../scss/_AIReport.scss';
import CssSelectField from '../../components/CssSelectField';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from './components/PageHeader';
import DashboardInfoCard from '../../components/UI/Cards/DashboardInfoCard';


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    boxShadow: 24,
    bgcolor: 'background.paper',
    p: 2,
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const DAYSINQUEUEOK = 3;
const DAYSINQUEUEWARN = 6;
interface csvValues {
    name: string;
    xValue?: string;
    yValue?: string;
    zValue?: string;
}

interface IReportProps {
    userRoles: any[];
}

export const AIReportsComponent = (props: IReportProps) => {
    const [modalText, setModalText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const [showEntryModal, setShowEntryModal] = useState(false);
    const {
        isLeftNavExpanded,
        pageTitle,
        toggleLeftNav,
        loadingProgress,
        isLoading,
        patientData,
        loadData,
        numPendingCases,
        numAssigned,
        numCompleted,
        numInProgress,
        numToBeAssigned,
        currentFilter,
        onFilter,
        userID,
        getSearchData,
        clearData,
        toggleFilterResultsExpand,
        loadTechnicianData,
        technician,
        aiStatus,
        handleChangeTechnician,
        handleStatusChange,
        setAiReportStatus,
    } = useMainStoreContext().aiReportsStore;

    const { loadDataValues } = useMainStoreContext().measurementsData;
    const { resetSubmission, triggerShowAiReportModal, setTriggerShowAiReportModal, setSubmissionId } =
        useMainStoreContext().aiReportEntryStore;

    useEffect(() => {
        loadData();
        loadTechnicianData();
        setAiReportStatus(GlobalUtils.getAIReportStatuses());
    }, [loadData, setAiReportStatus]);

    const downloadImages = async submissionId => {
        const urls = await Gateway.get(`aireports/images/${submissionId}`);
        urls.forEach(url =>
            setTimeout(function () {
                var a = document.createElement('a');
                a.setAttribute('href', url);
                document.body.appendChild(a);
                a.setAttribute('download', '');
                a.setAttribute('target', '_blank');
                a.click();
                a.remove();
            }, 500),
        );
    };

    const handleModalClose = () => {
        resetSubmission();
    };

    const handleUpload = async submissionId => {
        var fileUpload = document.createElement('input');
        fileUpload.setAttribute('type', 'file');
        document.body.appendChild(fileUpload);
        fileUpload.addEventListener('change', ev => {
            uploadFile(fileUpload, ev, submissionId);
        });
        fileUpload.click();
    };

    const uploadFile = (uploadElement: HTMLInputElement, ev: any, submissionId: number) => {
        var reader = new FileReader();
        reader.onload = async event => {
            if (event.target) {
                await Gateway.post(`aireports/images/${submissionId}`, { file: event.target?.result });
            }
            uploadElement.remove();
        };
        reader.readAsText(ev.target.files[0]);
    };

    const showCSVModal = (submissionId: number) => {
        setSelectedSubmissionId(submissionId);
        setShowModal(true);
    };
    const parseCSV = async modalText => {
        setIsSaving(true);
        const rows = modalText.split('\n');
        let values: csvValues[] = [];
        Papa.parse(modalText, {
            header: true,
            skipEmptyLines: 'greedy',
            complete: function (results) {
                let index = 0;
                let dataSet = results.data.map(obj => {
                    return {
                        name: obj[Object.keys(obj)[0]],
                        xValue: obj[Object.keys(obj)[1]] !== undefined ? obj[Object.keys(obj)[1]] : 'N/A',
                        yValue: obj[Object.keys(obj)[2]] !== undefined ? obj[Object.keys(obj)[2]] : 'N/A',
                        zValue: obj[Object.keys(obj)[3]] !== undefined ? obj[Object.keys(obj)[3]] : 'N/A',
                    };
                });
                values = dataSet;
            },
        });

        await Gateway.post(`aireports/${selectedSubmissionId}/csv`, values).then(() => {
            setIsSaving(false);
            setModalText('');
            setShowModal(false);
            loadDataValues(selectedSubmissionId);
        });
    };

    const numResults = 0;
    const hdrButtons: any = [];
    hdrButtons.push(
        <Button
            id={'myocorrecttherapist.header.btn.filterPatients.' + userID}
            className="vivButton large"
            onClick={toggleFilterResultsExpand}
        >
            FILTER RESULTS
        </Button>,
    );

    const infoBoxes: any = [];
    infoBoxes.push(
        // <Grid key={'dib0'}>
        <DashboardInfoCard
            cardLabelText={'Pending Cases'}
            cardNumberValue={numPendingCases}
            icon={<PendingActionsTwoToneIcon />}
            filter={AIReportsFilters.PendingCases}
            currentFilter={currentFilter}
            onFilter={value => {
                onFilter(value, AIReportsFilters.PendingCases);
            }}
        />
        // </Grid>,
    );
    infoBoxes.push(
        // <Grid container key={'dib1'}>
        <DashboardInfoCard
            cardLabelText={'In Progress'}
            cardNumberValue={numInProgress}
            icon={<LoopTwoToneIcon />}
            filter={AIReportsFilters.CasesInProgress}
            currentFilter={currentFilter}
            onFilter={value => {
                onFilter(value, AIReportsFilters.CasesInProgress);
            }}
        />
        // </Grid>
    );
    infoBoxes.push(
        // <Grid key={'dib2'}>
        <DashboardInfoCard
            cardLabelText={'Cases To Be Assigned'}
            filter={AIReportsFilters.ToBeAssigned}
            cardNumberValue={numToBeAssigned}
            icon={<LoopTwoToneIcon />}
            currentFilter={currentFilter}
            onFilter={value => {
                onFilter(value, AIReportsFilters.ToBeAssigned);
            }}
        />
        // </Grid>,
    );
    infoBoxes.push(
        // <Grid key={'dib3'}>
        <DashboardInfoCard
            cardLabelText={'Cases Assigned'}
            filter={AIReportsFilters.Assigned}
            icon={<LoopTwoToneIcon />}
            cardNumberValue={numAssigned}
            currentFilter={currentFilter}
            onFilter={value => {
                onFilter(value, AIReportsFilters.Assigned);
            }}
        />
        // </Grid>,
    );
    infoBoxes.push(
        // <Grid key={'dib4'}>
        <DashboardInfoCard
            cardLabelText={'Completed Rolling 30 Days'}
            filter={AIReportsFilters.Completed}
            icon={<LoopTwoToneIcon />}
            cardNumberValue={numCompleted}
            currentFilter={currentFilter}
            onFilter={value => {
                onFilter(value, AIReportsFilters.Completed);
            }}
        />
        // </Grid>,
    );

    const columns = [
        {
            field: 'id',
            headerName: 'Case Number',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'aireport.dashboard.cell.toggleAIReportModel.' + params.row.id}
                                variant="text"
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setSubmissionId(params.row.id);
                                    setSelectedSubmissionId(params.row.id);
                                    setTriggerShowAiReportModal(true);
                                }}
                            >
                                {params.row.id}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patientName',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'providerName',
            headerName: 'Provider',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'statusId',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                var a1 = _.find(aiStatus, (a) => { return a.name == param1.value });
                var a2 = _.find(aiStatus, (a) => { return a.name == param2.value });
                var ad1 = a1 ? (a1?.name) : "";
                var ad2 = a2 ? (a2?.name) : "";
                return ad1.localeCompare(ad2);
            },
            valueOptions: aiStatus.map((a) => { return a.name }),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            var advisor = _.find(aiStatus, (a) => { return (a.name) === filterItem.value });
                            if (!_.isNull(advisor) && !_.isUndefined(advisor)) {
                                return advisor.id === value;
                            }
                            return false;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            renderCell: params => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'aiReportStatus.dashboard.cell.statusId.' + params.row.ID}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Status"
                            value={params.row.statusId ? params.row.statusId : 0}
                            onChange={e => {
                                params.row.statusId = e.target.value;
                                handleStatusChange(params.row.id, e.target.value);
                            }}
                        >
                            {aiStatus.map((item: any) => (
                                <MenuItem key={item.id + item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            },
        },
        {
            field: 'reportTypeStatus',
            headerName: 'Report Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <figure style={{ zIndex: 100 }} className="gridFigure">
                        {params.row.reportType === 'Standard AI Report' && (
                            <img src={StandardSvg} style={{ width: "35px", height: "35px" }} className={'svgGrid'} />
                        )}
                        {params.row.reportType === 'Comparison AI Report' && (
                            <img
                                src={ComparisonSvg}
                                className={'svgGrid '}
                                style={{ filter: 'hue-rotate(90deg)', width: "35px", height: "35px" }}
                            />
                        )}
                        <figcaption>
                            <b>{params.row.reportType}</b>
                            <br />
                            <b>Accepted On:</b> {params.row.createdOn}
                            <br />
                            <b>Updated On:</b> {params.row.modifiedOn}
                            <br />
                            <b>Status:</b> {params.row.status}
                        </figcaption>
                    </figure>
                );
            },
        },
        {
            field: 'aiReport',
            headerName: 'AddOn Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                let addons = JSON.parse(params.row.addOns);
                if (addons === null) {
                    addons = [];
                }
                return (
                    <figure style={{ zIndex: 100 }}>
                        {addons.map((item: any) => {
                            if (item.IsActive === true) {
                                if (item.AddOnName === 'Radiology') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >
                                            <img style={{ width: "35px", height: "35px" }} src={RadiologySvg} />
                                        </HtmlTooltip>
                                    );
                                }

                                if (item.AddOnName === 'Sassouni') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >
                                            <img style={{ width: "35px", height: "35px" }} src={SassouniImg} />
                                            {/* <SassouniSvg width={35} height={35}></SassouniSvg> */}
                                        </HtmlTooltip>
                                    );
                                }

                                if (item.AddOnName === '3D Printed Airway') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >

                                            <img style={{ width: "35px", height: "35px" }} src={Print3DSvg} />
                                        </HtmlTooltip>
                                    );
                                }

                                if (item.AddOnName === 'Frontal Analysis') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >
                                            <img style={{ width: "35px", height: "35px" }} src={FrontalAnalysis} />
                                        </HtmlTooltip>
                                    );
                                }
                            }
                        })}
                    </figure>
                );
            },
        },
        {
            field: 'technicianId',
            type: 'singleselect',
            sortComparator: (v1, v2, param1, param2) => {
                var a1 = _.find(technician, (a) => { return a.userID == param1.value });
                var a2 = _.find(technician, (a) => { return a.userID == param2.value });
                var ad1 = (a1?.firstName + " " + a1?.lastName);
                var ad2 = (a2?.firstName + " " + a2?.lastName);
                return ad1.localeCompare(ad2);
            },
            valueOptions: technician.map((a) => { return a.firstName + " " + a.lastName }),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            var advisor = _.find(technician, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
                            if (!_.isNull(advisor) && !_.isUndefined(advisor)) {
                                return advisor.userID === value;
                            }
                            return false;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            headerName: 'Technician',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'aiReportTechnician.dashboard.cell.assignedTechnicianId.' + params.row.ID}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Select a Technician"
                            value={params.row.technicianId ? params.row.technicianId : 0}
                            onChange={e => {
                                params.row.technicianId = e.target.value;
                                handleChangeTechnician(params.row.id, e.target.value);
                            }}
                        >
                            <MenuItem key={0} value={0}>
                                {'Select a Technician...'}
                            </MenuItem>
                            {technician.map((item: any) => (
                                <MenuItem
                                    key={item.userID + item.lastName}
                                    value={item.userID}
                                    disabled={!item.isActive}
                                >
                                    {item.firstName + ' ' + item.lastName}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            },
        },
        // {
        //     field: 'radiologist',
        //     headerName: 'Radiologist',
        //     flex: 1,
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     align: 'center',
        // },
        {
            field: 'daysInQueue',
            headerName: 'Days in Queue',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                const days = moment(new Date()).diff(params.row.createdOn, "days");
                const statusstyle = days <= DAYSINQUEUEOK ? "statusgreen" : (days > DAYSINQUEUEOK && days <= DAYSINQUEUEWARN) ? "statusorange" : "statusred";
                return (
                    <Grid className={statusstyle}>
                        {moment(new Date()).diff(params.row.createdOn, "days")}
                    </Grid>
                );
            },
        },
    ];

    return (
        <>
            <PageTitleWrapper>
                <PageHeader
                    numCompletedReports={0}
                    onCompletedFilterClick={undefined}
                    completedFilter={0}
                    currentFilter={undefined}
                >

                </PageHeader>
            </PageTitleWrapper>
            <Grid container paddingX={4} >
                <Grid item xs={12}>
                    <Grid container spacing={2} padding={2}>
                        {infoBoxes}
                    </Grid>
                </Grid>
                <Grid item xs={12} padding={2}>
                    <SearchBar
                        onChange={getSearchData}
                        onClear={clearData}
                        onCancel={clearData}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid id={'aireporttech.dashboard' + userID} paddingX={1}>
                        {isLoading && <LinearProgress variant="determinate" value={loadingProgress} />}
                        <DataGrid columns={columns} loading={isLoading} data={patientData} />
                    </Grid>
                </Grid>
            </Grid>
            <ModalContainer
                noScroll={true}
                show={triggerShowAiReportModal}
                title={'AI Report Data Entry'}
                onClose={() => {
                    setTriggerShowAiReportModal(false);
                    handleModalClose();
                }}
                onClick={() => { }}
            >
                <AIReportEntry
                    aiReport={selectedRow}
                    submissionId={selectedSubmissionId}
                    onClose={() => { }}
                    showCSVModal={showCSVModal}
                />
            </ModalContainer>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box sx={modalStyle}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h6">Please Paste the Dolphin values</Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="right">
                            <IconButton onClick={() => setShowModal(false)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box
                        style={{
                            width: '100%',
                            backgroundColor: 'white',
                            height: '100%',
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <TextareaAutosize
                            onChange={ev => setModalText(ev.target.value)}
                            style={{ width: 700 }}
                            minRows={17}
                            maxRows={17}
                        />
                    </Box>
                    <Grid container style={{ backgroundColor: 'white' }}>
                        <Grid item xs={12} display="flex" justifyContent="right">
                            {isSaving &&
                                <LoadingButton className={"vivButton large"}
                                    color="secondary"
                                    variant="outlined"
                                    loading={isSaving}
                                    loadingPosition="start"
                                    loadingIndicator="Parsing CSV Data"
                                >

                                </LoadingButton>}
                            {!isSaving &&
                                <Box padding={1}>
                                    <Button variant='contained' onClick={() => {

                                        parseCSV(modalText);
                                    }}>
                                        Save
                                    </Button></Box>}

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
export const AIReports = observer(AIReportsComponent);
