import React, { useEffect, useMemo, useState } from 'react';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { observer } from 'mobx-react';
import { Serializer, settings as sett1 } from 'survey-core';
import { settings } from 'survey-creator-core';
import * as _ from 'lodash';

// @store
import { useMainStoreContext } from '../../../stores/MainStore';
import { toJS } from 'mobx';
import { convertTypes } from '../../../lib';
import { isNull } from 'lodash';
import { CustomFormModal } from '../CustomFormsModal';
import { Gateway } from '../../../api/Gateway';

interface EditQuestionSurveyInterface {
    question: any;
    onClose: (value: boolean) => void;
    categories: any;
    mappingTables: any;
}

export const EditQuestionSurvey: React.FC<EditQuestionSurveyInterface> = observer(
    ({ question, categories, mappingTables, onClose }) => {
        let { updateQuestion, loadMappingTables } = useMainStoreContext().customFormStore;

        let propertyStopList = ['category', 'mappedTable', 'mappedColumn', 'description', 'name', 'title', 'titleLocation', 'widthMode', 'startWithNewLine'];
        const [showModal, setShowModal] = useState(false);
        const [detailQuestion, setDetailQuestion] = useState<any>({});
        const [tableName, setTableName] = useState("");
        useEffect(() => {
            setDetailQuestion(toJS(question));
            detailQuestion.hideNumber = true;
            creator.onShowingProperty.add(function (sender, options) {
                checkShowingProperty(options);
            });
        }, [question]);

        useEffect(() => {
            var type = detailQuestion.type;
            if (type === 'imagepicker') {
                detailQuestion.titleLocation = 'top';
                detailQuestion.description = 'Select an image';
                detailQuestion.showLabel = true;
                propertyStopList.push('imageWidth');
                propertyStopList.push('multiSelect');
                propertyStopList.push('choices');
            }

            if (type === 'text') {
                propertyStopList.push('suffix');
                propertyStopList.push('inputType');
            }

            if (type === 'comment') {
                propertyStopList.push('rows');
                propertyStopList.push('cols');
                propertyStopList.push('autogrow');
            }

            if (type === 'checkbox' || type === 'radiogroup' || type === 'imagepicker') {
                propertyStopList.push('colCount');
                propertyStopList.push('choices');
                detailQuestion.colCount = question.colCount;
                detailQuestion.columns = question.columns;
            }

            if (type === 'rating') {
                propertyStopList.push('rateMax');
            }

            if (type === 'ranking') {
                propertyStopList.push('choices');
            }

            if (type === 'signaturepad') {
                propertyStopList.push('signatureWidth');
                propertyStopList.push('width');
            }

            creator.onShowingProperty.add(function (sender, options) {
                checkShowingProperty(options);
            });
        }, [detailQuestion]);

        const creator = useMemo(() => {
            const creatorOptions = {
                isAutoSave: false,
                questionTypes: ['text', 'checkbox', 'radiogroup', 'comment', 'rating', 'signaturepad', 'imagepicker', 'ranking'],
                showTestSurveyTab: true,
                showJSONEditorTab: false,
                showLogicTab: false,
                showSidebar: false,
                showSurveyTitle: false,
                showSettings: true,
                showAddQuestionButton: false,
            };

            return new SurveyCreator(creatorOptions);
        }, []);


        const checkShowingProperty = (options) => {
            if (creator.JSON.elements?.length > 0) {
                const { type } = creator.JSON.elements[0];

                if (type === 'text') {
                    options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
                    return;
                }

                if (options.property.name === 'suffix') {
                    options.canShow = false;
                    return;
                } else {
                    options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
                    return;
                }
            } else {
                options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
            }
        };

        creator.onShowingProperty.add(function (sender, options) {
            checkShowingProperty(options);
        });

        Serializer.findProperty("checkbox", "hasSelectAll").visible = false;
        Serializer.findProperty("checkbox", "hasNone").visible = false;
        Serializer.findProperty("checkbox", "hasOther").visible = false;

        Serializer.addProperty('question', {
            name: 'category:dropdown',
            category: 'general',
            choices: categories,
        });

        Serializer.addProperty('question', {
            name: 'mappedTable',
            category: 'Mapping',
            choices: function () {

                var choices: any[] = [];
                _.forEach(mappingTables, (t) => {
                    choices.push({ id: t.id, value: `${t.tableSchema}.${t.valueTableName}` });
                });

                return choices;
            }
        });

        Serializer.addProperty('question', {
            name: 'mappedColumn',
            visibleIndex: 1,
            dependsOn: ["mappedTable"],
            category: 'Mapping',
            choices: function (obj, choicesCallback) {
                if (!choicesCallback || !obj || !obj.mappedTable || tableName === obj.mappedTable) {
                    return;
                }
                var table = _.filter(mappingTables, (m) => { return `${m.tableSchema}.${m.valueTableName}` === obj.mappedTable });
                if (table && table.length > 0) {
                    var propertyChoices: any[] = [];
                    var choices: any[] = [];
                    var promises: any[] = [Gateway.get(`questionnaire/form/properties/${table[0].value}`).then((resp) => {
                        _.forEach(resp, (col) => {
                            propertyChoices.push({ id: col.id.toString(), value: col.name });
                            choices.push({ text: col.name, value: col.id.toString() });
                        });
                    })];

                    Promise.all(promises).then(() => {
                        propertyChoices = _.orderBy(propertyChoices, ['value'], ['asc']);
                        choices = _.orderBy(choices, ['text'], ['asc']);
                        choicesCallback(propertyChoices);
                        // var type = creator.survey.pages[0].elements[0];
                        // if (objectTypeOf(type) === "QuestionCheckboxModel" || objectTypeOf(type) === "QuestionRadiogroupModel") {
                        //     if (!creator.survey.pages[0].elements[0]["mappedColumn"] || creator.survey.pages[0].elements[0]["mappedColumn"] === "") {
                        //         creator.survey.pages[0].elements[0]["choices"] = choices;
                        //         return choices;
                        //     }
                        // }
                    });
                }
            }
        });

        Serializer.addProperty('question', {
            name: 'suffix:text',
            category: 'general',
            default: detailQuestion.suffix,
            onSetValue: (survey, value) => {
                survey.setPropertyValue("suffix", value);
            }
        });

        creator.JSON = {
            title: '',
            logoPosition: 'right',
            pageEditMode: 'single',
            elements: [
                {
                    type: convertTypes(detailQuestion.type) || detailQuestion.type,
                    name: detailQuestion.text,
                    title: detailQuestion.title,
                    titleLocation: detailQuestion.titleLocation,
                    hideNumber: true,
                    //choices: detailQuestion.type !== 'rating' ? []: detailQuestion.choices,
                    choices: detailQuestion.choices,
                    rateValues: detailQuestion.type === 'rating' ? detailQuestion.choices : [],
                    //inputType: convertDisplayType(detailQuestion.type) || detailQuestion.inputType,
                    showLabel: detailQuestion.showLabel,
                    isRequired: detailQuestion.isRequired,
                    description: detailQuestion.description,
                    id: detailQuestion.id,
                    rateMax: detailQuestion.rateMax,
                    colCount: detailQuestion.colCount ? detailQuestion.colCount : null,
                    columns: detailQuestion.columns ? detailQuestion.columns : null,
                    multiSelect: detailQuestion.multiSelect ? detailQuestion.multiSelect : null,
                    rows: detailQuestion.rows ? detailQuestion.rows : null,
                    cols: detailQuestion.cols ? detailQuestion.cols : null,
                    suffix: detailQuestion.suffix ? detailQuestion.suffix : null,
                    mappedTable: detailQuestion.mappedTable ? detailQuestion.mappedTable : null,
                    mappedColumn: detailQuestion.mappedColumn ? detailQuestion.mappedColumn : null,
                    width: detailQuestion.width ? detailQuestion.width : null,
                    signatureWidth: detailQuestion.signatureWidth ? detailQuestion.signatureWidth : null,
                    startWithNewLine: detailQuestion.startWithNewLine ? detailQuestion.startWithNewLine : null,
                    height: detailQuestion.height ? detailQuestion.height : null,
                    imageWidth: detailQuestion.imageWidth ? detailQuestion.imageWidth : null,
                    widthMode: detailQuestion.widthMode ? detailQuestion.widthMode : null,
                    category: detailQuestion.categoryId ? _.find(categories, (c) => { return c.id === detailQuestion.categoryId }).value : null,
                },
            ],
        };

        settings.designer.showAddQuestionButton = false;
        sett1.allowShowEmptyTitleInDesignMode = false;
        // creator.toolbox.forceCompact = false;
        // creator.pageEditMode = 'standard';

        creator.pageEditMode = 'single';

        // creator.toolbox.forceCompact = true;

        creator.onQuestionAdded.add((sender, options) => {
            const question = options.question;

            const page = question.page;

            if (sender.JSON.elements.length > 1) {
                page.removeElement(question);
                return;
            }

            const type = question.getType();

            question.hideNumber = true;
            question.titleLocation = 'left';
            question.suffix = '';
            question.category = '';

            if (type === 'imagepicker') {
                question.titleLocation = 'top';
                question.description = 'Select an image';
                question.showLabel = true;
                propertyStopList.push('imageWidth');
                propertyStopList.push('multiSelect');
                propertyStopList.push('choices');
            }

            if (type === 'text') {
                propertyStopList.push('suffix');
                propertyStopList.push('inputType');
            }

            if (type === 'comment') {
                propertyStopList.push('rows');
                propertyStopList.push('cols');
                propertyStopList.push('autogrow');
            }

            if (type === 'checkbox' || type === 'radiogroup' || type === 'imagepicker') {
                propertyStopList.push('colCount');
            }

            if (type === 'rating') {
                propertyStopList.push('rateMax');
            }

            if (type === 'signaturepad') {
                propertyStopList.push('signatureWidth');
                propertyStopList.push('width');
            }

            question.title = type[0].toUpperCase() + type.substring(1);
            question.name = question.title;
        });

        creator.onElementAllowOperations.add((sender, options) => {
            let obj = options.obj;

            if (!obj || !obj.page) return;

            //enable edit
            options.allowEdit = true;
            //disable change show/hide title
            options.allowShowHideTitle = false;
            //disable copying
            options.allowCopy = false;
            //disable changing type
            options.allowChangeType = false;
            //Enable/disable element drag&drop
            options.allowDragging = false;
        });

        useEffect(() => {
            if (!mappingTables || mappingTables.length === 0) {
                loadMappingTables();
            }
            let showPanel = creator.toolbarItems.filter(x => x.id === 'svd-settings')[0];
            if (showPanel) {
                creator.toolbarItems.splice(creator.toolbarItems.indexOf(showPanel), 2);
            }

            creator.toolbox.addItems([
                {
                    id: 'country',
                    name: 'date',
                    isCopied: true,
                    iconName: 'icon-default',
                    title: 'Date',
                    category: 'Custom Question',
                    json: {
                        type: 'text',
                        inputType: 'date',
                        titleLocation: 'left',
                        hideNumber: true,
                    },
                },
                {
                    id: 'checkboxHorizontal',
                    name: 'checkboxHorizontal',
                    isCopied: true,
                    iconName: 'icon-default',
                    title: 'Checkbox Horizontal',
                    category: 'Custom Question',
                    json: {
                        type: 'checkbox',
                        choices: ['item1', 'item2', 'item3'],
                        colCount: 0,
                        titleLocation: 'left',
                        hideNumber: true,
                    },
                },
            ]);
        }, [creator.toolbox]);

        creator.saveSurveyFunc = (saveNo: any, callback: any) => {
            const question = creator.JSON.elements[0];
            question.id = detailQuestion.id;
            question.startWithNewLine = detailQuestion.startWithNewLine;
            if (!isNull(creator.JSON.elements)) {
                question.categoryId = question.category;
                delete question.category;
                question.categoryId = categories.find(
                    x => x.value === creator.survey.pages[0].questions[0].category,
                )?.id;
                if (question.title) {
                    updateQuestion(question).then(resp => {
                        onClose(false);
                    });
                    callback(saveNo, true);
                } else {
                    setShowModal(true);
                }
            }
        };

        creator.toolbox.getItemByName('text').title = 'Single-Line Text';
        creator.toolbox.getItemByName('checkbox').iconName = 'icon-checked';

        return (
            <>
                <SurveyCreatorComponent creator={creator} />
                <CustomFormModal isOpen={showModal} onClose={() => setShowModal(false)} title="Error">
                    {'Question title is required.'}
                </CustomFormModal>
            </>
        );
    },
);
