import { observer } from "mobx-react"
import { Gateway } from "../../api/Gateway";
import { useMainStoreContext } from "../../stores/MainStore";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

const StepThree = () => {
	const { currentPatient, selectedPlan, userID, setToggleShowMyoCorrectModal } = useMainStoreContext().myoCorrectEnrollmentStore;
    const { loadPatient } = useMainStoreContext().consultationData;
	const [ isEnrolling, setIsEnrolling ] = useState(false);

	const enroll = () => {
		if(!currentPatient) return;
		setIsEnrolling(true);
		Gateway.post('/patient/enroll', {
			patientId: currentPatient.id,
			planId: selectedPlan,
			userId: userID
		}).then(() => {
			loadPatient().then(() => {
				setToggleShowMyoCorrectModal(false);
			});
			setIsEnrolling(false);
		});
	};
	return <div>
		<LoadingButton loading={isEnrolling} onClick={enroll}>Enroll</LoadingButton>
	</div>
}
export default observer(StepThree);