// Vendors
import React from "react";
import { Grid } from "@mui/material";

// Components
import { PatientReworkDashboard } from "./SubmitReworkModals/PatientReworkDashboard";
import { PatientPrescriptionDashboard } from "./SubmitReworkModals/PatientPrescriptionDashboard";

// Stores
import { useMainStoreContext } from "../../../stores/MainStore";

// Entities
import ProviderPatient from "../../../entities/ProviderPatient";

// API
import { observer } from "mobx-react";
import _ from "lodash";

interface IGIProps {
    patient: ProviderPatient;
}


export const PatientApplianceTherapy: React.FC<IGIProps> = observer(({ patient }) => {

    const tabs = [
        'Prescriptions',
        'Rework Claims'
    ];
    let store = useMainStoreContext().reworkClaimStore;

    return (
        <>
            <Grid xs={3} className="tabSubSection">
                <Grid xs={11} className="tabs" container direction={'row'}>
                    {_.map(tabs, (t, key: number) => {
                        return (
                            <Grid
                                key={key}
                                className={'tab' + (store.activePatientApplianceTab === key ? ' active' : '')}
                                onClick={() => {
                                    store.setActivePatientApplianceTab(key);
                                }}
                            >
                                {t}
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>

            <Grid className={"tabContent"} xl={12} >
                {store.activePatientApplianceTab === 0 && (
                    <PatientPrescriptionDashboard patient={patient} />
                )}
                {store.activePatientApplianceTab === 1 && (
                    <PatientReworkDashboard patient={patient} />
                )}
            </Grid>
        </>
    )
}
);

