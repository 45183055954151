import { Divider, Grid, MenuItem, TextareaAutosize } from "@mui/material";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite"
import moment from "moment";
import React from "react";
import { FC } from "react"
import { FileUploader } from "react-drag-drop-files";
import { Button } from "reactstrap";
import Claim from "../../entities/Claim";
import _ from "lodash";
import { useMainStoreContext } from "../../stores/MainStore";
import CssSelectField from "../CssSelectField";
import CssTextField from "../CssTextField";
import { ClaimType } from "../../entities/Enums";

interface IGIProps {
    claim: Claim;
}

export const LabInvestigation: FC<IGIProps> = observer(({claim}) => {
    
    const fileTypes = ["JPG", "PNG", "JPEG"]; 
    const CLAIMDOCUMENTTYPE = 789;
    const [filesContent, setFilesContent] = React.useState<File[]>([]);
    const[showTextArea, setShowTextArea]= React.useState(claim.labRemakeNeeded ? claim.labRemakeNeeded : false);
    const [dateValue, setDateValue] = React.useState<Date | null>(claim.labClaimMaterialsReceivedOn);
    const [investigationText, setInvestigationText] = React.useState(claim.labInvestigationNotes ? claim.labInvestigationNotes : '');
    const [remakeText, setRemakeText] = React.useState(claim.labRemakeNotes ? claim.labRemakeNotes : '');
    const [applianceStatus, setApplianceStatus] = React.useState<any>(claim.labClaimMaterialApplianceStatus ? claim.labClaimMaterialApplianceStatus : 595);
    const [modelStatus, setModelStatus] = React.useState<any>(claim.labClaimMaterialModelStatus ? claim.labClaimMaterialModelStatus : 712);
    const [biteStatus, setBiteStatus] = React.useState<any>(claim.labClaimMaterialBiteStatus ? claim.labClaimMaterialBiteStatus : 708);
    const [labInvRecordedBy, setlabInvRecordedBy] = React.useState("");

    let store = useMainStoreContext().reworkClaimStore;

    React.useEffect(() => {
        let user = store.teamMembers.find(tm => tm.userID === store.claim.labInvestigationRecordedBy)
        setlabInvRecordedBy(user ? user.firstName + " " + user.lastName : "");
        store.loadReworkDocuments(claim.id);
    },[]);

    const updatedClaim = {
        ...claim,
        labInvestigationNotes : investigationText,
        labRemakeNotes : remakeText,
        labRemakeNeeded : showTextArea,
        labClaimMaterialApplianceStatus : applianceStatus,
        labClaimMaterialModelStatus : modelStatus,
        labClaimMaterialBiteStatus : biteStatus,
        labClaimMaterialsReceivedOn : dateValue ? dateValue : new Date(),
        labInvestigationRecordedOn : new Date(),
        labInvestigationRecordedBy: store.userID
    }

    const saveClaim = () => {
        let updateClaim = updatedClaim;
        store.updateClaimChanges(updateClaim);
        uploadFile();
    } 

    const sentResultsToVivos = () => {
        let duplicatedClaim = updatedClaim;

        if(claim.claimType === 590 ) // Warranty 
        { 
            duplicatedClaim.rxId = duplicatedClaim.rxId + "-1";
            store.createNewClaim(duplicatedClaim);
            //TODO: Notify provider
        }
        else if(claim.claimType === 591)  // repair
        {
            duplicatedClaim.claimStatus = 594; //Cancel
            store.updateClaimChanges(duplicatedClaim);
            //TODO: Notify provider
        }
    }

    const uploadFile = () => {
        if (filesContent.length > 0) {
            store.uploadProcessedImages(claim.id, store.processedImages);
        }
    };

    const handleChangeFile = (files) => {
        setFilesContent([...files]);
        var imglist: FileReader[] = store.processedImages;
        var promises: any[] = [];
        _.forEach(files, async (file) => {
            const promise = doWork(file, imglist);
            promises.push(promise);
        })
        Promise.all(promises).then(() => {
            store.setProcessedImages(imglist);
        })
    };

    async function doWork(file, imglist) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileUpload = {
                fileName: file.name,
                dataBody: (reader.result as string).split(',')[1],
                active: true,
                imageType: {
                    id: CLAIMDOCUMENTTYPE
                }
            };
            imglist.push(fileUpload);
        }
    }

    const deleteFile = (file) => {
        store.removeFile(file, claim.id);
    };

    return(
        <Grid className="claim-container">
            <Grid xs={12} className="claim-save-button">
                <Button className="vivButton normal" onClick={() => {saveClaim()}}> Save Changes </Button>
            </Grid>
            <Divider flexItem={true} orientation="horizontal" />
                <Grid xs={12} container direction={'row'} className="principal-claim-container">
                <Grid xs={6.3} className="box-claim-container">
                    <Grid item className="claim-data-title"> Lab Investigation Results </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={5}>
                            <Grid>Date manufacturer received claim </Grid>    
                            <Grid>{claim.labClaimReceivedOn && moment(claim.labClaimReceivedOn).format('MM/DD/yyyy')}</Grid>
                        </Grid>
                        <Grid xs={4}>
                            <Grid>Date manufacturer claim materials</Grid>                
                            <Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        className="DateBox"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={dateValue}
                                        onChange={(value) => {
                                            setDateValue(value);
                                        }}
                                        renderInput={params => (
                                            <CssTextField
                                                disabled={false}
                                                {...params}
                                                sx={{
                                                    '& input': {
                                                        backgroundColor: 'white',
                                                        color: '#050505',
                                                        fontFamily: 'Proxima Nova',
                                                        fontSize: '16px',
                                                    },
                                                }}
                                                className="TextBox"
                                                size="small"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid> 
                        <Grid xs={3}>
                            <Grid>Additional Documentation </Grid>                
                            <Grid>
                            <FileUploader 
                                label="Upload or drop a image here" 
                                name="file"
                                handleChange={handleChangeFile} 
                                multiple={true} 
                                types={fileTypes}
                                children={
                                    <Grid xs={12} container direction="column" className="claimuploadImgContainer">
                                        <>
                                            <Grid item>Drag & Drop Image(s) or</Grid>
                                            <Grid item>Click to Select Image(s)</Grid>
                                        </>
                                    </Grid >
                                }/>
                            </Grid>
                            {store.processedImages.length > 0 ? ( store.processedImages.map(f => (
                                <Grid xs={9} container direction="row" className="claimuploadImg">
                                    <Grid xs={6}>{f.fileName ? f.fileName : f.url}</Grid>
                                    <Grid xs={3}>
                                        <Button onClick={() => { deleteFile(f) } }><DoNotDisturbOnIcon/></Button>
                                    </Grid>
                                </Grid>
                            ))) : <></>}
                        </Grid>
                    </Grid>
                    <Grid className="claim-info">Remake Needed</Grid>
                    <Grid container direction={'row'}>
                        <Button className={!showTextArea ? "vivButton normal" : "vivButton invert"} onClick={() => {setShowTextArea(true)}}>Yes</Button>
                        <Button className={showTextArea ? "vivButton normal" : "vivButton invert"} onClick={() => {setShowTextArea(false)}}>No</Button>
                    </Grid>
                    {showTextArea && <Grid xs={12} className="claim-info">
                        <Grid>Investigation of the claim process and outcome</Grid>
                        <TextareaAutosize 
                            className="claim-textarea"
                            onChange={(e) => {setInvestigationText(e.target.value)}}
                            style={{ width: "-webkit-fill-available" }}
                            minRows={4}
                            maxRows={4}
                            value={investigationText}
                        />
                        <Grid>Remake reasoning</Grid>
                        <TextareaAutosize
                            className="claim-textarea"  
                            onChange={(e) => {setRemakeText(e.target.value)}}
                            style={{ width: "-webkit-fill-available" }}
                            minRows={4}
                            maxRows={4}
                            value={remakeText}
                        />
                    </Grid>}
                </Grid>
                <Grid xs={5.3} direction={'column'}>
                    <Grid className="box-claim-container">
                        <Grid className="claim-data-title">Claim Material Return Overview </Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={6}>
                                <Grid >Return Shipped Date</Grid>
                                <Grid className="claim-data-title">{claim.shippedOn && moment(claim.shippedOn).format('MM/DD/yyyy')}</Grid>
                            </Grid>
                            <Grid xs={6}>
                                <Grid>Tracking # Provider to Lab</Grid>
                                <Grid>{claim.trackingNoMaterialsToLab}</Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={4}>
                                <Grid>Appliance Status</Grid>
                                <CssSelectField
                                    className="DateBox"
                                    labelId="modelStatus"
                                    label="Model Status"
                                    font-size="10px/13px"
                                    size="small"
                                    defaultValue={595}
                                    value={applianceStatus} 
                                    onChange={event => {
                                        setApplianceStatus(event.target.value)
                                    }}>
                                    <MenuItem key={595} value={595}>{"Pending"}</MenuItem>
                                    <MenuItem key={596} value={596}>{"Needed"}</MenuItem>
                                    <MenuItem key={597} value={597}>{"Waiting on Opposing Appliance"}</MenuItem>
                                    <MenuItem key={598} value={598}>{"Received"}</MenuItem>
                                    <MenuItem key={599} value={599}>{"Cancelled"}</MenuItem>
                                </CssSelectField>                            
                                </Grid>
                            <Grid xs={4}>
                                <Grid>Model Status</Grid>
                                <CssSelectField
                                    className="DateBox"
                                    labelId="modelStatus"
                                    label="Model Status"
                                    font-size="10px/13px"
                                    size="small"
                                    defaultValue={712}
                                    value={modelStatus}
                                    onChange={event => {
                                        setModelStatus(event.target.value)
                                    }}>
                                    <MenuItem key={712} value={712}>{"Pending"}</MenuItem>
                                    <MenuItem key={713} value={713}>{"Hold"}</MenuItem>
                                    <MenuItem key={714} value={714}>{"Received"}</MenuItem>
                                    <MenuItem key={715} value={715}>{"Cancelled"}</MenuItem>
                                </CssSelectField>                            
                                </Grid>
                            <Grid xs={4}>
                                <Grid>Bite Status</Grid>
                                <CssSelectField
                                    className="DateBox"
                                    labelId="modelStatus"
                                    label="Model Status"
                                    font-size="10px/13px"
                                    size="small"
                                    defaultValue={708}
                                    value={biteStatus}
                                    onChange={event => {
                                        setBiteStatus(event.target.value)
                                    }}
                                >
                                    <MenuItem key={708} value={708}>{"Pending"}</MenuItem>
                                    <MenuItem key={709} value={709}>{"Hold"}</MenuItem>
                                    <MenuItem key={710} value={710}>{"Received"}</MenuItem>
                                    <MenuItem key={711} value={711}>{"Cancelled"}</MenuItem>
                                </CssSelectField>
                            </Grid>
                        </Grid>
                        <Grid className="claim-info">
                            <Grid>Warranty Remake Approval</Grid>
                            <Grid className="claim-data-title">Pending</Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className="box-claim-container">
                        <Grid className="claim-data-title">Claim ID</Grid>
                        <Grid xs={12} container direction={'row'} className="claim-info">
                            <Grid>Lab Investigation Recorded By: </Grid>
                            <Grid>{labInvRecordedBy}</Grid>
                        </Grid>
                        <Grid xs={12} container direction={'row'} className="claim-info">
                            <Grid>Date: </Grid>
                            <Grid>{claim.labInvestigationRecordedOn && moment(claim.labInvestigationRecordedOn).format('MM/DD/yyyy')}</Grid>
                        </Grid>
                    </Grid>
                    <Grid className="claim-save-button">
                        <Button 
                         className="vivButton normal invert"
                         disabled={((claim.claimType === ClaimType.Warranty) || (claim.claimType === ClaimType.Pending))} 
                         onClick={() => {sentResultsToVivos()}}>Send Investigation Results to Vivos</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
})