import React, { useState } from 'react';
import { Grid, Typography, Button, styled, Box, alpha } from '@mui/material';

interface ButtonOptionsProps {
  title: string;
  options: string[];
  values: any[];
  currentAppointmentNote: any;
  setCurrentAppointmentNoteProp: any;
}

const BoxComposed = styled(Box)(
  () => `
    position: relative;
  `
);
const BoxComposedContent = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 7;
    display: flex;
    align-items: center;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedBg = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const ButtonGroupingOptions: React.FC<ButtonOptionsProps> = ({
  title,
  options,
  values,
  currentAppointmentNote,
  setCurrentAppointmentNoteProp, }) => {

  const [renderKey, setRenderKey] = useState(0);

  const handleButtonClick = (index: number) => {
    const currentValue = parseInt(currentAppointmentNote[values[index]]?.response);
    const newValue = currentValue === 1 ? 0 : 1;
    setCurrentAppointmentNoteProp(newValue, values[index]);

    // Update the renderKey to trigger a re-render
    setRenderKey(renderKey + 1);
  };


  return (
    <Grid container direction="column" margin={1} padding={1}>
      <BoxComposed
        sx={(theme) => ({
          height: `75px`,
          borderRadius: `${theme.general.borderRadius}`,
          background: `${theme.colors.gradients.black2}`
        })}
      >

        <BoxComposedBg
          sx={(theme) => ({
            opacity: 0.1,
            background: `${theme.colors.gradients.black1}`
          })}

        />
        <BoxComposedContent py={3} pt={0} pb={0} height={'75px'}>
          <Typography
            textAlign="left"
            sx={{
              ml: 4,
            }}
            variant="h3"
          >
            {title}
          </Typography>
        </BoxComposedContent>
      </BoxComposed>
      <Grid container spacing={2} alignItems={'center'} padding={1}>
        {options.map((option, index) => (
          <Grid key={index} item xs={4} md={3}>
            <Button
              style={{ height: '60px', padding: .5 }}
              variant={parseInt(currentAppointmentNote[values[index]]?.response) === 1 ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick(index)}
              color="primary"
              fullWidth
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ButtonGroupingOptions;



// <Grid xs={2} container direction="row" justifyContent='center' className="notesRow pink"><Radio className={currentAppointmentNote['asthma']?.markAsEdited && currentAppointmentNote['asthma']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
// <Grid xs={2} container direction="row" justifyContent='center' className="notesRow green"><Radio className={currentAppointmentNote['asthma']?.markAsEdited && currentAppointmentNote['asthma']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
