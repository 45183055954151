interface IUser{
    userID: number,
    firstName: string,
    lastName: string
}

interface User extends IUser{}

class User{
    constructor({userID,firstName,lastName}:IUser){
        this.userID = userID;
        this.firstName = firstName;
        this.lastName = lastName;
    }

}

export default User;