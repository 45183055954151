import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    styled,
    Box,
    alpha,
} from '@mui/material';
import SelectableImage from './SelectableImage';
import tongue0 from '../../../imgs/tongue/tongue0.png';
import tongue1 from '../../../imgs/tongue/tongue1.png';
import tongue2 from '../../../imgs/tongue/tongue2.png';
import tongue3 from '../../../imgs/tongue/tongue3.png';
import tongue4 from '../../../imgs/tongue/tongue4.png';
import tongue5 from '../../../imgs/tongue/tongue5.png';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

const imageUrls = [
    tongue0,
    tongue1,
    tongue2,
    tongue3,
    tongue4,
    tongue5,
];

interface TongueGradingProps {
    title: string;
    valueString: string;
    currentAppointmentNote: any;
    setCurrentAppointmentNoteProp: any;
}

const BoxComposed = styled(Box)(
    () => `
    position: relative;
  `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
    position: relative;
    z-index: 7;
    display: flex;
    align-items: center;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedBg = styled(Box)(
    () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const TongueGradingComponent: React.FC<TongueGradingProps> = ({
    title,
    valueString,
    currentAppointmentNote,
    setCurrentAppointmentNoteProp,
}) => {

    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    useEffect(() => {
        // Set the initially selected image here
        const initialValue = parseInt(currentAppointmentNote[valueString]?.response);
        if (initialValue !== undefined && initialValue >= 0 && initialValue < imageUrls.length) {
            setSelectedImage(imageUrls[initialValue]);
        }
    }, [currentAppointmentNote, valueString]);

    const handleImageSelect = (imageUrl: string, newValue: number) => {
        setSelectedImage(imageUrl);
        setCurrentAppointmentNoteProp(newValue, valueString);
    };

    const [renderKey, setRenderKey] = useState(0);

    const handleButtonClick = (newValue) => {
        setCurrentAppointmentNoteProp(newValue, valueString);
        // Update the renderKey to trigger a re-render
        setRenderKey(renderKey + 1);
    };


    return (
        <Grid container direction="column" margin={1} padding={1}>
            <Grid item xs={12}>
                <BoxComposed
                    sx={(theme) => ({
                        height: `75px`,
                        borderRadius: `${theme.general.borderRadius}`,
                        background: `${theme.colors.gradients.black2}`
                    })}
                >
                    <BoxComposedBg
                        sx={(theme) => ({
                            opacity: 0.1,
                            background: `${theme.colors.gradients.black1}`
                        })}

                    />
                    <BoxComposedContent py={3} pt={0} pb={0} height={'75px'}>
                        <Typography
                            textAlign="left"
                            sx={{
                                ml: 4,
                            }}
                            variant="h3"
                        >
                            {title}
                        </Typography>
                    </BoxComposedContent>
                </BoxComposed>
            </Grid>
            <Grid xs={12} padding={2} px={5}>
                <Grid container spacing={2}>
                    {imageUrls.map((imageUrl, index) => (
                        <Grid item xs={4} key={index} height={'150px'}>
                            <SelectableImage
                                markAsEdited={currentAppointmentNote[valueString]?.markAsEdited &&
                                    parseInt(currentAppointmentNote[valueString]?.response) === index}
                                imageUrl={imageUrl}
                                imageIndex={index}
                                selected={imageUrl === selectedImage}
                                onSelect={() => handleImageSelect(imageUrl, index)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TongueGradingComponent;



// <Grid xs={2} container direction="row" justifyContent='center' className="notesRow pink"><Radio className={currentAppointmentNote['asthma']?.markAsEdited && currentAppointmentNote['asthma']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
// <Grid xs={2} container direction="row" justifyContent='center' className="notesRow green"><Radio className={currentAppointmentNote['asthma']?.markAsEdited && currentAppointmentNote['asthma']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
