import { action, computed, makeAutoObservable, observable } from 'mobx';
import jwt_decode from 'jwt-decode';

export class GridHeaderBL {
    @observable _searchHeaderExpanded: boolean = false;
    @observable _gridHeaderExpanded: boolean = false;
    @observable _treatmentStatus: number;
    @observable _myoCorrectStatus: number;
    @observable _aiReportStatus: number;
    @observable _isProspectiveStatus: number;
    @observable _isProspectiveChecked: boolean;
    @observable _treatmentChecked: boolean;
    @observable _myCorrectChecked: boolean;
    @observable _aiReportChecked: boolean;
    @observable _textSearch: string;
    @observable _searchText: any;
    @observable _providerID: number = 0;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; providerId } = jwt_decode(token);
            this.providerID = tokenData.providerId;
        }

        this.clearTextInput = this.clearTextInput.bind(this);
        this._treatmentStatus = -1;
        this._myoCorrectStatus = -1;
        this._aiReportStatus = -1;
        this._isProspectiveStatus = -1;
        this._isProspectiveChecked = false;
        this._treatmentChecked = false;
        this._myCorrectChecked = false;
        this._aiReportChecked = false;
        this._textSearch = '';
    }

    @computed
    get searchHeaderExpanded() {
        return this._searchHeaderExpanded;
    }

    set searchHeaderExpanded(value: boolean) {
        this._searchHeaderExpanded = value;
    }

    @computed
    get gridHeaderExpanded() {
        return this._gridHeaderExpanded;
    }

    set gridHeaderExpanded(value: boolean) {
        this._gridHeaderExpanded = value;
    }

    @computed
    get textSearch() {
        return this._textSearch;
    }

    set textSearch(value: string) {
        this._textSearch = value;
    }

    @computed
    get providerID() {
        return this._providerID;
    }

    set providerID(value: number) {
        this._providerID = value;
    }

    @computed
    get isProspectiveStatus() {
        return this._isProspectiveStatus;
    }

    set isProspectiveStatus(value: number) {
        this._isProspectiveStatus = value;
    }

    @computed
    get isProspectiveChecked() {
        return this._isProspectiveChecked;
    }

    set isProspectiveChecked(value: boolean) {
        this._isProspectiveChecked = value;
    }

    @computed
    get treatmentStatus() {
        return this._treatmentStatus;
    }

    set treatmentStatus(value: number) {
        this._treatmentStatus = value;
    }

    @computed
    get treatmentChecked() {
        return this._treatmentChecked;
    }

    set treatmentChecked(value: boolean) {
        this._treatmentChecked = value;
    }

    @computed
    get myoCorrectStatus() {
        return this._myoCorrectStatus;
    }

    set myoCorrectStatus(value: number) {
        this._myoCorrectStatus = value;
    }

    @computed
    get myCorrectChecked() {
        return this._myCorrectChecked;
    }

    set myCorrectChecked(value: boolean) {
        this._myCorrectChecked = value;
    }

    @computed
    get aiReportStatus() {
        return this._aiReportStatus;
    }

    set aiReportStatus(value: number) {
        this._aiReportStatus = value;
    }

    @computed
    get searchText() {
        return this._searchText;
    }

    set searchText(value: any) {
        this._searchText = value;
    }

    @computed
    get aiReportChecked() {
        return this._aiReportChecked;
    }

    set aiReportChecked(value: boolean) {
        this._aiReportChecked = value;
    }

    clearTextInput = () => {
        this.textSearch = '';
        this._searchText.current.value = '';
    };

    @action
    onChange = (event: any) => {
        this.textSearch = event.target.value;
    };

    @action
    onFilterCheck = (name: string, value: any) => {
        if (name === 'Treatment Status') {
            this.treatmentChecked = value as unknown as boolean;
        } else if (name === 'AI Report Status') {
            this.aiReportChecked = value as unknown as boolean;
        } else if (name === 'MyoCorrect Status') {
            this.myCorrectChecked = value as unknown as boolean;
        } else if (name === 'Is Prospective') {
            this.isProspectiveChecked = value as unknown as boolean;
        }
        this.onFilterChange(name, value, value === true ? 0 : -1);
    };

    @action
    onFilterChange = (name: string, checked: boolean, value: any) => {
        if (name === 'Treatment Status') {
            if (checked === true) {
                this.treatmentStatus = Number.parseInt(value);
            } else {
                this.treatmentStatus = -1;
            }
        } else if (name === 'AI Report Status') {
            if (checked === true) {
                this.aiReportStatus = Number.parseInt(value);
            } else {
                this.aiReportStatus = -1;
            }
        } else if (name === 'MyoCorrect Status') {
            if (checked === true) {
                this.myoCorrectStatus = Number.parseInt(value);
            } else {
                this.myoCorrectStatus = -1;
            }
        } else if (name === 'Is Prospective') {
            if (checked === true) {
                this.isProspectiveStatus = Number.parseInt(value);
            } else {
                this.isProspectiveStatus = -1;
            }
        }
    };

    @action
    onFilterCancel = () => {
        this.treatmentChecked = false;
        this.aiReportChecked = false;
        this.myCorrectChecked = false;
        this.treatmentStatus = -1;
        this.myoCorrectStatus = -1;
        this.aiReportStatus = -1;
    };

    @action
    onFilter = (event: any): any => {
        var filters = {
            treatmentStatus: this.treatmentStatus,
            myoCorrectStatus: this.myoCorrectStatus,
            aiReportStatus: this.aiReportStatus,
            isProspective: this.isProspectiveStatus
        };
        return filters;
    };

    @action
    expandSearch = () => {
        this.searchHeaderExpanded = !this.searchHeaderExpanded;
    };
}
