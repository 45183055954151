// Vendors
import { useState } from "react";
import { Grid, Tab, Tabs, Typography, Box } from "@mui/material";

// Components
import Epworth from "../../../components/IntakeReports/Epworth";
import HeadacheImpact from "../../../components/IntakeReports/HeadacheImpact";
import HeadNeck from "../../../components/IntakeReports/HeadNeck";
import PerceivedStress from "../../../components/IntakeReports/PerceivedStress";
import StopBang from "../../../components/IntakeReports/StopBang";
import SleepStudy from "../../../components/IntakeReports/SleepStudy";
import SleepTest from "../../../components/ImagesAndDocs/SleepTest";


interface ComponentProps {
  visitId: number
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`report-info-tabpanel-${index}`}
      aria-labelledby={`report-info-tab-${index}`}
      style={{ maxHeight: "calc(100vh - 600px)", minHeight: "100px", overflowY: "auto", overflowX: "hidden" }}
      {...other}
    >
      {value === index && (
        <Grid sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Grid>
      )}
    </div>
  );
}


function a11yProps(index: number) {
  return {
    id: `report-info-tab-${index}`,
    'aria-controls': `report-info-tabpanel-${index}`,
  };
}

const PATIENTINTAKE = 0;
const HEADNECK = 1;
const SLEEPSTUDY = 2;
const EPWORTH = 3;
const PSQ = 4;
const STOPBANG = 5;
const HIT6 = 6;


function AIReportInfo(props: ComponentProps): JSX.Element {
  const { visitId } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (

    <Box sx={{ width: "100%", height: "100%" }} padding={2}>
      <Box sx={{ borderBottom: 0 }}>
        <Tabs className="tabs"
          value={value}
          onChange={handleChange}
          aria-label="inner report info tabs"
        >
          <Tab label="Patient Intake" {...a11yProps(EPWORTH)} />
          <Tab label="Head & Neck" {...a11yProps(HEADNECK)} />
          <Tab label="Sleep Study" {...a11yProps(SLEEPSTUDY)} />
          <Tab label="Epworth" {...a11yProps(EPWORTH)} />
          <Tab label="PSQ" {...a11yProps(PSQ)} />
          <Tab label="Stop-Bang" {...a11yProps(STOPBANG)} />
          <Tab label="HIT-6" {...a11yProps(HIT6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={PATIENTINTAKE}>
        <HeadNeck visitId={visitId} />
      </TabPanel>
      <TabPanel value={value} index={HEADNECK}>
        <HeadNeck visitId={visitId} />
      </TabPanel>
      <TabPanel value={value} index={SLEEPSTUDY}>
        {/* <SleepStudy visitId={visitId} /> */}
        <SleepTest isIntake={false} visit={undefined} />
      </TabPanel>
      <TabPanel value={value} index={EPWORTH}>
        <Epworth visitId={visitId} />
      </TabPanel>
      <TabPanel value={value} index={PSQ}>
        <PerceivedStress visitId={visitId} />
      </TabPanel>
      <TabPanel value={value} index={STOPBANG}>
        <StopBang visitId={visitId} />
      </TabPanel>
      <TabPanel value={value} index={HIT6}>
        <HeadacheImpact visitId={visitId} />
      </TabPanel>
    </Box>
  );
}

export default AIReportInfo;
