import React, { useEffect, useState } from 'react';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Typography
} from '@mui/material';
import { observer } from 'mobx-react';

import { useMainStoreContext } from '../../../stores/MainStore';
import PatientEditBL from '../PatientEditBL';
import { Oval } from 'react-loader-spinner';


interface IPatientCustomFormModal {
    isOpen: boolean;
    onClose: () => void;
    patientId?: number;
    providerId?: number;
}


export const PatientCustomFormModal: React.FC<IPatientCustomFormModal> = observer(
    ({ isOpen, onClose, patientId, providerId }) => {
        const { questionnairesData, loadQuestionnaireData, categories, loadQuestionCategories,
            checkCategorySendCustomForm, selectedCategories, clearSelected, selectedForms, checkFormsSendCustomForm } = useMainStoreContext().customFormStore;

        const [editBL] = React.useState(new PatientEditBL());

        const [selectedFormName, setSelectedFormName] = useState<string[]>([]);

        const sendFormObject = {
            customFormIds: selectedForms,
            patientId: patientId,
            providerId: Number(providerId),
        };

        useEffect(() => {
            if (!isOpen) {
                setSelectedFormName([]);
                clearSelected();
            }
            loadQuestionCategories();
            loadQuestionnaireData();
        }, [loadQuestionCategories, loadQuestionnaireData, isOpen]);

        return (
            <Dialog open={isOpen}>
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h3" gutterBottom>
                        Send Forms to Patient
                    </Typography>
                    <Typography variant="subtitle2">
                        Select categories then from the available forms.
                    </Typography>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        p: 3,
                    }}>
                    {editBL.isloading ? (
                        <Grid style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <Oval
                                height={60}
                                width={60}
                                color="#FF7C00"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#480141"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                            />
                        </Grid>
                    ) : (
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant='h5'>Select Category</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h5'>Select Forms</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{ paddingLeft: 2 }}>
                                    {categories.map(item => (
                                        <FormControlLabel key={item.id} id={"custom.Form.CategoryId." + item.id} value={item.id} label={item.value}
                                            onChange={(e) => checkCategorySendCustomForm(item.id)}
                                            control={
                                                <Checkbox
                                                    checked={selectedCategories.includes(item.id)}
                                                />}
                                        />
                                    ))}
                                    <FormControlLabel key={0} id={"custom.Form.CategoryId.0"} value={0} label={'Others'}
                                        control={
                                            <Checkbox
                                                checked={selectedCategories.includes(0)}
                                                onChange={(e) => checkCategorySendCustomForm(0)}
                                            />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                {selectedCategories.length > 0 && <FormControl>
                                    {questionnairesData.filter(x => selectedCategories.includes(x.categoryId) || (selectedCategories.includes(0) && x.categoryId === null)).map(item => (
                                        <FormControlLabel key={item.questionaireId} id="custom.Form.Id" value={item.questionaireId}
                                            control={<Checkbox onChange={(e: any) => checkFormsSendCustomForm(e.target.value)} />}
                                            label={item.questionnaireName ? item.questionaireTitle : item.questionaireTitle}
                                        />
                                    ))}

                                    {/* <CssSelectField
                                        id="custom.form.select"
                                        labelId="custom.form.select.label"
                                        label="Select Form"
                                        value={selectedForm}
                                        multiple
                                        placeholder="Select Form"
                                        onChange={(e: any) => setSelectedForm(e.target.value)}
                                    >
                                        {questionnairesData.filter(x => x.categoryId === selectedCategoryId).map(item => (
                                            <MenuItem key={item.questionaireId} id="custom.Form.Id" value={item.questionaireId}>
                                                {item.questionnaireName ? item.questionaireTitle : item.questionaireTitle}
                                            </MenuItem>
                                        ))}
                                    </CssSelectField> */}
                                </FormControl>}
                            </Grid>
                        </Grid>
                    )}


                </DialogContent>
                <DialogActions>
                    {!editBL.isloading && (
                        <Grid container direction="row" xs justifyContent={'space-between'}>
                            <Grid item xs={2}>
                                <Button id="cancel.form.button" variant="contained" color="inherit" onClick={onClose}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container spacing={2} justifyContent={'right'}>
                                    <Grid item>
                                        <Button
                                            id="send.form.button"
                                            variant="contained"
                                            disabled={selectedCategories.length === 0 || selectedForms.length === 0}
                                        // onClick={() => {}}
                                        >
                                            Print
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            id="send.form.button"
                                            variant="contained"
                                            disabled={selectedCategories.length === 0 || selectedForms.length === 0}
                                            onClick={() => {
                                                editBL.sendCustomForm(sendFormObject).then(() => {
                                                    onClose();
                                                });
                                            }}
                                        >
                                            Send
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>


                        </Grid>
                    )}
                </DialogActions>
            </Dialog>
        );
    },
);
