// @vendors
import React from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import { Close } from '@material-ui/icons';

// @interface
interface CustomFormModalProps {
    title: string;
    isOpen: boolean;
    onClose: (onClose: boolean) => void;
}

export const CustomFormModal: React.FC<CustomFormModalProps> = observer(
    ({ isOpen, onClose, children, title }) => {
        const handleOnClick = () => {
            onClose(false);
        };

        return (
            <Dialog open={isOpen}>
                <DialogTitle id='form-dialog-title' className='Title'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            sx={{
                                height: '30px'
                            }}
                            flexGrow={1}
                            fontSize='24px'
                            fontWeight={900}
                            fontFamily='Proxima Nova'
                        >
                            {title}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={handleOnClick}
                                sx={{
                                    fontSize: 25,
                                    backgroundColor: 'transparent',
                                    color: 'blue'
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        );
    }
);
