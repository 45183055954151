// Vendors
import React from 'react';
import { ChangeEvent, useState } from 'react';
import {
    Box,
    Grid,
    Card,
    Tooltip,
    Avatar,
    AvatarGroup,
    CardMedia,
    CardActionArea,
    LinearProgress,
    Typography,
    Tabs,
    IconButton,
    Divider,
    TextField,
    Button,
    Link,
    InputAdornment,
    Tab,
    Checkbox,
    FormControlLabel,
    alpha,
    styled,
    useTheme
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';

// Components
import Text from '../../../components/UI/Text';
import Label from '../../../components/UI/Label';

// API 
import { Link as RouterLink } from 'react-router-dom';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);
const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
          overflow: visible !important;
  
          .MuiTabs-scroller {
              overflow: visible !important;
          }
  
          .MuiButtonBase-root {
              text-transform: uppercase;
              font-size: ${theme.typography.pxToRem(12)};
  
              &:last-child {
                  margin-right: 0;
              }
          }
      `
);

const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const BoxComposedImage = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      filter: grayscale(80%);
      background-size: cover;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const BoxComposedBg = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        background: transparent;
        transition: ${theme.transitions.create(['all'])};
        color: ${theme.colors.alpha.trueWhite[70]};
        border-radius: 50px;
    
        &:hover {
          background: transparent;
          color: ${theme.colors.alpha.trueWhite[100]};
        }
    `
);

const DividerPrimary = styled(Divider)(
    ({ theme }) => `
        width: 50px;
        background: ${theme.colors.primary.main};
        height: 6px;
        border-radius: 50px;
    `
);

const DividerWarning = styled(Divider)(
    ({ theme }) => `
        width: 50px;
        background: ${theme.colors.warning.main};
        height: 6px;
        border-radius: 50px;
    `
);

const DividerSuccess = styled(Divider)(
    ({ theme }) => `
        width: 50px;
        background: ${theme.colors.success.main};
        height: 6px;
        border-radius: 50px;
    `
);
// background: ${theme.colors.alpha.black[10]};
const CardWrapper = styled(Card)(
    ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[10], 0.05)};
        box-shadow: none;
    `
);

const ButtonWrapper = styled(Button)(
    ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
    `
);

const LabelPrimary = styled(Box)(
    ({ theme }) => `
        font-weight: bold;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.colors.primary.main};
        text-transform: uppercase;
        font-size: ${theme.typography.pxToRem(10)};
        padding: ${theme.spacing(0.5, 1.5)};
        color: ${theme.palette.primary.contrastText};
    `
);

const LabelError = styled(Box)(
    ({ theme }) => `
        font-weight: bold;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.colors.error.main};
        text-transform: uppercase;
        font-size: ${theme.typography.pxToRem(10)};
        padding: ${theme.spacing(0.5, 1.5)};
        color: ${theme.palette.error.contrastText};
    `
);

const DividerVertialPrimary = styled(Box)(
    ({ theme }) => `
      height: 60%;
      width: 6px;
      left: -3px;
      border-radius: 50px;
      position: absolute;
      top: 20%;
      background: ${theme.colors.primary.main};
    `
);

const DividerVertialSuccess = styled(Box)(
    ({ theme }) => `
      height: 60%;
      width: 6px;
      left: -3px;
      border-radius: 50px;
      position: absolute;
      top: 20%;
      background: ${theme.colors.success.main};
    `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
          background-color: ${theme.colors.success.main};
          color: ${theme.palette.getContrastText(theme.colors.success.dark)};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          box-shadow: ${theme.colors.shadows.success};
          top: -${theme.spacing(4)};
          position: absolute;
          left: ${theme.spacing(3)};
    `
);

const AvatarInfo = styled(Avatar)(
    ({ theme }) => `
          background-color: ${theme.colors.info.main};
          color: ${theme.palette.getContrastText(theme.colors.info.dark)};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          box-shadow: ${theme.colors.shadows.info};
          top: -${theme.spacing(4)};
          position: absolute;
          left: ${theme.spacing(3)};
    `
);

const AvatarGradient = styled(Avatar)(
    ({ theme }) => `
          background: ${theme.colors.gradients.blue1};
          color: ${theme.palette.getContrastText(theme.colors.primary.main)};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          box-shadow: ${theme.colors.shadows.primary};
          top: -${theme.spacing(4)};
          position: absolute;
          left: ${theme.spacing(3)};
    `
);


function MyoCorrectContent() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const tabs = [
        { value: 'overview', label: 'Overview' },
        { value: 'enrollment', label: 'Enrollment' },
        { value: 'patientManagement', label: 'Patient Management' }
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };
    return (
        // <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        //     <BoxWrapper>
        //         <Typography variant='body1' textAlign={'center'}>This is where the MyoCorrect content will go.</Typography>
        //     </BoxWrapper>
        // </Card>
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={12} xl={5}>
                    <BoxComposed
                        display="flex"
                        alignItems="center"
                        sx={{
                            minHeight: '100%',
                            background: `${theme.colors.gradients.blue3}`
                        }}
                    >
                        <BoxComposedContent px={8} pt={8} pb={4}>
                            <Avatar
                                variant="square"
                                sx={{
                                    mx: 'auto',
                                    mb: 2,
                                    width: 'auto',
                                    height: 200,
                                    '& img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                src="/logos/MyoCorrect.png"
                            />
                            <Typography lineHeight="1.5" fontWeight="normal" variant="h5">
                                MyoCorrect is an exercise-based program that consists of a full, comprehensive
                                evaluation and a series of 20- to 30- minute, 1-on-1 myofunctional therapy
                                sessions within an eight- to 12-month period with your patients. The Vivos
                                orofacial myofunctional therapist (OMT) has been fully trained in the Vivos
                                treatment system and protocols for both children and adults, in addition to a
                                comprehensive training program in myofunctional therapy, Buteyko breathing, etc.
                                With this background and deep understanding of the treatment objectives that
                                Vivos Providers wish to achieve with their patients, MyoCorrect OMTs act as an
                                extension to Vivos Providers to help complement and support their patients
                                treatment program.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 5
                                }}
                                endIcon={<ArrowForwardTwoToneIcon />}
                                color="warning"
                            >
                                See Programs
                            </Button>
                        </BoxComposedContent>
                    </BoxComposed>
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Box py={3} px={4}>
                        <TabsWrapper
                            centered
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                    </Box>
                    <Divider />
                    {currentTab === 'overview' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>

                                        <Card
                                            sx={{
                                                overflow: 'visible',
                                                position: 'relative',
                                                pt: 4,
                                                mt: 4,

                                            }}
                                        >
                                            {/* <AvatarSuccess variant="rounded">
                                                <AssessmentTwoToneIcon fontSize="large" />
                                            </AvatarSuccess> */}

                                            <Box p={3.5}>

                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontSize: `${theme.typography.pxToRem(16)}`
                                                    }}
                                                >
                                                    What programs does MyoCorrect offer?
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <ButtonWrapper
                                                color="success"
                                                variant="text"
                                                fullWidth
                                                endIcon={<ChevronRightTwoToneIcon />}
                                            >
                                                Open PDF in new window.
                                            </ButtonWrapper>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            sx={{
                                                overflow: 'visible',
                                                position: 'relative',
                                                pt: 4,
                                                mt: 4
                                            }}
                                        >
                                            <Box p={3.5}>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontSize: `${theme.typography.pxToRem(16)}`
                                                    }}
                                                >
                                                    Frequently Asked Questions
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <ButtonWrapper
                                                color="success"
                                                variant="text"
                                                fullWidth
                                                endIcon={<ChevronRightTwoToneIcon />}
                                            >
                                                Open PDF in new window.
                                            </ButtonWrapper>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'enrollment' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            sx={{
                                                overflow: 'visible',
                                                position: 'relative',
                                                pt: 4,
                                                mt: 4
                                            }}
                                        >
                                            <AvatarSuccess variant="rounded">
                                                <AssessmentTwoToneIcon fontSize="large" />
                                            </AvatarSuccess>
                                            <Box p={3.5}>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontSize: `${theme.typography.pxToRem(16)}`
                                                    }}
                                                >
                                                    How do I enroll my patients in MyoCorrect?
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <ButtonWrapper
                                                color="success"
                                                variant="text"
                                                fullWidth
                                                endIcon={<ChevronRightTwoToneIcon />}
                                            >
                                                Open PDF in new window.
                                            </ButtonWrapper>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            sx={{
                                                overflow: 'visible',
                                                position: 'relative',
                                                pt: 4,
                                                mt: 4
                                            }}
                                        >
                                            <AvatarSuccess variant="rounded">
                                                <AssessmentTwoToneIcon fontSize="large" />
                                            </AvatarSuccess>
                                            <Box p={3.5}>

                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontSize: `${theme.typography.pxToRem(16)}`
                                                    }}
                                                >
                                                    How do I schedule a patient's initial MyoCorrect Appointment?
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <ButtonWrapper
                                                color="success"
                                                variant="text"
                                                fullWidth
                                                endIcon={<ChevronRightTwoToneIcon />}
                                            >
                                                Open PDF in new window.
                                            </ButtonWrapper>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'patientManagement' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            sx={{
                                                overflow: 'visible',
                                                position: 'relative',
                                                pt: 4,
                                                mt: 4
                                            }}
                                        >
                                            <AvatarSuccess variant="rounded">
                                                <AssessmentTwoToneIcon fontSize="large" />
                                            </AvatarSuccess>
                                            <Box p={3.5}>

                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontSize: `${theme.typography.pxToRem(16)}`
                                                    }}
                                                >
                                                    How can I view my MyoCorrect patient's notes and progress?
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <ButtonWrapper
                                                color="success"
                                                variant="text"
                                                fullWidth
                                                endIcon={<ChevronRightTwoToneIcon />}
                                            >
                                                Open PDF in new window.
                                            </ButtonWrapper>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            sx={{
                                                overflow: 'visible',
                                                position: 'relative',
                                                pt: 4,
                                                mt: 4
                                            }}
                                        >
                                            <AvatarSuccess variant="rounded">
                                                <AssessmentTwoToneIcon fontSize="large" />
                                            </AvatarSuccess>
                                            <Box p={3.5}>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontSize: `${theme.typography.pxToRem(16)}`
                                                    }}
                                                >
                                                    How do I contact my patient's Myofunctional therapist/advocate?
                                                </Typography>
                                            </Box>
                                            <Divider />
                                            <ButtonWrapper
                                                color="success"
                                                variant="text"
                                                fullWidth
                                                endIcon={<ChevronRightTwoToneIcon />}
                                            >
                                                Open PDF in new window.
                                            </ButtonWrapper>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
}

export default MyoCorrectContent;
