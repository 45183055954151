import { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Description } from '@mui/icons-material';
import { Button, Grid, Box, Typography, Icon, TextareaAutosize, TextField, MenuItem } from "@mui/material";
import { useMainStoreContext } from "../../../../stores/MainStore";
import { observer } from "mobx-react";
import { boxStyles } from "../styles";
import 'react-dropzone-uploader/dist/styles.css'
import { LinearProgress } from "@material-ui/core";
import { ApplianceSizes, RxAppliances, VivosAppliances } from "../../../../entities/Enums";
import React from "react";
import _ from "lodash";

const VGSizes = [25, 30, 35, 40, 45, 50, 55, 60, 65, 70];
const VGxSizes = [30, 35, 40, 45, 50, 55, 60, 65];
const VWSizes = [30, 35, 40, 45, 50, 55, 60, 65, 70];

const styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
    },
    vivosButton: {
        height: '100px',
        marginBottom: '-30px',
        marginTop: '-30px'
    }
}));
const StepFour = () => {

    const classes = styles();
    const boxClasses = boxStyles();
    const { currentPatient, selectedLab, selectedAppliance, applianceSizes, applianceColors, selectedApplianceColor, selectedApplianceSize, isUpdating, selectedApplianceType,
        setStep, setSelectedApplianceType, setSelectedApplianceColor, setSelectedApplianceSize, loadGuideSeries, setGuidesAttributes } = useMainStoreContext().prescriptionStore;
    const { selectedAllergyList } = useMainStoreContext().patientEditStore;

    useEffect(() => {
        if (isUpdating) {
            loadGuideSeries().then(() => {
                setGuidesAttributes();
            });
        }
        switch (selectedAppliance) {
            case RxAppliances.GUIDEVG:
                setSelectedApplianceType(VivosAppliances.GuideVG);
                break;
            case RxAppliances.GUIDEVGX:
                setSelectedApplianceType(VivosAppliances.GuideVGx);
                break;
            case RxAppliances.GUIDEVW:
                setSelectedApplianceType(VivosAppliances.GuideVW);
                break;
        }
    }, []);

    const handleBack = (): void => {
        setStep(0);
    };
    const handleNext = (): void => {
        setStep(4);
    };

    const handleApplianceSizeChange = (event: any) => {
        setSelectedApplianceSize(applianceSizes.find(x => x.id === event.target.value));
    };

    const handleApplianceColorChange = (event: any) => {
        setSelectedApplianceColor(applianceColors.find(x => x.id === event.target.value));
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Box className={boxClasses.leftBox}>
                        <Typography variant="h6">
                            Size
                        </Typography>

                        {selectedApplianceType === VivosAppliances.GuideVG && <TextField
                            id="VGSizeSelection"
                            label="Size"
                            fullWidth
                            value={!_.isUndefined(selectedApplianceSize?.id) ? selectedApplianceSize?.id : ''}
                            onChange={handleApplianceSizeChange}
                            select>
                            {applianceSizes.map((vg) => (
                                <MenuItem key={vg.id} value={vg.id}>
                                    {vg.name}
                                </MenuItem>
                            ))}
                        </TextField>}
                        {selectedApplianceType === VivosAppliances.GuideVGx && <TextField
                            id="VGxSizeSelection"
                            label="Size"
                            fullWidth
                            value={!_.isUndefined(selectedApplianceSize?.id) ? selectedApplianceSize?.id : ''}
                            onChange={handleApplianceSizeChange}
                            select>
                            {applianceSizes.filter((vg) => vg.id !== ApplianceSizes["25mm"] && vg.id !== ApplianceSizes["70mm"]).map((vg) => (
                                <MenuItem key={vg.id} value={vg.id}>
                                    {vg.name}
                                </MenuItem>
                            ))}
                        </TextField>}
                        {selectedApplianceType === VivosAppliances.GuideVW && <TextField
                            id="VWSizeSelection"
                            label="Size"
                            fullWidth
                            value={!_.isUndefined(selectedApplianceSize?.id) ? selectedApplianceSize?.id : ''}
                            onChange={handleApplianceSizeChange}
                            select>
                            {applianceSizes.filter((vg) => vg.id !== ApplianceSizes["25mm"]).map((vg) => (
                                <MenuItem key={vg.id} value={vg.id}>
                                    {vg.name}
                                </MenuItem>
                            ))}
                        </TextField>}
                        <Typography variant="h6">
                            Color
                        </Typography>
                        <TextField
                            id="ColorSelection"
                            label="Color"
                            fullWidth
                            value={!_.isUndefined(selectedApplianceColor?.id) ? selectedApplianceColor?.id : ''}
                            onChange={handleApplianceColorChange}
                            select>
                            {applianceColors.map((vg) => (
                                <MenuItem key={vg.id} value={vg.id}>
                                    {vg.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    RX Overview
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Allergies:</b> {selectedAllergyList.join(', ')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Appliance:</b> {selectedAppliance}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Appliance Size:</b> {selectedApplianceSize?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Appliance Color:</b> {selectedApplianceColor?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Lab:</b> {selectedLab.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
                            <Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}
                                disabled={isUpdating}>
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                disabled={_.isUndefined(selectedApplianceColor?.id) || _.isUndefined(selectedApplianceSize?.id)}
                                sx={{ borderRadius: '30px', margin: '20px' }}>
                                Save + Continue
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

const InputComponent = (props: { showInput: boolean }) => {
    return (
        <>
            {props.showInput &&
                <Box display='flex' flexDirection='column' style={{ width: '100%', cursor: 'pointer' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Description display='flex' fontSize='large' />
                    </div>
                    <Typography display='flex' variant='body2' textAlign='center'>
                        Upload Appliance Construction Diagram
                    </Typography>
                </Box>
            }
        </>)
}
const PreviewComponent = (props: any) => {
    return (
        <Box display='flex' flexDirection='column'>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Description display='flex' fontSize='large' />
            </div>
            <Typography display='flex' variant='body2' textAlign='center'>
                {props.meta.name}
            </Typography>
            <LinearProgress variant="determinate" value={props.meta.percent} />
        </Box>);
}

export default observer(StepFour);