
import { observer } from "mobx-react";
import { Button, Grid } from "@mui/material";
import ProviderPatient from "../../../../entities/ProviderPatient";
import React, { useCallback, useState } from "react";
import DataGrid from "../../../DataGrid/DataGrid";
import { useMainStoreContext } from "../../../../stores/MainStore";
import ModalContainer from "../../../ModalContainer";
import { SubmitReworkClaimWizard } from "../SubmitReworkClaimWizard";
import react from "react";
import moment from "moment";
import PrescriptionHorizontal from '../../../../pages/prescription/prescriptionhorizontal';
import { ProductionStatus } from "../../../../entities/Enums";

interface IGIProps {
    patient: ProviderPatient;
}

export const PatientPrescriptionDashboard: React.FC<IGIProps> = observer(({ patient }) => {

    const [selectedRow, setSelectedRow] = useState<any>({});
    let store = useMainStoreContext().reworkClaimStore;
    let prescriptionStore = useMainStoreContext().prescriptionStore;

    react.useEffect(() => {
        prescriptionStore.getPrescriptionsByPatientId(patient.id);
        store.getClaimsByPatientId(patient.id);
        prescriptionStore.loadPage(patient.id);
    }, [prescriptionStore.prescription]);

    const isAvailableToCreate = useCallback((shippedDate, applianceType, prescriptionId) => {

        const shippedOn = moment(shippedDate);
        const today = moment(new Date());
        const diff = (today.diff(shippedOn, 'days'));
        let list = [...store.claimData];
        let result = list.filter(x => x.prescriptionId === prescriptionId && x.isActive === true);

        return (([689, 690].includes(applianceType)) && diff < 1095) || diff < 365 && result.length === 0
    }, [store.claimData]);

    const columns = [
        {
            field: 'rxId',
            headerName: 'RX ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>{params.row.rxId}</Grid>
                );
            }
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return <Grid>{params.row.appliance.name}</Grid>
            }
        },
        {
            field: 'shippedDate',
            headerName: 'Shipped Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>{params.row.shippedDate && moment(params.row.shippedDate).format('MM/DD/yyyy')}</Grid>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <Button id={"reworkClaim.dashboard.cell.action" + params.row.id}
                            onClick={() => {
                                setSelectedRow(params.row);
                                store.setReworkSelection([]);
                                store.setClaimFiles([]);
                                store.setProviderNotes("");
                                store.setShowSubmitReworkWizard(true);
                            }}
                            disabled={!params.row.shippedDate || !isAvailableToCreate(params.row.shippedDate, params.row.appliance.applianceType, params.row.id)}>
                            Create Claim
                        </Button>
                    </Grid>
                );
            },
        }
    ]

    return (
        <Grid>
            <Grid className="grid" id={'patientPrescription.dashboard' + 1}>
                <DataGrid
                    columns={columns}
                    loading={prescriptionStore.isLoadingPrescriptions}
                    data={prescriptionStore.prescriptionData}
                />
            </Grid>
            {store.showSubmitReworkWizard && <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={store.showSubmitReworkWizard}
                title={"Submit Rework Claim"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowSubmitReworkWizard(false);
                }}>
                <SubmitReworkClaimWizard
                    patient={patient}
                    claim={selectedRow}
                    isEdit={false}
                    onClose={() => {
                        store.setShowSubmitReworkWizard(false);
                    }} />
            </ModalContainer>}
        </Grid>
    )
}
);

