import { styled, TextField } from "@mui/material";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      border:"0px",
    },
    // '& .Mui-focused': {
    //   border: '3px',
    //   borderColor: 'blue'
    // },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
      border:"0px",
    },
    '& .MuiOutlinedInput-root': {      
      '& fieldset': {
        borderColor: 'white',
        border:"0px",
        width:"100%",
        '.cssTextWithBorder': {
          borderColor: 'gray',
          //border:"5px",
          width:"100%",
        }
      },
      // '&:hover fieldset': {
      //   borderColor: 'white',
      //   border:"0px",
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: 'white',
      //   border:"0px",
      // },
    },
  });

  export default CssTextField;