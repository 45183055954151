import { Grid } from "@mui/material";
import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";

interface IncDecCounterProps {
  onChange(num: number): any;
  defaultValue: number;
}
const MINTOOTHINDEX = 51;
const MAXTOOTHINDEX = 82;

export const IncDecCounter: React.FC<IncDecCounterProps> = observer(
  ({ defaultValue, onChange }) => {
    const [num, setNum] = React.useState<number>(defaultValue);

    const incNum = () => {
      if (num < MAXTOOTHINDEX) {
        var newNum = Number(num) + 1;
        setNum(newNum);
        onChange(newNum);
      }
    };

    const decNum = () => {
      if (num > MINTOOTHINDEX) {
        var newNum = Number(num) - 1;
        setNum(newNum);
        onChange(newNum);
      }
    };

    const handleChange = (e: any) => {
      // let num = Number.parseInt(e.target.value);
      // if (num >= MINTOOTHINDEX && num <= MAXTOOTHINDEX) {
      //   onChange(num);
        setNum(e.target.value);
     // }
    };

    const handleOnBlur = (e: any) => {
      let num = Number.parseInt(e.target.value);
      if (num >= MINTOOTHINDEX && num <= MAXTOOTHINDEX) {
        onChange(num);
        setNum(e.target.value);
      } else {
        num = MINTOOTHINDEX;
        setNum(MINTOOTHINDEX);
      }
    };

    return (
      <Grid className="input-group superBetween" container direction="row">
        <Grid className="input-group-prepend">
          <button className="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
        </Grid>
        <Grid><input type="text" className="form-control betweenInput" style={{width:"100px"}} value={num}  onChange={handleChange} onBlur={handleOnBlur} /></Grid>
        <Grid className="input-group-prepend">
          <button className="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
        </Grid>
      </Grid>
    );
  }
);