import {
    Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl,
    FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup
} from "@mui/material";
import { observer } from "mobx-react";
import { Close } from "@mui/icons-material";
import { IncDecCounter } from "../IncDecCounter";
import React, { useEffect } from "react";
import { useMainStoreContext } from "../../stores/MainStore";


interface IDTProps {
    isOpen: boolean;
    onSave(location: number, isDeciduous: boolean, position: number): any;
    onClose: (onClose: boolean) => void;
}

export const SupernumeraryModal: React.FC<IDTProps> = observer(
    ({ isOpen, onClose, onSave }) => {
        const handleOnClick = () => {
            onClose(false);
        };
        const { openSupernumeraryModal, teethChartTeeth, teethChart, openSupernumeraryModel
        } = useMainStoreContext().teethChartStore;

        const [isDeciduous, setIsDeciduous] = React.useState(false);
        const [position, setPosition] = React.useState(0);
        const [location, setLocation] = React.useState(0);

        const resetModal = () => {
            setIsDeciduous(false);
            setPosition(0);
            setLocation(0);
        };

        useEffect(() => {
            resetModal();
        }, [])

        const handleDeciduousChange = (event: any) => {
            setIsDeciduous(event.target.checked);
        };

        const handlePositionsChange = (event: any) => {
            setPosition(event.target.value);
        };

        const handleLocationChange = (event: any) => {
            setLocation(event);
        };

        const handleSave = () => {
            onSave(location, isDeciduous, position);
            openSupernumeraryModel(false);
        }

        return (
            <Dialog
                open={isOpen}
                onClose={() => { openSupernumeraryModel(false) }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >Add Supernumerary Tooth</Box>
                        <Box>
                            {<IconButton onClick={handleOnClick}>
                                <Close />
                            </IconButton>}
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <Grid container direction="column" className="supernumeraryModal" alignItems={"spaceBetween"}>
                            <Grid className="fillWidth" >
                                <FormLabel>Location</FormLabel>
                                <Grid container direction="row" justifyContent="start" className="superGrouping" sx={{ margin: "0px 0px 0px 5px" }}>
                                    <Grid>
                                        <IncDecCounter defaultValue={51} onChange={handleLocationChange} />
                                    </Grid>
                                    <Grid sx={{padding:"10px"}}> Between 51 and 82</Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <FormLabel>Position</FormLabel>
                                <RadioGroup name="superPosition"
                                    className="superGrouping"
                                    onChange={handlePositionsChange}
                                    value={position}
                                    defaultValue={"0"} >
                                    <Grid container direction="row" alignItems={'center'}>
                                        <Grid><FormControlLabel name="superPosition" value="0" label="Inline" control={<Radio />} /></Grid>
                                        <Grid><FormControlLabel name="superPosition" value="1" label="Labial" control={<Radio />} /></Grid>
                                        <Grid><FormControlLabel name="superPosition" value="2" label="Lingual" control={<Radio />} /></Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                            <Grid >
                                <FormLabel>Deciduous</FormLabel>
                                <Grid className='superGrouping'><FormControlLabel name="deciduous" value={isDeciduous} control={<Checkbox onChange={handleDeciduousChange} />} label={"Is Deciduous"} /> </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent={"flex-end"}>
                                <Grid><Button className="vivButton normal" onClick={() => { openSupernumeraryModel(false); }}>Cancel</Button></Grid>
                                <Grid><Button className={"vivButton normal"} onClick={handleSave}>Add Tooth</Button></Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
            </Dialog>
        )
    }
);