import { ContactSupportOutlined } from "@mui/icons-material";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { observer } from "mobx-react-lite"
import React from "react";
import { FC } from "react"
import { useMainStoreContext } from "../../stores/MainStore";
import { ClaimHeader } from "./ClaimHeader";
import { ClaimInformation } from "./ClaimInformation";
import { InitialImpression } from "./InitialImpression";
import { LabInvestigation } from "./LabInvestigation";
import { LabRework } from "./LabRework";
import { ManagementReview } from "./ManagementReview";
import jwt_decode from 'jwt-decode';
import { Roles } from "../../entities/Enums";
import _ from "lodash";

interface IGIProps {
    claimViewData: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CLAIMINFORMATION = 0;
const INITIALIMPRESSION = 1;
const LABINVESTIGATION = 2;
const LABREWORK = 3;
const MANAGEMENTREVIEW = 4;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid>
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ClaimReview: FC<IGIProps> = observer(({ claimViewData }) => {

    const [value, setValue] = React.useState(0);
    const [roles, setRoles] = React.useState<any>([]);

    const store = useMainStoreContext().reworkClaimStore;

    React.useEffect(() => {
        store.setClaimId(claimViewData.id);
        store.loadClaim();

        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            setRoles(JSON.parse(tokenData.roles));
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Grid xs={12} className="aiReportEntryModal" sx={{ overflowY: 'hidden' }}>
        <Grid xs={12} sx={{ padding: '0px 0px 15px 0px' }}>
            <ClaimHeader claimViewData={claimViewData} patientName={claimViewData.patientName} />
            <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} className="tabs" onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Claim Information" {...a11yProps(CLAIMINFORMATION)} />
                    <Tab label="Initial Impression" {...a11yProps(INITIALIMPRESSION)} />
                    <Tab label="Lab Investigation" {...a11yProps(LABINVESTIGATION)} />
                    <Tab label="Lab Rework" {...a11yProps(LABREWORK)} />
                    {_.isUndefined(roles.find(x => (x.Name === Roles.AISADMIN || x.Name === Roles.EXTERNALLAB))) && <Tab label="Management Review" {...a11yProps(MANAGEMENTREVIEW)} />}
                </Tabs>
            </Grid>
            <Grid>
                <TabPanel index={value} value={CLAIMINFORMATION}>
                    <ClaimInformation claimData={claimViewData} />
                </TabPanel>
                <TabPanel index={value} value={INITIALIMPRESSION}>
                    <InitialImpression claimData={claimViewData} />
                </TabPanel>
                <TabPanel index={value} value={LABINVESTIGATION}>
                    <LabInvestigation claim={store.claim} />
                </TabPanel>
                <TabPanel index={value} value={LABREWORK}>
                    <LabRework claim={store.claim} roles={roles} />
                </TabPanel>
                <TabPanel index={value} value={MANAGEMENTREVIEW}>
                    <ManagementReview claimData={claimViewData} />
                </TabPanel>
            </Grid>
        </Grid>
    </Grid>
})