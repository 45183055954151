export const convertTypes = (type: string) => {
    switch (type) {
        case 'SINGLELINETEXT':
            return 'text';
        case 'textarea':
            return 'text';
        case 'CHECKBOX':
            return 'checkbox';
        case 'RADIOBTNGRP':
            return 'radiogroup';
        case 'DATETIME':
            return 'text';
        default:
            return;
    }
};

export const convertDisplayType = (type: string) => {
    switch (type) {
        case 'DATETIME':
            return 'date';
    }
};
