import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { MainStore } from "./MainStore";
import { Gateway } from "../api/Gateway";
import jwt_decode from 'jwt-decode';
import { GlobalUtils } from "../api/GlobalUtils";
import DictionaryListItem from "../entities/DictionaryListItem";
import _ from "lodash";

export default class OrderStore {

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; userFullName } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.checkRoles();
    }

    @observable mainStore: MainStore;
    @observable pageTitle = "Orders Dashboard";
    @observable ordersData: any[] = [];
    @observable origOrdersData: any[] = [];
    @observable isLoadingOrders: boolean = false;
    @observable isLoadingPatient: boolean = false;
    @observable userID: number = 0;
    @observable isLeftNavExpanded: boolean = false;  
    @observable searchParam = '';
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable _toggleShowPatientEditModal: boolean = false;
    @observable _toggleShowOrderStatusModal: boolean = false;
    @observable _currentPatientId: number = 0;
    @observable patientData: any;
    @observable orderStatusValues: DictionaryListItem[] = [];

    roles: any = [];
    isAISAdmin = false;

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                this.isAISAdmin = true;
            }
        }
        return;
    };

    @action setOrderStatusValues = (value: DictionaryListItem[]) => { this.orderStatusValues = value; }
    @action setOrderData = (value: any[]) => { this.ordersData = value; }
    @action setOrigOrdersData = (value: any[]) => { this.origOrdersData = value; }

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    @action setPatientData = (value: any) => this.patientData = value;

    get toggleShowPatientEditModal() {
        return this._toggleShowPatientEditModal;
    }

    @action
    set toggleShowPatientEditModal(value: any) {
        this._toggleShowPatientEditModal = value;
    }

    get toggleShowOrderStatusModal() {
        return this._toggleShowOrderStatusModal;
    }

    @action
    set toggleShowOrderStatusModal(value: any) {
        this._toggleShowOrderStatusModal = value;
    }

    get currentPatientId() {
        return this._currentPatientId;
    }

    @action
    set currentPatientId(value: any) {
        this._currentPatientId = value;
    }

    @action
    toggleEditPatientModal = () => {
        runInAction(() => {
            this.toggleShowPatientEditModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    toggleOrderStatusModal = () => {
        runInAction(() => {
            this.toggleShowOrderStatusModal = !this.toggleShowOrderStatusModal;
        });
    };

    @action
    getOrders = async () => {
        this.ordersData.length = 0;
        runInAction(() => {
            this.isLoadingOrders = true;
        });

        var url = this.isAISAdmin ? `/provider/orders` : `/provider/orders?providerId=${this.userID}`;
        const resp = await Gateway.get(url);

        runInAction(() => {
            this.isLoadingOrders = false;
            this.ordersData = resp.map(data => {
                const newData = {...data};
                newData.patientName = data.patientName + " " + data.patientLastName;
                newData.providerName = data.providerName + " " + data.providerLastName;
                return newData;
            });
        });
        this.origOrdersData = this.ordersData;
    };
    
    @action
    getPatientData = (patientId) => {
        runInAction(() => {
            this.isLoadingPatient = true;
        });

        var url = `/patient/${patientId}`;
        Gateway.get(url).then(resp => {
            this.setPatientData(resp as unknown);
            this.isLoadingPatient = false;
        });
    };

    clearData = () => {
        this.ordersData = this.origOrdersData;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.ordersData;
    };

    @action filterBySearchString = () => {
        this.ordersData = this.origOrdersData.filter(
            x =>
                x.id.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.providerName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.providerLastName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.patientVivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) || 
                x.patientName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) || 
                x.patientLastName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.ordRxId.toString().toLowerCase().includes(this.searchParam.toLowerCase())
        );
    };

    handleOrderStatusChange = (changedOrder, event) => {
        var url = `/provider/${changedOrder.id}/orderStatus/${event.target.value}/${this.userID}`;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.ordersData, c => {
                    return c.id === respData['id'];
                });
                var newOrderData = this.ordersData.reduce((newData, currentOrder, index) => {
                    const copiedOrder: any = { ...currentOrder };
                    if (ind === index) {
                        copiedOrder.ordStatusId = respData['orderStatusId'];
                    }
                    return [...newData, copiedOrder];
                }, [] as any[]);

                this.setOrderData(newOrderData);

                ind = _.findIndex(this.origOrdersData, c => {
                    return c.id === respData['id'];
                });
                newOrderData = this.origOrdersData.reduce((newData, currentOrder, index) => {
                    const copiedOrder: any = { ...currentOrder };
                    if (ind === index) {
                        copiedOrder.ordStatusId = respData['orderStatusId'];
                    }
                    return [...newData, copiedOrder];
                }, [] as any[]);
                this.setOrigOrdersData(newOrderData);
            }
        });
    };

    @action loadOrderStatusValues = async () => {
        GlobalUtils.getOrderStatus().then(resp => {
            this.setOrderStatusValues(resp);
        })
    };

}

