import { useMainStoreContext } from '../../../stores/MainStore';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepTwoVersa from './Versa/StepTwoVersa';
import StepFourVersa from './Versa/StepFourVersa';
import StepTwoDNAmRNAmmRNA from './DNAmRNAmmRNA/StepTwo';
import StepThree from './stepThree';
import StepFour from './stepFour';
import StepFourDNAmRNAmmRNA from './DNAmRNAmmRNA/StepFour';
import StepFive from './stepFive';
import StepSix from './stepSix';
import StepSeven from './stepSeven';
import { observer } from 'mobx-react';
import { Labs, RxAppliances } from '../../../entities/Enums';
import StepFourGuides from './Guides/StepFourGuides';
import StepFourVida from './Vida/VidaStepFour';


const FormIndex = () => {
	const { step, isUpdating, selectedAppliance, labs,
		setStep, setSelectedLab, loadGuideSeries, loadVidaConstructionValues } = useMainStoreContext().prescriptionStore;

	if (isUpdating) {
		switch (step) {
			case 0:
				setStep(1);
				return (<></>);
			case 1:
				if (selectedAppliance === RxAppliances.VERSA || selectedAppliance === RxAppliances.VIDA) {
					setSelectedLab(labs.find(x => x.name === Labs.VIVOS));
					return (<StepTwoVersa />);
				}
				if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) {
					setStep(3);
					return (<></>);
				}
				if(selectedAppliance === RxAppliances.DNAmRNAmmRNA) {
					return (<StepTwoDNAmRNAmmRNA />);
				}
				return (<StepTwo />);
			case 2:
				setStep(3);
				return (<></>);
			case 3:
				if (selectedAppliance === RxAppliances.VERSA)
					return (<StepFourVersa />);
				if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW))
					return (<StepFourGuides />);
				if (selectedAppliance === RxAppliances.VIDA)
					return (<StepFourVida />);
				if (selectedAppliance === RxAppliances.DNAmRNAmmRNA) {
					return (<StepFourDNAmRNAmmRNA />);
				}
				return (<StepFour />);
			case 4:
				if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) {
					setStep(5);
					return (<></>);
				}
				return (<StepFive />);
			case 5:
				return (<StepSix />);
			default:
				return <></>
		}
	}
	switch (step) {
		case 0:
			return (<StepOne />);
		case 1:
			if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) {
				loadGuideSeries();
				setSelectedLab(labs.find(x => x.name === Labs.VIVOS));
				setStep(3);
				return (<></>);
			}
			if (selectedAppliance === RxAppliances.VERSA) {
				setSelectedLab(labs.find(x => x.name === Labs.VIVOS));
				return (<StepTwoVersa />);
			}
			if (selectedAppliance === RxAppliances.VIDA) {
				loadVidaConstructionValues();
				setSelectedLab(labs.find(x => x.name === Labs.VIVOS));
				return (<StepTwoVersa />);
			}
				if (selectedAppliance === RxAppliances.DNAmRNAmmRNA) {
					return (<StepTwoDNAmRNAmmRNA />);
				}
			return (<StepTwo />);
		case 2:
			return (<StepThree />);
		case 3:
			if (selectedAppliance === RxAppliances.VERSA)
				return (<StepFourVersa />);
			if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW))
				return (<StepFourGuides />);
			if (selectedAppliance === RxAppliances.VIDA)
				return (<StepFourVida />);
			if (selectedAppliance === RxAppliances.DNAmRNAmmRNA)
				return (<StepFourDNAmRNAmmRNA />);
			return (<StepFour />);
		case 4:
			if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) {
				setStep(5);
				return (<></>);
			}
			return (<StepFive />);
		case 5:
			return (<StepSix />);
		case 6:
			return (<StepSeven />);
		default:
			return <></>
	}

}

export default observer(FormIndex);