import { Button, Grid, IconButton, Link, Typography } from "@mui/material";
import { Comment } from '@mui/icons-material'
import { observer } from "mobx-react";
import { Fragment, useEffect, useState } from "react";
import { DashboardInfoBox } from "../components/DashboardInfoBox/DashboardInfoBox";
import DataGrid from "../components/DataGrid/DataGrid";
import { LeftNav } from "../components/LeftNav/LeftNav";
import ModalContainer from "../components/ModalContainer";
import { MyoCorrectGridHeader } from "../components/MyoCorrectGridHeader";
import { useMainStoreContext } from "../stores/MainStore";
import { LabManagementFilters } from '../entities/Enums';
import { ClaimReview } from "../components/Claim/ClaimReview";

export const LabManagementDashboardComponent = () => {
    const [selectedRow, setSelectedRow] = useState<any>({ id: 0 });
    const [showModal, setShowModal] = useState<boolean>(false);
    const store = useMainStoreContext().labManagementStore;
    const {
        toggleLeftNav,
        getSearchData,
        clearData,
        toggleFilterResultsExpand,
        onFilter,
        loadPage,
        loadFilterCount,
        isLeftNavExpanded,
        pageTitle,
        userId,
        currentFilter,
        numProductionReady,
        numInFabrication,
        numShipped,
        numCasesOverSevenDays,
        isLoadingData,
        labManagementData,
        loadClaimData,
    } = store;
    useEffect(() => {
        loadFilterCount();
        loadPage();
    }, [])
    const numResults = 0;
    const hdrButtons: any = [];
    const infoBoxes: any = [];

    const handleRxClick = async (row: any) => {
        const claimData = await loadClaimData(row.id);
        setSelectedRow(claimData);
        setShowModal(true);
    };

    const defaultPage = "LAB MANAGEMENT";

    hdrButtons.push(
        <Button
            id={'myocorrecttherapist.header.btn.filterPatients.' + userId}
            className="vivButton large"
            onClick={toggleFilterResultsExpand}
        >
            FILTER RESULTS
        </Button>,
    );
    infoBoxes.push(<Grid key={"dib1"}><DashboardInfoBox title={"Production Ready Cases"} filter={LabManagementFilters.ProductionReady} value={numProductionReady} currentFilter={currentFilter} onFilter={(value) => { onFilter(value, LabManagementFilters.ProductionReady) }} /></Grid>)
    infoBoxes.push(<Grid key={"dib2"}><DashboardInfoBox title={"In Fabrication"} filter={LabManagementFilters.InFabrication} value={numInFabrication} currentFilter={currentFilter} onFilter={(value) => { onFilter(value, LabManagementFilters.InFabrication) }} /></Grid>)
    infoBoxes.push(<Grid key={"dib3"}><DashboardInfoBox title={"Shipped"} filter={LabManagementFilters.Shipped} value={numShipped} currentFilter={currentFilter} onFilter={(value) => { onFilter(value, LabManagementFilters.Shipped) }} /></Grid>)
    infoBoxes.push(<Grid key={"dib4"}><DashboardInfoBox title={"Cases over 7 days in Queue"} filter={LabManagementFilters.CasesOver7Days} value={numCasesOverSevenDays} currentFilter={currentFilter} onFilter={(value) => { onFilter(value, LabManagementFilters.CasesOver7Days) }} /></Grid>)
    const columns = [
        {
            field: 'submissionDate',
            headerName: 'Date Submitted',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { submissionDate } }) => {
                return submissionDate?.replace('T', ' ').replace('Z', '')
            }
        },
        {
            field: 'daysInQueue',
            headerName: 'Days in Queue',
            headerAlign: 'center',
            minWidth: 150,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { daysInQueue } }) => {
                const color = daysInQueue <= 3 ? 'green' :
                    daysInQueue <= 6 ? 'orange' :
                        'red';
                return (<Typography variant="h6" sx={{ color, fontWeight: 'bold' }}>{daysInQueue}</Typography>)
            },
        },
        {
            field: 'rxId',
            headerName: 'RX ID',
            headerAlign: 'center',
            minWidth: 120,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row }) => (<Button variant="text" onClick={() => handleRxClick(row)}>{row.rxId}</Button>)
        },
        {
            field: 'provider',
            headerName: 'Provider',
            headerAlign: 'center',
            minWidth: 120,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patient',
            headerName: 'Patient',
            headerAlign: 'center',
            minWidth: 120,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'lab',
            headerName: 'Lab',
            headerAlign: 'center',
            minWidth: 100,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            headerAlign: 'center',
            minWidth: 100,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'productionStatus',
            headerName: 'Prod Status',
            headerAlign: 'center',
            minWidth: 140,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'neededByDate',
            headerName: 'Date Need',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { neededByDate } }) => {
                return neededByDate?.replace('T', ' ').replace('Z', '')
            },
        },
        {
            field: 'fabricatedOn',
            headerName: 'Date of Fab',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { fabricatedOn } }) => {
                return fabricatedOn?.replace('T', ' ').replace('Z', '')
            },
        },
        {
            field: 'estimateShippedDate',
            headerName: 'Est Ship Date',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { estimateShippedDate } }) => {
                return estimateShippedDate?.replace('T', ' ').replace('Z', '')
            },
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerAlign: 'center',
            minWidth: 80,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (param) => <IconButton color="primary"><Comment /></IconButton>
        },
        {
            field: 'tags',
            headerName: 'Tags',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        }
    ];

    return (
        <Fragment>
            <Grid xs={12} item container direction="row" className="page">
                {/* <LeftNav onToggle={toggleLeftNav} defaultPage={defaultPage} bl={store} /> */}
                <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')}>
                    {/* <Grid>
                        <MyoCorrectGridHeader
                            parentId={'labmanagement.dashboard'}
                            id={userId}
                            onChange={getSearchData}
                            onClear={clearData}
                            title={pageTitle}
                            numResults={numResults}
                            buttons={hdrButtons}
                        />
                    </Grid> */}
                    <Grid container direction="row" sx={{ marginBottom: '15px;' }} justifyContent={'space-between'}>
                        {infoBoxes}
                        <Grid>
                            <Button
                                className="vivButton large"
                                onClick={event => {
                                }}
                            >
                                {'Run Report'}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className="grid" id={'aireporttech.dashboard' + userId}>
                        <DataGrid columns={columns} loading={isLoadingData} data={labManagementData} />
                    </Grid>
                </Grid>
            </Grid>
            <ModalContainer
                noScroll={false}
                show={showModal}
                title={"Claim Review"}
                onClick={() => { }}
                onClose={() => {
                    setShowModal(false);
                }}>
                <ClaimReview claimViewData={selectedRow} />
            </ModalContainer>
        </Fragment>
    )
};
export const LabManagementDashboard = observer(LabManagementDashboardComponent);