// Vendors
import React from 'react';
import { FC, useState } from 'react';
import { Button, Divider, Grid, MenuItem } from '@mui/material';

// Components
import { CommentsPanel } from '../../../components/CommentsPanel/CommentsPanel';
import { Oval } from 'react-loader-spinner';

// Entities
import AIReport from '../../../entities/AIReports';
import Submission from '../../../entities/Submission';
import MyoCorrectPatient from '../../../entities/MyCorrectPatient';

// Stores
import { useMainStoreContext } from '../../../stores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';



interface IGIProps {
    submission: Submission;
    aiReport: AIReport;
    patient: MyoCorrectPatient;
    showCSVModal(submissionId): any;
}

export const AIReportActionButtons: FC<IGIProps> = observer(({ submission, aiReport, patient, showCSVModal }) => {
    const { downloadFiles, uploadMeasurements, generateReport } = useMainStoreContext().aiReportEntryStore;
    const [openComments, setOpenComments] = useState(false);

    return submission ? (

        <Grid container direction="column" paddingLeft={5}>
            <CommentsPanel
                significantId={patient.id}
                isOpen={openComments}
                onClose={() => setOpenComments(!openComments)}
            />
            <Grid item xs={12} padding={1} paddingRight={0}>
                <Button
                    fullWidth
                    href={
                        process.env.REACT_APP_GATEWAY_URL + '/api/v1/' + 'aireports/images/' + aiReport.id
                    }
                    variant='contained'
                >
                    Download Files
                </Button>
            </Grid>
            <Grid item xs={12} padding={1} paddingRight={0}>
                <Button fullWidth onClick={() => showCSVModal(aiReport.id)} variant='contained'>
                    Upload Measurements
                </Button>
            </Grid>
            <Grid item xs={12} padding={1} paddingRight={0}>
                <Button fullWidth onClick={() => setOpenComments(!openComments)} variant='contained'>
                    Activity Summary
                </Button>
            </Grid>
            <Grid item xs={12} padding={1} paddingRight={0}>
                <Button fullWidth onClick={generateReport} variant='contained'>
                    Generate Report
                </Button>
            </Grid>
        </Grid>

    ) : (
        <></>
    );
});
