import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, RadioGroup, Radio } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';

import CssTextField from "../CssTextField";
import Item from "../Item";
import { useMainStoreContext } from "../../stores/MainStore";
import { AlertDialog } from '../AlertDialog';

interface ComponentProps {
    visitId: number
}

const StopBang = (props: ComponentProps) => {
    const { visitId } = props;
    const [hasLoaded, setHasLoaded] = useState(false);
    const { loadReportPropertiesData, saveReportInfo } = useMainStoreContext().intakeReportsStore;
    const [reportValues, setReportValues] = useState<any>([]);
    const [score, setScore] = useState<number>(0);
    const [disabled, setDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);

    useEffect(() => {
        loadReportPropertiesData('stopbang').then(resp => {
            resp.map(r => reportValues.push({ PropertyId: r.id, Name: r.name, Value: "0" }));
            setHasLoaded(true);
        })
    }, []);

    const questions = (index) => {
        return (<Grid xs={12} className="bottomMargin25">
            <Grid xs={12}>
                <Grid className="formLabel" >{reportValues[index].Name}</Grid>
            </Grid>
            <FormControl>
                <Item>
                    <RadioGroup
                        name={`StopBangRadio${reportValues[index].Name}`}
                        row
                        defaultValue="0"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            var value = event.target.value;
                            reportValues[index].Value = value;
                            setScore(reportValues.reduce((accumulator, object) => {
                                return parseInt(accumulator) + parseInt(object.Value);
                            }, 0));
                        }}>
                        <Grid container direction="row" gap={5}>
                            <FormControlLabel
                                font-size="5px"
                                value="1"
                                name="yes"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                font-size="5px"
                                value="0"
                                name="no"
                                control={<Radio />}
                                label="No"
                            />
                        </Grid>
                    </RadioGroup>
                </Item>
            </FormControl>
        </Grid>)
    }

    const handleClick = () => {
        var scoreIndex = reportValues.findIndex(r => { return (r.Name.includes('Score')) });
        reportValues[scoreIndex].Value = reportValues.reduce((accumulator, object) => {
            return parseInt(accumulator) + parseInt(object.Value);
        }, 0).toString();
        setDisabled(true);
        saveReportInfo(visitId, reportValues, 'stopbang').then((resp) => {
            if (resp === true) {
                setAlertTitle("Report saved succesfully.");
                setAlertMessage("Report saved succesfully.");
                setOpenAlertMessage(true);
            } else {
                setDisabled(false);
                setAlertTitle("Report not saved.");
                setAlertMessage("There was an error saving the report. Please try again.");
                setOpenAlertMessage(true);
            }
        });
    }

    return (
        <Grid >
            {hasLoaded ?
                <Grid container direction={"row"} className="formSectionLabel" sx={{ margin: "25px 25px" }}><Grid>STOP-BANG Sleep Apnea Questionnaire</Grid>
                    <Grid sx={{ margin: "25px 25px", padding: "0px 35px" }} className="formBasics">
                        <Grid className="formBasics" >
                            <Grid>
                                <Grid xs={12} container direction={"row"} justifyContent="flex-start">
                                    <FormGroup>
                                        <FormControlLabel className="sectionHdr" control={<Grid>STOP</Grid>} label="" />
                                    </FormGroup>
                                </Grid>
                                {reportValues.map((r, i) => { if (i < (reportValues.length - 1) && i < 4) return questions(i); })}
                                <Grid xs={12} container direction={"row"} justifyContent="flex-start">
                                    <FormGroup>
                                        <FormControlLabel className="sectionHdr" control={<Grid>BANG</Grid>} label="" />
                                    </FormGroup>
                                </Grid>
                                {reportValues.map((r, i) => { if (i < (reportValues.length - 1) && i >= 4) return questions(i); })}
                                <Grid xs={12} className="bottomMargin25">
                                    <Grid xs={12}>
                                        <Grid className="formLabel" >Total Score</Grid>
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="medium"
                                            disabled={true}
                                            value={score}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid container direction={"row"} justifyContent={"flex-end"}><Button className="vivButton large invert" onClick={handleClick} disabled={disabled}>Save</Button></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> : <></>}
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    )
};

export default observer(StopBang);
