// Vendors
import React from 'react';
import { Grid, Typography } from "@mui/material";

const EstimatedRepairFee: React.FC = () => {
    return (
        <Grid className="box-wizard-container">
            <Grid container>
                <Grid item xs={12} className="title-wizard-container">
                    <Typography variant='h4'>Please Acknowledge: Estimated Repair Fee</Typography>
                </Grid>
                <Grid item xs={12} padding={2}>
                    <Grid container>
                        <Grid item xs={12} paddingBottom={1}>
                            <Typography variant='body1'>
                                If an appliance is not eligible to be repaired under warranty, and the manufacturing lab is able
                                to repair the appliance, there will be an Estimated Repair Fee provided directly from the Vivos
                                Admin to the email provided above. You will have 48 hours (2 days) to approve the fee. If the fee
                                is not approved within the 48 hour time frame, the lab will be asked to proceed with the repair.
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    );
};

export default EstimatedRepairFee;
