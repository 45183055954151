import { Button, Divider, Grid, MenuItem } from "@mui/material";
import { observer } from "mobx-react";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { GlobalUtils } from "../../api/GlobalUtils";
import LabCase from "../../entities/LabCases";
import PatientIcon from '../../imgs/png/Patient.png';
import { useMainStoreContext } from "../../stores/MainStore";
import CssSelectField from "../CssSelectField";

interface IGIProps {
    labCase: LabCase;
}

export const LabCaseHeader: FC<IGIProps> = observer(({labCase}) => {
    const [qcStatus, setQcStatus] = useState(0);
    const [modelStatus, setModelStatus] = useState(0);

    const {
        setPatientId,
        loadPatient,
        patient
      } = useMainStoreContext().labCaseReviewStore;

    useEffect(() => {
        setPatientId(labCase.patientId);
        loadPatient();  
    }, []);

    return <Grid xs={12}>
          <Grid xs={12} container className="header" direction={'row'}>
                <Grid xs={1.3} sx={{ marginRight: '20px', paddingBottom: '25px' }}>
                    {patient.profileImageUrl && (
                        <img className="thumbnail profilePhoto" src={patient?.profileImageUrl ? GlobalUtils.getPatientProfileUrl(patient?.profileImageUrl, patient?.vivosId as string): ""}></img>
                    )}
                    {!patient.profileImageUrl && <img className="thumbnail profilePhoto" src={PatientIcon}></img>}
                </Grid>
                <Grid xs={10.5} container direction={'row'}>
                    <Divider flexItem={true} orientation="vertical" />
                    <Grid container className="nameSection ">
                        <Grid container direction={'column'} xs={4}>
                            <Grid container direction={'row'} className="name">
                                <Grid>{labCase.patient}</Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={6} container direction="row" className="center-box" >
                            <Grid direction= "column" className="date-box">
                                <Grid className="claim-info">Date Submitted</Grid>
                                <Grid fontSize="x-large">{labCase.dateSubmitted && moment(labCase.dateSubmitted).format('MM/DD/yyyy')}</Grid>
                            </Grid>
                            <Grid direction="column" margin="15px">
                            <Grid className="claim-info">QC Status</Grid>
                            <CssSelectField
                                    className="DateBox"
                                    labelId="qcStatus"
                                    label="QC Status"
                                    font-size="10px/13px"
                                    size="small"
                                    defaultValue={592}
                                    value={qcStatus}
                                    onChange={event => {
                                        setQcStatus(event.target.value as number);
                                    }}
                                >
                                    <MenuItem value={592}>Pending Approval</MenuItem>
                                    <MenuItem value={593}>Approved</MenuItem>
                                    <MenuItem value={594}>Denied</MenuItem>
                                </CssSelectField>
                            <Grid className="claim-info">Model Status</Grid>
                            <CssSelectField
                                    className="DateBox"
                                    labelId="modelStatus"
                                    label="Model Status"
                                    font-size="10px/13px"
                                    size="small"
                                    defaultValue={595}
                                    value={modelStatus}
                                    onChange={event => {
                                        setModelStatus(event.target.value as number);
                                    }}
                                >
                                    <MenuItem value={595}>Pending</MenuItem>
                                    <MenuItem value={596}>On Hold</MenuItem>
                                    <MenuItem value={597}>In Fabrication</MenuItem>
                                    <MenuItem value={598}>Shipped</MenuItem>
                                    <MenuItem value={599}>Cancelled</MenuItem>
                                </CssSelectField>
                            </Grid>

                        </Grid>
                        <Grid xs={2} container direction="column" className=" ">
                            <Button
                                className="vivButton normal invert"
                            >
                                Not Used
                            </Button>
                            <Button  className="vivButton normal invert">
                                Create Action Item
                            </Button>
                            <Button className="vivButton normal invert">
                                Generate CCR Form
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </Grid>
});