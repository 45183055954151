import { MainStore } from './MainStore';
import { observable, action, makeObservable, computed, makeAutoObservable } from 'mobx';
import MyoCorrectSession from '../entities/MyoCorrectSession';
import MyoCorrectSessionEvaluation from '../entities/MyoCorrectSessionEvaluation';
import { Gateway } from '../api/Gateway';
import QuestionaireMetaData from '../entities/QuestionaireMetaData';
import { GlobalUtils } from '../api/GlobalUtils';
import jwt_decode from "jwt-decode";
import PatientQuestionnaireResponse from '../entities/PatientQuestionnaireResponse';

const defaultEvaluation = {
    id: 0,
    myoCorrectPatientScheduleid: 0,
    toObject(): MyoCorrectSessionEvaluation {
        return { ...this };
    }
}

const defaultSession = {
    id: 0,
    patientId: 0,
    patientRegistrationId: 0,
    sessionIndexNumber: 0,
    appointmentType: "",
    evaluationType: 0,
    scheduleStatus: "",
    scheduleDateTime: new Date(),
    assignedTherapistId: 0,
    sessionEvaluation: defaultEvaluation,
    additionalNotes: "",
    sessionNotesPdf: "",
    sessionNotesCreatedOn: new Date(),
    oldNotesUrl: ""
}
const utils = new GlobalUtils();



export default class CreateAppointmentNotesStore {
    @observable mainStore: MainStore;
    constructor(mainstore: MainStore) {
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles, id } = jwt_decode(token);
            this.roles = JSON.parse(tokenData.roles);
        }
        this.checkRoles();
        makeAutoObservable(this);
    }

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVISOR) {
                this.isMyoCorrectAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE) {
                this.isMyoCorrectAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADMIN) {
                this.isMyoCorrectAdmin = true;
            }
        }
        return;
    }

    roles: any = [];
    @observable private _isMyoCorrectAdvisor = false;
    @observable private _isMyoCorrectAdvocate = false;
    @observable private _isMyoCorrectAdmin = false;
    @observable private _showModal = false;
    @observable private _showNotesModal = false;
    @observable private _showNoShowModal = false;
    @observable _editedAfterTimePeriod = false;
    @observable _currentSession = new MyoCorrectSession(defaultSession);
    @observable _currentAppointmentNote: { [key: string]: PatientQuestionnaireResponse } = {};
    @observable currentVivosId = "";
    @observable currentPatientId = 0;
    @observable _additionalNotes = "";
    @observable initialSessionId = -1;
    @observable _questionsID: QuestionaireMetaData[] = [];
    @observable _idChiefComplaint: number = 0;
    @observable _prevSessionUrl: string = "";
    @observable private _prevAdditionalNotes: string = "";    
    @observable private _isLoading = false;
    @observable private _loadingText = "";


    get isLoading() : boolean {
        return this._isLoading;
    }
   setIsLoading(value: any) {this._isLoading = value};

    get loadingText() : string {
        return this._loadingText;
    }
    @action setLoadingText(value: string) {this._loadingText = value};

    get editedAfterTimePeriod(): boolean {
        return this._editedAfterTimePeriod;
    }

    @action
    set editedAfterTimePeriod(value: boolean) {
        this._editedAfterTimePeriod = value;
    }

    get isMyoCorrectAdvisor(): any {
        return this._isMyoCorrectAdvisor;
    }

    @action
    set isMyoCorrectAdvisor(value: any) {
        this._isMyoCorrectAdvisor = value;
    }

    get isMyoCorrectAdvocate(): any {
        return this._isMyoCorrectAdvocate;
    }

    @action
    set isMyoCorrectAdvocate(value: any) {
        this._isMyoCorrectAdvocate = value;
    }

    get isMyoCorrectAdmin(): any {
        return this._isMyoCorrectAdmin;
    }

    @action
    set isMyoCorrectAdmin(value: any) {
        this._isMyoCorrectAdmin = value;
    }

    get showModal() {
        return this._showModal;
    }
    @action
    set showModal(value: boolean) {
        this._showModal = value;
    }

    get showNotesModal() {
        return this._showNotesModal;
    }

    get questionsID() {
        return this._questionsID;
    }
    @action
    set showNotesModal(value: boolean) {
        this._showNotesModal = value;
    }

    @action
    set questionsID(value: QuestionaireMetaData[]) {
        this._questionsID = value;
    }

    get showNoShowModal() {
        return this._showNoShowModal;
    }
    @action
    set showNoShowModal(value: boolean) {
        this._showNoShowModal = value;
    }

    get idChiefComplaint() {
        return this._idChiefComplaint;
    }
    @action
    set idChiefComplaint(value: number) {
        this._idChiefComplaint = value;
    }

    get currentSession() {
        return this._currentSession;
    }
    @action
    set currentSession(value: MyoCorrectSession) {
        this._currentSession = value;
    }

    get additionalNotes() {
        return this._additionalNotes;
    }
    @action
    set additionalNotes(value: string) {
        this._additionalNotes = value;
    }

    get prevAdditionalNotes() {
        return this._prevAdditionalNotes;
    }
    
    @action
    setPrevAdditionalNotes = (value: string) => {
        this._prevAdditionalNotes = value;
    }

    @action
    toggleOpenSessionNotesModal = () => {
        this._showModal = !this._showModal;
    }

    @action
    getCurrentSessionId = () => { return this.currentSession.sessionIndexNumber };

    @action
    toggleOpenAdditionalNotesModal = () => {
        this.showNotesModal = !this.showNotesModal;
    }

    @action
    toggleNoShowModal = () => {
        this.showNoShowModal = !this.showNoShowModal;
    }

    @action
    setEditedAfterTimePeriod = (value: boolean) => {
        this.editedAfterTimePeriod = value;
    }

    get prevSessionUrl() {
        return this._prevSessionUrl;
    }

    @action
    set prevSessionUrl(value: string) {
        this._prevSessionUrl = value;
    }

    @action setPrevSessionUrl = (value: string) => {
        this.prevSessionUrl = value;
    }

    // getCurrentSessionId = () => {return this.currentSession.sessionIndexNumber};
    @action setCurrentAdditionalNotes = (value: string) => { 
        this.currentSession.additionalNotes = value;
    };
    @action setInitialSessionId = (value: number) => { this.initialSessionId = value; };
    @action setCurrentVivosID = (newId: string) => { this.currentVivosId = newId };
    @action setCurrentPatientId = (newId: number) => { this.currentPatientId = newId };

    get currentAppointmentNote() { return this._currentAppointmentNote; }
    @action setCurrentAppointmentNote = (newAppointmentNote: { [key: string]: PatientQuestionnaireResponse }) => {
        this._currentAppointmentNote = newAppointmentNote
    };

    @action setCurrentAppointmentNoteProp = (value: any, property: string) => {
            var newValue = new PatientQuestionnaireResponse({ questionId: 0, response: value, markAsEdited: this.editedAfterTimePeriod })
            this.currentAppointmentNote[property] = newValue;
    }

    @action setCurrentAppointmentNotePropCheckbox = (property: string) => {

        var newValue = new PatientQuestionnaireResponse({ questionId: this.currentAppointmentNote[property].questionId, response: '', markAsEdited: this.editedAfterTimePeriod })
        if (this.currentAppointmentNote[property].response === 'on') {
            newValue.response = 'off'
        } else {
            newValue.response = 'on'
        }

        this.currentAppointmentNote[property] = newValue;

    }

    @action setCurrentSession = async (newSession: MyoCorrectSession) => {
        this.currentSession = newSession;

        var urlMetadata = "/questionaire/" + (this.currentSession.evaluationType.toString() === 'Initial Evaluation' ? "Initial Evaluation" : "General Session Notes")
        var data = await Gateway.get(urlMetadata);
        if (data.length > 0) {
            this.questionsID = data;
        }

        if (this._idChiefComplaint < 1) {
            var urlinitial = "/questionaire/Initial Evaluation"
            var dataInitial = await Gateway.get(urlinitial);
            var idChiefComplaint = dataInitial.find((obj) => {
                return obj.value === 'chiefComplaint';
            });
            this.idChiefComplaint = idChiefComplaint.id;
        }

        var tempArray: { [key: string]: PatientQuestionnaireResponse } = {};
        this.questionsID.map((question: any) => {
            tempArray[question.value] = new PatientQuestionnaireResponse({ questionId: question.id, markAsEdited: false, response: (question.defaultValue === undefined ? '' : question.defaultValue) });
        });

        this._currentAppointmentNote = tempArray;
    };

    @action saveStatus = () => {
        this.currentSession.scheduleStatus = "No Show";
        let url = "myocorrect/session/" + this.currentSession.id;
        let data =
            this.currentSession;
        Gateway.post(url, data)
            .then((respData) => {
                this.toggleNoShowModal();
            })
    }

    @action saveNote = () => {
        let url = "myocorrect/session/" + this.currentSession.id;;
        let data =
            this.currentSession;
        Gateway.post(url, data)
            .then((respData) => {
                this.toggleOpenAdditionalNotesModal();
            })
    }

    @action
    handleOldNotesUpload = (fileUploaded: any) => {
        this.setIsLoading(true);
        return Gateway.fileUploader('myocorrect/upload/oldnotes/' + this.currentSession.id, 'oldnotes.pdf', fileUploaded)
    }
}