import { FC, useEffect, useState } from 'react';
import { AppBar, Button, Toolbar, Typography, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid/Grid';
import moment from 'moment';
import CssSelectField from '../components/CssSelectField';

import DataGrid from '../components/DataGrid/DataGrid';
import { LeftNav } from '../components/LeftNav/LeftNav';

import { useMainStoreContext } from '../stores/MainStore';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PatientEdit } from '../components/PatientProfile/PatientEdit';
import { ModalContainer } from '../components/ModalContainer';
import { GlobalUtils } from '../api/GlobalUtils';
import DictionaryListItem from '../entities/DictionaryListItem';
import ConsultationPatient from '../entities/ConsultationPatient';

export const MyNetwork: FC = observer(() => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const {
        consultationData,
        loadDataValues,
        toggleLeftNav,
        isLeftNavExpanded,
        toggleShowPatientEdit,
        setToggleShowPatientEdit,
        roles,
        setPatientId,
        patientId,
        handleConsultationStatusChange,
    } = useMainStoreContext().consultationData;
    const [consultationStatus, setConsultationStatus] = useState<DictionaryListItem[]>([]);

    const [uniquePatient, setUniquePatient] = useState<ConsultationPatient>({} as ConsultationPatient);

    useEffect(() => {
        setConsultationStatus(GlobalUtils.getConsultationStatus());
        loadDataValues().then(() => {
            setHasLoaded(true);
        });
    }, [loadDataValues]);

    const columns: GridColDef[] = [
        {
            field: 'patientVivosId',
            headerName: 'Patient ID',
            flex: 1,
            headerAlign: 'left',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    setPatientId(params.row.patientId);
                                    setUniquePatient(params.row);
                                    setToggleShowPatientEdit(true);
                                }}
                            >
                                {params.row.patientVivosId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'patientName',
            headerName: 'Patient Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'providerName',
            headerName: 'Provider Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'consultationStatusId',
            headerName: 'Consultation Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'mynetwork.dashboard.cell.ConsultationStatus.' + params.row.id}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Select an consultation status"
                            value={params.row.consultationStatusId ? params.row.consultationStatusId : 0}
                            onChange={event => {
                                params.row.consultationStatusId = event.target.value;
                                handleConsultationStatusChange(params.row, event);
                            }}
                        >
                            <MenuItem key={0} value={0}>
                                {'Select an consultation status..'}
                            </MenuItem>
                            {consultationStatus.map(th => (
                                <MenuItem key={th.id} value={th.id}>
                                    {th.name}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            },
        },
        {
            field: 'scheduleDateTime',
            headerName: 'Scheduled Date/Time',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return <Grid>{moment.utc(params.row.scheduleDateTime).local().format('MM/DD/yyyy hh:mmA')}</Grid>;
            },
        },
    ];

    return (
        <Grid xs={12} item container direction="row" className="page">
            <LeftNav onToggle={toggleLeftNav} />
            <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')}>
                <Grid container direction="column" alignItems="center" className="gridHeader" xl={12}>
                    <Grid container className="placeholder" alignItems="center" alignContent="center" xl={12}>
                        <AppBar position="static" className="modalHeader" color="inherit">
                            <Toolbar>
                                <Typography
                                    id={'consultations.hdr.lbl.title'}
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                    className="title"
                                >
                                    MY NETWORK
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                </Grid>
                {hasLoaded && (
                    <Grid xs={12}>
                        <DataGrid
                            getRowId={row => row['networkId']}
                            columns={columns}
                            data={consultationData}
                            loading={!hasLoaded}
                            pageSize={100}
                        />
                    </Grid>
                )}
            </Grid>
            <ModalContainer
                show={toggleShowPatientEdit}
                noScroll={true}
                title=""
                hideTitlebar={true}
                onClose={() => {}}
                onClick={() => setToggleShowPatientEdit(false)}
            >
                <PatientEdit
                    asProvider={false}
                    userRoles={roles}
                    patientID={patientId}
                    patient={uniquePatient}
                    onClose={() => setToggleShowPatientEdit(false)}
                />
            </ModalContainer>
        </Grid>
    );
});
