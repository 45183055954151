import React, { useEffect } from "react";
import 'react-dropzone-uploader/dist/styles.css';

import * as _ from "lodash";
import Grid from '@mui/material/Grid';
import { observer } from "mobx-react";
import {
  TextField,
  Select,
  SelectChangeEvent,
  FormControl,
  IconButton,
  Tooltip,
  TextareaAutosize,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { MenuItem } from '@material-ui/core';
import { useMainStoreContext } from '../../stores/MainStore';
import UploadZipDragDrop from "../UploadDragAndDrop/UploadZipDragDrop";
import { FolderZip } from "@mui/icons-material";
import { Gateway } from "../../api/Gateway";
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { ProgressModal } from "../ProgressModal";
import { GlobalUtils } from "../../api/GlobalUtils";
import CssSelectField from "../CssSelectField";
import PatientVisit from "../../entities/PatientVisit";
import DeleteIcon from '@mui/icons-material/Delete';
// import { Link } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import { width } from "@mui/system";
import PatientImage from "../../entities/PatientImage";
import Patient from "../../entities/Patient";

interface IProps {
  isIntake: boolean;
  visit: PatientVisit | undefined;
}

const ScanCBCT: React.FC<IProps> = ({ isIntake, visit }) => {
  const { patientImageType, patientImages, patient, setPatientImages, patientScanCBCTImages } = useMainStoreContext().createPatientStore;
  const { cbctMachineBrand, fov01, fov02, setCbctMachineBrand, setFov01, setFov02, setVisitId } = useMainStoreContext().scanStore;
  const { cbctscan } = useMainStoreContext().aiReportOrder;
  const { getVisitId, visitCbctscan, saveVisit, currentVisit } = useMainStoreContext().visitRecordStore;
  const storeProvider = useMainStoreContext().providerStore;

  const chunkSize = 1048576 * 10;//its 10MB, increase the number measure in mb
  const [hascbct, sethascbct] = React.useState(false);
  const [type, setType] = React.useState<any>({});
  const [fileTypes, setFileTypes] = React.useState<any>([]);
  const [fileName, setFileName] = React.useState<any>("");
  const [showProgress, setShowProgress] = React.useState(false)
  const [counter, setCounter] = React.useState(1)
  const [fileToBeUpload, setFileToBeUpload] = React.useState<File>({} as File)
  const [beginingOfTheChunk, setBeginingOfTheChunk] = React.useState(0)
  const [endOfTheChunk, setEndOfTheChunk] = React.useState(chunkSize)
  const [progress, setProgress] = React.useState(0)
  const [fileGuid, setFileGuid] = React.useState("")
  const [fileSize, setFileSize] = React.useState<number>(0)
  const [chunkCount, setChunkCount] = React.useState(0)
  const progressInstance = <CircularProgressWithLabel value={progress} />;
  const [userId, setUserId] = React.useState(0);
  const [patientCBCTImages, setpatientCBCTImages] = React.useState<PatientImage[]>([]);

  useEffect(() => {
    if (!visit) {
      sethascbct(false);
      storeProvider.getProviderPreferences().then(resp => {
        let cbctPreference = storeProvider.providerPreferences.cbctsbrand;
        setCbctMachineBrand(cbctPreference ? cbctPreference : 0);
      });
    }
    else {
      setpatientCBCTImages(patientScanCBCTImages);
      loadVisitValues(visit);
    }

    setFileTypes(patientImageType.filter(x => x.listItemKey === "CBCTSCAN"));
    setType(fileTypes[0]);

    if (!isIntake && visit?.patientsImages[0]?.cbctScan) {
      setFileName(visit.patientsImages[0].cbctScan);
      sethascbct(true);
    }

    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      setUserId(tokenData.id);
    }

  }, [])

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }
  }, [fileToBeUpload, progress])

  const loadVisitValues = (visit): any => {
    if (visit.patientsImages) {
      setVisitId(visit.id);
      setFov01(visit.patientsImages[0].cbctFov01 ? visit.patientsImages[0].cbctFov01 : 0)
      setFov02(visit.patientsImages[0].cbctFov02 ? visit.patientsImages[0].cbctFov02 : 0);
      setCbctMachineBrand(visit.patientsImages[0].cbctMachineBrandId ? visit.patientsImages[0].cbctMachineBrandId : 0);
    }
  };

  let cbctMacBrandValues: any = [];
  const cbctBrandMap = (): any => {
    cbctMacBrandValues = GlobalUtils.getCBCTMachineBrands();
    var cbctBrand = _.map(cbctMacBrandValues, (s, i) => {
      return (
        <MenuItem key={i} value={s['id']}>
          {s['name']}
        </MenuItem>
      );
    });
    return cbctBrand;
  };

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const getFileContext = (e) => {
    resetChunkProperties();
    const _file = e;
    setFileSize(_file.size);
    const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setFileToBeUpload(_file);
    const _fileID = uuidv4() + "." + _file.name.split('.').pop();
    setFileGuid(_fileID);

  }

  const addToPatientImages = (file: any) => {
    patientImages.push(file);
    setFileName(file.pathUrl);
    setPatientImages(patientImages);
    sethascbct(true);
  }

  const resetChunkProperties = () => {
    setShowProgress(true)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
  }

  const fileUpload = (counter: number) => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      if (getVisitId() <= 0) {
        saveVisit().then(async (resp) => {
          if (currentVisit) {
            visit = currentVisit;
            uploadChunk(chunk);
          }
        });
      } else {
        uploadChunk(chunk);
      }
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  const uploadChunk = async (chunk) => {
    var url = visit ? `patient/${visit.patientId}/${getVisitId()}/${userId}/fileuploadchunk` : `patient/${patient.id}/${getVisitId()}/${userId}/fileuploadchunk`;
    //const enc = new TextDecoder('utf-8');
    var chunkyData = {
      chunkNumber: counter,
      fileName: fileGuid,
      data: await convertBase64(chunk)
    };
    const response = await Gateway.post(url, chunkyData).then(async (response) => {
      const data = response as unknown as any;
      if (data.isSuccess) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter == chunkCount) {
          console.log('Process is complete, counter', counter)
          await uploadCompleted();
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log('Error Occurred:', data.errorMessage)
      }
    });
  }
  const uploadCompleted = async () => {
    var url = visit ? `patient/${visit.patientId}/${getVisitId()}/${userId}/fileuploadchunkcomplete` : `patient/${patient.id}/${getVisitId()}/${userId}/fileuploadchunkcomplete`;
    var formData = {
      filename: fileGuid,
    };
    var chunkyData = {
      fileName: fileGuid,
      data: "",
      CbctMachineBrandId: cbctMachineBrand,
      CbctFov01: fov01,
      CbctFov02: fov02
    };
    await Gateway.post(url, chunkyData).then((response) => {
      const data = response as unknown as any;
      if (data.isSuccess) {
        setProgress(100);
        setShowProgress(false);
        setFileName(fileGuid);
      }
    })
  }

  return (
    <Grid className="modalPageHeight">
      <form id="scan">
        <Grid container direction="row" padding={3} justifyContent={"center"} justifyItems={"Center"}>
          <Grid item xs={6} justifyContent={"center"} justifyItems={"Center"} paddingTop={5}>
            <FormControl sx={{ width: '100%' }}>
              <UploadZipDragDrop
                allowMultiple={false}
                type={type}
                handleImageAdd={getFileContext}
                typeOptions={fileTypes}
                parentCallback={() => { }}
                extFiles={patientCBCTImages} />
              {hascbct &&
                <Grid xs={6} container direction="column" className="uploadImgTextContainer">
                  <Grid item xs={3}>Upload file: {fileName}</Grid>
                </Grid >
              }
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container padding={5} direction={"column"}>
              <Typography variant="h4">
                Vivos CBCT guidelines for the most comprehensive Airway Analysis
              </Typography>
              <Box>
                <Grid container padding={1} direction={"column"}>
                  <Typography variant="body1">
                    For best results and support a CBCT scan should capture the following anatomy:
                  </Typography>
                  <Grid paddingLeft={2}>
                    <Typography variant="h5">Nasion</Typography>
                    <Typography variant="h5">Bottom of chin (soft tissue)</Typography>
                    <Typography variant="h5">Inferior border of C3 (hard tissue) C4 and C5</Typography>
                    <Typography variant="h5">Entire spine of C1 (hard tissue)</Typography>
                    <Typography variant="h5">Glabella</Typography>
                    <Typography variant="h5">Tip of nose (soft tissue)</Typography>
                  </Grid>
                </Grid>
                <Grid container padding={1} direction={"column"}>
                  <Typography variant="body1" align="justify" paddingBottom={1}>
                    For proper diagnostics, it is generally recommended that patients who meet the following critera have the following X-Ray
                    imaging, are taken at the start of treatment, and at the end of each phase of treatment when appropriate.
                  </Typography>
                  <Typography variant="body1" >Ages 8 and older:</Typography>
                  <Typography variant="h5" paddingLeft={2} paddingBottom={2}>Full field of view CBCT Scans</Typography>
                  <Typography variant="body1" >Ages 7 and younger:</Typography>
                  <Typography variant="h5" paddingLeft={2}>CBCT scan or Panoramic and Cephalometric X-Ray</Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid xs={5} direction="column">
              <Grid xs={12} direction="row">
                <Grid xs={5.5}>
                  <FormControl sx={{ width: '90%' }}>
                    <Grid container direction={"row"} className="formLabel"><Grid>Select which brand of machine was used for this patients CBCT Scan</Grid></Grid>
                    <CssSelectField
                      size="small"
                      sx={{ width: '100%' }}
                      onChange={(event: any) => {
                        setCbctMachineBrand(event.target.value as unknown as number);
                      }}
                      value={cbctMachineBrand ? cbctMachineBrand : ''}
                    >
                      {cbctBrandMap()}
                    </CssSelectField>
                  </FormControl>
                </Grid>
                <Grid xs={5.5}>
                  <FormControl sx={{ width: '90%' }}>
                    <Grid container direction={"row"} className="formLabel"><Grid>What is the FOV Size (cm) for this image?</Grid></Grid>
                    <Grid container direction="row">
                      <Grid xs={5} >
                        <TextField
                          variant="outlined"
                          className="TextBox"
                          size="medium"
                          placeholder=""
                          required={true}
                          value={fov01}
                          type="number"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFov01(event.target.valueAsNumber);
                          }}
                        /></Grid>
                      <Grid xs={5} >
                        <TextField
                          variant="outlined"
                          className="TextBox"
                          size="medium"
                          placeholder=""
                          required={true}
                          value={fov02}
                          type="number"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFov02(event.target.valueAsNumber);
                          }}
                        /></Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid> */}
        </Grid>

        {/* <TextareaAutosize
            style={{ width: "-webkit-fill-available" }}
            value={stringText}
            maxRows={5} /> */}
        {/* </Box> */}
        <ProgressModal title={"Upload Progress"} progress={progress} isOpen={showProgress} onClose={() => { setShowProgress(false) }} />
      </form >
    </Grid >
  )
};

export default observer(ScanCBCT);


