import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useMainStoreContext } from "../../stores/MainStore";


const WarningModal = (props) => {

    const continueToCancel = () => {
        //createPatientReset();
        //vitalsAndMeasurementsReset();
        //patientHistoryReset();
        props.CloseModal(true);
    }

    const doNotCancel = () => {
        props.CloseModal(false);
    }

    return (
        <Grid>
            <Grid sx={{ padding: "10px" }}>
                Any unsaved changes will be lost. Do you want to continue?
            </Grid>
            <Grid container direction="row" justifyContent={"end"}>
                <Button onClick={continueToCancel}>Yes</Button>
                <Button onClick={doNotCancel} >No</Button>
            </Grid>
        </Grid>
    )

}
export default observer(WarningModal);
