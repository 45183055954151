import { Card, CardContent } from '@mui/material';
import { DraggableProvided } from 'react-beautiful-dnd';

import { Provider } from '../../../entities/Provider';

interface ICardProvider {
    item?: Provider;
    provided: any;
    style?: any;
    children?: React.ReactNode;
    index?: number;
    isDragging?: boolean;
}

const getStyle = (style: Object | undefined, provided: DraggableProvided) => {
    if (!style) {
        return {};
    }
    return {
        ...style,
        ...provided.draggableProps.style,
    };
};

export const CardProvider = ({ item, provided, style, index }: ICardProvider) => {
    return (
        <Card
            sx={{
                maxWidth: 250,
                marginBottom: '10px',
            }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(style, provided)}
        >
            <CardContent>
                <div className="item">
                    {item?.firstName} {item?.lastName} {item?.providerName}
                </div>
            </CardContent>
        </Card>
    );
};
