import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Typography, List, ListItem, Checkbox, ListItemButton, ListItemIcon, Alert } from "@mui/material";
import { Error } from '@material-ui/icons';
import { useMainStoreContext } from "../../../../stores/MainStore";
import { observer } from "mobx-react";
import { boxStyles } from "../styles";
import { RxAppliances } from "../../../../entities/Enums";
import React from "react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Gateway } from '../../../../api/Gateway';
import { LoadingButton } from '@mui/lab';

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

interface InvalidConstruction {
	toothId: number;
	error: string;
};

interface ConstructionDetails {
	toothId: number;
	partId: number;
}

const StepFour = () => {

	const classes = styles();
	const boxClasses = boxStyles();
	const { setStep, currentPatient, selectedLab, selectedAppliance, versaTypeSelected, fabricationTime,} = useMainStoreContext().prescriptionStore;
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;
	const { teethChartTeeth, teethChart, getIndexByNumberingSystem, currentNumberingSystem, handleNumberingSystemClick } = useMainStoreContext().teethChartStore;
	
	const [ loadingParts, setLoadingParts ] = useState(false);
	const [ parts, setParts ] = useState<{ id:number, partName: string }[]>([]);
	const [ selectedTooth, setSelectedTooth ] = useState<number | null>(null);
	const [ constructionDetails, setConstructionDetails ] = useState<ConstructionDetails[]>([]);

	const [ axialSpringId, setAxialSpringId ] = useState<number | null>(null);
	const [ cLoopAxialSpringId, setCLoopAxialSpringId ] = useState<number | null>(null);
	const [ isLoading, setIsLoading ] = useState(false);

	const [ errorList, setErrorList ] = useState<InvalidConstruction[]>([]);

	const handleBack = (): void => {
		setStep(2);
	};
	const handleNext = (): void => {
		setIsLoading(true);
		Gateway.post('teethchart/care/',
		{
			teethChart: teethChart,
			constructionDetails: constructionDetails
		}).then((result) => {
			if(result.length > 0) {
				setErrorList(result as unknown as InvalidConstruction[]);
			} else {
				setStep(4);
			}
		})
		.finally(() => {
			setIsLoading(false);
		});
	};

	const loadParts = async () => {
		if(!loadingParts) {
			setLoadingParts(true);
			const apiParts = await Gateway.get('TeethChart/parts');
			setParts(apiParts);
			setAxialSpringId(apiParts.find(part => part.partName === '3D Axial Spring')?.id);
			setCLoopAxialSpringId(apiParts.find(part => part.partName === 'C-Loop 3D Axial Spring')?.id);
		}
	};

	const handleToothClick = (toothId: number) => {
		if(selectedTooth === toothId) {
			setSelectedTooth(null);
		} else {
			setSelectedTooth(toothId);
		}
	};

	const handlePartClick = (partId: number) => {
		if(selectedTooth) {
			const cd = constructionDetails.filter(cd => cd.toothId === selectedTooth && cd.partId === partId);
			if(cd.length > 0) {
				setConstructionDetails(constructionDetails.filter(cd => cd.toothId !== selectedTooth || cd.partId !== partId));
			} else {
				setConstructionDetails([...constructionDetails, { toothId: selectedTooth, partId }]);
			}
		}
	};

	useEffect(() => {
		loadParts();
	}, []);

	return (
		<div className={classes.root}>
		<Grid container spacing={3}>
			<Grid item xs={8}>
				<Box className={boxClasses.leftBox}>
					<Grid container>
						<Grid item xs={11}>
							<Typography variant="h6">
								Appliance Construction
							</Typography>
						</Grid>
						<Grid item xs={1} display='flex' justifyContent='end'>
                            <button className="vivButton orange" onClick={() => { handleNumberingSystemClick() }}>{currentNumberingSystem}</button>
						</Grid>
					</Grid>
					{constructionDetails.filter(cd => cd.partId === cLoopAxialSpringId).length > 3 &&
						<Alert severity="warning">
							Placing more than three C-Loop 3D Axial Springs on a single arch may cause issues with appliance fabrication. Are you sure you want to continue?
						</Alert>
					}
					{errorList.length > 0 &&
					selectedTooth !== null &&
					errorList.filter(error => error.toothId === selectedTooth).length > 0 &&
						<Alert severity="error">
							{errorList.filter(error => error.toothId === selectedTooth).map(error => error.error).join(', ')}
						</Alert>
					}
					<Grid container spacing={4} sx={{marginTop: '5px'}}>
						<Grid item xs={8}>
							<Typography variant='body2'>Select Tooth</Typography>
							<List>
								{teethChartTeeth.map(tooth => 
									(<ListItem
										key={tooth.id}
										disablePadding>
										<ListItemButton
											selected={selectedTooth === tooth.id}
											onClick={() => handleToothClick(tooth.id)}>
											{errorList.filter(error => error.toothId === tooth.id).length > 0 &&
												<ListItemIcon>
													<Error color="error" />
												</ListItemIcon>
											}
											# { getIndexByNumberingSystem(tooth.toothNumber) }
										</ListItemButton>
									</ListItem>)
								)}
							</List>
						</Grid>
						<Grid item xs={4}>
							<Typography variant='body2'>Select Parts</Typography>
							<List>
								{parts.map(part => 
									(<ListItem
										key={part.id}
										disablePadding
										secondaryAction={
											<Checkbox
												edge="end"
												onChange={() => handlePartClick(part.id)}
												disabled={
													selectedTooth === null ||
													(part.partName === 'C-Loop 3D Axial Spring' && 
													constructionDetails.filter(cd => cd.toothId === selectedTooth && cd.partId === axialSpringId).length === 0)}
												checked={constructionDetails.filter(cd => cd.toothId === selectedTooth && cd.partId === part.id).length > 0}
											/>
										}>
										<ListItemButton
												disabled={
													selectedTooth === null ||
													(part.partName === 'C-Loop 3D Axial Spring' && 
													constructionDetails.filter(cd => cd.toothId === selectedTooth && cd.partId === axialSpringId).length === 0)}
												onClick={() => handlePartClick(part.id)}>
											{ part.partName }
										</ListItemButton>
									</ListItem>)
								)}
							</List>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={4}>
				<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="h6">
								RX Overview
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<b>Allergies:</b> {selectedAllergyList.join(', ')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<b>Appliance:</b> {selectedAppliance}
							</Typography>
						</Grid>
						{selectedAppliance !== RxAppliances.VERSA && 
								<Grid item xs={12}>
									<Typography>
										<b>Lab:</b> {selectedLab.name}
									</Typography>
								</Grid>
							}
						<Grid item xs={12}>
							<Typography>
								<b>Fabrication Time:</b> {fabricationTime}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<b>Teeth Charting:</b> Confirmed
							</Typography>
						</Grid>
						{selectedAppliance === RxAppliances.VERSA && <Grid item xs={12}>
								<Typography>
									<b>Versa Type Selected:</b> {versaTypeSelected}
								</Typography>
							</Grid>}
					</Grid>
					<Box alignSelf='flex-end' sx={{ textAlign:'center', width: '100%', justifyItems: 'center'}}>
						<Button variant="contained" color="primary" onClick={handleBack} sx={{borderRadius: '30px', margin:'20px'}}>
							Back
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							onClick={handleNext}
							loading={isLoading}
							disabled={selectedLab.id === -1}
							sx={{borderRadius: '30px', margin:'20px'}}>
							Save + Continue
						</LoadingButton>
					</Box>
				</Box>
			</Grid>
		</Grid>
		</div>
	);
}
export default observer(StepFour);