import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import DataGrid from '../components/DataGrid/DataGrid';
import '../scss/_Patients.scss';
import Button from '@mui/material/Button';
import { GridHeader } from '../components/GridHeader/GridHeader';
import { LeftNav } from '../components/LeftNav/LeftNav';
import { Grid } from '@mui/material';
import { PatientStore } from '../api/PatientStore';
import { TreatmentStatus, MyoCorrectStatus } from '../entities/Enums';
import { GetAuthToken } from '../components/GetAuthToken';
import { ModalContainer } from '../components/ModalContainer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PatientEdit } from '../components/PatientProfile/PatientEdit';
import PatientEditBL from '../components/PatientProfile/PatientEditBL';
import { AiReportModal } from '../components/AiReportOrder';
import { DashboardInfoBox } from '../components/DashboardInfoBox/DashboardInfoBox';
import { ClinicalAdvocateStore } from '../stores/ClinicalAdvocateStore';

interface PageProps {
    userRoles: any;
}

let userRoles: any = [];
let provId = -1;

@observer
export class ClinicalAdvocate extends Component<PageProps> {
    @observable pageTitle = 'Patients';
    defaultPage = 'PATIENTS';
    store: ClinicalAdvocateStore = new ClinicalAdvocateStore();
    editBL: PatientEditBL;
    static displayName = ClinicalAdvocate.name;

    constructor(props: PageProps) {
        super(props);
        makeObservable(this);
        this.editBL = new PatientEditBL();
    }

    columns: GridColDef[] = [
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            type: 'string',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    this.store.selectedVivosId = params.row.vivosId;
                                    this.store.currentPatientId = params.row.id;
                                    this.store.currentRow = params.row;
                                    this.store.toggleEditPatientModal();
                                }}
                            >
                                {params.row.vivosId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'firstName',
            flex: 1,
            headerName: 'First Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{params.row.firstName}</Grid>;
            },
        },
        {
            field: 'lastName',
            flex: 1,
            headerName: 'Last Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{params.row.lastName}</Grid>;
            },
        },
        {
            field: 'provider',
            flex: 1,
            headerName: 'Provider',
            type: 'text',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{params.row.provider}</Grid>;
            },
        },
        {
            field: 'treatmentStatusId',
            flex: 1,
            headerName: 'Treatment Status',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => <Grid>{TreatmentStatus[params.row.treatmentStatusId]}</Grid>,
        },
        {
            field: 'myoCorrectStatusId',
            flex: 1,
            headerName: 'MyoCorrect',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => <Grid>{MyoCorrectStatus[params.row.myoCorrectStatusId]}</Grid>,
        },
    ];

    componentDidMount() {
        this.store.loadData();
    }
    k = 0;

    render() {
        var infoBoxes: any = [];
        infoBoxes.push(
            <Grid key={'dib1'}>
                <DashboardInfoBox
                    title={"Today's Appointments"}
                    filter={0}
                    value={0}
                    currentFilter={0}
                    onFilter={value => {}}
                />
            </Grid>,
        );

        return (
            <>
                <GetAuthToken>
                    {({ roles, providerId }) => {
                        userRoles = roles;
                        provId = providerId;
                        return <div></div>;
                    }}
                </GetAuthToken>
                <Grid xl={12} container direction="row" className="page">
                    <LeftNav defaultPage={this.defaultPage} onToggle={this.store.toggleLeftNav} bl={this.store} />
                    <Grid className={'pageContent' + (this.store.isLeftNavExpanded === true ? ' small' : ' big')}>
                        <Grid>
                            <GridHeader
                                onChange={() => {}}
                                filterClass={'filter'}
                                onClear={this.store.loadData}
                                onCancel={() => {}}
                                onFilter={() => {}}
                                title={this.pageTitle}
                                numResults={this.store.resultCount}
                                buttons={[]}
                            ></GridHeader>
                        </Grid>

                        <Grid className="grid">
                            {
                                <DataGrid
                                    data={this.store.patientData}
                                    columns={this.columns}
                                    loading={this.store.isLoadingPatients}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <ModalContainer
                    show={this.store.toggleShowPatientEditModal}
                    noScroll={true}
                    title=""
                    hideTitlebar={true}
                    onClose={() => {}}
                    onClick={this.store.toggleEditPatientModal.bind(this)}
                >
                    <PatientEdit
                        asProvider={false}
                        userRoles={this.props.userRoles}
                        patientID={this.store.currentPatientId}
                        patient={this.store.currentRow}
                        onClose={this.store.toggleEditPatientModal.bind(this)}
                    ></PatientEdit>
                </ModalContainer>
            </>
        );
    }
}
