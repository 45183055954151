import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { TeethCharting } from "../../TeethCharting/TeethCharting";
import Patient from "../../../entities/Patient";
import { useMainStoreContext } from "../../../stores/MainStore";
import ModalContainer from "../../ModalContainer";
import WarningModal from "../../TeethCharting/WarningModal";

interface IGIProps {
    patient: Patient;
}

export const PatientTeeth: React.FC<IGIProps> = observer(
    ({ patient }) => {

        const { showWarningModal, saveTeethChart, setShowWarningModal, resetTeethChart } = useMainStoreContext().teethChartStore;
        useEffect(() => {
            resetTeethChart();
        })

        return (
            <Grid className={"viewTeethChart"} xs={12} >
                <Grid >
                    <TeethCharting existingPatient={patient} />
                </Grid>
                <Grid>
                    <Grid container direction={"row"} justifyContent={"flex-end"}><Button className="vivButton large invert" onClick={saveTeethChart}>Save</Button></Grid>
                </Grid>
                {showWarningModal && <ModalContainer
                    noScroll={false}
                    mediumScreen={true}
                    show={showWarningModal}
                    title={"Warning"}
                    onClick={() => { }}
                    onClose={() => {
                        setShowWarningModal(false);
                    }}>
                    <WarningModal />
                </ModalContainer>}
            </Grid>
        );
    }
);