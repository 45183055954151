import { observer } from "mobx-react";
import { Button, Box, Stepper, Step, StepLabel, Typography, CircularProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Gateway } from "../../api/Gateway";
import { useMainStoreContext } from "../../stores/MainStore";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

interface IProps {
    patientId: number;
}

const MyoCorrectEnrollment:FC<IProps> = (props: IProps) => {
    const { setCurrentPatient, step, setStep } = useMainStoreContext().myoCorrectEnrollmentStore;
	const steps = ['Verify Information', 'MyoCorrect Enrollment', 'Payment'];

    useEffect(() => {
        Gateway.get('/patient/' + props.patientId).then((res) => {
            setCurrentPatient(res as any);
        });
    }, []);

	return (
		<Box className='BoxDialog'>
            <Stepper activeStep={step}
                alternativeLabel={true}
            >
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                            {/* <StepConnector className="line"></StepConnector> */}
                        </Step>
                    );
                })}
            </Stepper>

            {step === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className='vivButton large' onClick={() => setStep(0)}>Reset</Button>
                    </Box>
                </>
            ) : (false ? 
                    <>
                        <Box sx={{width:'100%', height:'700px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <CircularProgress size={60} />
                        </Box>
                    </>
                    :
                    <>
                        {step === 0 && <StepOne />}
                        {step === 1 && <StepTwo />}
                        {step === 2 && <StepThree />}
                    </>
            )}
        </Box>
	)
}
export default observer(MyoCorrectEnrollment);