import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Box, Paper, Grid, Typography, Card } from '@mui/material';
import close from '../imgs/tmp/bigx.png';
import { Close } from '@material-ui/icons';
import { observer } from "mobx-react";

type ModalContainerProps =
    {
        show: boolean,
        title: string,
        onClick: Function,
        onClose: Function,
        hideTitlebar?: boolean,
        noScroll?: boolean,
        smallScreen?: boolean,
        mediumScreen?: boolean,
        extSx?: any,
        children: any
    }

export const ModalContainer: FC<ModalContainerProps> = ({ title, show, children, onClick, onClose, hideTitlebar, noScroll, smallScreen, mediumScreen, extSx }) => {

    const handleOnClick = () => {
        onClick();
    };

    const handleOnClose = () => {
        onClose();
    };

    return (
        <Grid className={(smallScreen ? "modal-smallscreen" : mediumScreen ? "modal-mediumscreen" : "modal-fullscreen") + (noScroll ? " noScroll" : "")} >
            <Grid container className={hideTitlebar ? "noHeader" : "content"}>
                <Dialog
                    className={(smallScreen ? "modal-fullscreen" : mediumScreen ? "modal-mediumscreen" : "modal-fullscreen") + (noScroll ? " noScroll" : "")}
                    maxWidth={'xl'}
                    sx={extSx ? extSx : {
                        '& .MuiPaper-root': {
                            height: '100%',
                            width: '100%'
                        }
                    }}
                    open={show}
                    onClose={handleOnClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" className={hideTitlebar ? "noHeader" : 'Title'} >
                        <Box display={hideTitlebar ? "none" : "flex"} alignItems="center">
                            <Typography flexGrow={1} variant="h3" gutterBottom>
                                {title}
                            </Typography>
                            {/* <Box
                                sx={{
                                    height: '30px'
                                }} flexGrow={1} fontSize='24px' fontWeight={900} fontFamily='Proxima Nova' >{title}</Box> */}
                            <Box>
                                <IconButton onClick={handleOnClose} sx={{ fontSize: 25, backgroundColor: "transparent", color: "blue" }} >
                                    <Close />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent className={(hideTitlebar ? "noHeader" : "full") + (noScroll ? " noScroll" : "")} style={{ padding: "0px" }}>
                        {children}
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    )
}
export default observer(ModalContainer);