interface PlanData {
    id: number,
    planName: string,
    numberOfSessions: number,
    midEval: any,
    finalEval: any,
    maxAdditionalSessionsAllowed: number
}

interface Plan extends PlanData {}

class Plan {
    constructor({id,planName,numberOfSessions,midEval, finalEval,maxAdditionalSessionsAllowed}: PlanData) {
        this.id = id;
        this.planName = planName;
        this.numberOfSessions= numberOfSessions;
        this.midEval = midEval;
        this.finalEval = finalEval;
        this.maxAdditionalSessionsAllowed = maxAdditionalSessionsAllowed;
    }


    toObject(): PlanData {
        return {...this};
    }
}
export default Plan;