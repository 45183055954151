import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AppBar, Box, CircularProgress, Fade, Grid, IconButton, Modal, Toolbar, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { SurveyCreatorCustomForm } from './Creator';

interface AddModalInterface {
    isOpen: boolean;
    onClose: (onClose: boolean) => void;
    section: any;
    sections: any;
    isLoading?: boolean;
}

export const EditCustomForm: React.FC<AddModalInterface> = observer(({ onClose, isOpen, section, sections, isLoading }) => {
    useEffect(() => { }, [isOpen, section]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen, sections]);


    const handleOnClick = () => {
        onClose(false);
    };

    return (
        <Modal open={isOpen} className="modalContainer" style={{ height: '100vh' }}>
            <Fade in={isOpen}>
                <Box className="modalQuestion">
                    <Grid container direction="row" justifyContent={'space-between'}>
                        <AppBar position="static" className="modalHeader" color="inherit">
                            <Toolbar>
                                <Typography
                                    id={'modal.addCustomForm.title'}
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                    className="title"
                                >
                                    {'Edit Custom Form'}
                                </Typography>
                                <IconButton id="addCustomForm.mod.btn.close" onClick={handleOnClick}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    {isLoading &&
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>
                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                    {!isLoading && <SurveyCreatorCustomForm onClose={setOpen} sections={sections} section={section} />
                    }

                </Box>
            </Fade>
        </Modal>
    );
});
