interface IPatientQuestionnaireResponse {
    questionId: number,
    response: string,
    markAsEdited: boolean
}

interface PatientQuestionnaireResponse extends IPatientQuestionnaireResponse {}

class PatientQuestionnaireResponse {
    constructor({questionId, response, markAsEdited})
    {
        this.questionId = questionId;
        this.response= response;
        this.markAsEdited = markAsEdited;
    }
}

export default PatientQuestionnaireResponse;