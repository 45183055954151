import React, { useEffect } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { Grid, TextField } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import * as _ from 'lodash';
import "../../scss/_Tooth.scss";
import "../../scss/_global.scss";
import { Tooth } from "./Tooth";
import { observer } from 'mobx-react';
import DataGrid from '../DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useMainStoreContext } from '../../stores/MainStore';
import CssSelectField from '../CssSelectField';
import { GlobalUtils } from '../../api/GlobalUtils';
import CssTextField from '../CssTextField';
import { SupernumeraryModal } from './SupernumeraryModal';
import { UneruptedModal } from './UneruptedReasonsModal';
import { toJS } from 'mobx';
import Patient from '../../entities/Patient';
import { NumberingSystem, TeethSet } from '../../entities/Enums';

interface TeethChartingProps {
    existingPatient?: Patient
}

const gridStyle = { marginTop: 10 }
const MISSINGREASONDEFAULT = 113;
const PRESENTCONDITIONDEFAULT = 122;

export const TeethCharting: React.FC<TeethChartingProps> = observer(
    ({ existingPatient }) => {
        const { openSupernumeraryModal, openUneruptedModal, teethChartTeeth, patientId, teethChart, notes, currentNumberingSystem, currentTeethSet, showWarningModal, addSupernumerary, addUnerupted, handleSetNotes,
            onChangeMissingReason, openSupernumeraryModel, onChangePresentConditions, hasDetails, onDeleteTooth, onToothClick, onToothNoteChange, handleNumberingSystemClick,
            resetTeethChart, setOpenUneruptedModal, setPatientId, loadChart, handleTeethSetClick, getIndexByNumberingSystem, getDeciduousToothIndex, setCurrentTeethSet, setCurrentNumberingSystem } = useMainStoreContext().teethChartStore;
        const { patient } = useMainStoreContext().createPatientStore
        const storeProvider = useMainStoreContext().providerStore;
        const [missingReasons, setMissingReasons] = React.useState<JSX.Element[]>([]);
        const [presentConditions, setPresentConditions] = React.useState<JSX.Element[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);

        useEffect(() => {
            resetTeethChart();
            setMissingReasons(_.map(GlobalUtils.getMissingReasons(), (m, i) => {
                return <MenuItem key={i} className='gridDropDown' value={m.id}>{m.name}</MenuItem>
            }));
            setPresentConditions(_.map(GlobalUtils.getPresentConditions(), (m, i) => {
                return <MenuItem key={i} className='gridDropDown' value={m.id}>{m.name}</MenuItem>
            }));
            if (existingPatient) {
                setPatientId(existingPatient.id);
            } else {
                setPatientId(patient ? patient.id : 0);
            }
            loadChart().then(() => {
                setHasLoaded(true);
            });

            storeProvider.getProviderPreferences().then(() => {
                let teethSetType = GlobalUtils.getTeethSetType();
                let numericSystemType = GlobalUtils.getTeethNumSystemType();

                let foundedSetType = teethSetType?.find(x => x.id === storeProvider.providerPreferences.teethSetPref);
                setCurrentTeethSet(foundedSetType ? foundedSetType.listItemKey : TeethSet.ALL);

                let foundedSystemType = numericSystemType?.find(x => x.id === storeProvider.providerPreferences.teethSystemPref);
                setCurrentNumberingSystem(foundedSystemType ? foundedSystemType.listItemKey : NumberingSystem.UNIVERSAL);
            });

        }, []);

        const columns: GridColDef[] = [
            {
                field: 'toothNumber', headerName: 'Tooth ##', type: 'number', headerAlign: 'center', headerClassName: "colHeader", align: "left", renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.isDeciduous && getDeciduousToothIndex(params.row.toothNumber)}
                            {!params.row.isDeciduous && getIndexByNumberingSystem(params.row.toothNumber)}
                        </Grid>
                    )
                }
            },
            {
                field: 'missingReason', flex: 1, headerName: 'Missing Reason', type: 'number', headerAlign: 'center', headerClassName: "colHeader", align: "left", renderCell: ((params) => {
                    return (
                        <CssSelectField className='gridDropDown'
                            id={"teethChartGrid.ddl.missingReasons" + params.row.toothNumber}
                            disabled={params.row.presentConditionsArray.length > 0 && params.row.presentConditionsArray[0] !== PRESENTCONDITIONDEFAULT}
                            defaultValue={MISSINGREASONDEFAULT}
                            value={params.row.missingReason}
                            onChange={(event) => { onChangeMissingReason(params.row, event.target.value) }}>
                            {missingReasons}
                        </CssSelectField>);
                })
            },
            {
                field: 'PresentCondition', flex: 1, headerName: 'Present Condition', headerAlign: 'center', headerClassName: "colHeader", align: "left", renderCell: ((params) => {
                    return (
                        <CssSelectField className='gridDropDown'
                            id={"teethChartGrid.ddl.presentConditions" + params.row.toothNumber}
                            defaultValue={PRESENTCONDITIONDEFAULT}
                            disabled={params.row.missingReason && params.row.missingReason !== MISSINGREASONDEFAULT}
                            multiple
                            value={params.row.presentConditionsArray}
                            onChange={(event) => { onChangePresentConditions(params.row, event.target.value) }}>
                            {presentConditions}
                        </CssSelectField>);
                })
            },
            {
                field: 'Notes', flex: 2, headerName: 'Notes', headerAlign: "left", headerClassName: "colHeader", align: "left", renderCell: ((params) => {
                    return (
                        <CssTextField
                            id={"teethChartGrid.txt.notes" + params.row.toothNumber}
                            className="gridTextField"
                            variant="standard"
                            value={params.row.notes}
                            onChange={(event) => { onToothNoteChange(event, params.row.toothNumber) }}
                        />
                    )
                })
            },
            {
                field: 'IsActive', flex: 0.5, headerName: ' ', headerAlign: 'center', headerClassName: "colHeader", align: "left", renderCell:
                    ((params) => {
                        return (<Grid onClick={() => { onDeleteTooth(params.row.toothNumber) }}><RemoveCircleIcon color="error" /></Grid>);
                    })
            },
        ]


        return (

            <Grid xs={12} container direction="column" className='teethCharting' rowSpacing={{ xl: 1 }}>
                <Grid xs={12} container direction="column">
                    <Grid container direction="row" alignItems={"center"} justifyContent="space-between">
                        <Grid><div>Select a tooth then select conditions in the table below.</div></Grid>
                        <Grid>
                            <button className="vivButton orange" onClick={() => { handleTeethSetClick() }}>{currentTeethSet}</button>
                            <button className="vivButton orange" onClick={() => { handleNumberingSystemClick() }}>{currentNumberingSystem}</button>
                            <button className="vivButton orange" onClick={() => { openSupernumeraryModel(true) }}>[+] SUPERNUMERARY</button>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container justifyContent="center" className="gridDirections"><span>UPPER</span></Grid>
                    <Grid container direction="row" justifyContent="center">
                        <Grid xs={.2} container alignContent="center" className="gridDirections"><span>L</span></Grid>
                        <Grid xs={10.6} container direction="row" className="toothChart">
                            <Grid container>
                                <Grid container direction="row" xs={6} className="borderBottomRight">
                                    <Tooth onClick={onToothClick} index={1} tooth={hasDetails(1)} currentPresentConditions={teethChartTeeth[1]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={2} tooth={hasDetails(2)} currentPresentConditions={teethChartTeeth[2]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={3} tooth={hasDetails(3)} currentPresentConditions={teethChartTeeth[3]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={4} tooth={hasDetails(4)} currentPresentConditions={teethChartTeeth[4]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={5} tooth={hasDetails(5)} currentPresentConditions={teethChartTeeth[5]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={6} tooth={hasDetails(6)} currentPresentConditions={teethChartTeeth[6]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={7} tooth={hasDetails(7)} currentPresentConditions={teethChartTeeth[7]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={8} tooth={hasDetails(8)} currentPresentConditions={teethChartTeeth[8]?.presentConditionsArray} />
                                </Grid>
                                <Grid container direction="row" xs={6} className="borderBottomLeft">
                                    <Tooth onClick={onToothClick} index={9} tooth={hasDetails(9)} currentPresentConditions={teethChartTeeth[9]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={10} tooth={hasDetails(10)} currentPresentConditions={teethChartTeeth[10]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={11} tooth={hasDetails(11)} currentPresentConditions={teethChartTeeth[11]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={12} tooth={hasDetails(12)} currentPresentConditions={teethChartTeeth[12]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={13} tooth={hasDetails(13)} currentPresentConditions={teethChartTeeth[13]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={14} tooth={hasDetails(14)} currentPresentConditions={teethChartTeeth[14]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={15} tooth={hasDetails(15)} currentPresentConditions={teethChartTeeth[15]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={16} tooth={hasDetails(16)} currentPresentConditions={teethChartTeeth[16]?.presentConditionsArray} />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid container direction="row" xs={6} className="borderTopRight">
                                    <Tooth onClick={onToothClick} index={32} tooth={hasDetails(32)} currentPresentConditions={teethChartTeeth[32]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={31} tooth={hasDetails(31)} currentPresentConditions={teethChartTeeth[31]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={30} tooth={hasDetails(30)} currentPresentConditions={teethChartTeeth[30]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={29} tooth={hasDetails(29)} currentPresentConditions={teethChartTeeth[29]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={28} tooth={hasDetails(28)} currentPresentConditions={teethChartTeeth[28]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={27} tooth={hasDetails(27)} currentPresentConditions={teethChartTeeth[27]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={26} tooth={hasDetails(26)} currentPresentConditions={teethChartTeeth[26]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={25} tooth={hasDetails(25)} currentPresentConditions={teethChartTeeth[25]?.presentConditionsArray} />
                                </Grid>
                                <Grid container direction="row" xs={6} className="borderTopLeft">
                                    <Tooth onClick={onToothClick} index={24} tooth={hasDetails(24)} currentPresentConditions={teethChartTeeth[24]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={23} tooth={hasDetails(23)} currentPresentConditions={teethChartTeeth[23]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={22} tooth={hasDetails(22)} currentPresentConditions={teethChartTeeth[22]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={21} tooth={hasDetails(21)} currentPresentConditions={teethChartTeeth[21]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={20} tooth={hasDetails(20)} currentPresentConditions={teethChartTeeth[20]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={19} tooth={hasDetails(19)} currentPresentConditions={teethChartTeeth[19]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={18} tooth={hasDetails(18)} currentPresentConditions={teethChartTeeth[18]?.presentConditionsArray} />
                                    <Tooth onClick={onToothClick} index={17} tooth={hasDetails(17)} currentPresentConditions={teethChartTeeth[17]?.presentConditionsArray} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={.2} container alignContent="center" className="gridDirections"><span>R</span></Grid>
                    </Grid>
                    <Grid xs={12} container justifyContent="center" className="gridDirections"><span>LOWER</span></Grid>
                    <Grid >
                        {
                            <DataGrid
                                columns={columns}
                                data={toJS(teethChartTeeth)}
                                hideFooter={true}
                                size={"small"}
                                pageSize={7}
                            />
                        }
                    </Grid>
                    <Grid container padding={1}>
                        <TextField
                            label="Other teeth condition notes:"
                            fullWidth
                            multiline
                            minRows={3}
                            value={notes}
                            onChange={handleSetNotes}
                        />
                    </Grid>
                </Grid>
                <SupernumeraryModal isOpen={openSupernumeraryModal} onClose={() => { openSupernumeraryModel(false); }} onSave={addSupernumerary} />
                <UneruptedModal isOpen={openUneruptedModal} onClose={() => { setOpenUneruptedModal(false); }} onSave={addUnerupted} />
            </Grid>
        )
    }

);