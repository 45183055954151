import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Grid, Box, styled, Typography } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import { IconButton } from "@material-ui/core";
import { GlobalUtils } from '../../api/GlobalUtils';
import _ from "lodash";
import DocUploader from "./DocUploader";
import DictionaryListItem from "../../entities/DictionaryListItem";
import FilesIcon from '../../imgs/png/Miscellaneous-Files.png';
import { FolderZip } from "@mui/icons-material";
import PatientImage from "../../entities/PatientImage";

const fileTypes = ["ZIP"];
interface IUploaderProps {
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    typeOptions: any[];
    allowMultiple: boolean;
    handleImageAdd(file: any);
    type?: any;
    extFiles: any[];
}

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);
const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const UploadZipDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback, typeOptions, type, allowMultiple, handleImageAdd, extFiles }) => {

        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [docs, setDocs] = React.useState<any[]>([]);
        const [files, setFiles] = React.useState<any[]>([]);
        const [options, setOptions] = React.useState<JSX.Element[]>([]);
        const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            setOptions(typeOptions);
            if (extFiles && extFiles.length > 0) {
                _.forEach(extFiles, (i, index) => {
                    i["extIndex"] = index;
                    if (i.imageType) {
                        selectedOptions.push(i.imageType.id);
                    }
                })
                setDocs(extFiles);
                setSelectedOptions(selectedOptions);
            }
            setHasLoaded(true);
        }, [])

        useMemo(() => {
            GlobalUtils.getIntakeReports().map(item => {
                setReportTypes(prevState => [...prevState, item]);
            });
        }, []);


        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var img = {} as PatientImage;
            var image = URL.createObjectURL(file);
            img.data64String = await convertBase64(file) as unknown as string;
            img.data64Url = decodeURI(image);
            img.pathUrl = decodeURI(file.name);
            img.imageType = type;
            img["new"] = true;
            img["extIndex"] = extFiles.length;
            extFiles.push(img);
            imglist.push(img);
        }

        const handleImageSet = (thefiles) => {
            setIsPreview(false);
            var imglist: any[] = files;
            var promises: any[] = [];
            handleImageAdd(thefiles[0]);
            promises.push(doWork(thefiles[0], imglist));

            Promise.all(promises).then(() => {
                setFiles(imglist);
                setDocs(imglist.length > 0 ? imglist : extFiles);
                _.forEach(imglist, (i) => {

                    //  handleImageAdd(i)
                });
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (index: number) => {
            docs.splice(index, 1);
            setDocs(docs);
        }

        const handleOptionSelect = (event: any, oldOption: number, index: number) => {
            var selOpts = selectedOptions;
            extFiles[docs[index]["extIndex"]].imageType = type;
            _.remove(selOpts, (o) => { return o == oldOption });
            selOpts.push(event.target.value);
            setSelectedOptions(selOpts);
            setKey(Math.floor(Math.random() * 42));
            return selOpts;
        }

        return (
            hasLoaded ?
                <Grid container>
                    <Grid xs={12} item>
                        <Grid container justifyContent={"center"} justifyItems={"Center"}>
                            <FileUploader
                                label="Upload or drop a image here"
                                handleChange={handleImageSet}
                                types={fileTypes}
                                multiple={true}
                                name="file">
                                <BoxUploadWrapper width={"600px"} justifyContent={"center"} justifyItems={"Center"}>
                                    <IconButtonWrapper>
                                        <FolderZip style={{ color: "#00A1DE", fontSize: "48px" }} />
                                    </IconButtonWrapper>
                                    <Typography sx={{ mt: 2 }}>
                                        Drag & Drop
                                    </Typography>
                                    <Typography                                        >
                                        or Click to Select File(s)
                                    </Typography>
                                </BoxUploadWrapper>
                            </FileUploader>
                        </Grid>
                    </Grid >
                    {
                        _.map(docs, (doc, i) => {
                            return <Grid xs={4} item paddingLeft={5}>
                                <DocUploader
                                    file={doc}
                                    fileName={doc.name}
                                    index={i}
                                    docIndex={key + i}
                                    parentCallback={handleImageSet}
                                    handleOptionSelect={handleOptionSelect}
                                    handleRemove={handleRemove}
                                    typeOptions={typeOptions}
                                    selectedOptions={selectedOptions}
                                    setFiles={setDocs}
                                />
                            </Grid>
                        })
                    }
                </Grid > : null
        )
    }

export default observer(UploadZipDragDrop);