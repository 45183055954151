import { action, observable, makeAutoObservable } from 'mobx';

export default class CommentEditStore{
    @observable private _isOpenModal = false;

    constructor() {
        makeAutoObservable(this);
    }
    
    get isOpenModal() {
        return this._isOpenModal;
    }

    @action
    set isOpenModal(value: boolean) {
        this._isOpenModal = value;
    }
    @action
    handleClickModal = () => {
        this.isOpenModal = !this.isOpenModal;
        console.log(this.isOpenModal);
    };

    handleCloseModal = () => {
        this.isOpenModal = false;
    };
}