import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Grid } from '@mui/material';

import { PatientTreatmentModal } from './PatientTreatmentModal';
import { useMainStoreContext } from '../../../stores/MainStore';
import { GlobalUtils } from '../../../api/GlobalUtils';
import { toJS } from 'mobx';

interface IPatientTreatmentPlanning {
    patient: any;
}

export const PatientTreatmentPlanning: React.FC<IPatientTreatmentPlanning> = observer(({ patient }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const {
        setPatientId,
        loadTreatment,
        setSleepCondition,
        setExtraoralCondition,
        setIntraoralCondition,
        setCbctCondition,
        setIntraoralRelationShip,
        setTreatmentObjectives,
        setPrimaryData,
    } = useMainStoreContext().patientTreatmentStore;

    const handleOpen = () => setIsOpen(true);

    const onClosed = () => setIsOpen(false);

    useEffect(() => {
        setPatientId(patient.patientId);
        setSleepCondition(GlobalUtils.getSleepConditions());
        setExtraoralCondition(GlobalUtils.getExtraoralConditions());
        setIntraoralCondition(GlobalUtils.getIntraoralConditions());
        setCbctCondition(GlobalUtils.getCbctConditions());
        setIntraoralRelationShip(GlobalUtils.getMolarRelationships());
        setTreatmentObjectives(GlobalUtils.getTreatmentObjectives());
        setPrimaryData(GlobalUtils.getPrimaryTherapy());

        loadTreatment();
    }, [loadTreatment, patient.id, setPatientId]);

    return (
        <Grid container>
            <Grid item xs={12} container justifyContent="flex-end" marginTop={2}>
                <Button className="vivButton large invert" onClick={handleOpen}>
                    Edit Treatment Plan
                </Button>
                <PatientTreatmentModal patient={patient} isOpen={isOpen} onClose={onClosed} />
            </Grid>
        </Grid>
    );
});
