import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid, Button, SelectChangeEvent, IconButton } from '@mui/material';
import _ from "lodash";
import { StlViewer } from "react-stl-viewer";
import { Delete } from "@material-ui/icons";
import { GlobalUtils } from "../../api/GlobalUtils";

const fileTypes = ["JPG", "PNG"];
const MAXWIDTH = 350;
const MAXHEIGHT = 350;
const MAXCROPPERWIDTH = 100;
const MAXCROPPERHEIGHT = 100;
interface IUploaderProps {
    index: number;
    parentCallback(data64string: string, dataPathUrl: string): any;
    handleRemove(index: number): any;
    image: any;
    key: any;
    container: string;
    containerId: number;
    vivosId: string;
}

const Stl3DViewer: React.FC<IUploaderProps> =
    ({ image, index, key, handleRemove, parentCallback, container, containerId, vivosId}) => {
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [openModal , setOpenModal] = React.useState(false);

        useEffect(() => {
            setHasLoaded(true);
        }, [key])

        const getSource = () => {
            var src = container == process.env.REACT_APP_AIREPORTS_CONTAINER ?
                    GlobalUtils.getAIReportUrl(image.value ? image.value :image.data64Url, vivosId, containerId) :
                    GlobalUtils.getVisitRecordUrl(image.value ? image.value :image.data64Url, vivosId, containerId);
            return src;
        }

        const style = {
            top: 0,
            left: 0,
            width: MAXWIDTH, 
            height: MAXHEIGHT,
            border: '1px solid #7E9DBB',
        }

        return (
            hasLoaded ?
                <Grid key={key} xs={12} container direction="column">
                    <Grid className="" justifyItems={"flex-end"}>
                        <IconButton className="BtnTopRightCorner" sx={{right:"-322px"}}
                            onClick={(event: any) => {
                               handleRemove(index);                               
                            }}>
                            <Delete className="cornerBtn" />
                        </IconButton>
                        <StlViewer
                            style={style}
                            orbitControls
                            shadows
                            url={getSource()}
                        />
                    </Grid>                   
                </Grid>
                : null
        )
    }
export default observer(Stl3DViewer);