// @vendors
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem } from '@mui/material';

// @components
import Item from '../../../Item';
import CssTextField from '../../../CssTextField';
import CssSelectField from '../../../CssSelectField';

// @store
import { useMainStoreContext } from '../../../../stores/MainStore';

interface IDiagnosisPage {
    patient?: any;
}

export const IntraoralConditions: FC<IDiagnosisPage> = observer(() => {
    const {
        intraoralDiagnosis,
        intraoralDiagnosisNotes,
        setIntraoralDiagnosis,
        setIntraoralDiagnosisNotes,
        intraoralCondition,
        intraoralRelationship,
        molarRelationshipsRight,
        setMolarRelationshipsRight,
        molarRelationshipsLeft,
        setMolarRelationshipsLeft,
    } = useMainStoreContext().patientTreatmentStore;

    return (
        <Grid>
            <Grid container xs={12} paddingLeft={4} columnSpacing={0} className="formBasics" marginTop={5}>
                <Grid container direction={'row'} className="formSectionLabel">
                    Intraoral Conditions
                </Grid>
                <Divider variant="middle" />

                <FormControl>
                    <Item>
                        <Grid
                            container
                            display="grid"
                            gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
                        >
                            {intraoralCondition.map(item => {
                                return (
                                    <FormControlLabel
                                        key={item.id}
                                        control={
                                            <Checkbox
                                                checked={intraoralDiagnosis.some(el => el.conditionId === item.id)}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setIntraoralDiagnosis([
                                                            ...intraoralDiagnosis,
                                                            {
                                                                id: item.id,
                                                                name: item.name,
                                                                conditionId: item.id,
                                                                listItemKey: item.listItemKey,
                                                                treatmentObjective: 499,
                                                                treatmentObjectiveName: 'N/A',
                                                                treatmentPlan: [],
                                                                treatmentOptionIds: 0,
                                                            },
                                                        ]);
                                                    } else {
                                                        setIntraoralDiagnosis(
                                                            intraoralDiagnosis.filter(
                                                                (el: any) => el.conditionId !== item.id,
                                                            ),
                                                        );
                                                    }
                                                }}
                                                name={item.listItemKey}
                                                color="primary"
                                            />
                                        }
                                        label={item.name}
                                    />
                                );
                            })}
                        </Grid>
                    </Item>
                </FormControl>
            </Grid>
            <Grid
                xs={12}
                paddingLeft={4}
                columnSpacing={0}
                width={{ xs: '100%', sm: '60%', md: '30%' }}
                display="flex"
                direction="column"
            >
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Select Molar Relationship Right</InputLabel>
                    <CssSelectField
                        value={molarRelationshipsRight[0]?.id || molarRelationshipsRight[0]?.conditionValueB}
                        autoWidth
                        label="Molar Relationship Right"
                        onChange={(e: any) => {
                            setMolarRelationshipsRight([
                                {
                                    id: e.target.value,
                                    conditionId: e.target.value,
                                    name: intraoralRelationship.find((item: any) => item.id === e.target.value)?.name,
                                    listItemKey: intraoralRelationship.find((item: any) => item.id === e.target.value)
                                        ?.listItemKey,
                                    treatmentObjective: 499,
                                    treatmentObjectiveName: '',
                                    treatmentPlan: [],
                                    treatmentOptionIds: 0,
                                },
                            ]);
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {intraoralRelationship.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </CssSelectField>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Select Molar Relationship Left</InputLabel>

                    <CssSelectField
                        autoWidth
                        value={molarRelationshipsLeft[0]?.id || molarRelationshipsLeft[0]?.conditionValueA}
                        onChange={(e: any) => {
                            setMolarRelationshipsLeft([
                                {
                                    id: e.target.value,
                                    conditionId: e.target.value,
                                    name: intraoralRelationship.find((item: any) => item.id === e.target.value)?.name,
                                    listItemKey: intraoralRelationship.find((item: any) => item.id === e.target.value)
                                        ?.listItemKey,
                                    treatmentObjective: 499,
                                    treatmentObjectiveName: '',
                                    treatmentPlan: [],
                                    treatmentOptionIds: 0,
                                },
                            ]);
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {intraoralRelationship.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </CssSelectField>
                </FormControl>
            </Grid>

            <Grid
                container
                xs={12}
                direction="row"
                paddingLeft={4}
                columnSpacing={0}
                className="formBasics"
                marginTop={2}
            >
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                disabled={!intraoralDiagnosis.some(el => el.conditionId === 273)}
                                placeholder="Other Intraoral Conditions Notes"
                                required={false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setIntraoralDiagnosisNotes(event.target.value);
                                }}
                                value={intraoralDiagnosisNotes}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
});
