import * as _ from 'lodash';
import moment from 'moment';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';

import { Gateway } from '../api/Gateway';
import { MainStore } from './MainStore';
import { AIReportsFilters } from '../entities/Enums';
import AIReport from '../entities/AIReports';
import { GlobalUtils } from '../api/GlobalUtils';
import { ITechnician } from '../entities/Technician';
import DictionaryListItem from '../entities/DictionaryListItem';
import PatientImage from '../entities/PatientImage';

export class AIReportStore {
    mainStore: MainStore;
    private _totalPages: number = 1;
    private _currentPage: number = -1;
    private _hasMore: boolean = true;
    private _firstPageLoaded: boolean = false;
    private _open: boolean = false;
    private _isAiReportAdmin = false;
    private _isAiReportTech = false;
    private _isProvider = false;
    private _currentFilter: AIReportsFilters = -1;
    userID: number = 0;
    pageSize: number = 200;
    roles: any = [];

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
            //this.userID = 15;
        }
        this.checkRoles();
    }

    get open(): any {
        return this._open;
    }

    set open(value: any) {
        this._open = value;
    }

    @observable pageTitle = 'AI Report Dashboard';
    @observable isLeftNavExpanded: boolean = false;
    @observable filterNav: string = '*';
    @observable origPatientData: AIReport[] = [];
    @observable patientData: AIReport[] = [];
    @observable didInitPatients = false;
    @observable isLoading = false;
    @observable loadingProgress = 0;
    @observable searchParam = '';
    @observable numPendingCases: number = 0;
    @observable numInProgress: number = 0;
    @observable numAssigned: number = 0;
    @observable numToBeAssigned: number = 0;
    @observable numCompleted: number = 0;
    @observable toggleShowFilterResults: boolean = false;    
    @observable aiStatus: DictionaryListItem[] = [];
    @observable technician: ITechnician[] = [];
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    
    @action setTechnician = (technician: ITechnician[]) => (this.technician = technician);
    @action setAiReportStatus = (aiReportStatus: DictionaryListItem[]) => (this.aiStatus = aiReportStatus);
    @action setPatientData = newPatientData => (this.patientData = newPatientData);
    @action setOrigPatientData = newPatientData => (this.origPatientData = newPatientData);
    @action setFilterNav = (newFilter: string) => (this.filterNav = newFilter);

    @action addTechnician = (technician: ITechnician) => {
        this.technician.push(technician);
    };

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    @action toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };

    get hasMore(): boolean {
        return this._hasMore;
    }

    set hasMore(value: any) {
        this._hasMore = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: any) {
        this._totalPages = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    set currentPage(value: any) {
        this._currentPage = value;
    }

    @observable currentRow: any = {};
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);

    get firstPageLoaded(): boolean {
        return this._firstPageLoaded;
    }

    set firstPageLoaded(value: any) {
        this._firstPageLoaded = value;
    }

    get isAiReportAdmin(): any {
        return this._isAiReportAdmin;
    }

    set isAiReportAdmin(value: any) {
        this._isAiReportAdmin = value;
    }

    get isAiReportTech(): any {
        return this._isAiReportTech;
    }

    set isAiReportTech(value: any) {
        this._isAiReportTech = value;
    }

    get isProvider(): any {
        return this._isProvider;
    }

    set isProvider(value: any) {
        this._isProvider = value;
    }

    get currentFilter(): AIReportsFilters {
        return this._currentFilter;
    }

    set currentFilter(value: any) {
        this._currentFilter = value;
    }

    onFilter = (value, filter: AIReportsFilters) => {
        if (value) {
            this.setPatientData(this.filterPatients(this.origPatientData, filter));
            this.currentFilter = filter;
        } else {
            this.currentFilter = -1;
            this.patientData = this.origPatientData;
        }
    };

    loadData = (): any => {
        var url = '';
        if (this.isAiReportAdmin) {
            var url = 'aireports/' + 0 + '/' + this.pageSize;
        } else if (this.isAiReportTech) {
            var url = 'aireports/technician/' + this.userID + '/' + 0 + '/' + this.pageSize;
        }
        this.numPendingCases = 0;
        this.numAssigned = 0;
        this.numCompleted = 0;
        this.numInProgress = 0;
        this.numToBeAssigned = 0;
        Gateway.get(url).then(resp => {
            if (resp['results'].length > 0) {
                this.totalPages = resp['pageCount'];
                this.setPatientData(resp['results']);
                this.origPatientData = this.patientData;
                // this.getFilterNums();
                this.setNumPatientValues();
                this.setAiReportStatus(GlobalUtils.getAIReportStatuses());
                this.firstPageLoaded = true;
            } else {
                this.totalPages = 1;
                // this.setPatientData(reportData);
                this.origPatientData = this.patientData;
                //this.getFilterNums();
                this.setNumPatientValues();
            }
        });
    };

    filterPatients = (patientData: any, filter: AIReportsFilters) => {
        let data: AIReport[] = [];
        switch (filter) {
            case AIReportsFilters.PendingCases:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status !== 'Completed' && rec.status !== 'Completed - Incomplete') {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.ToBeAssigned:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (
                            rec.status === 'Pending Information' ||
                            rec.status === 'Pending' ||
                            rec.status === 'Submitted'
                        ) {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.CasesInProgress:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status === 'Processing') {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.Assigned:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status === 'Assigned') {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.Completed:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status === 'Completed' || rec.status === 'Completed - Incomplete') {
                            data.push(rec);
                        }
                    }
                });
                break;
        }
        return data;
    };

    setNumPatientValues = async () => {
        _.forEach(this.patientData, p => {
            if (p.status !== 'Completed' && p.status !== 'Completed - Incomplete') {
                this.numPendingCases += 1;
            }
            if (p.status === 'Pending Information' || p.status === 'Pending' || p.status === 'Submitted') {
                this.numToBeAssigned += 1;
            }
            if (p.status === 'Assigned') {
                this.numAssigned += 1;
            }
            if (p.status === 'Processing') {
                this.numInProgress += 1;
            }
            if (p.status === 'Completed' || p.status === 'Completed - Incomplete') {
                this.numCompleted += 1;
            }
        });
    };

    loadMoreItems = () => {
        if (this.currentPage + 1 < this.totalPages) {
            let curpage = this.currentPage < this.totalPages ? this.currentPage + 1 : this.currentPage;
            this.currentPage = this.currentPage + 1;
            var url = 'aireports/' + curpage + '/' + this.pageSize;
            Gateway.get(url).then(resp => {
                this.setPatientData(resp['results']);
                this.origPatientData = this.patientData;
            });
        } else {
            this.hasMore = false;
        }
    };

    loadTechnicianData = () => {
        Gateway.get('teams/user/' + this.userID).then(resp => {
            _.forEach(resp, team => {
                let url = 'teams/users/' + team.teamId;
                Gateway.get(url).then(users => {
                    _.forEach(users, user => {
                        this.addTechnician(user);
                    });
                });
            });
        });
    };

    handleChangeTechnician = (submissionId, technicianId) => {
        let url = `AIReports/${submissionId}/set/${technicianId}`;

        Gateway.post(url, undefined).then(resp => {});

        if (submissionId) {
            setTimeout(() => {
                this.loadData();
            }, 1000);
        }
    };

    handleStatusChange = (submissionId, statusId) => {
        let url = `AIReports/${submissionId}/status/${statusId}`;

        Gateway.post(url, { templateID: 'd-6dcb6807f9614dc7a7d26693eb9320ab' }).then(resp => {});

        if (submissionId) {
            setTimeout(() => {
                this.loadData();
            }, 1000);
        }
    };

    clearData = () => {
        this.patientData = this.origPatientData;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.patientData;
    };

    @action filterBySearchString = () => {
        this.patientData = this.origPatientData.filter(
            x =>
                x.caseNumber.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.vivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.patientName.toString().toLowerCase().includes(this.searchParam.toLowerCase()),
        );
    };

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_ADMIN) {
                this.isAiReportAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_TECH) {
                this.isAiReportTech = true;
            }
        }
        return;
    };

   
}
