// @vendors
import { FC } from 'react';
import { FormControl, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react';

// @components
import { RowObjective } from './RowObjective';
import Item from '../../../Item';
import CssTextField from '../../../CssTextField';
import "../../../../scss/_TreatmentPlanning.scss"

// @store
import { useMainStoreContext } from '../../../../stores/MainStore';

// @enums
import { ExtraoralCondition } from '../../../../entities/Enums';

interface ISleepConditionProps {
    sleepCondition?: string;
}

export const ExtraoralObjectives: FC<ISleepConditionProps> = observer(() => {
    const { extraoralDiagnosis, extraoralDiagnosisNotes, setExtraoralDiagnosisNotes, setExtraoralDiagnosis } =
        useMainStoreContext().patientTreatmentStore;

    return (
        <Grid>
            <Grid>
                <TableContainer className="treatmentTable">
                    <Table sx={{ minWidth: '50%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Extraoral Conditions</TableCell>
                                <TableCell align="right">Correct</TableCell>
                                <TableCell align="right">Improve</TableCell>
                                <TableCell align="right">Maintain</TableCell>
                                <TableCell align="right">N/A</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.HEADACHE) ||
                                extraoralDiagnosis.some(el => el.conditionId === 245)) && (
                                <RowObjective
                                    name="Headache"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(el => el.listItemKey === ExtraoralCondition.HEADACHE)
                                            ?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 245)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.HEADACHE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.ATYPFACEPAIN) ||
                                extraoralDiagnosis.some(el => el.conditionId === 246)) && (
                                <RowObjective
                                    name="Atypical Facial Pain"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(
                                            el => el.listItemKey === ExtraoralCondition.ATYPFACEPAIN,
                                        )?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 246)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.ATYPFACEPAIN) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.MACROGENIA) ||
                                extraoralDiagnosis.some(el => el.conditionId === 249)) && (
                                <RowObjective
                                    name="Macrogenia"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(el => el.listItemKey === ExtraoralCondition.MACROGENIA)
                                            ?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 249)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.MACROGENIA) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.TEMPOMANDJOINTDIS) ||
                                extraoralDiagnosis.some(el => el.conditionId === 250)) && (
                                <RowObjective
                                    name="Tempomandibular Joint Disorder"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(
                                            el => el.listItemKey === ExtraoralCondition.TEMPOMANDJOINTDIS,
                                        )?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 250)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.TEMPOMANDJOINTDIS) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.OTHERSPECDIS) ||
                                extraoralDiagnosis.some(el => el.conditionId === 251)) && (
                                <RowObjective
                                    name="Oher Specified Disorders of TMJ (i.e. pop, click)"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(
                                            el => el.listItemKey === ExtraoralCondition.OTHERSPECDIS,
                                        )?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 251)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.OTHERSPECDIS) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.LIMMANDIROM) ||
                                extraoralDiagnosis.some(el => el.conditionId === 252)) && (
                                <RowObjective
                                    name="Limited Mandibular ROM"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(el => el.listItemKey === ExtraoralCondition.LIMMANDIROM)
                                            ?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 252)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.LIMMANDIROM) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.DEVOPENCLOSEMAND) ||
                                extraoralDiagnosis.some(el => el.conditionId === 253)) && (
                                <RowObjective
                                    name="Deviation in Open/Close of Mandible"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(
                                            el => el.listItemKey === ExtraoralCondition.DEVOPENCLOSEMAND,
                                        )?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 253)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.DEVOPENCLOSEMAND) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.MOUTHBREATHER) ||
                                extraoralDiagnosis.some(el => el.conditionId === 254)) && (
                                <RowObjective
                                    name="Mouth Breather"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(
                                            el => el.listItemKey === ExtraoralCondition.MOUTHBREATHER,
                                        )?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 254)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.MOUTHBREATHER) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.EXTRAORALOTHER) ||
                                extraoralDiagnosis.some(el => el.conditionId === 255)) && (
                                <RowObjective
                                    name="Other"
                                    treatmentObjectiveId={
                                        extraoralDiagnosis.find(
                                            el => el.listItemKey === ExtraoralCondition.EXTRAORALOTHER,
                                        )?.treatmentObjective ||
                                        extraoralDiagnosis.find(el => el.conditionId === 255)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setExtraoralDiagnosis(
                                            extraoralDiagnosis.map(el => {
                                                if (el.listItemKey === ExtraoralCondition.EXTRAORALOTHER) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {(extraoralDiagnosis.some(el => el.listItemKey === ExtraoralCondition.EXTRAORALOTHER) ||
                extraoralDiagnosis.some(el => el.conditionId === 255)) && (
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                placeholder="Other Extraoral Conditions Notes"
                                required={true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setExtraoralDiagnosisNotes(event.target.value);
                                }}
                                value={extraoralDiagnosisNotes}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
});
