import { Grid, IconButton, LinearProgress, Button, TextField, responsiveFontSizes } from "@mui/material";
import React from 'react';
import { LeftNav } from "../../components/LeftNav/LeftNav";
import DataGrid from '../../components/DataGrid/DataGrid';
import Papa from 'papaparse'
import { Gateway } from "../../api/Gateway";
import moment from "moment";
// import "../scss/_global.scss";
interface AddPatientResponse {
    vivosId,
    sucesss,
    messages: string[]
}

const defaultState = {
    Message: "",
    NumberRan: 0,
    NumberSucess: 0,
    NumberFailed: 0
}

function MyoCorrectAdminTools() {
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([{}]);
    const [isUploadingPatients, setUploadingPatients] = React.useState(false);
    const [uploadPatientsProgress, setUploadingPatientsProgress] = React.useState(0);
    const [updaloadState, setUploadState] = React.useState(defaultState);

    const changeHandler = (event) => {
        reset();
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: "greedy",
            transform: function (value: string, header) {
                var returnvalue = value;
                if (header === 'vAire ID') {
                    var digitValue = returnvalue.match(/(?<subfix>VIV(?<slash>-)?)?(?<value>[0-9]+)/)?.groups?.value;
                    returnvalue = 'VIV-' + digitValue;
                }
                return returnvalue;
            },
            complete: function (results) {
                let index = 0;
                let dataSet = results.data.map((obj) => {
                    return { ...obj, id: index++ };
                });
                setData(dataSet);

                var columnsSet = Object.keys(dataSet[0]).map((col) => {
                    let value = { field: col, flex: 1, headerAlign: "center", headerName: col, headerClassName: "colHeader" };
                    return value;
                }).filter(x => x.field !== 'id');
                setColumns(columnsSet);
            },
        });
    };

    const reset = () => {
        updaloadState.NumberFailed = 0;
        updaloadState.NumberRan = 0;
        updaloadState.NumberSucess = 0;
        updaloadState.Message = "";
        setUploadState(updaloadState);
        setUploadingPatients(false);
        setUploadingPatientsProgress(0);
        setColumns([]);
        setData([]);
    }

    const uploadPatients = async () => {
        setUploadingPatients(true);
        setUploadingPatientsProgress(0);
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            var request = {
                VivosId: element['vAire ID'],
                ScheduleDateTime: element['Initial Evaluation Date'] ? moment(element['Initial Evaluation Date']) : '',
                CalendlyUri: "",
                CalendlyRescheduleUri: "",
                AdvisorEmail: "",
                AdvisorName: element['Assigned Advisor'],
                AditionalNotes: element['Additional Notes'],
                Results: ""
            }
            var response = await Gateway.postNonAudit('myocorrect/addpatient', request) as AddPatientResponse;
            if (response.sucesss === true) {
                updaloadState.NumberSucess++;

            }
            else {
                updaloadState.NumberFailed++;
            }

            if (response.messages.length > 0) {
                response.messages.forEach((message) => {
                    updaloadState.Message += "id:  " + response.vivosId + " " + message + "\r\n";
                });
            }

            updaloadState.NumberRan++;
            setUploadState(updaloadState);
            setUploadingPatientsProgress(((index + 1) / data.length) * 100);
        }
    }

    return (
        <Grid container direction={"row"} xs={12} >
            <Grid xs={2}><LeftNav onToggle={() => { }}></LeftNav></Grid>
            <Grid xs={10}>
                <Grid xs={12} container sx={{ width: "90%", justifyContent: "center", margin: "25px" }}>
                    <Grid className="modalHeader" container sx={{ width: "100%", justifyContent: "center" }}><Grid className="title">ADMIN TOOLS</Grid></Grid>
                </Grid>
                <Grid xs={12} sx={{ justifyContent: "center", margin: "25px" }}><IconButton id="appointments.cell.btn.notes" onClick={() => { }}></IconButton><input type="file" accept=".csv" onChange={changeHandler} /></Grid>
                <Grid xs={12} direction='row' sx={{ justifyContent: "center", margin: "25px" }}>

                    <Grid xs={10} container direction='row'><Grid>Number ran:</Grid><Grid sx={{ paddingLeft: '10px' }}>{updaloadState.NumberRan}</Grid></Grid>
                    <Grid xs={10} container direction='row'><Grid>Number success:</Grid><Grid sx={{ paddingLeft: '10px' }}>{updaloadState.NumberSucess}</Grid></Grid>
                    <Grid xs={10} container direction='row'><Grid>Number failed:</Grid><Grid sx={{ paddingLeft: '10px' }}>{updaloadState.NumberFailed}</Grid></Grid>
                    <Grid xs={10} container direction='row'><Grid>Progress</Grid><Grid sx={{ paddingLeft: '10px' }}>{uploadPatientsProgress}%</Grid></Grid>
                    <Grid xs={10} container direction='row'><Grid><Button className="vivButton large invert" onClick={uploadPatients}>Upload</Button></Grid>
                        <Grid><Button className="vivButton large invert" onClick={reset}>Reset</Button></Grid></Grid>

                </Grid>
                <Grid xs container direction='row' sx={{ margin: "25px" }}><textarea rows={10} disabled style={{ width: '80%' }} value={updaloadState.Message}></textarea></Grid>
                <Grid xs={12} className="grid">
                    {isUploadingPatients &&
                        <LinearProgress variant="determinate" value={uploadPatientsProgress} />
                    }
                    {data.length > 0 &&
                        <DataGrid
                            columns={columns}
                            data={data} />}
                </Grid>
            </Grid>

        </Grid>
    )

}


export default MyoCorrectAdminTools;