import { Gateway } from './Gateway';
import * as _ from 'lodash';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';
import ProviderPatient from '../entities/ProviderPatient';
import Patient from '../entities/Patient';

export class PatientStore {
    @observable private _basePatientData: any[] = [];
    @observable private _patientData: any[] = [];
    @observable private _resultCount: number = 0;
    @observable private _toggleShowSearch: boolean = false;
    @observable private _toggleShowFilterResults: boolean = false;
    @observable private _toggleShowQuickddPatientsModal: boolean = false;
    @observable private _toggleShowAddPatientsModal: boolean = false;
    @observable private _toggleShowAddPatientFilesModal: boolean = false;
    @observable private _toggleShowCancelWarningModal: boolean = false;
    @observable private _toggleShowPrescriptionModal: boolean = false;
    @observable private _toggleShowPatientEditModal: boolean = false;
    @observable private _toggleShowAiReportOrderModal: boolean = false;
    @observable private _currentPatientId: number = 0;
    @observable private _currentPatient: {} = {};
    @observable private _totalPages: number = 1;
    @observable private _isLeftNavExpanded: boolean = false;
    @observable private _hasMore: boolean = true;
    @observable private _firstPageLoaded: boolean = false;
    @observable private _currentPage: number = -1;
    @observable private _selectedVivosId: string = '';
    @observable private _currentRow: any;
    @observable private _currentSubNavItem: number = 0;
    @observable didInitPatients = false;
    @observable isLoadingPatients = false;
    @observable loadingPatientsProgress = 0;
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable searchParam = '';

    userId: number = 0;
    userRoles: any;
    pageSize: number = 200;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    get origPatientData(): ProviderPatient[] {
        return this._basePatientData;
    }

    @action
    set origPatientData(value: any[]) {
        this._basePatientData = value;
    }

    get selectedVivosId(): string {
        return this._selectedVivosId;
    }

    @action
    set selectedVivosId(value: string) {
        this._selectedVivosId = value;
    }

    get currentRow(): any {
        return this._currentRow;
    }

    @action
    set currentRow(value: any) {
        this._currentRow = value;
    }
    get patientData(): ProviderPatient[] {
        return this._patientData;
    }

    @action
    set patientData(value: any[]) {
        this._patientData = value;
    }

    get firstPageLoaded(): boolean {
        return this._firstPageLoaded;
    }

    @action
    set firstPageLoaded(value: any) {
        this._firstPageLoaded = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    @action
    set currentPage(value: any) {
        this._currentPage = value;
    }

    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    @action
    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    @action
    set totalPages(value: any) {
        this._totalPages = value;
    }

    get resultCount() {
        return this._resultCount;
    }

    @action
    set resultCount(value: any) {
        this._resultCount = value;
    }

    get toggleShowSearch() {
        return this._toggleShowSearch;
    }

    @action
    set toggleShowSearch(value: any) {
        this._toggleShowSearch = value;
    }

    get toggleShowFilterResults() {
        return this._toggleShowFilterResults;
    }

    @action
    set toggleShowFilterResults(value: any) {
        this._toggleShowFilterResults = value;
    }

    get toggleShowAddPatientsModal() {
        return this._toggleShowAddPatientsModal;
    }

    @action
    set toggleShowAddPatientsModal(value: any) {
        this._toggleShowAddPatientsModal = value;
    }

    get toggleShowAddPatientFilesModal() {
        return this._toggleShowAddPatientFilesModal;
    }

    @action
    set toggleShowQuickddPatientsModal(value: any) {
        this._toggleShowQuickddPatientsModal = value;
    }

    get toggleShowQuickddPatientsModal() {
        return this._toggleShowQuickddPatientsModal;
    }

    @action
    set toggleShowAddPatientFilesModal(value: any) {
        this._toggleShowAddPatientFilesModal = value;
    }

    get toggleShowPatientEditModal() {
        return this._toggleShowPatientEditModal;
    }

    @action
    set toggleShowPatientEditModal(value: any) {
        this._toggleShowPatientEditModal = value;
    }

    get toggleShowCancelWarningModal() {
        return this._toggleShowCancelWarningModal;
    }

    @action
    set toggleShowCancelWarningModal(value: any) {
        this._toggleShowCancelWarningModal = value;
    }

    get toggleShowPrescriptionModal() {
        return this._toggleShowPrescriptionModal;
    }

    @action
    set toggleShowPrescriptionModal(value: any) {
        this._toggleShowPrescriptionModal = value;
    }

    @computed
    get toggleShowAiReportOrderModal() {
        return this._toggleShowAiReportOrderModal;
    }

    set toggleShowAiReportOrderModal(value: any) {
        this._toggleShowAiReportOrderModal = value;
    }

    get isLeftNavExpanded() {
        return this._isLeftNavExpanded;
    }

    @action
    set isLeftNavExpanded(value: any) {
        this._isLeftNavExpanded = value;
    }

    get currentPatientId() {
        return this._currentPatientId;
    }

    @action
    set currentPatientId(value: any) {
        this._currentPatientId = value;
    }

    get currentPatient() {
        return this._currentPatient;
    }

    @action
    set currentPatient(value: any) {
        this._currentPatient = value;
    }

    get hasMore(): boolean {
        return this._hasMore;
    }

    @action
    set hasMore(value: any) {
        this._hasMore = value;
    }

    @action
    onFilter = (filters: any): any => {
        this._patientData = this.filterResults(this.origPatientData, filters);
        this.resultCount = this.patientData.length;
    };

    @action
    onFilterCancel = () => {
        this.toggleFilterResultsExpand();
        this.patientData = this.origPatientData;
        this.resultCount = this.origPatientData.length;
    };

    @action
    toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };

    @action
    handleCancelClose = (doCancel: boolean) => {
        // this.toggleCancelWarningModal();
        // if (doCancel === true) {
        this.toggleAddPatientModal();
        // }
    };

    @action
    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this._isLeftNavExpanded = isLeftNavExpanded;
    };

    @action
    toggleAddPatientModal = () => {
        runInAction(() => {
            this.toggleShowAddPatientsModal = !this.toggleShowAddPatientsModal;
        });
    };

    @action
    toggleQuickAddModal = () => {
        runInAction(() => {
            this.toggleShowQuickddPatientsModal = !this.toggleShowQuickddPatientsModal;
        });
    };

    @action
    toggleEditPatientModal = () => {
        runInAction(() => {
            this.toggleShowPatientEditModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    toggleCancelWarningModal = () => {
        runInAction(() => {
            this.toggleShowCancelWarningModal = !this.toggleShowCancelWarningModal;
        });
    };

    @action
    togglePrescriptionModal = () => {
        runInAction(() => {
            this._toggleShowPrescriptionModal = !this._toggleShowPrescriptionModal;
        });
    };

    @action
    toggleAiReportOrderModal = () => {
        runInAction(() => {
            this._toggleShowAiReportOrderModal = !this._toggleShowAiReportOrderModal;
        });
    };

    @action
    setCurrentPatientId = id => {
        runInAction(() => {
            this._currentPatientId = id;
        });
    };

    @action
    searchForPatient = () => {
        this.toggleShowSearch = !this.toggleShowSearch;
    };

    loadAll = async () => {
        runInAction(() => {
            this.didInitPatients = true;
            this.isLoadingPatients = true;
        });
        const patients: ProviderPatient[] = [];
        var url = '/patient/' + this.userId + '/' + 0 + '/' + this.pageSize;
        const resp = await Gateway.get(url);
        const pages = resp['pageCount'];
        const totalPatients = resp['rowCount'];
        patients.push(...resp['results']);
        if (totalPatients !== 0) {
            runInAction(() => {
                this.loadingPatientsProgress = (patients.length / totalPatients) * 100;
            });
        }
        //this.getFilterNums();

        const promises: Promise<any[]>[] = [];
        let currentPage = 1;
        while (currentPage <= pages) {
            promises.push(
                Gateway.get(`/patient/${this.userId}/${currentPage}/${this.pageSize}`).then(response => {
                    patients.push(...response['results']);
                    runInAction(() => {
                        this.loadingPatientsProgress = (patients.length / totalPatients) * 100;
                    });
                    return response;
                }),
            );
            currentPage++;
        }
        await Promise.all(promises);

        runInAction(() => {
            this.isLoadingPatients = false;
            this.patientData = patients;
            this.origPatientData = this.patientData;
            //this.setNumPatientValues();
        });
    };

    loadData = (): any => {
        this.loadAll();
        var url = 'patient/' + this.userId + '/' + 0 + '/' + this.pageSize;

        Gateway.get(url).then(resp => {
            this.totalPages = resp['pageCount'];
            this.patientData = resp['results'];
            this.origPatientData = this.patientData;
            this.firstPageLoaded = true;
            //this.setNumPatientValues();
        });
        // this.setNumPatientValues();
    };

    // @action
    // loadPatient = () => {
    //     Gateway.get('/patient/' + this.currentPatientId).then(data => {
    //         this.currentPatient = data;
    //     });
    // };

    onSubNavClick = (filter: number, selectedSubNavItem: number) => {
        this.currentSubNavItem = selectedSubNavItem;
        if (filter === 0) {
            this._patientData = this.origPatientData;
            return;
        }
        this._patientData = _.filter(this.origPatientData, p => {
            return p.treatmentStatusId == filter;
        });
    };
    static TO_NAME = 1;
    static TO_ABBREVIATED = 2;
    filterResults = (patientData: any, filters: any) => {
        var filteredData = _.filter(patientData, p => {
            var ts = filters.treatmentStatus >= 0;
            var ms = filters.myoCorrectStatus >= 0;
            var ais = filters.aiReportStatus >= 0;
            var isp = filters.isProspective >= 0;
            if (ts || ms || ais || isp) {
                if (ts) {
                    if (ms && ais && isp) {
                        return (
                            ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect)) &&
                            p.treatmentStatus === filters.treatmentStatus &&
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    if (ms && ais) {
                        return (
                            p.treatmentStatus === filters.treatmentStatus &&
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    if (ms) {
                        return (
                            p.treatmentStatus === filters.treatmentStatus &&
                            p.myoCorrectStatus === filters.myoCorrectStatus
                        );
                    }
                    if (ais) {
                        return (
                            p.treatmentStatus === filters.treatmentStatus && p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    return p.treatmentStatus === filters.treatmentStatus;
                } else if (ms) {
                    if (ais && isp) {
                        return (
                            ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect)) &&
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    } else if (ais) {
                        return (
                            p.myoCorrectStatus === filters.myoCorrectStatus &&
                            p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    return p.myoCorrectStatus === filters.myoCorrectStatus;
                } else if (ais) {
                    if (isp) {
                        return (
                            ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect)) && p.aiReportStatus === filters.aiReportStatus
                        );
                    }
                    return p.aiReportStatus === filters.aiReportStatus;
                } else if (isp) {
                    return ((filters.isProspective === 1 && p.isProspect) || (filters.isProspective === 2 && !p.isProspect));
                }
            }
        });
        return (filters.treatmentStatus >= 0 || filters.myoCorrectStatus >= 0 || filters.aiReportStatus >= 0 || filters.isProspective >= 0) &&
            filteredData.length >= 0
            ? filteredData
            : patientData;
    };

    togglePatientProfileModal = () => {
        //     runInAction(() => {
        //         this.toggleShowDetailsPanelModal = !this.toggleShowDetailsPanelModal;
        //         if (!this.toggleShowDetailsPanelModal) {
        //             this.loadPatient(this.currentRow.vivosId);
        //         }
        //     })
    };

    @action
    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.patientData;
    };

    @action filterBySearchString = () => {
        this.patientData = this.origPatientData.filter(
            x =>
                x.vivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.firstName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.lastName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) 
        );
    };

    @action
    currentRowToPerson = (currentRow: any) => {
        var person = new Patient({
            id: currentRow.id,
            orgId: currentRow.organizationId,
            providerId: currentRow.providerId,
            vivosId: currentRow.vivosId,
            firstName: currentRow.firstName,
            middleName: currentRow.middleName,
            lastName: currentRow.lastName,
            dateOfBirth: currentRow.dateOfBirth,
            ethnicityId: currentRow.ethnicityId,
            isProspect: currentRow.isProspect,
            isLincare: currentRow.isLincare,
            genderId: currentRow.genderId,
            ehrsId: currentRow.ehrsId,
            primaryPhone: currentRow.primaryPhone,
            altPhone: currentRow.altPhone,
            emailAddress: currentRow.emailAddress,
        });
        return person;
    };

    @action
    updatePerson = (currentRow: any) => {
        var person = this.currentRowToPerson(currentRow);
        Gateway.post('/patient/person/' + person.id, person).then();
    };
}
