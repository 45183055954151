import * as React from 'react';
import _ from "lodash";
import { observer } from "mobx-react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  styled,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  Button,
  TextField,
  Box,
  Grid,
  Avatar,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete
} from "@mui/material";
import { Allergies, Ethnicity, Gender } from '../../entities/Enums'
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { isMinor, getAgeDisplayString } from '../../api/getAge';
import { useMainStoreContext } from '../../stores/MainStore';
import { stateLabelValues } from "../../entities/Enums"
import Item from "../Item";
import LegalGuardian from "../LegalGuardian";
import LegalGuardianData from "../../entities/LegalGuardian";
import CssAutoComplete from '../CssAutoComplete';
import moment from 'moment';

const LGDEFAULTVALUEPARENT = 18;
const defaultLGValues = {
  firstName: "",
  lastName: "",
  relationshipTypeId: LGDEFAULTVALUEPARENT,
  emailAddress: "",
  primaryPhone: "",
  altPhone: ""
}

interface AutoCompleteOptionType {
  inputValue?: string;
  name: string;
}
const Input = styled('input')({
  display: 'none'
});

const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const roles = [
  { label: 'Administrator', value: 'admin' },
  { label: 'Subscriber', value: 'subscriber' },
  { label: 'Customer', value: 'customer' }
];

const PatientPersonalInformation = () => {
  // const { firstName, lastName, dateOfBirth, genderId, ethnicityId, isProspect, address1, address2, city, state, postalCode,
  //   emailAddress, primaryPhone, altPhone, legalGuardians, error, isLincare,
  //   setFirstName, setLastName, setDateOfBirth, setGenderId, setEthnicityId, setIsProspect, setAddress1, setAddress2,
  //   helperFirstNameText, helperLastText, helperGenderText, helperDOBText, helperEthnicityText, helperAddress1Text, helperAddress2Text,
  //   helperCityText, helperStateText, helperPostalCodeText, helperEmailText, helperPrimaryPhoneText, helperAltPhoneText, ethnicities,
  //   setCity, setState, setPostalCode, setEmailAddress, setPrimaryPhone, setAltPhone, setLegalGuardians, setIsLincare,
  //   setError } = useMainStoreContext().createPatientStore;

  const { firstName, lastName, dateOfBirth, genderId, ethnicityId, isProspect, emailAddress, primaryPhone, altPhone, legalGuardians, error, isLincare,
    setFirstName, setLastName, setDateOfBirth, setGenderId, setEthnicityId, setIsProspect, setAddress1, setAddress2,
    setCity, setState, setPostalCode, setEmailAddress, setPrimaryPhone, setAltPhone, setLegalGuardians, setIsLincare,
    setError } = useMainStoreContext().createPatientStore;

  const genders = [
    { id: 22, label: "Female" },
    { id: 23, label: "Male" },
  ];

  const ethnicities = [
    { id: 844, label: "American Indian or Alaska Native" },
    { id: 27, label: "Asian" },
    { id: 28, label: "Black or African America" },
    { id: 24, label: "Caucasian (European, Middle Eastern, or North African Ancestry)" },
    { id: 25, label: "Hispanic or Latino" },
    { id: 29, label: "Native Hawaiian or Other Pacific Islander" },
    { id: 30, label: "Prefer Not To Say" },
  ];

  const { addGuardian, getGuardians, removeGuardian, guardiansReset } = useMainStoreContext().createLegalGuardianStore;

  const storeProvider = useMainStoreContext().providerStore;

  const filter = createFilterOptions<AutoCompleteOptionType>();
  const [otherConcernText, setOtherConcernText] = React.useState('');
  const [errorLast, setErrorLast] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);
  const LEGAL_ADULT_AGE: number = 18;
  const MAX_LEGAL_GUARDIAN: number = 2;

  const [constructorHasRun, setConstructorHasRun] = React.useState(false);
  const [legalGuardianElems, setLegalGuardiansElems] = React.useState([]);

  const [isAdultValidation, setIsAdultValidation] = React.useState(false);
  const [isLinCareProvider, setIsLinCareProvider] = React.useState(false);

  React.useEffect(() => {
    constructor();
    if (moment(dateOfBirth).isSame(new Date(), "day")) {
      setDateOptions(dateOfBirth);
    }
    storeProvider.getProviderTypes().then(() => {
      setIsLinCareProvider(storeProvider.providerTypes.filter(x => x.listItemKey === 'LINCARE').length === 1);
    });
  }, [])

  const getAge = (dateOfBirth: Date): number => {
    const today = new Date();
    const date = new Date(dateOfBirth);
    let age = today.getFullYear() - date.getFullYear();
    const months = today.getMonth() - date.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  };

  const constructor = () => {
    if (constructorHasRun) { return; }

    setConstructorHasRun(true);
  }

  const stateMap = (): any => {
    var states = _.map(stateLabelValues, (s, i) => {
      return { id: s.value, label: s.label }
      //return <MenuItem key={i} value={s.value}>{s.label}</MenuItem>
    });
    return states;
  }

  // const handleConcernOtherChange = (value: any) => {
  //   setAreasOfConcern(value);
  // }

  const deleteGuardian = (index) => {
    if (isMinor(dateOfBirth) && getGuardians().length > 1) {
      removeGuardian(index);
      setLegalGuardians(getGuardians());
      generateLegalGuardianElems();
      setConstructorHasRun(true);
    }
  }

  const generateLegalGuardianElems = (): JSX.Element[] => {
    var guardians: any = getGuardians().map((lgu, index) => {
      return <Grid><LegalGuardian index={index} onDelete={deleteGuardian} /></Grid>
    });
    setLegalGuardiansElems(guardians);
    return guardians;
  }

  const setDateOptions = (newValue: any) => {
    if (isMinor(newValue)) {
      setIsAdultValidation(false);
      setCity("");
      setState("");
      setPostalCode("");
      setEmailAddress("")
      setPrimaryPhone("");
      setAltPhone("");
    }
    else {
      setIsAdultValidation(true);
    }

    if (isMinor(newValue) && legalGuardians.length === 0) {
      addGuardian(new LegalGuardianData(
        defaultLGValues
      ));
    } else if (!isMinor(newValue)) {
      guardiansReset();
    }
    setLegalGuardians(getGuardians());
    generateLegalGuardianElems();
    setDateOfBirth(newValue as Date);
  }

  const phoneRegExp = /^[0-9]{10}$/; // Example: 1234567890 (10 digits)

  return (
    <Grid>
      <Grid paddingLeft={3} paddingRight={3} paddingTop={3} sx={{ flexGrow: 1 }}>
        <Typography variant='h3'>Patient Information</Typography>
        <Formik
          initialValues={{
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            dateOfBirth: dateOfBirth,
            gender: '',
            primaryPhone: primaryPhone,
            altPhone: altPhone,
            ethnicity: '',
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .max(255)
              .required('Field is required'),
            lastName: Yup.string()
              .max(255)
              .required('Field is required'),
            emailAddress: Yup.string()
              .email('The email provided should be a valid email address')
              .max(255)
              .notRequired(),
            dateOfBirth: Yup.date()
              .nullable()
              .required('The birthday field is required')
              .max(new Date(), 'The birthday cannot be in the future'),
            gender: Yup.string() // Define validation rules for the gender field
              .required('Gender is required'),
            primaryPhone: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid') //Currently not activating
              .notRequired(),
            altPhone: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid') //Currently not activating
              .notRequired(),
          })}
          onSubmit={() => { }}
        >
          {/* formik props */}
          {({
            errors,
            handleBlur,
            handleChange,
            // handleSubmit,
            // isSubmitting,
            touched,
            values
          }) => (
            <form autoComplete='new-password'>
              <Grid container spacing={3} paddingLeft={3} paddingRight={3} paddingTop={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(
                      touched.firstName && errors.firstName
                    )}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label='First Name'
                    name="firstName"
                    required={true}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      setFirstName(event.target.value);
                    }}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(
                      touched.lastName && errors.lastName
                    )}
                    fullWidth
                    required={true}
                    helperText={touched.lastName && errors.lastName}
                    label='Last name'
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      setLastName(event.target.value);
                    }}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="gender"
                    autoComplete={false}
                    freeSolo
                    autoSelect={false}
                    options={genders}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onChange={(event, value: unknown) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      var val = value as any;
                      setGenderId(val.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="gender"
                        name='gender'
                        label='Gender'
                        required={true}
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder="Select Gender..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={10} sm={10}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date of Birth"
                          value={dateOfBirth}

                          openTo="day"
                          disableFuture={true}
                          views={['year', 'month', 'day']}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              id="date-of-birth"
                              label="Date of Birth"
                              name='dateOfBirth'
                              fullWidth
                              required={true}
                              value={values.dateOfBirth}
                            />}
                          onChange={(newValue) => {
                            setDateOptions(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <TextField
                        id="age"
                        label="Age"
                        fullWidth
                        disabled
                        value={dateOfBirth ? getAge(dateOfBirth) : ''} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="patientadd.cbo.ethnicityid"
                    autoComplete={false}
                    freeSolo
                    autoSelect={false}
                    options={ethnicities}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    // value={values.ethnicity}
                    onChange={(event, value: unknown) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      var val = value as any;
                      setEthnicityId(val.id);
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="patientadd.txt.ethnicityid"
                        label='Ethnicity'
                        required={false}
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder="Select an Ethnicity..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.emailAddress && errors.emailAddress)}
                        fullWidth
                        helperText={touched.emailAddress && errors.emailAddress}
                        label='Email address'
                        name="emailAddress"
                        required={false}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Call handleChange to handle Formik's state
                          handleChange(event);
                          // Call patient creation store update
                          setEmailAddress(event.target.value);
                        }}
                        value={values.emailAddress}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                        helperText={touched.primaryPhone && errors.primaryPhone}
                        id="home-phone" //Home and primary interchangeable?
                        label="Primary Phone" //Home and primary interchangeable?
                        name="primaryPhone"
                        type='tel'
                        fullWidth
                        required={false}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Call handleChange to handle Formik's state
                          handleChange(event);
                          // Call patient creation store update
                          setPrimaryPhone(event.target.value);
                        }}
                        value={values.primaryPhone} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        error={Boolean(touched.altPhone && errors.altPhone)}
                        helperText={touched.altPhone && errors.altPhone}
                        id="mobile-phone"
                        label="Mobile Phone"
                        name="altPhone"
                        type='tel'
                        fullWidth
                        required={false}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Call handleChange to handle Formik's state
                          handleChange(event);
                          // Call patient creation store update
                          setAltPhone(event.target.value);
                        }}
                        value={values.altPhone} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </form>
          )}
        </Formik>
      </Grid>
      {(isMinor(dateOfBirth) && getAge(dateOfBirth) != 0 && getGuardians().length <= MAX_LEGAL_GUARDIAN)
        ?
        <Grid container spacing={3} padding={3}>
          <Grid item xs={12}>
            {/* <Grid container direction="column"> */}
            <hr />
            {
              legalGuardianElems
            }
            {/* </Grid> */}
          </Grid>
          <Grid item justifyContent={"left"}>
            <Button variant='contained'
              hidden={getGuardians().length >= MAX_LEGAL_GUARDIAN}
              onClick={() => {
                if (getGuardians().length < MAX_LEGAL_GUARDIAN)
                  addGuardian(new LegalGuardianData(
                    defaultLGValues
                  ));
                setLegalGuardians(getGuardians());
                generateLegalGuardianElems();
              }} >
              {'[ + ] Add Legal Guardian'}
            </Button>
          </Grid>
        </Grid>
        : ''}
    </Grid>
  );
};
export default observer(PatientPersonalInformation);


