import { Grid } from '@mui/material';
import { useEffect, FC } from "react";
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useMainStoreContext } from '../../../stores/MainStore';
import { _allowStateChangesInsideComputed } from 'mobx';
import * as _ from 'lodash';
import CssSelectField from '../../../components/CssSelectField';
import { MenuItem } from '@material-ui/core';

interface IGIProps {
    submissionId: number;
}

export const AIReportAddons: FC<IGIProps> = observer(
    ({ submissionId }) => {
        const [hasLoaded, setHasLoaded] = useState(false);
        const { submission, addOnTypes, aiReportStatuses, addOnStatusChange } = useMainStoreContext().aiReportEntryStore;

        useEffect(() => {
            setHasLoaded(true);
        }, [])

        const handleAddOnChange = (event: any, addOnId: number) => {
            //  addOnStatusChange(event.target.value, addOnId);
        }

        return (
            <Grid sx={{ margin: "25px 0px" }} className="modalPageHeight autoScroll" xs={12} container direction="column">
                {
                    _.map(submission.addOns, (addOn) => {
                        return (
                            <Grid xs={6} sx={{ margin: "25px" }} container direction="row" className="center">
                                <Grid xs={3} sx={{ margin: "5px 10px" }}>{_.find(addOnTypes, ((a) => { return a.id == addOn.addOnType }))?.name}</Grid>
                                <Grid xs={2} sx={{ margin: "5px 10px" }}>
                                    <CssSelectField
                                        className='TextBox'
                                        onChange={e => {
                                            handleAddOnChange(e, addOn.id)
                                        }}
                                        value={addOn.statusId}>
                                        {_.map(aiReportStatuses, (s) => {
                                            return (<MenuItem value={s.id}>{s.name}</MenuItem>)
                                        })}
                                    </CssSelectField>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>

        );
    });


