import React, { useCallback, useEffect } from "react";
import * as _ from 'lodash';
import moment from 'moment';
import { Grid } from "@mui/material";
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { DataGridBL } from "./DataGridBL";
window.moment = moment;

const gridStyle = { minHeight: "500px", height:"calc(100vh - 350px)", marginBottom: "10", marginLeft: "5", marginRight: "5", textAlign: 'center', align: 'center' }

const DataGrid = (props) => {
    let gridBL: DataGridBL = props.gridBL;
    const apiRef: React.MutableRefObject<any> = useGridApiRef();
    const getDetailPanelContent = useCallback(
        (row) => <div>{React.cloneElement(props.detailPanel, {
            row: row
        })}</div>,
        [],
    );

    useEffect(() => {
        gridBL.apiRef = apiRef;
    })

    const getDetailPanelHeight = React.useCallback(() => 400, []);

    return (
        <Grid  id={`${props.parendId}_${props.userId}`} className="dataGrid" sx={{ gridStyle }}>
            <DataGridPro
            style={{minHeight: "500px", height:"calc(100vh - 350px)"}}
                getRowId={props.getRowId}
                columns={props.columns}
                rows={toJS(props.data)}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                rowThreshold={props.rowThreshold}
                loading={props.loading}
                hideFooter={props.hideFooter}
                onDetailPanelExpandedRowIdsChange={gridBL.togglePanel}
                sx={{
                    '& .MuiDataGrid-detailPanel': {
                        height: '100%'
                    }
                }}
            />
        </Grid>
    );
}

export default observer(DataGrid);

