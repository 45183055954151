import React, { Component } from 'react';
import '../../scss/main.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import squaregrey from '../../imgs/tmp/squaregrey.png';
import squareyellow from '../../imgs/tmp/squareyellow.png';
import { Grid } from '@mui/material';
import { Link } from "react-router-dom";
import { SubNavItem } from './SubNavItem';

interface NavItemProps {
    id: number;
    text: string;
    subItems: any[];
    selected: boolean;
    addText?: string;
    showAddNew?: string;
    url: string;
    isCollapsed: boolean;
    useLinks: boolean;
    numItems: number;
    bl?: any;
    onClick(event: any): any;
    onClickNew(): any;
    onAddClick(): any;
}

@observer
export default class NavItem extends Component<NavItemProps>  {

    static displayName = NavItem.name;
    @observable showAddNew: boolean = false;
    id: number = -1;
    subItems: any[] = [];
    subitemcount: number = 0;
    numDigits: number = 0;

    constructor(props: any) {
        super(props);
        this.showAddNew = this.props.showAddNew === "true";
        this.id = this.props.id;
    }

    onClick = (event: any) => {
        this.props.onClick(event);
    }

    render() {
        this.subitemcount = 0;
        var mappedItems: JSX.Element[] = _.map(this.props.subItems, (i, key) => {
            this.subitemcount += i.count;
            this.numDigits = this.subitemcount.toString().length;
            return <SubNavItem key={key} index={key} currentSubNavItem={this.props.bl ? this.props.bl.currentSubNavItem : -1} isLink={this.props.useLinks} label={i.label} count={i.count} filter={i.filter} numItems={i.count} onClick={this.props.bl ? this.props.bl.onSubNavClick : () => {}} />
        });

        return (
            <Grid className="navItem" xs={12} >
                <Grid className={"navItemHeader" + (this.props.selected ? " selected" : " notSelected") + ((this.props.isCollapsed) ? " collapsed" : "")}>
                    <Grid>
                        <Link className={"link collapsed"} to={this.props.url}><img src={this.props.selected ? squareyellow : squaregrey} alt="selected"  /></Link>
                        {((this.props.numItems > 0 || this.subitemcount > 0) && this.props.isCollapsed) && <Grid className={"itemCount big itemCollapse" + ((this.numDigits == 3) + " threedigitbig")}>{this.props.numItems > 0 ? this.props.numItems : (this.subitemcount > 0 ? this.subitemcount : 0) }</Grid>}
                    </Grid>
                    <Grid onClick={this.onClick}>
                        {!this.props.isCollapsed &&
                            <Link className={"link expanded"} to={this.props.url}>{this.props.text.toUpperCase()}</Link>
                        }
                    </Grid>
                    {(this.props.numItems > 0 && !this.props.isCollapsed) && <Grid className={"itemCount big"}>{this.props.numItems}</Grid>}
                </Grid>
                <Grid className={"subItems" + (((this.props.selected && !this.props.isCollapsed) && mappedItems.length > 0) ? " expanded" : " collapsed")} >
                    <Grid sx={{ marginBottom: "25px" }}>{mappedItems}</Grid>
                    <Grid>{!this.props.isCollapsed && <Grid className={"addNew" + (this.showAddNew ? "" : " hidden")} onClick={this.props.onAddClick}>{this.props.addText}</Grid>}</Grid>
                </Grid>
            </Grid>
        );
    }
}