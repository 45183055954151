// Vendors
import { useState } from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    Card,
    Divider,
    Container,
    Tooltip,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TableContainer,
    styled,
    TextField,
    MenuItem
} from '@mui/material';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';

// Components
import Logo from '../UI/LogoSign';

// API
import { format } from 'date-fns';

import CssTextField from '../CssTextField';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    background: ${theme.colors.alpha.black[5]};
  `
);

const TableWrapper = styled(Box)(
    ({ theme }) => `
    border: 1px solid ${theme.colors.alpha.black[10]};
    border-bottom: 0;
    margin: ${theme.spacing(4)} 0;
  `
);

const LogoWrapper = styled(Box)(
    () => `
      width: '52px'
  `
);

export const AiReportPayment = () => {
    const [shipping, setShipping] = useState({
        shipToName: '',
        company: '',
        locationType: {
            business: false,
            patientHome: false,
        },
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        homePhone: '',
        mobilePhone: '',
    });


    const [locationType, setLocationType] = useState({
        business: false,
        patientHome: false,
    });

    const handleLocationType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocationType({ ...locationType, [event.target.name]: event.target.checked });
    };

    const handleShipping = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShipping({ ...shipping, [event.target.name]: event.target.value });
    };

    const { shipToName, company, streetAddress, city, state, zip, email, homePhone, mobilePhone } = shipping;

    const printedAirway = true;

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={8} padding={2}>
                <Card
                    sx={{
                        p: 3,
                        mb: 3
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                    // height={'150px'}
                    >
                        <Box>
                            <Typography variant="h1" gutterBottom>
                                Airway Intelligence Report
                            </Typography>
                            <Typography variant="h3" color="text.secondary">
                                Order Review
                            </Typography>
                        </Box>
                        {/* <Box display="flex" flexDirection="column" width={'300px'}>
                            <LogoWrapper>
                                <Logo />
                            </LogoWrapper>
                        </Box> */}
                    </Box>
                    <Divider
                        sx={{
                            my: 4
                        }}
                    />

                    <TableWrapper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Report Type</TableCell>
                                        <TableCell>Add-Ons</TableCell>
                                        <TableCell align='right'>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell>
                                            <Typography variant='h5'>Standard</Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>
                                            $149.00
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>
                                            <Typography noWrap></Typography>
                                        </TableCell>
                                        <TableCell>Sassouni+ Analysis</TableCell>
                                        <TableCell align='right'>
                                            $0.00
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>
                                            <Typography noWrap></Typography>
                                        </TableCell>
                                        <TableCell>Faciometric</TableCell>
                                        <TableCell align='right'>
                                            $0.00
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>
                                            <Typography noWrap></Typography>
                                        </TableCell>
                                        <TableCell>70355 Orthopantogram</TableCell>
                                        <TableCell align='right'>
                                            $0.00
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>
                                            <Typography noWrap></Typography>
                                        </TableCell>
                                        <TableCell>3D Printed Airway</TableCell>
                                        <TableCell align='right'>
                                            $19.00
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>
                                            <Typography variant='h5'>Radiology Report</Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>
                                            $109.00
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="right">
                                            <Typography
                                                variant='h2'
                                                gutterBottom
                                                color="text.secondary"
                                                fontWeight="bold"
                                            >
                                                Total:
                                            </Typography>
                                        </TableCell>

                                        <TableCell align='right'>
                                            <Typography variant="h3" fontWeight="bold">
                                                $277.00
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    <Typography variant="subtitle2" gutterBottom>
                        Additional information:
                    </Typography>
                    <Typography variant="body2">
                        Once submitted, our team of Airway Professionals will conduct a review of your selected visit records
                        and will complete the requested Custom AI Report.
                    </Typography>
                </Card>
            </Grid>
            {printedAirway &&
                <Grid item xs={4} padding={2}>
                    <Card
                        sx={{
                            p: 3,
                            mb: 3
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            height={'150px'}
                        >

                            <Grid
                                container
                                spacing={1}
                                justifyContent="flex-start"
                            >
                                <Grid item xs={12} paddingBottom={5}>
                                    <Typography variant="h1" gutterBottom>
                                        3D Printed Airway
                                    </Typography>
                                    <Typography variant="h3" color="text.secondary">
                                        Shipping Information
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} padding={1}>
                                    <TextField
                                        variant='outlined'
                                        required
                                        name='shipToName'
                                        value={shipToName}
                                        onChange={handleShipping}
                                        placeholder="Shipping To Name"
                                        label="Shipping To Name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={8} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        placeholder="Company"
                                        label="Company"
                                        name="company"
                                        value={company}
                                        onChange={handleShipping}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        placeholder="Location Type"
                                        label="Location Type"
                                        name="locationType"
                                        value={locationType}
                                        onChange={handleLocationType}
                                        select
                                        fullWidth  // Add fullWidth to ensure the dropdown takes the full width
                                    >
                                        <MenuItem value="business">Business</MenuItem>
                                        <MenuItem value="patientHome">Patient Home</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        name="streetAddress"
                                        value={streetAddress}
                                        placeholder="Street Address"
                                        onChange={handleShipping}
                                        label="Street Address"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        onChange={handleShipping}
                                        label="City"
                                        fullWidth
                                        name="city"
                                        value={city}
                                        placeholder="City"
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="State"
                                        fullWidth
                                        name="state"
                                        value={state}
                                        placeholder="State"
                                        onChange={handleShipping}
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="Zip"
                                        fullWidth
                                        placeholder="Zip"
                                        name="zip"
                                        value={zip}
                                        onChange={handleShipping}
                                    />
                                </Grid>
                                <Grid item xs={8} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="Email"
                                        fullWidth
                                        placeholder="Email"
                                        name="email"
                                        value={email}
                                        onChange={handleShipping}
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="Phone Number"
                                        fullWidth
                                        placeholder="Phone Number"
                                        name="mobilePhone"
                                        value={mobilePhone}
                                        onChange={handleShipping}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            }
        </Grid>
    );
};
