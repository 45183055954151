import { makeStyles } from '@material-ui/core/styles';
import { Description } from '@mui/icons-material';
import { Button, Grid, Box, Typography, TextareaAutosize, TextField, MenuItem } from "@mui/material";
import { useMainStoreContext } from "../../../stores/MainStore";
import { observer } from "mobx-react";
import { boxStyles } from "./styles";
import { LinearProgress } from "@material-ui/core";
import { GlobalUtils } from "../../../api/GlobalUtils";
import { ApplianceColors, ApplianceSizes, RxAppliances, VivosAppliances } from "../../../entities/Enums";
import React from "react";
import _ from "lodash";
import Dropzone from "./ApplianceDiagramDropzone";
import { useEffect, useState } from "react";

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));
const StepFour = () => {

	const classes = styles();
	const boxClasses = boxStyles();
	const [isUploading, setIsUploading] = useState(false);
	const [applianceSizes, setApplianceSizes] = useState<any>([]);
	const [applianceColors, setApplianceColors] = useState<any>([]);
	const { setStep, currentPatient, selectedLab, selectedAppliance, versaTypeSelected, selectedApplianceSize, fabricationTime,
		setVersaTypeSelected, setApplianceConstructionDiagramFilename, setSelectedApplianceColor, setSelectedApplianceSize,
		isUpdating } = useMainStoreContext().prescriptionStore;
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;

	useEffect(() => {
		GlobalUtils.getApplianceSizes().then(resp => {
			setApplianceSizes(resp);
		});
		GlobalUtils.getApplianceColors().then(resp => {
			setApplianceColors(resp);
		});

	}, []);

	const handleBack = (): void => {
		isUpdating ? setStep(1) : setStep(2);
	};
	const handleNext = (): void => {
		setStep(4);
	};
	const handleChangeStatus = ({ meta, file }, status) => { setIsUploading(true); };

	const getUploadParams = async ({ file }) => {
		const body = new FormData();
		const base64 = await fileToBase64(file);

		body.append('diagram', base64.substring(base64.indexOf(',') + 1));
		body.append('name', file.name);

		setApplianceConstructionDiagramFilename(file.name);

		return { url: `${process.env.REACT_APP_GATEWAY_URL}/api/v1/patient/appliances/${currentPatient.id}/diagram`, body }
	};

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				if (reader.readyState === FileReader.DONE) {
					const base64 = reader.result as string;
					resolve(base64);
				}
			};

			reader.onerror = () => {
				reject(new Error('Error reading file'));
			};

			reader.readAsDataURL(file);
		});
	}

	const handleApplianceSizeChange = (event: any) => {
		setSelectedApplianceSize(applianceSizes.find(x => x.id === event.target.value));

	};

	const handleApplianceColorChange = (event: any) => {
		setSelectedApplianceColor(applianceColors.find(x => x.id === event.target.value));
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Box className={boxClasses.leftBox}>
						<Typography variant="h6">
							Appliance Construction
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={3}>
								<Button variant={versaTypeSelected === 'Versa 9' ? 'contained' : 'outlined'} color="primary" onClick={() => setVersaTypeSelected('Versa 9')}>Versa 9</Button>
							</Grid>
							<Grid item xs={3}>
								<Button variant={versaTypeSelected === 'Versa Nylon EMA' ? 'contained' : 'outlined'} color="primary" onClick={() => setVersaTypeSelected('Versa Nylon EMA')}>Versa Nylon EMA</Button>
							</Grid>
							<Grid item xs={6}>
								<Box display='flex' justifyContent='right'>
									<Dropzone />
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6">
									Construction Comments
								</Typography>
							</Grid>
							<Grid item xs={12} paddingTop='100px'>
								<TextareaAutosize minRows={5} style={{ width: '80%', marginInline: '10%' }}></TextareaAutosize>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							{selectedAppliance !== RxAppliances.VERSA &&
								<Grid item xs={12}>
									<Typography>
										<b>Lab:</b> {selectedLab.name}
									</Typography>
								</Grid>
							}
							<Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> {fabricationTime}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>
							{selectedAppliance === RxAppliances.VERSA && <Grid item xs={12}>
								<Typography>
									<b>Versa Type Selected:</b> {versaTypeSelected}
								</Typography>
							</Grid>}
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							<Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								disabled={selectedLab.id === -1}
								sx={{ borderRadius: '30px', margin: '20px' }}>
								Save + Continue
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
}

const InputComponent = (props: { showInput: boolean }) => {
	return (
		<>
			{props.showInput &&
				<Box display='flex' flexDirection='column' style={{ width: '100%', cursor: 'pointer' }}>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<Description display='flex' fontSize='large' />
					</div>
					<Typography display='flex' variant='body2' textAlign='center'>
						Upload Appliance Construction Diagram
					</Typography>
				</Box>
			}
		</>)
}
const PreviewComponent = (props: any) => {
	return (
		<Box display='flex' flexDirection='column'>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<Description display='flex' fontSize='large' />
			</div>
			<Typography display='flex' variant='body2' textAlign='center'>
				{props.meta.name}
			</Typography>
			<LinearProgress variant="determinate" value={props.meta.percent} />
		</Box>);
}

export default observer(StepFour);