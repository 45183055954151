import { Grid} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { FC } from "react";

interface IGIProps {
}


export const ProviderPayments: FC<IGIProps> = observer(() => {
    
    return <>
    <Grid xs={12} className="patientEditModal">
        Payments Tab.
    </Grid>
</>
});