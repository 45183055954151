interface ILegalGuardianData {
    firstName: string,
    lastName: string,
    relationshipTypeId: number,
    emailAddress: string, 
    primaryPhone: string,
    altPhone: string
}

interface LegalGuardian extends ILegalGuardianData {}

class LegalGuardian {
    constructor({ firstName, lastName, relationshipTypeId, emailAddress, primaryPhone, altPhone}: ILegalGuardianData) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.relationshipTypeId = relationshipTypeId;
        this.emailAddress = emailAddress;
        this.primaryPhone = primaryPhone;
        this.altPhone = altPhone;
    }

    toObject(): ILegalGuardianData {
        return {...this};
    }
}
export default LegalGuardian;