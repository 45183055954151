import { createContext, useContext } from 'react';
import CreatePatientStore from './CreatePatientStore';
import ScanStore from './ScanStore';
import CreateLegalGuardianStore from './CreateLegalGuardian';
import CreateHistoryStore from './CreateHistoryStore';
import PrescriptionStore from './PrescriptionStore';
import MyoCorrectAdminStore from './MyoCorrectAdminStore';
import { TeamAdminStore } from './TeamAdminStore';
import CreateAppointmentNotesStore from './CreateAppointNotesStore';
import CreateTeamStore from './CreateTeamStore';
import { MyoCorrectStore } from '../api/MyoCorrectStore';
import { SessionsStore } from './SessionsStore';
import { CustomFormStore } from './CustomFormStore';
import { PendingCasesStore } from './PendingCasesStore';
import { AIReportStore } from './AIReportStore';
import { CustomFormBuilderStore } from './CustomFormBuilderStore';
import VitalsAndMeasurementsStore from './VitalsAndMeasurementsStore';
import PatientHistoryEditStore from './PatientHistoryEditStore';
import TeethChartStore from './TeethChartStore';
import VisitStore from './VisitStore';
import PatientConsultationStore from './PatientConsultationStore';
import AIReportEntryStore from './AIReportEntryStore';
import { AiReportOrderStore } from '../components/AiReportOrder/AiReportOrderStore';
import { IntakeReportsStore } from './IntakeReportsStore';
import { CommentStore } from '../components/CommentsPanel/CommentStore';
import AIReportDatalogStore from './AIReportDatalogStore';
import AIReportDataLogMeasurementsStore from './AIReportDataLogMeasurementsStore';
import TreatmentPlanStore from './TreatmentPlanStore';
import ConsultationDataStore from './ConsultationDataStore';
import { PatientTreatmentStore } from './PatientTreatmentStore';
import OcclusionStore from './OcclusionStore';
import { CalendlyConsultationsStore } from './CalendlyConsultations';
import { CustomFormToProviderStore } from './CustomFormToProviderStore';
import UserCustomFormStore from './UserCustomFormStore';
import CustomFormsBL from '../pages/customForms/CustomFormsBL';
import ReworkClaimStore from './ReworkClaimStore';
import { LaboratoryStore } from './LaboratoryStore';
import LabCaseReviewStore from './LabCaseReviewStore';
import ClaimStore from './ClaimStore';
import { LabManagementStore } from './LabManagementStore';
import OrderStore from './OrderStore';
import ShopifyStore from './ShopifyStore';
import ProviderStore from './ProviderStore';
import MyoCorrectEnrollmentStore from './MyoCorrectEnrollmentStore';
import PatientLincareStore from './PatientLincare';
import MainDashboardStore from './MainDashboardStore';

export class MainStore {
    aiReportsStore: AIReportStore;
    aiReportEntryStore: AIReportEntryStore;
    createPatientStore: CreatePatientStore;
    createLegalGuardianStore: CreateLegalGuardianStore;
    createHistoryStore: CreateHistoryStore;
    prescriptionStore: PrescriptionStore;
    scanStore: ScanStore;
    myoCorrectAdminStore: MyoCorrectAdminStore;
    myoCorrectStore: MyoCorrectStore;
    teamAdminStore: TeamAdminStore;
    createAppointmentNotesStore: CreateAppointmentNotesStore;
    createTeamStore: CreateTeamStore;
    sessionsStore: SessionsStore;
    customFormStore: CustomFormStore;
    pendingCasesStore: PendingCasesStore;
    customFormBuilderStore: CustomFormBuilderStore;
    patientEditStore: PatientHistoryEditStore;
    vitalsAndMeasurementsStore: VitalsAndMeasurementsStore;
    teethChartStore: TeethChartStore;
    visitRecordStore: VisitStore;
    patientConsultationStore: PatientConsultationStore;
    aiReportOrder: AiReportOrderStore;
    intakeReportsStore: IntakeReportsStore;
    commentStore: CommentStore;
    measurementsData: AIReportDataLogMeasurementsStore;
    treatmentPlanStore: TreatmentPlanStore;
    consultationData: ConsultationDataStore;
    patientTreatmentStore: PatientTreatmentStore;
    calendlyConsultationsStore: CalendlyConsultationsStore;
    occlusionStore: OcclusionStore;
    customFormToProviderStore: CustomFormToProviderStore;
    userCustomFormStore: UserCustomFormStore;
    formBL: CustomFormsBL;
    reworkClaimStore: ReworkClaimStore;
    laboratoryStore: LaboratoryStore;
    labCaseReviewStore: LabCaseReviewStore;
    claimStore: ClaimStore;
    labManagementStore: LabManagementStore;
    orderStore: OrderStore;
    shopifyStore: ShopifyStore;
    providerStore: ProviderStore;
    myoCorrectEnrollmentStore: MyoCorrectEnrollmentStore;
    patientLincareStore: PatientLincareStore;
    mainDashboardStore: MainDashboardStore;

    constructor() {
        this.aiReportEntryStore = new AIReportEntryStore(this);
        this.aiReportOrder = new AiReportOrderStore(this);
        this.aiReportsStore = new AIReportStore(this);
        this.calendlyConsultationsStore = new CalendlyConsultationsStore(this);
        this.commentStore = new CommentStore(this);
        this.consultationData = new ConsultationDataStore(this);
        this.createAppointmentNotesStore = new CreateAppointmentNotesStore(this);
        this.createHistoryStore = new CreateHistoryStore(this);
        this.createLegalGuardianStore = new CreateLegalGuardianStore(this);
        this.createPatientStore = new CreatePatientStore(this);
        this.prescriptionStore = new PrescriptionStore(this);
        this.createTeamStore = new CreateTeamStore(this);
        this.customFormBuilderStore = new CustomFormBuilderStore(this);
        this.customFormStore = new CustomFormStore(this);
        this.customFormToProviderStore = new CustomFormToProviderStore(this);
        this.intakeReportsStore = new IntakeReportsStore(this);
        this.measurementsData = new AIReportDataLogMeasurementsStore(this);
        this.myoCorrectAdminStore = new MyoCorrectAdminStore(this);
        this.myoCorrectStore = new MyoCorrectStore(this);
        this.occlusionStore = new OcclusionStore(this);
        this.patientConsultationStore = new PatientConsultationStore(this);
        this.patientEditStore = new PatientHistoryEditStore(this);
        this.patientTreatmentStore = new PatientTreatmentStore(this);
        this.pendingCasesStore = new PendingCasesStore(this);
        this.scanStore = new ScanStore(this);
        this.sessionsStore = new SessionsStore(this);
        this.teamAdminStore = new TeamAdminStore(this);
        this.teethChartStore = new TeethChartStore(this);
        this.treatmentPlanStore = new TreatmentPlanStore(this);
        this.userCustomFormStore = new UserCustomFormStore(this);
        this.visitRecordStore = new VisitStore(this);
        this.vitalsAndMeasurementsStore = new VitalsAndMeasurementsStore(this);
        this.formBL = new CustomFormsBL(this);
        this.reworkClaimStore = new ReworkClaimStore(this);
        this.laboratoryStore = new LaboratoryStore(this);
        this.labCaseReviewStore = new LabCaseReviewStore(this);
        this.claimStore = new ClaimStore(this);
        this.labManagementStore = new LabManagementStore(this);
        this.orderStore = new OrderStore(this);
        this.shopifyStore = new ShopifyStore(this);
        this.providerStore = new ProviderStore(this);
        this.myoCorrectEnrollmentStore = new MyoCorrectEnrollmentStore(this);
        this.patientLincareStore = new PatientLincareStore(this);
        this.mainDashboardStore = new MainDashboardStore(this);
    }
}

export const store = new MainStore();
export const MainStoreContext = createContext<MainStore>(store);
export const useMainStoreContext = () => {
    return useContext(MainStoreContext);
};
