import { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import DeleteIcon from '@mui/icons-material/Delete';

import CssSelectField from '../../../CssSelectField';

import { GlobalUtils } from '../../../../api/GlobalUtils';

import { Grid, IconButton, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import { useMainStoreContext } from '../../../../stores/MainStore';
import { select } from 'survey-creator-core';
type estimatedTime = {
    index?: number;
    estimatedTreatmentTime?: any;
    onUpdate(start: any, end: any, index: any) : any;
    onDelete(index: number): any;
};

export const EstimatedTime = observer(({ index, onUpdate, onDelete }: estimatedTime) => {
    const { setEstimatedTreatment, getEstimatedTreatmentTIme, treatmentConditions } =
        useMainStoreContext().patientTreatmentStore;


    const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
    const [therapyOptionsData, setTherapyOptionsData] = useState<any>([]);
    const [selectedTherapy, setSelectedTherapy] = useState<any>();

    const startDate = value[0]?.format();

    const endDate = value[1]?.format();

    useEffect(() => {
        let estimated = {
            startDate: startDate,
            endDate: endDate,
            therapyOptionId: selectedTherapy,
        };

        setEstimatedTreatment(Number(index), estimated);
        setTherapyOptionsData(GlobalUtils.getVivosAppliances());
    }, [endDate, index, selectedTherapy, setEstimatedTreatment, startDate]);

    const handleSelect = e => {
        setSelectedTherapy(e.target.value);
    };

    return (
        <Grid container xs={12} style={{marginLeft:"25px"}}>
            <Grid item xs={6}>
                <CssSelectField
                    id="therapyOptionId"
                    label="Therapy Option"
                    className="TextBox"
                    value={selectedTherapy ? selectedTherapy: 0}
                    onChange={handleSelect}
                    style={{ minWidth: "200px" }}>
                    <MenuItem key={0} value={0}>
                        {'Select a Report Type'}
                    </MenuItem>
                    {therapyOptionsData.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </CssSelectField>
            </Grid>
            <Grid item xs={4}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: 'Start Treatment ', end: 'End Treatment' }}
                >
                    <DateRangePicker
                        value={value}
                        onChange={(newValue, index) => {
                            setValue(newValue);
                            onUpdate(newValue[0], newValue[1], index);
                        }}
                        renderInput={(startProps, endProps) => (
                            <>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                            </>
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid>
                <IconButton
                    hidden={getEstimatedTreatmentTIme().length <= 1}
                    onClick={() => {
                        onDelete(Number(index));
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
});
