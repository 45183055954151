import React, { useState } from 'react';
import {
    Grid,
    Typography,
    styled,
    Box,
    alpha,
    RadioGroup,
    Radio,
    FormControl,
    Stack,
    FormLabel,
    IconButton,
    Popover,
    Divider
} from '@mui/material';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

interface MuscleAssessmentProps {
    title: string;
    optionTitles: string[];
    valueStringTitles: string[];
    valueStringOptions: string[];
    currentAppointmentNote: any;
    setCurrentAppointmentNoteProp: any;
}

const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      top: ${theme.spacing(2)};
      z-index: 12;
    `
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        background: transparent;
        transition: ${theme.transitions.create(['all'])};
        color: ${theme.colors.alpha.trueWhite[70]};
        border-radius: 50px;
    
        &:hover {
          background: transparent;
          color: ${theme.colors.alpha.trueWhite[100]};
        }
    `
);

const BoxComposed = styled(Box)(
    () => `
    position: relative;
  `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
    position: relative;
    z-index: 7;
    display: flex;
    align-items: center;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedBg = styled(Box)(
    () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);


const MuscleAssessmentComponent: React.FC<MuscleAssessmentProps> = ({
    title,
    optionTitles,
    valueStringTitles,
    valueStringOptions,
    currentAppointmentNote,
    setCurrentAppointmentNoteProp,
}) => {

    const [renderKey, setRenderKey] = useState(0);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, valueToUpdate: string) => {
        setCurrentAppointmentNoteProp(event.target.value.toString(), valueToUpdate);
        setRenderKey(renderKey + 1);
    };
    const renderSquare = (color: string, value: string, valueToUpdate: string) => (
        <Box
            key={value}
            sx={{
                position: 'relative',
                display: 'inline-block',
            }}
        >
            {currentAppointmentNote[valueToUpdate]?.markAsEdited &&
                currentAppointmentNote[valueToUpdate]?.response === value && (
                    <ErrorOutlineTwoToneIcon
                        style={{ position: 'absolute', top: -10, right: -10, color: 'blue' }}
                    />
                )}
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: color,
                    borderRadius: '4px',
                    cursor: 'pointer',
                    opacity: currentAppointmentNote[valueToUpdate]?.response !== value ? 0.25 : 1,
                }}
                onClick={() =>
                    handleChange(
                        { target: { value } } as React.ChangeEvent<HTMLInputElement>,
                        valueToUpdate
                    )
                }
            >
                <Radio
                    value={value}
                    checked={currentAppointmentNote[valueToUpdate]?.response === value}
                    sx={{ position: 'absolute', opacity: 0, width: '100%', height: '100%' }}
                />
            </Box>
        </Box>
    );

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    return (
        <Grid container direction="column" margin={1} padding={1}>
            <Grid item xs={12}>
                <BoxComposed
                    sx={(theme) => ({
                        height: `75px`,
                        borderRadius: `${theme.general.borderRadius}`,
                        background: `${theme.colors.gradients.black2}`
                    })}
                >

                    <BoxComposedBg
                        sx={(theme) => ({
                            opacity: 0.1,
                            background: `${theme.colors.gradients.black1}`
                        })}

                    />
                    <BoxComposedContent py={3} pt={0} pb={0} height={'75px'}>
                        <Typography
                            textAlign="left"
                            sx={{
                                ml: 4,
                            }}
                            variant="h3"
                        >
                            {title}
                        </Typography>
                        <CardActions display="flex" alignItems="center" >

                            <IconButtonWrapper
                                sx={{
                                    ml: 0.5
                                }}
                                onClick={handlePopoverOpen}
                            >
                                <HelpTwoToneIcon fontSize="small" />
                            </IconButtonWrapper>
                        </CardActions>

                    </BoxComposedContent>
                </BoxComposed>
            </Grid>

            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { width: '500px', padding: '20px' } }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Posture</Typography>
                    </Grid>
                    <Grid item xs={12} padding={1}>
                        <Typography variant='subtitle1'>Resting position and appearance</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Functions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' padding={1}>Swallowing Dysfunction is present when compensatory
                            muscles are active. In some cases head movement will be
                            present or not</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Mobility</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' padding={1}>Dysfunction or alteration were considered to be present
                            when lack of precision in the movement, tremor, associated
                            movements of other components (e.g., lips accompanying
                            the movements of the tongue) and inability to perform the
                            movement were observed</Typography>
                    </Grid>
                    <Divider />
                    <Grid item xs={12}>
                        <Grid container direction={'column'}>
                            <Grid item xs={12} padding={1}>
                                <Grid container alignItems={'center'} spacing={1}>
                                    <Grid item xs={2}>
                                        <Box
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundColor: 'green',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h5'>
                                            Excellent:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant='subtitle1'>
                                            Mobility/ Function/ Resting Position
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} padding={1}>
                                <Grid container alignItems={'center'} spacing={1}>
                                    <Grid item xs={2}>
                                        <Box
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundColor: 'yellow',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h5'>
                                            Slight Weakness:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant='subtitle1'>
                                            Signs of muscle compensation
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} padding={1}>
                                <Grid container alignItems={'center'} spacing={1}>
                                    <Grid item xs={2}>
                                        <Box
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundColor: 'orange',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h5'>
                                            Moderate Weakness:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant='subtitle1'>
                                            Jaw movement, active compensatory
                                            muscles, low tongue position.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} padding={1}>
                                <Grid container alignItems={'center'} spacing={1}>
                                    <Grid item xs={2}>
                                        <Box
                                            sx={{
                                                width: '40px',
                                                height: '40px',
                                                backgroundColor: 'red',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                position: 'relative',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant='h5'>
                                            Heavy:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant='subtitle1'>
                                            Atypical swallow, no lip seal, heavy jaw
                                            movement, active compensatory muscles,
                                            low tongue position, tongue-tied
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>

                    </Grid>
                </Grid>
            </Popover>

            <Grid xs={12} padding={2} px={5}>
                <Grid container>
                    {valueStringTitles.map((title, titleIndex) => (
                        <Grid key={titleIndex} item xs={12}>
                            <Typography variant='h4'>
                                {title}
                            </Typography>

                            <Grid container>
                                {valueStringOptions.slice(titleIndex * 3, titleIndex * 3 + 3).map((valueString, index) => (
                                    <Grid key={index} item xs={12} lg={4} px={2}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{optionTitles[index]}</FormLabel>
                                            <RadioGroup
                                                row
                                                value={parseInt(currentAppointmentNote[valueString]?.response)}
                                                onChange={handleChange}
                                            >
                                                <Stack direction="row" spacing={2}>
                                                    {renderSquare('green', '0', valueString)}
                                                    {renderSquare('yellow', '1', valueString)}
                                                    {renderSquare('orange', '2', valueString)}
                                                    {renderSquare('red', '3', valueString)}
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

        </Grid>
    );
};

export default MuscleAssessmentComponent;