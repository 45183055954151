import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Switch } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import react, { FC } from "react";
import Item from "../../components/Item";
import CssTextField from "../../components/CssTextField";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CssSelectField from "../../components/CssSelectField";
import _ from "lodash";
import { useMainStoreContext } from "../../stores/MainStore";
import { Ethnicity, Gender, countryLabelValues, stateLabelValues } from "../../entities/Enums";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GlobalUtils } from "../../api/GlobalUtils";
import { Provider } from "../../entities/Provider";
import CloseIcon from '@mui/icons-material/Close';

interface IGIProps {
    provider: Provider;
}


export const ProviderDetails: FC<IGIProps> = observer(({ provider }) => {

    const store = useMainStoreContext().providerStore;
    const [toggleValidateModal, setToggleValidateModal] = react.useState(false);
    const [validationList, setValidationList] = react.useState<any>([]);

    const stateMap = (): any => {
        var states = _.map(stateLabelValues, (s, i) => {
            return (
                <MenuItem key={i} value={s.value}>
                    {s.label}
                </MenuItem>
            );
        });
        return states;
    };

    const countryMap = (): any => {
        var countries = _.map(countryLabelValues, (s, i) => {
            return (
                <MenuItem key={i} value={s.value}>
                    {s.label}
                </MenuItem>
            );
        });
        return countries;
    };

    let genderValues = [];
    const genderMap = (): any => {
        genderValues = GlobalUtils.getGenders();
        var genders = _.map(genderValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return genders;
    };

    let ethnicityValues = [];
    const ethnicityMap = (): any => {
        ethnicityValues = GlobalUtils.getEthnicities();
        var ethnicities = _.map(ethnicityValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return ethnicities;
    };

    const validate = (): string[] => {
        let validations: string[] = [];
        if (provider.firstName === "") {
            validations.push("First Name is required");
        }
        if (provider.lastName === "") {
            validations.push("Last Name is required");
        }
        if (provider.emailAddress === "") {
            validations.push("Contact E-mail is required");
        }
        else if (!GlobalUtils.validateEmail(provider.emailAddress ? provider.emailAddress : "")) {
            validations.push("Invalid Email Format");
        }
        setValidationList(validations);
        return validations;
    }

    const handleSaveButton = () => {
        const validation = validate();
        if (validation.length > 0) {
            setToggleValidateModal(true);
        }
        else {
            store.saveProvider();
        }
    }

    return (
        <Grid xs={12} className="patientDetailsTab">
            <Grid xs={12} container direction={'row'} justifyContent="flex-end">
                <FormGroup>
                    <FormControlLabel
                        className="switch"
                        control={<Switch onChange={() => { store.setToggleEditMode(!store.editMode) }} checked={store.editMode} />}
                        label="Edit"
                    />
                </FormGroup>
            </Grid>
            <Grid xs={12} container direction={'row'} className="headerLine ">
                <Grid className="label">
                    <Grid>GENERAL INFORMATION</Grid>
                </Grid>
            </Grid>
            <Grid className="viewBody" xs={12} container direction={'column'}>

                <Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid container direction={"row"}><Grid>First Name </Grid><Grid sx={{ color: "red" }} >*</Grid></Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            store.providerData.firstName = event.target.value;
                                        }}
                                        value={provider.firstName}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Middle Name</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            store.providerData.middleName = event.target.value;
                                        }}
                                        value={provider.middleName}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid container direction={"row"}><Grid>Last Name </Grid><Grid sx={{ color: "red" }} >*</Grid></Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            store.providerData.lastName = event.target.value;
                                        }}
                                        value={provider.lastName}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Birth Date</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            disabled={!store.editMode}
                                            placeholder=""
                                            required={true}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                // store.providerData.dateOfBirth = event.target.value;
                                            }}
                                            value={moment(provider.dateOfBirth).format('MM/DD/yyyy')}
                                        />
                                    )}
                                    {store.editMode && (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                className="DateBox"
                                                openTo="day"
                                                views={['year', 'month', 'day']}
                                                value={provider.dateOfBirth}
                                                onChange={newValue => {
                                                    if (newValue != null) {
                                                        store.providerData.dateOfBirth = moment(newValue).toDate();
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <CssTextField
                                                        disabled={store.editMode}
                                                        {...params}
                                                        sx={{
                                                            '& input': {
                                                                backgroundColor: 'white',
                                                                color: '#050505',
                                                                fontFamily: 'Proxima Nova',
                                                                fontSize: '16px',
                                                            },
                                                        }}
                                                        className="TextBox"
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Gender</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            disabled={!store.editMode}
                                            placeholder=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                store.providerData.genderId = event.target.value as unknown as number;
                                            }}
                                            value={provider.genderId ? Gender[provider.genderId] : ''}
                                        />
                                    )}
                                    {store.editMode && (
                                        <CssSelectField
                                            size="small"
                                            className="DateBox"
                                            sx={{ width: '100%' }}
                                            onChange={(event: any) => {
                                                store.providerData.genderId = event.target.value as unknown as number;
                                            }}
                                            value={provider.genderId ? provider.genderId : ''}
                                        >
                                            {genderMap()}
                                        </CssSelectField>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Ethnicity</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            disabled={!store.editMode}
                                            placeholder=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                store.providerData.ethnicityId = event.target.value as unknown as number;
                                            }}
                                            value={provider.ethnicityId ? Ethnicity[provider.ethnicityId] : ''}
                                        />
                                    )}
                                    {store.editMode && (
                                        <CssSelectField
                                            size="small"
                                            className="DateBox"
                                            sx={{ width: '100%' }}
                                            onChange={(event: any) => {
                                                store.providerData.ethnicityId = event.target.value as unknown as number;
                                            }}
                                            value={provider.ethnicityId ? provider.ethnicityId : ''}
                                        >
                                            {ethnicityMap()}
                                        </CssSelectField>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={6}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Address 1</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        sx={{ width: '90%', marginRight: '25px' }}
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.personalAddress != null) {
                                                store.personalAddress.address1 = event.target.value;
                                            } else {
                                                const address = {};
                                                store.personalAddress = address;
                                                store.personalAddress.address1 = event.target.value;
                                            }
                                        }}
                                        value={store.personalAddress ? store.personalAddress.address1 : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Address 2</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        sx={{ width: '90%', marginRight: '25px' }}
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.personalAddress != null) {
                                                store.personalAddress.address2 = event.target.value;
                                            } else {
                                                const address = {};
                                                store.personalAddress = address;
                                                store.personalAddress.address2 = event.target.value;
                                            }
                                        }}
                                        value={store.personalAddress ? store.personalAddress.address2 : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>City</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.personalAddress != null) {
                                                store.personalAddress.city = event.target.value;
                                            } else {
                                                const address = {};
                                                store.personalAddress = address;
                                                store.personalAddress.city = event.target.value;
                                            }
                                        }}
                                        value={store.personalAddress ? store.personalAddress.city : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>State</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            disabled={!store.editMode}
                                            placeholder=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { }}
                                            value={
                                                store.personalAddress
                                                    ? _.find(stateLabelValues, st => {
                                                        return st.value == store.personalAddress.state;
                                                    })?.label
                                                    : ''
                                            }
                                        />
                                    )}
                                    {store.editMode && (
                                        <CssSelectField
                                            size="small"
                                            className="DateBox"
                                            sx={{ width: '100%' }}
                                            onChange={(event: any) => {
                                                if (store.personalAddress) {
                                                    store.personalAddress.state = event.target.value;
                                                }
                                            }}
                                            value={
                                                store.personalAddress
                                                    ? _.find(stateLabelValues, st => {
                                                        return st.value == store.personalAddress.state;
                                                    })?.value
                                                    : ''
                                            }
                                        >
                                            {stateMap()}
                                        </CssSelectField>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Postal Code</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.personalAddress != null) {
                                                store.personalAddress.postalCode = event.target.value;
                                            } else {
                                                const address = {};
                                                store.personalAddress = address;
                                                store.personalAddress.postalCode = event.target.value;
                                            }
                                        }}
                                        value={store.personalAddress ? store.personalAddress.postalCode : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Country</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            disabled={!store.editMode}
                                            size="small"
                                            placeholder=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (store.personalAddress != null) {
                                                    store.personalAddress.country = event.target.value;
                                                } else {
                                                    const address = {};
                                                    store.personalAddress = address;
                                                    store.personalAddress.country = event.target.value;
                                                }
                                            }}
                                            value={
                                                store.personalAddress
                                                    ? _.find(countryLabelValues, st => {
                                                        return st.value == store.personalAddress.country;
                                                    })?.label
                                                    : ''
                                            }
                                        />
                                    )}
                                    {store.editMode && (
                                        <CssSelectField
                                            size="small"
                                            className="DateBox"
                                            sx={{ width: '100%' }}
                                            onChange={(event: any) => {
                                                if (store.personalAddress != null) {
                                                    store.personalAddress.country = event.target.value;
                                                } else {
                                                    const address = {};
                                                    store.personalAddress = address;
                                                    store.personalAddress.country = event.target.value;
                                                }
                                            }}
                                            value={
                                                store.personalAddress
                                                    ? _.find(countryLabelValues, st => {
                                                        return st.value == store.personalAddress.country;
                                                    })?.value
                                                    : ''
                                            }
                                        >
                                            {countryMap()}
                                        </CssSelectField>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Phone</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        disabled={!store.editMode}
                                        size="small"
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            store.providerData.primaryPhone = event.target.value;
                                        }}
                                        value={provider.primaryPhone}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Mobile</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        disabled={!store.editMode}
                                        size="small"
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            store.providerData.altPhone = event.target.value;
                                        }}
                                        value={provider.altPhone}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid container direction={"row"}><Grid>Contact E-mail</Grid><Grid sx={{ color: "red" }} >*</Grid></Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        sx={{ width: '100%' }}
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            store.providerData.emailAddress = event.target.value;
                                        }}
                                        value={provider.emailAddress ? provider.emailAddress : ''}
                                        required={true}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'} className="headerLine ">
                        <Grid className="label">
                            <Grid>BILLING ADDRESS</Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={6}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Address 1</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        sx={{ width: '90%', marginRight: '25px' }}
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.billableAddress != null) {
                                                store.billableAddress.address1 = event.target.value;
                                            } else {
                                                const address = {};
                                                store.billableAddress = address;
                                                store.billableAddress.address1 = event.target.value;
                                            }
                                        }}
                                        value={store.billableAddress ? store.billableAddress.address1 : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Address 2</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        sx={{ width: '90%', marginRight: '25px' }}
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.billableAddress != null) {
                                                store.billableAddress.address2 = event.target.value;
                                            } else {
                                                const address = {};
                                                store.billableAddress = address;
                                                store.billableAddress.address2 = event.target.value;
                                            }
                                        }}
                                        value={store.billableAddress ? store.billableAddress.address2 : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>City</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.billableAddress != null) {
                                                store.billableAddress.city = event.target.value;
                                            } else {
                                                const address = {};
                                                store.billableAddress = address;
                                                store.billableAddress.city = event.target.value;
                                            }
                                        }}
                                        value={store.billableAddress ? store.billableAddress.city : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>State</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            disabled={!store.editMode}
                                            placeholder=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { }}
                                            value={
                                                store.billableAddress
                                                    ? _.find(stateLabelValues, st => {
                                                        return st.value == store.billableAddress.state;
                                                    })?.label
                                                    : ''
                                            }
                                        />
                                    )}
                                    {store.editMode && (
                                        <CssSelectField
                                            size="small"
                                            className="DateBox"
                                            sx={{ width: '100%' }}
                                            onChange={(event: any) => {
                                                if (store.billableAddress) {
                                                    store.billableAddress.state = event.target.value;
                                                }
                                            }}
                                            value={
                                                store.billableAddress
                                                    ? _.find(stateLabelValues, st => {
                                                        return st.value == store.billableAddress.state;
                                                    })?.value
                                                    : ''
                                            }
                                        >
                                            {stateMap()}
                                        </CssSelectField>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Postal Code</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        disabled={!store.editMode}
                                        placeholder=""
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (store.billableAddress != null) {
                                                store.billableAddress.postalCode = event.target.value;
                                            } else {
                                                const address = {};
                                                store.billableAddress = address;
                                                store.billableAddress.postalCode = event.target.value;
                                            }
                                        }}
                                        value={store.billableAddress ? store.billableAddress.postalCode : ''}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Country</Grid>
                                    </Grid>
                                    {!store.editMode && (
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            disabled={!store.editMode}
                                            size="small"
                                            placeholder=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (store.billableAddress != null) {
                                                    store.billableAddress.country = event.target.value;
                                                } else {
                                                    const address = {};
                                                    store.billableAddress = address;
                                                    store.billableAddress.country = event.target.value;
                                                }
                                            }}
                                            value={
                                                store.billableAddress
                                                    ? _.find(countryLabelValues, st => {
                                                        return st.value == store.billableAddress.country;
                                                    })?.label
                                                    : ''
                                            }
                                        />
                                    )}
                                    {store.editMode && (
                                        <CssSelectField
                                            size="small"
                                            className="DateBox"
                                            sx={{ width: '100%' }}
                                            onChange={(event: any) => {
                                                if (store.billableAddress != null) {
                                                    store.billableAddress.country = event.target.value;
                                                } else {
                                                    const address = {};
                                                    store.billableAddress = address;
                                                    store.billableAddress.country = event.target.value;
                                                }
                                            }}
                                            value={
                                                store.billableAddress
                                                    ? _.find(countryLabelValues, st => {
                                                        return st.value == store.billableAddress.country;
                                                    })?.value
                                                    : ''
                                            }
                                        >
                                            {countryMap()}
                                        </CssSelectField>
                                    )}
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {store.editMode && (
                        <Grid>
                            <Grid container direction={'row'} justifyContent={'flex-end'}>
                                <Button className="vivButton large invert" onClick={() => { handleSaveButton() }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Dialog
                        open={toggleValidateModal}
                        onClose={() => { }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            <Grid display="flex" alignItems="center">
                                <Grid flexGrow={1} >Invalid Provider Data</Grid>
                                <Grid>
                                    {<IconButton className="closeButton" onClick={() => { setToggleValidateModal(false) }}>
                                        <CloseIcon />
                                    </IconButton>}
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid>
                                <Grid>Please correct the following:</Grid>
                                <Grid>{_.map(validationList, (vl) => {
                                    return <Grid>{vl}</Grid>
                                })}</Grid>
                            </Grid>
                            <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setToggleValidateModal(false) }}>OK</Button></Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>

            </Grid>
        </Grid>
    );

});