import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    FormControl,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import "../../../../scss/_TreatmentPlanning.scss"

import Item from '../../../Item';
import CssTextField from '../../../CssTextField';

import { useMainStoreContext } from '../../../../stores/MainStore';
import { SleepCondition } from '../../../../entities/Enums';

interface ISleepConditionProps {
    sleepCondition?: string;
}

const radios = [
    { name: 'Improve', value: 497, key: 1 },
    { name: 'Maintain', value: 498, key: 2 },
    { name: 'N/A', value: 499, key: 3 },
];

interface IRow {
    name: string;
    callback: (id: any, value: string) => void;
    treatmentObjectiveId: number;
}

const Row = observer(({ name, callback, treatmentObjectiveId }: IRow) => {
    const [value, setValue] = useState<any[]>([]);

    const [newChoice, setNewChoice] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setNewChoice(Number(value));

        callback(Number(value), name);
    };

    useEffect(() => {
        setNewChoice(treatmentObjectiveId);

        setValue(radios);
    }, [treatmentObjectiveId, value]);

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {name}
            </TableCell>
            {value.map(radio => (
                <TableCell align="right" key={radio.value}>
                    <FormControl component="fieldset" key={radio.value}>
                        <RadioGroup value={newChoice} key={radio.value} onChange={handleChange}>
                            <Radio value={radio.value} name={radio.name} />
                        </RadioGroup>
                    </FormControl>
                </TableCell>
            ))}
        </TableRow>
    );
});

export const SleepObjectives: FC<ISleepConditionProps> = observer(() => {
    const { sleepDiagnosis, sleepNotesDiagnosis, setSleepNotesDiagnosis, setSleepDiagnosis } =
        useMainStoreContext().patientTreatmentStore;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer className="treatmentTable">
                    <Table sx={{ minWidth: '50%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sleep Conditions</TableCell>
                                <TableCell align="right">Improve</TableCell>
                                <TableCell align="right">Maintain</TableCell>
                                <TableCell align="right">N/A</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(sleepDiagnosis.some(
                                (el: { listItemKey: SleepCondition }) => el.listItemKey === SleepCondition.OBSSLEEPAP,
                            ) ||
                                sleepDiagnosis.some((el: { conditionId: number }) => el.conditionId === 241)) && (
                                <Row
                                    name="Obstructive Sleep Apnea"
                                    treatmentObjectiveId={
                                        sleepDiagnosis.find(
                                            (el: { listItemKey: SleepCondition }) =>
                                                el.listItemKey === SleepCondition.OBSSLEEPAP,
                                        )?.treatmentObjective ||
                                        sleepDiagnosis.find((el: { conditionId: number }) => el.conditionId === 241)
                                            .treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setSleepDiagnosis(
                                            sleepDiagnosis.map((el: { listItemKey: SleepCondition }) => {
                                                if (el.listItemKey === SleepCondition.OBSSLEEPAP) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(sleepDiagnosis.some(
                                (el: { listItemKey: SleepCondition }) => el.listItemKey === SleepCondition.SLEEPRELBRU,
                            ) ||
                                sleepDiagnosis.some((el: { conditionId: number }) => el.conditionId === 243)) && (
                                <Row
                                    name="Sleep Related Bruxism"
                                    treatmentObjectiveId={
                                        sleepDiagnosis.find(
                                            (el: { listItemKey: SleepCondition }) =>
                                                el.listItemKey === SleepCondition.SLEEPRELBRU,
                                        )?.treatmentObjective ||
                                        sleepDiagnosis.find((el: { conditionId: number }) => el.conditionId === 243)
                                            .treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setSleepDiagnosis(
                                            sleepDiagnosis.map((el: { listItemKey: SleepCondition }) => {
                                                if (el.listItemKey === SleepCondition.SLEEPRELBRU) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(sleepDiagnosis.some(
                                (el: { listItemKey: SleepCondition }) =>
                                    el.listItemKey === SleepCondition.OTHERSLEEPDIS,
                            ) ||
                                sleepDiagnosis.some((el: { conditionId: number }) => el.conditionId === 244)) && (
                                <Row
                                    name="Other Sleep Disorders"
                                    treatmentObjectiveId={
                                        sleepDiagnosis.find(
                                            (el: { listItemKey: SleepCondition }) =>
                                                el.listItemKey === SleepCondition.OTHERSLEEPDIS,
                                        )?.treatmentObjective ||
                                        sleepDiagnosis.find((el: { conditionId: number }) => el.conditionId === 244)
                                            .treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setSleepDiagnosis(
                                            sleepDiagnosis.map((el: { listItemKey: SleepCondition }) => {
                                                if (el.listItemKey === SleepCondition.OTHERSLEEPDIS) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {sleepDiagnosis.some((el: { id: number }) => el.id === 244) && (
                <Grid
                    container
                    xs={12}
                    direction="row"
                    paddingLeft={4}
                    columnSpacing={0}
                    className="formBasics"
                    marginTop={2}
                >
                    <Grid container xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <Item>
                                <CssTextField
                                    variant="outlined"
                                    className="TextBox"
                                    autoComplete="new-password"
                                    size="medium"
                                    placeholder="Other Sleep Disorders Notes"
                                    required={false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSleepNotesDiagnosis(event.target.value);
                                    }}
                                    value={sleepNotesDiagnosis}
                                />
                            </Item>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});
