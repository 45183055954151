
import { observer } from "mobx-react";
import { Button, Grid, LinearProgress, Link } from "@mui/material";
import ProviderPatient from "../../../../entities/ProviderPatient";
import React, { useState } from "react";
import DataGrid from "../../../DataGrid/DataGrid";
import { useMainStoreContext } from "../../../../stores/MainStore";
import LinkIcon from '@mui/icons-material/Link';
import ReportIcon from '@mui/icons-material/Report';

import moment from "moment";
import ModalContainer from "../../../ModalContainer";
import { SubmitReworkClaimWizard } from "../SubmitReworkClaimWizard";
import { ReworkActionRequiredModal } from "../ReworkActionRequiredModal";
import { ActionItemStatus, ClaimStatus, ClaimType, ErfStatus } from "../../../../entities/Enums";
import { PatientStore } from "../../../../api/PatientStore";

interface IGIProps {
    patient: ProviderPatient;
}


export const PatientReworkDashboard: React.FC<IGIProps> = observer(({ patient }) => {

    const [selectedRow, setSelectedRow] = useState<any>({});
    let store = useMainStoreContext().reworkClaimStore;
    const patientStore = new PatientStore();

    React.useEffect(() => {
        store.loadClaimStatusValues();
        store.loadProductionStatusValues();
        store.getClaimsByPatientId(patient.id);
    }, []);

    React.useEffect(() => {
        if (store.isLoadedShipmentLabel)
            downloadPDF(store.shipmentLabel.labelData);
    }, [store.isLoadedShipmentLabel]);

    function downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `ShipmentLabel_${patient.vivosId}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        store.setLoadedShipmentLabel(false);
    }

    const columns = [
        {
            field: 'rxId',
            headerName: 'RX Claim ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={"reworkClaim.dashboard.cell.rxId" + params.row.id}
                                variant="text"
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    store.setShowSubmitReworkWizard(true);
                                }}
                            >
                                {params.row.rxId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'submissionDate',
            headerName: 'Claim Submitted',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.submissionDate" + params.row.id}>
                    {params.row.submissionDate && moment(params.row.submissionDate).format('MM/DD/yyyy')}</Grid>)
            }
        },
        {
            field: 'reviewedOn',
            headerName: 'Reviewed Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.dateSubmitted" + params.row.id}>
                    {params.row.reviewedOn && moment(params.row.reviewedOn).format('MM/DD/yyyy')}</Grid>)
            }
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.appliance" + params.row.id}>{params.row.appliance?.applianceTypeNavigation.name}</Grid>)
            }
        },
        {
            field: 'claimStatus',
            headerName: 'Claim Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.claimStatus" + params.row.id}>{store.claimStatusValues.find(x => x.id === params.row.claimStatus)?.name}</Grid>)
            }
        },
        {
            field: 'productionStatus',
            headerName: 'Production Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.productionStatus" + params.row.id}>{store.productionStatusValues.find(x => x.id === params.row.productionStatus)?.name}</Grid>)
            }
        },
        {
            field: 'shippedOn',
            headerName: 'Shipped Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.dateSubmitted" + params.row.id}>
                    {params.row.shippedOn && moment(params.row.shippedOn).format('MM/DD/yyyy')}</Grid>)
            }
        },
        {
            field: 'documentation',
            headerName: 'Documentation',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <Button
                            id={"reworkClaim.dashboard.cell.documentation" + params.row.id}
                        >
                            <LinkIcon id={"reworkClaim.dashboard.cell.documentation" + params.row.id} />
                        </Button>
                    </Grid>
                );
            },
        },
        {
            field: 'shippinglabel',
            headerName: 'Shipping Label',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <Button
                            id={"reworkClaim.dashboard.cell.shippinglabel" + params.row.id}
                            onClick={() => {
                                store.getShipmentLabel();
                            }
                            }
                        >
                            <LinkIcon id={"reworkClaim.dashboard.cell.shippinglabel" + params.row.id} />
                        </Button>
                    </Grid>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    (params.row.erfstatus === ErfStatus["Pending Approval"] ||
                        (params.row.labRemakeNeeded === true && params.row.claimStatus !== ClaimStatus.Completed) ||
                        (params.row.actionItemNotes !== null && params.row.actionItemStatus === ActionItemStatus.Required)
                    ) ? (
                        <Grid>
                            <Link
                                id={"reworkClaim.dashboard.cell.action" + params.row.id}
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    store.setShowActionRequired(true);
                                }}
                            >
                                <ReportIcon id={"reworkClaim.dashboard.cell.action" + params.row.id} />
                            </Link>
                        </Grid>) : <></>
                );
            },
        }
    ]

    return (
        <Grid>
            <Grid className="grid" id={'aireporttech.dashboard' + 1}>
                {store.isLoadingClaims && <LinearProgress variant="determinate" value={store.loadingClaimsProgress} />}
                <DataGrid
                    columns={columns}
                    loading={store.isLoadingClaims}
                    data={store.claimData}
                />
            </Grid>
            {store.showSubmitReworkWizard && <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={store.showSubmitReworkWizard}
                title={"Submit Rework Claim"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowSubmitReworkWizard(false);
                    store.loadPage();
                }}>
                <SubmitReworkClaimWizard
                    patient={patient}
                    claim={selectedRow}
                    isEdit={true}
                    onClose={() => {
                        store.setShowSubmitReworkWizard(false);
                        store.loadPage();
                    }} />
            </ModalContainer>}
            {store.showActionRequired && <ModalContainer
                noScroll={true}
                mediumScreen={true}
                extSx={{
                    '& .MuiPaper-root': {
                        height: '300px',
                        width: '500px'
                    }
                }}
                show={store.showActionRequired}
                title={"Action Required"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowActionRequired(false);
                }}>
                <ReworkActionRequiredModal
                    patient={patient}
                    claim={selectedRow}
                    patientStore={patientStore}
                    onClose={() => {
                        store.setShowActionRequired(false);
                    }} />
            </ModalContainer>}
        </Grid>
    )
}
);

