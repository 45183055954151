// vendors
import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, StepLabel } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';

// components
import { PatientComplains } from './PatientComplains';
import { DiagnosisPage } from './Diagnosis';
import { TreatmentObjectivesPage } from './TreatmentObjectives';
import { useMainStoreContext } from '../../../stores/MainStore';
import { TreatmentPlantToDiagnosis } from './TreatmentPlanToDiagnosis';
import { TreatmentPlanPage } from './TreatmentPlan';
import { EstimatedTreatmentTime } from './EstimatedTreatmentTime';

const steps = [
    'Patient Complaints',
    'Diagnosis',
    'Treatment Objective',
    'Treatment Plan',
    'Treatment Plan To Diagnosis',
    'Estimated Treatment Time',
];

interface IAirReportSteps {
    onClosed: () => void;
    patient: any;
}

const enum StepType {
    PatientComplains,
    Diagnosis,
    TreatmentObjectives,
    TreatmentPlan,
    TreatmentPlanToDiagnosis,
    EstimatedTreatmentTime,
}

export const PatientTreatmentSteps: FC<IAirReportSteps> = observer(({ onClosed, patient }) => {
    const {
        saveDiagnosis,
        saveTreatmentPlan,
        setPatientId,
        saveTreatmentDiagnosis,
        resetTreatmentPlan,
        updateTreatment,
        getNewTreatmentPlanToDiagnosis,
    } = useMainStoreContext().patientTreatmentStore;

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === StepType.PatientComplains) {
            doNextStep();
        } else if (activeStep === StepType.Diagnosis) {
            doNextStep();
        } else if (activeStep === StepType.TreatmentObjectives) {
            saveTreatmentDiagnosis();
            getNewTreatmentPlanToDiagnosis();
            doNextStep();
        } else if (activeStep === StepType.TreatmentPlan) {
            saveTreatmentPlan();
            doNextStep();
        } else if (activeStep === StepType.TreatmentPlanToDiagnosis) {
            saveDiagnosis();

            doNextStep();
        } else if (activeStep === StepType.EstimatedTreatmentTime) {
            doNextStep();
            updateTreatment();
            onClosed();
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const closeWizzard = () => {};

    const doNextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    React.useEffect(() => {
        setPatientId(patient.id);

        return () => {
            resetTreatmentPlan();
        };
    }, [patient.id]);

    return (
        <Box className="BoxDialog">
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};

                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <hr />
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className="vivButton large" onClick={() => closeWizzard}>
                            RETURN TO PATIENT PORTAL
                        </Button>
                    </Box>
                </React.Fragment>
            ) : (
                <>
                    {activeStep === StepType.PatientComplains ? (
                        <Box>
                            <PatientComplains
                                complaint={patient.areasOfConcern}
                                complaintNote={patient.areasOfConcernNotes}
                            />
                        </Box>
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                    )}
                    {activeStep === StepType.Diagnosis ? (
                        <Box>
                            <DiagnosisPage />
                        </Box>
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                    )}
                    {activeStep === StepType.TreatmentObjectives ? (
                        <Box>
                            <TreatmentObjectivesPage />
                        </Box>
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                    )}
                    {activeStep === StepType.TreatmentPlan ? (
                        <Box>
                            <TreatmentPlanPage />
                        </Box>
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                    )}

                    {activeStep === StepType.TreatmentPlanToDiagnosis ? (
                        <Box>
                            <TreatmentPlantToDiagnosis />
                        </Box>
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                    )}

                    {activeStep === StepType.EstimatedTreatmentTime ? (
                        <Box>
                            <EstimatedTreatmentTime />
                        </Box>
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, marginLeft: '25px', marginRight: '25px' }}>
                        {activeStep !== 0 && (
                            <Button
                                className="vivButton large"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                        )}
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button
                            className="vivButton large"
                            onClick={event => {
                                handleNext();
                            }}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Confirm & Continue'}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
});
