import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Box, FormControl, Button } from '@mui/material';
import Item from "../../../components/Item";
import CssTextField from "../../../components/CssTextField";
import { useMainStoreContext } from '../../../stores/MainStore';
import Team from '../../../entities/Team';
import { Gateway } from '../../../api/Gateway';


const defaultTeam = {
    id: 0,
    organizationId: 1,
    name: '',
    description: '',
    isActive: true
}

const NewTeamsModalComponent = (props) => {
    //const { newTeam } = useMainStoreContext().createTeamStore;
    const [newTeam, setNewTeam] = React.useState(new Team(defaultTeam));
    const [ableToSave, setAbleToSave] = React.useState(false);
    const { toggleShowNewTeamModal, loadData } = useMainStoreContext().teamAdminStore;
    const handleSave = () => {
        var requestData = {
            "organizationID": 1,
            "name": newTeam.name,
            "description": newTeam.description,
            "isActive": true,
            "createdBy": 3,
            "createdOn": new Date(),
            "modifiedBy": 3,
            "modifiedOn": new Date(),
            "teamTypeId": props.teamTypeId
        }
        Gateway.post("/teams", requestData)
            .then((respData) => {
                if (respData) {
                    loadData(props.teamTypeId);
                    toggleShowNewTeamModal();
                }

            });
    }

    return (
        <form>
            <Box paddingTop={2} sx={{ flexGrow: 1, height: "100%" }}>
                <Grid>
                    <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
                        <Grid item md={4}>
                            <FormControl sx={{ width: '90%' }} >
                                <Item>
                                    <Grid container direction={"row"} className="formLabel" id="teamName"><Grid>Team Name</Grid></Grid>
                                    <CssTextField
                                        InputLabelProps={{ shrink: true }}
                                        className="TextBox"
                                        id="outlined-basic"
                                        size="small"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setNewTeam({ ...newTeam, name: event.target.value });
                                            var flag = event.target.value !== ''
                                            setAbleToSave(flag);
                                        }}
                                        value={newTeam.name}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <FormControl sx={{ width: '90%' }} >
                                <Item>
                                    <Grid container direction={"row"} className="formLabel" id="teamDesc"><Grid>Team Description</Grid></Grid>
                                    <CssTextField
                                        InputLabelProps={{ shrink: true }}
                                        className="TextBox"
                                        id="outlined-basic"
                                        size="small"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setNewTeam({ ...newTeam, description: event.target.value });
                                        }}
                                        value={newTeam.description}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-end">
                            <Button className="vivButton large" disabled={!ableToSave} onClick={handleSave}>Save</Button>
                            <Button className="vivButton large" onClick={() => {toggleShowNewTeamModal(); }}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </form>


    )
}

export const NewTeamsModal = observer(NewTeamsModalComponent);