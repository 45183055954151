// vendors
import React, { FC, useState } from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    StepLabel,
    Button,
    Step,
    Typography,
    Stepper,
    styled
} from '@mui/material';

// Components
import { AiReportPatientInformation } from './AiReportPatienInformation';
import { AiReportAddons } from './AiReportAddons';
import { AiReportPayment } from './AiReportPayment';
import { AiReportCheckOut } from './AiReportCheckOut';
import { AiReportOrdercomplete } from './AirReportOrderComplete';
import { AiReportSelectVisit } from './AiReportSelectVisit';

// Pages
import IntraoralImages from '../ImagesAndDocs/IntraoralImages';
import ScanCBCT from '../ImagesAndDocs/ScanCbct';
import ScanOcclusion from '../ImagesAndDocs/ScanOcclusion';
import ExtraoralImages from '../ImagesAndDocs/ExtraoralImages';

// Entities
import { PatientInformation } from '../../entities/AiReportOrder';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

// API
import { GlobalUtils } from '../../api/GlobalUtils';
import { PatientStore } from '../../api/PatientStore';
import { observer } from 'mobx-react';
import PatientVisit from '../../entities/PatientVisit';
import UploadedImageListPreview from '../UploadedImageListPreview';
import { toJS } from 'mobx';
import { isEmpty, isNull } from 'lodash';
import { Gateway } from '../../api/Gateway';

const BoxBorderBottom = styled(Box)(
    () => `
          border-bottom: transparent 5px solid;
    `
);

const steps = [
    'Report Setup',
    'Order Summary',
    'Order Complete',
];

// const comparisonSteps = [
//     'Select Visit',
//     'Patient Information',
//     'Extraoral Images',
//     'Intraoral Images',
//     'CBCT',
//     'Occlusion',
//     'Add-Ons',
//     'Order Summary',
//     'Checkout',
//     'Order Complete',
// ];

interface IAirReportSteps {
    patient: PatientInformation;
    submission?: any;
    onClosed: () => void;
    visit: PatientVisit;
}

export const AirReportSteps: FC<IAirReportSteps> = observer(({ patient, visit, submission, onClosed }) => {
    const { toggleAiReportOrderModal, loadData } = new PatientStore();
    // const {
    //     setIntraoralImageType,
    //     setExtraoralImageType,
    //     setCbctMachineBrandType,
    //     setMachineBrand3DType,
    //     setpatientOcclusionImageType,
    //     setpatientImageType,
    //     setMolarRelationshipType,
    //     patientImages, cbctMachineBrandType
    // } = useMainStoreContext().createPatientStore;

    const {
        addOns,
        setVisit,
        setAiReportOrderData,
        saveAiReportOrder,
        visitImagesToPatientImages,
        setPatient,
        setVivosId,
        uploadImages,
        resetVisitRecord
    } = useMainStoreContext().aiReportOrder;

    // const { fov01, fov02, cbctMachineBrand } = useMainStoreContext().scanStore;
    // const { updateOcclusionDetails } = useMainStoreContext().occlusionStore;
    const { id, visitRecords, patientsImages, getVisitId, loadVisits, setImages, setVisitPatientId } =
        useMainStoreContext().visitRecordStore;

    const [activeStep, setActiveStep] = useState(0);
    const [stepsToUse, setStepsToUse] = useState<any>(steps);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [visitRe, setVisitRe] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);

    // const COMPARISONAIREPORT = 'COMPARISONAIREPORT';

    const REPORTSETUP = 0;
    const ORDERSUMMARY = 1;
    const ORDERCOMPLETE = 2;

    // const COMPARESELECTVISIT = 0;
    // const COMPAREPATIENTINFORMATION = 1;
    // const COMPAREEXTRAORALIMAGES = 2;
    // const COMPAREINTRAORALIMAGES = 3;
    // const COMPARECBCT = 4;
    // const COMPAREOCCLUSION = 5;
    // const COMPAREADDONS = 6;
    // const COMPAREORDERSUMMARY = 7;
    // const COMPARECHECKOUT = 8;
    // const COMPAREORDERCOMPLETE = 9;


    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === REPORTSETUP) {
            doNextStep();
        } else if (activeStep === ORDERSUMMARY) {
            saveAiReportOrder().then(res => {
                loadData();
            });
            doNextStep();
        } else if (activeStep === ORDERCOMPLETE) {
            onClosed();
            resetVisitRecord();
        }
    };

    // const handleCompareNext = () => {
    //     if (activeStep === COMPARESELECTVISIT) {
    //         doNextStep();
    //     } else if (activeStep === COMPAREPATIENTINFORMATION) {
    //         doNextStep();
    //     } else if (activeStep === COMPAREEXTRAORALIMAGES) {
    //         uploadImages();
    //         setImages(patientsImages);
    //         uploadImages();
    //         doNextStep();
    //     } else if (activeStep === COMPAREINTRAORALIMAGES) {
    //         uploadImages();
    //         setImages(patientsImages);
    //         uploadImages();
    //         doNextStep();
    //     } else if (activeStep === COMPARECBCT) {
    //         setImages(patientsImages);
    //         uploadImages();
    //         doNextStep();
    //     } else if (activeStep === COMPAREOCCLUSION) {
    //         setImages(patientsImages);
    //         uploadImages();
    //         doNextStep();
    //     } else if (activeStep === COMPAREADDONS) {
    //         doNextStep();
    //     } else if (activeStep === COMPAREORDERSUMMARY) {
    //         doNextStep();
    //     } else if (activeStep === COMPARECHECKOUT) {
    //         doNextStep();
    //     } else if (activeStep === COMPAREORDERCOMPLETE) {
    //         saveAiReportOrder().then(res => {
    //             loadData();
    //         });
    //         onClosed();
    //         resetVisitRecord();
    //     }
    // };

    // const updateCbctDetails = () => {
    //     return new Promise((resolve, reject) => {
    //         var promises: any[] = [];
    //         var details = {
    //             cbctFov01: fov02,
    //             cbctFov02: fov01,
    //             machineBrandId: cbctMachineBrand
    //         }
    //         promises.push(Gateway.post(`visitrecord/${id}/cbctdetails`, details).then(() => { }));

    //         Promise.all(promises).then(() => {
    //             resolve(true);
    //         })

    //     });
    // }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const closeWizzard = () => {
        toggleAiReportOrderModal.bind(false);
    };

    const doNextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    React.useEffect(() => {
        setVisitPatientId(patient.id);
        loadVisits().then(resp => {
            setVisit(resp[0]);
            setPatient(patient);
            // setVisitId(id);
            setVivosId(patient.vivosId as string);
            visitImagesToPatientImages().then((res) => {
                isEmpty(submission)
                    && setAiReportOrderData(resp[0].id, patient.id, patient.providerId, patient.aiReportType, addOns, submission)
                // : setAiReportOrderData(resp[0].id, patient.id, patient.providerId, GlobalUtils.getAIReportTypes().find(x => x.listItemKey === COMPARISONAIREPORT), addOns, submission);
                setHasLoaded(true);
            });
        });
    }, []);

    // React.useEffect(() => {
    //     isEmpty(submission) ? setStepsToUse(steps) : setStepsToUse(comparisonSteps);
    //     setVisitPatientId(patient.id);
    //     //isEmpty(submission) ? setAiReportOrderData(id, patient.id, patient.providerId, patient.aiReportType, addOns, submission) : setAiReportOrderData(id, patient.id, patient.providerId, GlobalUtils.getAIReportTypes().find(x => x.listItemKey === COMPARISONAIREPORT), addOns, submission);
    //     setIntraoralImageType(GlobalUtils.getIntraOralImages());
    //     setExtraoralImageType(GlobalUtils.getExtraOralImages());
    //     setCbctMachineBrandType(GlobalUtils.getCBCTMachineBrands());
    //     setMachineBrand3DType(GlobalUtils.get3DMachineBrands());
    //     setpatientOcclusionImageType(GlobalUtils.getPatientOcclusionImageTypes());
    //     setpatientImageType(GlobalUtils.getPatientImageTypes());
    //     setMolarRelationshipType(GlobalUtils.getMolarRelationships());
    // }, []);


    const reportSteps = () => {
        return (
            <Grid sx={{ border: '1px solid #ccc', }}>
                {/* {activeStep === PATIENTINFORMATION ? (
                    <Box>
                        <AiReportPatientInformation patient={patient} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )} */}
                {/* {activeStep === EXTRAORALIMAGES ? (
                    <Box>
                        <ExtraoralImages isIntake={false} isVisit={false} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )} */}
                {/* {activeStep === INTRAORALIMAGES ? (
                    <Box>
                        <IntraoralImages isIntake={false} isVisit={false} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )} */}
                {/* {activeStep === CBCT ? (
                    <Box>
                        <ScanCBCT isIntake={false} visit={visit} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )} */}
                {/* {activeStep === OCCLUSION ? (
                    <Box>
                        <ScanOcclusion isIntake={false} visit={visit} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )} */}

                {activeStep === REPORTSETUP ? (
                    <Box>
                        <AiReportAddons visits={visitRecords} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}

                {activeStep === ORDERSUMMARY ? (
                    <Box>
                        <AiReportPayment />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}

                {activeStep === ORDERCOMPLETE ? (
                    <Box>
                        <AiReportOrdercomplete />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}
            </Grid>);
    };

    // const compareReportSteps = () => {
    //     return (
    //         <>
    //             {activeStep === COMPARESELECTVISIT ? (
    //                 <Box>
    //                     <AiReportSelectVisit visits={visitRecords} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}
    //             {activeStep === COMPAREPATIENTINFORMATION ? (
    //                 <Box>
    //                     <AiReportPatientInformation patient={patient} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}
    //             {activeStep === COMPAREEXTRAORALIMAGES ? (
    //                 <Box>
    //                     <ExtraoralImages isIntake={false} isVisit={false} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}
    //             {activeStep === COMPAREINTRAORALIMAGES ? (
    //                 <Box>
    //                     <IntraoralImages isIntake={false} isVisit={false} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}
    //             {activeStep === COMPARECBCT ? (
    //                 <Box>
    //                     <ScanCBCT isIntake={false} visit={visit} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}
    //             {activeStep === COMPAREOCCLUSION ? (
    //                 <Box>
    //                     <ScanOcclusion isIntake={false} visit={visit} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}

    //             {activeStep === COMPAREADDONS ? (
    //                 <Box>
    //                     <AiReportAddons visits={visitRecords} />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}

    //             {activeStep === COMPAREORDERSUMMARY ? (
    //                 <Box>
    //                     <AiReportPayment />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}

    //             {activeStep === COMPARECHECKOUT ? (
    //                 <Box>
    //                     <AiReportCheckOut />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}

    //             {activeStep === COMPAREORDERCOMPLETE ? (
    //                 <Box>
    //                     <AiReportOrdercomplete />
    //                 </Box>
    //             ) : (
    //                 <Typography sx={{ mt: 2, mb: 1 }}></Typography>
    //             )}
    //         </>);
    // };

    return (
        <Box >
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {stepsToUse.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};

                    if (patient?.aiReport) {
                        if (index === 7) {
                            return null;
                        }
                    }

                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <hr />
            {activeStep === stepsToUse.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className="vivButton large" onClick={() => closeWizzard}>
                            RETURN TO PATIENT PORTAL
                        </Button>
                    </Box>
                </React.Fragment>
            ) : (
                <>
                    {hasLoaded
                        ? (isEmpty(submission)
                            && reportSteps())
                        // : compareReportSteps())
                        : <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>}
                    {hasLoaded ?
                        <BoxBorderBottom
                            padding={1}
                            sx={(theme) => ({
                                borderBottomColor: theme.palette.primary.main,
                                width: '100%',
                                backgroundColor: theme.colors.alpha.black[5],
                                position: 'absolute',
                                bottom: '0',
                            })}>
                            <Grid container justifyContent={'right'} alignContent={'center'}>
                                {activeStep !== 0 && (
                                    <Button
                                        variant='contained'
                                        color='inherit'
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    variant='contained'
                                    onClick={event => {
                                        isEmpty(submission) && handleNext();
                                        //  : handleCompareNext();
                                    }}
                                >
                                    {activeStep === stepsToUse.length - 1 ? 'Finish' : 'Confirm & Continue'}
                                </Button>
                            </Grid>
                        </BoxBorderBottom> : <></>}
                </>
            )}
        </Box>
    );
});
