// Vendors
import { useRef, useState, useEffect, Component } from 'react';
import {
  Avatar,
  Box,
  alpha,
  IconButton,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';

// Components
import LeftNavBL from '../../../../components/LeftNav/LeftNavBL';
import { ProviderSettings } from '../../../../modals/ProviderSettings/ProviderSettings';
import ModalContainer from '../../../../components/ModalContainer';

// API
import jwt_decode from 'jwt-decode';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

const IconButtonAccountSettings = styled(IconButton)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(0)};
    color: ${theme.colors.alpha.trueWhite[70]};
    top: ${theme.spacing(0)};
    background: ${theme.colors.alpha.trueWhite[10]};
    &:hover {
           color: ${theme.colors.alpha.trueWhite[100]};
           background: ${alpha(theme.colors.alpha.trueWhite[100], 0.2)};
        }

  `
)

interface LeftNavProps {
  bl?: any;
}

@observer
class SidebarTopSection extends Component<LeftNavProps> {
  @observable hasloaded: boolean = false;
  @observable toggleShowProviderSettings: boolean = false;

  @action
  handleShowProviderSettings = () => {
    this.toggleShowProviderSettings = !this.toggleShowProviderSettings;
  };

  leftNavBL: LeftNavBL;

  constructor(props: any) {
    super(props);
    makeObservable(this);
    this.leftNavBL = new LeftNavBL();
  }

  render() {
    return (
      <Box
        sx={{
          textAlign: 'center',
          mx: 2,
          pt: 1,
          position: 'relative'
        }}
      >
        <Avatar
          sx={{
            width: 68,
            height: 68,
            mb: 2,
            mx: 'auto'
          }}
          alt={this.leftNavBL.username}
        />

        <Typography
          variant="h4"
          sx={{ color: (theme) => theme.colors.alpha.trueWhite[100] }}
        >
          {this.leftNavBL.username}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ color: (theme) => theme.colors.alpha.trueWhite[70] }}
        >
          [Insert User Type]
        </Typography>
        <IconButtonAccountSettings
          size="small"
          onClick={() => { this.handleShowProviderSettings() }}
        >
          <ManageAccountsTwoToneIcon fontSize="small" />
        </IconButtonAccountSettings>
        {this.toggleShowProviderSettings && <ModalContainer
          show={this.toggleShowProviderSettings}
          noScroll={true}
          title="Provider Settings"
          hideTitlebar={false}
          onClose={() => { this.handleShowProviderSettings() }}
          onClick={() => { this.handleShowProviderSettings() }}
        >
          <ProviderSettings />
        </ModalContainer>}
      </Box>
    );
  }
}

export default SidebarTopSection;
