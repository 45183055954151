// Vendor
import React, { Component, Fragment } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import {
    Link,
    Grid,
    Button,
} from '@mui/material';

// Components
import DataGrid from '../../components/DataGrid/DataGrid';
import { CreatePatientWizard } from '../../components/PatientIntake/CreatePatientWizard';
import PrescriptionHorizontal from '../prescription/prescriptionhorizontal';
import { GetAuthToken } from '../../components/GetAuthToken';
import { ModalContainer } from '../../components/ModalContainer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { PatientEdit } from '../../components/PatientProfile/PatientEdit';
import PatientEditBL from '../../components/PatientProfile/PatientEditBL';
import { AiReportModal } from '../../components/AiReportOrder';
import { DashboardInfoBox } from '../../components/DashboardInfoBox/DashboardInfoBox';
import { QuickAddModal } from '../../components/PatientIntake/QuickAddModal';
import View from '../../components/ViewCustomFormResponse/View';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from './components/PageHeader';

// Entities
import { TreatmentStatus, MyoCorrectStatus } from '../../entities/Enums';

// Store
import { PatientStore } from '../../api/PatientStore';
import { MainStoreContext } from '../../stores/MainStore';
import ShopifyStore from '../../stores/ShopifyStore';
import PrescriptionStore from '../../stores/PrescriptionStore';

// API
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import Moment from 'moment';

// Styling
import '../../scss/_Patients.scss';

interface PatientsProps {
    userRoles: any;
}

let userRoles: any = [];
let provId = -1;



@observer
export class Patients extends Component<PatientsProps> {
    @observable pageTitle = 'All Patients';
    defaultPage = 'PATIENTS';
    store: PatientStore = new PatientStore();
    static contextType = MainStoreContext;
    editBL: PatientEditBL; //= new PatientEditBL();
    static displayName = Patients.name;
    shopifyStore: ShopifyStore = new ShopifyStore();
    prescriptionStore: PrescriptionStore = new PrescriptionStore();

    constructor(props: PatientsProps) {
        super(props);
        makeObservable(this);
        this.editBL = new PatientEditBL();

    }



    columns: GridColDef[] = [
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            type: 'string',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    this.store.selectedVivosId = params.row.vivosId;
                                    this.store.currentPatientId = params.row.id;
                                    this.store.currentRow = params.row;
                                    this.store.toggleEditPatientModal();
                                }}
                            >
                                {params.row.vivosId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'firstName',
            flex: 1,
            headerName: 'First Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{params.row.firstName}</Grid>;
            },
        },
        {
            field: 'lastName',
            flex: 1,
            headerName: 'Last Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{params.row.lastName}</Grid>;
            },
        },
        {
            field: 'createdOn',
            flex: 1,
            headerName: 'Added',
            type: 'datetime',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{Moment(params.row.createdOn).format('MM/DD/yyyy')}</Grid>;
            },
        },
        {
            field: 'treatmentStatusId',
            flex: 1,
            headerName: 'Treatment Status',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => <Grid>{TreatmentStatus[params.row.treatmentStatusId]}</Grid>,
        },
        {
            field: 'myoCorrectStatusId',
            flex: 1,
            headerName: 'MyoCorrect',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => <Grid>{MyoCorrectStatus[params.row.myoCorrectStatusId]}</Grid>,
        },
        {
            field: 'isProspect',
            flex: 1,
            headerName: 'Is Prospective',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid>{params.row.isProspect ? 'True' : 'False'}</Grid>;
            },
        },
        {
            field: 'aiReportType',
            flex: 1,
            headerName: 'AI Report',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.aiReportType ? (
                            <>
                                <Link
                                    id={'patients.dashboard.cell.patientsAiReport' + params.row.ID}
                                    href={""}
                                >
                                    <LinkIcon id={'patients.dashboard.cell.patientsAiReport' + params.row.ID} />
                                </Link>
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'id',
            headerName: '',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: ({ value }) => {
                const onClick = () => {
                    this.store.setCurrentPatientId(value);
                    this.store.togglePrescriptionModal();
                };
                return (
                    <div>
                        <Button onClick={onClick}>NEW Rx</Button>
                    </div>
                );
            },
        },
    ];

    componentDidMount() {
        this.store.loadData();
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get('success') === 'true') {
            this.shopifyStore.client.checkout.fetch(sessionStorage.getItem('Cart')).then((checkout) => {
                this.prescriptionStore.getPrescription(sessionStorage.getItem('PrescriptionId')).then(() => {
                    this.prescriptionStore.updatePrescription(checkout).then(() => {
                        sessionStorage.removeItem('Cart');
                        sessionStorage.removeItem('PrescriptionId');
                    });
                })
            });
        }
    }
    k = 0;


    render() {
        const { customFormStore } = this.context;

        var hdrButtons: any = [];
        hdrButtons.push(
            <Button className="vivButton large" onClick={this.store.toggleQuickAddModal}>
                QUICK ADD PATIENT
            </Button>,
        );
        hdrButtons.push(
            <Button className="vivButton large" onClick={this.store.toggleAddPatientModal}>
                ADD PATIENT
            </Button>,
        );

        var infoBoxes: any = [];
        infoBoxes.push(
            <Grid key={'dib1'}>
                <DashboardInfoBox
                    title={"Today's Appointments"}
                    filter={0}
                    value={0}
                    currentFilter={0}
                    onFilter={value => { }}
                />
            </Grid>,
        );

        return (
            <>
                <GetAuthToken>
                    {({ roles, providerId }) => {
                        userRoles = roles;
                        provId = providerId;
                        return <div></div>;
                    }}
                </GetAuthToken>
                {/* <Grid xl={12} container direction="row" className="page"> */}
                <Fragment>
                    <PageTitleWrapper>
                        <PageHeader
                            onNewPatientClick={this.store.toggleAddPatientModal}
                            onChange={this.store.getSearchData}
                            onClear={this.store.loadData}
                            onCancel={this.store.onFilterCancel}
                            onFilter={this.store.onFilter}
                        />
                    </PageTitleWrapper>

                    {/* <LeftNav defaultPage={this.defaultPage} onToggle={this.store.toggleLeftNav} bl={this.store} /> */}
                    <Grid className={'pageContent' + (this.store.isLeftNavExpanded === true ? ' small' : ' big')}>
                        {/* <Grid> */}
                        {/* <GridHeader
                                onChange={this.store.getSearchData}
                                filterClass={this.store.toggleShowFilterResults === true ? 'filter expanded' : 'filter'}
                                onClear={this.store.loadData}
                                onCancel={this.store.onFilterCancel}
                                onFilter={this.store.onFilter}
                                title={this.pageTitle}
                                numResults={this.store.resultCount}
                                buttons={hdrButtons}
                            ></GridHeader> */}

                        {/* <ModalContainer show={this.store.toggleShowCancelWarningModal} title='WARNING' onClose={this.store.handleCancelClose.bind(this)}  onClick={this.store.handleCancelClose.bind(this)} >
                <CancelModal CloseModal={this.store.handleCancelClose.bind(this)} />
              </ModalContainer> */}
                        {/* </Grid> */}

                        <Grid className="grid">
                            {
                                // <InfiniteScroll
                                // pageStart={1}
                                // loadMore={this.store.loadMoreItems}
                                // hasMore={this.store.hasMore}
                                // loader={
                                //   <div className="loader" key={0}>
                                //     Loading ...
                                //   </div>
                                // }
                                // useWindow={true}>

                                <DataGrid
                                    data={this.store.patientData}
                                    columns={this.columns}
                                    loading={this.store.isLoadingPatients}
                                />
                                // </InfiniteScroll>
                            }
                        </Grid>
                        <AiReportModal
                            isOpen={this.store.toggleShowAiReportOrderModal}
                            patient={this.store.currentRow}
                            onClose={this.store.toggleAiReportOrderModal.bind(this)}
                        />
                    </Grid>
                    {/* </Grid> */}
                </Fragment>
                <QuickAddModal isOpen={this.store.toggleShowQuickddPatientsModal} onClose={this.store.toggleQuickAddModal} />
                <ModalContainer
                    // smallScreen={true}
                    show={this.store.toggleShowAddPatientsModal}
                    title="ADD PATIENT"
                    onClose={this.store.handleCancelClose}
                    onClick={this.store.handleCancelClose}
                >
                    {/* <CloseIcon onClick={() => { this.toggleAddPatientModal(); }} />  */}
                    <CreatePatientWizard
                        onClose={() => {
                            this.store.handleCancelClose(true);
                            this.store.loadData();
                        }}
                    ></CreatePatientWizard>
                </ModalContainer>
                <ModalContainer
                    show={this.store.toggleShowPatientEditModal}
                    noScroll={true}
                    title=""
                    hideTitlebar={true}
                    onClose={() => { }}
                    onClick={this.store.toggleEditPatientModal.bind(this)}
                >
                    <PatientEdit
                        asProvider={true}
                        patientID={this.store.currentPatientId}
                        patient={this.store.currentRow}
                        onClose={() => {
                            this.store.toggleEditPatientModal();
                            this.store.loadData();
                        }}
                    />
                </ModalContainer>
                <ModalContainer
                    show={this.store.toggleShowPrescriptionModal}
                    title="PRESCRIPTION"
                    onClose={() => {
                        this.store.toggleShowPrescriptionModal = false;
                        this.store.loadData();
                    }}
                    onClick={this.store.togglePrescriptionModal.bind(this)}
                >
                    <PrescriptionHorizontal patientId={this.store.currentPatientId}></PrescriptionHorizontal>
                </ModalContainer>

                <ModalContainer
                    show={customFormStore.toggleShowCustomFormViewModal}
                    title=""
                    onClose={customFormStore.toggleCustomFormModal.bind(this)}
                    onClick={customFormStore.toggleCustomFormModal.bind(this)}
                >
                    <View />
                </ModalContainer>
            </>
        );
    }
}
