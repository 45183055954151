// Vendors
import * as React from 'react';
import { FC, useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    SelectChangeEvent,
    IconButton,
    styled,
    Typography,
    Avatar,
    TextField
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';

// Components
import ModalContainer from "./ModalContainer";
import Appointments from './Appointments/Appointments';
import { SingleLineTextModal } from "./SingleLineTextModal";
import { CommentsPanel } from "./CommentsPanel/CommentsPanel";
import Label from "./UI/Label";

// Store
import { useMainStoreContext } from "../stores/MainStore";
import { PatientStore } from "../api/PatientStore";

// Entities
import { Gender, Ethnicity, MyoCorrectStatus } from "../entities/Enums";

// API
import _ from "lodash";
import moment from "moment";
import { observer } from "mobx-react";

// Styling
import CssSelectField from "./CssSelectField";
import CssTextField from "./CssTextField";

const RootWrapper = styled(Box)(
    ({ theme }) => `
          margin-top: ${theme.spacing(0)};
          margin-bottom: ${theme.spacing(3)};
          background: ${theme.colors.gradients.black2};
          border-radius: ${theme.general.borderRadius};
  `
);

interface IDetailProps {
    therapists: any;
    onClose(): any;
}

const FrenectomyStatusConst = [
    {
        id: '0',
        name: "None Needed"
    },
    {
        id: '1',
        name: "Lip Frenectomy"
    },
    {
        id: '4',
        name: "Lingual Frenectomy"
    },
    {
        id: '2',
        name: "Buccal Frenectomy"
    },
    {
        id: '8',
        name: "Completed"
    }
];

const DetailPanel: FC<IDetailProps> = (props: IDetailProps) => {

    const {
        myoCorrectStatus,
        selectedFrenectomy,
        clearSessionData,
        handleMyocorrectStatusChange,
        setMyoCorrectStatus,
        setSelectedFrenectomy,
        updateFrenectomyStatus } = useMainStoreContext().sessionsStore;
    const { currentRow, clearData, setCurrentRow, updatePatientDataItem } = useMainStoreContext().myoCorrectStore;
    const [patientStore, setPatientStore] = React.useState(new PatientStore());
    const [status, setStatus] = useState(currentRow.myoCorrectStatusId);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const [modalLabel, setModalLabel] = React.useState("");
    const [prevModalValue, setPrevModalValue] = React.useState("");
    const [modalFieldName, setModalfieldName] = React.useState("");
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleLabelClick = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    useEffect(() => {
        setPatientStore(new PatientStore());
        setMyoCorrectStatus(currentRow.myoCorrectStatusId);
        setDataLoaded(true);
    }, []);

    useEffect(() => {
        if (currentRow.frenectomyStatuses) {
            setSelectedFrenectomy(JSON.parse(currentRow.frenectomyStatuses).map(th => th.Status));
        }
    }, [currentRow.frenectomyStatuses])

    const changeStatus = (patientData, event) => {
        handleMyocorrectStatusChange(patientData, event);
    }

    const handleChange = (event: SelectChangeEvent<any>) => {
        var arrayvalue = event.target.value as string[];
        if (arrayvalue.indexOf("Completed") > -1) {
            setSelectedFrenectomy(["Completed"]);
        }
        else if (arrayvalue.indexOf("None Needed") > -1) {
            setSelectedFrenectomy(["None Needed"]);
        }
        else {
            setSelectedFrenectomy(event.target.value as string[]);
        }
    };

    const handleClickSave = () => {
        updateFrenectomyStatus(currentRow.id);
        props.onClose();
    }
    const onSave = (value: string) => {
        updateFrenectomyStatus(currentRow.id);
        currentRow[modalFieldName] = value;
        setShowModal(false);
        patientStore.updatePerson(currentRow);
        setCurrentRow(currentRow);
        updatePatientDataItem(currentRow.id, value, modalFieldName, currentRow);
    }

    return (

        // <Grid container direction="row">
        <RootWrapper>
            <Grid container spacing={3} px={4} minHeight={'250px'} alignContent={'center'}>
                <Grid item xs={1} height={165} alignItems="center" >
                    <Box display="flex" flex='2' alignItems="center" ml={3}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                width: 134,
                                height: 134
                            }}
                            src={currentRow.profileImageUrl}
                        />
                    </Box>

                </Grid>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item xs={4} direction={'column'}>
                            <Grid container direction={'row'} spacing={1}>
                                <Grid item>
                                    <Typography variant="h5" ml={3} color={'white'}>
                                        {currentRow.firstName}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Typography gutterBottom variant="h3" ml={3} color={'white'}>
                                {currentRow.lastName}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" ml={3} color={'white'}>
                                {currentRow.vivosId}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" ml={3} color={'white'}>
                                {`${moment(currentRow.dateOfBirth).format('MM/DD/yyyy')} | ${Gender[currentRow.genderId]} | ${Ethnicity[currentRow.ethnicityId]}`}
                            </Typography>
                            <Grid container ml={3}>
                                <IconButton className="icon small" onClick={
                                    () => {
                                        setModalType("email");
                                        setPrevModalValue(currentRow.emailAddress);
                                        setModalfieldName("emailAddress");
                                        setModalLabel("Email Address");
                                        setShowModal(true);
                                    }
                                }><Edit className="icon small" /></IconButton>

                                <Typography gutterBottom variant="subtitle1" color={'white'} ml={1} mr={1}>
                                    {`${currentRow.emailAddress} |   `}
                                </Typography>
                                <IconButton className="icon small" onClick={
                                    () => {
                                        setModalType("tel");
                                        setPrevModalValue(currentRow.primaryPhone);
                                        setModalLabel("Patient Phone No");
                                        setModalfieldName("primaryPhone");
                                        setShowModal(true);
                                    }
                                }><Edit className="icon small" /></IconButton>
                                <Typography gutterBottom variant="subtitle1" color={'white'} ml={1}>
                                    {currentRow.primaryPhone}
                                </Typography>

                            </Grid>

                        </Grid>
                        <Grid item xs={3} direction={'column'}>
                            <Grid container justifyContent={'space-evenly'} alignContent={'center'}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'} pr={1}>
                                        MyoCorrect Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justifyContent={'center'}>
                                        <TextField
                                            variant="outlined"
                                            InputProps={{ style: { backgroundColor: 'white', height: '40px' } }}
                                            // labelId="myocorrectstatusDropDownLabel"
                                            id="myocorrectstatusDropDown"
                                            value={(!_.isUndefined(myoCorrectStatus) ? myoCorrectStatus : 0)}
                                            // label="MyoCorrectStatus"
                                            defaultValue={0}
                                            select
                                            onChange={(event) => { changeStatus(currentRow, event.target.value); }}
                                            sx={{ border: "0px", height: '20px' }}
                                        >
                                            <MenuItem key={0} value={0}>{"Select a Status..."}</MenuItem>
                                            <MenuItem disabled key={7} value={7}><Label color={'warning'}>{"Enrolled"}</Label></MenuItem>
                                            <MenuItem disabled key={9} value={9}><Label color={'primary'}>{"In-Progress"}</Label></MenuItem>
                                            <MenuItem key={101} value={101}><Label color={'error'}>{"Cancelled"}</Label></MenuItem>
                                            <MenuItem key={102} value={102}><Label color={'error'}>{"Refunded"}</Label></MenuItem>
                                            <MenuItem key={10} value={10}><Label color={'success'}>{"Completed"}</Label></MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'space-evenly'} alignContent={'space-evenly'} height={'100px'}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'} pr={1}>
                                        Frenectomy Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justifyContent={'center'}>
                                        {selectedFrenectomy.map(row => (
                                            <Grid key={row}>
                                                {row}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'space-evenly'}>
                                <Grid item xs={6}>
                                    <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'} pr={1}>
                                        Treatment Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justifyContent={'center'}>
                                        <Label color={'info'}>{currentRow.treatmentStatusId}</Label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} >
                            <Grid container direction={'column'} justifyItems={'right'}>
                                <Typography gutterBottom variant="h4" ml={3} color={'white'} textAlign={'right'}>
                                    Provider Info
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'}>
                                    {currentRow.provider}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'}>
                                    {currentRow.providerPhone}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'}>
                                    {currentRow.providerEmail}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={2} direction={'column'}>
                            <Button
                                startIcon={<CommentTwoToneIcon />}
                                variant="contained"
                                // onClick={this.commentsEdit.handleClickModal}
                                sx={{
                                    width: '85%', textAlign: 'left', mb: 1,
                                    mx: 2
                                }}
                            >
                                Activity Summary
                            </Button>
                            <Button
                                startIcon={<DynamicFormTwoToneIcon />}
                                variant="contained"
                                // onClick={this.headerBL.handleClickModal}
                                sx={{
                                    width: '85%', textAlign: 'left', mb: 1,
                                    mx: 2
                                }}
                            >
                                Send Forms
                            </Button>
                            <Button
                                startIcon={<DynamicFormTwoToneIcon />}
                                variant="contained"
                                // onClick={this.openDialog}
                                sx={{
                                    width: '85%', textAlign: 'left', mb: 1,
                                    mx: 2
                                }}
                            >
                                Create Action Item
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <CommentsPanel
                        significantId={this.props.patient.personId}
                        isOpen={this.commentsEdit.isOpenModal}
                        onClose={this.commentsEdit.handleCloseModal.bind(this)}
                    /> */}
            </Grid>

            <Grid container>
                {
                    <Grid item xs={12}>
                        <Appointments
                            dataLoaded={dataLoaded}
                            patientId={currentRow.id}
                            vivosId={currentRow.vivosId}
                            sessions={currentRow.patientSessions}
                            assignedTherapist={currentRow.assignedTherapistId}
                            therapists={props.therapists}
                            myoCorrectPlanId={currentRow.myoCorrectPlanId}
                            myoCorrectEnrollmentDate={currentRow.myoCorrectEnrollmentDate} />
                    </Grid>
                }
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
                <Button id="detailpanel-mod.btn.close" className="vivButton large" onClick={
                    () => {
                        clearData();
                        clearSessionData();
                        props.onClose();
                    }
                }>Close</Button>
            </Grid>
            <SingleLineTextModal fieldName={modalFieldName} isOpen={showModal} title={modalLabel} type={modalType} currentObj={currentRow} onSave={onSave}
                onClose={() => {
                    currentRow[modalFieldName] = prevModalValue;
                    setShowModal(false);
                }} />
            {/* </Grid > */}
        </RootWrapper>
    )
}

export default observer(DetailPanel);
