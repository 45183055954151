import { observable, action, computed, makeAutoObservable, runInAction } from "mobx";
import * as _ from 'lodash';
import jwt_decode from "jwt-decode";
import MyoCorrectSession from "../../entities/MyoCorrectSession";
import moment from "moment";


export default class AppointmentsBL {
    @observable private _open: boolean = false;
    @observable private _openSessionNotesModal: boolean = false;
    @observable private _patientId: number = -1;
    @observable private _isMyoCorrectAdvisor = false;
    @observable private _isMyoCorrectAdvocate = false;
    @observable private _isMyoCorrectAdmin = false;
    @observable private _isVivosAdmin = false;
    @observable private _isProvider = false;
    @observable private _isAvailableToSchedule = false;
    @observable private _toggleShowSetNowShowModal: boolean = false;
    roles: any = [];
    userID: number = 0;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles, id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    get open() {
        return this._open;
    }

    @action
    set open(value: boolean) {
        this._open = value;
    }

    get patientId() {
        return this._patientId;
    }

    @action
    set patientId(value: number) {
        this._patientId = value;
    }

    get openSessionNotesModal() {
        return this._openSessionNotesModal;
    }

    @action
    set openSessionNotesModal(value: boolean) {
        this._openSessionNotesModal = value;
    }

    get isMyoCorrectAdvisor(): any {
        return this._isMyoCorrectAdvisor;
    }

    @action
    set isMyoCorrectAdvisor(value: any) {
        this._isMyoCorrectAdvisor = value;
    }

    get isMyoCorrectAdvocate(): any {
        return this._isMyoCorrectAdvocate;
    }

    @action
    set isMyoCorrectAdvocate(value: any) {
        this._isMyoCorrectAdvocate = value;
    }

    get isMyoCorrectAdmin(): any {
        return this._isMyoCorrectAdmin;
    }

    @action
    set isMyoCorrectAdmin(value: any) {
        this._isMyoCorrectAdmin = value;
    }

    get isVivosAdmin(): any {
        return this._isVivosAdmin;
    }

    @action
    set isVivosAdmin(value: any) {
        this._isVivosAdmin = value;
    }
    get isProvider(): any {
        return this._isProvider;
    }

    @action
    set isProvider(value: any) {
        this._isProvider = value;
    }

    get isAvailableToSchedule(): any {
        return this._isAvailableToSchedule;
    }

    @action
    set isAvailableToSchedule(value: any) {
        this._isAvailableToSchedule = value;
    }

    get toggleShowSetNowShowModal() {
        return this._toggleShowSetNowShowModal;
    }

    @action
    set toggleShowSetNowShowModal(value: any) {
        this._toggleShowSetNowShowModal = value;
    }


    @action
    toggleNoShowModal = () => {
        runInAction(() => {
            this.toggleShowSetNowShowModal = !this.toggleShowSetNowShowModal;
        })
    }

    @action
    availableToSchedule = (sessionData) => {
        if (moment(sessionData.scheduleDateTime, 'YYYY-MM-DD').isSameOrAfter(moment(process.env.REACT_APP_CUTOVER_DATE, 'YYYY-MM-DD'))) {
            return true;
        }

        return false;
    }


    @action
    toggleOpenSessionNotesModal = () => {
        this._openSessionNotesModal = !this._openSessionNotesModal
    }

    @action
    getOldNotesElem = (session: MyoCorrectSession) => {
        if((!_.isUndefined(session.oldNotesUrl) && !_.isNull(session.oldNotesUrl))) {
                return "show";
        } else {
            return "new";
        }
    }

    @action
    getNotesElem = (session: MyoCorrectSession) => {
       // if((!_.isUndefined(session.scheduleDateTime) && !_.isNull(session.scheduleDateTime)) && (!_.isUndefined(session.assignedTherapistId) && !_.isNull(session.assignedTherapistId))) {
        if((session.scheduleStatus != "No Show") && (!_.isUndefined(session.assignedTherapistId) && !_.isNull(session.assignedTherapistId))) {
            var nowdate = new Date();
            var now = moment(nowdate);
            var diff = now.diff(session.scheduleDateTime, 'hours');
            var isNoShow = session.scheduleStatus == "No Show";
            var isOutside48hrWindow = diff < -24 || diff > 24;
            var hasNotes = (!_.isUndefined(session.sessionNotesPdf) && !_.isNull(session.sessionNotesPdf));
            var hasNotesCreatedDate = !_.isUndefined(session.sessionNotesCreatedOn) && !_.isNull(session.sessionNotesCreatedOn);
            var notesCreationDiff = moment(now).diff(session.sessionNotesCreatedOn, 'hours');
            //if(!isOutside48hrWindow && !isNoShow && !hasNotes) {
            if( !isNoShow && !hasNotes) {
                return "new";
            } else if (!isNoShow && hasNotes && hasNotesCreatedDate && notesCreationDiff < 24) {
                return "edit";
            } else if (!isNoShow && hasNotes && hasNotesCreatedDate && notesCreationDiff > 24) {
                return "editAfterPeriod";
            } else if (!isNoShow && hasNotes && hasNotesCreatedDate && notesCreationDiff >= 24) {
                return "show";
            } else {
                return "";
            }
        } else {
            return "";
        }
    }


    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                this.isVivosAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_PROVIDER) {
                this.isProvider = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVISOR) {
                this.isMyoCorrectAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE) {
                this.isMyoCorrectAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADMIN) {
                this.isMyoCorrectAdmin = true;
            }
        }
        return;
    }


}

