// @vendors
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import {
    DragDropContext,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
    DroppableProvided,
} from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { FixedSizeList } from 'react-window';
import { observer } from 'mobx-react';

// @components
import { CardProvider } from './CardProvider';
import { SearchProvider } from './SearchProvider';
import { RowList } from './Row';

// @stores
import { useMainStoreContext } from '../../../stores/MainStore';
import { Oval } from 'react-loader-spinner';
import { toJS } from 'mobx';

interface CustomFormToProvidersFormValues {
    isOpen: boolean;
    onClose: () => void;
    customFormId: number;
}

export const CustomFormToProvidersPage: React.FC<CustomFormToProvidersFormValues> = observer(
    ({ onClose, isOpen, customFormId }) => {
        const {
            providers,
            setProviderIds,
            sendCustomFormToProviders,
            setCustomFormId,
            resetCustomFormToProviderStore,
            isLoadingSearchProviders,
            providersByForm,
            setProvidersByForm,
        } = useMainStoreContext().customFormToProviderStore;

        const [state, setState] = React.useState<any>();

        const [providerSelected, setProviderSelected] = useState<any>([]);

        const handleSendCustomFormToProviders = async () => {
            const ids = providerSelected.map((item: any) => item.id);
            setProviderIds(ids);
            setCustomFormId(customFormId);
            await sendCustomFormToProviders().then(() => {
                onClose();
                setProviderSelected([]);
                resetCustomFormToProviderStore();
            });
        };

        useEffect(() => {
            setProviderSelected(state?.selected?.items);
        }, [state?.selected?.items]);

        useMemo(() => {
            const init = {
                available: {
                    id: 'column-0',
                    title: 'Available',
                    items: providers,
                },
                selected: {
                    id: 'column-1',
                    title: 'Selected',
                    items: providersByForm,
                },
            };
            setState(init);
        }, [providers, providersByForm]);

        const handleClose = () => {
            onClose();
        };

        const onDragEnd = useCallback(
            result => {
                const { source, destination } = result;

                if (!destination) {
                    return;
                }

                if (source.droppableId !== destination.droppableId) {
                    const sourceColumn = state[source.droppableId];
                    const destColumn = state[destination?.droppableId];

                    const sourceItems = [...sourceColumn.items];
                    const destItems = [...destColumn.items];

                    const [removed] = sourceItems.splice(source.index, 1);
                    destItems.splice(destination?.index, 0, removed);

                    setState({
                        ...state,
                        [source.droppableId]: {
                            ...sourceColumn,
                            items: sourceItems,
                        },
                        [destination?.droppableId]: {
                            ...destColumn,
                            items: destItems,
                        },
                    });
                } else {
                    const column = state[source.droppableId];
                    const copiedItems = [...column.items];
                    const [removed] = copiedItems.splice(source.index, 1);
                    copiedItems.splice(destination?.index!, 0, removed);

                    setState({
                        ...state,
                        [source.droppableId]: {
                            ...column,
                            items: copiedItems,
                        },
                    });
                }
            },
            [state, setState],
        );

        return (
            <Dialog className="custom-form-to-providers-container" open={isOpen} onClose={handleClose} maxWidth="xl">
                <DialogTitle
                    className="
                    custom-form-to-providers-container__title
                "
                >
                    Custom Form To Providers
                </DialogTitle>
                <DialogContent
                    className=" 
                custom-form-to-providers-container__content
                "
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Grid className="custom-form-to-providers-container__content__grid" xs={12}>
                            <Grid item xs={6} className="">
                                <Droppable
                                    droppableId="available"
                                    mode="virtual"
                                    renderClone={(
                                        provided: DraggableProvided,
                                        snapshot: DraggableStateSnapshot,
                                        rubric: DraggableRubric,
                                    ) => (
                                        <CardProvider
                                            provided={provided}
                                            isDragging={snapshot.isDragging}
                                            item={state[rubric.source.index]}
                                            style={{ margin: 0 }}
                                            index={rubric.source.index}
                                        />
                                    )}
                                >
                                    {(droppableProvided: DroppableProvided) => (
                                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                            <Grid
                                                container
                                                alignContent="center"
                                                flexDirection="column"
                                                justifyContent="center"
                                            >
                                                <h4>Available</h4>
                                                <SearchProvider />
                                            </Grid>
                                            <Grid container justifyContent="center">
                                                {isLoadingSearchProviders ? (
                                                    <div className="ListOval" ref={droppableProvided.innerRef}>
                                                        <Oval
                                                            height={60}
                                                            width={60}
                                                            color="#FF7C00"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            ariaLabel="oval-loading"
                                                            secondaryColor="#480141"
                                                            strokeWidth={4}
                                                            strokeWidthSecondary={4}
                                                        />
                                                    </div>
                                                ) : (
                                                    <FixedSizeList
                                                        height={400}
                                                        itemCount={state.available.items.length}
                                                        itemSize={100}
                                                        width={400}
                                                        style={{ padding: 8, transition: 'background-color 0.2s ease' }}
                                                        itemData={state.available.items}
                                                        className="List"
                                                        outerRef={droppableProvided.innerRef}
                                                    >
                                                        {RowList}
                                                    </FixedSizeList>
                                                )}
                                            </Grid>
                                        </div>
                                    )}
                                </Droppable>
                            </Grid>

                            <Grid item xs={6} className="">
                                <Droppable
                                    droppableId="selected"
                                    mode="virtual"
                                    renderClone={(
                                        provided: DraggableProvided,
                                        snapshot: DraggableStateSnapshot,
                                        rubric: DraggableRubric,
                                    ) => (
                                        <CardProvider
                                            provided={provided}
                                            isDragging={snapshot.isDragging}
                                            item={state[rubric.source.index]}
                                            style={{ margin: 0 }}
                                            index={rubric.source.index}
                                        />
                                    )}
                                >
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <Grid container justifyContent="center">
                                                <h4>Selected</h4>
                                            </Grid>
                                            <Grid container justifyContent="center">
                                                <FixedSizeList
                                                    height={400}
                                                    itemCount={state?.selected?.items?.length}
                                                    itemSize={100}
                                                    width={400}
                                                    style={{ padding: 8, transition: 'background-color 0.2s ease' }}
                                                    itemData={state?.selected?.items}
                                                    className="List"
                                                >
                                                    {RowList}
                                                </FixedSizeList>
                                            </Grid>
                                        </div>
                                    )}
                                </Droppable>
                            </Grid>
                        </Grid>
                    </DragDropContext>

                    <Grid container justifyContent="flex-end">
                        <Button
                            className="vivButton xlarge"
                            onClick={() => {
                                setProviderSelected([]);
                                resetCustomFormToProviderStore();
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="vivButton xlarge"
                            onClick={() => {
                                handleSendCustomFormToProviders().then(() => {
                                    setProviderSelected([]);
                                    resetCustomFormToProviderStore();
                                });
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    },
);
