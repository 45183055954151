import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, SelectChangeEvent, Select, Checkbox } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';

import CssTextField from "../CssTextField";
import Item from "../Item";
import { MenuItem } from "@material-ui/core";
import { useMainStoreContext } from '../../stores/MainStore';
import { AlertDialog } from '../AlertDialog';
import { isNull } from "lodash";

interface ComponentProps {
    visitId: number
}

const HeadNeck = (props: ComponentProps) => {
    const { visitId } = props;
    const [hasLoaded, setHasLoaded] = useState(false);
    const { loadReportPropertiesData, saveReportInfo } = useMainStoreContext().intakeReportsStore;
    const [reportValues, setReportValues] = useState<any>([]);
    const [disabled, setDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
    const [current, setCurrent] = useState<any>({
        index: "",
        value: ""
    });

    const classesList = [
        {
            value: "NA",
            label: "NA"
        },
        {
            value: "Class I",
            label: "Class I"
        },
        {
            value: "Class II",
            label: "Class II"
        },
        {
            value: "Class III",
            label: "Class III"
        }
    ];

    const biteDetailsList = [
        {
            value: "Not Included",
            label: "Not Included"
        },
        {
            value: "Bite-Wing Scan",
            label: "Bite-Wing Scan"
        },
        {
            value: "Inadecuate Spacing",
            label: "Inadecuate Spacing"
        },
        {
            value: "Natural Bite",
            label: "Natural Bite"
        }
    ];

    const tongueConditionsList = [
        {
            value: "WNL",
            label: "WNL"
        },
        {
            value: "Fissured",
            label: "Fissured"
        }
    ];

    useEffect(() => {
        loadReportPropertiesData('headneck').then(resp => {
            resp.map(r => {
                var value = "false";
                if (r.name.includes("Molar Relationship") || r.name === "Malocclusion") {
                    value = "NA";
                }

                if (r.name === "Tongue Condition") {
                    value = "WNL";
                }

                if (r.name === "Construction Bite Details") {
                    value = "Not Included";
                }

                if (r.name === "BMI" || r.name === "TMJ ROM Measurement"
                    || r.name.includes("Maxillary Sinus") || r.name.includes("Angle")
                    || r.name.includes("Width")) {
                    value = "0";
                }
                reportValues.push({ PropertyId: r.id, Name: r.name, Value: value })
            });
            setHasLoaded(true);
        })
    }, []);

    const questions = (index) => {
        var conditionTitle =
            <FormGroup>
                <FormControlLabel className="sectionHdr" control={<Grid>{reportValues[index].Name}</Grid>} label="" />
            </FormGroup>;
        var conditionElement =
            <FormControlLabel
                control={<Checkbox
                    checked={(reportValues[index].Value === "true")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        var value = event.target.checked;
                        reportValues[index].Value = String(value);
                        setCurrent({ index: index, value: value });
                    }}
                />} label="Condition Present" />;

        if (reportValues[index].Name.includes("Molar Relationship") || reportValues[index].Name === "Malocclusion") {
            conditionElement =
                <Select
                    id={`select${reportValues[index].Name}`}
                    sx={{ width: '300px', height: '30px' }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        var selected = classesList.filter(x => x.value.toString() === event.target.value.toString())[0];
                        reportValues[index].Value = selected.value.toString();
                        setCurrent({ index: index, value: selected.value });
                    }}
                    value={reportValues[index].Value.toString() === "" ? "NA" : reportValues[index].Value.toString()}
                >
                    {classesList.map((currentVal) => (
                        <MenuItem value={currentVal.value}>{`${currentVal.label}`}</MenuItem>
                    ))}
                </Select>;
        }

        if (reportValues[index].Name === "Tongue Condition") {
            conditionElement =
                <Select
                    id={`select${reportValues[index].Name}`}
                    sx={{ width: '300px', height: '30px' }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        var selected = tongueConditionsList.filter(x => x.value.toString() === event.target.value.toString())[0];
                        reportValues[index].Value = selected.value.toString();
                        setCurrent({ index: index, value: selected.value });
                    }}
                    value={reportValues[index].Value.toString()}
                >
                    {tongueConditionsList.map((currentVal) => (
                        <MenuItem value={currentVal.value}>{`${currentVal.label}`}</MenuItem>
                    ))}
                </Select>;
        }

        if (reportValues[index].Name === "Construction Bite Details") {
            conditionElement =
                <Select
                    id={`select${reportValues[index].Name}`}
                    sx={{ width: '300px', height: '30px' }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        var selected = biteDetailsList.filter(x => x.value.toString() === event.target.value.toString())[0];
                        reportValues[index].Value = selected.value.toString();
                        setCurrent({ index: index, value: selected.value });
                    }}
                    value={reportValues[index].Value.toString()}
                >
                    {biteDetailsList.map((currentVal) => (
                        <MenuItem value={currentVal.value}>{`${currentVal.label}`}</MenuItem>
                    ))}
                </Select>;
        }

        if (reportValues[index].Name === "BMI" || reportValues[index].Name === "TMJ ROM Measurement"
            || reportValues[index].Name.includes("Maxillary Sinus") || reportValues[index].Name.includes("Angle")
            || reportValues[index].Name.includes("Width")) {
            conditionElement =
                <CssTextField
                    variant="outlined"
                    className="TextBox"
                    size="small"
                    type="number"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        reportValues[index].Value = event.target.value.toString();
                        setCurrent({ index: index, value: event.target.value });
                    }}
                    value={reportValues[index].Value}
                />;
        }

        return (<Grid xs={12} alignItems="center" container direction={"row"}>
            <Grid item xs={4}>
                {conditionTitle}
            </Grid>
            <Grid item xs={8}>
                <FormControl>
                    <Item>
                        {conditionElement}
                    </Item>
                </FormControl>
            </Grid>
        </Grid>
        );
    }

    const handleClick = () => {
        setDisabled(true);
        saveReportInfo(visitId, reportValues, 'headneck').then((resp) => {
            if (resp === true) {
                setAlertTitle("Report saved succesfully.");
                setAlertMessage("Report saved succesfully.");
                setOpenAlertMessage(true);
            } else {
                setDisabled(false);
                setAlertTitle("Report not saved.");
                setAlertMessage("There was an error saving the report. Please try again.");
                setOpenAlertMessage(true);
            }
        });
    }

    return (
        <Grid >
            {hasLoaded ?
                <Grid container direction={"row"} className="formSectionLabel"><Grid>Head & Neck Exam</Grid>
                    <Grid sx={{ margin: "25px 25px", padding: "0px 35px" }} className="formBasics">
                        <Grid className="formBasics" >
                            <Grid>
                                {reportValues.map((r, i) => { return questions(i); })}
                            </Grid>
                            <Grid>
                                <Grid container direction={"row"} justifyContent={"flex-end"}><Button className="vivButton large invert" onClick={handleClick} disabled={disabled}>Save</Button></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> : <></>}
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    )
};

export default observer(HeadNeck);
