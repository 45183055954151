import * as React from "react";
import { LineItem } from "shopify-buy";
import { useMainStoreContext } from "../../stores/MainStore";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { Cancel } from "@material-ui/icons";

interface ILineItemProps {
  lineItem: LineItem;
}

export default function LineItemComponent(props: ILineItemProps) {
  const { lineItem } = props;
  const { removeLineItemFromCart } = useMainStoreContext().shopifyStore;
  return (
    <ListItem>
        <ListItemButton>
          {lineItem.variant.image && (
            <ListItemAvatar>
              <Avatar alt={`${lineItem.title} product shot`} variant="square" src={lineItem.variant.image.src} />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={lineItem.title}
            secondary={
              <div style={{ display: 'flex', gap: '5px' }}>
                <span>
                  $ {(lineItem.quantity * parseFloat(lineItem.variant.price.amount)).toFixed(2)}
                </span>
                <span>Quantity: {lineItem.quantity}</span>
              </div>
            }/>
            <ListItemSecondaryAction>
              <IconButton>
                <Cancel onClick={() => removeLineItemFromCart(lineItem.id)} />
              </IconButton>
            </ListItemSecondaryAction>
        </ListItemButton>
    </ListItem>
  );
}
