import React, { useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, SelectChangeEvent, Select } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';

import CssTextField from "../CssTextField";
import Item from "../Item";
import { MenuItem } from "@material-ui/core";
import { useMainStoreContext } from '../../stores/MainStore';
import { AlertDialog } from '../AlertDialog';

interface ComponentProps {
    visitId: number
}

const HeadacheImpact = (props: ComponentProps) => {
    const { visitId } = props;
    const [hasLoaded, setHasLoaded] = useState(false);
    const { loadReportPropertiesData, saveReportInfo } = useMainStoreContext().intakeReportsStore;
    const [reportValues, setReportValues] = useState<any>([]);
    const [disabled, setDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);

    useEffect(() => {
        loadReportPropertiesData('headacheimpact').then(resp => {
            resp.map(r => {
                if (r.name.includes('Score')) {
                    reportValues.push({ PropertyId: r.id, Name: r.name, Value: "0" });
                } else {
                    reportValues.push({ PropertyId: r.id, Name: r.name, Value: "6" });
                }
            });
            setHasLoaded(true);
        })
    }, []);

    const dozingList = [
        {
            value: "6",
            label: "Never"
        },
        {
            value: "8",
            label: "Rarely"
        },
        {
            value: "10",
            label: "Sometimes"
        },
        {
            value: "11",
            label: "Very Often"
        },
        {
            value: "13",
            label: "Always"
        }
    ];

    const [current, setCurrent] = useState<any>({
        value: "6",
        label: ""
    });

    const questions = (index) => {
        return (<Grid>
            <Grid xs={12} container direction={"row"} justifyContent="flex-start">
                <FormGroup>
                    <FormControlLabel className="sectionHdr" control={<Grid>{reportValues[index].Name}</Grid>} label="" />
                </FormGroup>
            </Grid>
            <Grid xs={12} container direction={"row"} className="bottomMargin15">
                <Grid xs={12} container direction={"row"}>
                    <Grid item xs={3}>
                        <FormControl>
                            <Item>
                                <Select
                                    id={`select${reportValues[index].Name}`}
                                    sx={{ width: '300px', height: '30px' }}
                                    onChange={(event: SelectChangeEvent<string>) => {
                                        var selected = dozingList.filter(x => x.value.toString() === event.target.value.toString())[0];
                                        reportValues[index].Value = selected.value.toString();
                                        setCurrent(selected);
                                    }}
                                    value={reportValues[index].Value.toString()}
                                >
                                    {dozingList.map((currentVal) => (
                                        <MenuItem value={currentVal.value}>{`${currentVal.label} - ${currentVal.value}`}</MenuItem>
                                    ))}
                                </Select>
                            </Item>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        );
    }

    const handleClick = () => {
        var scoreIndex = reportValues.findIndex(r => { return (r.Name.includes('Score')) });
        reportValues[scoreIndex].Value = reportValues.reduce((accumulator, object) => {
            return parseInt(accumulator) + parseInt(object.Value);
        }, 0).toString();
        setDisabled(true);
        saveReportInfo(visitId, reportValues, 'headacheimpact').then((resp) => {
            if (resp === true) {
                setAlertTitle("Report saved succesfully.");
                setAlertMessage("Report saved succesfully.");
                setOpenAlertMessage(true);
            } else {
                setDisabled(false);
                setAlertTitle("Report not saved.");
                setAlertMessage("There was an error saving the report. Please try again.");
                setOpenAlertMessage(true);
            }
        });
    }

    return (
        <Grid >
            {hasLoaded ?
                <Grid container direction={"row"} className="formSectionLabel"><Grid>Headache Impact Test</Grid>
                    <Grid sx={{ margin: "25px 25px", padding: "0px 35px" }} className="formBasics">
                        <Grid className="formBasics" >
                            <Grid>
                                {reportValues.map((r, i) => { if (i < reportValues.length - 1) return questions(i); })}
                                <Grid xs={12} >
                                    <Grid xs={12}>
                                        <Grid className="formLabel" >Total Score</Grid>
                                        <CssTextField
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            disabled={true}
                                            value={reportValues.find(r => { return (r.Name.includes('Score')) }).Value} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid container direction={"row"} justifyContent={"flex-end"}><Button className="vivButton large invert" onClick={handleClick} disabled={disabled}>Save</Button></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> : <></>}
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    )
};

export default observer(HeadacheImpact);
