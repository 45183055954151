import _ from "lodash";
import { action, makeObservable, makeAutoObservable, observable, runInAction } from "mobx";
import jwt_decode from "jwt-decode";
import { any } from "prop-types";
import DictionaryList from "../entities/DictionaryList";
import DictionaryListItem from "../entities/DictionaryListItem";
import { Gateway } from "./Gateway";
import { GlobalUtils } from "./GlobalUtils";
import { observer } from "mobx-react";
import { ListItemIcon } from "@material-ui/core";
import User from "../entities/User";
import Role from "../entities/Role";

const defaultList = {
    name: "",
    listKey: "",
    dictionaryListItems: [],
    isActive: true,
    createdBy: 0
}

const defaultListItem = {
    name: "",
    listItemKey: "",
    value: "",
    dictionaryListId: -1,
    isActive: true,
    createdBy: 0
}

export class AdminBL {
    @observable private _lists: DictionaryList[] = [];
    @observable private _users: User[] = [];
    @observable private _roles: Role[] = [];
    @observable private _primaryCategories: DictionaryList[] = [];
    @observable private _secondaryCategories: DictionaryList[] = [];
    @observable private _propTables: any[] = [];
    @observable private _currentList: any = {};
    @observable private _currentPropertyTable: any = {};
    @observable private _currentListItem: any = {};
    @observable private _currentUser: User = {} as User;
    @observable private _isItemModalOpen = false;
    @observable private userID = 0;

    constructor() {
       makeObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles, id } = jwt_decode(token);
            this.userID = tokenData.id;
        }
    }

    get lists(): DictionaryList[] {
        return this._lists;
    }

    @action
    setLists(value: DictionaryList[]) {
        this._lists = value;
    }

    get users(): User[] {
        return this._users;
    }

    @action
    setUsers(value: User[]) {
        this._users = value;
    }

    get roles(): Role[] {
        return this._roles;
    }

    @action
    setRoles(value: Role[]) {
        this._roles = value;
    }

    get primaryCategories(): DictionaryList[] {
        return this._lists;
    }

    @action
    setPrimaryCategories(value: DictionaryList[]) {
        this._primaryCategories = value;
    }

    get secondaryCategories(): DictionaryList[] {
        return this._secondaryCategories;
    }

    @action
    setSecondaryCategories(value: DictionaryList[]) {
        this._secondaryCategories = value;
    }

    get propertyTables(): any[] {
        return this._propTables;
    }

    @action
    setPropertyTables(value: any[]) {
        this._propTables = value;
    }

    get currentPropertyTable(): any {
        return this._currentPropertyTable;
    }
    
    @action
    setCurrentPropertyTable(value) {
        this._currentPropertyTable = value;
    }

    get currentList(): DictionaryList {
        return this._currentList;
    }
    
    @action
    setCurrentList(value) {
        this._currentList = value;
    }

    get currentListItem(): DictionaryListItem {
        return this._currentListItem;
    }
   
    setCurrentListItem(value) {
        this._currentListItem = value;
    }

    get isItemModalOpen() {
        return this._isItemModalOpen;
    }

    get currentUser(): User {
        return this._currentUser;
    }

    @action
    setCurrentUser(value: User) {
        this._currentUser = value;
    }
    
    setIsItemModalOpen(value) {
        this._isItemModalOpen = value;
    }

    @action
    createNewList = () => {
        this._currentList = defaultList;
        this._currentList.createdBy = this.userID;
    }

    @action
    createNewListItem = () => {
        this._currentListItem = defaultListItem;
        this._currentListItem.createdBy = this.userID;
        this._currentListItem.dictionaryListId = this.currentList.id;
    }

    @action
    loadLists = async (): Promise<any> => {
        await GlobalUtils.getDictionaryLists().then((data) => {
             this.setLists( data );
             return this.lists;
        });

        return this.lists;
    }

    @action
    loadUsers = async (): Promise<any> => {
        await Gateway.get("global/users").then((data) => {
             this.setUsers( data );
             return this.users;
        });

        return this.users;
    }

    @action
    loadRoles = async (): Promise<any> => {
        await GlobalUtils.getRoles().then((data) => {
             this.setRoles( data );
             return this.roles;
        });

        return this.roles;
    }

    @action
    loadPropTables = async (): Promise<any> => {
        await GlobalUtils.getPropertyTables().then((data) => {
             this.setPropertyTables( data );
             return this.propertyTables;
        });

        return this.propertyTables;
    }

    @action
    loadPrimaryCategories = async (): Promise<any> => {
        await GlobalUtils.getPrimaryCategories().then((data) => {
            this.setPrimaryCategories(data);
        })
    }

    @action
    loadSecondaryCategories = async (): Promise<any> => {
        await GlobalUtils.getSecondaryCategories().then((data) => {
            this.setSecondaryCategories(data);
        })
    }

    @action
    reloadLists = async () => {
        await GlobalUtils.reloadDictionaryLists().then((data) => {
            this.setLists( data);
            return this.lists;
        })
    }

    @action
    saveList = (list: DictionaryList) => {
        var url = "global/list" + ((list.id && list.id <= 0) ? "/" + list.id :  "" );
        Gateway.post(url, this.currentList)
            .then((resp) => {
                this.reloadLists();
            })
    }

    @action
    saveItem = (item: DictionaryListItem) => {
        var url = "global/list/" + item.dictionaryListId + "/item" + ((item.id && item.id <= 0) ? "/" + item.id :  "" );
        Gateway.post(url, item)
            .then((resp) => {
                var id = this.currentList.id;
                this.reloadLists().then(() => {
                var c = _.find(this.lists, (f) => {
                    return f.id == id;
                })
                this.setCurrentList(c);
            });
            })
    }

}