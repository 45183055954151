import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { makeObservable, observable } from "mobx";
import { Component, useState, useEffect } from "react";
import { FC } from 'react';
import { observer } from 'mobx-react';


import { LeftNav } from "../components/LeftNav/LeftNav";
import TabPanel from '../components/TabPanel';
import DataGrid from "../components/DataGrid/DataGrid";
import { GridColDef, GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";
import { AdminBL } from "../api/AdminBL";
import { AddCircleOutline } from "@mui/icons-material";
import { DictionaryListModal } from "../components/Admin/DictionaryLists/DictionaryListModal";
import DictionaryList from "../entities/DictionaryList";
import { PropertyTablesModal } from "../components/Admin/PropertyTables/PropertyTables";
import Item from "../components/Item";
import { UserModal } from "../components/Admin/Users/UserModal";

interface IAdminProps {

}

const AdminPage: FC<IAdminProps> = (props: IAdminProps) => {
  const displayName = Admin.name;
  const [bl, setAdminBl] = useState(new AdminBL());
  const [isLeftNavExpanded, setIsLeftNavExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [areRolesLoading, setAreRolesLoading] = useState(true);
  const [isListsLoading, setIsListsLoading] = useState(true);
  const [isPropTablesLoading, setIsPropTablesLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [showPropTableModal, setShowPropTableModal] = useState(false);
  const [showDictListModal, setShowDictListModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [dictionaryLists, setDictionaryLists] = useState<DictionaryList[]>([]);
  const [propertyTables, setPropertyTables] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    bl.loadLists().then((data) => {
      setDictionaryLists(data);
      setIsListsLoading(false);
      bl.loadRoles().then((resp) => {
        setRoles(resp);
        setAreRolesLoading(true)
      })
    })

    bl.loadPropTables().then((resp) => {
      setPropertyTables(resp);
      setIsPropTablesLoading(false);
    })

    bl.loadUsers().then((resp) => {
      setUsers(resp);
      setIsUsersLoading(false);
    })

  }, []);

  const setTheActiveTab = (event: any, tab: number) => {
    setActiveTab(tab);
  }

  const saveList = () => {
    bl.saveList(bl.currentList);
    setDictionaryLists(bl.lists);
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const usersColumns: GridColDef[] = [
    {
      field: 'userID', headerName: 'User Id', flex: .25, type: 'number', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>{params.row.userID}</Grid>
        )
      }
    },
    {
      field: 'name', headerName: 'Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", align: "left", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>
            {<Button id={"admin.dashboard.cell.name." + params.row.ID} variant="text" onClick={() => {
              bl.setCurrentUser(params.row);
              setShowUserModal(true);
            }}>{`${params.row.firstName} ${params.row.lastName}`}</Button>}
          </Grid>
        )
      }
    },
    {
      field: 'userName', headerName: 'User Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>{params.row.userName}</Grid>
        )
      }
    },
    {
      field: 'isActive', headerName: 'Active', flex: 1, type: 'boolean', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>{params.row.isActive ? "True" : "False"}</Grid>
        )
      }
    }
  ]

  const dictListsColumns: GridColDef[] = [
    {
      field: 'name', headerName: 'Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>
            {<Button id={"provider.dashboard.cell.dictionarylist." + params.row.ID} variant="text" onClick={() => {
              bl.setCurrentList(params.row);
              setShowDictListModal(true);
            }}>{params.row.name}</Button>}
          </Grid>
        )
      }
    },
    {
      field: 'listKey', headerName: 'List Key', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>{params.row.listKey}</Grid>
        )
      }
    },
    {
      field: 'isActive', headerName: 'Active', flex: 1, type: 'boolean', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>{params.row.isActive ? "True" : "False"}</Grid>
        )
      }
    }
  ]

  const propertyTableColumns: GridColDef[] = [
    {
      field: 'tableName', headerName: 'Table Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>
            {<Button id={"admin.dashboard.cell.propertytables." + params.row.ID} variant="text" onClick={() => {
              bl.setCurrentPropertyTable(params.row);
              setShowPropTableModal(true);
            }}>{params.row.tableName}</Button>}
          </Grid>
        )
      }
    },
    {
      field: 'id', headerName: 'Id', flex: .25, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid>{params.row.id}</Grid>
        )
      }
    },
    {
      field: 'isActive', headerName: '', flex: .25, type: 'boolean', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
        return (
          <Grid xs container justifyContent={"center"}>
            <FormControl>
              <Item>
                <Grid className="formLabel" id="hb">{""}</Grid>
                <FormControlLabel
                  onChange={(event: React.SyntheticEvent<Element>, checked) => params.row.isActive == checked}
                  control={<Checkbox checked={params.row.isActive} />}
                  label="Is Active"
                />
              </Item>
            </FormControl>
          </Grid>
        )
      }
    }
  ]

  const DictListCustomFooter = () => {
    return (
      <GridFooterContainer>
        <IconButton id="admin.btn.addDictionaryList" onClick={() => {
          bl.createNewList();
          setShowDictListModal(true);
        }}><AddCircleOutline /></IconButton>
        <GridFooter sx={{
          border: 'none'
        }} />
      </GridFooterContainer>
    )
  }

  const toggleLeftNav = (isLeftNavExpanded: boolean) => {
    setIsLeftNavExpanded(!isLeftNavExpanded);
  }

  return (
    <Grid>
      <Grid xs={12} container>
        {/* <LeftNav onToggle={toggleLeftNav} defaultPage="ADMIN" /> */}
        <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')}>
          <Tabs value={activeTab} onChange={setTheActiveTab}>
            <Tab label="Dictionary Lists" sx={{ fontSize: '20px' }} {...a11yProps(0)} />
            <Tab label="Users" sx={{ fontSize: '20px' }} {...a11yProps(1)} />
            <Tab label="Property Tables" sx={{ fontSize: '20px' }} {...a11yProps(2)} />
            <Tab label="Other" sx={{ fontSize: '20px' }} {...a11yProps(3)} hidden={true} />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Grid>
              <DataGrid
                columns={dictListsColumns}
                data={bl.lists}
                loading={isListsLoading}
                customFooter={DictListCustomFooter}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid>
              <DataGrid
                columns={usersColumns}
                data={bl.users}
                loading={isUsersLoading && areRolesLoading}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Grid>
              <DataGrid
                columns={propertyTableColumns}
                data={bl.propertyTables}
                loading={isPropTablesLoading}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
      {
        showDictListModal === true &&
        <DictionaryListModal bl={bl}
          onSave={saveList}
          isOpen={showDictListModal}
          title={"Dictionary List"}
          onClose={() => {
            setShowDictListModal(false);
          }} />
      }
      {
        showUserModal === true &&
        <UserModal bl={bl}
          onSave={saveList}
          isOpen={showUserModal}
          title={"User"}
          onClose={() => {
            setShowUserModal(false);
          }} />
      }
      {
        showPropTableModal === true &&
        <PropertyTablesModal bl={bl}
          onSave={() => { }}
          isOpen={showPropTableModal}
          title={"Property Table"}
          onClose={() => {
            setShowPropTableModal(false);
          }} />
      }
    </Grid>
  );

}
export const Admin = observer(AdminPage);

