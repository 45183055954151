// Vendors
import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  TextField,
  Grid,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  styled,
  Popover
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

// Components
import Upload3DImgMultiDragDrop from "../UploadDragAndDrop/Upload3DImgMultiDragDrop";

// Entities
import PatientVisit from "../../entities/PatientVisit";
import { OcclusionModelType } from "../../entities/Enums";

// Stores
import { useMainStoreContext } from '../../stores/MainStore';

// API
import { observer } from "mobx-react";
import * as _ from 'lodash';

// Styling
import 'react-dropzone-uploader/dist/styles.css';
import '../../scss/FilesDragAndDrop.scss';
import CssTextField from "../CssTextField";
import CssSelectField from "../CssSelectField";
import 'react-calendar/dist/Calendar.css';

interface IProps {
  isIntake: boolean;
  visit: PatientVisit | undefined;
  isRework: boolean;
}

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
      background: transparent;
      transition: ${theme.transitions.create(['all'])};
      color: ${theme.colors.warning.light};
      border-radius: 50px;
  
      &:hover {
        background: transparent;
        color: ${theme.colors.warning.dark};
      }
  `
);

const ScanOcclusion: React.FC<IProps> = ({ isIntake, visit, isRework }) => {
  const { patient, machineBrand3DType, patientOcclusionImageType, patientOcclusionImages, molarRelationshipType, setPatientImages, setMachineBrand3DType, setOcclusion3dMachineBrandId } = useMainStoreContext().createPatientStore;
  const { getVisitId, visitOcclusion2dimages, visitOcclusion3dimages, imagesToDelete } = useMainStoreContext().visitRecordStore;
  const { occlusion2dimages, occlusion3dimages, setOcclusion3dImages, occlusionImageTypes, vivosId } = useMainStoreContext().aiReportOrder;
  const { lg_3Dscantype, lg_2dImages, set3DScanType, set2DImages } = useMainStoreContext().scanStore;
  const { rmrelation, lmrelation, staffmember, impressiondate, lg_machinetype,
    setRMRelation, setLMRelation, setStaffMember, setImpressionDate, setMachineType, setOcclusionModelTypeId, occlusionModelTypeId } = useMainStoreContext().occlusionStore;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [state2Dor3D, setState2Dor3D] = React.useState(0);
  const [selectedConstructionType, setConstructionTypeOption] = useState('');

  const handleConstructionTypeChange = (event) => {
    setConstructionTypeOption(event.target.value);
  };

  const handle3D = (notUsed1: string, notUsed2: string, option: number) => {
    setState2Dor3D(2);
  }
  const handle2D = (notUsed1: string, notUsed2: string, option: number) => {
    setState2Dor3D(1);
  }

  const [constructionBiteValue, setConstructionBiteValue] = React.useState('');
  const handleconstructionBiteChange = (event) => {
    setConstructionBiteValue(event.target.value);
  };

  const getModelAge = (impressiondate: Date): number => {
    const today = new Date();
    const date = new Date(impressiondate);
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const timeDifference = today.getTime() - date.getTime();
    const modelAgeInDays = Math.floor(timeDifference / millisecondsPerDay);

    return modelAgeInDays;
  };

  const deleteCallback = (itemOption: number) => {
    imagesToDelete.push(itemOption);
  }

  useEffect(() => {
    if (visit) {
      loadVisitValues(visit);
    }
    setOcclusionModelTypeId(occlusionModelTypeId === 0 ? OcclusionModelType.Digital : occlusionModelTypeId);
  }, []);

  const loadVisitValues = (visit): any => {
    if (visit.patientsImages) {
      setRMRelation(visit.patientsImages[0].occlusionRmolarRelationship ? visit.patientsImages[0].occlusionRmolarRelationship : 0);
      setLMRelation(visit.patientsImages[0].occlusionLmolarRelationship ? visit.patientsImages[0].occlusionLmolarRelationship : 0)
      setStaffMember(visit.patientsImages[0].occlusionMemberTookImpression ? visit.patientsImages[0].occlusionMemberTookImpression : '');
      setMachineType(visit.patientsImages[0].occlusion3dmachineBrandId ? visit.patientsImages[0].occlusion3dmachineBrandId : 0);
      setImpressionDate(visit.patientsImages[0].occlusionImpressionDate ? visit.patientsImages[0].occlusionImpressionDate : '');
    }
  };

  const handleModelTypeToggle = (
    event: React.MouseEvent<HTMLElement>,
    modelTypeSelect: number | null
  ) => {
    if (modelTypeSelect !== null) {
      setOcclusionModelTypeId(modelTypeSelect);
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid>
      <form id="scan">
        <Grid paddingTop={1}>
          <Typography variant="h3">Select Model Type</Typography>
          <Grid container direction="row" paddingTop={1} paddingLeft={2} >
            <ToggleButtonGroup exclusive onChange={handleModelTypeToggle} value={occlusionModelTypeId}>
              <ToggleButton value={OcclusionModelType.Digital} className="vivButton normalToggle"> Digital </ToggleButton>
              <ToggleButton value={OcclusionModelType.Physical} className="vivButton normalToggle"> Physical </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid container spacing={1} direction="row" paddingTop={1} paddingLeft={3} maxWidth={'800px'}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label='Staff Member Who Took the Impression'
                  required={false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setStaffMember(event.target.value);
                  }}
                  value={staffmember}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Impression Date"
                    value={impressiondate}
                    openTo="day"
                    disableFuture={true}
                    views={['year', 'month', 'day']}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        label="Impression Date"
                        fullWidth
                        required={true}
                        value={impressiondate}
                      />}
                    onChange={(value) => {
                      if (value !== null) {
                        setImpressionDate(value);
                      }
                    }}
                  />

                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="modelAge"
                label="Days Since Scan"
                fullWidth
                disabled
                value={impressiondate ? getModelAge(impressiondate) : ''} />
            </Grid>
            <Grid item>
              {getModelAge(impressiondate) >= 60 && <Typography variant="h5">
                *Due to the age of the impressions, it will be recommended that you retake them prior to submitting
                prescriptions for this patient. Some appliances may have a lesser age requirement.
              </Typography>}
            </Grid>
          </Grid>

          <Grid container direction="row" paddingTop={2} paddingLeft={2} marginLeft={1} >
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Typography variant="h4">Construction Bite Information</Typography>
                </Grid>
                <Grid style={{ color: 'orange', fontSize: '8px' }}>
                  <IconButtonWrapper
                    sx={{
                      ml: 0.5,
                      top: '-15px',
                    }}
                    onClick={handlePopoverOpen}
                  >
                    <HelpTwoToneIcon fontSize="small" />
                  </IconButtonWrapper>
                  <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{ style: { width: '500px', padding: '20px' } }}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h2'>Construction Bite Details</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1' padding={1}>
                          Here is some information to help you understand construction bite details.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid container spacing={1} direction="row" paddingTop={1} paddingLeft={3} maxWidth={'800px'}>
            <Grid item xs={12}>
              <FormControl fullWidth component="fieldset">
                <RadioGroup
                  aria-label="options"
                  name="radio-buttons-group"
                  value={constructionBiteValue}
                  onChange={handleconstructionBiteChange}
                ><Grid paddingLeft={3}>
                    {occlusionModelTypeId === OcclusionModelType.Digital && <FormControlLabel value="option1" control={<Radio />} label="Digital construction bite is embedded in my upper and lower arch STL files." />}
                    {occlusionModelTypeId === OcclusionModelType.Digital && <FormControlLabel value="option2" control={<Radio />} label="Digital construction bite is included as a seperate STL file." />}
                    <FormControlLabel value="option3" control={<Radio />} label="Physical contruction bite was taken and will be mailed in when applicable." />
                    <FormControlLabel value="option4" control={<Radio />} label="No construction bite included with these models." />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            {constructionBiteValue != "option4" &&
              <Grid item xs={4} marginLeft={10}>
                <TextField
                  id="select-dropdown"
                  select
                  label="Construction Bite Type"
                  value={selectedConstructionType}
                  onChange={handleConstructionTypeChange}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="option1">Natural</MenuItem>
                  <MenuItem value="option2">Nueromuscular</MenuItem>
                  <MenuItem value="option3">Phonetic</MenuItem>
                  <MenuItem value="option4">Protrusive</MenuItem>
                  <MenuItem value="option5">Other</MenuItem>
                </TextField>
              </Grid>
            }


          </Grid>
        </Grid>

        <Grid paddingTop={2}>
          <Divider />
          {(occlusionModelTypeId === OcclusionModelType.Digital)
            ?
            <Grid container direction="column">
              <Typography variant="h4" paddingLeft={3} paddingTop={1}>3D Digital Impressions</Typography>
              <Grid xs={4} paddingBottom={2} paddingLeft={3} paddingTop={2}>
                <Typography variant="subtitle1">Select the brand of Machine used to create models</Typography>
                <TextField
                  fullWidth
                  id="selectImage"
                  select
                  onChange={(event: any) => {
                    var foundedType = machineBrand3DType.filter(x => x.id.toString() === event.target.value.toString())[0];
                    setMachineType(foundedType);
                    setOcclusion3dMachineBrandId(foundedType.id);
                  }}
                  value={lg_machinetype.id ? lg_machinetype.id : lg_machinetype}
                >
                  {machineBrand3DType.map((currentVal) => (
                    <MenuItem value={currentVal.id}>{currentVal.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Typography variant="caption" paddingTop={1} paddingBottom={1} align="center">
                Please upload your 3D Digital impression STL files below.
              </Typography>
              <Grid container justifyContent={"center"} paddingTop={2}>
                <Upload3DImgMultiDragDrop
                  extImages={isIntake ? patientOcclusionImages : (visit) ? visitOcclusion3dimages : occlusion3dimages}
                  allowMultiple={true}
                  typeOptions={patientOcclusionImageType.filter(x => x.name.includes("3D"))}
                  visitId={getVisitId()}
                  parentCallback={handle3D}
                  vivosId={isIntake ? patient.vivosId : vivosId}
                  deleteCallback={deleteCallback}
                  isRework={isRework}
                />
              </Grid>
            </Grid>
            : <Grid></Grid>
          }
        </Grid>
      </form>
    </Grid>
  )
};

export default React.memo(observer(ScanOcclusion));

