import { Button, Divider, Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { observer } from "mobx-react-lite"
import { FC, useState } from "react"
import { ClaimStatus, ClaimType, InitialImpressionIssue } from "../../entities/Enums";
import { useMainStoreContext } from "../../stores/MainStore";
import moment from "moment";
import React from "react";

interface IGIProps {
    claimData: any;
}

export const InitialImpression: FC<IGIProps> = observer(({ claimData }) => {
    const store = useMainStoreContext().reworkClaimStore;

    const [otherIssueText, setOtherIssueText] = useState(claimData.initialImpressionOtherNotes ? claimData.initialImpressionOtherNotes : '');
    const [deniedReasonNotesText, setDeniedReasonNotesText] = useState(claimData.deniedReasonNotes ? claimData.deniedReasonNotes : '');
    const [claimTypeId, setClaimTypeId] = useState(claimData.claimTypeId ? claimData.claimTypeId : ClaimType.Pending);
    const [initialImpressionId, setInitialImpressionId] = useState(claimData.initialImpressionId ? claimData.initialImpressionId : null);
    const [hasAdverseEvents, setHasAdverseEvents] = useState(claimData.hasAdverseEvents);
    const [hasPatientComplaint, setHasPatientComplaint] = useState(claimData.hasPatientComplaint);
    const [initialImpressionByFullName, setInitialImpressionByFullName] = useState(claimData.initialImpressionByFirstName + ' ' + claimData.initialImpressionByLastName);
    const [initialImpressionOn, setInitialImpressionOn] = useState(claimData.initialImpressionOn ? moment(claimData.initialImpressionOn).format('yyyy-MM-DD') : '');
    const [isSendToLab, setIsSendToLab] = useState(claimData.initialImpressionBy ? true : false);
    const [isDenied, setIsDenied] = useState(claimData.claimStatusId ? claimData.claimStatusId === ClaimStatus.Cancelled : false);


    const handleClaimTypeToggle = (
        event: React.MouseEvent<HTMLElement>,
        claimTypeSelect: number | null
    ) => {
        if (claimTypeSelect !== null) {
            setClaimTypeId(claimTypeSelect);
        }
    };

    const handleInitialImpressionToggle = (
        event: React.MouseEvent<HTMLElement>,
        initialImpressionSelect: number | null
    ) => {
        if (initialImpressionSelect !== null) {
            setInitialImpressionId(initialImpressionSelect);
        }
    };

    const handleHasAdverseEventsToggle = (
        event: React.MouseEvent<HTMLElement>,
        hasAdverseEventsSelect: boolean | null
    ) => {
        if (hasAdverseEventsSelect !== null) {
            setHasAdverseEvents(hasAdverseEventsSelect);
        }
    };

    const handleHasPatientComplaintToggle = (
        event: React.MouseEvent<HTMLElement>,
        hasPatientComplaintSelect: boolean | null
    ) => {
        if (hasPatientComplaintSelect !== null) {
            setHasPatientComplaint(hasPatientComplaintSelect);
        }
    };

    const updateClaim = async () => {

        var temp = "";
        switch (claimTypeId) {
            case ClaimType.Repair:
                temp = store.claim.rxId.replace("-C", "-R");
                break;

            case ClaimType.Warranty:
                temp = store.claim.rxId.replace("-C", "-W");
                break;

            case ClaimType["Warranty & Repair"]:
                temp = store.claim.rxId.replace("-C", "-WR");
                break;

            default:
                break;
        }

        let updateClaim = {
            ...store.claim,
            claimType: claimTypeId,
            initialImpression: initialImpressionId,
            initialImpressionOtherNotes: initialImpressionId === InitialImpressionIssue.Other ? otherIssueText : '',
            hasAdverseEvents: hasAdverseEvents,
            hasPatientComplaint: hasPatientComplaint,
            rxId: temp
        };
        var claims: any = await store.updateClaimChanges(updateClaim);
        claimData = claims.value.find(x => x.id === store.claimId);
    }

    const sendClaimToLab = async () => {
        setIsSendToLab(true);
        let updateClaim = {
            ...store.claim,
            initialImpressionBy: store.userID,
            initialImpressionOn: new Date()
        };
        var claims: any = await store.updateClaimChanges(updateClaim);
        claimData = claims.value.find(x => x.id === store.claimId);
        setInitialImpressionByFullName(claimData.initialImpressionByFirstName + ' ' + claimData.initialImpressionByLastName);
        setInitialImpressionOn(moment(claimData.initialImpressionOn).format('yyyy-MM-DD'));
        store.loadPage();

    }

    const denyClaim = async () => {
        setIsDenied(true);
        let updateClaim = {
            ...store.claim,
            deniedReasonNotes: deniedReasonNotesText,
            claimStatus: ClaimStatus.Cancelled
        };
        var claims: any = await store.updateClaimChanges(updateClaim);
        claimData = claims.value.find(x => x.id === store.claimId);
        store.setClaimStatus(ClaimStatus.Cancelled);
        store.loadPage();
    }


    return (
        <Grid xs={12} container direction={'row'} className={store.isExternalLab ? "principal-claim-container-disabled" : "principal-claim-container"}>
            <Grid xs={12} className="claim-save-button">
                {!store.isExternalLab && <Button className="vivButton normal" disabled={isDenied} onClick={() => { updateClaim() }}> Save Changes </Button>}
            </Grid>
            <Divider flexItem={true} orientation="horizontal" />
            <Grid xs={7} direction={'column'}>
                <Grid className={claimData.claimTypeId !== ClaimType.Pending ? "box-claim-container-disabled" : "box-claim-container"}>
                    <Grid className="claim-data-title">Classify Claim</Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <ToggleButtonGroup exclusive onChange={handleClaimTypeToggle} value={claimTypeId}>
                            <ToggleButton value={ClaimType.Warranty} className="vivButton normalToggle"> Warranty </ToggleButton>
                            <ToggleButton value={ClaimType.Repair} className="vivButton normalToggle"> Repair </ToggleButton>
                            <ToggleButton value={ClaimType["Warranty & Repair"]} className="vivButton normalToggle"> Warranty & Repair </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    {((claimTypeId === ClaimType.Warranty) || (claimTypeId === ClaimType["Warranty & Repair"])) && <Grid>
                        <Grid className="claim-data-title">Make an initial impression of the claim</Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <ToggleButtonGroup exclusive onChange={handleInitialImpressionToggle} value={initialImpressionId}>
                                <ToggleButton className="vivButton normalToggle" value={InitialImpressionIssue["Manufacturing Issue"]}> Manufacturing Issue </ToggleButton>
                                <ToggleButton className="vivButton normalToggle" value={InitialImpressionIssue["Material Issue"]}> Material Issue </ToggleButton>
                                <ToggleButton className="vivButton normalToggle" value={InitialImpressionIssue["Provider Issue"]}> Provider Issue </ToggleButton>
                                <ToggleButton className="vivButton normalToggle" value={InitialImpressionIssue["Patient Issue"]}> Patient Issue </ToggleButton>
                                <ToggleButton className="vivButton normalToggle" value={InitialImpressionIssue.Other}> Other Issue </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {(initialImpressionId === InitialImpressionIssue.Other) && <Grid xs={12} className="claim-info">
                            <Grid>If other is selected please describe</Grid>
                            <TextField
                                className="claim-textarea"
                                onChange={(e) => { setOtherIssueText(e.target.value) }}
                                style={{ width: "100%" }}
                                minRows={4}
                                maxRows={4}
                                value={otherIssueText}
                                margin="normal"
                            />
                        </Grid>}
                        <Grid container direction={'column'}>
                            <Grid className="claim-data-title">Were there any adverse events?</Grid>
                            <Grid container direction={'row'} className="claim-info">
                                <ToggleButtonGroup exclusive onChange={handleHasAdverseEventsToggle} value={hasAdverseEvents}>
                                    <ToggleButton className="vivButton normalToggle" value={true}> Yes </ToggleButton>
                                    <ToggleButton className="vivButton normalToggle" value={false}> No </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container direction={'column'}>
                            <Grid className="claim-data-title">Was there a patient complaint involved?</Grid>
                            <Grid container direction={'row'} className="claim-info">
                                <ToggleButtonGroup exclusive onChange={handleHasPatientComplaintToggle} value={hasPatientComplaint}>
                                    <ToggleButton className="vivButton normalToggle" value={true}> Yes </ToggleButton>
                                    <ToggleButton className="vivButton normalToggle" value={false}> No </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid xs={5} direction={'column'}>
                <Grid className="box-claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={7}>
                            <Grid>Claim ID:</Grid>
                        </Grid>
                        <Grid className="claim-data">{claimData.id}</Grid>
                    </Grid>

                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={7}>
                            <Grid>Initial Impression Recorded By:</Grid>
                        </Grid>
                        <Grid className="claim-data">{initialImpressionByFullName}</Grid>
                    </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={7}>
                            <Grid justifyContent="flex-end">Date:</Grid>
                        </Grid>
                        <Grid className="claim-data">{initialImpressionOn}</Grid>
                    </Grid>
                </Grid>
                <Grid container direction={'row'} justifyContent="flex-end">
                    <Button className="vivButton normal" disabled={isSendToLab} onClick={() => { sendClaimToLab() }}> Send Claim to Lab for Investigation </Button>
                </Grid>
                <Grid className="box-claim-container">
                    <Grid className="claim-info">Denied Reason</Grid>
                    <TextField
                        className="claim-textarea"
                        onChange={(e) => { setDeniedReasonNotesText(e.target.value) }}
                        style={{ width: "100%" }}
                        minRows={4}
                        maxRows={4}
                        value={deniedReasonNotesText}
                        margin="normal"
                        disabled={isDenied}
                    />
                </Grid>
                <Grid container direction={'row'} justifyContent="flex-end">
                    <Button className="vivButton normal" disabled={deniedReasonNotesText === '' || isDenied} onClick={() => { denyClaim() }}> Deny Claim and send reason to Provider </Button>
                </Grid>
            </Grid>
        </Grid>)
})