import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import ThemeProvider from './theme/ThemeProvider';
import { SidebarProvider } from './contexts/SidebarContext';


LicenseInfo.setLicenseKey('18e486b7410d1a8f62c2661ef65dcdc8Tz00NTYxNCxFPTE2ODY3NzUzNTI4NDMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
ReactDOM.render(
  <React.StrictMode>
    <SidebarProvider>

      <BrowserRouter>

        <Routes>
          <Route path="*" element={<ThemeProvider><App /></ThemeProvider>} />
        </Routes>
      </BrowserRouter>

    </SidebarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
