// Vendor
import React from 'react';
import { FC, useEffect, useState } from 'react';
import { Button, Grid, IconButton, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Components
import FilesIcon from '../../../imgs/png/Miscellaneous-Files.png';
import BoxUploadWrapper from '../../../components/UI/UploadWrappers/BoxUploadWrapper';

// Stores
import { useMainStoreContext } from '../../../stores/MainStore';

// Entities

// API
import { observer } from 'mobx-react';
import { Gateway } from '../../../api/Gateway';
import { FileUploader } from 'react-drag-drop-files';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { NONAME } from 'dns';



interface IGIProps {
    submissionId: number;
}
export interface Reports {
    id?: number;
    submissionId?: number;
    version?: number;
    link?: string;
    createdOn?: Date;
    createdBy?: number;
    modifiedOn?: Date;
    modifiedBy?: number;
    isActive?: boolean;
    submission?: null;
}
interface SubmissionData {
    id: number;
    patientId: number;
    visitRecordId: number;
    requestorId: number;
    providerId: number;
    statusId: number;
    technicianId: number;
    reportyTypeId: number;
    requestDate: Date;
    caseNumber: number;
    daysInQueue: number;
    isActive: boolean;
    //addOns: AddOn[],
    //processedImages : ProcessedImages[],
    reports: Reports[];
}
interface Submission extends SubmissionData { }
const NoDocument = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item className="noDocument" sx={{ margintop: '50' }}>
                <br />
                AI Report has not yet been generated
            </Grid>
        </Grid>
    );
};
const MyLoadingRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || '';
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item>Loading AiReport...</Grid>
        </Grid>
    );
};
export const AIReportDownloadUpload: FC<IGIProps> = observer(({ submissionId }) => {
    const [fileContent, setFile] = useState<File>();
    const { patient, reportData, userID } = useMainStoreContext().aiReportEntryStore;
    const [uri, setUri] = useState('');
    const [reports, setReports] = useState<any[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    useEffect(() => {
        setReports(reportData?.reports);
        let lastReport: any = {};
        if ((reportData as unknown as Submission).reports != null) {
            lastReport = (reportData as unknown as Submission).reports[
                (reportData as unknown as Submission).reports.length - 1
            ];
        }
        if (lastReport != null) {
            let filename = lastReport.link;
            setUri(
                decodeURI(
                    `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${patient.vivosId
                    }/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${lastReport!.submissionId}/${filename}`,
                ),
            );
        }
    }, [reportData?.reports]);
    const handleChange = file => {
        setFile(file);
    };
    const uploadFile = () => {
        if (fileContent) {
            let reader = new FileReader();
            reader.readAsDataURL(fileContent);
            reader.onloadend = () => {
                let fileUpload = {
                    fileName: fileContent.name,
                    dataBody: (reader.result as string).split(',')[1],
                };
                setIsUploading(true);
                Gateway.post(`/aireports/uploadreport/${submissionId}/${fileContent.name}`, fileUpload).then(resp => {
                    setUri(resp.toString());
                    setReports(reportData?.reports);
                    setIsUploading(false);
                    setIsUploaded(true);
                });
            };
        }
    };
    const onDownload = () => {
        const link = document.createElement('a');
        link.download = uri;
        link.href = uri;
        link.click();
    };
    return (
        <Grid container className="modalPageHeight autoScroll" >
            <Grid xs={9} item>
                <Grid container justifyContent={'center'} >
                    {reports?.length > 0 ? (
                        <DocViewer
                            pluginRenderers={DocViewerRenderers}
                            config={{
                                loadingRenderer: {
                                    overrideComponent: MyLoadingRenderer,
                                },
                                noRenderer: {
                                    overrideComponent: MyLoadingRenderer,
                                },
                            }}
                            documents={[
                                {
                                    uri: uri,
                                },
                            ]}
                        />
                    ) : (
                        <NoDocument />
                    )}
                </Grid>
            </Grid>
            <Grid item marginBottom={3} xs={3} paddingTop={2}>
                {reports?.length > 0 && (
                    <Grid container justifyContent={'center'}>
                        <FileUploader
                            hoverTitle="Upload Report"
                            label="Upload or drop your Ai Report"
                            handleChange={handleChange}
                            name="file"
                            types={['PPT', 'PPTX']}
                            id="ppt_doc"
                            children={
                                <BoxUploadWrapper>
                                    <Grid container direction="column" alignItems={'center'} width={'250px'}>
                                        <IconButton>
                                            <img src={FilesIcon} className="uploadImg" />
                                        </IconButton>
                                        {fileContent ? (
                                            <Grid item>{fileContent.name}</Grid>
                                        ) : (
                                            <>
                                                <Typography>Drag & Drop or</Typography>
                                                <Typography>Click to Select File(s)</Typography>
                                            </>
                                        )}
                                    </Grid>
                                </BoxUploadWrapper>
                            }
                        />
                        <Grid container justifyContent={'center'} spacing={2} paddingTop={2}>
                            <Grid item>
                                <LoadingButton variant='contained' onClick={uploadFile} loading={isUploading}>
                                    Upload AI Report
                                </LoadingButton>
                            </Grid>
                            {reports?.length > 0 && (
                                <Grid item>
                                    <Button variant='contained' color='info' onClick={onDownload}>
                                        Download
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );

});