import TeethChartTooth from "./TeethChartTooth";

interface ITeethChart {
    id: number,
    patientId: number,
    notes: string,
    teethChartTeeth: TeethChartTooth[]
}

interface TeethChart extends ITeethChart {}

class TeethChart{
    constructor({id,patientId,notes,teethChartTeeth}: ITeethChart){
        this.id = id;
        this.patientId = patientId;
        this.notes = notes;
        this.teethChartTeeth = teethChartTeeth;
    }
}

export default TeethChart;