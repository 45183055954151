import { Grid } from "@mui/material";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component, RefObject } from "react";
import '../../scss/_DashboardInfoBox.scss';

interface DashboardProps {
    title: string;
    value: number;
    onFilter: any;
    filter: number;
    currentFilter: number;
}
@observer
export class DashboardInfoBox extends Component<DashboardProps> {
    
    constructor(props: any) {
        super(props);
    }

    render() {
        return(
            <Grid container className="dashInfoBox " onClick={this.toggleSelected}>
                <Grid className={"dashHeader" + (((this.props.filter === this.props.currentFilter)) ? " selected" : "")}>
                        {this.props.title}
                </Grid>                
                <Grid className="dashBody">
                        {this.props.value}
                </Grid>
            </Grid>
        )
    }

    @action
    toggleSelected = () => {
         this.props.onFilter(!(this.props.filter === this.props.currentFilter));
    }
}