
import React from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    Avatar,
    MenuList,
    MenuItem,
    ListItemText,
    useTheme
} from "@mui/material";
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';

const MenuListWrapperRounded = styled(MenuList)(
    ({ theme }) => `
      & .MuiMenuItem-root {
          border-radius: 50px;
          padding: ${theme.spacing(1, 1, 1, 2.5)};
          margin-bottom: 2px;
          position: relative;
          color: ${theme.colors.alpha.black[70]};
  
          &.Mui-selected,
          &:hover,
          &.MuiButtonBase-root:active {
              background: ${theme.colors.alpha.black[10]};
              color: ${theme.colors.alpha.black[100]};
          }
  
          &:last-child {
              margin-bottom: 0;
          }
        }
    `
);


function VidaSleepCard() {
    const theme = useTheme();
    return (
        <Card>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
            >
                <Box>
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                        py={5}
                        px={2}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                mx: 'auto',
                                mb: 2,
                                width: 'auto',
                                height: 100,
                                '& img': {
                                    objectFit: 'contain'
                                }
                            }}
                            src="/logos/appliances/.png"
                        />
                        <Typography
                            sx={{
                                pt: 1
                            }}
                            variant="subtitle2"
                            textAlign="center"
                        >
                            The Vivos Vida Sleep® is an FDA 510k cleared Class II device indicated for the
                            amelioration of clenching and bruxing associated with TMD and is to be used to
                            aid in the relief of symptoms of TMD/TMJ and sleep disordered breathing.
                        </Typography>
                    </Box>
                    <MenuListWrapperRounded
                        disablePadding
                        sx={{
                            mb: 3,
                            mx: 3
                        }}
                    >
                        <MenuItem selected>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={'What is the Vivos Vida Sleep Appliance?'}
                            />
                            <ChevronRightTwoToneIcon
                                sx={{
                                    color: `${theme.colors.alpha.black[30]}`,
                                    opacity: 0.8
                                }}
                            />
                        </MenuItem>
                    </MenuListWrapperRounded>
                </Box>
            </Box>
        </Card>
    )
}

export default VidaSleepCard