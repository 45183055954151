import { FormControl, Grid, InputLabel, MenuItem } from '@mui/material';

import { GridColDef } from '@mui/x-data-grid-pro';

import { useMainStoreContext } from '../../../../stores/MainStore';
import DataGrid from '../../../DataGrid/DataGrid';
import CssSelectField from '../../../CssSelectField';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import _ from 'lodash';

export const TreatmentPlantToDiagnosis = observer(() => {
    const {
        treatmentPlanToDiagnosis,
        treatmentPlan,
        primaryData,
        setNewTreatmentPlanToDiagnosis,
        primaryBitwise,
        setNewTreatmentPlan,
    } = useMainStoreContext().patientTreatmentStore;

    const getTreatmentPlan = useCallback(() => {
        let conditions = primaryData;

        let treatmentPlan: any = [];

        _.forEach(conditions, a => {
            let value = Number(a.value);

            if (value > 0 && (primaryBitwise & value) === value) {
                treatmentPlan.push({
                    id: Number(a.value),
                    name: a.name,
                    planId: a.id,
                });
            }
        });

        setNewTreatmentPlan(treatmentPlan);
    }, [primaryBitwise, primaryData, setNewTreatmentPlan]);

    useEffect(() => {
        getTreatmentPlan();
    }, [getTreatmentPlan]);

    const getTreatmentOptionIds = useCallback(() => {
        let conditions = primaryData;

        _.forEach(treatmentPlanToDiagnosis, a => {
            if (a.id <= 0) {
                a.id = 0;
            }

            let tOptions = 0;

            _.forEach(a.treatmentPlan, b => {
                let condition = _.find(conditions, c => {
                    return c.id === b;
                });
                tOptions = tOptions | (condition ? Number(condition.value) : 0);
            });
            a.treatmentOptionIds = tOptions;
        });

        return treatmentPlanToDiagnosis;
    }, [primaryData, treatmentPlanToDiagnosis]);

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Diagnosed Condition', width: 300 },
        {
            field: 'treatmentPlan',
            headerName: 'Treatment Plan',
            width: 300,
            valueOptions: treatmentPlan.map(item => item.name),
            sortComparator: (v1, v2, param1, param2) => {
                return v1.value.localeCompare(v2.value);
            },
            valueFormatter(params) {
                return params.value;
            },

            renderCell: (params: any) => {
                return (
                    <>
                        {treatmentPlan.length !== 0 && (
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="select.Treatment.Plan">Select Treatment Plan</InputLabel>

                                <CssSelectField
                                    labelId="select.Treatment.Plan"
                                    id="treatmentPlan"
                                    value={params.row.treatmentPlan}
                                    multiple
                                    name={params.row.name}
                                    onChange={(e: any) => {
                                        getTreatmentOptionIds();
                                        setNewTreatmentPlanToDiagnosis(
                                            treatmentPlanToDiagnosis.map((item: any) => {
                                                if (item.name === e.target.name) {
                                                    item.treatmentPlan = e.target.value;
                                                }
                                                return item;
                                            }),
                                        );
                                        params.row.treatmentPlan = e.target.value;
                                    }}
                                >
                                    {treatmentPlan.map(item => (
                                        <MenuItem key={item.id} value={item.planId}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </CssSelectField>
                            </FormControl>
                        )}
                    </>
                );
            },
        },
        { field: 'treatmentObjectiveName', headerName: 'Treatment Objective', width: 300 },
        { field: 'details', headerName: 'Details', width: 300 },
    ];

    return (
        <Grid>
            <DataGrid data={treatmentPlanToDiagnosis} columns={columns} />
        </Grid>
    );
});
