import { Button, Divider, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useMainStoreContext } from '../../../../stores/MainStore';
import moment from "moment";
import * as _ from "lodash";

import { EstimatedTime } from './EstimatedTreatment';

interface IEstimatedTreatmentTime {
    patient?: any;
}

const defaultValues = {
    startDate: '',
    endDate: '',
    therapyOptions: 0,
};

export const EstimatedTreatmentTime: FC<IEstimatedTreatmentTime> = observer(({ patient }) => {
    const { addEstimatedTreatmentTime, getEstimatedTreatmentTIme, removeEstimatedTreatmentTime } =
        useMainStoreContext().patientTreatmentStore;

    const [estimatedElems, setEstimatedElems] = useState<any>([]);
    const [timeInMonths, setTimeInMonths] = useState<any>(0);

    const deleteEstimatedTime = (index: number) => {
        removeEstimatedTreatmentTime(index);
        generateEstimatedTime();
        getEstimatedTreatmentTIme();
    };

    const handleTimeUpdate = (start: any, end: any, index: any) => {
        var time = 0;
        if (start && end) {
            _.forEach(getEstimatedTreatmentTIme(), (e) => {
                time += moment(end.toDate()).diff(start.toDate(), "days");
            });
            var aggregateTime = (time/30).toString();
            setTimeInMonths(parseFloat(aggregateTime).toFixed(2));
        }
    }

    const generateEstimatedTime = (): JSX.Element[] => {
        let estimated: any = getEstimatedTreatmentTIme().map((item, index) => {
            return <EstimatedTime key={index} index={index} onUpdate={handleTimeUpdate} onDelete={deleteEstimatedTime} />;
        });
        setEstimatedElems(estimated);
        return estimated;
    };

    return (
        <Grid style={{ margin: "0px 25px" }}>
            <Grid>
                <Grid>{estimatedElems}</Grid>
                <Grid>
                    <Button
                        onClick={() => {
                            addEstimatedTreatmentTime(defaultValues);
                            generateEstimatedTime();
                        }}
                        className="vivButton large invert"
                    >
                        (+) Add Treatment Option
                    </Button>
                </Grid>
            </Grid>

            <Grid>
                <Grid style={{ marginTop: "25px" }}>
                    <span>Estimated Treatment Time (in months): {timeInMonths}</span>
                </Grid>
            </Grid>

            {/* <Divider /> */}

            <Grid>
                {/* <Grid>Phase 2 treatement options</Grid> */}
            </Grid>
        </Grid>
    );
});
