import { Button, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react-lite"
import { FC, useState } from "react"
import { useMainStoreContext } from "../../stores/MainStore";
import moment from "moment";

import UploadDragDrop from "../UploadDragAndDrop/UploadDragDrop";


interface IGIProps {
    claimData: any;
}

export const ManagementReview: FC<IGIProps> = observer(({ claimData }) => {
    const [claimApprovedByFullName, setClaimApprovedByFullName] = useState(claimData.claimApprovedByFirstName + ' ' + claimData.claimApprovedByLastName);
    const [claimApprovedOn, setClaimApprovedOn] = useState(claimData.claimApprovedOn ? moment(claimData.claimApprovedOn).format('yyyy-MM-DD') : '');
    const [isApproved, setIsApproved] = useState(claimData.claimApprovedBy ? true : false);
    const store = useMainStoreContext().reworkClaimStore;

    const updateClaimDocuments = () => {
        store.removeFiles(claimData.id);
        if (store.claimFiles.length > 0) {
            store.uploadFiles(claimData.id);
            store.loadPage();
            claimData = store.claimData.find(x => x.id === claimData.id);
        }
    }

    const approveClaim = async () => {
        setIsApproved(true);
        let updateClaim = {
            ...store.claim,
            claimApprovedBy: store.userID,
            claimApprovedOn: new Date()
        };
        
        var claims: any = await store.updateClaimChanges(updateClaim);
        claimData = claims.find(x => x.id === store.claimId);
        store.loadReworkDocuments(claimData.id);
        setClaimApprovedByFullName(claimData.claimApprovedByFirstName + ' ' + claimData.claimApprovedByLastName);
        setClaimApprovedOn(moment(claimData.claimApprovedOn).format('yyyy-MM-DD'));
    }

    return (
        <Grid xs={12} container direction={'row'} className="principal-claim-container">
            <Grid xs={12} className="claim-save-button">
                <Button className="vivButton normal" onClick={() => { updateClaimDocuments() }}> Save Changes </Button>
            </Grid>
            <Divider flexItem={true} orientation="horizontal" />
            <Grid xs={7} direction={'column'}>
                <Grid className="box-claim-container">
                    <UploadDragDrop itemsToUpload={store.claimFiles}
                        type="multi" parentCallback={() => { }}
                        typeOptions={store.claimReworkDocumentTypes}
                        vivosId={claimData.vivosId}
                        containerId={claimData.id}
                        parentContainerId={claimData.prescriptionId} />
                </Grid>
            </Grid>
            <Grid xs={5} direction={'column'}>
                <Grid className="box-claim-container">
                    <Grid className="claim-data-title">Overview</Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={12}>
                            <Grid>Shipped Date:</Grid>
                        </Grid>
                        <Grid className="claim-data-title">{moment(claimData.shippedOn).format('yyyy-MM-DD')}</Grid>
                    </Grid>
                    <br />
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={12}>
                            <Grid>I have reviewed all information regarding this complaint for accuracy and completion.</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} justifyContent="flex-end">
                        <Button className="vivButton normal" disabled={isApproved} onClick={() => { approveClaim(); }}> Approve + Sign </Button>
                    </Grid>
                </Grid>
                <Grid className="box-claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={7}>
                            <Grid>Claim ID:</Grid>
                        </Grid>
                        <Grid className="claim-data">{claimData.id}</Grid>
                    </Grid>

                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={7}>
                            <Grid>Approved By:</Grid>
                        </Grid>
                        <Grid className="claim-data">{claimApprovedByFullName}</Grid>
                    </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={7}>
                            <Grid justifyContent="flex-end">Date:</Grid>
                        </Grid>
                        <Grid className="claim-data">{claimApprovedOn}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
})