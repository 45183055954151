import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled, Box, } from '@mui/material';

const UploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);

interface BoxUploadWrapperProps {
    children?: ReactNode;
}

const BoxUploadWrapper: FC<BoxUploadWrapperProps> = ({ children }) => {
    return (
        <>
            <UploadWrapper>{children}</UploadWrapper >
        </>
    );
};

BoxUploadWrapper.propTypes = {
    children: PropTypes.node.isRequired
};

export default BoxUploadWrapper;
