import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { Component, createRef } from "react";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBox from '@material-ui/icons/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import "../scss/_FilterOption.scss";
import { Grid, RadioGroup } from "@mui/material";

interface FilterOptionProps {
    filterName: string;
    radioOptions: JSX.Element[];
    checked: boolean;
    status: number;
    defaultValue: string;
    onCheck(name: string, checked: boolean): any;
    onChange(name: string, checked: boolean, value: any): any;
}
@observer
export class FilterOption extends Component<FilterOptionProps> {
    @observable checked: boolean = false;
    @observable status: number = 0;
    toggleOptionSelected: boolean = false;
    editFilterOptions: any;

    constructor(props: any) {
        super(props);
        makeObservable(this);
        this.editFilterOptions = createRef();
    }
    componentDidMount() {
        this.toggleOptionSelected = false;
        this.checked = this.props.checked;
        this.status = this.props.status;
    }

    render() {
        return (
            <Grid className="filterOption" >
                <Grid className={"selectBox" + (this.checked === true ? " selected" : "")}>
                    <Checkbox onClick={this.onCheck} checked={this.checked} className="cbx" />
                </Grid>
                <Grid className="filterName">{this.props.filterName}</Grid>
                <Grid className={"filterOptions" + (this.checked === true ? "" : " disabled")} ref={this.editFilterOptions}>
                    <RadioGroup onChange={this.onChange} defaultValue={this.props.defaultValue} value={this.status}>{this.props.radioOptions}</RadioGroup></Grid>
            </Grid>
        )
    }

    @action
    onCheck = (event: any) => {
        var val = event.target.checked;
        this.checked = val;
        this.props.onCheck(this.props.filterName, val);
    }

    @action
    onChange = (event: any) => {
        var val = event.target.value;
        this.props.onChange(this.props.filterName, this.checked, val);
    }
}