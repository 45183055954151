import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useMainStoreContext } from '../../stores/MainStore';

interface IProps {
    
}
export const AiReportOrdercomplete: FC<IProps> = observer(({  }) => {
    const {
        submission
    } = useMainStoreContext().aiReportOrder;
    return (
        <>
            <Box padding={2}>
                <Typography variant="h6">AI REPORT ORDER SUBMISSION SUCCESSFUL</Typography>
                <Typography variant="subtitle1" color="red">
                    ORDER # -- {submission.id}
                </Typography>
            </Box>
        </>
    );
})
