import { Button, Divider, Grid, MenuItem } from "@mui/material";
import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react"
import { GlobalUtils } from "../../api/GlobalUtils";
import { useMainStoreContext } from "../../stores/MainStore";
import CssSelectField from "../CssSelectField";
import PatientIcon from '../../imgs/png/Patient.png';
import moment from "moment";
import { ClaimStatus, ClaimType } from "../../entities/Enums";

interface IGIProps {
    claimViewData: any;
    patientName: string;
}


export const ClaimHeader: FC<IGIProps> = observer(({ claimViewData, patientName }) => {
    const [claimStatusId, setClaimStatusId] = useState(claimViewData.claimStatusId ? claimViewData.claimStatusId : 0);
    const [prodStatusId, setProdStatusId] = useState(claimViewData.productionStatusId ? claimViewData.productionStatusId : 0);

    const {
        setPatientId,
        loadPatient,
        patient
    } = useMainStoreContext().labCaseReviewStore;

    const store = useMainStoreContext().reworkClaimStore;

    useEffect(() => {
        store.setClaimStatus(claimStatusId);
        setPatientId(claimViewData.patientId);
        loadPatient();
        store.loadClaimStatusValues();
        store.loadProductionStatusValues();
        store.loadClaimReviewDocumentTypes();
        store.loadReworkRequestValues(claimViewData);
        store.loadReworkDocuments(claimViewData.id);
    }, []);

    return <Grid xs={12}>
        <Grid xs={12} container className="header" direction={'row'}>
            <Grid xs={1.3} sx={{ marginRight: '20px', paddingBottom: '25px' }}>
                {patient.profileImageUrl && (
                    <img className="thumbnail profilePhoto" src={patient?.profileImageUrl ? GlobalUtils.getPatientProfileUrl(patient?.profileImageUrl, patient?.vivosId as string) : ""}></img>
                )}
                {!patient.profileImageUrl && <img className="thumbnail profilePhoto" src={PatientIcon}></img>}
            </Grid>
            <Grid xs={10.5} container direction={'row'}>
                <Divider flexItem={true} orientation="vertical" />
                <Grid container className="nameSection ">
                    <Grid container direction={'column'} xs={4}>
                        <Grid container direction={'row'} className="name">
                            <Grid>{patientName}</Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={6} container direction="row" className="center-box" >
                        <Grid direction="column" className="date-box">
                            <Grid className="claim-info">Date Claim Submitted</Grid>
                            <Grid fontSize="x-large">{claimViewData.dateSubmitted && moment(claimViewData.dateSubmitted).format('MM/DD/yyyy')}</Grid>
                        </Grid>
                        <Grid direction="column" margin="15px">
                            <Grid className="claim-info">Claim Status</Grid>
                            <CssSelectField
                                className="DateBox"
                                labelId="claimStatus"
                                label="Claim Status"
                                font-size="10px/13px"
                                size="small"
                                value={store.claimStatus}
                                onChange={event => {
                                        store.setClaimStatus(event.target.value as number);
                                        store.handleClaimStatusChange(claimViewData, event.target.value as number)    
                                }}
                            >
                                {
                                    store.claimStatusValues.map((cs) =>
                                        (<MenuItem key={cs.id} value={cs.id} disabled={cs.id === ClaimStatus.Approved ? store.claim.claimType === ClaimType.Pending : false}>{cs.name}</MenuItem>))
                                }
                            </CssSelectField>
                            <Grid className="claim-info">Production Status</Grid>
                            <CssSelectField
                                className="DateBox"
                                labelId="prodStatus"
                                label="Production Status"
                                font-size="10px/13px"
                                size="small"
                                value={prodStatusId}
                                onChange={event => {
                                    setProdStatusId(event.target.value as number);
                                    store.handleProductionStatusChange(claimViewData, event.target.value as number)
                                }}
                            >
                                {
                                    store.productionStatusValues.map((ps) =>
                                        (<MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>))
                                }
                            </CssSelectField>
                        </Grid>

                    </Grid>
                    <Grid xs={2} container direction="column" className=" ">
                        <Button
                            className="vivButton normal invert">
                            Not Used
                        </Button>
                        <Button className="vivButton normal invert">
                            Create Action Item
                        </Button>
                        <Button className="vivButton normal invert">
                            Generate CCR Form
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
})