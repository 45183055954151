
import { Component, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, CircularProgress, Grid } from "@mui/material";

import ProviderPatient from "../../../entities/ProviderPatient";
import PatientEditBL from "../PatientEditBL";
import Appointments from "../../Appointments/Appointments";
import { useMainStoreContext } from "../../../stores/MainStore";
import { Gateway } from "../../../api/Gateway";
import React from "react";
import ModalContainer from "../../ModalContainer";
import { makeStyles } from '@material-ui/core/styles';
import MyoCorrectTherapist from "../../../entities/MyoCorrectTherapist";

interface IGIProps {
    patient: ProviderPatient;
}

const styles = {
    buttons: {
        backgroundColor: '#324c9d',
        color: '#fff',
        marginTop: '5px',
        marginBottom: '5px',
        borderRadius: '18px',
    },
}

const Comp: React.FC<IGIProps> =
    ({ patient }) => {

        const [editBl] = React.useState<PatientEditBL>(new PatientEditBL());
        const { advocates, loadAdvocates, setCurrentRow, advisors } = useMainStoreContext().myoCorrectStore;
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [sessionsLoaded, setSessionsLoaded] = React.useState(false);
        const [therapists, setTherapists] = React.useState<MyoCorrectTherapist[]>([]);
        const { setToggleShowMyoCorrectModal, setToggleFirstSession } = useMainStoreContext().myoCorrectEnrollmentStore;

        setCurrentRow(patient);

        const classes = makeStyles(styles)();

        const {
            sessions,
            loadCurrentSessions,
        } = useMainStoreContext().sessionsStore;

        useEffect(() => {
            loadAdvocates();
            editBl.loadMyocorrectRegistration(patient.id);
            setHasLoaded(true);
            loadCurrentSessions(patient.id).then(() => {
                setSessionsLoaded(true);
            });
        }, []);

        useEffect(() => {
            setTherapists([...advisors, ...advocates]);
        }, [advocates, advisors]);

        return (
            <>
                {
                    !patient.myoCorrectRegistrationId ?
                        (<>
                            <Grid container>
                                <Grid item xs={12} textAlign='right'>
                                    <Button variant="contained" className={classes.buttons} onClick={() => setToggleShowMyoCorrectModal(true)}>Enroll into MyoCorrect</Button>
                                </Grid>
                            </Grid>
                        </>)
                        : !sessionsLoaded ?
                            (<>
                                <Grid container>
                                    <Grid item xs={12} textAlign='center'>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </>)
                            : sessionsLoaded && sessions && !!sessions.length ?
                                (<>
                                    <Grid className="formBasics">
                                        <Appointments
                                            patientId={patient.id}
                                            therapists={therapists}
                                            vivosId={patient.vivosId}
                                            assignedTherapist={editBl.patientMyocorrectRegistration.assignedTherapistId}
                                            sessions={editBl.patientMyocorrectRegistration}
                                            myoCorrectEnrollmentDate={editBl.patientMyocorrectRegistration.myoCorrectEnrollmentDate}
                                            myoCorrectPlanId={editBl.patientMyocorrectRegistration.myoCorrectPlanId}
                                            dataLoaded={hasLoaded}
                                        />
                                    </Grid>
                                </>) :
                                (<>
                                    <Grid container>
                                        <Grid item xs={12} textAlign='right'>
                                            <Button variant="contained" className={classes.buttons} onClick={() => setToggleFirstSession(true)}>Assign initial evaluation</Button>
                                        </Grid>
                                    </Grid>
                                </>)
                }
            </>
        )
    }
export const PatientMyoCorrect = observer(Comp);

