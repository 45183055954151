import { action, makeAutoObservable, observable } from 'mobx';
import { Gateway } from '../api/Gateway';
import VitalsAndMeasurments from '../entities/VitalsAndMeasurments';
import { MainStore } from './MainStore';
import PatientVisit from '../entities/PatientVisit';
import PatientEpworth from '../entities/PatientEpworth';
import PatientHeadNeck from '../entities/PatientHeadNeck';
import PatientFile from '../entities/PatientFIle';
import PatientSleepStudy from '../entities/PatientSleepStudy';
import PatientImage from '../entities/PatientImage';
import jwt_decode from 'jwt-decode';
import DictionaryListItem from '../entities/DictionaryListItem';
import Compliance from '../entities/Compliance';
import _ from 'lodash';

const defaultVisit = {
    id: 0,
    patientid: 0,
    patientVitalsAndMeasurements: [],
    patientImages: [],
    patientCompliances:[],
};

const defaultVitalsAndMeasurments = {
    id: 0,
    patientId: 0,
    visitId: 0,
    heartbeats: 0,
    bloodPressure: 0,
    tempurature: 0,
    respirations: 0,
    oxygenSaturation: 0,
    height: 0,
    weight: 0,
    headCircumference: 0,
    neckCircumference: 0,
    waistcircumference: 0,
    notes: '',
    unitFormat: 0,
    isActive: true,
};

const defaultCompliance = {
    id:0,
    visitId: -1,
    prescribedDaytimeHours: 0,
    prescribedNighttimeHours: 0,
    actualDaytimeHours:  0,
    actualNighttimeHours: 0,
    topViewUpper:  "",
    topViewLower: "",
    bottomViewUpper: "",
    bottomViewLower: "",
    images: [] as any[],
    isActive: true,
  }

export default class VisitStore {
    mainStore: MainStore;
    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    @observable id = 0;
    @observable userId = 0;
    @observable patientId = 0;
    @observable patientStatus = 0;
    @observable patientVitalsAndMeasurements: VitalsAndMeasurments = {} as VitalsAndMeasurments;
    @observable patientsImages: any[] = [];
    @observable patientFiles: PatientFile[] = [];
    @observable sleepTestFiles: PatientFile[] = [];
    @observable documentsFiles: PatientFile[] = [];
    @observable patientUploadFiles: PatientFile[] = [];
    @observable patientEpworth: PatientEpworth = {} as PatientEpworth;
    @observable patientSleepStudy: PatientSleepStudy = {} as PatientSleepStudy;
    @observable patientHeadNecks: PatientHeadNeck[] = [];
    @observable patientCompliances: Compliance[] = [];
    @observable visitRecords: PatientVisit[] = [];
    @observable currentVisit: PatientVisit = {} as PatientVisit;
    @observable intraoralImageTypes: DictionaryListItem[] = [];
    @observable extraoralImageTypes: DictionaryListItem[] = [];
    @observable occlusionImageTypes: DictionaryListItem[] = [];
    @observable visitExtraoral2dimages: any[] = [];
    @observable visitExtraoral3dimages: any[] = [];
    @observable visitIntraoral2dimages: any[] = [];
    @observable visitOcclusion2dimages: any[] = [];
    @observable visitOcclusion3dimages: any[] = [];
    @observable topUpperImages: any[] = [];
    @observable topLowerImages: any[] = [];
    @observable bottomUpperImages: any[] = [];
    @observable bottomLowerImages: any[] = [];
    @observable imagesToDelete: any[] = [];
    @observable complianceImagesToDelete: any[] = [];

    @observable visitCbctscan: any = {};
    @observable activeTab: number = 0;
    @observable imagesToSave: any[] = [];
    @observable complianceImagesSaved: boolean = false;
    @observable newCompliance: boolean = false;

    getVisitId = () => {
        return this.id;
    };

    @action setExtraoral2dImages = (value: any[]) => { this.visitExtraoral2dimages = value; }
    @action setExtraoral3dImages = (value: any[]) => { this.visitExtraoral3dimages = value; }
    @action setintraoral2dImages = (value: any[]) => { this.visitIntraoral2dimages = value; }
    @action setOcclusion2dImages = (value: any[]) => { this.visitOcclusion2dimages = value; }
    @action setOcclusion3dImages = (value: any[]) => { this.visitOcclusion3dimages = value; }
    @action setTopUpperImages = (value: any[]) => { this.topUpperImages = value; }
    @action setTopLowerImages = (value: any[]) => { this.topLowerImages = value; }
    @action setBottomUpperImages = (value: any[]) => { this.bottomUpperImages = value; }
    @action setBottomLowerImages = (value: any[]) => { this.bottomLowerImages = value; }

    @action setExtraoralImageTypes = (value: any[]) => { this.extraoralImageTypes = value; }
    @action setIntraoralImageTypes = (value: any[]) => { this.intraoralImageTypes = value; }
    @action setOcclusionImageTypes = (value: any[]) => { this.occlusionImageTypes = value; }
    @action setImagesToDelete = (value: any[]) => { this.imagesToDelete = value; }
    @action setComplianceImagesSaved = (value: boolean) => { this.complianceImagesSaved = value; }
    @action setNewCompliance = (value: boolean) => { this.newCompliance = value; }
    @action setCbctScan = (value: any) => { this.visitCbctscan = value; }

    @action setVisitRecords = (visitRecords: PatientVisit[]) => {
        this.visitRecords = visitRecords;
    };

    @action setCurrentVisit = (newValue: any) => {
        this.currentVisit = newValue;
    }

    @action setActiveTab = (newValue: number) => {
        this.activeTab = newValue;
    }

    @action setId = (newValue: number) => {
        this.id = newValue;
    };
    @action setVisitPatientId = (newValue: number) => {
        this.patientId = newValue;
    };
    @action setPatientVitalsAndMeasurements = (newValue: VitalsAndMeasurments) => {
        this.patientVitalsAndMeasurements = newValue;
    };
    @action setImages = (newValue: PatientImage[]) => {
        this.patientsImages = newValue;
    };
    @action
    setPatientFiles = (files: PatientFile[]) => {
        this.patientFiles = files;
    };
    @action
    setSleepTestFiles = (files: PatientFile[]) => {
        this.sleepTestFiles = files;
    };
    @action
    setDocumentFiles = (files: PatientFile[]) => {
        this.documentsFiles = files;
    };
    @action
    setPatientUploadFiles = (files: PatientFile[]) => {
        this.patientUploadFiles = files;
    };

    @action
    setPatientCompliances = (value: Compliance[]) => {
        this.patientCompliances = value;
    }

    @action
    setVisit = (visit: any) => {
        if (visit && visit.id) {
            this.setId(visit.id);
            this.setVisitPatientId(visit.patientId);
            this.setPatientVitalsAndMeasurements(visit.patientVitalsAndMeasurments);
            this.setImages(visit.patientsImages);
            this.setPatientFiles(visit.patientFiles);
            this.setPatientCompliances(visit.patientCompliances);
        }
    };

    @action
    loadVisits = async () => {
        return await Gateway.get('visitrecord/' + this.patientId).then(resp => {
            this.setVisitRecords(resp);
            if (resp.length > 1) {
                this.setId(resp[resp.length - 1].id);
                this.setVisitPatientId(resp[resp.length - 1].patientId);
                this.setPatientVitalsAndMeasurements(resp[resp.length - 1].patientVitalsAndMeasurements);
                this.setImages(resp[resp.length - 1].patientsImages);
                this.setPatientCompliances(resp[resp.length - 1].compliances);
            } else if (resp.length === 1) {
                this.setId(resp[0].id);
                this.setVisitPatientId(resp[0].patientId);
                this.setPatientVitalsAndMeasurements(resp[0].patientVitalsAndMeasurements);
                this.setImages(resp[0].patientsImages);
                this.setPatientCompliances(resp[0].compliances);
            } else {
                this.setId(0);
                this.setVisitPatientId(0);
                this.setPatientVitalsAndMeasurements({} as VitalsAndMeasurments);
                this.setImages([]);
                this.setPatientCompliances([defaultCompliance as Compliance]);
            }

            return resp;
        });
    };

    @action
    deleteFile = async (fileId, visitId) => {
        return await Gateway.delete('patient/deletefile/'+ fileId +'/'+ visitId +'/'+ this.userId, {}).then(resp => {
            return resp;
        });
    };

    @action
    deleteImages = async (visitId) => {
        _.forEach(this.imagesToDelete, (img) => {
            Gateway.delete(`patient/${visitId}/${img}/${this.userId}/deletepatientimage`, {}).then(
                () => {
                    this.setImagesToDelete([]);
                 });
        })
    };

    @action
    deleteComplianceImages = async (visitId) => {
        _.forEach(this.complianceImagesToDelete, (img) => {
            Gateway.delete(`visitrecord/compliance/${visitId}/${img}/${this.userId}`, {}).then(
                () => {
                    this.setImagesToDelete([]);
                 });
        })
    };

    @action
    uploadFiles = async (patientId, visitId) => {
        _.forEach(this.patientUploadFiles, (doc) => {
            Gateway.post(`/patient/${patientId}/${visitId}/${this.userId}/addpatientfile`, doc).then(
                (resp) => {
                    return resp;
                }
            );
        })
    };

    @action 
    visitImagesToComplianceImages = () => {
        this.clearImages();
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            if(this.patientCompliances[0]?.topViewUpper){
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].topViewUpper);
                img.pathUrl = decodeURI(this.patientCompliances[0].topViewUpper);
                img['filename'] = 'topViewUpper';
                img['type'] = '2D';
                this.topUpperImages.push(img);
            }
            if(this.patientCompliances[0]?.topViewLower){
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].topViewLower);
                img.pathUrl = decodeURI(this.patientCompliances[0].topViewLower);
                img['filename'] = 'topViewLower';
                img['type'] = '2D';
                this.topLowerImages.push(img);
            }
            if(this.patientCompliances[0]?.bottomViewLower){
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].bottomViewLower);
                img.pathUrl = decodeURI(this.patientCompliances[0].bottomViewLower);
                img['filename'] = 'bottomViewLower';
                img['type'] = '2D';
                this.bottomLowerImages.push(img);
            }
            if(this.patientCompliances[0]?.bottomViewUpper){
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].bottomViewUpper);
                img.pathUrl = decodeURI(this.patientCompliances[0].bottomViewUpper);
                img['filename'] = 'bottomViewUpper';
                img['type'] = '2D';
                this.bottomUpperImages.push(img);
            }
            Promise.all(promises).then(() => {
                this.setTopUpperImages(this.topUpperImages);
                this.setTopLowerImages(this.topLowerImages);
                this.setBottomLowerImages(this.bottomLowerImages);
                this.setBottomUpperImages(this.bottomUpperImages);

                resolve(true);
            });
        });
    };


    @action
    visitImagesToPatientImages = () => {
        this.clearImages();
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forOwn(this.patientsImages[0], async (val, k) => {
                if (val) {
                    if (k.toString().toLowerCase().indexOf('extraoral') >= 0) {
                        if (k.toString().toLowerCase().indexOf('3d') >= 0) {
                            this.visitExtraoral3dimages.push({ key: k, value: val, type: '3D' });
                        } else {
                            var img = {} as PatientImage;
                            img.id = 0;
                            img.data64Url = decodeURI(val);
                            img.pathUrl = decodeURI(val);
                            img['filename'] = k;
                            img['type'] = '2D';
                            var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                            if (opt) {
                                img.imageType = opt;
                            }
                            this.visitExtraoral2dimages.push(img);
                        }
                    } else if (k.toString().toLowerCase().indexOf('intraoral') >= 0) {
                        var img = {} as PatientImage;
                        img.id = 0;
                        img.data64Url = decodeURI(val);
                        img.pathUrl = decodeURI(val);
                        img['filename'] = k;
                        img['type'] = '2D';
                        var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                        if (opt) {
                            img.imageType = opt;
                        }
                        this.visitIntraoral2dimages.push(img);
                    } else if (k.toString().toLowerCase().indexOf('cbctscan') >= 0) {
                        this.visitCbctscan = [{ key: k, value: val, type: 'zip' }];
                    } else if (k.toString().toLowerCase().indexOf('occlusion') >= 0) {
                        if (
                            k.toString().toLowerCase().indexOf('3dupperarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('3dlowerarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('3dconstructedbite') >= 0
                        ) {
                            var img = {} as PatientImage;
                            img.id = 0;
                            img.data64Url = decodeURI(val);
                            img.pathUrl = decodeURI(val);
                            img['filename'] = k;
                            img['type'] = '3D';
                            var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                            if (opt) {
                                img.imageType = opt;
                            }
                            this.visitOcclusion3dimages.push(img);
                        } else if (
                            k.toString().toLowerCase().indexOf('occlusionupperarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionlowerarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionanterior') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionleft') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionright') >= 0
                        ) {
                            var img = {} as PatientImage;
                            img.id = 0;
                            img.data64Url = decodeURI(val);
                            img.pathUrl = decodeURI(val);
                            img['filename'] = k;
                            img['type'] = '2D';
                            var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                            if (opt) {
                                img.imageType = opt;
                            }
                            this.visitOcclusion2dimages.push(img);
                        }
                    }
                }
            });
            Promise.all(promises).then(() => {
                this.setExtraoral2dImages(this.visitExtraoral2dimages);
                this.setExtraoral3dImages(this.visitExtraoral3dimages);
                this.setintraoral2dImages(this.visitIntraoral2dimages);
                this.setOcclusion2dImages(this.visitOcclusion2dimages);
                this.setOcclusion3dImages(this.visitOcclusion3dimages);
                this.setCbctScan(this.visitCbctscan);
                resolve(true);
            });
        });
    };

    @action
    loadVisit = () => {
        Gateway.get('visitrecord/' + this.patientId + '/visit/' + this.id).then(resp => {
            this.setCurrentVisit(resp as unknown as PatientVisit);
            return this.currentVisit;
        });
    };

    @action
    getVisit = (): PatientVisit => {
        var visit = {} as PatientVisit;
        visit.patientEpworth = this.patientEpworth;
        visit.patientFiles = this.patientFiles;
        visit.patientHeadNecks = this.patientHeadNecks;
        visit.patientSleepStudy = this.patientSleepStudy;
        visit.patientVitalsAndMeasurements = [this.patientVitalsAndMeasurements];
        // visit.patientsImages = this.patient;
        visit.patientCompliances = this.patientCompliances;
        visit.patientId = this.patientId;
        visit.id = this.id;
        return visit;
    }

    @action
    saveVisit = () => {
        let visit = this.getVisit();
        visit.id = this.id;
        visit.patientId = this.patientId;
        this.imagesToSave = this.patientCompliances[0] ? this.patientCompliances[0].images : [];

        return new Promise((resolve, reject) => {
            var promises: any[] = [];

            if (this.id > 0) {
                promises.push(
                    Gateway.post(`visitrecord/${visit.id}`, visit).then(resp => {
                        this.setVisit(resp);
                        this.setCurrentVisit(resp as unknown as PatientVisit);
                    }),
                );
            } else {
                promises.push(
                    Gateway.post('visitrecord', visit).then(resp => {
                        this.setVisit(resp);
                        this.setCurrentVisit(resp as unknown as PatientVisit);
                    }),
                );
            }
            Promise.all(promises).then(() => {
                resolve(this.currentVisit);
            });
        })
    };

    @action
    saveCompliance = async() => {
        this.complianceImagesSaved = false;
        this.patientCompliances[0].visitId = this.id;
        if (this.patientCompliances[0].id > 0) {
            if(!this.patientCompliances[0].images){
                this.patientCompliances[0].images = this.imagesToSave;
            }
            Gateway.put(`visitrecord/compliance`, this.patientCompliances[0]).then(resp => {
                this.complianceImagesSaved = true;
            });
        } else {
            Gateway.post('visitrecord/compliance', this.patientCompliances[0]).then(resp => {
                this.complianceImagesSaved = true;
            });
        }
    }

    @action
    saveVitals = async (): Promise<any> => {
        if (this.patientVitalsAndMeasurements.id > 0) {
            Gateway.post(`visitrecord/vitals/${this.id}`, this.patientVitalsAndMeasurements).then(resp => {
                this.setPatientVitalsAndMeasurements(resp as unknown as VitalsAndMeasurments);
                return resp as unknown as Promise<any>;
            });
        } else {
            Gateway.post('visitrecord/vitals', this.patientVitalsAndMeasurements).then(resp => {
                this.setPatientVitalsAndMeasurements(resp as unknown as VitalsAndMeasurments);
                return resp as unknown as Promise<any>;
            });
        }
    };

    @action
    complianceImageDecode = (url: string): any => {
        var img = {} as PatientImage;
        img.id = 0;
        img.data64Url = decodeURI(url);
        img.pathUrl = decodeURI(url);
        img['filename'] = url;
        img['type'] = 'appliance';
        
        return img;
    }

    @action
    resetVisit = () => {
        this.setId(0);
        this.setPatientVitalsAndMeasurements({} as VitalsAndMeasurments);
        this.setImages([]);
        this.setPatientFiles([]);
        this.setPatientCompliances([]);
        this.clearImages();
        this.clearComplianceImages();
    }

    @action
    clearImages = () => {
        this.setExtraoral2dImages([]);
        this.setExtraoral3dImages([]);
        this.setintraoral2dImages([]);
        this.setOcclusion3dImages([]);
        this.setOcclusion2dImages([]);
        this.setCbctScan({}); 
    };

    @action
    clearComplianceImages = () => {
        this.setTopLowerImages([]);
        this.setTopUpperImages([]);
        this.setBottomLowerImages([]);
        this.setBottomUpperImages([]);
    };

    @action
    getTypeFromName = (name: any) => {
        switch (name.toLowerCase()) {
            // case "cbctScan":
            //     return "CBCT Scan";
            //     break;
            case 'extraoralfrontrelaxed':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 182;
                }); // "Front Relaxed";
                break;
            case 'extraoralbigsmile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 181;
                }); // "Big Smile";
                break;
            case 'extraoralrightprofile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 183;
                }); // "Right Profile";
                break;
            case 'extraoralsubmentovertex':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 184;
                }); // "Submento Vertex";
                break;
            case 'extraoralfullbodypostanterior':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 185;
                }); // "Full Body Post Anterior";
                break;
            case 'extraoralfullbodypostright':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 186;
                }); // "Full Body Post Right";
                break;
            case 'extraoral3dfacescan':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 187;
                }); // "3D Face Scan";
                break;
            case 'intraoralupperarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 170;
                }); //  "Upper Arch";
                break;
            case 'intraorallowerarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 171;
                }); // "Lower Arch";
                break;
            case 'intraoralanteriorocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 172;
                }); // "Anterior Occlusion";
                break;
            case 'intraoralleftocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 173;
                }); //  "Left Occlusion";
                break;
            case 'intraoralrightocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 174;
                }); //  "right Occlusion";
                break;
            case 'intraorallingualfrenum':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 220;
                }); // "Lingual Frenum";
                break;
            case 'intraoralsurfacetongue':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 175;
                }); // "Vertical Surface Tongue";
                break;
            case 'occlusion3dupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 221;
                }); // "3D Upper Arch";
                break;
            case 'occlusion3dlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 222;
                }); //  "3D Lower Arch";
                break;
            case 'occlusion3dconstructedbite':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 223;
                }); //  "3D Constructed Bite";
                break;
            case 'occlusionupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 224;
                }); // "Upper Arch";
                break;
            case 'occlusionlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 225;
                }); //  "Lower Arch";
                break;
            case 'occlusionanterior':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 226;
                }); //  "Anterior";
                break;
            case 'occlusionleft':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 227;
                }); //  "Left";
            break;
            case 'occlusionright':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 228;
                }); //  "Right";
            break;
            default:
                return;
        }
    };
}
