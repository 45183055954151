import {
    Box, Button, Dialog, DialogContent, DialogTitle, FormControl,
    Grid, IconButton, Radio, RadioGroup
} from "@mui/material";
import { observer } from "mobx-react";
import { Close } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useMainStoreContext } from "../../stores/MainStore";
import { UneruptedConditions } from "../../entities/Enums";


interface IDTProps {
    isOpen: boolean;
    onSave(location: number, reason: number): any;
    onClose: (onClose: boolean) => void;
}

export const UneruptedModal: React.FC<IDTProps> = observer(
    ({ isOpen, onClose, onSave }) => {
        const handleOnClick = () => {
            onClose(false);
        };
        const { teethChartTeeth, teethChart, currentToothNum, setOpenUneruptedModal
        } = useMainStoreContext().teethChartStore;

        const [uneruptedReason, setUneruptedReason] = React.useState(0);

        const handleSave = () => {
            onSave(currentToothNum, uneruptedReason);
            setOpenUneruptedModal(false);
        }
        const resetModal = () => {
            setUneruptedReason(0);
        }

        useEffect(() => {
            resetModal();
        },[])

        return (
            <Dialog
                open={isOpen}
                onClose={() => { setOpenUneruptedModal(false) }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >Select Unerupted Reason</Box>
                        <Box>
                            {<IconButton onClick={handleOnClick}>
                                <Close />
                            </IconButton>}
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <Grid container direction="row" className="supernumeraryModal" alignItems={"spaceBetween"}>
                            <Grid xs={12} className="fillWidth" direction="row">
                                <Grid container xs={12}>
                                    <Grid xs={12}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="Breathing-group-label"
                                            name="radio-buttons-Breathing"
                                            defaultValue={UneruptedConditions["None"]}
                                            onChange={(event: any) => {
                                                setUneruptedReason(event.target.value);
                                            }}
                                            value={uneruptedReason} >
                                            <Grid container direction="column" xs={12}>
                                                <Grid container direction="row" className="notesRow"><Grid xs={1} className="noteRdo"><Radio value={UneruptedConditions["Poor Eruption Path"]} /></Grid><Grid xs={10}>Poor Eruption Path</Grid></Grid>
                                                <Grid container direction="row" className="notesRow"><Grid xs={1} className="noteRdo"><Radio value={UneruptedConditions["Impacted"]} /></Grid><Grid xs={10}>Impacted</Grid></Grid>
                                                <Grid container direction="row" className="notesRow"><Grid xs={1} className="noteRdo"><Radio value={UneruptedConditions["Embedded"]} /></Grid><Grid xs={10} >Embedded</Grid></Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} container direction="row" justifyContent={"flex-end"}>
                                <Grid><Button className="vivButton normal" onClick={() => { setOpenUneruptedModal(false); }}>Cancel</Button></Grid>
                                <Grid><Button className={"vivButton normal"} onClick={handleSave}>Add Reason</Button></Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
            </Dialog >
        )
    }
);