import { Grid, Link, Typography } from '@mui/material';
import { AttachEmail, WhatsApp } from '@mui/icons-material';

import { ModalContainer } from '../../components/ModalContainer';

interface IContactInfo {
    isOpen: boolean;
    onClose: () => void;
}

export const ContactInfo: React.FC<IContactInfo> = ({ isOpen, onClose }) => {
    return (
        <Grid container>
            <ModalContainer noScroll onClick={() => {}} show={isOpen} title="Contact Info" onClose={onClose} smallScreen={true}>
                <Grid container xs={12} padding={10}>
                    <Grid container>
                        <AttachEmail />
                        &nbsp;
                        <Grid>Email: </Grid>
                        <Link
                            id="contactInfo.hdr.lbl.link"
                            rel="noopener noreferrer"
                            href={`mailto:helpdesk@vivoslife.com`}
                        >
                            <Typography id="contactInfo.hdr.lbl.title"> helpdesk@vivoslife.com</Typography>
                        </Link>
                    </Grid>
                    <Grid container>
                        <WhatsApp />
                        &nbsp;
                        <Grid>Phone: </Grid>
                        <Typography id="contactInfo.hdr.lbl.title"> 720-399-9322</Typography>
                    </Grid>
                </Grid>
            </ModalContainer>
        </Grid>
    );
};
