// Vendors
import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    alpha,
    Button,
    styled,
    Card,
} from '@mui/material';
import CheckboxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';

// Components
import { AiReportSelectVisit } from '../AiReportOrder/AiReportSelectVisit';

// API
import { GlobalUtils } from '../../api/GlobalUtils';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as _ from 'lodash';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const CardWrapper = styled(Card)(
    ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[10], 0.05)};
    `
);

interface IGIProps {
    // patient: ProviderPatient;
    visits?: any;
}

export const VisitRecordSectionModule: React.FC<IGIProps> = observer(({ visits }) => {
    const [currentRow, setCurrentRow] = React.useState<any>({})

    const hasForms = (row: any): boolean => {
        if ((row.patientSleepStudies && row.patientSleepStudies.length > 0) ||
            (row.patientEpworths && row.patientEpworths.length > 0) ||
            (row.patientHeadNecks && row.patientHeadNecks.length > 0) ||
            (row.patientHeadacheImpacts && row.patientHeadacheImpacts.length > 0) ||
            (row.patientPerceivedStresses && row.patientPerceivedStresses.length > 0) ||
            (row.patientStopBangs && row.patientStopBangs.length > 0)
        ) {
            return true;
        }
        return false;
    }
    const hasImages = (row: any): boolean => {
        if (row.patientImages && row.patientImages.length > 0) {
            _.forEach(Object.keys(row.patientImages[0]), (key) => {
                if (row.patientImages[0][key] != "") {
                    return true;
                }
            });
        }
        return false;
    }

    const hasCBCT = (patientImages: any): boolean => {
        if (patientImages && patientImages.length > 0) {
            if (patientImages[0].cbctScan != null &&
                patientImages[0].cbctScan != "") {
                return true;
            }
        }
        return false;
    }

    const hasModels = (patientImages: any): boolean => {
        if (patientImages && patientImages.length > 0) {
            if ((patientImages[0].occlusion3dlowerArch && patientImages[0].occlusion3dlowerArch != "")
                || (patientImages[0].occlusion3dupperArch && patientImages[0].occlusion3dupperArch != "")
                || (patientImages[0].occlusion3dconstructedBite && patientImages[0].occlusion3dconstructedBite != "")) {
                return true;
            }
        }
        return false;
    }


    const columns = [
        {
            field: 'id',
            headerName: 'Visit Record Id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button id={"visitrecords.dashboard.cell.toggleOpenModal." + params.row.ID} variant="text" onClick={() => {
                            setCurrentRow(params.row);
                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Visit Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button id={"visitrecords.dashboard.cell.toggleOpenModal." + params.row.ID} variant="text" onClick={() => {
                            //  setViewOnly(true);
                            //  setNoteDetails(params.row);
                            // setIsOpen(true);
                        }}>{moment(params.row.createdOn).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'forms',
            headerName: 'Forms',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasTheForms = hasForms(params.row);
                return (
                    <Grid>
                        {
                            hasTheForms &&
                            <CheckboxTwoToneIcon className="lightgreen" />
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'images',
            headerName: 'Images',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasTheImages = hasImages(params.row);
                return (
                    <Grid>
                        {
                            hasTheImages &&
                            <CheckboxTwoToneIcon className="lightgreen" />
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'cbct',
            headerName: 'CBCT',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasIt = hasCBCT(params.row.patientImages);
                {
                    hasIt &&
                        <Grid>
                            <CheckboxTwoToneIcon className="green" />
                        </Grid>
                }
            }
        },
        {
            field: 'models',
            headerName: 'Models',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasIt = hasModels(params.row.patientImages);
                {
                    hasIt &&
                        <Grid>
                            <CheckboxTwoToneIcon className="green" />
                        </Grid>
                }
            }
        },
    ];

    return (


        <Box height={'500px'}>
            <CardWrapper
                elevation={0}
                sx={{
                    textAlign: 'center',
                    // pt: 3,
                    pb: 2.5
                }}
            >
                <Grid container>
                    <Grid xs={12}>
                        <Box
                            sx={(theme) => ({
                                position: `relative`,
                                left: `0`,
                                top: `0`,
                                height: `75px`,
                                borderRadius: `${theme.general.borderRadius}`,
                                background: `${theme.colors.gradients.black2}`
                            })}>
                            <BoxComposedContent py={2}>
                                <Typography variant='h3'>
                                    Select Visit Records to Use
                                </Typography>
                            </BoxComposedContent>
                        </Box>
                    </Grid>
                    <Grid xs={12} padding={1}>
                        <AiReportSelectVisit visits={visits} />
                    </Grid>
                </Grid>

            </CardWrapper>
        </Box>


    );
});
