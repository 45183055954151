import { action, makeAutoObservable, observable } from "mobx";
import { Gateway } from "../api/Gateway";
import { MainStore } from "./MainStore";

export default class LabCaseReviewStore {
    mainStore: MainStore;
    triggerShowClaimModal: boolean = false;
    patientId = 0;

    @observable patient: any = {};

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
    }
    
    setTriggerShowClaimModal = (value: boolean) => {
        this.triggerShowClaimModal = value;
    };


    setPatientId = (newValue: number) => {
        this.patientId = newValue;
    };

    @action 
    loadPatient = async () => {
        await Gateway.get('/patient/' + this.patientId).then(data => {
            this.patient = data;
        });
    };

}