// Vendors
import React, { useEffect } from "react";
import { Grid, TextField, Typography, Autocomplete, IconButton, MenuItem, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Delete } from "@material-ui/icons";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Components

// Entities
import DictionaryListItem from '../../entities/DictionaryListItem';

// Stores

// API
import { observer } from "mobx-react";
import _ from "lodash";
import { Formik } from 'formik';
import * as Yup from 'yup';

const fileTypes = ["PDF", "DOC", "DOCX"];
const MAXWIDTH = 250;
const MAXHEIGHT = 200;
interface IUploaderProps {
    index: number;
    docIndex: number;
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    handleOptionSelect(event: any, oldOption: number, index: number, isRemoval: boolean): any;
    handleRemove(index: number): any;
    typeOptions: any[];
    selectedOptions: number[];
    file: any;
    docOption?: number;
    fileName: string;
    setFiles: any;
}

// IDs will need to be UPDATED
const SleepTestTypeOptions = [
    { id: 1001, label: "Vivos Score" },
    { id: 1002, label: "WatchPat" },
    { id: 1003, label: "ResMed" },
    { id: 1004, label: "ARES" },
    { id: 1005, label: "PSG" },
    { id: 1006, label: "Medibyte" },
    { id: 1007, label: "Phillips Respironics" },
    { id: 1008, label: "Sleep medix" },
    { id: 1009, label: "Stardust II" },
    { id: 1010, label: "Virtuox" },
    { id: 1011, label: "Other" },
];
const SleepStageOptions = [
    { id: 1012, label: "Pre-Treatment" },
    { id: 1013, label: "Mid-Treatment" },
    { id: 1014, label: "Post-Treatment" },

];

const DocSleepUploader: React.FC<IUploaderProps> =
    ({ file, fileName, setFiles, index, docIndex, typeOptions, selectedOptions, handleRemove, handleOptionSelect, parentCallback, docOption }) => {
        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [options, setOptions] = React.useState<any[]>([]);
        const [option, setOption] = React.useState<number>(-1);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [reportTypes, setReportTypes] = React.useState<DictionaryListItem[]>([]);

        useEffect(() => {
            setOptions(typeOptions);
            generateOptions(typeOptions, selectedOptions);
            setHasLoaded(true);
            setOption(docOption ? docOption : (file && file.imageType ? file.imageType.id : -1));
        }, [])

        useEffect(() => {
            generateOptions(typeOptions, selectedOptions);
        }, [index])

        const generateOptions = (list: any[], selectedList: number[]) => {
            var newList = _.map(list, (option) => {
                var index = _.findIndex(selectedList, (l) => { return option.id === l })
                return (
                    <MenuItem disabled={index >= 0} value={option.id}>{option.name}</MenuItem>)
            })
            setOptions(newList);
        }

        const handleOptionChange = (event: any) => {
            var opts = handleOptionSelect(event, option, docIndex, false);
            generateOptions(typeOptions, opts);
            setOption(Number(event.target.value));
        }

        return (
            hasLoaded ?
                <Grid container>
                    <Grid xs={2}>
                        <Grid key={index} container direction="column" marginLeft={5}>
                            <Grid item xs={12}>
                                <IconButton className="BtnTopRightCorner small"
                                    onClick={(event: any) => {
                                        handleOptionSelect(event, option, docIndex, true);
                                        handleRemove(docIndex);
                                    }}>
                                    <Delete className="cornerBtn" />
                                </IconButton>
                                <Grid container className="uploadImgContainer"
                                    justifyContent={"center"} alignContent="center"
                                    sx={{ height: MAXHEIGHT, width: MAXWIDTH, border: '1px solid #7E9DBB' }}>
                                    <Grid item><InsertDriveFileIcon className="uploadFile" /></Grid>
                                    <Grid item> {fileName}</Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} paddingTop={2} marginLeft={2} paddingLeft={1} width={MAXWIDTH}> */}
                            {/* <Grid container justifyContent={"flex-end"}> */}
                            {/* <TextField
                                    label="Document Type"
                                    select
                                    fullWidth
                                    onChange={handleOptionChange}
                                    value={option}
                                >
                                    {options}
                                </TextField> */}
                            {/* </Grid> */}
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid xs={10} paddingLeft={3}>
                        <Grid container padding={5}>
                            <Grid item xs={12}>
                                <Formik
                                    initialValues={{
                                        sleepTestType: "",
                                        dateOfSleepTest: "",
                                        treatmentStage: "",
                                        ahi3: "",
                                        rdi3: "",
                                        odi3: "",
                                        ahi4: "",
                                        rdi4: "",
                                        odi4: "",
                                        sleepHours: "",
                                        sleepMinutes: "",
                                        averageOxygenSat: "",
                                        averageBPM: "",
                                        averageSnoreDB: "",
                                        pctREM: "",
                                        pctDeNadirs: "",
                                        sleepLatency: "",
                                        usingOralAppliance: false,
                                        readByQualifiedPhys: false,
                                        noDiagnosis: false,
                                        obstructiveSleepApnea: false,
                                        upperAirwayResistanceSyndrome: false,
                                        centralSleepApnea: false,
                                        otherSleepDiagnosis: false,

                                    }}
                                    validationSchema={Yup.object().shape({
                                        sleepTestType: Yup.string()
                                            .notRequired(),
                                        dateOfSleepTest: Yup.date()
                                            .nullable()
                                            .required('Date of sleep test is required.')
                                            .max(new Date(), 'The birthday cannot be in the future'),
                                        treatmentStage: Yup.string()
                                            .notRequired(),
                                        ahi3: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        rdi3: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        odi3: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        rdi4: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        odi4: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        sleepHours: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 24 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        sleepMinutes: Yup.string()
                                            .notRequired()
                                            .test('is-valid', 'Invalid Value', (value) => {
                                                if (!value) return true; // Allow empty values
                                                const floatValue = parseFloat(value);
                                                return floatValue >= 0 && floatValue <= 1440 && /^\d+(\.\d{1})?$/.test(value);
                                            }),
                                        averageOxygenSat: Yup.string()
                                            .notRequired(),
                                        averageBPM: Yup.string()
                                            .notRequired(),
                                        averageSnoreDB: Yup.string()
                                            .notRequired(),
                                        pctREM: Yup.string()
                                            .notRequired(),
                                        pctDeNadirs: Yup.string()
                                            .notRequired(),
                                        sleepLatency: Yup.string()
                                            .notRequired(),
                                        usingOralAppliance: Yup.boolean()
                                            .notRequired(),
                                        readByQualifiedPhys: Yup.boolean()
                                            .notRequired(),
                                        noDiagnosis: Yup.boolean()
                                            .notRequired(),
                                        obstructiveSleepApnea: Yup.boolean()
                                            .notRequired(),
                                        upperAirwayResistanceSyndrome: Yup.boolean()
                                            .notRequired(),
                                        centralSleepApnea: Yup.boolean()
                                            .notRequired(),
                                        otherSleepDiagnosis: Yup.boolean()
                                            .notRequired(),

                                    })}
                                    onSubmit={() => { }}
                                >
                                    {/* formik props */}
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        // handleSubmit,
                                        // isSubmitting,
                                        touched,
                                        values
                                    }) => (
                                        <form autoComplete='new-password'>
                                            <Grid container spacing={3} paddingLeft={3} paddingRight={3} paddingTop={1} marginTop={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">
                                                        Sleep Study Data
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Grid container direction={"column"} spacing={2} paddingRight={1}>
                                                        <Grid item xs={12}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    label="Sleep Test Date"
                                                                    value={values.dateOfSleepTest}
                                                                    openTo="day"
                                                                    disableFuture={true}
                                                                    views={['year', 'month', 'day']}
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            id="sleepTestDate"
                                                                            label="Date of Sleep Test"
                                                                            name='dateOfSleepTest'
                                                                            fullWidth
                                                                            required={true}
                                                                            value={values.dateOfSleepTest}
                                                                        />}
                                                                    onChange={(event) => {
                                                                        // Call handleChange to handle Formik's state
                                                                        handleChange(event);
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                id="sleepTestType"
                                                                autoComplete={false}
                                                                freeSolo
                                                                autoSelect={false}
                                                                options={SleepTestTypeOptions}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                onChange={(event, value: unknown) => {
                                                                    // Call handleChange to handle Formik's state
                                                                    handleChange(event);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        id="sleepTestType"
                                                                        name='sleepTestType'
                                                                        label='Sleep Test Type'
                                                                        required={false}
                                                                        autoComplete='new-password'
                                                                        variant="outlined"
                                                                        placeholder="Select Sleep Test Type..."
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                id="treatmentStage"
                                                                autoComplete={false}
                                                                freeSolo
                                                                autoSelect={false}
                                                                options={SleepStageOptions}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                onChange={(event, value: unknown) => {
                                                                    // Call handleChange to handle Formik's state
                                                                    handleChange(event);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        id="treatmentStage"
                                                                        name='treatmentStage'
                                                                        label='Treatment Stage'
                                                                        required={false}
                                                                        autoComplete='new-password'
                                                                        variant="outlined"
                                                                        placeholder="Select Treatment Stage..."
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <Grid container spacing={2} paddingRight={1} paddingLeft={1}>
                                                        <Grid item xs={6}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.ahi3 && errors.ahi3
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.ahi3 && errors.ahi3}
                                                                        label='AHI 3%'
                                                                        name="ahi3"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.ahi3}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.rdi3 && errors.rdi3
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.rdi3 && errors.rdi3}
                                                                        label='RDI 3%'
                                                                        name="rdi3"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.rdi3}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.odi3 && errors.odi3
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.odi3 && errors.odi3}
                                                                        label='ODI 3%'
                                                                        name="odi3"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.odi3}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.ahi4 && errors.ahi4
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.ahi4 && errors.ahi4}
                                                                        label='AHI 4%'
                                                                        name="ahi4"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.ahi4}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.rdi4 && errors.rdi4
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.rdi4 && errors.rdi4}
                                                                        label='RDI 4%'
                                                                        name="rdi4"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.rdi4}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.odi4 && errors.odi4
                                                                        )}
                                                                        fullWidth
                                                                        helperText={touched.odi4 && errors.odi4}
                                                                        label='ODI 4%'
                                                                        name="odi4"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.odi4}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} md={2}>
                                                    <Grid container direction={"column"} spacing={2} paddingRight={2}>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                error={Boolean(
                                                                    touched.sleepHours && errors.sleepHours
                                                                )}
                                                                fullWidth
                                                                helperText={touched.sleepHours && errors.sleepHours}
                                                                label='Sleep Hours'
                                                                name="sleepHours"
                                                                required={false}
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    // Call handleChange to handle Formik's state
                                                                    handleChange(event);
                                                                }}
                                                                value={values.sleepHours}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                error={Boolean(
                                                                    touched.sleepMinutes && errors.sleepMinutes
                                                                )}
                                                                fullWidth
                                                                helperText={touched.sleepMinutes && errors.sleepMinutes}
                                                                label='Sleep Minutes'
                                                                name="sleepMinutes"
                                                                required={false}
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    // Call handleChange to handle Formik's state
                                                                    handleChange(event);
                                                                }}
                                                                value={values.sleepMinutes}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <Grid container direction={"column"}>
                                                        <Grid item paddingBottom={1}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={values.usingOralAppliance}
                                                                        onChange={handleChange}
                                                                        name="usingOralAppliance"
                                                                    />
                                                                }
                                                                label="This sleep test was done using an oral appliance."
                                                            />
                                                        </Grid>
                                                        <Grid item paddingBottom={1}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={values.readByQualifiedPhys}
                                                                        onChange={handleChange}
                                                                        name="readByQualifiedPhys"
                                                                    />
                                                                }
                                                                label="This sleep test was read by a qualified sleep physician."
                                                            />
                                                        </Grid>
                                                        {values.readByQualifiedPhys && (
                                                            <Grid item paddingLeft={5}>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={values.noDiagnosis}
                                                                                onChange={handleChange}
                                                                                name="noDiagnosis"
                                                                            />
                                                                        }
                                                                        label="No Diagnosis"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={values.obstructiveSleepApnea}
                                                                                onChange={handleChange}
                                                                                name="obstructiveSleepApnea"
                                                                                disabled={values.noDiagnosis}
                                                                            />
                                                                        }
                                                                        label="Obstructive Sleep Apnea"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={values.upperAirwayResistanceSyndrome}
                                                                                onChange={handleChange}
                                                                                name="upperAirwayResistanceSyndrome"
                                                                                disabled={values.noDiagnosis}
                                                                            />
                                                                        }
                                                                        label="Upper Airway Resistance Syndrome"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={values.centralSleepApnea}
                                                                                onChange={handleChange}
                                                                                name="centralSleepApnea"
                                                                                disabled={values.noDiagnosis}
                                                                            />
                                                                        }
                                                                        label="Central Sleep Apnea"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={values.otherSleepDiagnosis}
                                                                                onChange={handleChange}
                                                                                name="other"
                                                                                disabled={values.noDiagnosis}
                                                                            />
                                                                        }
                                                                        label="Other"
                                                                    />
                                                                </FormGroup>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container direction={"column"} spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h5">
                                                                Advanced Data
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2} >
                                                                <Grid item>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.averageOxygenSat && errors.averageOxygenSat
                                                                        )}
                                                                        // fullWidth
                                                                        helperText={touched.averageOxygenSat && errors.averageOxygenSat}
                                                                        label='Average Oxygen Saturation'
                                                                        name="averageOxygenSat"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.averageOxygenSat}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.averageBPM && errors.averageBPM
                                                                        )}
                                                                        // fullWidth
                                                                        helperText={touched.averageBPM && errors.averageBPM}
                                                                        label='Average BPM'
                                                                        name="averageBPM"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.averageBPM}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.averageSnoreDB && errors.averageSnoreDB
                                                                        )}
                                                                        // fullWidth
                                                                        helperText={touched.averageSnoreDB && errors.averageSnoreDB}
                                                                        label='Average Snore DB'
                                                                        name="averageSnoreDB"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.averageSnoreDB}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.pctREM && errors.pctREM
                                                                        )}
                                                                        // fullWidth
                                                                        helperText={touched.pctREM && errors.pctREM}
                                                                        label='% REM'
                                                                        name="pctREM"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.pctREM}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.pctDeNadirs && errors.pctDeNadirs
                                                                        )}
                                                                        // fullWidth
                                                                        helperText={touched.pctDeNadirs && errors.pctDeNadirs}
                                                                        label='O2 Nadir'
                                                                        name="pctDeNadirs"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.pctDeNadirs}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.sleepLatency && errors.sleepLatency
                                                                        )}
                                                                        // fullWidth
                                                                        helperText={touched.sleepLatency && errors.sleepLatency}
                                                                        label='Sleep Latency'
                                                                        name="sleepLatency"
                                                                        required={false}
                                                                        onBlur={handleBlur}
                                                                        onChange={(event) => {
                                                                            // Call handleChange to handle Formik's state
                                                                            handleChange(event);
                                                                        }}
                                                                        value={values.sleepLatency}
                                                                        variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid> : null
        )
    }
export default observer(DocSleepUploader);