import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AppBar, Box, Fade, Grid, IconButton, Modal, Toolbar, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { SurveyCreatorCustomForm } from './CreatorCustomForm';

interface AddModalInterface {
    isOpen: boolean;
    onClose: (onClose: boolean) => void;
    sections: any;
}

export const AddCustomForm: React.FC<AddModalInterface> = observer(({ onClose, isOpen, sections }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen, sections]);

    const handleOnClick = () => {
        onClose(false);
    };

    return (
        <Modal open={open} className="modalContainer"  style={{height:'100vh'}}>
            <Fade in={open}>
                <Box className="modalQuestion">
                    <Grid container direction="row" justifyContent={'space-between'}>
                        <AppBar position="static" className="modalHeader" color="inherit">
                            <Toolbar>
                                <Typography
                                    id={'modal.addCustomForm.title'}
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                    className="title"
                                >
                                    {'Add Custom Form'}
                                </Typography>
                                <IconButton id="addCustomForm.mod.btn.close" onClick={handleOnClick}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <SurveyCreatorCustomForm onClose={setOpen} sections={sections} />
                </Box>
            </Fade>
        </Modal>
    );
});
