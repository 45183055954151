import { Box, Button, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { boxStyles } from "./styles";
import { makeStyles } from '@material-ui/core/styles';
import { useMainStoreContext } from "../../../stores/MainStore";
import { RxAppliances, VivosAppliances } from "../../../entities/Enums";
import { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';
import { LoadingButton } from "@mui/lab";

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

const StepSix = () => {
	let decodedToken: any = [];
	const [providerType, setProviderType] = useState();
	const classes = styles();
	const boxClasses = boxStyles();
	const {
		currentPatient,
		selectedLab,
		selectedAppliance,
		versaTypeSelected,
		constructionBite,
		constructionBiteType,
		modelSource,
		shippingMethodSelected,
		speakWithLab,
		selectedApplianceType,
		selectedApplianceSize,
		selectedApplianceColor,
		fabricationTime,
		isSavingPrescription,
		setStep,
		setSpeakWithLab,
		setShippingMethodSelected,
		createPrescription,
		setIsSavingPrescription
	} = useMainStoreContext().prescriptionStore;
	const { client, cart, products, addVariantToCart, setProducts, setCartOpen } = useMainStoreContext().shopifyStore;
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;
	const handleBack = (): void => {
		((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) ? setStep(3) : setStep(4);
	};
	const handleNext = (): void => {
		setStep(6);
	};
	const handleShippingMethodChange = (method: 'Standard' | 'Expedited'): void => {
		setShippingMethodSelected(method);
	};
	const handleSpeakWithLabChange = (value: boolean): void => {
		setSpeakWithLab(value);
	};

	useEffect(() => {
		var token = sessionStorage.getItem('token');
		if (token) {
			decodedToken = jwt_decode(token);
			var providerTypes = JSON.parse(decodedToken.providerTypes);
			if (providerTypes.includes('VIP')) {
				setProviderType(providerTypes.find(x => x.includes('VIP')));
			} else {
				setProviderType(providerTypes[0]);
			}
		}
		if (client)
			client.product.fetchAll(100).then((products) => {
				setProducts(products);
				setCartOpen(true);
			});
	}, []);

	const openCheckout = async () => {
		setIsSavingPrescription(true);
		let product = products.find(x => x.productType === selectedApplianceType?.toString());
		const token = sessionStorage.getItem('token');
		if(token) {
			const decodedToken :any = jwt_decode(token);
			const providerTier = JSON.parse(decodedToken.providerTier);
			if (providerTier[0]) {
				await client.checkout.addDiscount(cart.id, providerTier[0]);
			}
		}
		if ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) {
			let variant = product.variants.find(y =>
				y.selectedOptions.find(z => (z.name === 'Color' &&
					z.value === selectedApplianceColor.name)) &&
				y.selectedOptions.find(z => (z.name === 'Size' &&
					z.value === selectedApplianceSize.name)) &&
				y.selectedOptions.find(z => (z.name === 'ProviderType' &&
					z.value === providerType)));

			addVariantToCart(variant.id, 1).then(() => {
				createPrescription().then((resp: any) => {
					sessionStorage.setItem("Cart", cart.id);
					sessionStorage.setItem("PrescriptionId", resp.id)
					window.open(cart.webUrl, "_self");
				});
			});
		}

		if (selectedApplianceType?.toString() === VivosAppliances["Versa-9"].toString() || selectedApplianceType?.toString() === VivosAppliances["Versa-EMA"].toString()
			|| selectedApplianceType?.toString() === VivosAppliances["Vida-Pod"].toString()) {
			let variant = product.variants.find(y =>
				y.selectedOptions.find(z => (z.name === 'ProviderType' &&
					z.value === providerType)));

			addVariantToCart(variant.id, 1).then(() => {
				createPrescription().then((resp: any) => {
					sessionStorage.setItem("Cart", cart.id);
					sessionStorage.setItem("PrescriptionId", resp.id)
					window.open(cart.webUrl, "_self");
				});
			});
		}
		setIsSavingPrescription(false);
	};
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Box className={boxClasses.leftBox}>
						<Typography variant="h6">
							Final Review
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									Shipping Method
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={3} textAlign='center'>
										<Button
											size="small"
											onClick={() => handleShippingMethodChange('Standard')}
											variant={shippingMethodSelected === 'Standard' ? 'contained' : 'outlined'}>
											Standard
										</Button>
									</Grid>
									<Grid item xs={3} textAlign='center'>
										<Button
											size="small"
											onClick={() => handleShippingMethodChange('Expedited')}
											variant={shippingMethodSelected === 'Expedited' ? 'contained' : 'outlined'}>
											Expedited
										</Button>
									</Grid>
								</Grid>
								<Typography variant="subtitle1">
									Ship to Address
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="body2">
											Tara Griffen
										</Typography>
										<Typography variant="body2">
											1200 Test Lane
										</Typography>
										<Typography variant="body2">
											Boulder, Colorado 55555
										</Typography>
										<Typography variant="body2">
											United States
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography>
											Would you like to speak to a laboratory specialist from [{selectedLab?.name}] before this appliance is manufactured?
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={3} textAlign='center'>
												<Button
													onClick={() => handleSpeakWithLabChange(true)}
													size="small"
													variant={speakWithLab ? 'contained' : 'outlined'}>
													Yes
												</Button>
											</Grid>
											<Grid item xs={3} textAlign='center'>
												<Button
													onClick={() => handleSpeakWithLabChange(false)}
													size="small"
													variant={speakWithLab ? 'outlined' : 'contained'}>
													No
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									Selected Therapies
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							{((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Appliance Size:</b> {selectedApplianceSize.name}
								</Typography>
							</Grid>}
							{((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Appliance Color:</b> {selectedApplianceColor.name}
								</Typography>
							</Grid>}
							<Grid item xs={12}>
								<Typography>
									<b>Lab:</b> {selectedLab?.name}
								</Typography>
							</Grid>
							{!((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> {fabricationTime}
								</Typography>
							</Grid>}
							{!((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>}
							{selectedAppliance === RxAppliances.VERSA && <Grid item xs={12}>
								<Typography>
									<b>Versa Type Selected:</b> {versaTypeSelected}
								</Typography>
							</Grid>}
							{!((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Bite Source:</b> {constructionBite}
								</Typography>
							</Grid>}
							{!((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Bite Type:</b> {constructionBiteType}
								</Typography>
							</Grid>}
							{!((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) && <Grid item xs={12}>
								<Typography>
									<b>Model Source:</b> {modelSource}
								</Typography>
							</Grid>}
							<Grid item xs={12}>
								<Typography>
									<b>Shipping:</b> {shippingMethodSelected}
								</Typography>
							</Grid>
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							<Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>
							<LoadingButton
								loading={isSavingPrescription}
								disabled={selectedLab?.id === -1}
								variant='contained'
								color="primary"
								sx={{ borderRadius: '30px', margin: '20px' }}
								onClick={openCheckout}>Checkout
							</LoadingButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>);
};
export default observer(StepSix);
