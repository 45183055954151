import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { FC } from "react";
import { ProviderHeader } from "./ProviderHeader";
import { ProviderDetails } from "./ProviderDetails";
import { ProviderPayments } from "./ProviderPayments";
import { useMainStoreContext } from "../../stores/MainStore";
import { Oval } from "react-loader-spinner";
import { ProviderOrderHistory } from "./ProviderOrderHistory";
import { ProviderPreferences } from "./ProviderPreferences";

interface IGIProps {
}


export const ProviderSettings: FC<IGIProps> = observer(() => {

    const store = useMainStoreContext().providerStore;
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        store.getProviderDetails().then(() => {
            setIsLoaded(true);
        });
    }, []);

    if (!isLoaded) {
        return (
            <Grid container sx={{ marginTop: '50px', justifyContent: 'center' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        );
    } else {
        return <>
            <Grid xs={12} className="patientEditModal">
                <Grid xs={12}>
                    <ProviderHeader provider={store.providerData} />
                </Grid>
                <Grid className={"tabContent"} xl={12} >
                    {store.activeTab === 0 && (
                        <ProviderDetails provider={store.providerData} />
                    )}
                    {store.activeTab === 1 && (
                        <ProviderPayments />
                    )}
                    {store.activeTab === 2 && (
                        <ProviderOrderHistory />
                    )}
                    {store.activeTab === 3 && (
                        <ProviderPreferences />
                    )}
                </Grid>
            </Grid>
        </>
    }
});