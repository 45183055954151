import { MainStore } from './MainStore';
import { observable, action,  makeObservable } from 'mobx';
import VitalsAndMeasurments from '../entities/VitalsAndMeasurments';

const defaultVitalsAndMeasurments = {
  id: 0,
  patientId: 0,
  visitId: 0,
  heartbeats: 0,
  bloodPressure: "0",
  temperature: 0,
  respirations: 0,
  oxygenSaturation: 0,
  height: 0,
  weight: 0,
  headCircumference: 0,
  neckCircumference: 0,
  waistCircumference: 0,
  notes: "",
  unitOfMeasure: false,
  isActive: true
}
export default class CreateVitalsAndMeasurementsStore {
  mainStore: MainStore;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;
  }

  @observable vitalsAndMeasures = new VitalsAndMeasurments(defaultVitalsAndMeasurments);
  @observable id = 0;
  @observable patientId = 0;
  @observable visitId = 0;
  @observable heartbeats = 0;
  @observable bloodPressure = "0";
  @observable temperature= 0;
  @observable respirations = 0;
  @observable oxygenSaturation = 0;
  @observable height = 0;
  @observable weight = 0;
  @observable headCircumference = 0;
  @observable neckCircumference = 0;
  @observable waistCircumference = 0;
  @observable notes = "";
  @observable unitOfMeasure = false;
  @observable error = true;

  @action setVitalsId = (newValue: number) => { this.id = newValue; };
  @action setVisitId = (newValue: number) => { this.visitId = newValue; };
  @action setVitalsPatientId = (newValue: number) => { this.patientId = newValue; };
  @action setHeartbeats = (newValue: number) => { this.heartbeats = newValue; };
  @action setBloodPressure = (newValue: string) => { this.bloodPressure = newValue };
  @action setTemperature = (newValue: number) => { this.temperature = newValue };
  @action setRespirations = (newValue: number) => { this.respirations = newValue };
  @action setOxygenSaturation = (newValue: number) => { this.oxygenSaturation = newValue };
  @action setHeight = (newValue: number) => { this.height = newValue }
  @action setWeight = (newValue: number) => { this.weight = newValue }
  @action setHeadCircumference = (newValue: number) => { this.headCircumference = newValue }
  @action setNeckCircumference = (newValue: number) => { this.neckCircumference = newValue }
  @action setWaistcircumference = (newValue: number) => { this.waistCircumference = newValue }
  @action setNotes = (newValue: string) => { this.notes = newValue }
  @action setUnitOfMeasure = (newValue: boolean) => { this.unitOfMeasure = newValue }
  @action setError = (error: boolean) => { this.error = error }

  
  @action setVitalsAndMeasurements = (newValue: VitalsAndMeasurments) => { 
    this.vitalsAndMeasures = newValue; 
    this.setVisitId(newValue.visitId);
    this.setVitalsPatientId(newValue.patientId);
    this.setHeartbeats(newValue.heartbeats);
    this.setBloodPressure(newValue.bloodPressure);
    this.setTemperature(newValue.temperature);
    this.setRespirations(newValue.respirations);
    this.setOxygenSaturation(newValue.oxygenSaturation);
    this.setHeight(newValue.height);
    this.setWeight(newValue.weight);
    this.setHeadCircumference(newValue.headCircumference);
    this.setNeckCircumference(newValue.neckCircumference);
    this.setWaistcircumference(newValue.waistCircumference);
    this.setNotes(newValue.notes);
  };

  @action
  vitalsAndMeasurementsReset = () => {
    this.id = 0;
    this.patientId = 0;
    this.visitId = 0;
    this.heartbeats = 0;
    this.bloodPressure = "0";
    this.temperature= 0;
    this.respirations = 0;
    this.oxygenSaturation = 0;
    this.height = 0;
    this.weight = 0;
    this.headCircumference = 0;
    this.neckCircumference = 0;
    this.waistCircumference = 0;
    this.notes = "";
    this.unitOfMeasure = false;
    this.error = false;
  }

  @action
  getVitalsAndMeasurements = () => {
    this.vitalsAndMeasures.id = this.id;
    this.vitalsAndMeasures.bloodPressure = this.bloodPressure;
    this.vitalsAndMeasures.heartbeats = this.heartbeats;
    this.vitalsAndMeasures.temperature = this.temperature;
    this.vitalsAndMeasures.respirations = this.respirations;
    this.vitalsAndMeasures.oxygenSaturation = this.oxygenSaturation;
    this.vitalsAndMeasures.height = this.height;
    this.vitalsAndMeasures.weight = this.weight;
    this.vitalsAndMeasures.headCircumference = this.headCircumference;
    this.vitalsAndMeasures.neckCircumference = this.neckCircumference;
    this.vitalsAndMeasures.waistCircumference = this.waistCircumference;
    this.vitalsAndMeasures.notes = this.notes;
    this.vitalsAndMeasures.unitOfMeasure = this.unitOfMeasure;

    return this.vitalsAndMeasures;
  }
}


