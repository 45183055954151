//@vendors
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { AttachEmail, WhatsApp } from '@mui/icons-material';
import jwt_decode from 'jwt-decode';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

// @components
import Item from '../../../components/Item';
import { ConsultationNotes } from './ConsultationNotes';
import DataGrid from '../../DataGrid/DataGrid';
import { ConsultationsModal } from './PatientConsultationWizard';

import { useMainStoreContext } from '../../../stores/MainStore';
import { Gateway } from '../../../api/Gateway';
import { toJS } from 'mobx';

interface IPatientConsultations {
    patient: any;
    isClinicalAdvocate?: boolean;
    notesInfo?: any;
}

interface ConsultationData {
    clinicalAdvisor: any;
    clinicalAdvocate: any;
}

export const PatientConsultation: React.FC<IPatientConsultations> = observer(
    ({ patient, isClinicalAdvocate, notesInfo }) => {

        const {
            consultationNotes,
            setPatientId,
            loadData,
            loadConsultationById,
            resetConsultationNotes,
            setConsultationNotesId,
            setCurrentConsultation,
            currentConsultation,
        } = useMainStoreContext().patientConsultationStore;

        useEffect(() => {
            setPatientId(notesInfo.patientId || patient.id);
            loadData();
            setHasLoaded(true);
        }, [loadData]);

        const [isOpen, setIsOpen] = useState(false);
        const [openConsultationNotes, setOpenConsultationNotes] = useState(false);
        const [hasLoaded, setHasLoaded] = useState(false);

        const [consultationData, setConsultationData] = useState<ConsultationData>();

        useEffect(() => {
            resetConsultationNotes();
            if (!consultationData) {
                let token = sessionStorage.getItem('token');
                let userID = 0;
                if (token) {
                    let tokenData: { id } = jwt_decode(token);
                    userID = tokenData.id;
                }
                Gateway.getOne(`consultations/${userID}`).then(data => {
                    setConsultationData(data);
                });
            }
        }, []);

        const handleClose = () => {
            resetConsultationNotes();
            setOpenConsultationNotes(false);
            setIsOpen(false);
        };

        const columns: GridColDef[] = [
            {
                field: 'scheduledDateTime',
                headerName: 'Scheduled Datetime',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: params => {
                    return (
                        <Grid>
                            {!isClinicalAdvocate && 
                            <Button
                                id={'patientConsultation.dashboard.cell.toggleOpenModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    setOpenConsultationNotes(true);
                                    setConsultationNotesId(params.row.consultationId);
                                    setCurrentConsultation(params.row);
                                    loadConsultationById();
                                }}
                            
                            >
                                <Grid>
                                    {moment.utc(params.row.scheduledDateTime).local().format('MM/DD/yyyy hh:mmA')}
                                </Grid>
                            </Button>
                        }
                        {(isClinicalAdvocate && (params.row.consultationStatus == "Scheduled" || params.row.consultationStatus == "Session Complete")) && 
                            <Button
                                id={'patientConsultation.dashboard.cell.toggleOpenModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    setOpenConsultationNotes(true);
                                    setConsultationNotesId(params.row.consultationId);
                                    setCurrentConsultation(params.row);
                                    loadConsultationById();
                                }}                 
                            >
                                <Grid>
                                    {moment.utc(params.row.scheduledDateTime).local().format('MM/DD/yyyy hh:mmA')}
                                </Grid>
                            </Button>
                        }
                        {(isClinicalAdvocate && (params.row.consultationStatus == "Notes Complete" || params.row.consultationStatus == "Cancelled")) && 
                        <Grid>
                            {moment.utc(params.row.scheduledDateTime).local().format('MM/DD/yyyy hh:mmA')}
                        </Grid>
                        }
                        </Grid>
                    );
                },
            },
            {
                field: 'consultationStatus',
                headerName: 'Consultation Status',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: params => {
                    return (
                        <Grid>
                            {params.row.consultationStatus}
                            
                        </Grid>
                    );
                },
            },
            {
                field: 'questions',
                headerName: 'Consultation Questions',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: params => {
                    return (
                        <Grid>
                                {params.row.questions}
                            
                        </Grid>
                    );
                },
            },
        ];

        return (
            <>
                {!isClinicalAdvocate && (
                    <Grid container flexGrow={1}>
                        <Grid item xs={12}>
                            <Item justifyContent="center" display="flex">
                                <Card>
                                    <CardContent>
                                        <Box height={150} alignItems="center" display="flex">
                                            <Box>
                                                {consultationData && consultationData.clinicalAdvocate && (
                                                    <Grid className="containerCalendly">
                                                        <Grid container className="containerInfo">
                                                            <Box style={{ display: 'flex', margin: 5 }}>
                                                                &nbsp;
                                                                <Typography>
                                                                    {consultationData.clinicalAdvocate.firstName +
                                                                        ' ' +
                                                                        (consultationData.clinicalAdvocate.middleName
                                                                            ? consultationData.clinicalAdvocate
                                                                                  .middleName + ' '
                                                                            : '') +
                                                                        consultationData.clinicalAdvocate.lastName}
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{ display: 'flex', margin: 5 }}>
                                                                <WhatsApp />
                                                                &nbsp;
                                                                <Typography>
                                                                    {consultationData.clinicalAdvocate.primaryPhone}
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{ display: 'flex', margin: 5 }}>
                                                                <AttachEmail />
                                                                &nbsp;
                                                                <Typography>
                                                                    {consultationData.clinicalAdvocate.emailAddress}
                                                                </Typography>
                                                            </Box>
                                                            <br />
                                                        </Grid>
                                                        <Grid
                                                            className="containerButton"
                                                            style={{ display: 'flex', margin: 5 }}
                                                        >
                                                            &nbsp;
                                                            <Button
                                                                onClick={() => setIsOpen(true)}
                                                                disabled={
                                                                    !consultationData ||
                                                                    !consultationData.clinicalAdvocate
                                                                }
                                                                variant="contained"
                                                            >
                                                                Schedule Consultation with Clinical Advocate
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Item>
                        </Grid>
                    </Grid>
                )}

                <Grid xs={12} container direction="column">
                    {consultationNotes && (
                        <Grid xs={12}>
                            <DataGrid
                                columns={columns}
                                data={consultationNotes}
                                loading={!hasLoaded}
                                size={'small'}
                                pageSize={10}
                                getRowId={row => row.consultationId}
                            />
                        </Grid>
                    )}
                </Grid>

                <ConsultationsModal
                    isOpen={isOpen}
                    onClose={handleClose}
                    patient={patient}
                    consultationsData={consultationData}
                />

                <ConsultationNotes
                    isClinicalAdvocate={isClinicalAdvocate}
                    consultation={consultationData}
                    currentConsultation={currentConsultation}
                    isOpen={openConsultationNotes}
                    notesInfo={notesInfo}
                    onClose={handleClose}
                />
            </>
        );
    }
);
