import { Divider, FormControl, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import Item from '../../Item';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CssTextField from '../../CssTextField';
import { FC } from 'react';
import { useMainStoreContext } from '../../../stores/MainStore';

interface IPatientComplains {
    complaint?: number;
    complaintNote?: string;
}

export const PatientComplains: FC<IPatientComplains> = observer(({ complaint, complaintNote }) => {
    const { complaints, setComplaints, complaintsNotes, setComplaintsNotes } =
        useMainStoreContext().patientTreatmentStore;

    const CONCERN_SLEEP: number = 1;
    const CONCERN_FUNCTION: number = 2;
    const CONCERN_PAIN: number = 4;
    const CONCERN_COSMETICS: number = 8;
    const CONCERN_OTHER: number = 16;

    const complainsChange = (event, options: number) => {
        let value = complaints;
        if (event.target.checked) {
            value = value | options;
        } else {
            value = value & ~options;
        }
        setComplaints(value);
    };

    const optionChecked = (options, option) => {
        return (options & option) === option;
    };

    return (
        <Grid>
            <Grid
                container
                xs={12}
                direction="row"
                paddingLeft={4}
                columnSpacing={0}
                className="formBasics"
                marginBottom={5}
                marginTop={5}
            >
                <Grid container direction={'row'} className="formSectionLabel">
                    <Grid>Patient Complaints</Grid>
                </Grid>
                <FormControl>
                    <Item>
                        <Grid
                            container
                            direction="row"
                            paddingLeft={4}
                            justifyContent="space-between"
                            alignItems={'center'}
                        >
                            <Grid>
                                <FormControlLabel
                                    onChange={event => {
                                        complainsChange(event, CONCERN_SLEEP);
                                    }}
                                    control={<Checkbox checked={optionChecked(complaints, CONCERN_SLEEP)} />}
                                    label="Sleep"
                                />
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    onChange={event => {
                                        complainsChange(event, CONCERN_FUNCTION);
                                    }}
                                    control={<Checkbox checked={optionChecked(complaints, CONCERN_FUNCTION)} />}
                                    label="Function"
                                />
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    onChange={event => {
                                        complainsChange(event, CONCERN_PAIN);
                                    }}
                                    control={<Checkbox checked={optionChecked(complaints, CONCERN_PAIN)} />}
                                    label="Pain"
                                />
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    onChange={event => {
                                        complainsChange(event, CONCERN_COSMETICS);
                                    }}
                                    control={<Checkbox checked={optionChecked(complaints, CONCERN_COSMETICS)} />}
                                    label="Cosmetics"
                                />
                            </Grid>
                            <Grid>
                                <FormControlLabel
                                    onChange={event => {
                                        complainsChange(event, CONCERN_OTHER);
                                    }}
                                    control={<Checkbox checked={optionChecked(complaints, CONCERN_OTHER)} />}
                                    label="Other"
                                />
                            </Grid>
                        </Grid>
                    </Item>
                </FormControl>
            </Grid>
            <Divider variant="middle" />
            <Grid
                container
                xs={12}
                direction="row"
                paddingLeft={4}
                columnSpacing={0}
                className="formBasics"
                marginTop={5}
            >
                <Grid container direction={'row'} className="formSectionLabel">
                    <Grid>Details</Grid>
                </Grid>
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                placeholder="Please describe the complaints in detail"
                                required={false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setComplaintsNotes(event.target.value);
                                }}
                                value={complaintsNotes}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
});
