import { AddCircleOutline, Close, ThirtyFpsSelect } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton } from "@mui/material";
import { GridColDef, GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";
import { makeObservable, observable, toJS } from "mobx";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { Component } from "react";
import DictionaryList from "../../../entities/DictionaryList";
import DictionaryListItem from "../../../entities/DictionaryListItem";
import CssTextField from "../../CssTextField";
import DataGrid from "../../DataGrid/DataGrid";
import Item from "../../Item";
import { DictionaryListItemModal } from "./DictionaryListItemModal";


interface IModalProps {
    bl: any;
    isOpen: boolean;
    title: string;
    onSave(value: DictionaryList): any;
    onClose: (onClose: boolean) => void;
}

const defaultList = {
    name: "",
    listKey: "",
    dictionaryListItems: [],
    isActive: true,
    createdBy: 0
}

@observer
export class DictionaryListModal extends Component<IModalProps> {
    @observable showDictListItemModal: boolean = false;
    constructor(props: any) {
        super(props);
       makeObservable(this);
    }

    handleOnClick = () => {
        this.props.bl.setCurrentList    (defaultList);
        this.props.onClose(true);
    };

    columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid>
                        <Button onClick={() => {
                            this.props.bl.setCurrentListItem(params.row);
                            this.setShowListItemModal(true);
                        }}>
                            {params.row.name}
                        </Button>
                    </Grid>
                )
            }
        },
        {
            field: 'listItemKey', headerName: 'List Item Key', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (<Grid>  {params.row.listItemKey} </Grid>)
            }
        },
        {
            field: 'value', headerName: 'Value', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid>{params.row.value}</Grid>
                )
            }
        },
        {
            field: 'isActive', headerName: 'Active', flex: 1, type: 'boolean', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid xs container alignContent={"center"}>
                        <FormControl>
                            <Item>
                                <Grid className="formLabel" id="hb">{""}</Grid>
                                <FormControlLabel
                                    onChange={(event: React.SyntheticEvent<Element>, checked) => this.props.bl.currentList.isActive == checked}
                                    control={<Checkbox checked={params.row.isActive} />}
                                    label="Is Active"
                                />
                            </Item>
                        </FormControl>
                    </Grid>
                )
            }
        }

    ];

    DictListCustomFooter = () => {
        return (
            <GridFooterContainer>
                <IconButton id="admin.btn.addDictionaryList" onClick={() => {
                    this.props.bl.createNewListItem();
                    this.setShowListItemModal(true);
                }}><AddCircleOutline /></IconButton>
                <GridFooter sx={{ border: 'none' }} />
            </GridFooterContainer>
        )
    }

    render() {
        return (
            <Grid>
                <Dialog open={this.props.isOpen} fullWidth={true} maxWidth={"xl"} >
                    <DialogTitle id='form-dialog-title' className='Title'>
                        <Box display='flex' alignItems='center'>
                            <Box
                                sx={{ height: '30px' }}
                                flexGrow={1}
                                fontSize='24px'
                                fontWeight={900}
                                fontFamily='Proxima Nova'>
                                {this.props.title}
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={this.handleOnClick}
                                    sx={{
                                        fontSize: 25,
                                        backgroundColor: 'transparent',
                                        color: 'blue'
                                    }}>
                                    <Close />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <Grid xs container direction="column" className="formBasics" sx={{ margin: "25px 0px 0px 0px" }}>
                            <Grid container direction="row">
                                <Grid xs container>
                                    <FormControl>
                                        <Item>
                                            <Grid container direction={"row"} className="formLabel" ><Grid>Id</Grid></Grid>
                                            <Grid className="formLabel" id="hb">{""}</Grid>
                                            <CssTextField id="dictionarylist.txt.id"
                                                variant="outlined"
                                                className="TextBox"
                                                size="small"
                                                fullWidth
                                                type={"text"}
                                                disabled={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                }}
                                                value={this.props.bl.currentList ? this.props.bl.currentList.id : -1}
                                            />
                                        </Item>
                                    </FormControl>
                                </Grid>
                                <Grid xs container>
                                    <FormControl>
                                        <Item>
                                            <Grid container direction={"row"} className="formLabel" ><Grid>Name</Grid></Grid>
                                            <Grid className="formLabel" id="hb">{""}</Grid>
                                            <CssTextField id="dictionarylist.txt.name"
                                                variant="outlined"
                                                className="TextBox"
                                                size="small"
                                                fullWidth
                                                type={"text"}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.props.bl.currentList.name = event.target.value;
                                                    this.props.bl.currentList.listKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                                }}
                                                value={ this.props.bl.currentList.name}
                                            />
                                        </Item>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid xs container>
                                    <FormControl>
                                        <Item>
                                            <Grid container direction={"row"} className="formLabel" ><Grid>Dictionary List Key</Grid></Grid>
                                            <Grid className="formLabel" id="hb">{""}</Grid>
                                            <CssTextField id="dictionarylist.txt.listkey"
                                                variant="outlined"
                                                className="TextBox"
                                                size="small"
                                                fullWidth
                                                type={"text"}
                                                onChange={(event: any) => {
                                                    this.props.bl.currentList.listKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                                }}
                                                value={this.props.bl.currentList ? this.props.bl.currentList.listKey : ""}
                                            />
                                        </Item>
                                    </FormControl>
                                </Grid>
                                <Grid xs container alignContent={"center"}>
                                    <FormControl>
                                        <Item>
                                            <Grid className="formLabel" id="hb">{""}</Grid>
                                            <FormControlLabel
                                                onChange={(event: any) => {
                                                    this.props.bl.currentList.isActive = event.target.checked;
                                                }}
                                                control={<Checkbox checked={this.props.bl.currentList ? this.props.bl.currentList.isActive : false} />}
                                                label="Is Active"
                                            />
                                        </Item>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid sx={{ padding: "25px" }}>
                                <DataGrid
                                    columns={this.columns}
                                    data={toJS(this.props.bl.currentList.dictionaryListItems)}
                                    pageSize={5}
                                    customFooter={this.DictListCustomFooter}
                                />
                            </Grid>
                            <Grid container direction="row" xs justifyContent={"flex-end"} >
                                <Grid><Button className="vivButton large invert" onClick={this.handleOnClick}>Cancel</Button></Grid>
                                <Grid><Button className="vivButton large invert" onClick={() => {this.props.onSave(this.props.bl.currentList); this.handleOnClick();}}>Save</Button></Grid>
                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog >
                {this.setShowListItemModal && 
                <DictionaryListItemModal title={"Add/Edit List Item"}
                    bl={this.props.bl}
                    isOpen={this.showDictListItemModal}
                    onSave={this.saveListItem}
                    onClose={() => {
                        this.props.bl.currentItem = {};
                        this.setShowListItemModal(false);
                    }} />}
            </Grid>
        )
    }

    saveListItem = async () => {
        await this.props.bl.saveItem(this.props.bl.currentListItem);
      }

    setShowListItemModal = (value: boolean) => {
        this.showDictListItemModal = value;
    }
}