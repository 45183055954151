import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { boxStyles } from "./styles";
import { makeStyles } from '@material-ui/core/styles';
import { useMainStoreContext } from "../../../stores/MainStore";
import { RxAppliances } from "../../../entities/Enums";
import react, { useEffect } from "react";

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

const StepFive = () => {
	const classes = styles();
	const boxClasses = boxStyles();
	const [isCustomVertical, setIsCustomVertical] = react.useState(false);
	const {
		setStep,
		currentPatient,
		selectedLab,
		selectedAppliance,
		versaTypeSelected,
		constructionBite,
		setConstructionBite,
		constructionBiteType,
		setConstructionBiteType,
		protrusionPosition,
		setProtrusionPosition,
		protrusionDistance,
		setProtrusionDistance,
		modelSource,
		setModelSource,
		labAdjustBite,
		setLabAdjustBite,
		digitalConstructionBiteEmbedded,
		setDigitalConstructionBiteEmbedded,
		isUpdating,
		updatePrescription,
		fabricationTime,
		selectedVerticalThickness,
		selectedBiteLocation
	} = useMainStoreContext().prescriptionStore;
	
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;

	const handleBack = (): void => {
		setStep(3);
	};
	const handleNext = (): void => {
		isUpdating ? updatePrescription() : setStep(5);
	};
	useEffect(() => {
		setIsCustomVertical(selectedVerticalThickness?.listItemKey === 'CUSTOMVERTICAL' ? true : false);
    }, []);

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Box className={boxClasses.leftBox}>
						<Typography variant="h6">
							Bites
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									Construction Bite
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBite === 'Physical' ? 'contained' : 'outlined'} onClick={() => setConstructionBite('Physical')}>Physical</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBite === 'Digital' ? 'contained' : 'outlined'} onClick={() => setConstructionBite('Digital')}>Digital</Button>
									</Grid>
									{!isCustomVertical && <Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBite === 'None' ? 'contained' : 'outlined'} onClick={() => setConstructionBite('None')}>None</Button>
									</Grid>}
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									Lab is approved to modify/adjust my construction bite up to 2mm in a vertical dimension only as needed.
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={6} textAlign='right'>
										<Button size="small" variant={labAdjustBite ? 'contained' : 'outlined'} onClick={() => setLabAdjustBite(true)}>Yes</Button>
									</Grid>
									<Grid item xs={6}>
										<Button size="small" variant={labAdjustBite ? 'outlined' : 'contained'} onClick={() => setLabAdjustBite(false)}>No</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									Construction Bite Type
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBiteType === 'Natural' ? 'contained' : 'outlined'} onClick={() => setConstructionBiteType('Natural')}>Natural</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBiteType === 'Neuromuscular' ? 'contained' : 'outlined'} onClick={() => setConstructionBiteType('Neuromuscular')}>Neuromuscular</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBiteType === 'Phonetic' ? 'contained' : 'outlined'} onClick={() => setConstructionBiteType('Phonetic')}>Phonetic</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={constructionBiteType === 'Protrusive' ? 'contained' : 'outlined'} onClick={() => setConstructionBiteType('Protrusive')}>Protrusive</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									The digital construction bite is embedded in my upper and lower arch STL files
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={6} textAlign='right'>
										<Button size="small" variant={digitalConstructionBiteEmbedded ? 'contained' : 'outlined'} onClick={() => setDigitalConstructionBiteEmbedded(true)}>Yes</Button>
									</Grid>
									<Grid item xs={6}>
										<Button size="small" variant={digitalConstructionBiteEmbedded ? 'outlined' : 'contained'} onClick={() => setDigitalConstructionBiteEmbedded(false)}>No</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									The lower midline compared to the upper midline at protrusion should be:
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={protrusionPosition === 'Left' ? 'contained' : 'outlined'} onClick={() => setProtrusionPosition('Left')}>Left</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={protrusionPosition === 'Center' ? 'contained' : 'outlined'} onClick={() => setProtrusionPosition('Center')}>Center</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={protrusionPosition === 'Right' ? 'contained' : 'outlined'} onClick={() => setProtrusionPosition('Right')}>Right</Button>
									</Grid>
									<Grid item xs={12}>
										<Box
											sx={{
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
											}}>
											<Box
												sx={{
													width: '50%',
													display: 'flex',
												}}>
												<Grid container spacing={1}>
													<Grid item xs={2}>
														<Typography variant="caption">
															By
														</Typography>
													</Grid>
													<Grid item xs={8}>
														<TextField
															type="number"
															size="small"
															variant="outlined"
															value={protrusionDistance}
															onChange={(ev) => setProtrusionDistance(parseInt(ev.target.value))} />
													</Grid>
													<Grid item xs={2}>
														<Typography variant="caption">
															mm
														</Typography>
													</Grid>
												</Grid>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Typography variant="h6">
							Models
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Typography variant="subtitle1">
									Model Source
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={modelSource === 'Physical' ? 'contained' : 'outlined'} onClick={() => setModelSource('Physical')}>Physical</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={modelSource === 'Digital' ? 'contained' : 'outlined'} onClick={() => setModelSource('Digital')}>Digital</Button>
									</Grid>
									<Grid item xs={4} textAlign='center'>
										<Button size="small" variant={modelSource === 'None' ? 'contained' : 'outlined'} onClick={() => setModelSource('None')}>None</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={3}>
									</Grid>
									<Grid item xs={3}>
									</Grid>
									<Grid item xs={3}>
									</Grid>
									<Grid item xs={3}>
										<Box>
											<TextField label='Impression Date' size="small" variant="outlined" />
											<TextField label='Staff Member' size="small" variant="outlined" />
											<TextField label='Machine Brand' size="small" variant="outlined" />
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Lab:</b> {selectedLab.name}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> {fabricationTime}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>
							{selectedAppliance === RxAppliances.VERSA && <Grid item xs={12}>
								<Typography>
									<b>Versa Type Selected:</b> {versaTypeSelected}
								</Typography>
							</Grid>}
							{selectedAppliance === RxAppliances.VIDA && <Grid item xs={12}>
								<Typography>
									<b>Vertical Thickness:</b> {selectedVerticalThickness.name}
								</Typography>
							</Grid> }
							{selectedAppliance === RxAppliances.VIDA && <Grid item xs={12}>
								<Typography>
									<b>Unilateral BiteBlock:</b> {selectedBiteLocation.name}
								</Typography>
							</Grid> }
							<Grid item xs={12}>
								<Typography>
									<b>Bite Source:</b> {constructionBite}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Bite Type:</b> {constructionBiteType}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Model Source:</b> {modelSource}
								</Typography>
							</Grid>
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							<Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								sx={{ borderRadius: '30px', margin: '20px' }}>
								{isUpdating ? 'Save' : 'Save + Continue'}
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>);
};
export default observer(StepFive);
