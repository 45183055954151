import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid } from '@mui/material';
import { IconButton, MenuItem } from '@material-ui/core';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import _ from "lodash";
import CssSelectField from "../CssSelectField";
import { Delete } from "@material-ui/icons";

const fileTypes = ["PDF", "DOC", "DOCX"];
const MAXWIDTH = 250;
const MAXHEIGHT = 200;
interface IUploaderProps {
    index: number;
    docIndex: number;
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    handleOptionSelect(event: any, oldOption: number, index: number, isRemoval: boolean): any;
    handleRemove(index: number): any;
    typeOptions: any[];
    selectedOptions: number[];
    file: any;
    fileName: string;
    setFiles: any;
    files: any[];
}

const DocUploaderGeneral: React.FC<IUploaderProps> =
    ({ file, fileName, setFiles, files, index, docIndex, typeOptions, selectedOptions, handleRemove, handleOptionSelect, parentCallback }) => {
        const [options, setOptions] = React.useState<any[]>([]);
        const [option, setOption] = React.useState<number>(-1);
        const [hasLoaded, setHasLoaded] = React.useState(false);

        useEffect(() => {
            if (typeOptions) {
                setOptions(typeOptions);
                generateOptions(typeOptions, selectedOptions);
                setHasLoaded(true);
            }
            setOption(files[docIndex] && files[docIndex].imageType ? files[docIndex].imageType.id : -1);
        }, [])

        useEffect(() => {
            generateOptions(typeOptions, selectedOptions);
        }, [index, typeOptions])

        const generateOptions = (list: any[], selectedList: number[]) => {
            var newList = _.map(list, (option) => {
                var index = _.findIndex(selectedList, (l) => { return option.id === l })
                return (
                    <MenuItem disabled={index >= 0 && selectedList[index] !== 791} value={option.id}>{option.name}</MenuItem>)
            })
            var arr = newList.slice();
            arr.unshift(<MenuItem value={-1}>{"Select a type..."}</MenuItem>);
            newList = arr;
            setOptions(newList);
        }

        const handleOptionChange = (event: any) => {
            
            var opts = handleOptionSelect(event, option, docIndex, false);
            generateOptions(typeOptions, opts);
            setOption(Number(event.target.value));
        }

        return (
            hasLoaded ?
                <Grid key={index} xs={12} container >
                    <Grid className="" justifyItems={"flex-end"} >
                        <IconButton className="BtnTopRightCorner small"
                            onClick={(event: any) => {
                                handleOptionSelect(event, option, docIndex, true);
                                handleRemove(docIndex);
                            }}>
                            <Delete className="cornerBtn" />
                        </IconButton>
                        <Grid container direction="column" className="uploadImgContainer"
                            justifyContent={"center"} alignContent="center"
                            sx={{ height: MAXHEIGHT, width: MAXWIDTH, border: '1px solid #7E9DBB' }}>
                            <Grid item><InsertDriveFileIcon className="uploadFile" /></Grid>
                            <Grid item> {fileName}</Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction="row" sx={{ paddingTop: "5px" }}>
                        <Grid xs={12} container direction="row">
                            <Grid xs={2.5} container className="formLabel" sx={{ alignContent: "center" }}  ><Grid>{"File Type"}</Grid></Grid>
                            <Grid>
                                <CssSelectField
                                    size="small"
                                    className="DateBox"
                                    sx={{ width: "auto" }}
                                    onChange={handleOptionChange}
                                    value={option}
                                    >
                                    {options}
                                </CssSelectField>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid> : null
        )
    }
export default observer(DocUploaderGeneral);