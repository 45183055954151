import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useMainStoreContext } from '../../../stores/MainStore';
import { observer } from 'mobx-react';
import { Allergies, Ethnicity, Gender, RxAppliances } from '../../../entities/Enums';
import { LoadingButton } from '@mui/lab';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { boxStyles } from './styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

const StepOne = () => {
	const classes = styles();
	const boxClasses = boxStyles();
	const genders = Object.values(Gender).filter(v => typeof (v) === 'string');
	const ethnicities = Object.values(Ethnicity).filter(v => typeof (v) === 'string');
	const {
		currentPatient,
		patientId,
		isSavingPatient,
		selectedAppliance,
		setSelectedAppliance,
		setCurrentPatient,
		setStep,
		updatePerson,
		setIsSavingPatient
	} = useMainStoreContext().prescriptionStore;

	const { allergyOptions, allergyhxOnChange, optionChecked, upsertPatientHistory } = useMainStoreContext().patientEditStore;

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.firstName = event.target.value;
		setCurrentPatient(patient);
	};
	const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.lastName = event.target.value;
		setCurrentPatient(patient);
	};
	const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.genderId = parseInt(event.target.value);
		setCurrentPatient(patient);
	};
	const handleEthnicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.ethnicityId = parseInt(event.target.value);
		setCurrentPatient(patient);
	};
	const handleDateOfBirthChange = (value: Date | null) => {
		if (!value) return;
		const patient = { ...currentPatient };
		patient.dateOfBirth = value;
		setCurrentPatient(patient);
	};
	const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.altPhone = event.target.value;
		setCurrentPatient(patient);
	};
	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.primaryPhone = event.target.value;
		setCurrentPatient(patient);
	}
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.emailAddress = event.target.value;
		setCurrentPatient(patient);
	};

	const getAge = (dateOfBirth: Date): number => {
		const today = new Date();
		const date = new Date(dateOfBirth);
		let age = today.getFullYear() - date.getFullYear();
		const months = today.getMonth() - date.getMonth();
		if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
			age--;
		}
		return age;
	};

	const handleChangeAppliance = (appliance: RxAppliances) => {
		setSelectedAppliance(appliance);
	}

	const handleSaveChanges = async () => {
		setIsSavingPatient(true);
		await updatePerson(currentPatient, patientId);
		await upsertPatientHistory(patientId);
		setIsSavingPatient(false);
		setStep(1);
	};
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={9}>
					<Box className={boxClasses.leftBox}>
						<Grid container spacing={3}>
							<Grid item xs={10}>
								<Typography variant="h5">Patient Information</Typography>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="first-name"
									label="First Name"
									fullWidth
									value={currentPatient.firstName}
									onChange={handleFirstNameChange} />
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="last-name"
									label="Last Name"
									fullWidth
									value={currentPatient.lastName}
									onChange={handleLastNameChange} />
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="gender"
									select
									label="Gender"
									fullWidth
									value={currentPatient.genderId}
									onChange={handleGenderChange}
								>
									{genders.map((option) => (
										<MenuItem key={Gender[option]} value={Gender[option]}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Grid container spacing={3}>
									<Grid item xs={8} sm={8}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												label="Date of Birth"
												value={currentPatient.dateOfBirth ?? ''}
												openTo="day"
												disableFuture={true}
												views={['year', 'month', 'day']}
												renderInput={(params) => <TextField {...params} id="date-of-birth" label="Date of Birth" fullWidth value={currentPatient.dateOfBirth ?? ''} />}
												onChange={handleDateOfBirthChange}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item xs={4} sm={4}>
										<TextField
											id="age"
											label="Age"
											fullWidth
											disabled
											value={currentPatient.dateOfBirth ? getAge(currentPatient.dateOfBirth) : ''} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="ethnicity"
									select
									label="Ethnicity"
									fullWidth
									value={currentPatient.ethnicityId}
									onChange={handleEthnicityChange}
								>
									{ethnicities.map((option) => (
										<MenuItem key={Ethnicity[option]} value={Ethnicity[option]}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={4}></Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="email"
									label="Email"
									fullWidth
									value={currentPatient.emailAddress}
									onChange={handleEmailChange} />
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="mobile-phone"
									label="Mobile Phone"
									fullWidth
									value={currentPatient.altPhone}
									onChange={handleCellPhoneChange} />
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									id="home-phone"
									label="Home Phone"
									fullWidth
									value={currentPatient.primaryPhone}
									onChange={handlePhoneChange} />
							</Grid>
							<Grid item xs={12} sx={{ marginTop: '50px', marginBottom: '95px' }}>
								<Typography>Allergies</Typography>
								<Grid container spacing={3}>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies.Acrylic) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies.Acrylic) }}
											fullWidth>
											Acrylic
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies.Metals) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies.Metals) }}
											fullWidth>
											Metals
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies.Plastic) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies.Plastic) }}
											fullWidth>
											Plastic
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies.Latex) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies.Latex) }}
											fullWidth>
											Latex
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies.Food) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies.Food) }}
											fullWidth>
											Food
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies['Pain Medications']) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies['Pain Medications']) }}
											fullWidth>
											Pain Medications
										</Button>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant={optionChecked(allergyOptions, Allergies.Other) ? 'contained' : 'outlined'}
											onClick={(event) => { allergyhxOnChange(event, Allergies.Other) }}
											fullWidth>
											Other
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Box className={boxClasses.rightBox}>
						<Grid container spacing={3}>
							<Grid item xs={12} sx={{ display: 'flex', justifyItems: 'center' }}>
								<Typography variant="h6">Select Appliance to proceed with.</Typography>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.GUIDEVG ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.GUIDEVG)}>
									{[RxAppliances.GUIDEVG]}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.GUIDEVGX ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.GUIDEVGX)}>
									{[RxAppliances.GUIDEVGX]}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.GUIDEVW ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.GUIDEVW)}>
									{[RxAppliances.GUIDEVW]}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.VERSA ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.VERSA)}>
									{[RxAppliances.VERSA]}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.VIDA ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.VIDA)}>
									{[RxAppliances.VIDA]}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.SLEEPVIDA ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.SLEEPVIDA)}>
									{[RxAppliances.SLEEPVIDA]}
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant={selectedAppliance === RxAppliances.DNAmRNAmmRNA ? 'contained' : 'outlined'}
									fullWidth
									onClick={() => handleChangeAppliance(RxAppliances.DNAmRNAmmRNA)}>
									{[RxAppliances.DNAmRNAmmRNA]}
								</Button>
							</Grid>
							<Grid item xs={12} sx={{ textAlign: 'center' }}>
								<LoadingButton loading={isSavingPatient} disabled={!selectedAppliance} size="small" variant='contained' sx={{ borderRadius: '30px' }} onClick={handleSaveChanges}>Save + Continue</LoadingButton>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};
export default observer(StepOne);