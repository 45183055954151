import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { DataGridPro, GridFilterModel, useGridApiRef } from '@mui/x-data-grid-pro';
import '../../scss/_DataGrid.scss';
import { DataGridBL } from './DataGridBL';
import { toJS } from 'mobx';

window.moment = moment;

const gridStyle = { height: '100%', marginBottom: 10, marginLeft: 5, marginRight: 5, textAlign: 'center' };
interface IProps {
    data: any;
    size?: "small" | "large",
    pageSize?: number;
    columns: any;
    hideFooter?: boolean;
    customFooter?: any;
    customSorting?: any;
    getRowId?: any;
    loading?: boolean;
    pagination?: boolean;
    rowCount?: number;
    serverSidePagination?: boolean;
    onPageChange?: (newPage) => void;
    onFilterChange?: (filterModel: GridFilterModel) => void;
    rowEditCommit?: (id, key, value) => void;
}
const DataGrid: React.FC<IProps> = (props: IProps) => {
    let gridBL: DataGridBL = new DataGridBL();
    const apiRef = useGridApiRef();

    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(5);

    useEffect(() => {
        gridBL.apiRef = apiRef;
    }, []);

    useEffect(() => {
        if (props.data) {
            setRows(toJS(props.data));
        }
    }, [props.data]);

    const handleRowEditCommit = (params) => {
        if (props.rowEditCommit)
            props.rowEditCommit(params.id, params.field, params.value);
    };

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        if(props.onFilterChange){
            props.onFilterChange(filterModel);
        }
      }, []);

    return (
        <Grid id="datagrid_grid" className={"dataGrid" + (props.size == "small" ? "" : " standard")} sx={{ gridStyle }}>
            <DataGridPro
                experimentalFeatures={{ newEditingApi: true }}
                rowCount={props.rowCount ? props.rowCount : undefined}
                paginationMode={props.serverSidePagination ? (props.serverSidePagination ? "server" : "client") : "client"}
                filterMode={props.serverSidePagination ? (props.serverSidePagination ? "server" : "client") : "client"}
                page={page}
                apiRef={apiRef}
                className="dataGrid"
                pagination={props.pagination ? props.pagination : undefined}
                onFilterModelChange={onFilterChange}
                onCellFocusOut={handleRowEditCommit}
                columns={props.columns ? props.columns : []}
                components={props.customFooter ? { Footer: props.customFooter } : undefined}
                hideFooter={props.hideFooter}
                initialState={{
                    sorting: props.customSorting ? props.customSorting : undefined,
                }}
                getRowId={props.getRowId}
                loading={props.loading}
                rows={rows ? rows : []}
                pageSize={props.pageSize ? props.pageSize : 15}
                onPageChange={(newPage) => {
                    setPage(newPage);
                    if (props.onPageChange) {
                        props.onPageChange(newPage);
                    }
                }}
                onCellKeyDown={(_cell, event) => {
                    if (event.key === ' ') {
                        event.stopPropagation()
                    }
                }
                }
            />
        </Grid>
    );
};

export default observer(DataGrid);
