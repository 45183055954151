// Vendors
import React, { FC, useEffect } from 'react';
import { Grid } from '@mui/material';

// Components
import UploadProcessedImageDragDrop from './UploadProcessedImageDragDrop';

// Entities
import PatientImage from '../../../entities/PatientImage';
import DictionaryListItem from '../../../entities/DictionaryListItem';
import Submission from '../../../entities/Submission';

// Store
import { useMainStoreContext } from '../../../stores/MainStore';

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from '../../../api/GlobalUtils';

const style = {
    top: 0,
    left: 0,
    width: '500px',
    height: '500px',
};

const fileTypes = ['JPG', 'PNG'];
interface IGIProps { }

export const AIReportProcessedImages: FC<IGIProps> = observer(({ }) => {
    const { submission } = useMainStoreContext().aiReportEntryStore;
    const { extraoralImageType, patientImages, setPatientImages } = useMainStoreContext().createPatientStore;
    const [currentSelectedType, setCurrentSelectedType] = React.useState<DictionaryListItem>(
        new DictionaryListItem({
            id: 0,

            name: '',
            listItemKey: '',
            value: '',
            isActive: true,
            dictionaryListId: 0,
        }),
    );
    const [processedImageTypes, setProcessedImageTypes] = React.useState<DictionaryListItem[]>([]);

    const [imgList, setProcessedImgList] = React.useState<any[]>([]);

    const handleonClick = (data64String, dataPathUrl, imageType) => {
        var patientImageToSet: PatientImage = {
            id: 0,
            imageType: imageType,
            is3d: false,
            data64String: data64String,
            data64Url: dataPathUrl,
            notes: '',
            pathUrl: dataPathUrl ? dataPathUrl : 'blank',
        };

        var currentPatientImageArray = patientImages.filter(x => x.imageType.id !== imageType.id);
        currentPatientImageArray.push(patientImageToSet);
        setPatientImages(currentPatientImageArray);
    };

    useEffect(() => {
        setProcessedImageTypes(GlobalUtils.getProcessedImageTypes());
    }, []);

    return (
        <Grid className="modalPageHeight autoScroll" paddingBottom={5}>
            {/* <Grid container className="autoScroll"> */}
            <Grid xs={12} paddingTop={2}>
                <UploadProcessedImageDragDrop
                    type="multi"
                    parentCallback={handleonClick}
                    typeOptions={processedImageTypes}
                />
            </Grid>
        </Grid>
    );
});
