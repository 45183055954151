import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography } from "@mui/material";
import { boxStyles } from "../styles";
import { useMainStoreContext } from '../../../../stores/MainStore';
import { observer } from 'mobx-react';
const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));

const StepTwo = () => {
	const classes = styles();
	const boxClasses = boxStyles();
	const {
		setStep,
		currentPatient,
		selectedLab,
		selectedAppliance,
		isUpdating,
		fabricationTime,
		setFabricationTime
	} = useMainStoreContext().prescriptionStore;

	const { selectedAllergyList } = useMainStoreContext().patientEditStore;

	const handleNext = () => {
		setStep(2);
	};
	const handleBack = () => {
		setStep(0);
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Box className={boxClasses.leftBox}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									Fabrication Time
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Button
									variant={fabricationTime === 'Standard' ? 'contained' : 'outlined'}
									onClick={() => setFabricationTime('Standard')}
									color="primary">
									Standard
								</Button>
							</Grid>
							<Grid item xs={2}>
								<Button
									variant={fabricationTime === 'Expedited' ? 'contained' : 'outlined'}
									onClick={() => setFabricationTime('Expedited')}
									color="primary">
									Expedited
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									The Vivos certified lab you have selected has a total turnaround time of approximately&nbsp;
									<b><u>14 calendar days for standard production, and 14 calendar days for rushed production.</u></b>&nbsp;
									Please note that these turnaround times can vary from provider and case and is based on the assumption that the lab will receive a complete set of records in a timely manner in order to meet these turnaround times.&nbsp;
									If you have any questions about our turnaround times, please fell free to reach out to us at rx@vivoslife.com or 720-738-3663.&nbsp;
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Lab:</b> {selectedLab.name}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> {fabricationTime}
								</Typography>
							</Grid>
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							{!isUpdating && <Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>}
							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								sx={{ borderRadius: '30px', margin: '20px' }}>
								Save + Continue
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>
	)
};
export default observer(StepTwo);