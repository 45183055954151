import CssNumberField from "../CssNumberField";
import CssTextField from "../CssTextField";
import Item from "../Item";
import { useMainStoreContext } from '../../stores/MainStore';
import { observer } from "mobx-react";
import * as React from 'react';
import { Gateway } from '../../api/Gateway';
import {
  Grid,
  FormControl,
  FormHelperText,
  Typography,
  Box,
  TextField
} from "@mui/material";



const GeneralSessionNotes = (props) => {
  const { currentAppointmentNote, setCurrentAppointmentNoteProp, currentSession, initialSessionId, currentPatientId, idChiefComplaint } = useMainStoreContext().createAppointmentNotesStore;
  const [dataInitialEvaluation, setDataInitialEvaluation] = React.useState('');

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    var url = "/questionaire/response/" + initialSessionId + "/" + currentPatientId + "/" + idChiefComplaint;

    Gateway.get(url)
      .then((respData) => {
        setDataInitialEvaluation(respData['response']);

      })
  }

  return (
    <Grid container paddingLeft={4}>
      <Grid item xs={12} lg={4} padding={2}>
        <Box margin={1} padding={1}>
          <Typography variant="h4">
            Original Chief Complaint
          </Typography>
          <TextField
            fullWidth
            multiline
            minRows={5}
            maxRows={5}
            disabled
            variant="outlined"
            // label="New Chief Complaint"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentAppointmentNoteProp(event.target.value, 'newChiefComplaint');
            }}
            value={dataInitialEvaluation}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4} padding={2}>
        <Box margin={1} padding={1}>
          <Typography variant="h4">
            Updated Chief Complaint
          </Typography>
          <TextField
            fullWidth
            inputProps={{
              style: {
                color: currentAppointmentNote['newChiefComplaint']?.markAsEdited ? 'red' : 'Black',
              },
            }}
            multiline
            minRows={5}
            maxRows={5}
            variant="outlined"
            // label="New Chief Complaint"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentAppointmentNoteProp(event.target.value, 'newChiefComplaint');
            }}
            value={currentAppointmentNote['newChiefComplaint']?.response}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4} padding={2}>
        <Box margin={1} padding={1} >
          <Typography variant="h4" paddingBottom={1}>
            Appliance Compliance
          </Typography>
          <Grid container paddingLeft={2} spacing={1} paddingBottom={1} alignItems={'center'}>
            <Grid item xs={4}>
              <Typography variant="h6" textAlign={'right'}>
                Daytime Wear:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    color: currentAppointmentNote['daytimeWeardays']?.markAsEdited ? 'red' : 'Black',
                  },
                }}
                variant="outlined"
                label="Days"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'daytimeWeardays');
                }}
                value={parseInt(currentAppointmentNote['daytimeWeardays']?.response)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    color: currentAppointmentNote['daytimeWearhours']?.markAsEdited ? 'red' : 'Black',
                  },
                }}
                variant="outlined"
                label="Hours"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'daytimeWearhours');
                }}
                value={parseInt(currentAppointmentNote['daytimeWearhours']?.response)}
              />
            </Grid>
          </Grid>
          <Grid container paddingLeft={2} spacing={1} alignItems={'center'}>
            <Grid item xs={4}>
              <Typography variant="h6" textAlign={'right'}>
                Nighttime Wear:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    color: currentAppointmentNote['nighttimeWeardays']?.markAsEdited ? 'red' : 'Black',
                  },
                }}
                variant="outlined"
                label="Days"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'nighttimeWeardays');
                }}
                value={parseInt(currentAppointmentNote['nighttimeWeardays']?.response)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    color: currentAppointmentNote['nighttimeWearhours']?.markAsEdited ? 'red' : 'Black',
                  },
                }}
                variant="outlined"
                label="Hours"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'nighttimeWearhours');
                }}
                value={parseInt(currentAppointmentNote['nighttimeWearhours']?.response)}
              />
            </Grid>
          </Grid>


          {/* <TextField
              fullWidth
              style={{ width: '100%', height: '100px' }}
              inputProps={{
                style: {
                  color: currentAppointmentNote['newChiefComplaint']?.markAsEdited ? 'red' : 'Black',
                },
              }}
              multiline
              minRows={3}
              maxRows={3}
              variant="outlined"
              // label="New Chief Complaint"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentAppointmentNoteProp(event.target.value, 'newChiefComplaint');
              }}
              value={currentAppointmentNote['newChiefComplaint']?.response}
            /> */}
        </Box>
      </Grid>
      {/* <Grid item xs={6}>
          <Grid container direction={"row"} className="subheader" ><Grid>New Chief Complaint</Grid></Grid>
          <FormControl >
            <Item>
              <CssTextField
                variant="outlined"
                className={currentAppointmentNote['newChiefComplaint']?.markAsEdited ? "TextBox redTextNotes" : "TextBox"}
                size="medium"
                multiline
                minRows="4"
                required={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value, 'newChiefComplaint');
                }}
                value={currentAppointmentNote['newChiefComplaint']?.response}
              />
            </Item>
            <FormHelperText>{""}</FormHelperText>
          </FormControl>
        </Grid> */}
      {/* <Grid container direction={"row"} className="subheader" ><Grid>Chief Complaint</Grid></Grid>
        <FormControl >
          <Item>
            <CssTextField
              variant="outlined"
              className="TextBox"
              size="medium"
              multiline
              minRows="4"
              disabled
              required={false}
              value={dataInitialEvaluation}
            />
          </Item>
          <FormHelperText>{""}</FormHelperText>
        </FormControl> */}
    </Grid>
  )
}
export default observer(GeneralSessionNotes);