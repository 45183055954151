import { Close } from '@material-ui/icons';
import { Box, Fade, Grid, IconButton, Modal, AppBar, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SurveyCreatorWidget } from './Creator';

const style = {
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        height: '100vh',
        maxHeight: 1000,
    },
    header: {
        padding: '12px 0',
        borderBottom: '1px solid darkgrey',
    },
    content: {
        padding: 12,
        overflow: 'scroll',
    },
};

interface ComponentProps {
    open: boolean;
    onClose: (onClose: boolean) => void;
    questions: any;
}

const AddSectionsModal = (props: ComponentProps) => {
    const { open, onClose, questions } = props;
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleOnClick = () => {
        onClose(false);
    };

    return (
        <Modal open={isOpen} className="modalContainer" style={{height:'100vh'}}>
            <Fade in={isOpen}>
                <Box className="modalQuestion">
                    <Grid container direction="row" justifyContent={'space-between'}>
                        <AppBar position="static" className="modalHeader" color="inherit">
                            <Toolbar>
                                <Typography
                                    id={'modal.addSections.title'}
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                    className="title"
                                >
                                    {'Add Section'}
                                </Typography>
                                <IconButton id="modal.addSections.btn.close" onClick={handleOnClick}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <br></br>
                    <SurveyCreatorWidget onClose={setOpen} questions={questions} />
                </Box>
            </Fade>
        </Modal>
    );
};

export default observer(AddSectionsModal);
