import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid, styled, TextField, Tooltip, IconButton, MenuItem } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import _ from "lodash";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
);

const fileTypes = ["PDF", "DOC", "DOCX"];
const MAXWIDTH = 250;
const MAXHEIGHT = 200;
interface IUploaderProps {
    index: number;
    docIndex: number;
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    handleOptionSelect(event: any, oldOption: number, index: number, isRemoval: boolean): any;
    handleRemove(index: number): any;
    typeOptions: any[];
    selectedOptions: number[];
    file: any;
    docOption?: number;
    fileName: string;
    setFiles: any;
}

const DocUploader: React.FC<IUploaderProps> =
    ({ file, fileName, setFiles, index, docIndex, typeOptions, selectedOptions, handleRemove, handleOptionSelect, parentCallback, docOption }) => {
        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [options, setOptions] = React.useState<any[]>([]);
        const [option, setOption] = React.useState<number>(-1);
        const [hasLoaded, setHasLoaded] = React.useState(false);

        useEffect(() => {
            setOptions(typeOptions);
            generateOptions(typeOptions, selectedOptions);
            setHasLoaded(true);
            setOption(docOption ? docOption : (file && file.imageType ? file.imageType.id : -1));
        }, [])

        useEffect(() => {
            generateOptions(typeOptions, selectedOptions);
        }, [index])

        const generateOptions = (list: any[], selectedList: number[]) => {
            var newList = _.map(list, (option) => {
                var index = _.findIndex(selectedList, (l) => { return option.id === l })
                return (
                    <MenuItem disabled={index >= 0} value={option.id}>{option.name}</MenuItem>)
            })
            setOptions(newList);
        }

        const handleOptionChange = (event: any) => {
            var opts = handleOptionSelect(event, option, docIndex, false);
            generateOptions(typeOptions, opts);
            setOption(Number(event.target.value));
        }

        return (
            hasLoaded ?
                <Grid key={index} container direction="column" paddingTop={2}>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        <Tooltip title={'Delete'} arrow>
                            <IconButtonWrapper
                                style={{
                                    position: 'absolute',
                                    top: "7px",
                                    right: "45px",
                                }}
                                sx={(theme) => ({
                                    ml: 1,
                                    backgroundColor: theme.colors.error.lighter,
                                    color: theme.colors.error.main,
                                    transition: theme.transitions.create(['all']),

                                    '&:hover': {
                                        backgroundColor: theme.colors.error.main,
                                        color: theme.palette.getContrastText(
                                            theme.colors.error.main
                                        )
                                    }
                                })
                                }
                                onClick={(event: any) => {
                                    handleOptionSelect(event, option, docIndex, true);
                                    handleRemove(docIndex);
                                }}
                            >
                                <DeleteTwoToneIcon fontSize="small" />
                            </IconButtonWrapper>
                        </Tooltip>
                        <Grid container className="uploadImgContainer"
                            justifyContent={"center"} alignContent="center"
                            sx={{ height: MAXHEIGHT, width: MAXWIDTH, border: '1px solid #7E9DBB' }}>
                            <Grid item><InsertDriveFileIcon className="uploadFile" /></Grid>
                            <Grid item> {fileName}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingTop={2} marginLeft={2} paddingLeft={1} width={MAXWIDTH}>
                        {/* <Grid container justifyContent={"flex-end"}> */}
                        <TextField
                            label="Patient Form Name"
                            select
                            fullWidth
                            onChange={handleOptionChange}
                            value={option}
                        >
                            {options}
                        </TextField>

                        {/* </Grid> */}
                    </Grid>
                </Grid> : null
        )
    }
export default observer(DocUploader);