// Vendors
import React from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    Grid,
    Avatar,
    MenuList,
    MenuItem,
    ListItemText,
    useTheme
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';

// Appliance Content
import DNACard from './ApplianceComponents/dna';
import MrnaCard from './ApplianceComponents/mrna';
import MmrnaCard from './ApplianceComponents/mmrna';
import GuideCard from './ApplianceComponents/guide';
import PExCard from './ApplianceComponents/pex';
import VidaCard from './ApplianceComponents/vida';
import VidaSleepCard from './ApplianceComponents/vidasleep';
import VersaCard from './ApplianceComponents/versa';
import EmaNowCard from './ApplianceComponents/emanow';
import RescueCard from './ApplianceComponents/rescue';
import GeneralCard from './ApplianceComponents/general';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);

const MenuListWrapperRounded = styled(MenuList)(
    ({ theme }) => `
      & .MuiMenuItem-root {
          border-radius: 50px;
          padding: ${theme.spacing(1, 1, 1, 2.5)};
          margin-bottom: 2px;
          position: relative;
          color: ${theme.colors.alpha.black[70]};
  
          &.Mui-selected,
          &:hover,
          &.MuiButtonBase-root:active {
              background: ${theme.colors.alpha.black[10]};
              color: ${theme.colors.alpha.black[100]};
          }
  
          &:last-child {
              margin-bottom: 0;
          }
        }
    `
);

function VivosAppliancesContent() {
    const theme = useTheme();

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <GeneralCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <DNACard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <MrnaCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <MmrnaCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <GuideCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <PExCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <VersaCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <VidaCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <VidaSleepCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <EmaNowCard />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <RescueCard />
                </Grid>


            </Grid>

        </Card >
    );
}

export default VivosAppliancesContent;
