import { MainStore } from './MainStore';
import { observable, action, makeObservable, runInAction } from 'mobx';
import Laboratory from '../entities/Laboratory';
import Patient from '../entities/Patient';
import { Gateway } from '../api/Gateway';
import jwt_decode from "jwt-decode";
import { GlobalUtils } from '../api/GlobalUtils';
import PatientHistory from '../entities/PatientHistory';
import { OrderStatus } from '../entities/Enums';
import _ from 'lodash';

const defaultLab = {
    id: -1,
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postalCode: 0,
    phone: "",
    fax: "",
    email: "",
    fdaNumber: 0,
    shipName: "",
    createdOn: new Date(),
    createdBy: -1,
    modifiedOn: new Date(),
    modifiedBy: -1,
    isActive: false
}

const defaultPatientValues = {
    id: -1,
    orgId: -1,
    providerId: -1,
    vivosId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: new Date(),
    ethnicityId: -1,
    isProspect: false,
    isLincare: false,
    genderId: -1,
    ehrsId: -1,
    primaryPhone: "",
    altPhone: "",
    emailAddress: ""
}

const defaultAppliance = {
    id: -1,
    name: "",
    listItemKey: "",
}

const defaultHistory = {
    id: 0,
    patientId: -1,
    allergyOptions: 0,
    allergyHxNotes: "",
    cpapMadOptions: 0,
    cpapMadHxNotes: "",
    dentalOptions: 0,
    dentalHxNotes: "",
    medicalOptions: 0,
    medicalHxNotes: "",
    areasOfConcern: 0,
    areasOfConcernNotes: "",
    areasOfConcernOther: "",
    notes: "",
    personId: -1,
    userId: -1,
    vivosId: "",
    headAndNeckExamUrl: "",
    healthIntakeFormUrl: "",
}

export default class PrescriptionStore {
    mainStore?: MainStore;
    @observable private userID = 0;

    constructor(mainstore?: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles, id } = jwt_decode(token);
            this.userID = tokenData.id;
        }
    }

    @observable step: number = 0;
    @action setStep = (value: number) => {
        this.step = value;
    }

    @observable isLoading: boolean = false;
    @action setIsLoading = (value: boolean) => {
        this.isLoading = value;
    }

    @observable labs: Laboratory[] = [];
    @observable applianceSizes: any[] = [];
    @observable applianceColors: any[] = [];
    @observable verticalThickness: any[] = [];
    @observable unilateralBiteBlock: any[] = [];
    @observable selectedLab = new Laboratory(defaultLab);
    @observable isLoadingPrescriptions = false;
    @observable currentPatient = new Patient(defaultPatientValues);
    @observable selectedAppliance: string | null = null; // = new Appliance(defaultAppliance);
    @action setSelectedAppliance = (value: string | null) => {
        this.selectedAppliance = value;
    };

    @observable applianceType: string | null = null;
    @action setApplianceType = (value: string | null) => {
        this.applianceType = value;
    };

    @observable selectedApplianceType: number | null = null;
    @action setSelectedApplianceType = (value) => {
        this.selectedApplianceType = value
    }

    @observable selectedApplianceTypeName: string | null = null;
    @action setSelectedApplianceTypeName = (value) => { this.selectedApplianceTypeName = value }

    @observable selectedApplianceId: number = 0;
    @action setSelectedApplianceId = (value) => {
        this.selectedApplianceId = value
    }
    @observable isSavingPatient = false;
    @observable isSavingPrescription = false;

    @observable patientId = -1;
    @action setPatientId = (value: number) => {
        this.patientId = value;
    };
    @observable versaTypeSelected: 'Versa 9' | 'Versa Nylon EMA' = 'Versa 9'
    @action setVersaTypeSelected = (value: 'Versa 9' | 'Versa Nylon EMA') => {
        this.versaTypeSelected = value;
    }

    @observable constructionBite: 'Physical' | 'Digital' | 'None' = 'Physical'
    @action setConstructionBite = (value: 'Physical' | 'Digital' | 'None') => {
        this.constructionBite = value;
    }

    @observable constructionBiteType: 'Natural' | 'Neuromuscular' | 'Phonetic' | 'Protrusive' = 'Natural'
    @action setConstructionBiteType = (value: 'Natural' | 'Neuromuscular' | 'Phonetic' | 'Protrusive') => {
        this.constructionBiteType = value;
    }

    @observable protrusionPosition: 'Right' | 'Center' | 'Left' = 'Left'
    @action setProtrusionPosition = (value: 'Right' | 'Center' | 'Left') => {
        this.protrusionPosition = value;
    }
    @observable protrusionDistance = 0;
    @action setProtrusionDistance = (value: number) => {
        this.protrusionDistance = value;
    }

    @observable modelSource: 'Physical' | 'Digital' | 'None' = 'Physical'
    @action setModelSource = (value: 'Physical' | 'Digital' | 'None') => {
        this.modelSource = value;
    }

    @observable labAdjustBite: boolean = true;
    @action setLabAdjustBite = (value: boolean) => {
        this.labAdjustBite = value;
    }
    @observable digitalConstructionBiteEmbedded: boolean = true;
    @action setDigitalConstructionBiteEmbedded = (value: boolean) => {
        this.digitalConstructionBiteEmbedded = value;
    }

    @action setLabs = (newValue: Laboratory[]) => { this.labs = newValue }
    @action setApplianceSizes = (newValue) => { this.applianceSizes = newValue }
    @action setApplianceColors = (newValue) => { this.applianceColors = newValue }
    @action setVerticalThickness = (newValue) => { this.verticalThickness = newValue }
    @action setUnilateralBiteBlock = (newValue) => { this.unilateralBiteBlock = newValue }

    @action setSelectedLab = (lab) => {
        this.selectedLab = lab;
    }

    @observable shippingMethodSelected: 'Standard' | 'Expedited' = 'Standard'
    @action setShippingMethodSelected = (value: 'Standard' | 'Expedited') => {
        this.shippingMethodSelected = value;
    }

    @observable speakWithLab: boolean = false;
    @action setSpeakWithLab = (value: boolean) => {
        this.speakWithLab = value;
    }

    @observable applianceConstructionDiagramFilename: string | null = null;
    @action setApplianceConstructionDiagramFilename = (value) => {
        this.applianceConstructionDiagramFilename = value;
    }

    @action setCurrentPatient = (patient) => {
        this.currentPatient = patient;
    }

    @observable showPrescriptionWizard: boolean = false;
    @action setshowPrescriptionWizard = (value: boolean) => { this.showPrescriptionWizard = value; }

    @observable isUpdating: boolean = false;
    @action setIsUpdating = (value: boolean) => { this.isUpdating = value; }

    @observable prescriptionData: any[] = [];
    @action setPrescriptionData = newPrescriptionData => (this.prescriptionData = newPrescriptionData);

    @observable prescription: any;
    @action setPrescription = (value: any) => {
        this.prescription = value;
        this.setSelectedLab(this.labs.find((lab) => lab.id === this.prescription.labId));
        this.setSelectedAppliance(this.prescription.appliance.name);
    }

    @observable selectedApplianceSize: any = {};
    @action setSelectedApplianceSize = (newValue) => (this.selectedApplianceSize = newValue);

    @observable selectedApplianceColor: any = {};
    @action setSelectedApplianceColor = newValue => (this.selectedApplianceColor = newValue);

    @observable constructionComments: string = '';
    @action setConstructionComments = newValue => (this.constructionComments = newValue);

    @observable selectedVerticalThickness: any = {};
    @action setSelectedVerticalThickness = newValue => (this.selectedVerticalThickness = newValue);

    @observable selectedCustomThickness: number = 5;
    @action setSelectedCustomThickness = newValue => (this.selectedCustomThickness = newValue);

    @observable selectedBiteLocation: any = {};
    @action setSelectedBiteLocation = newValue => (this.selectedBiteLocation = newValue);

    @action reset = () => {
        this.setSelectedLab(defaultLab);
        this.setSelectedAppliance(null);
        this.setFabricationTime('Standard');
        this.setApplianceConstructionDiagramFilename(null);
        this.setCurrentPatient(defaultPatientValues);
        this.setVersaTypeSelected('Versa 9');
        this.setConstructionBite('Physical');
        this.setConstructionBiteType('Natural');
        this.setProtrusionPosition('Left');
        this.setProtrusionDistance(0);
        this.setModelSource('Physical');
        this.setLabAdjustBite(true);
        this.setDigitalConstructionBiteEmbedded(true);
        this.setShippingMethodSelected('Standard');
        this.setSpeakWithLab(false);
        this.setSelectedApplianceColor(null);
        this.setSelectedApplianceSize(null);
        this.setIsUpdating(false);
        this.setSelectedApplianceType(null);
        this.setConstructionComments('');
        this.step = 0;
    }
    loadPage = async (patientId: number) => {
        this.setIsLoading(true);
        this.setPatientId(patientId);
        const patient = await Gateway.get('/patient/' + patientId);
        this.setCurrentPatient(patient);
        const labs = await Gateway.get('/lab');
        this.setLabs(labs);
        this.setIsLoading(false);
    }
    @action
    setIsSavingPatient = (isLoading: boolean) => {
        this.isSavingPatient = isLoading;
    };
    
    @action
    setIsSavingPrescription = (isLoading: boolean) => {
        this.isSavingPrescription = isLoading;
    };

    currentRowToPerson = (currentRow: any) => {
        var person = {
            id: currentRow.id,
            orgId: currentRow.organizationId,
            providerId: currentRow.providerId,
            vivosId: currentRow.vivosId,
            firstName: currentRow.firstName,
            middleName: currentRow.middleName,
            lastName: currentRow.lastName,
            dateOfBirth: currentRow.dateOfBirth,
            ethnicityId: currentRow.ethnicityId,
            isProspect: currentRow.isProspect,
            isLincare: currentRow.isLincare,
            genderId: currentRow.genderId,
            ehrsId: currentRow.ehrsId,
            primaryPhone: currentRow.primaryPhone,
            altPhone: currentRow.altPhone,
            emailAddress: currentRow.emailAddress,
            isActive: currentRow.person?.isActive ?? true,
            personTypeId: currentRow.personTypeId
        };
        return person;
    };

    @action
    updatePerson = async (currentRow: any, patientId: number) => {

        var person = this.currentRowToPerson(currentRow);
        await Gateway.post(`/patient/person/${patientId}`, person);
        this.setIsSavingPatient(false);
    };

    @action createPrescription = async () => {

        var newPrescription = {
            "Id": 0,
            "RxId": this.currentPatient.vivosId,
            "LabId": this.selectedLab.id,
            "ProviderId": this.currentPatient.providerId,
            "IsActive": true,
            "PatientId": this.patientId,
            "SubmittedDate": Date.now(),
            "DiagramURL": this.applianceConstructionDiagramFilename,
            "Appliance": {
                "ApplianceType": this.selectedApplianceType,
                "PatientId": this.patientId,
                "ColorId": this.selectedApplianceColor?.id,
                "SizeId": this.selectedApplianceSize?.id,
                "Name": this.selectedAppliance,
                "VerticalThicknessId": this.selectedVerticalThickness?.id,
                "CustomThickness": this.selectedCustomThickness,
                "UnilateralBiteBlockId": this.selectedBiteLocation?.id
            },
            "Order": {
                "OrderStatusId": OrderStatus.Placed,
                "PatientId": this.patientId
            }
        }
        var result = await Gateway.post(`/prescription/${this.userID}`, newPrescription);
        this.setPrescription(result);
        return result;
    }

    @action updatePrescription = async (checkout?) => {
        if (_.isUndefined(checkout?.order?.orderNumber) || _.isNull(checkout?.order?.orderNumber)) {
            this.prescription.labId = this.selectedLab.id;
            this.prescription.diagramUrl = this.applianceConstructionDiagramFilename;
            this.prescription.appliance.colorId = this.selectedApplianceColor?.id;
            this.prescription.appliance.sizeId = this.selectedApplianceSize?.id;
            this.prescription.appliance.verticalThicknessId = this.selectedVerticalThickness?.id;
            this.prescription.appliance.customThickness = this.selectedCustomThickness;
            this.prescription.appliance.unilateralBiteBlock = this.selectedBiteLocation?.id;
            this.setshowPrescriptionWizard(false);
            this.setApplianceConstructionDiagramFilename(this.prescription.diagramUrl);

        } else {
            this.prescription.order.orderStatusId = OrderStatus.Paid;
            this.prescription.order.externalECommerceOrderId = checkout.order.id;
            this.prescription.order.externalECommerceOrderNumber = checkout.order.orderNumber.toString();
            _.forEach(checkout.order.lineItems, lineItem => {
                var orderDetail = {
                    externalEcommerceItemId: lineItem.variant.id,
                    externalEcommerceTitle: lineItem.variant.title
                }
                this.prescription.order.orderDetails.push(orderDetail);
            })
        }
        this.setPrescription(await Gateway.put(`/prescription/${this.userID}`, this.prescription));
    }

    @action
    getPrescriptionsByPatientId = async (patientId) => {
        this.prescriptionData.length = 0;
        runInAction(() => {
            this.isLoadingPrescriptions = true;
        });
        var url = `/prescription/patient/${patientId}`;
        const resp = await Gateway.get(url);

        runInAction(() => {
            this.isLoadingPrescriptions = false;
            this.prescriptionData = resp.map(data => {
                return data;
            });
        });
    };

    @action
    getPrescription = async (prescriptionId) => {
        var url = `/prescription/${prescriptionId}`;
        this.setPrescription(await Gateway.get(url));
    };

    @action
    loadGuideSeries = async () => {
        GlobalUtils.getApplianceSizes().then(resp => {
            this.setApplianceSizes(resp);
        });
        GlobalUtils.getApplianceColors().then(resp => {
            this.setApplianceColors(resp);
        });
    }

    @action
    loadVidaConstructionValues = async () => {
        GlobalUtils.getVerticalThickness().then(resp => {
            this.setVerticalThickness(resp);
        })
        GlobalUtils.getUnilateralBiteBlock().then(resp => {
            this.setUnilateralBiteBlock(resp);
        });
    }

    @action
    setGuidesAttributes = async () => {
        this.setSelectedApplianceSize(this.applianceSizes.find(x => x.id === this.prescription.appliance.sizeId));
        this.setSelectedApplianceColor(this.applianceColors.find(x => x.id === this.prescription.appliance.colorId));
    }

    @action
    setConstructionAttributes = async () => {
        this.setSelectedVerticalThickness(this.verticalThickness.find(x => x.id === this.prescription.appliance.verticalThicknessId));
        this.setSelectedBiteLocation(this.unilateralBiteBlock.find(x => x.id === this.prescription.appliance.unilateralBiteBlockId));
        this.setSelectedCustomThickness(this.prescription.appliance.customThickness);
    }

    @observable fabricationTime = 'Standard';
    @action setFabricationTime = (value: string) => {
        this.fabricationTime = value;
    };

    @action optionChecked = (options: number, option: number): boolean => {
        return (options & option) === option;
    }

    @observable patientHistory = new PatientHistory(defaultHistory);
    @action setPatientHistory = (value: PatientHistory) => {
        this.patientHistory = value;
    };

    @observable allergyOptions = this.patientHistory.allergyOptions;
    @action setAllergyOptions = (newValue: number) => { this.allergyOptions = newValue }

    @action allergyhxOnChange = (event, value) => {
        if (event.target.checked) {
            this.setAllergyOptions(this.allergyOptions | Number.parseInt(value));
        } else {
            this.setAllergyOptions(this.allergyOptions ^ Number.parseInt(value));
        }
    }
}