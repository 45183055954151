import { action, makeAutoObservable, observable } from "mobx";
import { MainStore } from "./MainStore";
import jwt_decode from 'jwt-decode';

export default class MainDashboardStore {

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { id } = jwt_decode(token);
            this.userID = tokenData.id;
        }
    }

    @observable mainStore: MainStore;
    @observable pageTitle = "Dashboard";
    @observable userID: number = 0;
    @observable isLeftNavExpanded: boolean = false;  

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    clearData = () => {

    };

    getSearchData = (searchParam: string): any => {

    };

}

