import { MainStore } from './MainStore';
import { action, makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../api/Gateway';

import DictionaryListItem from '../entities/DictionaryListItem';

import mock from '../components/PatientProfile/PatientTreatmentPlanning/mock.json';
import _ from 'lodash';

export interface TreatmentPlan {
    id: number;
    name: string;
    planId: number;
}

interface TreatmentPlanToDiagnosis {
    id: number;
    conditionId: number;
    conditionValueA?: number;
    conditionValueB?: number;
    name: string;
    treatmentObjectiveName: string;
    treatmentOptionIds: number;
    treatmentPlan: any[];
}

export class PatientTreatmentStore {
    mainStore: MainStore;

    constructor(mainStore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    userId = 0;
    roles: any[] = [];
    visitId = 0;
    patientId = 0;
    treatmentId = 0;

    complaints: number = 0;
    complaintsNotes: string = '';

    sleepDiagnosis: any = [];
    sleepCondition: DictionaryListItem[] = [];
    sleepNotesDiagnosis = '';
    extraoralDiagnosis: any = [];
    extraoralCondition: DictionaryListItem[] = [];
    extraoralDiagnosisNotes = '';
    intraoralDiagnosis: any = [];
    intraoralCondition: DictionaryListItem[] = [];
    intraoralRelationship: DictionaryListItem[] = [];
    molarRelationshipsRight: any[] = [];
    molarRelationshipsLeft: any[] = [];
    intraoralDiagnosisNotes = '';
    cbctDiagnosis: any = [];
    cbctCondition: DictionaryListItem[] = [];
    cbctDiagnosisNotes = '';

    primaryData: DictionaryListItem[] = [];
    primaryThearapy: any = [];
    primaryBitwise: number = 0;
    primaryThearapyNotes = '';
    suplementalTherapies: any = [];
    suplementalBitwise: number = 0;
    suplementalTherapiesNotes = '';
    dentalPlan: any = [];
    dentalPlanBitwise: number = 0;
    dentalPlanNotes = '';
    collaborationPlan: any = [];
    collaborationPlanBitwise: number = 0;
    collaborationPlanNotes = '';
    surgeryPlan: any = [];
    surgeryPlanBitwise: number = 0;
    surgeryPlanNotes = '';
    otherPlan: any = [];
    otherPlanBitwise: number = 0;
    otherPlanNotes = '';

    treatmentObjectives: DictionaryListItem[] = [];

    treatmentPlan: TreatmentPlan[] = [];
    newTreatmentPlan: TreatmentPlan[] = [];

    treatmentPlanToDiagnosis: TreatmentPlanToDiagnosis[] = [];

    treatmentConditions: any = [];
    estimatedTreatmentTime: any = [];

    phase2TreatmentOptions: number = 0;
    phase2TreatmentOptionsNotes = '';

    setPrimaryData = (data: DictionaryListItem[]) => {
        this.primaryData = data;
    };

    setTreatmentObjectives = (value: DictionaryListItem[]) => {
        this.treatmentObjectives = value;
    };

    setNewTreatmentPlanToDiagnosis = (data: any) => {
        this.treatmentPlanToDiagnosis = data;
    };

    setTreatmentPlan = (treatmentPlan: TreatmentPlan[]) => {
        this.treatmentPlan = treatmentPlan;
    };

    setComplaints = (value: number) => {
        this.complaints = value;
    };

    setComplaintsNotes = (value: string) => {
        this.complaintsNotes = value;
    };

    setPatientId = (id: number) => {
        this.patientId = id;
    };

    setSleepDiagnosis = (sleep: any) => {
        this.sleepDiagnosis = sleep;
    };

    setSleepCondition = (sleep: DictionaryListItem[]) => {
        this.sleepCondition = sleep;
    };

    setSleepNotesDiagnosis = (notes: any) => {
        this.sleepNotesDiagnosis = notes;
    };

    setExtraoralDiagnosis = (extraoral: any) => {
        this.extraoralDiagnosis = extraoral;
    };

    setExtraoralCondition = (extraoral: DictionaryListItem[]) => {
        this.extraoralCondition = extraoral;
    };

    setExtraoralDiagnosisNotes = (notes: any) => {
        this.extraoralDiagnosisNotes = notes;
    };

    setIntraoralDiagnosis = (intraoral: any) => {
        this.intraoralDiagnosis = intraoral;
    };

    setIntraoralCondition = (intraoral: DictionaryListItem[]) => {
        this.intraoralCondition = intraoral;
    };

    setIntraoralRelationShip = (intraoral: DictionaryListItem[]) => {
        this.intraoralRelationship = intraoral;
    };

    setMolarRelationshipsRight = (molar: any[]) => {
        this.molarRelationshipsRight = molar;
    };

    setMolarRelationshipsLeft = (molar: any[]) => {
        this.molarRelationshipsLeft = molar;
    };

    setIntraoralDiagnosisNotes = (notes: any) => {
        this.intraoralDiagnosisNotes = notes;
    };

    setCbctDiagnosis = (cbct: any) => {
        this.cbctDiagnosis = cbct;
    };

    setCbctCondition = (cbct: DictionaryListItem[]) => {
        this.cbctCondition = cbct;
    };

    setCbctDiagnosisNotes = (notes: any) => {
        this.cbctDiagnosisNotes = notes;
    };

    setPrimaryThearapy = (primary: any) => {
        this.primaryThearapy = primary;
    };

    setPrimaryBitwise = (bitwise: number) => {
        this.primaryBitwise = bitwise;
    };

    setPrimaryThearapyNotes = (notes: any) => {
        this.primaryThearapyNotes = notes;
    };

    setSuplementalTherapies = (suplemental: any) => {
        this.suplementalTherapies = suplemental;
    };

    setSuplementalBitwise = (bitwise: number) => {
        this.suplementalBitwise = bitwise;
    };

    setSuplementalTherapiesNotes = (notes: any) => {
        this.suplementalTherapiesNotes = notes;
    };

    setDentalPlan = (dental: any) => {
        this.dentalPlan = dental;
    };

    setDentalPlanBitwise = (bitwise: number) => {
        this.dentalPlanBitwise = bitwise;
    };

    setDentalPlanNotes = (notes: any) => {
        this.dentalPlanNotes = notes;
    };

    setCollaborationPlan = (collaboration: any) => {
        this.collaborationPlan = collaboration;
    };

    setCollaborationPlanBitwise = (bitwise: number) => {
        this.collaborationPlanBitwise = bitwise;
    };

    setCollaborationPlanNotes = (notes: any) => {
        this.collaborationPlanNotes = notes;
    };

    setSurgeryPlan = (surgery: any) => {
        this.surgeryPlan = surgery;
    };

    setSurgeryPlanBitwise = (bitwise: number) => {
        this.surgeryPlanBitwise = bitwise;
    };

    setSurgeryPlanNotes = (notes: any) => {
        this.surgeryPlanNotes = notes;
    };

    setOtherPlan = (other: any) => {
        this.otherPlan = other;
    };

    setOtherPlanBitwise = (bitwise: number) => {
        this.otherPlanBitwise = bitwise;
    };

    setOtherPlanNotes = (notes: any) => {
        this.otherPlanNotes = notes;
    };

    addEstimatedTreatmentTime = (data: any) => {
        this.estimatedTreatmentTime.push(data);
    };

    setEstimatedTreatment = (index: number, data: any) => {
        this.estimatedTreatmentTime[index] = data;
    };

    removeEstimatedTreatmentTime = (index: number) => {
        this.estimatedTreatmentTime.splice(index, 1);
    };

    getEstimatedTreatmentTIme = () => {
        return this.estimatedTreatmentTime;
    };

    setPhase2TreatmentOptions = (value: number) => {
        this.phase2TreatmentOptions = value;
    };

    setPhase2TreatmentOptionsNotes = (value: string) => {
        this.phase2TreatmentOptionsNotes = value;
    };

    setNewTreatmentPlan = (value: TreatmentPlan[]) => {
        this.treatmentPlan = value;
    };

    resetTreatmentPlan = () => {
        this.complaints = 0;
        this.complaintsNotes = '';
        this.sleepDiagnosis = [];
        this.sleepNotesDiagnosis = '';
        this.extraoralDiagnosis = [];
        this.extraoralDiagnosisNotes = '';
        this.intraoralDiagnosis = [];
        this.molarRelationshipsLeft = [];
        this.molarRelationshipsRight = [];
        this.intraoralDiagnosisNotes = '';
        this.cbctDiagnosis = [];
        this.cbctDiagnosisNotes = '';
        this.primaryThearapy = [];

        this.primaryBitwise = 0;
        this.primaryThearapyNotes = '';
        this.suplementalTherapies = [];
        this.suplementalBitwise = 0;
        this.suplementalTherapiesNotes = '';
        this.dentalPlan = [];
        this.dentalPlanBitwise = 0;
        this.dentalPlanNotes = '';
        this.collaborationPlan = [];
        this.collaborationPlanBitwise = 0;
        this.collaborationPlanNotes = '';
        this.surgeryPlan = [];
        this.surgeryPlanBitwise = 0;
        this.surgeryPlanNotes = '';
        this.otherPlan = [];
        this.otherPlanBitwise = 0;
        this.otherPlanNotes = '';
        this.estimatedTreatmentTime = [];
        this.phase2TreatmentOptions = 0;
        this.phase2TreatmentOptionsNotes = '';
    };

    saveTreatmentPlan = () => {
        let data: TreatmentPlan[] = [];

        this.primaryThearapy.forEach((element: any) => {
            data.push({
                id: element.id,
                name: element.name,
                planId: element.planId,
            });
        });

        this.suplementalTherapies.forEach((element: any) => {
            data.push({
                id: element.id,
                name: element.name,
                planId: element.planId,
            });
        });

        this.dentalPlan.forEach((element: any) => {
            data.push({
                id: element.id,
                name: element.name,
                planId: element.planId,
            });
        });

        this.collaborationPlan.forEach((element: any) => {
            data.push({
                id: element.id,
                name: element.name,
                planId: element.planId,
            });
        });

        this.surgeryPlan.forEach((element: any) => {
            data.push({
                id: element.id,
                name: element.name,
                planId: element.planId,
            });
        });

        this.otherPlan.forEach((element: any) => {
            data.push({
                id: element.id,
                name: element.name,
                planId: element.planId,
            });
        });

        this.treatmentPlan = data;
    };

    saveTreatmentDiagnosis = async () => {
        let planToDiagnosis: TreatmentPlanToDiagnosis[] = [];

        this.sleepDiagnosis.forEach((item: any) => {
            planToDiagnosis.push({
                id: item.id || item.conditionId,
                name: item.name || this.sleepCondition.find((element: any) => element.id === item.conditionId)?.name,
                conditionId: item.conditionId,
                treatmentObjectiveName:
                    item.treatmentObjectiveName ||
                    this.treatmentObjectives.find((element: any) => element.id === item.treatmentObjective)?.name,
                treatmentPlan: [],
                treatmentOptionIds: item.treatmentOptionIds,
            });
        });

        this.extraoralDiagnosis.forEach((item: any) => {
            planToDiagnosis.push({
                id: item.id || item.conditionId,
                name:
                    item.name || this.extraoralCondition.find((element: any) => element.id === item.conditionId)?.name,
                conditionId: item.conditionId,
                treatmentObjectiveName:
                    item.treatmentObjectiveName ||
                    this.treatmentObjectives.find((element: any) => element.id === item.treatmentObjective)?.name,
                treatmentPlan: [],
                treatmentOptionIds: item.treatmentOptionIds,
            });
        });

        this.intraoralDiagnosis.forEach((item: any) => {
            planToDiagnosis.push({
                id: item.id || item.conditionId,
                name:
                    item.name || this.intraoralCondition.find((element: any) => element.id === item.conditionId)?.name,
                conditionId: item.conditionId,
                treatmentObjectiveName:
                    item.treatmentObjectiveName ||
                    this.treatmentObjectives.find((element: any) => element.id === item.treatmentObjective)?.name,
                treatmentPlan: [],
                conditionValueA: 0,
                conditionValueB: 0,
                treatmentOptionIds: item.treatmentOptionIds,
            });
        });

        this.molarRelationshipsRight.forEach((item: any) => {
            planToDiagnosis.push({
                id: item.id || item.conditionValueB,
                name:
                    item.name ||
                    this.intraoralRelationship.find((element: any) => element.id === item.conditionValueB)?.name,
                conditionId: item.conditionId || item.conditionValueB,
                treatmentObjectiveName:
                    item.treatmentObjectiveName ||
                    this.treatmentObjectives.find((element: any) => element.id === item.treatmentObjective)?.name,
                treatmentPlan: [],
                conditionValueA: 0,
                conditionValueB: item.id || item.conditionValueB,
                treatmentOptionIds: item.treatmentOptionIds,
            });
        });

        this.molarRelationshipsLeft.forEach((item: any) => {
            planToDiagnosis.push({
                id: item.id || item.conditionValueA,
                name:
                    item.name ||
                    this.intraoralRelationship.find((element: any) => element.id === item.conditionValueA)?.name,
                conditionId: item.conditionId || item.conditionValueA,
                treatmentObjectiveName:
                    item.treatmentObjectiveName ||
                    this.treatmentObjectives.find((element: any) => element.id === item.treatmentObjective)?.name,
                treatmentPlan: [],
                conditionValueA: item.id || item.conditionValueA,
                conditionValueB: 0,
                treatmentOptionIds: item.treatmentOptionIds,
            });
        });

        this.cbctDiagnosis.forEach((item: any) => {
            planToDiagnosis.push({
                id: item.id || item.conditionId,
                name: item.name || this.cbctCondition.find((element: any) => element.id === item.conditionId)?.name,
                conditionId: item.conditionId,
                treatmentObjectiveName:
                    item.treatmentObjectiveName ||
                    this.treatmentObjectives.find((element: any) => element.id === item.treatmentObjective)?.name,
                treatmentPlan: [],
                treatmentOptionIds: item.treatmentOptionIds,
            });
        });

        this.treatmentPlanToDiagnosis = planToDiagnosis;
    };

    saveDiagnosis = async () => {
        let treatmentConditions: any = [];

        this.sleepDiagnosis.forEach((item: any) => {
            treatmentConditions.push({
                conditionId: item.conditionId,
                treatmentObjective: item.treatmentObjective,
                treatmentOptionIds: item.treatmentOptionIds
                    ? this.treatmentPlanToDiagnosis.find((x: any) => x.id === item.id)?.treatmentOptionIds
                    : item.treatmentOptionIds,
            });
        });

        this.extraoralDiagnosis.forEach((item: any) => {
            treatmentConditions.push({
                conditionId: item.conditionId,
                treatmentObjective: item.treatmentObjective,
                treatmentOptionIds: this.treatmentPlanToDiagnosis.find((x: any) => x.id === item.id)
                    ?.treatmentOptionIds,
            });
        });

        this.intraoralDiagnosis.forEach((item: any) => {
            treatmentConditions.push({
                conditionId: item.conditionId,
                treatmentObjective: item.treatmentObjective,
                treatmentOptionIds: this.treatmentPlanToDiagnosis.find((x: any) => x.id === item.id)
                    ?.treatmentOptionIds,
            });
        });

        this.cbctDiagnosis.forEach((item: any) => {
            treatmentConditions.push({
                conditionId: item.conditionId,
                treatmentObjective: item.treatmentObjective,
                treatmentOptionIds: this.treatmentPlanToDiagnosis.find((x: any) => x.id === item.id)
                    ?.treatmentOptionIds,
            });
        });

        this.molarRelationshipsRight.forEach((item: any) => {
            treatmentConditions.push({
                conditionValueA: item.id,
                treatmentObjective: item.treatmentObjective,
                treatmentOptionIds: this.treatmentPlanToDiagnosis.find((x: any) => x.id === item.id)
                    ?.treatmentOptionIds,
            });
        });

        this.molarRelationshipsLeft.forEach((item: any) => {
            treatmentConditions.push({
                conditionValueB: item.id,
                treatmentObjective: item.treatmentObjective,
                treatmentOptionIds: this.treatmentPlanToDiagnosis.find((x: any) => x.id === item.id)
                    ?.treatmentOptionIds,
            });
        });

        this.treatmentConditions = treatmentConditions;
    };

    @action
    getTreatmentOptions = () => {
        let conditions = this.primaryData;

        _.forEach(this.treatmentPlanToDiagnosis, a => {
            if (a.id <= 0) {
                a.id = 0;
            }

            let tOptions = 0;

            _.forEach(a.treatmentPlan, b => {
                let condition = _.find(conditions, c => {
                    return c.id === b;
                });
                tOptions = tOptions | (condition ? Number(condition.value) : 0);
            });
            a.treatmentOptionIds = tOptions;
        });

        return this.treatmentPlanToDiagnosis;
    };

    getNewTreatmentPlanToDiagnosis = () => {
        let conditions = this.primaryData;

        _.forEach(this.treatmentPlanToDiagnosis, a => {
            if (a.treatmentOptionIds && a.treatmentOptionIds > 0) {
                a.treatmentPlan = [];
                _.forEach(conditions, b => {
                    let value = Number(b.value);
                    if (a.treatmentOptionIds && value > 0 && (a.treatmentOptionIds & value) === value) {
                        a.treatmentPlan.push(b.id);
                    }
                });
            }
        });
        this.setNewTreatmentPlanToDiagnosis(this.treatmentPlanToDiagnosis);
    };

    saveTreatment = async () => {
        let data = {
            patientId: this.patientId,
            complaints: String(this.complaints),
            complaintNotes: this.complaintsNotes,
            sleepConditionNotes: this.sleepNotesDiagnosis,
            intraoralConditionNotes: this.intraoralDiagnosisNotes,
            externalConditionNotes: this.extraoralDiagnosisNotes,
            cbctDiagnosisDotes: this.cbctDiagnosisNotes,
            primaryTherapies: this.primaryBitwise,
            primaryTherapiesNotes: this.primaryThearapyNotes,
            supplementalTherapies: this.suplementalBitwise,
            supplementalTherapiesNotes: this.suplementalTherapiesNotes,
            dentalTherapies: this.dentalPlanBitwise,
            dentalTherapiesNotes: this.dentalPlanNotes,
            collaborationOptions: this.collaborationPlanBitwise,
            collaborationOptionsNotes: this.collaborationPlanNotes,
            surgeryOptions: this.surgeryPlanBitwise,
            surgeryOptionsNotes: this.surgeryPlanNotes,
            otherTherapies: this.otherPlanBitwise,
            otherTherapiesNotes: this.otherPlanNotes,
            phase2TreatmentOptions: this.phase2TreatmentOptions,
            other: this.phase2TreatmentOptionsNotes,
            treatmentConditions: this.treatmentConditions,
            treatmentEstimations: this.estimatedTreatmentTime,
        };

        const url = 'patient/treatment';

        await Gateway.post(url, data).then(response => {
            console.log(response);
        });
    };

    updateTreatment = async () => {
        let data = {
            patientId: this.patientId,
            complaints: this.complaints,
            complaintNotes: this.complaintsNotes,
            sleepConditionNotes: this.sleepNotesDiagnosis,
            intraoralConditionNotes: this.intraoralDiagnosisNotes,
            externalConditionNotes: this.extraoralDiagnosisNotes,
            cbctDiagnosisDotes: this.cbctDiagnosisNotes,
            primaryTherapies: this.primaryBitwise,

            primaryTherapiesNotes: this.primaryThearapyNotes,
            supplementalTherapies: this.suplementalBitwise,
            supplementalTherapiesNotes: this.suplementalTherapiesNotes,
            dentalTherapies: this.dentalPlanBitwise,
            dentalTherapiesNotes: this.dentalPlanNotes,
            collaborationOptions: this.collaborationPlanBitwise,
            collaborationOptionsNotes: this.collaborationPlanNotes,
            surgeryOptions: this.surgeryPlanBitwise,
            surgeryOptionsNotes: this.surgeryPlanNotes,
            otherTherapies: this.otherPlanBitwise,
            otherTherapiesNotes: this.otherPlanNotes,
            phase2TreatmentOptions: this.phase2TreatmentOptions,
            other: this.phase2TreatmentOptionsNotes,
            treatmentConditions: this.treatmentConditions ? this.treatmentPlanToDiagnosis : [],
            treatmentEstimations: this.estimatedTreatmentTime,
            isActive: true,
        };

        const url = `patient/treatment/${this.treatmentId}`;

        await Gateway.put(url, data).then(response => {
            console.log(response);
        });
    };

    loadTreatment = async () => {
        const url = `Patient/${this.patientId}/treatment`;

        await Gateway.getOne(url).then(response => {
            if (response) {
                let response = mock;
                this.treatmentId = response.id;
                this.primaryBitwise = response.primaryTherapies;
                this.suplementalBitwise = response.supplementalTherapies;
                this.dentalPlanBitwise = response.dentalTherapies;
                this.collaborationPlanBitwise = response.collaborationOptions;
                this.surgeryPlanBitwise = response.surgeryOptions;
                this.otherPlanBitwise = response.otherTherapies;
                this.phase2TreatmentOptions = response.phase2TreatmentOptions;
                this.phase2TreatmentOptionsNotes = response.other;
                this.complaints = response.complaints;
                this.complaintsNotes = response.complaintNotes;
                this.primaryThearapyNotes = response.primaryTherapiesNotes;
                this.sleepDiagnosis = response.treatmentConditions.filter(
                    item =>
                        item.conditionId === this.sleepCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueA ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueB ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id,
                );
                this.extraoralDiagnosis = response.treatmentConditions.filter(
                    item =>
                        item.conditionId ===
                            this.extraoralCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueA ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueB ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id,
                );
                this.intraoralDiagnosis = response.treatmentConditions.filter(
                    item =>
                        item.conditionId ===
                            this.intraoralCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueA ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueB ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id,
                );

                this.cbctDiagnosis = response.treatmentConditions.filter(
                    item =>
                        item.conditionId === this.cbctCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueA ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id &&
                        item.conditionValueB ===
                            this.cbctCondition.find(element => element.id === item.conditionId)?.id,
                );

                this.molarRelationshipsRight = response.treatmentConditions.filter(
                    item =>
                        item.conditionId ===
                        this.intraoralRelationship.find(element => element.id === item?.conditionValueA)?.id,
                );

                this.molarRelationshipsLeft = response.treatmentConditions.filter(
                    item =>
                        item.conditionId ===
                        this.intraoralRelationship.find(element => element.id === item.conditionValueB)?.id,
                );

                this.estimatedTreatmentTime = response.treatmentEstimations;
            }
        });
    };
}
