import { action, computed, makeObservable, observable } from "mobx";
import { GridRowId } from '@mui/x-data-grid-pro';

export class DataGridBL {
    private _apiRef: any;
    private _currentDetailPanel: GridRowId = -1;

    constructor() {
        makeObservable(this);
    }

    get apiRef() {
        return this._apiRef;
    }

    set apiRef(value: any) {
        this._apiRef = value;
    }

    get currentDetailPanel() {
        return this._currentDetailPanel;
    }

    set currentDetailPanel(value: GridRowId) {
        this._currentDetailPanel = value;
    }

    @action
    togglePanel = (ids: any, details: any) => {
        var rows = details.api.getExpandedDetailPanels();
        if (rows.length > 1) {
            this.currentDetailPanel = rows[1];
            details.api.toggleDetailPanel(rows[0]);
        }
    }
}
