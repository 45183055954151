import { Button, Divider, Grid, TextareaAutosize } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite"
import moment from "moment";
import React from "react";
import { FC } from "react"
import { ErfStatus } from "../../constants/ErfStatus";
import { ProductionStatus } from "../../constants/ProductionStatus";
import Claim from "../../entities/Claim";
import { useMainStoreContext } from "../../stores/MainStore";
import CssTextField from "../CssTextField";
import { ClaimType, Roles } from "../../entities/Enums";

interface IGIProps {
    claim: Claim,
    roles: any[]
}

export const LabRework: FC<IGIProps> = observer(({claim, roles}) => {
    const [dateValue, setDateValue] = React.useState<Date | null>(claim.fabricatedOn);
    const [labReworkNotes, setLabReworkNotes] = React.useState(claim.labReworkNotes);
    const [prodStatus, setProdStatus] = React.useState<string | undefined>("");
    const [erfStatus, setErfStatus] = React.useState<string | undefined> ("");
    const [erfQuote, setErfQuote] = React.useState(claim.erfamount ? claim.erfamount : 0);
    const [erfEstimate, setErfEstimate] = React.useState(claim.erfamount ? (erfQuote + (erfQuote * 0.15) + 20) : 0);
    const [labReworkRecordedBy, setlabReworkRecordedBy] = React.useState("");
    const [isAisAdmin, setIsAisAdmin] = React.useState<any>([]);
    let store = useMainStoreContext().reworkClaimStore;
    

    const updatedClaim = {
        ...claim,
        labReworkNotes : labReworkNotes,
        erfamount: erfQuote,
        fabricatedOn : dateValue ? dateValue : new Date(),
    }

    React.useEffect(() => {
        setProdStatus(ProductionStatus.find(ps => ps.code === claim.productionStatus)?.status);
        setErfStatus(ErfStatus.find(es => es.code === claim.erfstatus)?.status);
        let user = store.teamMembers.find(tm => tm.userID === store.claim.labReworkRecordedBy)
        setlabReworkRecordedBy(user ? user.firstName + " " + user.lastName : "" );
        setIsAisAdmin(roles.find(x => x.Name === Roles.AISADMIN));
    },[]);

    React.useEffect(() => {
        setErfEstimate(erfQuote + (erfQuote * 0.15) + 20 );
    },[erfQuote]);

    const saveRework = () => {
        store.updateClaimChanges(updatedClaim);
     };

    const sendReworkToVivos = () => {
        let updateClaim = updatedClaim;
        updateClaim.labReworkRecordedOn = new Date();
        updateClaim.labReworkRecordedBy = store.userID;
        store.updateClaimChanges(updateClaim);

        // TODO: Set status field.
    };

    const requestApproval = () =>{
        setErfStatus("Pending Approval"); 
        let updateClaim = updatedClaim;
        updateClaim.erfstatus = 610; //Pending approval 
        store.updateClaimChanges(updateClaim);

        //TODO: Adding Shipping Cost and notify the provider.
    }
    
    return(
        <Grid>
            <Grid xs={12} className="claim-save-button">
                <Button className="vivButton normal" onClick={() => {saveRework()}}> Save Changes </Button>
                <Button className="vivButton normal"> Generate Label </Button>
                </Grid>
            <Divider flexItem={true} orientation="horizontal" />
                <Grid xs={12} container direction={'row'} className="principal-claim-container">
                    <Grid xs={6.3}>
                        <Grid className="box-claim-container">
                            <Grid item className="claim-data-title"> Lab Rework </Grid>
                            <Grid container direction={'row'} className="claim-info">
                                <Grid xs={12}>
                                    <Grid>What manufacturing work needs to be performed to resolve this complaint? </Grid>    
                                    <TextareaAutosize 
                                    className="claim-textarea"
                                    onChange={(e) => {setLabReworkNotes(e.target.value)}}
                                    style={{ width: "-webkit-fill-available" }}
                                    minRows={4}
                                    maxRows={4}
                                    value={labReworkNotes}
                                />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="box-claim-container">
                            <Grid item className="claim-data-title"> Claim ID </Grid>
                            <Grid container direction={'row'} className="claim-info">
                                <Grid xs={12} container direction={'row'}>
                                    <Grid>Lab Rework Recorded By:</Grid>    
                                    <Grid>{labReworkRecordedBy}</Grid>
                                </Grid>                                
                                <Grid xs={12} container direction={'row'}>
                                    <Grid>Date:</Grid>    
                                    <Grid>{claim.labReworkRecordedOn && moment(claim.labReworkRecordedOn).format('MM/DD/yyyy') }</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="claim-save-button">
                            <Button className="vivButton normal invert" disabled={isAisAdmin} onClick={sendReworkToVivos}> Send Rework Results to Vivos </Button>
                        </Grid>
                    </Grid>
                <Grid xs={5.3} direction={'column'}>
                    <Grid className="box-claim-container">
                        <Grid className="claim-data-title">Rework overview </Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={6}>
                                <Grid>Tracking # Lab to Provider</Grid>
                                <Grid>{claim.trackingNoReworkToProvider}</Grid>
                            </Grid>
                            <Grid xs={6}>
                                <Grid>RX ID of New</Grid>
                                <Grid className="claim-data">{claim.rxId}</Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={6}>
                                <Grid>Date of Fabrication</Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        className="DateBox"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={dateValue}
                                        onChange={(value) => {
                                            setDateValue(value);
                                        }}
                                        renderInput={params => (
                                            <CssTextField
                                                disabled={false}
                                                {...params}
                                                sx={{
                                                    '& input': {
                                                        backgroundColor: 'white',
                                                        color: '#050505',
                                                        fontFamily: 'Proxima Nova',
                                                        fontSize: '16px',
                                                    },
                                                }}
                                                className="claim-textarea"
                                                size="small"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                </Grid>
                            <Grid xs={6}>
                                <Grid>Shipped Date</Grid>   
                                <Grid className="claim-data">{claim.shippedOn && moment(claim.shippedOn).format('MM/DD/yyyy') }</Grid>                           
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={6}>
                                <Grid>Production Status</Grid>
                                <Grid className="claim-data-title">{prodStatus}</Grid>
                            </Grid>
                            <Grid xs={6}>
                                <Grid>Warranty Remake Approval</Grid>
                                <Grid className="claim-data-title">Pending</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className="box-claim-container">
                        <Grid className="claim-data-title">Repair Only Information</Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={4}>
                                <Grid>ERF Quote </Grid>
                                <CssTextField
                                variant="outlined"
                                className="claim-textarea"
                                size="small"
                                placeholder=""
                                type="number"
                                required={true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setErfQuote(Math.max(0, parseInt(event.target.value)));
                                }}
                                value={erfQuote}
                            />
                            </Grid>
                            <Grid xs={4}>
                                <Grid>ERF Estimate</Grid>
                                <Grid className="claim-data">{erfEstimate}</Grid>
                            </Grid>
                            <Grid xs={4}>
                                <Grid>ERP Approval Status </Grid>
                                <Grid className="claim-data">{erfStatus}</Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} className="claim-info">
                            <Grid xs={4}>
                                {/* <Grid>Shipping Cost </Grid>
                                <Grid className="claim-data">$11</Grid> Is not implemented yet*/}
                            </Grid>
                            <Grid xs={4}>
                                <Grid>ERF Approval Date</Grid>
                                <Grid className="claim-data">{claim.erfapprovedOn && moment(claim.erfapprovedOn).format('MM/DD/yyyy')}</Grid>
                            </Grid>
                            <Grid xs={4}>
                                <Button className="vivButton normal invert" 
                                disabled={((claim.claimType === ClaimType.Warranty) || (claim.claimType === ClaimType.Pending))} 
                                onClick={requestApproval}> Request approval from Provider to continue </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
})