import { Grid } from "@mui/material";


function Item (props: any) {
    const { sx, ...other } = props;
    return (
        <Grid
            sx={{
                p: .2,
                m: .2,
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                fontSize: '0.875rem',
                font: "normal normal medium 16px/19px Proxima Nova",
                fontWeight: '700',
                flexDirection: "row",
                ...sx,
            }}
            {...other}
        />
    );
}
export default Item;