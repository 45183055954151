import { styled, Select, Autocomplete } from "@mui/material";

const CssAutoComplete = styled(Autocomplete)({
    '& label.Mui-focused': {
        border: "0px",
    },
    '& .MuiInput-underline:hover:before': {
        borderColor: 'white',
        border: "0px",
    },
    '& .MuiInput-underline:before': {
        borderColor: 'white',
        border: "0px",
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
        border:"0px",
      },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        border: "0px",
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
            border: "0px",
            width: "100%",
        },
        '&:hover fieldset': {
            borderColor: 'white',
            border: "0px",
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            border: "0px",
        },
    },
});

export default CssAutoComplete;