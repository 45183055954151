import React, { useState } from 'react';
import {
    Grid,
    Typography,
    Button,
    styled,
    Box,
    alpha,
    Stack,
} from '@mui/material';
import ReactQuill from "react-quill"
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

interface ButtonOptionsProps {
    title: string;
    valueString: string;
    valueNoteString: string;
    currentAppointmentNote: any;
    setCurrentAppointmentNoteProp: any;
    optionStrings: string[];
    buttonColors: any[];
    includeNotes: boolean;
}

const BoxComposed = styled(Box)(
    () => `
    position: relative;
  `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
    position: relative;
    z-index: 7;
    display: flex;
    align-items: center;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedBg = styled(Box)(
    () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const MyoGradingComponent: React.FC<ButtonOptionsProps> = ({
    title,
    valueString,
    valueNoteString,
    currentAppointmentNote,
    setCurrentAppointmentNoteProp,
    optionStrings,
    buttonColors,
    includeNotes
}) => {

    const handleChangeEditor = (editor, propertyToUpdate: string) => {
        let newValue = editor.getText();
        let oldValue = currentAppointmentNote['assessmentNotes']?.response
        if (newValue !== oldValue) {
            setCurrentAppointmentNoteProp(newValue, propertyToUpdate)
        }
    };
    const [renderKey, setRenderKey] = useState(0);

    const handleButtonClick = (newValue) => {
        setCurrentAppointmentNoteProp(newValue, valueString);
        // Update the renderKey to trigger a re-render
        setRenderKey(renderKey + 1);
    };

    return (
        <Grid container direction="column" margin={1} padding={1}>
            <Grid item xs={12}>
                <BoxComposed
                    sx={(theme) => ({
                        height: `75px`,
                        borderRadius: `${theme.general.borderRadius}`,
                        background: `${theme.colors.gradients.black2}`
                    })}
                >
                    <BoxComposedBg
                        sx={(theme) => ({
                            opacity: 0.1,
                            background: `${theme.colors.gradients.black1}`
                        })}

                    />
                    <BoxComposedContent py={3} pt={0} pb={0} height={'75px'}>
                        <Typography
                            textAlign="left"
                            sx={{
                                ml: 4,
                            }}
                            variant="h3"
                        >
                            {title}
                        </Typography>
                    </BoxComposedContent>
                </BoxComposed>
            </Grid>
            <Grid xs={12} padding={2} px={5}>
                {optionStrings.map((optionString, index) => (
                    <Stack
                        key={index}
                        alignItems="center"
                        spacing={1}
                        position="relative"
                        mt={index > 0 ? 1 : 0} // Add margin top for options other than the first one
                        mb={index < optionStrings.length - 1 ? 1 : 2} // Add margin bottom for options other than the last one
                    >
                        {currentAppointmentNote[valueString]?.markAsEdited &&
                            parseInt(currentAppointmentNote[valueString]?.response) === index && (
                                <ErrorOutlineTwoToneIcon
                                    style={{ position: 'absolute', top: 0, right: 0, color: 'blue' }}
                                />
                            )}
                        <Button
                            style={{ height: '60px', padding: 0.5, opacity: 0.5 }}
                            variant={
                                parseInt(currentAppointmentNote[valueString]?.response) === index
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={() => handleButtonClick(index)}
                            color={buttonColors[index]}
                            fullWidth
                        >
                            {optionString}
                        </Button>
                    </Stack>
                ))}
                {includeNotes &&
                    <Grid>
                        <Typography
                            variant="h5"
                        >
                            {`${title} Notes`}
                        </Typography>
                        <ReactQuill
                            theme='snow'
                            style={{ height: '200px', marginBottom: '25px' }}
                            onBlur={(content, delta, editor) => handleChangeEditor(editor, valueNoteString)}

                            value={currentAppointmentNote[valueNoteString]?.response || ''}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default MyoGradingComponent;