import { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import { GridHeaderBL } from "../../../components/GridHeader/GridHeaderBL"
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import {
    styled,
    IconButton,
    Grid,
    TextField,
    InputAdornment
} from '@mui/material';

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
          width: ${theme.spacing(4)};
          height: ${theme.spacing(4)};
          border-radius: ${theme.general.borderRadiusLg};
  `
);

interface SearchProps {
    onChange(value: string): any;
    onClear(): any;
    onCancel(): any;
    // onFilter(filters: any): any;
}

@observer
export class SearchBar extends Component<SearchProps> {
    @observable store = new GridHeaderBL();

    constructor(props: any) {
        super(props);
        makeObservable(this);
        this.store.searchText = createRef();
    }

    render() {
        return (
            <Grid container>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchTwoToneIcon />
                            </InputAdornment>
                        ),
                        endAdornment: this.store.textSearch ? (
                            <InputAdornment position="end">
                                <IconButtonWrapper >
                                    <IconButton onClick={() => {
                                        this.store.clearTextInput();
                                        this.props.onClear();
                                    }}>
                                        <CancelTwoToneIcon />
                                    </IconButton>
                                </IconButtonWrapper>
                            </InputAdornment>
                        ) : null
                    }}
                    sx={{
                        m: 0,
                    }}
                    ref={this.store.searchText}
                    value={this.store.textSearch}
                    onChange={(event) => {
                        this.props.onChange(event.target.value);
                        this.store.onChange(event);
                    }}
                    placeholder={('Search patient by name or ID')}
                    //   value={query}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        );
    }
}

export default SearchBar;