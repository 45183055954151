import { action, makeObservable, observable } from "mobx";
import { Gateway } from "../api/Gateway";
import TreatmentPlan from "../entities/TreatmentPlan";
import { MainStore } from "./MainStore";


const defaultHistory = {
  id: -1,
  patientId: -1,
  areasOfConcern: 0,
  areasOfConcernNotes: ""
}

export default class TreatmentPlanStore {
  mainStore: MainStore;


  @observable areasOfConcern = 0;
  @observable areasOfConcernNotes = "";
  @observable helperAOCText = "";
  @observable helperAOCNoteText = "";

  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;
  }


  @action setAreasOfConcern = (newValue: number) => { this.areasOfConcern = newValue }
  @action setAreasOfConcernNotes = (newValue: string) => { this.areasOfConcernNotes = newValue }
  @action setHelperAOCText = (newValue: string) => { this.helperAOCText = newValue }
  @action setHelperAOCNoteText = (newValue: string) => { this.helperAOCNoteText = newValue }

  @action optionChecked = (options: number, option: number): boolean => {
    return (options & option) === option;
  }


  @action areasOfConcernChange = (event, value) => {
    if (event.target.checked) {
      this.setAreasOfConcern(this.areasOfConcern | Number.parseInt(value));
    } else {
      this.setAreasOfConcern(this.areasOfConcern ^ Number.parseInt(value));
    }
    this.setAreasOfConcern(this.areasOfConcern);
  }

  @action resetTreatmentPlanStore = () => {
    //this.setAreasOfConcern(0);
    this.setAreasOfConcernNotes("");
    this.setHelperAOCText("");
    this.setHelperAOCNoteText("");
  }

  @action
  saveTreatmentPlan = (patientId: number) => {
    var plan = {} as TreatmentPlan;
    plan.complaints = this.areasOfConcern;
    plan.complaintNotes = this.areasOfConcernNotes;
    plan.patientId = patientId;

    Gateway.post(`patient/treatment`, plan).then(() => {

    });
  }

  @action
  validateTreatmentPlan = (): any[] => {
    let fieldValidationList: string[] = [];

    if ((this.areasOfConcern <= 0)) {
      fieldValidationList.push("An Area of Concern selection is required.");
      this.setHelperAOCText("Required Field");
    }

    if ((this.areasOfConcernNotes === "")) {
      fieldValidationList.push("An Area of Concern Notes are required.");
      this.setHelperAOCNoteText("Required Field");
    }

    return fieldValidationList;
  }
}