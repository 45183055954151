import { Grid, Typography, TextField, MenuItem, Link, CardMedia, Card, CardContent, CardActionArea, Button } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useMainStoreContext } from "../../stores/MainStore";
import { Ethnicity, Gender } from '../../entities/Enums';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MyoCorrectLogo from '../../imgs/MyoCorrect/MyoCorrectLogo.png'
import MyoCorrectPlusLogo from '../../imgs/MyoCorrect/MyoCorrectPlusLogo.png'
import { observer } from "mobx-react";

const styles = makeStyles((theme) => ({
	root: {
	  flexGrow: 1,
	  margin: theme.spacing(2),
	},
	buttons: {
		backgroundColor: '#324c9d',
		color: '#fff',
		marginTop: '5px',
		marginBottom: '5px',
		borderRadius: '18px',
	},
	logoImage: {
		width: '100%',
	},
	selectedPlan: {
		backgroundColor: '#324c9d',
		color: '#fff'
	},
	plan: {
		backgroundColor: '#fff',
		color: '#000'
	}
  }));

const StepTwo = () => {
	const classes = styles();
	const { selectedPlan, setSelectedPlan, setStep } = useMainStoreContext().myoCorrectEnrollmentStore;
	return (
		<div className={classes.root}>
		  <Grid container spacing={3}>
			<Grid item xs={12}>
			  <Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h5">MyoCorrect Enrollment</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">MyoFunctional therapy has been shown* to directly or indrirectly help with the following:</Typography>
				</Grid>
				<Grid item xs={12}>
					<ul>
						<li>Shortened treatment time in Vivos oral appliance therapy with higher satisfaction</li>
						<li>Nasal breathing as primary function</li>
						<li>Reducing Anxiety</li>
					</ul>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6">Please select wich Myofunctional Therapy Plan to enroll your patient into. By enrolling your patient into one of the MyoCorrect Therapy plans below, you also agree to the <Link href="https://www.google.com" target="_blank">Terms and Conditions</Link></Typography>					
				</Grid>
				<Grid item xs={6}>
					<Grid container direction="row-reverse">
						<Grid item xs={6}>
							<Card className={selectedPlan === 2 ? classes.selectedPlan : classes.plan}>
								<CardActionArea onClick={() => setSelectedPlan(2)}>
									<CardContent>
										<img src={MyoCorrectLogo} alt="MyoCorrect Logo" className={classes.logoImage} />
										<Typography variant="h6">12 Sessions</Typography>
										<ul>
											<li>3 months of weekly 1:1 sessions</li>
											<li>Each session 20-30 minutes each</li>
											<li>For patients 4 years old and up</li>
											<li>Patient has no OSA or Mild OSA AHI 14 or less</li>
											<li>No frenulum present</li>
											<li>No TMD</li>
										</ul>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container>
						<Grid item xs={6}>
							<Card className={selectedPlan === 3 ? classes.selectedPlan : classes.plan}>
								<CardActionArea onClick={() => setSelectedPlan(3)}>
									<CardContent>
										<img src={MyoCorrectPlusLogo} alt="MyoCorrect+ Logo" className={classes.logoImage} />
										<Typography variant="h6">18 Sessions</Typography>
										<ul>
											<li>8 months of personalized sessions</li>
											<li>Each session 20-30 minutes each</li>
											<li>For patients 4 years old and up</li>
											<li>Moderate to Severe OSA (AHI 15 or more)</li>
											<li>Patient is tongue tied</li>
											<li>Patient has a lip tie</li>
										</ul>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			  </Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={6}>
						<Button onClick={() => setStep(0)} variant="contained" color="primary" className={classes.buttons}>Back</Button>
					</Grid>
					<Grid item xs={6} display='flex' flexDirection='row-reverse'>
						<Button onClick={() => setStep(2)} variant="contained" color="primary" className={classes.buttons}>Save and Continue</Button>
					</Grid>
				</Grid>
			</Grid>
		  </Grid>
		</div>
	)
}
export default observer(StepTwo);