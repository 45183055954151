import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface AlertProps {
    open: boolean;
    title?: string;
    message?: string;
    multipleMessage?: string[];
    close: () => void;
}
const Alert: React.FC<AlertProps> = ({ open, title, message, close, multipleMessage }) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            {message && <DialogContent>{message}</DialogContent>}

            {multipleMessage && (
                <DialogContent>
                    {multipleMessage?.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}
                </DialogContent>
            )}

            <DialogActions>
                <Button onClick={close}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Alert;
