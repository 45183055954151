import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ProviderPatient from "../../../entities/ProviderPatient";
import PatientEditBL from "../PatientEditBL";
import DataGrid from "../../DataGrid/DataGrid";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { Button, Grid } from "@material-ui/core";
import { useMainStoreContext } from "../../../stores/MainStore";
interface IGIProps {
    patient: ProviderPatient;
}
export const PatientFormResponses: React.FC<IGIProps> = observer(({ patient }) => {
    const [editBl, setEditBl] = useState({} as unknown as PatientEditBL);
    const [data, setData] = useState<any[]>([]);
    const store = useMainStoreContext().customFormStore;
    useEffect(() => {
        store.loadResponsesQuestionaireData(patient.id).then((response) => {
            setData(response);
        })
        //this.editBl = new PatientEditBL();
        //this.editBl.patientData = this.props.patient;
    }, [])
    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {

                return (
                    <Grid>
                        <Button className="MuiButton-textPrimary" id={"patientEdit.Responses." + params.row.ID} variant="text" onClick={() => {
                            store.viewQuestionaireId = params.row.questionaireId;
                            store.viewResponseQuestionaireId = params.row.id;
                            store.toggleCustomFormModal();
                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }
        },
        {
            field: 'questionaire.name', headerName: 'Form Name', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
            renderCell: (params) => {
                return (<Grid>{params.row.questionaire.name}</Grid>)
            }
        },
        {
            field: 'createdOn', type: 'date', headerName: 'Response Date', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return '';
                }

                const valueFormatted = new Date(params.value).toLocaleDateString();
                return `${valueFormatted}`;
            }
        },
    ]
    return (
        <Grid>
            <DataGrid
                columns={columns}
                data={data}
                size={"small"}
                pageSize={10}
            />
        </Grid>

    )
});