import * as React from 'react';
import { FC, useEffect } from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { Oval } from 'react-loader-spinner';
import { CircularProgress, Grid } from '@mui/material';

import { PatientDetails } from './PatientDetailComponents/PatientDetails';
import PatientEditBL from './PatientEditBL';
import { PatientHeader } from './PatientDetailComponents/PatientHeader';
import { PatientHeaderTwo } from './PatientDetailComponents/PatientHeaderTwo';
import { PatientStore } from '../../api/PatientStore';
import { PatientTreatmentPlanning } from './PatientTreatmentPlanning';
import { PatientVisitRecords } from './PatientVisitRecordComponents/PatientVisitRecords';
import { PatientAIReports } from './PatientAIReportComponents/PatientAIReports';
import { PatientMyoCorrect } from './PatientMyoCorrectComponents/PatientMyoCorrect';
import { PatientApplianceTherapy } from './PatientApplianceComponents/PatientApplianceTherapy';
import { PatientOpenOrders } from './PatientApplianceComponents/PatientOpenOrders';
import { PatientConsultation } from './PatientConsultations';
import { useMainStoreContext } from '../../stores/MainStore';
import { PatientTeeth } from './PatientTeethComponents/PatientTeeth';
import { PatientFormResponses } from './PatientModalCustomForm/PatientFormResponses';
import MyoCorrectEnrollment from "../../pages/MyoCorrectEnrollment/MyoCorrectEnrollment";
import ModalContainer from '../ModalContainer';
import InitialEvaluationCalendly from '../AppointmentModal/InitialEvaluationCalendly';
import { AppointmentModal } from '../../components/AppointmentModal';
import { PatientDetailsTwo } from './PatientDetailComponents/PatientDetailsTwo';

interface IPatientEditProps {
    patientID: number;
    patient?: any;
    onClose(): any;
    userRoles?: any;
    asProvider: boolean;
}

const patientTabs = [
    'Patient Details',
    'Visit Records',
    'Teeth Charting',
    'AI Reports',
    'Treatment Planning',
    'MyoCorrect',
    'Appliance Therapy',
    'Consultation',
    'Open Orders',
    "Forms Responses"
];

export const PatientEdit: FC<IPatientEditProps> = observer(({ patient, patientID, userRoles, asProvider, onClose }) => {
    const isClinicalAdvocate = userRoles?.some((role: any) => role.Name === 'ClinicalAdvocate');

    const teethStore = useMainStoreContext().teethChartStore;
    const { loadPatient, patientConsultation, resetConsultationData, setPatientId } = useMainStoreContext().consultationData;
    const { toggleShowMyoCorrectModal, setToggleShowMyoCorrectModal, toggleFirstSession, setToggleFirstSession, toggleCalendly, setToggleCalendly } = useMainStoreContext().myoCorrectEnrollmentStore;
    const { openCalendly, setOpenCalendly, setSchedulerLink } = useMainStoreContext().myoCorrectAdminStore;
    const { setIsLoadingSessions, patientIdentification, loadCurrentSessions } = useMainStoreContext().sessionsStore;
    const [waitForSessions, setWaitForSessions] = React.useState(false);

    const toggleOpen = (schedulerLink: string | null) => {
        setTimeout(() => {
            setSchedulerLink(schedulerLink);
            setOpenCalendly(!openCalendly);
            if (patientIdentification) {
                setIsLoadingSessions(true);
                setTimeout(() => loadCurrentSessions(patientIdentification), 2000);
            }
        }, 200);
    };

    const [editBL] = React.useState(new PatientEditBL());
    const [store] = React.useState(new PatientStore());
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tabs, setTabs] = React.useState<any>();
    const [hasClinicalAdvocateRole, setHasClinicalAdvocateRole] = React.useState(isClinicalAdvocate);

    const handleTabs = () => {
        if (userRoles) {
            if (hasClinicalAdvocateRole && !asProvider) {
                const newTabs = _.remove(patientTabs, tab => {
                    return tab !== 'Patient Details' && tab !== 'Treatment Planning' && tab !== 'Consultation';
                });
                setTabs(newTabs);
            }
        } else {
            setTabs(patientTabs);
        }
    };

    const closeInitialEvaluation = () => {
        setToggleFirstSession(false);
        setWaitForSessions(true);
        setTimeout(() => {
            setWaitForSessions(false);
        }, 45000);
    };

    useEffect(() => {
        handleTabs();
        setTabs(patientTabs);
        store.currentPatientId = patientID;
        teethStore.setPatientId(patientID);
        setPatientId(patientID);
        loadPatient().then(() => {
            setIsLoaded(true);
        });

        return () => {
            setHasClinicalAdvocateRole((!hasClinicalAdvocateRole && !asProvider));
            resetConsultationData();
        };
    }, [hasClinicalAdvocateRole, patientID, teethStore.setPatientId, store]);

    if (!isLoaded) {
        return (
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        );
    } else {
        return (
            <>
                <Grid xs={12} className="patientEditModal">
                    <Grid xs={12}>
                        <PatientHeaderTwo
                            isClinicalAdvocate={hasClinicalAdvocateRole}
                            patient={hasClinicalAdvocateRole ? patientConsultation : patient}
                            activeTab={editBL.activeTab}
                            onClose={onClose}
                            onClick={editBL.setActiveTab}
                            tabs={tabs}
                        />
                    </Grid>
                    <Grid>
                        {editBL.activeTab === 0 && (
                            <PatientDetailsTwo
                                isClinicalAdvocate={hasClinicalAdvocateRole}
                                patient={hasClinicalAdvocateRole ? patientConsultation : patient}
                                bl={editBL}
                            />
                        )}
                        {!hasClinicalAdvocateRole && editBL.activeTab === 1 && (
                            <PatientVisitRecords patient={patient} />
                        )}
                        {editBL.activeTab === 2 && <PatientTeeth patient={patient} />}
                        {editBL.activeTab === 3 && <PatientAIReports patient={patient} bl={editBL} />}
                        {editBL.activeTab === 4 && (
                            <PatientTreatmentPlanning
                                patient={hasClinicalAdvocateRole ? patientConsultation : patient}
                            />
                        )}
                        {editBL.activeTab === 5 && (waitForSessions ? <CircularProgress /> : <PatientMyoCorrect patient={patientConsultation} />)}
                        {editBL.activeTab === 6 && <PatientApplianceTherapy patient={patient} />}
                        {editBL.activeTab === 7 && (
                            <PatientConsultation
                                notesInfo={patient}
                                isClinicalAdvocate={hasClinicalAdvocateRole}
                                patient={hasClinicalAdvocateRole ? patientConsultation : patient}
                            />
                        )}

                        {hasClinicalAdvocateRole && editBL.activeTab === 2 && (
                            <PatientConsultation
                                notesInfo={patient}
                                patient={hasClinicalAdvocateRole ? patientConsultation : patient}
                                isClinicalAdvocate={hasClinicalAdvocateRole}
                            />
                        )}
                        {editBL.activeTab === 8 && <PatientOpenOrders patient={patient} />}
                        {editBL.activeTab === 9 && <PatientFormResponses patient={patient} />}
                    </Grid>
                </Grid>
                <ModalContainer
                    show={toggleShowMyoCorrectModal}
                    title='MyoCorrect' onClick={setToggleShowMyoCorrectModal} onClose={setToggleShowMyoCorrectModal}>
                    <MyoCorrectEnrollment patientId={patient.id} />
                </ModalContainer>
                <ModalContainer
                    show={toggleFirstSession}
                    title='Calendly' onClick={setToggleFirstSession} onClose={() => closeInitialEvaluation()}>
                    <InitialEvaluationCalendly />
                </ModalContainer>
                <AppointmentModal open={openCalendly} onClose={() => toggleOpen(null)} />
            </>
        );
    }
});
