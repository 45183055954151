import { action, makeAutoObservable, observable } from "mobx";
import { MainStore } from "./MainStore";
import jwt_decode from 'jwt-decode';
import { Gateway } from "../api/Gateway";
import { Provider } from "../entities/Provider";
import { GlobalUtils } from "../api/GlobalUtils";
import DictionaryListItem from "../entities/DictionaryListItem";
import PatientImage from "../entities/PatientImage";

export default class ProviderStore {

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        this.loadAddressTypeValues();
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; userFullName; providerId; userName } = jwt_decode(token);
            this.userID = tokenData.id;
            this.ProviderID = tokenData.providerId;
            this.userName = tokenData.userName;
        }
    }
    
    @observable mainStore: MainStore;
    @observable userID: number = 0;
    @observable ProviderID: number = 0;
    @observable userName: string = "";
    @observable private _activeTab = 0;
    @observable emailReset = "";
    @observable providerData: Provider = {} as Provider;
    @observable providerPreferences: Provider = {} as Provider;
    @observable personalAddress: any = {};
    @observable billableAddress: any = {};
    @observable editMode: boolean = false;
    @observable profileImage: PatientImage[] = [];
    @observable logoImage: PatientImage[] = [];
    @observable logoUploaded: boolean = false;
    @observable profileImageUploaded: boolean = false;
    @observable emailSent: boolean = false;
    @observable emailNotExist: boolean = false;
    @observable providerTypes: DictionaryListItem[] = [];
    personalAddressTypeId: number | undefined = 0;
    billableAddressTypeId: number | undefined = 0;
    IMAGENAME: string = "profileImage.png";

    get activeTab() {
        return this._activeTab;
    }
    
    @action
    set activeTab(value: number) {
        this._activeTab = value;
    }

    @action
    setActiveTab = (index: number) => {
        this.activeTab = index;
    };

    @action
    setToggleEditMode = (value: boolean) => {
        this.editMode = value;
    };

    @action
    saveProvider = async () => {
        var url = 'provider/' + this.ProviderID; 
        this.providerData.addresses.length = 0;
        this.personalAddress.addressTypeId = this.personalAddressTypeId;
        this.providerData.addresses.push(this.personalAddress);
        this.billableAddress.addressTypeId = this.billableAddressTypeId;
        this.providerData.addresses.push(this.billableAddress);
        this.setToggleEditMode(false);
        await Gateway.put(url, this.providerData).then();
    };

    @action
    saveProviderPreferences = async () => {
        var url = 'provider/preferences/' + this.ProviderID; 
        await Gateway.put(url, this.providerPreferences).then();
    };

    @action
    saveProfileImage = () => {
        this.profileImageUploaded = false;
        Gateway.post(`/provider/profileImage/${this.ProviderID}`, this.profileImage[0]).then(() => {
            this.profileImageUploaded = true;
            this.loadProfileImage();
         });
    };

    @action
    saveLogoImage = () => {
        this.logoUploaded = false;
        Gateway.post(`/provider/logoImage/${this.ProviderID}`, this.logoImage[0]).then(() => {
            this.logoUploaded = true;
         });
    };

    @action
    resetPassword = () => {
        if(this.emailReset === this.userName){
            Gateway.identityPost(`/account/resetPassword?email=${this.emailReset}`, []).then((data) => {
                this.emailSent = data.length > 0 ? true : false;
                this.emailNotExist = data.length === 0 ? true : false;
             });
        }
        else {
            this.emailNotExist = true;
        }
    };

    @action
    getProviderDetails = async () => {
        var url = '/provider/details/'+ this.ProviderID; 
        await Gateway.get(url).then(data => {
            this.providerData = data as unknown as Provider;
            this.personalAddress = this.providerData.addresses.find(x => x.addressTypeId === this.personalAddressTypeId);
            this.billableAddress = this.providerData.addresses.find(x => x.addressTypeId === this.billableAddressTypeId);
        });
    };

    @action
    getProviderPreferences = async () => {
        var url = '/provider/'+ this.ProviderID; 
        await Gateway.get(url).then(data => {
            this.providerPreferences = data as unknown as Provider;
        });
    };

    @action loadAddressTypeValues = async () => {
        GlobalUtils.getAddresstype().then(resp => {
            this.personalAddressTypeId = resp.find(x => x.listItemKey === "PERSONALADDRESS")?.id;
            this.billableAddressTypeId = resp.find(x => x.listItemKey === "BILLABLEADDRESS")?.id;
        })
    };

    @action loadProfileImage = async () => {
        const img = await GlobalUtils.getProviderUrl(this.IMAGENAME, this.ProviderID);
        this.providerData.imageProfileUrl = `${img}?${Math.random()}`;
    };

    @action getProviderTypes = async () => {
        var url = '/provider/types/'+ this.ProviderID; 
        await Gateway.get(url).then(data => {
            this.providerTypes = data as unknown as DictionaryListItem[];
        });
    };
}

