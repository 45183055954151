import { Button, Grid } from "@mui/material";
import { Component } from "react";
import { Link } from "react-router-dom";

interface SubNavItemProps {
    isLink: boolean;
    label: string;
    count: number;
    numItems: number;
    currentSubNavItem?: number;
    url?: string;
    onClick( filter: any, index: any): any;
    filter?: number;
    index: number;
}

export class SubNavItem extends Component<SubNavItemProps> {
    numDigits: number = 0;
    constructor(props: any) {
        super(props);
        this.numDigits = this.props.count.toString().length;
    }

    render() {
        return (
            <Grid key={this.props.label} >
                <Grid className={"subItem"} >
                    <Grid container direction={"row"} alignContent={"center"} textAlign={"left"} alignItems={"center"}>
                        {this.props.count > 0 && <Grid  xs={2} className={"itemCount small" + ((this.numDigits == 3) + " threedigitsmall")}>{this.props.numItems}</Grid>}
                        
                        {this.props.count <= 0 && <Grid xs={2} className="itemCountEmpty" ></Grid>}
                        {
                            this.props.isLink &&
                            <Grid  xs={10}><Link id={"leftNav.navLink.subnavitem.link." + this.props.label} className={"link" + (this.props.index == this.props.currentSubNavItem ? " subNavselected" : "")} to={{ pathname: `${this.props.url}/${this.props.label.replace(/\s/g, "")}` }}>{this.props.label ? this.props.label.toUpperCase() : ""}</Link></Grid>
                        }
                        {
                            !this.props.isLink &&
                            <Grid xs={10}><Button id={"leftNav.navLink.subnavitem.btn." + this.props.label} variant="text" className={(this.props.index == this.props.currentSubNavItem ? " subNavselected" : "")} onClick={() => { this.props.onClick(this.props.filter, this.props.index) }}>{this.props.label ? this.props.label.toUpperCase() : ""}</Button></Grid>
                        }
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}