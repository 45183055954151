import {
    Tooltip,
    TooltipProps,
    tooltipClasses,
    styled,
    useTheme,
    CardMedia
} from '@mui/material';
import { Link } from 'react-router-dom';

const CardMediaWrapper = styled(CardMedia)(
    () => `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; // Ensure the image fills the entire card height
    `
);
const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.colors.alpha.trueWhite[100],
        color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 'bold',
        borderRadius: theme.general.borderRadiusSm,
        boxShadow:
            '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.colors.alpha.trueWhite[100]
    }
}));

function Logo() {
    const theme = useTheme();

    return (
        <TooltipWrapper
            title="vCloud Provider Portal"
            arrow
        >
            <CardMediaWrapper>
                <CardMedia
                    component="img"
                    image="/logos/vCloud_White_Blue2.png"
                    alt="..."
                />
            </CardMediaWrapper>
        </TooltipWrapper>
    );
}

export default Logo;
