import { Grid} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import react, { FC } from "react";
import { useMainStoreContext } from "../../stores/MainStore";
import moment from "moment";
import _ from "lodash";

interface IGIProps {
    provider: any;
}


export const ProviderHeader: FC<IGIProps> = observer(({provider}) => {

    const store = useMainStoreContext().providerStore;

    const providerTabs = [
        'Provider Details',
        'Payments',
        'Orders History',
        'Preferences'
    ];

    react.useEffect(() => {
        store.loadProfileImage();
    },[]);

    return <>
    <Grid xs={12}>
        <Grid xs={12} container sx={{ padding: '0px 20px 20px 20px' }} className="header" direction={'row'}>
            <Grid xs={2} className="imageContainer">
                <img
                    className="thumbnail profilePhoto"
                    src={provider.imageProfileUrl}
                ></img>
            </Grid>

            <Grid xs={10} container direction={'row'}>
                <Grid xs={12} container sx={{ justifyContent: 'flex-end' }}>
                    <Grid></Grid>
                </Grid>
                <Grid container className="nameSection divider top long">
                    <Grid container direction={'column'} xs={10}>
                        <Grid container direction={'row'} className="name">
                            <Grid>{provider.firstName}</Grid>
                        </Grid>
                        <Grid className="lastName">{provider.lastName}</Grid>
                        <Grid xs={0.5} className="divider bottom small"></Grid>
                        <Grid container direction={'row'} className="details">
                            <Grid className="detail">ID# -- {provider.id} </Grid>
                            <Grid className="detail">
                                DOB -- {provider.dateOfBirth && moment(provider.dateOfBirth).format('MM/DD/yyyy')}
                            </Grid>
                            <Grid className="detail">Gender -- {provider.gender?.name}</Grid>
                            <Grid className="detail">
                                Ethnicity -- {provider.ethnicity?.name}
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid xs={12} className="tabSection">
            <Grid xs={6} className="tabs" container direction={'row'}>
                {_.map(providerTabs, (t, key: number) => {
                    return (
                        <Grid
                            key={key}
                            className={'tab' + (store.activeTab === key ? ' active' : '')}
                            onClick={() => {
                                store.setActiveTab(key);
                            }}
                        >
                            {t}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    </>
});