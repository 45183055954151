import React from 'react';
import {
    Typography,
    Card,
    Box,
    styled
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);

function AIReportContent() {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            <BoxWrapper>
                <Typography variant='body1' textAlign={'center'}>This is where the AI Report content will go.</Typography>
            </BoxWrapper>
        </Card>
    );
}

export default AIReportContent;
