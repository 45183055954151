import React, { FC } from 'react';

import { ModalContainer } from '../../../ModalContainer';
import { P2PConsultationSteps } from './P2PConsultationSteps';
import { useMainStoreContext } from '../../../../stores/MainStore';

interface IConsultationsModal {
    isOpen: boolean;
    onClose: () => void;
    patient?: any;
    consultationsData: any;
}

export const ConsultationsModal: FC<IConsultationsModal> = ({ isOpen, patient, consultationsData, onClose }) => {
    const { resetVisitRecord } = useMainStoreContext().aiReportOrder;

    return (
        <ModalContainer
            show={isOpen}
            onClose={() => {
                onClose();
                resetVisitRecord();
            }}
            title={`Create Schedule Consultation - ${patient?.firstName} ${patient?.lastName} - ${patient?.vivosId}`}
            onClick={() => {
                onClose();
                resetVisitRecord();
            }}
        >
            <P2PConsultationSteps patient={patient} consultationsData={consultationsData} onClosed={onClose} />
        </ModalContainer>
    );
};
