// Vendors
import React, { Component, useEffect } from "react";
import { Button, Grid } from "@mui/material";

// Entities
import ProviderPatient from "../../entities/ProviderPatient";

// API
import { observer } from "mobx-react";

interface IGIProps {
    children: any;
    onClick(): void;
}


export const VisitTab: React.FC<IGIProps> = observer(
    ({ children, onClick }) => {

        const [hasLoaded, setHasLoaded] = React.useState(false);

        useEffect(() => {
            setHasLoaded(true);
        }, [])

        return (
            <Grid>
                <Grid>
                    {children}
                </Grid>
                <Grid container justifyContent="flex-end" style={{ margin: "25px 0 0 0" }}>
                    <Button className="vivButton invert large" onClick={onClick} >Save</Button>
                </Grid>
            </Grid>
        )
    }
);
