import { observer } from 'mobx-react';
import React, { FC, Fragment, useEffect } from 'react';
import * as _ from 'lodash';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Grid, LinearProgress, MenuItem } from '@mui/material';
import {
  GridActionsCellItem,
} from '@mui/x-data-grid';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import { LeftNav } from '../components/LeftNav/LeftNav';
import { DashboardInfoBox } from '../components/DashboardInfoBox/DashboardInfoBox';
import '../scss/_ReworkClaim.scss';
import { ReworkClaimFilters } from '../entities/Enums';
import DataGrid from '../components/DataGrid/DataGrid';
import { useMainStoreContext } from '../stores/MainStore';
import moment from 'moment';
import { ReworkClaimGridHeader } from '../components/ReworkClaimGridHeader';
import clsx from 'clsx';
import { GridCellParams, GridFilterInputValue, GridFilterItem } from '@mui/x-data-grid-pro';
import CssSelectField from '../components/CssSelectField';
import ModalContainer from '../components/ModalContainer';
import { ClaimReview } from '../components/Claim/ClaimReview';

interface ReworkClaimDashboardProps { }

const ReworkClaimDashboardComponent: FC<ReworkClaimDashboardProps> = (props: ReworkClaimDashboardProps) => {
  //let params = useParams();

  const numResults = 0;

  let store = useMainStoreContext().reworkClaimStore;

  const {
    setTriggerShowClaimModal,
    triggerShowClaimModal
  } = useMainStoreContext().claimStore;

  const defaultPage = "CLAIMS";

  useEffect(() => {
    store.didInitClaims = true;
    store.getFilterNums();
    store.loadPage();
    store.loadTeamMembers();
  }, [store.loadPage, store.loadTeamMembers]);

  useEffect(() => {
    store.loadPage();
  }, [store.filter])

  const [currentButton, setState] = React.useState(1);
  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const onButtonClicked = (newIcon) => {
    setState(newIcon);
  };

  const columns = [
    {
      field: 'id', headerName: 'ID', flex: 0.1, headerAlign: "center", headerClassName: "colHeader", align: "center", type: "number", renderCell: (params) => {
        return (<Grid>
          <Button id={"reworkClaim.dashboard.cell.id." + params.row.id} variant="text"
            onClick={() => {
              setTriggerShowClaimModal(true);
              setSelectedRow(params.row);
            }}> {params.row.id}</Button></Grid>)
      }
    },
    {
      field: 'dateSubmitted', headerName: 'Date Submitted', flex: 1, headerAlign: "center", headerClassName: "colHeader", type: "date", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.dateSubmitted." + params.row.id}>
          {params.row.dateSubmitted && moment(params.row.dateSubmitted).format('MM/DD/yyyy')}</Grid>)
      }
    },
    {
      field: 'daysInQueue', headerName: 'Days In Queue', flex: .5, headerAlign: "center", headerClassName: "colHeader", type: "number", align: "center", cellClassName: (params: GridCellParams<number>) => {
        if (params.value == null) {
          return "";
        }

        return clsx("daysInQueue", {
          redNumber: params.value >= 7,
          orangeNumber: params.value >= 4 && params.value < 7,
          greenNumber: params.value < 4
        });
      }, renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.daysInQueue." + params.row.id}>{params.row.daysInQueue}</Grid>)
      }
    },
    {
      field: 'rxClaimId', headerName: 'RX Claim ID', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        return (<Grid>
          <Button id={"reworkClaim.dashboard.cell.rxClaimId." + params.row.id} variant="text"> {params.row.rxClaimId}</Button></Grid>)
      }
    },
    {
      field: 'providerName', headerName: 'Provider', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.provider." + params.row.id}>{params.row.providerName}</Grid>)
      }
    },
    {
      field: 'patientName', headerName: 'Patient', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.patient." + params.row.id}>{params.row.patientName}</Grid>)
      }
    },
    {
      field: 'assignedToId', headerName: 'Assigned To', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
      sortComparator: (v1, v2, param1, param2) => {
        var a1 = _.find(store.teamMembers, (a) => { return a.peopleID == param1.value });
        var a2 = _.find(store.teamMembers, (a) => { return a.peopleID == param2.value });
        var ad1 = (a1?.firstName + " " + a1?.lastName);
        var ad2 = (a2?.firstName + " " + a2?.lastName);
        return ad1.localeCompare(ad2);
      },
      valueOptions: store.teamMembers.map((a) => { return a.firstName + " " + a.lastName }),
      filterOperators: [
        {
          value: 'is',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value == '') {
              return null;
            }

            return ({ value }): boolean => {
              var teamMember = _.find(store.teamMembers, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
              if (!_.isNull(teamMember) && !_.isUndefined(teamMember)) {
                return teamMember.peopleID === value;
              }
              return false;
            };
          },
          InputComponent: GridFilterInputValue,
          InputComponentProps: { type: 'singleSelect' },
        }],
      renderCell: (params) => {
        if (store.teamMembers.filter(x => x.peopleID === params.row.assignedToId).length === 0) {
          params.row.assignedToId = 0;
        }
        const disabled = store.isAISAdmin;

        return (
          <Grid>
            {!store.isExternalLab && <CssSelectField
              id={"reworkClaim.dashboard.cell.assignedToId." + params.row.id}
              sx={{ border: "0px" }}
              defaultValue="0"
              label="Assign To"
              disabled={disabled}
              value={(params.row.assignedToId ? params.row.assignedToId : 0)}
              onChange={(event) => {
                params.row.assignedToId = event.target.value;
                store.handleAssignToChange(params.row, event)
              }}
            >
              <MenuItem key={0} value={0}>{"Assign To..."}</MenuItem>
              {
                store.teamMembers.map((tm) =>
                  (<MenuItem key={tm.userID + tm.lastName} value={tm.peopleID}>{tm.firstName + ' ' + tm.lastName}</MenuItem>))
              }
            </CssSelectField>}
            {store.isExternalLab && params.row.assignedToName}
          </Grid>
        )
      }
    },
    {
      field: 'labName', headerName: 'Lab', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
      renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.lab." + params.row.id}>{params.row.labName}</Grid>)
      }
    },
    {
      field: "appliance", headerName: 'Appliance', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.appliance." + params.row.id}>{params.row.appliance}</Grid>)
      }
    },
    {
      field: 'claimType', headerName: 'Claim Type', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.claimType." + params.row.id}>
          <Button id={"reworkClaim.dashboard.cell.claimType." + params.row.id} variant="text">{params.row.claimType}</Button></Grid>)
      }
    },
    {
      field: 'claimStatus', headerName: 'Claim Status', sortable: true, flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
      renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.claimStatus." + params.row.id}>{params.row.claimStatus}</Grid>)
      }
    },
    {
      field: 'productionStatus', headerName: 'Production Status', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.productionStatus." + params.row.id}>{params.row.productionStatus}</Grid>)
      }
    },
    {
      field: 'ccrStatus', headerName: 'CCR Status', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.ccrstatus." + params.row.id}>{params.row.ccrStatus}</Grid>)
      }
    },
    {
      field: 'erfStatus', headerName: 'ERF Status', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.erfstatus." + params.row.id}>{params.row.erfStatus}</Grid>)
      }
    },
    {
      field: 'actions', headerName: 'Notes', flex: .3, headerAlign: "center", headerClassName: "colHeader", type: 'actions', align: "center",
      getActions: (params) => [
        <GridActionsCellItem icon={currentButton === 0 ? < ConnectWithoutContactIcon id={"reworkClaim.dashboard.cell.reworkClaimnotes." + params.row.id} /> : <AddCircleOutlineIcon id={"reworkclaim.dashboard.cell.reworkClaimnotes_" + params.row.id} />} onClick={() => onButtonClicked(0)} label="Notes" />
      ]
    }
  ]

  var hdrButtons: any = [];
  hdrButtons.push(<Button id={"reworkClaim.header.btn.filterReworkClaims." + store.userID} className='vivButton large' onClick={store.toggleFilterResultsExpand}>FILTER RESULTS</Button>);

  var infoBoxes: any = [];
  infoBoxes.push(<Grid key={"dib1"}><DashboardInfoBox title={"Pending Approval"} filter={ReworkClaimFilters.PendingApproval} value={store.numPendingApproval} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.PendingApproval) }} /></Grid>)
  infoBoxes.push(<Grid key={"dib2"}><DashboardInfoBox title={"Approved"} filter={ReworkClaimFilters.Approved} value={store.numApproved} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.Approved) }} /></Grid>)
  infoBoxes.push(<Grid key={"dib3"}><DashboardInfoBox title={"All Claims"} filter={ReworkClaimFilters.AllClaims} value={store.numAllClaims} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.AllClaims) }} /></Grid>)

  return (
    <Fragment>
      <Grid xs={12} container direction="row" className='page'>
        {/* <LeftNav onToggle={store.toggleLeftNav} defaultPage={defaultPage} bl={store} /> */}
        <Grid className={'content' + (store.isLeftNavExpanded === true ? ' small' : ' big')} >
          {/* <Grid >
            <ReworkClaimGridHeader
              parentId={"reworkClaim.dashboard"}
              id={store.userID}
              onChange={store.getSearchData}
              onClear={store.clearData}
              title={store.pageTitle}
              numResults={numResults}
              buttons={hdrButtons}
            />
          </Grid> */}
          <Grid container direction="row" sx={{ marginBottom: "15px;" }} justifyContent={"space-between"}>
            {infoBoxes}
            <Grid>
              <Button
                className="vivButton large"
                onClick={event => {
                }}
              >
                {'Run Report'}
              </Button>
              <br />
              <Button
                className="vivButton large"
                onClick={event => {
                }}
              >
                {'Admin Status'}
              </Button>
            </Grid>
          </Grid>
          <Grid className="grid" id={"reworkClaim.dashboard" + store.userID}>
            {store.isLoadingClaims &&
              <LinearProgress variant="determinate" value={store.loadingClaimsProgress} />
            }
            <DataGrid
              data={store.claimData}
              columns={columns}
              pagination={true}
              pageSize={store.pageSize}
              loading={store.isLoadingClaims}
              rowCount={store.totalPages}
              onPageChange={(pageNumber) => {
                store.currentPage = pageNumber;
                store.loadPage();
              }}
              serverSidePagination={true}
              onFilterChange={(filterObj) => {
                store.onFilterChange(filterObj);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <ModalContainer
        noScroll={false}
        show={triggerShowClaimModal}
        title={"Claim Review"}
        onClick={() => { }}
        onClose={() => {
          setTriggerShowClaimModal(false);
          store.loadPage();
          store.getFilterNums();
        }}>
        <ClaimReview claimViewData={selectedRow} />
      </ModalContainer>
    </Fragment>
  )
}

export const ReworkClaimDashboard = observer(ReworkClaimDashboardComponent);