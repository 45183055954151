import { MainStore } from './MainStore';
import { observable, action,  makeObservable } from 'mobx';

const defaultPatientHistory = {
  tonsilsRemoved: false,
  adenoidsRemoved: false,
  neckSurgery: false,
  backSurgery: false,
  chemotherapy: false,
  hypothyroidism: false,
  medicalOther: false,
  medicalHxNotes: "",
  braces: false,
  clearAligners: false,
  toriSurgery: false,
  jawSurgery: false,
  perioDisease: false,
  jointSurgery: false,
  dentalOther: false,
  ipr: false,
  dentalHxNotes: "",
  cpapPrescribed: false,
  cpapNonCompliant: false,
  cpapIntolerant: false,
  madPrescribed: false,
  madNonCompliant: false,
  madIntolerant: false,
  cPAPMadHxNotes: "",
  acrylic: false,
  metals: false,
  plastics: false,
  latex: false,
  food: false,
  painMedications: false,
  allergyOther: false,
  allergyHxNotes: ""
}
export default class CreatePatientStore {
  mainStore: MainStore;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;
  }

  @observable tonsilsRemoved= false;
  @observable adenoidsRemoved= false;
  @observable neckSurgery= false;
  @observable backSurgery= false;
  @observable chemotherapy= false;
  @observable hypothyroidism= false;
  @observable medicalOther= false;
  @observable medicalHxNotes= "";
  @observable braces= false;
  @observable clearAligners= false;
  @observable toriSurgery= false;
  @observable jawSurgery= false;
  @observable perioDisease= false;
  @observable jointSurgery= false;
  @observable dentalOther= false;
  @observable ipr= false;
  @observable dentalHxNotes= "";
  @observable cpapPrescribed= false;
  @observable cpapNonCompliant= false;
  @observable cpapIntolerant= false;
  @observable madPrescribed= false;
  @observable madNonCompliant= false;
  @observable madIntolerant= false;
  @observable cPAPMadHxNotes= "";
  @observable acrylic= false;
  @observable metals= false;
  @observable plastics= false;
  @observable latex= false;
  @observable food= false;
  @observable painMedications= false;
  @observable allergyOther= false;
  @observable allergyHxNotes= "";
  @observable error = true;
  @action setTonsilsRemoved= (newValue: boolean) => { this.tonsilsRemoved = newValue }
  @action setAdenoidsRemoved= (newValue: boolean) => { this.adenoidsRemoved = newValue }
  @action setNeckSurgery= (newValue: boolean) => { this.neckSurgery = newValue }
  @action setBackSurgery= (newValue: boolean) => { this.backSurgery = newValue }
  @action setChemotherapy= (newValue: boolean) => { this.chemotherapy = newValue }
  @action setHypothyroidism=(newValue: boolean) => { this.hypothyroidism = newValue }
  @action setMedicalOther= (newValue: boolean) => { this.medicalOther = newValue }
  @action setMedicalHxNotes = (newValue: string) => { this.medicalHxNotes = newValue }
  @action setBraces= (newValue: boolean) => { this.braces = newValue }
  @action setClearAligners= (newValue: boolean) => { this.clearAligners = newValue }
  @action setToriSurgery= (newValue: boolean) => { this.toriSurgery = newValue }
  @action setJawSurgery= (newValue: boolean) => { this.jawSurgery = newValue }
  @action setPerioDisease= (newValue: boolean) => { this.perioDisease = newValue }
  @action setJointSurgery= (newValue: boolean) => { this.jointSurgery = newValue }
  @action setDentalOther= (newValue: boolean) => { this.dentalOther = newValue }
  @action setIPR= (newValue: boolean) => { this.ipr = newValue }
  @action setCpapPrescribed= (newValue: boolean) => { this.cpapPrescribed = newValue }
  @action setCpapNonCompliant= (newValue: boolean) => { this.cpapNonCompliant = newValue }
  @action setCpapIntolerant= (newValue: boolean) => { this.cpapIntolerant = newValue }
  @action setMadPrescribed= (newValue: boolean) => { this.madPrescribed = newValue }
  @action setMadIntolerant= (newValue: boolean) => { this.madIntolerant = newValue }
  @action setMadNonCompliant= (newValue: boolean) => { this.madNonCompliant = newValue }
  @action setDentalHxNotes = (newValue: string) => { this.dentalHxNotes = newValue }
  @action setCPAPMadHxNotes = (newValue: string) => { this.cPAPMadHxNotes = newValue }
  @action setAcrylic = (newValue: boolean) => { this.acrylic = newValue }
  @action setMetals = (newValue: boolean) => { this.metals = newValue }
  @action setPlastics = (newValue: boolean) => { this.plastics = newValue }
  @action setLatex = (newValue: boolean) => { this.latex = newValue }
  @action setFood = (newValue: boolean) => { this.food = newValue }
  @action setPainMedications = (newValue: boolean) => { this.painMedications = newValue }
  @action setAllergyOther = (newValue: boolean) => { this.allergyOther = newValue }
  @action setAllergyHxNotes = (newValue: string) => { this.allergyHxNotes = newValue }
  @action setError = (error: boolean) => { this.error = error }
}
