interface Laboratory {
    id: number,
    name: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    country: string,
    postalCode: number,
    phone: string,
    fax: string,
    email: string,
    fdaNumber: number,
    shipName: string,
    createdOn: Date,
    createdBy: number,
    modifiedOn: Date,
    modifiedBy: number,
    isActive: boolean
}

class Laboratory{
    constructor({id,name,address1,address2,city,state,country,postalCode,phone,fax,email,fdaNumber,shipName,createdOn,createdBy,modifiedOn,modifiedBy,isActive}: Laboratory)
    {
        this.id = id;
        this.name= name;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.country = country;
        this.postalCode = postalCode;
        this.phone = phone;
        this.fax = fax;
        this.email = email;
        this.fdaNumber = fdaNumber;
        this.shipName = shipName;
        this.createdOn = createdOn;
        this.createdBy = createdBy;
        this.modifiedBy = modifiedBy;
        this.modifiedOn = modifiedOn;
        this.isActive = isActive;
    }
}

export default Laboratory;