// Vendors
import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";
import OpenInBrowserTwoToneIcon from "@mui/icons-material/OpenInBrowserTwoTone";

// Components
import ModalContainer from "../../../ModalContainer";

// Entities
import ProviderPatient from "../../../../entities/ProviderPatient";

// Stores
import { useMainStoreContext } from "../../../../stores/MainStore";

// API
import { observer } from "mobx-react-lite";
import moment from "moment";
import { GlobalUtils } from "../../../../api/GlobalUtils";

interface IGIProps {
    claim: any;
    patient: ProviderPatient;
    isEdit: boolean;
}

export const ConfirmRxStep: React.FC<IGIProps> = observer(({ claim, patient, isEdit }) => {
    const fileName = "rx-example.pdf";
    const route = GlobalUtils.getClaimDocumentUrl(fileName, patient.vivosId, claim.id, claim.id);
    const [shippedDays, setShippedDays] = React.useState(0);
    const store = useMainStoreContext().reworkClaimStore;
    const [shippedOn, setShippedOn] = React.useState(moment(new Date()));

    React.useEffect(() => setShippedOn(isEdit ? moment(claim.shippedOn) : moment(claim.shippedDate)), []);

    React.useEffect(() => {
        let today = moment(new Date());
        setShippedDays(today.diff(shippedOn, 'days'));
    }
        , [shippedOn]);

    const onDownload = () => {
        const link = document.createElement('a');
        link.download = fileName;
        link.href = route;
        link.dispatchEvent(new MouseEvent('click'));
    };

    return (
        <Grid className="step-wizard-container">
            <Grid className="box-wizard-container">
                <Grid className="title-wizard"> Rework submission regarding the following:</Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Patient:</Typography></Grid>
                            <Typography>{patient.firstName + " " + patient.lastName}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">RX ID:</Typography></Grid>
                            <Typography>{claim.rxId}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Appliance:</Typography></Grid>
                            <Typography>{isEdit ? claim.appliance.applianceTypeNavigation.name : claim.appliance.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab:</Typography></Grid>
                            <Typography>{isEdit ? claim.lab.name : claim.labName}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Date RX Shipped:</Typography></Grid>
                            <Typography>{moment(shippedOn).format('MM/DD/yyyy')}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Days Since RX Shipped:</Typography></Grid>
                            <Typography>{shippedDays}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className="title-wizard">Review original design and confirm prior to continuing</Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} justifyContent={'center'}>
                            <Grid item>
                                <Button variant="contained" color="info" startIcon={<OpenInBrowserTwoToneIcon />}
                                    onClick={() => { store.setShowRxNewTab(true) }}>Click to view RX in New Tab</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="info" startIcon={<DownloadTwoToneIcon />}
                                    onClick={() => { onDownload() }}>Click to download RX</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className="box-wizard-container" marginBottom={10}>
                <Grid container>
                    <Grid item xs={12} className="title-wizard-container"> Repair Guide</Grid>
                    <Grid item xs={12} padding={1}>
                        <Typography variant='body1'>
                            Please note repairs are to be completed at the discretion of the lab who originally manufactured the appliance.
                            Only the lab who originally manufactured the appliance can make repairs.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="title-wizard-container"> Warranty Guide</Grid>
                    <Grid item xs={12} padding={1}>
                        <Typography variant='body1'>
                            Please note warranty work on any appliance that exceeds the specified time frames from the
                            original appliance shipping date from the lab, is <strong>UNAUTHORIZED</strong>. Submission of a
                            warranty request is <strong>NOT</strong> an automatic approval of warranty. Once submitted, and
                            reviewed, Vivos will update provider on warranty approval status prior to any work
                            being performed on an appliance.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction={'column'} alignContent={'center'}>
                            <Typography variant="h5" textAlign={'center'}>DNA, mRNA, mmRNA: 60 Days (2 Months)</Typography>
                            <Typography variant="h5" textAlign={'center'}>Guides: 12 months (1 year)</Typography>
                            <Typography variant="h5" textAlign={'center'}>Vida (POD): 24 months (2 years)</Typography>
                            <Typography variant="h5" textAlign={'center'}>Vida Sleep (Sleep POD): 24 months (2 years)</Typography>
                            <Typography variant="h5" textAlign={'center'}>Versa: 36 months (3 years)</Typography>
                            <Typography variant="h5" textAlign={'center'}>PEx: 12 months (1 year)</Typography>
                            <Typography variant="h5" textAlign={'center'}>Advancement Mechanism Screws: 6 months</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} padding={1}>
                        <Typography variant='body1'>
                            All warranty requests before the 60 day warranty period for such components, are subject
                            to the Vivos warranty terms and conditions. For any work needed that falls outside of
                            the terms and conditions of the Vivos warranty, Vivos will collaborate with the Vivos
                            Certified Lab to provide an estimated repair fee(s) to provider prior to any repair work
                            being completed on an eligible appliance.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} padding={1}>
                        <Typography variant='h6'>
                            Form 72-2
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {store.showRxNewTab && <ModalContainer
                noScroll={false}
                show={store.showRxNewTab}
                title={"RX Viewer"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowRxNewTab(false);
                    store.loadPage();
                }}>
                <Grid>
                    <iframe src={route} width="100%" height="1050px"></iframe>
                </Grid>
            </ModalContainer>}
        </Grid>
    )
}
);
