import { Grid, MenuItem } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CssSelectField from "../../CssSelectField";
import { MyoCorrectEvaluationType } from "../../../entities/Enums";

interface EvaluationTypeProps {
	params: GridRenderCellParams,
	setCurrentRow: any,
	handleEvaluationTypeChange: any
}
export const EvaluationType = ({params, setCurrentRow, handleEvaluationTypeChange} : EvaluationTypeProps) => {
	return (
		<Grid>
			<CssSelectField
				id={"appointments.cell.evaluationType" + params.row.ID}
				sx={{ border: "0px" }}
				defaultValue={params.row.evaluationType}
				value={params.row.evaluationType}
				onChange={(event) => {
					setCurrentRow(params.row);
					handleEvaluationTypeChange(params.row, event.target.value);
				}}
			>
				<MenuItem key={0} value={" "}>{" "}</MenuItem>
				<MenuItem key={1} disabled value={MyoCorrectEvaluationType[21]}>{MyoCorrectEvaluationType[21]}</MenuItem>
				<MenuItem key={2} value={MyoCorrectEvaluationType[22]}>{MyoCorrectEvaluationType[22]}</MenuItem>
				<MenuItem key={3} value={MyoCorrectEvaluationType[23]}>{MyoCorrectEvaluationType[23]}</MenuItem>
			</CssSelectField>
		</Grid>)
}