import { MainStore } from './MainStore';
import { observable, action, makeObservable } from 'mobx';
import PatientHistory from '../entities/PatientHistory';
import { Gateway } from '../api/Gateway';
import _ from 'lodash';
import { Allergies } from '../entities/Enums';

const OTHER = 16;
const defaultHistory = {
  id: 0,
  patientId: -1,
  allergyOptions: 0,
  allergyHxNotes: "",
  cpapMadOptions: 0,
  cpapMadHxNotes: "",
  dentalOptions: 0,
  dentalHxNotes: "",
  medicalOptions: 0,
  medicalHxNotes: "",
  areasOfConcern: 0,
  areasOfConcernNotes: "",
  areasOfConcernOther: "",
  notes: "",
  personId: -1,
  userId: -1,
  vivosId: "",
  headAndNeckExamUrl: "",
  healthIntakeFormUrl: "",
}

const defaultPatientHistory = {
  patientHistory: new PatientHistory(defaultHistory)
}

export default class PatientHistoryEditStore {
  mainStore: MainStore;

  @observable medicalList: any[] = [];
  @observable dentalList: any[] = [];
  @observable cpapList: any[] = [];
  @observable allergyList: any[] = [];
  @observable selectedAllergyList: any[] = [];
  @observable patientId = -1;
  @observable patientHistory = new PatientHistory(defaultHistory);
  @observable id = this.patientHistory.id;
  @observable medicalHxNotes = this.patientHistory.medicalHxNotes;
  @observable medicalOptions = this.patientHistory.medicalOptions;
  @observable dentalOptions = this.patientHistory.dentalOptions;
  @observable dentalHxNotes = this.patientHistory.dentalHxNotes;
  @observable cpapMadOptions = this.patientHistory.cpapMadOptions;
  @observable cpapMadHxNotes = this.patientHistory.cpapMadHxNotes;
  @observable allergyOptions = this.patientHistory.allergyOptions;
  @observable allergyHxNotes = this.patientHistory.allergyHxNotes;
  @observable headAndNeckExamUrl = this.patientHistory.headAndNeckExamUrl;
  @observable healthIntakeFormUrl = this.patientHistory.healthIntakeFormUrl;
  @observable isEditMode = false;

  @observable error = false;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;
  }

  @action setPatientHistory = (newValue: PatientHistory) => { this.patientHistory = newValue }
  @action setPatientId = (newValue: number) => { this.patientId = newValue }
  @action setHistoryId = (newValue: number) => { this.id = newValue }
  @action setMedicalOptions = (newValue: number) => { this.medicalOptions = newValue }
  @action setDentalOptions = (newValue: number) => { this.dentalOptions = newValue }
  @action setCpapMadOptions = (newValue: number) => { this.cpapMadOptions = newValue }
  @action setAllergyOptions = (newValue: number) => { this.allergyOptions = newValue }
  @action setMedicalHxNotes = (newValue: string) => { this.medicalHxNotes = newValue }
  @action setDentalHxNotes = (newValue: string) => { this.dentalHxNotes = newValue }
  @action setCpapMadHxNotes = (newValue: string) => { this.cpapMadHxNotes = newValue }
  @action setAllergyHxNotes = (newValue: string) => { this.allergyHxNotes = newValue }
  @action setIsEditMode = (newValue: boolean) => { this.isEditMode = newValue }
  @action setHeadAndNeckExamUrl = (newValue: string) => { this.headAndNeckExamUrl = newValue }
  @action setHealthIntakeFormUrl = (newValue: string) => { this.healthIntakeFormUrl = newValue }

  @action setError = (error: boolean) => { this.error = error }

  @action setMedicalList = (newValue: any[]) => { this.medicalList = newValue }
  @action setDentalList = (newValue: any[]) => { this.dentalList = newValue }
  @action setCpapList = (newValue: any[]) => { this.cpapList = newValue }
  @action setAllergyList = (newValue: any[]) => { this.allergyList = newValue }

  @action getPatientHistory = () => {
    var history = {
      allergyHxNotes: this.allergyHxNotes,
      allergyOptions: this.allergyOptions,
      medicalHxNotes: this.medicalHxNotes,
      medicalOptions: this.medicalOptions,
      dentalHxNotes: this.dentalHxNotes,
      dentalOptions: this.dentalOptions,
      cpapMadHxNotes: this.cpapMadHxNotes,
      cpapMadOptions: this.cpapMadOptions,
      headAndNeckExamUrl: this.headAndNeckExamUrl,
      healthIntakeFormUrl: this.healthIntakeFormUrl
    }

    return history;
  }

  @action setHistory = (history) => {
    this.setHistoryId(history.id);
    this.setPatientId(this.patientId);
    this.setAllergyHxNotes(history.allergyHxNotes);
    this.setAllergyOptions(history.allergyOptions);
    this.setMedicalHxNotes(history.medicalHxNotes);
    this.setMedicalOptions(history.medicalOptions);
    this.setDentalHxNotes(history.dentalHxNotes);
    this.setDentalOptions(history.dentalOptions);
    this.setCpapMadHxNotes(history.cpapMadHxNotes);
    this.setCpapMadOptions(history.cpapMadOptions);
    this.setHeadAndNeckExamUrl(history.headAndNeckExamUrl);
    this.setHealthIntakeFormUrl(history.healthIntakeFormUrl);
  }


  @action optionChecked = (options: number, option: number): boolean => {
    return (options & option) === option;
  }

  @action medicalhxOnChange = (event, value) => {
    if (event.target.checked) {
      this.setMedicalOptions(this.medicalOptions | Number.parseInt(value));
    } else {
      this.setMedicalOptions(this.medicalOptions ^ Number.parseInt(value));
    }
  }

  @action dentalhxOnChange = (event, value) => {
    if (event.target.checked) {
      this.setDentalOptions(this.dentalOptions | Number.parseInt(value));
    } else {
      this.setDentalOptions(this.dentalOptions ^ Number.parseInt(value));
    }
  }
  @action cpapMadhxOnChange = (event, value) => {
    if (event.target.checked) {
      this.setCpapMadOptions(this.cpapMadOptions | Number.parseInt(value));
    } else {
      this.setCpapMadOptions(this.cpapMadOptions ^ Number.parseInt(value));
    }
  }
  @action allergyhxOnChange = (event, value) => {
    if (event.target.checked) {
      this.setAllergyOptions(this.allergyOptions | Number.parseInt(value));
    } else {
      this.setAllergyOptions(this.allergyOptions ^ Number.parseInt(value));
    }
  }

  arrayRemove = (arr, value) => {
    return arr.filter(function (element) {
      return element != value;
    });
  }

  @action
  patientHistoryReset = () => {
    this.patientHistory = new PatientHistory(defaultHistory);
    this.setHistoryId(0);
    this.setPatientId(0);
    this.setAllergyOptions(0);
    this.setAllergyHxNotes("");
    this.setCpapMadOptions(0);
    this.setCpapMadHxNotes("");
    this.setDentalOptions(0);
    this.setDentalHxNotes("");
    this.setMedicalOptions(0);
    this.setMedicalHxNotes("");
  }


  @action
  softValidatePatientHistory = (): string[] => {
    let fieldValidationList: string[] = [];

    //Removed validation until head and neck screen is in place
    /*if ((this.headAndNeckExamUrl === "")) {
      fieldValidationList.push("Head and Neck exam form is required.");
    }

    if ((this.healthIntakeFormUrl === "")) {
      fieldValidationList.push("Patient Health HX Intake form is required.");
    }*/


    return fieldValidationList;
  }

  @action
  upsertPatientHistory = async (patientId: number) => {
    this.patientHistory.allergyOptions = this.allergyOptions;
    this.patientHistory.patientId = patientId;
    await Gateway.post(`/patient/${patientId}/history`, this.patientHistory).then(() => {
      this.selectedAllergyList = [];
      this.checkAllergyOptions(this.allergyOptions);
    });
  }

  @action
  getHistory = async (patientId: number) => {
    const patientHistory = await Gateway.getOne('/patient/' + patientId + '/history');
    if (!_.isNull(patientHistory)) {
      this.setPatientHistory(patientHistory);
      this.setAllergyOptions(patientHistory.allergyOptions);
      this.selectedAllergyList = [];
      this.checkAllergyOptions(patientHistory.allergyOptions);
    }
  }

  checkAllergyOptions = async (allergyOptions: number) => {
    if (this.optionChecked(allergyOptions, Allergies.Acrylic)) {
      this.selectedAllergyList.push(Allergies[Allergies.Acrylic]);
    }
    if (this.optionChecked(allergyOptions, Allergies.Metals)) {
      this.selectedAllergyList.push(Allergies[Allergies.Metals]);
    }
    if (this.optionChecked(allergyOptions, Allergies.Plastic)) {
      this.selectedAllergyList.push(Allergies[Allergies.Plastic]);
    }
    if (this.optionChecked(allergyOptions, Allergies.Latex)) {
      this.selectedAllergyList.push(Allergies[Allergies.Latex]);
    }
    if (this.optionChecked(allergyOptions, Allergies.Food)) {
      this.selectedAllergyList.push(Allergies[Allergies.Food]);
    }
    if (this.optionChecked(allergyOptions, Allergies['Pain Medications'])) {
      this.selectedAllergyList.push(Allergies[Allergies['Pain Medications']]);
    }
    if (this.optionChecked(allergyOptions, Allergies.Other)) {
      this.selectedAllergyList.push(Allergies[Allergies.Other]);
    }
  }
}
