import { Button, Divider, Grid, Typography, FormControlLabel, Checkbox, Box, styled, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { useMainStoreContext } from '../../stores/MainStore';
import React, { useMemo, useState } from 'react';
import UploadDocDragDrop from '../UploadDragAndDrop/UploadDocDragDrop';
import DictionaryListItem from '../../entities/DictionaryListItem';
import PatientFile from '../../entities/PatientFIle';
import { GlobalUtils } from '../../api/GlobalUtils';
import DataGrid from '../DataGrid/DataGrid';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import PatientVisit from '../../entities/PatientVisit';
import { Padding } from '@mui/icons-material';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import TabletMacTwoToneIcon from '@mui/icons-material/TabletMacTwoTone';

interface IProps {
    isIntake: boolean;
    visit: PatientVisit | undefined;
    isPatientFormStep: boolean;
}

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
);

const Documents: React.FC<IProps> = ({ isIntake, visit, isPatientFormStep }) => {
    const { patient, patientDocuments } = useMainStoreContext().createPatientStore;
    const { id, patientFiles, patientUploadFiles, setPatientUploadFiles, deleteFile, setDocumentFiles, documentsFiles, setPatientFiles } = useMainStoreContext().visitRecordStore;
    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
    const [fileName, setFileName] = useState<string>('');
    const [hasLoaded, setHasLoaded] = React.useState(false);

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            setReportTypes(prevState => [...prevState, item].filter(x => x.listItemKey !== "SLEEPTEST"));
        });
    }, []);

    React.useEffect(() => {
        setDocumentFiles(patientFiles.filter(x => x.fileDescriptorId !== 191));
    }
        , [patientFiles]);

    const columns = [
        {
            field: 'id',
            headerName: 'Document Id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.id}
                    </Grid>
                )
            }

        },
        {
            field: 'displayName',
            headerName: 'File Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.displayName}
                    </Grid>
                )
            }

        },
        {
            field: 'fileDescriptor',
            headerName: 'File Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.fileDescriptor.name}
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.createdOn ? moment(params.row.createdOn).format('MM/DD/yyyy') : ""}
                    </Grid>
                )
            }

        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button onClick={() => handleDownloadFile(params.row.fileUrl)}>
                            <DownloadIcon />
                        </Button>
                        <Button onClick={() => handleDeleteFile(params.row.id)}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                )
            }

        },
    ]

    const handleUpload = data => {
        let patientFileToSet: PatientFile = {
            id: 0,
            patientId: patient.id,
            visitId: id,
            fileDescriptorId: data.reportId,
            fileUrl: data.fileName,
            displayName: data.reportType,
            isActive: true,
        } as PatientFile;

        let currentPatientFileArray = patientUploadFiles.filter(x => x.displayName !== data.reportType);
        currentPatientFileArray.push(patientFileToSet);
        setFileName(data.reportType);
        setPatientUploadFiles(currentPatientFileArray);
    };

    const handleDownloadFile = (url) => {
        window.open(url, '_blank');
    }

    const handleDeleteFile = (fileId) => {
        deleteFile(fileId, visit?.id).then(resp => {
            setPatientFiles(resp);
        });
    }

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                    {isPatientFormStep ? 'Patient Forms' : 'Clinical Forms'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={3} sx={{ borderRight: '3px solid #ccc' }}>
                        <Typography variant="subtitle1" paddingLeft={3} paddingTop={1}>
                            {isPatientFormStep
                                ? 'Please select forms below for your patient to complete.'
                                : 'Please select forms you wish to complete regarding this patient.'}
                        </Typography>
                        <Grid container paddingBottom={1}>
                            <Grid item xs={12} >
                                {isPatientFormStep
                                    ? <Grid container paddingLeft={4} paddingRight={5} marginLeft={5}>
                                        <Grid item xs={12}><FormControlLabel label={'Health History'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Sleep, Breathing, & Habit Questionnaire'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Epworth'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Stop-Bang'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Hit-6'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Pediatric Sleep Questionnaire'} control={<Checkbox />} /></Grid>
                                    </Grid>
                                    : <Grid container paddingLeft={4} paddingRight={5} marginLeft={5}>
                                        <Grid item xs={12}><FormControlLabel label={'Clinical Exam'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Intra Oral Dental Exam'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'CBCT Evaluation'} control={<Checkbox />} /></Grid>
                                        <Grid item xs={12}><FormControlLabel label={'Treatment Planning'} control={<Checkbox />} /></Grid>

                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container paddingTop={5} spacing={2} direction={'column'} justifyItems={'center'}>

                            {/* <Box width={'100%'} padding={1} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                <Button variant={'contained'} color='secondary' endIcon={<EmailTwoToneIcon />} style={{ minWidth: '150px' }}>
                                    Email Forms
                                </Button>
                            </Box>

                            <Box width={'100%'} padding={1} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                <Button variant={'contained'} endIcon={<TabletMacTwoToneIcon />} style={{ minWidth: '150px' }}>
                                    Digital Mode
                                </Button>
                            </Box>

                            <Box width={'100%'} padding={1} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                <Button variant={'contained'} endIcon={<PrintTwoToneIcon />} style={{ minWidth: '150px' }}>
                                    Print Forms
                                </Button>
                            </Box> */}

                            <Box display={'flex'} justifyContent={'right'} alignItems={'center'} paddingRight={1}>
                                {isPatientFormStep && <Tooltip title={'Email'} arrow>
                                    <IconButtonWrapper
                                        style={{ alignSelf: "center" }}
                                        sx={(theme) => ({
                                            ml: 1,
                                            backgroundColor: theme.colors.info.lighter,
                                            color: theme.colors.info.main,
                                            transition: theme.transitions.create(['all']),

                                            '&:hover': {
                                                backgroundColor: theme.colors.info.main,
                                                color: theme.palette.getContrastText(
                                                    theme.colors.info.main
                                                )
                                            }
                                        })
                                        }
                                    >
                                        <EmailTwoToneIcon fontSize="large" />
                                    </IconButtonWrapper>
                                </Tooltip>}
                                <Tooltip title={'Digital Mode'} arrow>
                                    <IconButtonWrapper
                                        style={{ alignSelf: "center" }}
                                        sx={(theme) => ({
                                            ml: 1,
                                            backgroundColor: theme.colors.info.lighter,
                                            color: theme.colors.info.main,
                                            transition: theme.transitions.create(['all']),

                                            '&:hover': {
                                                backgroundColor: theme.colors.info.main,
                                                color: theme.palette.getContrastText(
                                                    theme.colors.info.main
                                                )
                                            }
                                        })
                                        }
                                    >
                                        <TabletMacTwoToneIcon fontSize="large" />
                                    </IconButtonWrapper>
                                </Tooltip>
                                <Tooltip title={'Print Forms'} arrow>
                                    <IconButtonWrapper
                                        style={{
                                            alignSelf: "center",
                                        }}
                                        sx={(theme) => ({
                                            ml: 1,
                                            backgroundColor: theme.colors.info.lighter,
                                            color: theme.colors.info.main,
                                            transition: theme.transitions.create(['all']),

                                            '&:hover': {
                                                backgroundColor: theme.colors.info.main,
                                                color: theme.palette.getContrastText(
                                                    theme.colors.info.main
                                                )
                                            }
                                        })
                                        }
                                    >
                                        <PrintTwoToneIcon fontSize="large" />
                                    </IconButtonWrapper>
                                </Tooltip>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid item xs={9}>

                        <UploadDocDragDrop parentCallback={handleUpload} extFiles={patientUploadFiles} typeOptions={reportTypes} />
                        {!isIntake && <Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Uploaded Files</Typography>
                            </Grid>
                            <Grid xs={12} padding={4}>
                                <DataGrid
                                    columns={columns}
                                    data={documentsFiles}
                                    loading={hasLoaded}
                                    size={"small"}
                                    pageSize={10}
                                />
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid >
        </Grid >
    );
};

export default observer(Documents);
