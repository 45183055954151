import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid, Button, SelectChangeEvent, styled, Box, Typography } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import StlIcon from '../../imgs/png/stl.png';
import _ from "lodash";
import Stl3DViewerWithTypes from "./Stl3DViewerWithTypes";
import PatientImage from "../../entities/PatientImage";
import DictionaryListItem from "../../entities/DictionaryListItem";

const fileTypes = ["STL",];
interface IUploaderProps {
    parentCallback(data64string: string, dataPathUrl: string, option: any): any;
    deleteCallback(itemOption: number): any;
    disabled?: boolean;
    allowMultiple: boolean;
    typeOptions: any[];
    extImages: any[];
    vivosId: string;
    visitId: number;
    isRework: boolean;
}
const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);


const Upload3DImgMultiDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback,
        deleteCallback,
        allowMultiple,
        disabled,
        extImages,
        typeOptions,
        vivosId,
        visitId,
        isRework }) => {

        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [images, setImages] = React.useState<any[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [options, setOptions] = React.useState<JSX.Element[]>([]);
        const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            setOptions(typeOptions);
            if (extImages && extImages.length > 0) {
                setImages(extImages);
            }
            setHasLoaded(true);
        }, [])


        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var img = {} as PatientImage;
            var image = URL.createObjectURL(file);
            img.data64String = await convertBase64(file) as unknown as string;
            img.data64Url = decodeURI(image);
            img.pathUrl = decodeURI(file.name);
            imglist.push(img);
        }

        const handleImageSet = (files) => {
            setIsPreview(false);
            var imglist: PatientImage[] = images;
            var promises: any[] = [];
            _.forEach(files, async (file) => {
                const promise = doWork(file, imglist);
                promises.push(promise);
            })
            Promise.all(promises).then(() => {
                _.forEach(imglist, (i, index) => {
                    if (index >= extImages.length)
                        extImages.push(i);
                });
                setImages(imglist);
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (image: any, itemOption: number) => {
            if (!image.new) {
                deleteCallback(itemOption);
            }
            extImages.splice(image.id, 1);
            setImages(extImages);
        }

        const handleOptionSelect = (event: any, oldOption: number, index: number) => {
            var selOpts = selectedOptions;
            _.remove(selOpts, (o) => { return o == oldOption });
            selOpts.push(event.target.value);
            setSelectedOptions(selOpts);
            extImages[index].imageType = _.find(typeOptions, (t) => { return t.id == event.target.value })
            setKey(Math.floor(Math.random() * 42));
            return selOpts;
        }

        return (
            <Grid container direction="row" alignItems={"center"} >
                {/* <Grid xs={(images.length === 0) ? 12 : (images.length === 1) ? 6 : 4} item> */}
                <Grid item xs={isRework ? 12 : 4}>
                    <Grid container justifyContent={"center"}>
                        <FileUploader label="Upload or drop an image here"
                            handleChange={handleImageSet} types={["STL"]} multiple={allowMultiple ? true : false}
                            children={
                                <BoxUploadWrapper width={"400px"}>
                                    <Grid container direction="column" alignItems={'center'} justifyItems={'center'}>
                                        <img src={StlIcon} className="uploadImg" style={{ height: "124px", width: "94px" }} />
                                        <Typography>Drag & Drop or</Typography>
                                        <Typography>Click to Select Stl File(s)</Typography>
                                    </Grid >
                                </BoxUploadWrapper>
                            }
                            name="file" disabled={disabled ? disabled : false} />
                    </Grid>
                </Grid>
                {
                    _.map(images, (img, i) => {
                        return <Grid xs={isRework ? 12 : 4} item>
                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                <Stl3DViewerWithTypes
                                    index={i}
                                    key={i}
                                    stlIndex={i}
                                    typeOptions={typeOptions}
                                    selectedOptions={selectedOptions}
                                    handleOptionSelect={handleOptionSelect}
                                    images={extImages}
                                    vivosId={vivosId}
                                    container={process.env.REACT_APP_VISITRECORD_CONTAINER as string}
                                    containerId={visitId}
                                    image={img.data64Url}
                                    parentCallback={parentCallback}
                                    handleRemove={() => { handleRemove(img, i) }} />
                            </Grid>
                        </Grid>
                    })
                }
            </Grid>

        )
    }

export default observer(Upload3DImgMultiDragDrop);