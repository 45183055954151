import { Grid } from "@mui/material";
import * as React from 'react';
import * as _ from 'lodash'
import { observer } from "mobx-react";
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useMainStoreContext } from "../../stores/MainStore";
import { useParams } from "react-router-dom";
import { IDocOptions, SurveyPDF } from "survey-pdf";
import { runInAction } from "mobx";
import { Oval } from "react-loader-spinner";

const docOptions: IDocOptions = {
  readonlyRenderAs: "text", // Specify the rendering type for read-only components
  htmlRenderAs: "image"
};

var storageName = "SurveyJS_LoadState";
const ViewCustomFormData = () => {
  //const [data, setData] = React.useState(new Model());
  let { questionaireId, responseId } = useParams();
  const store = useMainStoreContext().customFormStore;
  const savePdf = function (surveyData, surveyJson) {

    const surveyPdf = new SurveyPDF(surveyJson, docOptions);
    surveyPdf.data = surveyData;
    surveyPdf.save();
  };

  const [survey, setSurvey] = React.useState(new Model(store.detailedQuestionnaireData));

  React.useEffect(() => {
    let dataSurvey = {};
    runInAction(() => {
      store.isLoadingScreen = true;
    })
    if (!responseId) {
      responseId = store.viewResponseQuestionaireId;
    }
    if (!questionaireId) {
      questionaireId = store.viewQuestionaireId;
    }

    store.loadResponseQuestionaireData(responseId).then((questionaireData) => {
      for (var i = 0; i < questionaireData.length; i += 1) {
        try {
          dataSurvey[questionaireData[i].id] = JSON.parse(questionaireData[i].value)
        }
        catch {
          dataSurvey[questionaireData[i].id] = questionaireData[i].value;
        }
      }

      survey.data = dataSurvey;
      store.loadDetailedQuestionnaireData(questionaireId).then((data) => {
        let newSurvey = new Model(data)
        newSurvey.data = dataSurvey;
        newSurvey.mode = "display";
        newSurvey.addNavigationItem({
          id: "pdf-export",
          title: "Save as PDF",
          action: () => savePdf(newSurvey.data, data)
        });
        setSurvey(newSurvey);
        runInAction(() => {
          store.isLoadingScreen = false;
        })
      });
    });


  }, [])

  function saveState(survey) {
    var res = { currentPageNo: survey.currentPageNo, data: survey.data };
    //Here should be the code to save the data into your database
    window.localStorage.setItem(storageName, JSON.stringify(res));
  }

  survey.onComplete.add((sender, options) => {
    //save the data on survey complete. You may call another function to store the final results
    saveState(sender);
  });


  return (
    <Grid>
      {!store.isLoadingScreen &&
        <Survey model={survey} />
      }

      {store.isLoadingScreen &&
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Oval
            height={60}
            width={60}
            color="#FF7C00"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#480141"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </Grid>
      }

    </Grid>
  );
};
export default observer(ViewCustomFormData);