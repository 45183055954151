import { FC } from 'react';
import { FormControl, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useMainStoreContext } from '../../../../stores/MainStore';

import { observer } from 'mobx-react';
import Item from '../../../Item';
import CssTextField from '../../../CssTextField';
import "../../../../scss/_TreatmentPlanning.scss"

import { IntraoralCondition } from '../../../../entities/Enums';
import { RowObjective } from './RowObjective';
import { toJS } from 'mobx';

interface ISleepConditionProps {
    sleepCondition?: string;
}

export const IntraoralObjectives: FC<ISleepConditionProps> = observer(() => {
    const {
        intraoralDiagnosis,
        setIntraoralDiagnosis,
        intraoralDiagnosisNotes,
        setExtraoralDiagnosis,
        molarRelationshipsRight,
        molarRelationshipsLeft,
        setMolarRelationshipsLeft,
        setMolarRelationshipsRight,
    } = useMainStoreContext().patientTreatmentStore;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer className="treatmentTable">
                    <Table sx={{ minWidth: '50%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Intraoral Conditions</TableCell>

                                <TableCell align="right">Correct</TableCell>
                                <TableCell align="right">Improve</TableCell>
                                <TableCell align="right">Maintain</TableCell>
                                <TableCell align="right">N/A</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.INTRAORALCROWDING) ||
                                intraoralDiagnosis.some(el => el.conditionId === 257)) && (
                                <RowObjective
                                    name="Crowding"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.INTRAORALCROWDING,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 257).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.INTRAORALCROWDING) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.SPACING) ||
                                intraoralDiagnosis.some(el => el.conditionId === 258)) && (
                                <RowObjective
                                    name="Spacing"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.SPACING)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 258).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.SPACING) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.CROSSBITE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 259)) && (
                                <RowObjective
                                    name="Crossbite"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.CROSSBITE)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 259).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CROSSBITE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.EDGETOEDGE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 260)) && (
                                <RowObjective
                                    name="Edge to Edge"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.EDGETOEDGE)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 260).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.EDGETOEDGE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.OVERBITE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 261)) && (
                                <RowObjective
                                    name="Overbite (Excessive)"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.OVERBITE)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 261).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.OVERBITE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.OVERJET) ||
                                intraoralDiagnosis.some(el => el.conditionId === 262)) && (
                                <RowObjective
                                    name="Overjet (Excessive)"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.OVERJET)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 262).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.OVERJET) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.ANTERIOROPENBITE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 263)) && (
                                <RowObjective
                                    name="Anterior Open bite"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.ANTERIOROPENBITE,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 263).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.ANTERIOROPENBITE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.POSTERIOROPENBITE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 264)) && (
                                <RowObjective
                                    name="Posterior Open bite"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.POSTERIOROPENBITE,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 264).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.POSTERIOROPENBITE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.CLEFTHARDPALATE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 265)) && (
                                <RowObjective
                                    name="Cleft Hard Palate"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.CLEFTHARDPALATE,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 265).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLEFTHARDPALATE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.EXOSTOSES) ||
                                intraoralDiagnosis.some(el => el.conditionId === 266)) && (
                                <RowObjective
                                    name="Exostoses"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.EXOSTOSES)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 266).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.EXOSTOSES) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(
                                el => el.listItemKey === IntraoralCondition.ANOMALIESINTERARCHDIST,
                            ) ||
                                intraoralDiagnosis.some(el => el.conditionId === 267)) && (
                                <RowObjective
                                    name="Anomalies of Interarch distance"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.ANOMALIESINTERARCHDIST,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 267).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.ANOMALIESINTERARCHDIST) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.MAXILARYASYMMETRY) ||
                                intraoralDiagnosis.some(el => el.conditionId === 268)) && (
                                <RowObjective
                                    name="Maxillary Asymmetry"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.MAXILARYASYMMETRY,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 268).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.MAXILARYASYMMETRY) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.LIPTIE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 269)) && (
                                <RowObjective
                                    name="Lip Tie"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.LIPTIE)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 269).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.LIPTIE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.TONGUETIE) ||
                                intraoralDiagnosis.some(el => el.conditionId === 270)) && (
                                <RowObjective
                                    name="Tongue Tie"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(el => el.listItemKey === IntraoralCondition.TONGUETIE)
                                            ?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 270).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.TONGUETIE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.REVERSESWALLOW) ||
                                intraoralDiagnosis.some(el => el.conditionId === 271)) && (
                                <RowObjective
                                    name="Reverse Swallow"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.REVERSESWALLOW,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 271).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.REVERSESWALLOW) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(
                                el => el.listItemKey === IntraoralCondition.ANTERIORTONGUETHRUST,
                            ) ||
                                intraoralDiagnosis.some(el => el.conditionId === 272)) && (
                                <RowObjective
                                    name="Anterior Tongue Thrust"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.ANTERIORTONGUETHRUST,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 272).treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.ANTERIORTONGUETHRUST) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsRight.some(el => el.listItemKey === IntraoralCondition.CLASSI) ||
                                molarRelationshipsRight.some(el => el.conditionValueB === 556)) && (
                                <RowObjective
                                    name={`Molar Relationship Right ${molarRelationshipsRight[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsRight.find(el => el.listItemKey === IntraoralCondition.CLASSI)
                                            ?.treatmentObjective ||
                                        molarRelationshipsRight.find(el => el.conditionValueB === 556)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsRight(
                                            molarRelationshipsRight.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSI) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsRight.some(el => el.listItemKey === IntraoralCondition.CLASSII) ||
                                molarRelationshipsRight.some(el => el.conditionValueB === 557)) && (
                                <RowObjective
                                    name={`Molar Relationship Right ${molarRelationshipsRight[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsRight.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSII,
                                        )?.treatmentObjective ||
                                        molarRelationshipsRight.find(el => el.conditionValueB === 557)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsRight(
                                            molarRelationshipsRight.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSII) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsRight.some(
                                el => el.listItemKey === IntraoralCondition.CLASSIITENDENCY,
                            ) ||
                                molarRelationshipsRight.some(el => el.conditionValueB === 558)) && (
                                <RowObjective
                                    name={`Molar Relationship Right ${molarRelationshipsRight[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsRight.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSIITENDENCY,
                                        )?.treatmentObjective ||
                                        molarRelationshipsRight.find(el => el.conditionValueB === 558)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsRight(
                                            molarRelationshipsRight.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSIITENDENCY) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsRight.some(el => el.listItemKey === IntraoralCondition.CLASSIII) ||
                                molarRelationshipsRight.some(el => el.conditionValueB === 559)) && (
                                <RowObjective
                                    name={`Molar Relationship Right ${molarRelationshipsRight[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsRight.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSIII,
                                        )?.treatmentObjective ||
                                        molarRelationshipsRight.find(el => el.conditionValueB === 559)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsRight(
                                            molarRelationshipsRight.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSIII) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsRight.some(
                                el => el.listItemKey === IntraoralCondition.CLASSIIITENDENCY,
                            ) ||
                                molarRelationshipsRight.some(el => el.conditionValueB === 560)) && (
                                <RowObjective
                                    name={`Molar Relationship Right ${molarRelationshipsRight[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsRight.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSIIITENDENCY,
                                        )?.treatmentObjective ||
                                        molarRelationshipsRight.find(el => el.conditionValueB === 560)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsRight(
                                            molarRelationshipsRight.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSIIITENDENCY) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsLeft.some(el => el.listItemKey === IntraoralCondition.CLASSI) ||
                                molarRelationshipsLeft.some(el => el.conditionValueA === 556)) && (
                                <RowObjective
                                    name={`Molar Relationship Left ${molarRelationshipsLeft[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsLeft.find(el => el.listItemKey === IntraoralCondition.CLASSI)
                                            ?.treatmentObjective ||
                                        molarRelationshipsLeft.find(el => el.conditionValueA === 556)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsLeft(
                                            molarRelationshipsLeft.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSI) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsLeft.some(el => el.listItemKey === IntraoralCondition.CLASSII) ||
                                molarRelationshipsLeft.some(el => el.conditionValueA === 557)) && (
                                <RowObjective
                                    name={`Molar Relationship Left ${molarRelationshipsLeft[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsLeft.find(el => el.listItemKey === IntraoralCondition.CLASSII)
                                            ?.treatmentObjective ||
                                        molarRelationshipsLeft.find(el => el.conditionValueA === 557)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsLeft(
                                            molarRelationshipsLeft.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSII) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsLeft.some(
                                el => el.listItemKey === IntraoralCondition.CLASSIITENDENCY,
                            ) ||
                                molarRelationshipsLeft.some(el => el.conditionValueA === 558)) && (
                                <RowObjective
                                    name={`Molar Relationship Left ${molarRelationshipsLeft[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsLeft.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSIITENDENCY,
                                        )?.treatmentObjective ||
                                        molarRelationshipsLeft.find(el => el.conditionValueA === 558)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsLeft(
                                            molarRelationshipsLeft.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSIITENDENCY) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsLeft.some(el => el.listItemKey === IntraoralCondition.CLASSIII) ||
                                molarRelationshipsLeft.some(el => el.conditionValueA === 559)) && (
                                <RowObjective
                                    name={`Molar Relationship Left ${molarRelationshipsLeft[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsLeft.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSIII,
                                        )?.treatmentObjective ||
                                        molarRelationshipsLeft.find(el => el.conditionValueA === 559)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsLeft(
                                            molarRelationshipsLeft.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSIII) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(molarRelationshipsLeft.some(
                                el => el.listItemKey === IntraoralCondition.CLASSIIITENDENCY,
                            ) ||
                                molarRelationshipsLeft.some(el => el.conditionValueA === 560)) && (
                                <RowObjective
                                    name={`Molar Relationship Left ${molarRelationshipsLeft[0].name}`}
                                    treatmentObjectiveId={
                                        molarRelationshipsLeft.find(
                                            el => el.listItemKey === IntraoralCondition.CLASSIIITENDENCY,
                                        )?.treatmentObjective ||
                                        molarRelationshipsLeft.find(el => el.conditionValueA === 560)
                                            ?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setMolarRelationshipsLeft(
                                            molarRelationshipsLeft.map(el => {
                                                if (el.listItemKey === IntraoralCondition.CLASSIIITENDENCY) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {(intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.INTRAORALOTHER) ||
                                intraoralDiagnosis.some(el => el.conditionId === 273)) && (
                                <RowObjective
                                    name="Other"
                                    treatmentObjectiveId={
                                        intraoralDiagnosis.find(
                                            el => el.listItemKey === IntraoralCondition.INTRAORALOTHER,
                                        )?.treatmentObjective ||
                                        intraoralDiagnosis.find(el => el.conditionId === 273)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setIntraoralDiagnosis(
                                            intraoralDiagnosis.map(el => {
                                                if (el.listItemKey === IntraoralCondition.INTRAORALOTHER) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {intraoralDiagnosis.some(el => el.listItemKey === IntraoralCondition.INTRAORALOTHER) && (
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                placeholder="Other Sleep Disorders Notes"
                                required={false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setExtraoralDiagnosis(event.target.value);
                                }}
                                value={intraoralDiagnosisNotes}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
});
