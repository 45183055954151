import { Button, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ActionItemStatus, ClaimStatus, ClaimType, ErfStatus, ProductionStatus } from "../../../entities/Enums";
import ProviderPatient from "../../../entities/ProviderPatient";
import { useMainStoreContext } from "../../../stores/MainStore";
import _ from "lodash";
import ModalContainer from "../../ModalContainer";
import { PatientStore } from "../../../api/PatientStore";
import PrescriptionHorizontal from '../../../pages/prescription/prescriptionhorizontal';

interface IGIProps {
    patient: ProviderPatient;
    claim: any;
    onClose(): any;
    patientStore: PatientStore
}

export const ReworkActionRequiredModal: React.FC<IGIProps> = observer(({ patient, claim, onClose, patientStore }) => {

    const store = useMainStoreContext().reworkClaimStore;
    const [isErfApproved, setIsErfApproved] = useState(false);
    const [isDuplicateClaim, setIsDuplicateClaim] = useState(false);
    const [isNewRx, setIsNewRx] = useState(false);
    const [isMarkCompleted, setIsMarkCompleted] = useState(false);

    const updateErfApproved = async () => {
        setIsErfApproved(true);
        let updateClaim = {
            ...claim,
            erfStatus: ErfStatus["Approved by Provider"],
            erfApprovedOn: new Date(),
            productionStatus: ProductionStatus.Ready
        };
        await store.updateClaimChanges(updateClaim);
        await store.getClaimsByPatientId(patient.id);
        onClose();
    }

    const startNewRx = async () => {
        setIsNewRx(true);
        let updateClaim = {
            ...claim,
            claimStatus: ClaimStatus.Completed
        };
        await store.updateClaimChanges(updateClaim);
        await store.getClaimsByPatientId(patient.id);

        patientStore.setCurrentPatientId(patient.id);
        patientStore.togglePrescriptionModal();
        patientStore.toggleShowPrescriptionModal = true;

        store.setShowNewRx(true);
    }

    const markCompleted = async () => {
        setIsMarkCompleted(true);
        let updateClaim = {
            ...claim,
            actionItemStatus: ActionItemStatus.Completed
        };
        await store.updateClaimChanges(updateClaim);
        await store.getClaimsByPatientId(patient.id);
        onClose();
    }


    const duplicateClaim = async () => {
        setIsDuplicateClaim(true);
        var temp = "";
        var str = claim.rxId;
        if (str.includes('-C')) {
            temp = claim.rxId.split('-C');
            str = temp[temp.length - 1];
        }
        if (str.includes('-W')) {
            temp = claim.rxId.split('-W');
            str = temp[temp.length - 1];
        }
        if (str.includes('-WR')) {
            temp = store.claim.rxId.split('-WR');
            str = temp[temp.length - 1];
        }

        var finalStr = "";
        const numPart = str.match(/(0?[1-9])+$|0?([1-9]+?0+)$/)[0];
        const strPart = str.slice(0, str.indexOf(numPart));
        const isLastIndexNine = numPart.match(/9$/);

        if (isLastIndexNine) {
            finalStr = strPart + numPart.replace(/\d+$/, (n) => ++n);
        }
        else {
            finalStr = strPart + '0' + numPart.replace(/\d+$/, (n) => ++n);
        }

        let updateClaim = {
            ...claim,
            claimStatus: ClaimStatus.Completed
        };
        await store.updateClaimChanges(updateClaim);

        let newClaim = {
            ...claim,
            rxId: claim.rxId.includes('-W') ? temp[0] + "-W" + finalStr : finalStr,
            labRemakeNeeded: false
        };
        await store.createNewClaim(newClaim);

        await store.getClaimsByPatientId(patient.id);
        onClose();
    }

    return (
        <Grid xs={12} className="box-wizard-container">
            {(claim.erfstatus === ErfStatus["Pending Approval"]) && <Grid>
                <Grid className="title-wizard">
                    Estimated Repair fee will be, please approve:
                </Grid>
                <Grid xs={12} container direction={'row'}>
                    <Grid xs={8}></Grid>
                    <Grid xs={1}>
                        <Grid>ERF</Grid>
                        <Grid className="claim-data">${claim.erfamount ? claim.erfamount : 0}</Grid>
                    </Grid>
                    <Grid xs={3}>
                        <Button className="vivButton normal orange" disabled={isErfApproved} onClick={() => { updateErfApproved(); }}> Approve </Button>
                    </Grid>
                </Grid>
            </Grid>}
            {(claim.labRemakeNeeded === true && ((claim.claimType === ClaimType.Warranty) || claim.claimType === ClaimType["Warranty & Repair"]) && claim.claimStatus !== ClaimStatus.Completed) && <Grid>
                <Grid className="title-wizard">
                    A remake is needed for your claim: (Warranty Only)
                </Grid>
                <Grid xs={12} container direction={'row'}>
                    <Grid xs={8}></Grid>
                    <Grid xs={4}>
                        <Button className="vivButton normal orange" disabled={isDuplicateClaim} onClick={() => { duplicateClaim(); }}> Approve </Button>
                    </Grid>
                </Grid>
            </Grid>}
            {(claim.labRemakeNeeded && ((claim.claimType === ClaimType.Repair) || claim.claimType === ClaimType["Warranty & Repair"]) && claim.claimStatus !== ClaimStatus.Completed) && <Grid>
                <Grid className="title-wizard">
                    A remake is needed for your claim: (Repair Only)
                </Grid>
                <Grid xs={12} container direction={'row'}>
                    <Grid xs={8}></Grid>
                    <Grid xs={4}>
                        <Button className="vivButton normal orange" disabled={isNewRx} onClick={() => { startNewRx(); }}> Start New RX </Button>
                    </Grid>
                </Grid>
            </Grid>}
            {(claim.actionItemNotes !== null && claim.actionItemStatus === ActionItemStatus.Required) && <Grid>
                <Grid className="title-wizard">
                    An admin has requested for additional information:
                </Grid>
                <Grid xs={12} container direction={'row'}>
                    <Grid xs={8}>
                        <TextField
                            className="claim-textarea"
                            onChange={() => { }}
                            style={{ width: "100%" }}
                            minRows={4}
                            maxRows={4}
                            disabled={true}
                            value={claim.actionItemNotes}
                            margin="normal"
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Button className="vivButton normal orange" disabled={isMarkCompleted} onClick={() => { markCompleted(); }}> Mark Completed </Button>
                    </Grid>
                </Grid>
            </Grid>
            }
            {store.showNewRx &&
                <ModalContainer
                    show={store.showNewRx}
                    title="PRESCRIPTION"
                    onClose={() => {
                        patientStore.toggleShowPrescriptionModal = false;
                        store.setShowNewRx(false);
                        patientStore.loadData();
                        onClose();
                    }}
                    onClick={() => { }}
                >
                    <PrescriptionHorizontal patientId={patient.id}></PrescriptionHorizontal>
                </ModalContainer>

            }
        </Grid>
    )
}
);
