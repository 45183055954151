import React, { useState } from 'react';
import { Grid, TextField, MenuItem, Typography } from "@mui/material";

interface OralHabitProps {
    title: string;
    valueStrings: string[];
    currentAppointmentNote: any;
    setCurrentAppointmentNoteProp: any;
}

const OralHabitDetailsComponent: React.FC<OralHabitProps> = ({
    title,
    valueStrings,
    currentAppointmentNote,
    setCurrentAppointmentNoteProp,
}) => {
    const [renderKey, setRenderKey] = useState(0);

    const handleOftenChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setCurrentAppointmentNoteProp(event.target.value.toString(), valueStrings[0]);
        setRenderKey(renderKey + 1);
    };

    const handleLongChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setCurrentAppointmentNoteProp(event.target.value.toString(), valueStrings[1]);
        setRenderKey(renderKey + 1);
    };

    return (
        <Grid container alignItems={'center'} margin={1} padding={1}>
            <Grid item xs={4}>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    minRows={1}
                    required={false}
                    label="How Often"
                    select
                    onChange={handleOftenChange}
                    value={parseInt(currentAppointmentNote[valueStrings[0]]?.response)}
                >
                    <MenuItem key="0day" value="-1" >{"Select"}</MenuItem>
                    <MenuItem key="1day" value="1" >{"1x day"}</MenuItem>
                    <MenuItem key="2day" value="2" >{"2x day"}</MenuItem>
                    <MenuItem key="3day" value="3" >{"3x day"}</MenuItem>
                    <MenuItem key="4day" value="4" >{"4x day"}</MenuItem>
                    <MenuItem key="5day" value="5" >{"5x day"}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    minRows="1"
                    required={false}
                    label="How Long"
                    select
                    onChange={handleLongChange}
                    value={parseInt(currentAppointmentNote[valueStrings[1]]?.response)}
                >
                    <MenuItem key="0year" value="-1" >{"Select"}</MenuItem>
                    <MenuItem key="1year" value="0" >{"1 year"}</MenuItem>
                    <MenuItem key="2year" value="1" >{"2 years"}</MenuItem>
                    <MenuItem key="3year" value="2" >{"3 years"}</MenuItem>
                    <MenuItem key="4year" value="3" >{"4 years"}</MenuItem>
                    <MenuItem key="5year" value="4" >{"5+ years"}</MenuItem>
                </TextField>
            </Grid>
        </Grid>
    );
};

export default OralHabitDetailsComponent;
