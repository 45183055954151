import { action, makeAutoObservable, observable, toJS } from 'mobx';
import * as _ from 'lodash';

import jwt_decode from 'jwt-decode';
import { Gateway } from '../api/Gateway';
import { MainStore } from './MainStore';
import Submission from '../entities/Submission';
import PatientVisit from '../entities/PatientVisit';
import MyoCorrectPatient from '../entities/MyCorrectPatient';
import DictionaryListItem from '../entities/DictionaryListItem';
import { GlobalUtils } from '../api/GlobalUtils';
import PatientImage from '../entities/PatientImage';
import ProcessedImages from '../entities/ProcessedImages';

const defaultSubmission = {
    id: 0,
    patientId: 0,
    visitRecordId: 0,
    requestorId: 0,
    providerId: 0,
    statusId: 0,
    technicianId: 0,
    reportyTypeId: 0,
    requestDate: Date(),
    caseNumber: 0,
    daysInQueue: 0,
    isActive: true,
    addOns: [],
    processedImages: {},
};

interface csvValues {
    name: string;
    xValue?: string;
    yValue?: string;
    zValue?: string;
}

interface ReportGenerate {
    submissionId: number;
    reportType: string;
}

export default class AIReportEntryStore {
    mainStore: MainStore;
    @observable submission: any = {}; // = new Submission(defaultSubmission);
    @observable patient: any = {};
    @observable visit: any = {};
    @observable patientId = 0;
    @observable submissionId = 0;
    @observable activeTab = 0;
    @observable isEditMode = false;
    @observable isLoading = false;
    @observable isOpenModal = false;
    @observable processedImages: any[] = [];
    @observable images: any[] = [];
    @observable showCSVModal: boolean = false;
    @observable processedImagesTypes: DictionaryListItem[] = [];
    @observable intraoralImageTypes: DictionaryListItem[] = [];
    @observable extraoralImageTypes: DictionaryListItem[] = [];
    @observable occlusionImageTypes: DictionaryListItem[] = [];
    @observable addOnTypes: DictionaryListItem[] = [];
    @observable aiReportStatuses: DictionaryListItem[] = [];
    @observable extraoral2dimages: any[] = [];
    @observable extraoral3dimages: any[] = [];
    @observable intraoral2dimages: any[] = [];
    @observable occlusion2dimages: any[] = [];
    @observable occlusion3dimages: any[] = [];
    @observable cbctscan: any = {};
    userID: any;
    roles: any;
    @observable reportData: any = {};

    aiReportType: number = 0;

    reportGenerate: ReportGenerate = {
        reportType: '',
        submissionId: 0,
    };

    triggerReportGenerate: boolean = false;
    triggerShowAiReportModal: boolean = false;
    validationMessage: string[] = [];
    measurements: any[] = [];

    setMeasurements = (measurements: any[]) => {
        this.measurements = measurements;
    };
    setResetMeasurements = () => {
        this.measurements = [];
    };

    setValidationMessage = (message: string[]) => {
        this.validationMessage = message;
    };

    setTriggerShowAiReportModal = (value: boolean) => {
        this.triggerShowAiReportModal = value;
    };

    setTriggerReportGenerate = (value: boolean) => {
        this.triggerReportGenerate = value;
    };

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.loadTypes();
        this.clearStore();
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @action setReport = (value: Submission) => {
        this.submission = value;
    };
    @action setVisit = (value: PatientVisit) => {
        this.visit = value;
    };
    @action setPatient = (value: any) => {
        this.patient = value;
    };
    @action setSubmission = (value: Submission) => {
        this.submission = value;
    };
    @action setPatientId = (value: number) => {
        this.patientId = value;
    };
    @action setSubmissionId = (value: number) => {
        this.submissionId = value;
    };
    @action setImages = (value: any[]) => {
        this.images = value;
    };
    @action setProcessedImages = (value: any[]) => {
        this.processedImages = value;
    };
    @action setExtraoral2dImages = (value: any[]) => {
        this.extraoral2dimages = value;
    };
    @action setExtraoral3dImages = (value: any[]) => {
        this.extraoral3dimages = value;
    };
    @action setintraoral2dImages = (value: any[]) => {
        this.intraoral2dimages = value;
    };
    @action setOcclusion2dImages = (value: any[]) => {
        this.occlusion2dimages = value;
    };
    @action setOcclusion3dImages = (value: any[]) => {
        this.occlusion3dimages = value;
    };
    @action setProcessedImagesTypes = (value: any[]) => {
        this.processedImagesTypes = value;
    };
    @action setExtraoralImageTypes = (value: any[]) => {
        this.extraoralImageTypes = value;
    };
    @action setIntraoralImageTypes = (value: any[]) => {
        this.intraoralImageTypes = value;
    };
    @action setOcclusionImageTypes = (value: any[]) => {
        this.occlusionImageTypes = value;
    };
    @action setAddOnTypes = (value: any[]) => {
        this.addOnTypes = value;
    };
    @action setAiReportStatuses= (value: any[]) => {
        this.aiReportStatuses = value;
    };
    @action setCbctScan = (value: any) => {
        this.cbctscan = value;
    };
    @action setActiveTab = (value: number) => {
        this.activeTab = value;
    };
    @action setIsEditMode = (value: boolean) => {
        this.isEditMode = value;
    };
    @action setIsOpenModal = (value: boolean) => {
        this.isOpenModal = value;
    };
    @action setShowCsvModal = (value: boolean) => {
        this.showCSVModal = value;
    };
    @action setReportData = (value: any) => {
        this.reportData = value;
    };

    setReportGenerate = (value: ReportGenerate) => {
        this.reportGenerate = value;
    };

    setAiReportType = (value: number) => {
        this.aiReportType = value;
    };

    @action loadTypes = () => {
        this.setProcessedImagesTypes(GlobalUtils.getProcessedImageTypes());
        this.setIntraoralImageTypes(GlobalUtils.getIntraOralImages());
        this.setExtraoralImageTypes(GlobalUtils.getExtraOralImages());
        this.setOcclusionImageTypes(GlobalUtils.getPatientOcclusionImageTypes());
        this.setAddOnTypes(GlobalUtils.getAddOnTypeList());
        this.setAiReportStatuses(GlobalUtils.getAIReportStatuses());
    };

    @action
    addOnStatusChange = (value, id) => {
        Gateway.post(`aireports/addon/${id}/${value}`, {}).then(() => {
            
        })
    }

    @action
    handleClickModal = () => {
        this.isOpenModal = !this.isOpenModal;
    };

    handleCloseModal = () => {
        this.isOpenModal = false;
    };

    @action
    loadReport = async () => {
        this.setIsLoading(true);
        var url = `/aireports/${this.submissionId}`;
        await Gateway.get(url).then(async data => {
            var sub = data;
            var subObj = data as unknown as Submission;
            this.setAiReportType(subObj.reportTypeId);
            this.setReportData(subObj.reports);
            Gateway.get(`/visitrecord/${sub['patientId']}/visit/${sub['visitRecordId']}`).then(resp => {
                var visitrecord = resp;
                this.setVisit(visitrecord as unknown as PatientVisit);
                Gateway.get(`/patient/${sub['patientId']}`).then(patient => {
                    this.setPatient(patient as unknown as MyoCorrectPatient);
                    var imgs: PatientImage[] = [];
                    _.forEach(sub['processedImages'], i => {
                        var img = {} as PatientImage;
                        img.id = i.id;
                        img.data64Url = GlobalUtils.getAIReportUrl(i.url, this.patient.vivosId, sub['id']);
                        img.pathUrl = GlobalUtils.getAIReportUrl(i.url, this.patient.vivosId, sub['id']);
                        img['type'] = '2D';
                        var opt: DictionaryListItem | undefined = this.processedImagesTypes.find(f => {
                            return f.id == i['imageType'];
                        });
                        if (opt) {
                            img.imageType = opt;
                        }
                        imgs.push(img);
                    });
                    this.setSubmission(sub as unknown as Submission);
                    this.setReportData(sub);
                    this.setProcessedImages(imgs);
                    this.setIsLoading(false);
                });
            });
        });
    };

    @action
    getItemFromList = (list: any[], id: string, comparetor: number, responseField: string) => {
        var item = _.findIndex(list, f => {
            return f[id] == comparetor;
        });
        return item >= 0 ? list[item][responseField] : '';
    };

    @action
    onEditChange = (event: any) => {
        this.isEditMode = event.target.checked;
    };

    @action
    healthOptionExists = (options: number, option: number): boolean => {
        return (options | option) === 1 ? true : false;
    };

    @action
    clearStore = () => {
        this.setSubmissionId(0);
        this.clearImages();
        this.clearProcessedImages();
    };

    @action
    clearImages = () => {
        this.setExtraoral2dImages([]);
        this.setExtraoral3dImages([]);
        this.setintraoral2dImages([]);
        this.setOcclusion3dImages([]);
        this.setOcclusion2dImages([]);
        this.setCbctScan({});
    };

    clearProcessedImages = () => {
        this.setProcessedImages([]);
    };

    @action
    removeImage = async img => {
        var rImg = {} as ProcessedImages;
        rImg.id = img.id;
        rImg.imageType = img.imageType.id;
        rImg.submissionId = this.submission.id;
        rImg.url = img.pathUrl;
        rImg.isActive = false;
        Gateway.post(`/aiReports/${this.submission.id}/processedImage/${img.id}/remove`, rImg).then(() => {});
    };

    async doSaveWork(img) {
        if (!img.data64String && img.data64Url.startsWith('blob:')) {
            img.data64String = await this.convertBase64(img.data64Url).then(() => {
                Gateway.post('/aiReports/' + this.submission.id + '/processedImage', img).then(() => {});
            });
        } else if ((img.data64String && img.data64Url.startsWith('blob:')) || img['edited'] == true) {
            Gateway.post('/aiReports/' + this.submission.id + '/processedImage', img).then(() => {});
        }
    }

    async doSaveSubmittedImageWork(img) {
        if (!img.data64String && img.data64Url.startsWith('blob:')) {
            img.data64String = await this.convertBase64(img.data64Url).then(() => {
                Gateway.post(`/patient/${this.patient.id}/${this.visit.id}/${this.userID}/addpatientimage`, img).then(
                    () => {},
                );
            });
        } else if ((img.data64String && img.data64Url.startsWith('blob:')) || img['edited'] == true) {
            Gateway.post(`/patient/${this.patient.id}/${this.visit.id}/${this.userID}/addpatientimage`, img).then(
                () => {},
            );
        }
    }

    @action
    uploadProcessedImages = () => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forEach(this.processedImages, async img => {
                promises.push(this.doSaveWork(img));
            });
            Promise.all(promises).then(() => {
                resolve(true);
            });
        });
    };

    @action
    uploadSubmittedImages = () => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forEach(this.extraoral2dimages, async img => {
                if (img['edited'] == true) {
                    promises.push(this.doSaveSubmittedImageWork(img));
                }
            });
            _.forEach(this.intraoral2dimages, async img => {
                if (img['edited'] == true) {
                    promises.push(this.doSaveSubmittedImageWork(img));
                }
            });
            _.forEach(this.occlusion2dimages, async img => {
                if (img['edited'] == true) {
                    promises.push(this.doSaveSubmittedImageWork(img));
                }
            });
            Promise.all(promises).then(() => {
                this.loadReport().then(() => {
                    resolve(true);
                });
            });
        });
    };

    convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    async doImagesWork(file, procImage, imglist) {
        var img = {} as PatientImage;
        await fetch(file).then(r => {
            r.blob().then(async blobFile => {
                var image = URL.createObjectURL(blobFile);
                await this.convertBase64(blobFile).then(resp => {
                    img.id = procImage.id;
                    img.data64String = resp as unknown as string;
                    img.data64Url = decodeURI(image);
                    img.pathUrl = decodeURI(image);
                    img['type'] = '2D';
                    var opt: DictionaryListItem | undefined = this.getTypeFromName(image);
                    if (opt) {
                        img.imageType = opt;
                    }
                    // this.selectedOptions.push(opt ? opt.id : 0);
                    imglist.push(img);
                });
            });
        });
    }

    @action
    processedImagesToPatientImages = images => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            if (!this.patient.vivosId || !this.submission.id) {
                return;
            }
            var tempImages: any[] = [];
            _.forEach(images, i => {
                promises.push(
                    this.doImagesWork(
                        GlobalUtils.getAIReportUrl(i.url, this.patient.vivosId, this.submission.id),
                        i,
                        tempImages,
                    ),
                );
            });

            Promise.all(promises).then(() => {
                this.setProcessedImages(tempImages);
                resolve(true);
            });
        });
    };

    @action
    visitImagesToPatientImages = () => {
        this.clearImages();
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forOwn(this.visit.patientsImages[0], async (val, k) => {
                if (val) {
                    if (k.toString().toLowerCase().indexOf('extraoral') >= 0) {
                        if (k.toString().toLowerCase().indexOf('3d') >= 0) {
                            this.extraoral3dimages.push({ key: k, value: val, type: '3D' });
                        } else {
                            var img = {} as PatientImage;
                            img.id = 0;
                            img.data64Url = decodeURI(val);
                            img.pathUrl = decodeURI(val);
                            img['filename'] = k;
                            img['type'] = '2D';
                            var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                            if (opt) {
                                img.imageType = opt;
                            }
                            this.extraoral2dimages.push(img);
                        }
                    } else if (k.toString().toLowerCase().indexOf('intraoral') >= 0) {
                        var img = {} as PatientImage;
                        img.id = 0;
                        img.data64Url = decodeURI(val);
                        img.pathUrl = decodeURI(val);
                        img['filename'] = k;
                        img['type'] = '2D';
                        var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                        if (opt) {
                            img.imageType = opt;
                        }
                        this.intraoral2dimages.push(img);
                    } else if (k.toString().toLowerCase().indexOf('cbctscan') >= 0) {
                        this.cbctscan = [{ key: k, value: val, type: 'zip' }];
                    } else if (k.toString().toLowerCase().indexOf('occlusion') >= 0) {
                        if (
                            k.toString().toLowerCase().indexOf('3dupperarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('3dlowerarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('3dconstructedbite') >= 0
                        ) {
                            this.occlusion3dimages.push({ key: k, value: val, type: '3D' });
                        } else if (
                            k.toString().toLowerCase().indexOf('occlusionupperarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionlowerarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionanterior') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionleft') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionright') >= 0
                        ) {
                            var img = {} as PatientImage;
                            img.id = 0;
                            img.data64Url = decodeURI(val);
                            img.pathUrl = decodeURI(val);
                            img['filename'] = k;
                            img['type'] = '2D';
                            var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                            if (opt) {
                                img.imageType = opt;
                            }
                            this.occlusion2dimages.push(img);
                        }
                    }
                }
            });
            Promise.all(promises).then(() => {
                this.setExtraoral2dImages(this.extraoral2dimages);
                this.setExtraoral3dImages(this.extraoral3dimages);
                this.setintraoral2dImages(this.intraoral2dimages);
                this.setOcclusion2dImages(this.occlusion2dimages);
                this.setOcclusion3dImages(this.occlusion3dimages);
                this.setCbctScan(this.cbctscan);
                resolve(true);
            });
        });
    };

    @action
    downloadFiles = async submissionId => {
        const urls = await Gateway.get(`aireports/images/${submissionId}`);
        urls.forEach(url =>
            setTimeout(function () {
                var a = document.createElement('a');
                a.setAttribute('href', url);
                document.body.appendChild(a);
                a.setAttribute('download', '');
                a.setAttribute('target', '_blank');
                a.click();
                a.remove();
            }, 500),
        );
    };

    @action
    uploadMeasurements = (submissionId: number) => {
        this.openCSVModal(submissionId);
    };

    @action
    openCSVModal = (submissionId: number) => {
        this.setShowCsvModal(true);
    };

    @action
    parseCSV = async modalText => {
        const rows = modalText.split('\n');
        const values: csvValues[] = rows.reduce((newRows, currentRow) => {
            var rowValues = currentRow.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
            if (rowValues) {
                for (var n = 0; n < rowValues.length; ++n) {
                    rowValues[n] = rowValues[n].trim();
                    if (rowValues[n] === ',') rowValues[n] = '';
                }
                if (currentRow[0] === ',') rowValues.unshift('');

                if (rowValues.length > 1) {
                    const csvValue: csvValues = {
                        name: rowValues[0],
                    };
                    if (csvValue.name && !csvValue.name.startsWith('---') && rowValues[1]) {
                        csvValue.xValue = rowValues[1];
                        if (rowValues.length > 2) {
                            csvValue.yValue = rowValues[2];
                        }
                        if (rowValues.length > 3) {
                            csvValue.zValue = rowValues[3];
                        }
                        return [...newRows, csvValue];
                    }
                }
            }
            return [...newRows];
        }, [] as csvValues[]);
        await Gateway.post(`${this.submissionId}/csv`, values);
        this.setShowCsvModal(false);
    };

    @action
    resetSubmission = async () => {
        await this.setSubmission({} as Submission);
        await this.setVisit({} as PatientVisit);
        await this.setPatient({} as MyoCorrectPatient);
        await this.clearStore();
    };

    @action
    getOptionFromFileName = (name: string): DictionaryListItem | undefined => {
        var nameseg = name.match(/.*(\s+-\s+)(.*)/);
        if (!nameseg) {
            nameseg = name.match(/.*(\s+)(.*)/);
        }
        var seg = nameseg && nameseg.length > 0 ? nameseg[nameseg.length - 1] : '';
        switch (seg) {
            case 'Airway.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 457;
                });
                break;
            case 'CB.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 458;
                });
                break;
            case 'CI.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 459;
                });
                break;
            case 'Digitized Arch.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 460;
                });
                break;
            case 'Facial Front at Rest.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 461;
                });
                break;
            case 'Facial Front Big Smile.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 462;
                });
                break;
            case 'Facial Profile R.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 463;
                });
                break;
            case 'Faciometric Frontal.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 464;
                });
                break;
            case 'Faciometric Profile.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 465;
                });
                break;
            case 'Grummons Analysis.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 466;
                });
                break;
            case 'IC.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 467;
                });
                break;
            case 'Intraoral Center.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 468;
                });
                break;
            case 'Intraoral Left.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 469;
                });
                break;
            case 'Intraoral Right.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 470;
                });
                break;
            case 'Model Center.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 471;
                });
                break;
            case 'Model Left.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 472;
                });
                break;
            case 'Model Lower.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 473;
                });
                break;
            case 'Model Right.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 474;
                });
                break;
            case 'Model Upper.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 475;
                });
                break;
            case 'Occlusal Lower.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 476;
                });
                break;
            case 'Occlusal Upper.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 477;
                });
                break;
            case 'PN.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 478;
                });
                break;
            case 'RG.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 479;
                });
                break;
            case 'RP.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 480;
                });
                break;
            case 'Submento Vertex.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 481;
                });
                break;
            case 'TMJ Sagittal.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 482;
                });
                break;
            case 'TPW.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 483;
                });
                break;
            case 'Undigitized Arch.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 484;
                });
                break;
            case 'Ventrical Surface of Tongue.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 485;
                });
                break;
            case 'Volume 1.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 486;
                });
                break;
            case 'Volume 2.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 487;
                });
                break;
            case 'X-ray Frontal.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 488;
                });
                break;
            case 'X-ray Lateral CPT Code 70350.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 489;
                });
                break;
            case 'X-ray Paranasal Sinus CPT Code 70220.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 490;
                });
                break;
            case 'X-ray Orthopantogram CPT Code 70355.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 491;
                });
                break;
            case 'X-ray Submento Vertex.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 492;
                });
                break;
            case 'X-ray TMJ Tomogram 1.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 493;
                });
                break;
            case 'Frontal_00.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 494;
                });
                break;
            case 'Profile_00.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 495;
                });
                break;
            case 'Sassouni Report.jpg':
                return this.processedImagesTypes.find(f => {
                    return f.id == 561;
                });
                break;
            default:
                return undefined;
        }
    };

    @action
    getTypeFromName = (name: any) => {
        switch (name.toLowerCase()) {
            // case "cbctScan":
            //     return "CBCT Scan";
            //     break;
            case 'extraoralfrontrelaxed':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 182;
                }); // "Front Relaxed";
                break;
            case 'extraoralbigsmile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 181;
                }); // "Big Smile";
                break;
            case 'extraoralrightprofile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 183;
                }); // "Right Profile";
                break;
            case 'extraoralsubmentovertex':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 184;
                }); // "Submento Vertex";
                break;
            case 'extraoralfullbodypostanterior':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 185;
                }); // "Full Body Post Anterior";
                break;
            case 'extraoralfullbodypostright':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 186;
                }); // "Full Body Post Right";
                break;
            case 'extraoral3dfacescan':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 187;
                }); // "3D Face Scan";
                break;
            case 'intraoralupperarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 170;
                }); //  "Upper Arch";
                break;
            case 'intraorallowerarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 171;
                }); // "Lower Arch";
                break;
            case 'intraoralanteriorocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 172;
                }); // "Anterior Occlusion";
                break;
            case 'intraoralleftocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 173;
                }); //  "Left Occlusion";
                break;
            case 'intraoralrightocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 174;
                }); //  "right Occlusion";
                break;
            case 'intraorallingualfrenum':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 220;
                }); // "Lingual Frenum";
                break;
            case 'occlusion3dupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 221;
                }); // "3D Upper Arch";
                break;
            case 'occlusion3dlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 222;
                }); //  "3D Lower Arch";
                break;
            case 'occlusion3dconstructedbite':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 223;
                }); //  "3D Constructed Bite";
                break;
            case 'occlusionupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 224;
                }); // "Upper Arch";
                break;
            case 'occlusionlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 225;
                }); //  "Lower Arch";
                break;
            case 'occlusionanterior':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 226;
                }); //  "Anterior";
                break;
            case 'occlusionleft':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 227;
                }); //  "Left";
            break;
            case 'occlusionright':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 228;
                }); //  "Right";
            break;
            default:
                return;
        }
    };

    @action
    generateReport = () => {
        let report = {
            submissionId: this.submissionId,
            reportType: this.aiReportType === 63 ? 'Initial' : 'Mid',
        };

      //  if (this.processedImages.length === 39 && this.measurements) {
            Gateway.generatedAiReport(
                `api/AIRCreationRequest`,
                report,
            ).then((response: any) => {
                if (response) {
                    this.setValidationMessage([
                        'Your request to generate AI Report has been submitted successfully. Once the report is ready for review, the system will notify you.',
                    ]);
                    this.setTriggerReportGenerate(true);
                }
            });
        // } else if (this.processedImages.length < 30 && !this.measurements.length) {
        //     this.setValidationMessage(['All the processed images are required', 'Dolphin measurement are required']);
        //     this.setTriggerReportGenerate(true);
        // } else if (this.processedImages.length < 30) {
        //     this.setValidationMessage(['All the processed images are required']);
        //     this.setTriggerReportGenerate(true);
        // } else if (!this.measurements.length) {
        //     this.setValidationMessage(['Dolphin measurement are required']);
        //     this.setTriggerReportGenerate(true);
        // }
    };

    @action
    validate = (): string[] => {
        let fieldValidationList: string[] = [];
        _.forEach(this.processedImages, image => {
            if (!image.imageType) {
                fieldValidationList.push('All images require an image type');
                return;
            }
        });

        return fieldValidationList;
    };

    // @action updateCbctDetails = () => {
    //     return new Promise((resolve, reject) => {
    //       var promises: any[] = [];
    //       var details = {
    //         cbctFov01: this.fov02,
    //         cbctFov02: this.fov01,
    //         machineBrandId: this.cbctMachineBrand
    //       }
    //       promises.push(Gateway.post(`visitrecord/${this.visitId}/cbctdetails`, details).then(() => { }));
    //       Promise.all(promises).then(() => {
    //         resolve(true);
    //       })
    //     });
    
      //}
}
