import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

interface ComponentProps {
    consultationData: any;
}

export const P2PConsentStep: FC<ComponentProps> = ({ consultationData }) => {
    return (
        <Grid container spacing={3} padding={5}>
            <Grid item display="flex">
                <Typography variant="h5">
                    By clicking 'Confirm & Continue', I consent to sharing patient medical information with {consultationData.clinicalAdvocate.firstName} {consultationData.clinicalAdvocate.lastName} for a limited duration.
                </Typography>
            </Grid>
        </Grid>
    );
};
