// Vendors
import React from 'react';
import { FC, useState } from 'react';
import { Grid, MenuItem, TextField, Typography, Box } from '@mui/material';

// Components
import PatientIcon from '../../../imgs/png/Patient.png';
import { ReactComponent as RadiologySvg } from '../../../imgs/aireports/radiology-report.svg';
import { ReactComponent as FrontalAnalysis } from '../../../imgs/aireports/Frontal-Analysis.svg';
import { ReactComponent as SassouniSvg } from '../../../imgs/aireports/Sassouni.svg';
import { ReactComponent as Print3DSvg } from '../../../imgs/aireports/Print_3D.svg';
import SassouniImg from '../../../imgs/aireports/Sassouni.svg';

// Entities
import AIReport from '../../../entities/AIReports';
import Submission from '../../../entities/Submission';
import MyoCorrectPatient from '../../../entities/MyCorrectPatient';

// Stores
import { useMainStoreContext } from '../../../stores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';

// Styling
import '../../../scss/_PatientEdit.scss';
import '../../../scss/_global.scss';


interface IGIProps {
    submission: Submission;
    aiReport: AIReport;
    patient: MyoCorrectPatient;
}

export const AIReportHeaderDetails: FC<IGIProps> = observer(({ submission, aiReport, patient }) => {
    const { downloadFiles, uploadMeasurements, generateReport } = useMainStoreContext().aiReportEntryStore;
    const { aiStatus, handleStatusChange } = useMainStoreContext().aiReportsStore;
    const [openComments, setOpenComments] = useState(false);

    return submission ? (
        <Grid container height={'100%'}>
            <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'}>
                <Grid container>
                    <Grid item xs={12} paddingBottom={1}>
                        <Typography variant='h2' color={'white'} textAlign={'center'}>
                            AI Report Request Details
                        </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography variant="h5" color={'white'}>
                            Report Status:
                        </Typography>
                        <TextField
                            sx={{ width: '200px' }}
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'aireportheader.statusId'}
                            select
                            defaultValue="0"
                            value={submission.statusId ? submission.statusId : 0}
                            onChange={e => {
                                handleStatusChange(submission.id, e.target.value);
                            }}
                        >
                            {aiStatus.map((item: any) => (
                                <MenuItem key={item.id + item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={8} pl={2}>
                        <Typography variant="h5" color={'white'} textAlign={"left"}>
                            Add-Ons Requested:
                        </Typography>

                        <Box height={'50px'} sx={{ background: 'white' }} border={2} borderRadius={1} borderColor={'white'}>
                            <Grid container direction="row" >
                                {
                                    _.map(submission.addOns, ((item) => {
                                        if (item.addOnType === 105) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid> <RadiologySvg width={50} height={50} className={'svgGrid status-' + item.statusId}></RadiologySvg></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 106) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid><img style={{ width: "50px", height: "50px" }} src={SassouniImg} className={'svgGrid status-' + item.statusId} /></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 108) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid> <Print3DSvg width={50} height={50} className={'svgGrid status-' + item.statusId}></Print3DSvg></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 107) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid><FrontalAnalysis width={50} height={50} className={'svgGrid status-' + item.statusId}></FrontalAnalysis></Grid>
                                                </Grid>
                                            );
                                        }
                                    }))
                                }
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </Grid>
    ) : (
        <></>
    );
});
