import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, MenuItem } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import react, { FC } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Item from "../../components/Item";
import CssTextField from "../../components/CssTextField";
import CssSelectField from "../../components/CssSelectField";
import UploadImgDragDrop from "../../components/UploadDragAndDrop/UploadImgDragDrop";
import { GlobalUtils } from "../../api/GlobalUtils";
import _ from "lodash";
import { useMainStoreContext } from "../../stores/MainStore";

interface IGIProps {
}


export const ProviderPreferences: FC<IGIProps> = observer(() => {
    const store = useMainStoreContext().providerStore;
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [invalidEmail, setInvalidEmail] = React.useState(false);
    const [unitType, setUnitType] = React.useState(0);

    react.useEffect(() => {
        setExpanded('panel1');
        store.getProviderPreferences().then(() => {
            setUnitType(store.providerPreferences.unitType ? 1 : 2);
        });
    }, []);

    react.useEffect(() => {
        store.providerPreferences.unitType = unitType == 1 ? true : false;
    }, [unitType]);

    const handleChange = (current: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? current : false);
    };

    let teethSetValues: any = [];
    const teethSetMap = (): any => {
        teethSetValues = GlobalUtils.getTeethSetType();
        var teethSet = _.map(teethSetValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return teethSet;
    };

    let teethNumSystemValues: any = [];
    const teethNumSystemMap = (): any => {
        teethNumSystemValues = GlobalUtils.getTeethNumSystemType();
        var teethNumSystem = _.map(teethNumSystemValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return teethNumSystem;
    };

    let cbctMacBrandValues: any = [];
    const cbctBrandMap = (): any => {
        cbctMacBrandValues = GlobalUtils.getCBCTMachineBrands();
        var cbctBrand = _.map(cbctMacBrandValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return cbctBrand;
    };

    const resetPassword = () => {
        store.emailNotExist = false;
        store.emailSent = false;
        if (!GlobalUtils.validateEmail(store.emailReset ? store.emailReset : "")) {
            setInvalidEmail(true);
        }
        else {
            store.resetPassword();
            setInvalidEmail(false);
            store.emailReset = "";
        }
    }

    return <>
        <Grid xs={12} className="patientDetailsTab">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid xs={12} container direction={'row'} className="headerLine ">
                        <Grid className="label">
                            <Grid>PREFERENCES</Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className="viewBody" xs={12} container direction={'row'}>
                        <Grid item xs={4}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Unit Type </Grid>
                                    </Grid>
                                    <CssSelectField
                                        size="small"
                                        className="DateBox"
                                        sx={{ width: '100%' }}
                                        onChange={(event: any) => {
                                            setUnitType(event.target.value as unknown as number);
                                        }}
                                        value={unitType}
                                    >
                                        <MenuItem key={1} value={1}>{'Metric'}</MenuItem>
                                        <MenuItem key={2} value={2}>{'US'}</MenuItem>
                                    </CssSelectField>
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>FOV1</Grid>
                                    </Grid>
                                    <CssSelectField
                                        size="small"
                                        className="DateBox"
                                        sx={{ width: '100%' }}
                                        onChange={(event: any) => {
                                            store.providerPreferences.fov1 = event.target.value as unknown as number;
                                        }}
                                        value={store.providerPreferences.fov1 ? store.providerPreferences.fov1 : ''}
                                    >
                                        {/* {fovMap()} */}
                                    </CssSelectField>
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>FOV2 </Grid>
                                    </Grid>
                                    <CssSelectField
                                        size="small"
                                        className="DateBox"
                                        sx={{ width: '100%' }}
                                        onChange={(event: any) => {
                                            store.providerPreferences.fov2 = event.target.value as unknown as number;
                                        }}
                                        value={store.providerPreferences.fov2 ? store.providerPreferences.fov2 : ''}
                                    >
                                        {/* {fovMap()} */}
                                    </CssSelectField>
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction={'row'}>
                        <Grid item xs={4}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Teeth Set</Grid>
                                    </Grid>
                                    <CssSelectField
                                        size="small"
                                        className="DateBox"
                                        sx={{ width: '100%' }}
                                        onChange={(event: any) => {
                                            store.providerPreferences.teethSetPref = event.target.value as unknown as number;
                                        }}
                                        value={store.providerPreferences.teethSetPref ? store.providerPreferences.teethSetPref : ''}
                                    >
                                        {teethSetMap()}
                                    </CssSelectField>
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Teeth Numering System</Grid>
                                    </Grid>
                                    <CssSelectField
                                        size="small"
                                        className="DateBox"
                                        sx={{ width: '100%' }}
                                        onChange={(event: any) => {
                                            store.providerPreferences.teethSystemPref = event.target.value as unknown as number;
                                        }}
                                        value={store.providerPreferences.teethSystemPref ? store.providerPreferences.teethSystemPref : ''}
                                    >
                                        {teethNumSystemMap()}
                                    </CssSelectField>
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl>
                                <Item>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>CBCT Machine Brand</Grid>
                                    </Grid>
                                    <CssSelectField
                                        size="small"
                                        className="DateBox"
                                        sx={{ width: '100%' }}
                                        onChange={(event: any) => {
                                            store.providerPreferences.cbctsbrand = event.target.value as unknown as number;
                                        }}
                                        value={store.providerPreferences.cbctsbrand ? store.providerPreferences.cbctsbrand : ''}
                                    >
                                        {cbctBrandMap()}
                                    </CssSelectField>
                                </Item>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                            <Button className="vivButton large invert" onClick={() => { store.saveProviderPreferences() }}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid xs={12} container direction={'row'} className="headerLine ">
                        <Grid className="label">
                            <Grid>LOGO</Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className="viewBody" xs={12} container direction={'column'}>
                        <UploadImgDragDrop
                            type=".jpg"
                            typeOptions={[]}
                            vivosId={store.providerPreferences.firstName ? store.providerPreferences.firstName : ""}
                            visitId={store.providerPreferences.id ? store.providerPreferences.id : 0}
                            extImages={store.logoImage}
                            parentCallback={() => { }}
                            deleteCallback={() => { }} />

                        {store.logoUploaded && <Grid container direction={'row'} className="formLabel">
                            <Grid>Image uploaded successfully.</Grid>
                        </Grid>}
                    </Grid>
                    <Grid>
                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                            <Button className="vivButton large invert" onClick={() => { store.saveLogoImage() }}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid xs={12} container direction={'row'} className="headerLine ">
                        <Grid className="label">
                            <Grid>PROFILE IMAGE</Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className="viewBody" xs={12} container direction={'column'}>
                        <UploadImgDragDrop
                            type=".jpg"
                            typeOptions={[]}
                            vivosId={store.providerPreferences.firstName ? store.providerPreferences.firstName : ""}
                            visitId={store.providerPreferences.id ? store.providerPreferences.id : 0}
                            extImages={store.profileImage}
                            parentCallback={() => { }}
                            deleteCallback={() => { }} />

                        {store.profileImageUploaded && <Grid container direction={'row'} className="formLabel">
                            <Grid>Image uploaded successfully.</Grid>
                        </Grid>}
                    </Grid>
                    <Grid>
                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                            <Button className="vivButton large invert" onClick={() => { store.saveProfileImage() }}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid xs={12} container direction={'row'} className="headerLine ">
                        <Grid className="label">
                            <Grid>RESET PASSWORD</Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className="viewBody" xs={12} container direction={'column'}>
                        <FormControl>
                            <Item>
                                <Grid container direction={'row'} className="formLabel">
                                    <Grid>Please enter your email address to start the password reset process.</Grid>
                                </Grid>
                                <CssTextField
                                    variant="outlined"
                                    className="TextBox"
                                    size="small"
                                    placeholder=""
                                    onChange={(event: any) => { store.emailReset = event.target.value }}
                                    value={store.emailReset}
                                />
                            </Item>
                            {invalidEmail && <Grid color={"red"} container direction={'row'} className="formLabel">
                                <Grid>Invalid email address.</Grid>
                            </Grid>}
                            {store.emailNotExist && <Grid color={"red"} container direction={'row'} className="formLabel">
                                <Grid>The user does not exist.</Grid>
                            </Grid>}
                            {store.emailSent && <Grid container direction={'row'} className="formLabel">
                                <Grid>Mail sent successfully.</Grid>
                            </Grid>}
                        </FormControl>
                    </Grid>
                    <Grid>
                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                            <Button className="vivButton large invert" onClick={() => { resetPassword() }}>
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </>
});