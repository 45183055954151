// Vendors
import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    styled,
    Popover,
    Typography,
    Box,
    Divider,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel,
} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

// Components
import ScanOcclusion from '../../../ImagesAndDocs/ScanOcclusion';
import { VisitRecordSectionModule } from '../../../VisitRecords/VisitRecordSelectionModule';

// Entities
import Claim from "../../../../entities/Claim";

// Stores
import { useMainStoreContext } from "../../../../stores/MainStore";

// API
import { observer } from "mobx-react-lite";
import { FileUploader } from "react-drag-drop-files";
import _ from "lodash";


interface IGIProps {
    claim: Claim;
}

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        background: transparent;
        transition: ${theme.transitions.create(['all'])};
        color: ${theme.colors.warning.light};
        border-radius: 50px;
    
        &:hover {
          background: transparent;
          color: ${theme.colors.warning.dark};
        }
    `
);

export const RecordsStep: React.FC<IGIProps> = observer(({ claim }) => {
    const store = useMainStoreContext().reworkClaimStore;
    const { id, visitRecords, patientsImages, getVisitId, loadVisits, setImages, setVisitPatientId } =
        useMainStoreContext().visitRecordStore;
    // const fileTypes = ["JPG", "PNG", "JPEG"];
    const [isDigital, setIsDigital] = React.useState(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [processValue, setProcessValue] = React.useState('option1');
    const [processOriginalValue, setProcessOriginalValue] = React.useState('');
    const handleProcessChange = (event) => {
        setProcessValue(event.target.value);
    };
    const handleProcessOriginalChange = (event) => {
        setProcessOriginalValue(event.target.value);
    };

    const CLAIMDOCUMENTTYPE = 790;

    // React.useEffect(() => {
    //     setVisitPatientId(store.patient);
    //     loadVisits().then(resp => {
    //         setVisit(resp[0]);
    //         setPatient(patient);
    //         // setVisitId(id);
    //         setVivosId(patient.vivosId as string);
    //         visitImagesToPatientImages().then((res) => {
    //             isEmpty(submission)
    //                 && setAiReportOrderData(resp[0].id, patient.id, patient.providerId, patient.aiReportType, addOns, submission)
    //             // : setAiReportOrderData(resp[0].id, patient.id, patient.providerId, GlobalUtils.getAIReportTypes().find(x => x.listItemKey === COMPARISONAIREPORT), addOns, submission);
    //             setHasLoaded(true);
    //         });
    //     });
    // }, []);


    async function doWork(file, imglist) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileUpload = {
                fileName: file.name,
                dataBody: (reader.result as string).split(',')[1],
                active: true,
                imageType: {
                    id: CLAIMDOCUMENTTYPE
                }
            };
            imglist.push(fileUpload);
        }
    }

    const handleChangeFile = (files) => {
        store.setFilesToUpload(true);
        var imglist: FileReader[] = store.claimFiles;
        var promises: any[] = [];
        _.forEach(files, async (file) => {
            const promise = doWork(file, imglist);
            promises.push(promise);
        })
        Promise.all(promises).then(() => {
            store.setClaimFiles(imglist);
        })
    };

    const deleteFile = (file) => {
        store.setFilesToUpload(false);
        store.removeFile(file, claim.id);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Grid padding={1} margin={1} marginBottom={10} className="box-wizard-container">
            <Grid xs={12} >
                <Grid xs={12} container direction='row' className="title-wizard">
                    <Grid xs={11} marginY={1} paddingLeft={1}>
                        Please choose the option that best fits how you will be moving forward regarding your impressions:
                    </Grid>
                    <Grid xs={1} style={{ color: 'orange', fontSize: '8px' }}>
                        <IconButtonWrapper
                            sx={{
                                ml: 0.5
                            }}
                            onClick={handlePopoverOpen}
                        >
                            <HelpTwoToneIcon fontSize="small" />
                        </IconButtonWrapper>
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{ style: { width: '500px', padding: '20px' } }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h2'>New Model Reminder</Typography>
                                </Grid>
                                <Grid item xs={12} padding={1}>
                                    <Typography variant='h4'>
                                        The manufacturing lab needs updated models with EVERY rework request.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' padding={1}>
                                        To ensure timely processing of your request, please follow these
                                        guidelines based on the type of models associated with the appliance:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' padding={1}>
                                        For <strong>DIGITAL</strong> models, please upload the most recent .STL files
                                        to this rework request.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' padding={1}>
                                        For <strong>PHYSICAL</strong> models, ship the latest models together with the appliance.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' padding={1}>
                                        It is important to note that if the appliance is within the warranty period
                                        (60 days from original ship date), the lab requires the models used in the
                                        appliance's initial fabrication to be returned alongside the appliance.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' padding={1}>
                                        If the appliance is part of a DUAL DNA WITH occlusal coverage, mRNA or mmRNA,
                                        we request that you ship both the upper and lower appliances to the lab. Additionally,
                                        along with the models a 3-4mm/5-6mm bite, depending on the type of appliance will
                                        need to be included. This will enable us to process your request efficiently.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Popover>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="options"
                            name="radio-buttons-group"
                            value={processValue}
                            onChange={handleProcessChange}
                        ><Grid paddingLeft={3}>
                                <FormControlLabel value="option1" control={<Radio />} label="I will be submitting new impressions for this rework claim." />
                                <FormControlLabel value="option2" control={<Radio />} label="I will be selecting impressions from a previous visit record." />
                                <FormControlLabel value="option3" control={<Radio />} label="I would like the original impressions used for this RX to be utilized." />
                                <FormControlLabel value="option4" control={<Radio />} label="I am opting out of adding new impressions or impressions do not apply for my rework claim." />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            {processValue === "option1" &&
                <Grid container paddingTop={2}>
                    <ScanOcclusion isIntake={false} visit={undefined} isRework={true} />
                </Grid>}
            {processValue === "option2" &&
                <Grid container justifyContent={'center'} paddingTop={2}>
                    <VisitRecordSectionModule visits={visitRecords} />
                </Grid>}
            {processValue === "option3" &&
                <Grid container justifyContent={'center'} paddingTop={2} sx={(theme) => ({
                    borderRadius: `${theme.general.borderRadius}`,
                })}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            <Typography variant="h5">
                                Regarding your original impressions what will you be doing:
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            aria-label="options"
                            name="radio-buttons-group"
                            value={processOriginalValue}
                            onChange={handleProcessOriginalChange}
                        ><Grid paddingLeft={3}>
                                <FormControlLabel value="option1" control={<Radio />} label="I will be sending back the original impressions to the lab." />
                                <FormControlLabel value="option2" control={<Radio />} label="I need the lab to reprint these original impressions and understand their will be an additional fee." />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>}
            {processValue === "option4" &&
                <Grid container direction={'column'} justifyContent={'center'} alignContent={'center'} paddingTop={2} sx={(theme) => ({
                    borderRadius: `${theme.general.borderRadius}`,
                })}>

                    <Typography variant="h4">
                        Disclaimer:
                    </Typography>
                    <Typography variant="subtitle1">
                        By opting out of adding new impressions, new impressions may still be requested.
                    </Typography>

                </Grid>}


            {/* {store.newRecordSubmitted && <Grid xs={12} className="box-wizard-container">
                <Grid xs={12} className="title-wizard"> Impressions </Grid>
                <Button className={isDigital ? 'vivButton invert' : 'vivButton'} onClick={() => { setIsDigital(true) }}>Digital</Button>
                <Button className={isDigital ? 'vivButton' : 'vivButton invert'} onClick={() => { setIsDigital(false) }}>Physical</Button>
                {isDigital &&
                    <Grid>
                        <FileUploader
                            label="Upload or drop a image here"
                            name="file"
                            handleChange={handleChangeFile}
                            multiple={true}
                            types={fileTypes}
                            children={
                                <Grid xs={12} container direction="column" className="wizardUploadImgContainer">
                                    <>
                                        <Grid item>Drag & Drop Image(s) or</Grid>
                                        <Grid item>Click to Select Image(s)</Grid>
                                    </>
                                </Grid >
                            } />
                        <Grid xs={12} container direction='row'>
                            <FormControlLabel control={<Checkbox onChange={() => { }} />} label={"Check here if construction bite is embedded in the upper and lower arch STLs"} />
                            {store.claimFiles.length > 0 ? (store.claimFiles.filter(x => x.imageType.id === CLAIMDOCUMENTTYPE).map(f => (
                                <Grid xs={12} container direction="row" className="claimuploadImgWizard">
                                    <Grid xs={10}>{f.pathUrl ? f.pathUrl : f.fileName}</Grid>
                                    <Grid xs={2}>
                                        <Button onClick={() => { deleteFile(f) }}><DoNotDisturbOnIcon /></Button>
                                    </Grid>
                                </Grid>
                            ))) : <> </>
                            }
                        </Grid>
                    </Grid>}
                {!isDigital && <Grid>
                    <Grid xs={12} className="title-wizard"> Mailing Instructions </Grid>
                </Grid>}
            </Grid>} */}
        </Grid>
    )
}
);
