import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { CircularProgress, Grid, Button, SelectChangeEvent, IconButton, MenuItem } from '@mui/material';
import Cropper from "react-cropper";
import _ from "lodash";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { GlobalUtils } from "../../api/GlobalUtils";


const MAXWIDTH = 430;
const MAXHEIGHT = 250;
const MAXCROPPERWIDTH = 100;
const MAXCROPPERHEIGHT = 100;
interface IUploaderProps {
    index: number;
    images: any[];
    cropperIndex: number;
    typeOptions: any[];
    selectedOptions: number[];
    imageOption?: number;
    vivosId: string;
    container: string,
    containerId: number;
    isEditable?: boolean;
    getCropDataCallBack(data64string: string, dataPathUrl: string, imageType: any): any;
    handleOptionSelect(event: any, oldOption: number, index: number, isRemoval: boolean): any;
    handleRemove(index: number): any;
    setImages(images: any[]): void;
}

const EditRotateCropper: React.FC<IUploaderProps> =
    ({ images, cropperIndex, index, typeOptions, selectedOptions, imageOption, vivosId, container, isEditable, containerId, handleRemove, handleOptionSelect, setImages }) => {
        const [cropData, setCropData] = React.useState("#");
        const [cropper, setCropper] = React.useState<any>();
        const [options, setOptions] = React.useState<any[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [imageLoading, setImageLoading] = React.useState(true);

        useEffect(() => {
            if (typeOptions) {
                setOptions(typeOptions);
                generateOptions(typeOptions, selectedOptions);
                setHasLoaded(true);
            }
        }, [])


        useEffect(() => {
            generateOptions(typeOptions, selectedOptions);
        }, [index])

        useEffect(() => {
            setOptions(typeOptions);
            generateOptions(typeOptions, selectedOptions);
            setHasLoaded(true);
        }, [typeOptions])

        const handleCropChange = () => {
            const croppedImgData = cropper.getCroppedCanvas().toDataURL();
            setCropData(croppedImgData);
            images[cropperIndex]["edited"] = true;
            images[cropperIndex].data64String = croppedImgData;
            setImages(images);
        }

        const handleRotate = () => {
            if (typeof cropper !== "undefined") {
                cropper.rotate(90);
                handleCropChange();
            }
        }
        const handleCropEnd = (event) => {
            const croppedImgData = event.target.cropper.getCroppedCanvas().toDataURL()
            setCropData(croppedImgData);
            images[cropperIndex]["edited"] = true;
            images[cropperIndex].data64String = croppedImgData;
            setImages(images);
        }

        const generateOptions = (list: any[], selectedList: number[]) => {
            var newList = _.map(list, (option) => {
                var index = _.findIndex(selectedList, (l) => { return option.id === l })
                return (
                    <MenuItem disabled={index >= 0} value={option.id}>{option.name}</MenuItem>)
            })
            setOptions(newList);
        }

        const handleReady = () => {
            setImageLoading(false);
        }

        const getSource = () => {
            return container == process.env.REACT_APP_AIREPORTS_CONTAINER ?
                GlobalUtils.getAIReportUrl(images[cropperIndex].data64Url, vivosId, containerId) :
                GlobalUtils.getVisitRecordUrl(images[cropperIndex].data64Url, vivosId, containerId)
        }

        return (
            hasLoaded ?
                <Grid key={index} xs={12} container className="cropper">
                    {imageLoading &&
                        <Grid style={{
                            // do your styles depending on your needs.
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            zIndex: "30",
                            position: "relative",
                            top: "150px"
                        }}>
                            {
                                <Grid container direction="row" justifyContent="center">
                                    <Grid container justifyContent="center"><Grid>Image Loading...</Grid></Grid>
                                    <CircularProgress sx={{
                                        width: "90%",
                                        height: "90%"
                                    }} />
                                </Grid>
                            }
                        </Grid>
                    }

                    <Grid item xs={12} sx={{ visibility: !imageLoading ? "visible" : "hidden" }}>
                        <IconButton className="BtnTopLeftCornerALT" disabled={isEditable ? isEditable : true}
                            onClick={handleRotate}>
                            <RotateRightIcon className="cornerBtn" />
                        </IconButton>
                        <Cropper
                            style={{ height: MAXHEIGHT, width: '100%', maxHeight: MAXHEIGHT, maxWidth: MAXWIDTH }}
                            className={"shadow crop"}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            highlight={true}
                            responsive={true}
                            checkOrientation={true}
                            autoCropArea={1}
                            ready={handleReady}
                            src={getSource()}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            cropend={handleCropEnd}
                            guides={true}
                            disabled={isEditable ? isEditable : false}
                            movable={isEditable ? isEditable : true}
                            rotatable={isEditable ? isEditable : true}
                            zoomable={isEditable ? isEditable : true}
                        />
                    </Grid>

                    {(!imageLoading && typeOptions && typeOptions.length > 0) &&
                        <Grid item xs={12} container direction="row" sx={{ paddingTop: "5px" }}>
                            <Grid xs={12} container direction="row">
                                <Grid xs>
                                    {images[cropperIndex].imageType ? images[cropperIndex].imageType.name : ""}
                                </Grid>
                            </Grid>
                        </Grid>}

                </Grid> : null
        )
    }
export default observer(EditRotateCropper);