interface ITeam {
    id: number,
    organizationId: number,
    name: string,
    description: string,
    isActive: boolean
}

interface Team extends ITeam {}

class Team{
    constructor({id,organizationId,name,description,isActive}: ITeam){
        this.id = id;
        this.organizationId = organizationId;
        this.name = name;
        this.description = description;
        this.isActive = isActive;
    }
}

export default Team;