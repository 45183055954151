import React from 'react';
import {
    Card,
    CardHeader,
    Typography,
    CardActionArea,
    Link,
    Button,
    CardMedia,
    Box,
    alpha,
    useTheme,
    styled,
    IconButton,
    Divider,

} from '@mui/material';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import BannerCard from './BannerCard';

const SwipeIndicator = styled(IconButton)(
    ({ theme }) => `
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
      border-radius: 100px;
      transition: ${theme.transitions.create(['all'])};

      &:hover {
        color: ${theme.colors.alpha.black[100]};
        background: ${theme.colors.primary.lighter};
      }
  `
);

const CardBorderTop = styled(Card)(
    () => `
          border-top: transparent 5px solid;
    `
);

function Banners() {
    const theme = useTheme();
    return (
        <CardBorderTop
            sx={{
                textAlign: 'left',
                borderTopColor: `${theme.colors.primary.main}`
            }}
        >
            <Card
                sx={{ height: '30vw' }}
            >
                <CardHeader
                    action={
                        <>
                            <SwipeIndicator className="MuiSwipe-root MuiSwipe-left">
                                <ChevronLeftTwoToneIcon />
                            </SwipeIndicator>
                            <SwipeIndicator className="MuiSwipe-root MuiSwipe-right">
                                <ChevronRightTwoToneIcon />
                            </SwipeIndicator>
                        </>
                    }
                    title={("Trending")}
                />
                <Divider />
                <Box
                    px={3}
                    pb={2}
                    height={'25vw'}
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        '.swiper-pagination-bullets': {
                            bottom: '0 !important'
                        }

                    }}
                >
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        loop
                        navigation={{
                            nextEl: '.MuiSwipe-right',
                            prevEl: '.MuiSwipe-left'
                        }}
                        // breakpoints={{
                        //     1100: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 24
                        //     }
                        // }}
                        modules={[Navigation, Pagination]}
                        pagination={{ dynamicBullets: true, clickable: true }}
                    >
                        <SwiperSlide>
                            <BannerCard image="/images/banners/BWC Banner.png" />
                        </SwiperSlide>

                        <SwiperSlide>
                            <BannerCard image="/images/banners/TVI Presents_Cases with Caulfield.png" />
                        </SwiperSlide>

                        <SwiperSlide>
                            <BannerCard image='/images/banners/OrderingUpdate Banner.png' />
                        </SwiperSlide>

                    </Swiper>
                </Box>
            </Card>
        </CardBorderTop>
    );
}

export default Banners;
