// Vendors
import * as React from 'react';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    SelectChangeEvent,
    IconButton,
    styled,
    Typography,
    Avatar,
    TextField,
    Divider,
    Card,
    Tabs,
    Tab
} from "@mui/material";


const RootWrapper = styled(Box)(
    ({ theme }) => `
        margin-top: ${theme.spacing(2)};
        width: 100%;
        background: ${theme.colors.gradients.blue3};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};
  `
);


const TabsContainerWrapper = styled(Box)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      justifyContent: 'space-evenly',
    `
);

function Header() {

    return (
        <RootWrapper>
            <Grid container minHeight={'250px'} paddingX={4} alignContent={'center'} justifyContent={'center'}>
                <TypographyPrimary variant='h1' fontSize={96}>
                    FAQ
                </TypographyPrimary>
            </Grid>
        </RootWrapper>
    )
};


export default Header;