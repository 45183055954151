import { FormControl, Radio, RadioGroup, TableCell, TableRow } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

const radios = [
    { name: 'Correct', value: 496, key: 1 },
    { name: 'Improve', value: 497, key: 2 },
    { name: 'Maintain', value: 498, key: 3 },
    { name: 'N/A', value: 499, key: 4 },
];

interface IRow {
    name: string;
    callback: (id: any, value: string) => void;
    treatmentObjectiveId: number;
}

export const RowObjective = observer(({ name, callback, treatmentObjectiveId }: IRow) => {
    const [value, setValue] = useState<any[]>([]);

    const [newChoice, setNewChoice] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setNewChoice(Number(value));

        callback(Number(value), name);
    };

    useEffect(() => {
        setNewChoice(treatmentObjectiveId);

        setValue(radios);
    }, [treatmentObjectiveId, value]);

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {name}
            </TableCell>
            {value.map(radio => (
                <TableCell key={radio.value} align="right">
                    <FormControl key={radio.value} component="fieldset">
                        <RadioGroup value={newChoice} key={radio.value} onChange={handleChange}>
                            <Radio value={radio.value} name={radio.name} />
                        </RadioGroup>
                    </FormControl>
                </TableCell>
            ))}
        </TableRow>
    );
});
