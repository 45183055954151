import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { MainStore } from "./MainStore";
import buildQuery from 'odata-query';
import jwt_decode from 'jwt-decode';
import { Gateway } from "../api/Gateway";
import Laboratory from "../entities/Laboratory";
import { ClaimType, LabManagementFilters, ProductionStatus } from '../entities/Enums';

export class LabManagementStore {
    mainStore: MainStore;
    userId: number = 0;
    roles: any = [];
    pageSize: number = 200;
    private _isAISAdminManager = false;
    private _isAISSupervisor = false;
    private _isAISAdmin = false;
    private _totalPages: number = 1;
    private _currentPage: number = 0;
    private _currentRow: any = {};
    private _currentFilter: LabManagementFilters | -1 = -1;
    private _currentSubNavItem: number = 0;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = tokenData.roles;
        }
        this.checkRoles();
    }
    @observable pageTitle = 'Laboratory Management Dashboard';
    @observable isLoadingData = false;
    @observable didInitLabManagement = false;
    @observable searchParam = '';
    @observable filter: LabManagementFilters | -1 = -1;
    @observable isLeftNavExpanded: boolean = false;
    @observable toggleShowFilterResults: boolean = false;
    //@observable labManagementData: Laboratory[] = [];
    //@observable origLabManagementData: Laboratory[] = [];
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable numProductionReady: number = 0;
    @observable numShipped: number = 0;
    @observable numInFabrication: number = 0;
    @observable numCasesOverSevenDays: number = 0;
    @observable numNewPrescriptions: number = 0;
    @observable numWarranties: number = 0;
    @observable numRepairs: number = 0;
    @observable numDays = 5;
    @observable statusTitle = 'Since Yesterday';
    @observable origLabManagementData: Laboratory[] = [];
    @action setOrigLabManagementData = newLabManagementData => (this.origLabManagementData = newLabManagementData);
    @observable labManagementData: Laboratory[] = [];
    @action setLabManagementData = newLabManagementData => (this.labManagementData = newLabManagementData);
    @observable currentRow: any = {};
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);
    //@action setOrigPatientData = newPatientData => (this.origLabManagementData = newPatientData);
    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };
    @action toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };
    get isAISAdminManager(): any {
        return this._isAISAdminManager;
    }
    set isAISAdminManager(value: any) {
        this._isAISAdminManager = value;
    }
    get isAISSupervisor(): any {
        return this._isAISSupervisor;
    }
    set isAISSupervisor(value: any) {
        this._isAISSupervisor = value;
    }
    get isAISAdmin(): any {
        return this._isAISAdmin;
    }
    set isAISAdmin(value: any) {
        this._isAISAdmin = value;
    }
    get totalPages(): number {
        return this._totalPages;
    }
    set totalPages(value: any) {
        this._totalPages = value;
    }
    get currentPage(): number {
        return this._currentPage;
    }
    set currentPage(value: any) {
        this._currentPage = value;
    }
    get currentFilter(): LabManagementFilters | -1 {
        return this._currentFilter;
    }
    set currentFilter(value: any) {
        this._currentFilter = value;
    }
    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }
    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) {
                this.isAISAdminManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR) {
                this.isAISSupervisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN) {
                this.isAISAdmin = true;
            }
        }
        return;
    };
    loadFilterCount = async () => {
        const sevenDays = new Date();
        sevenDays.setDate(sevenDays.getDate() - 7);
        let query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ submissionDate: { le: sevenDays } }] },
            count: true,
        });
        let url = `odata/labDashboard/${this.userId}?${query}`;
        let resp = await Gateway.get(url);
        const casesOver7Days = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ productionStatus: { eq: 'In Fabrication' } }] },
            count: true,
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const inFabrication = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ productionStatus: { eq: 'Shipped' } }] },
            count: true,
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const shipped = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ productionStatus: { eq: 'Ready' } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const productionReady = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ rxStatus: { eq: 'Pending' } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const newRx = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { or: [{ claimTypeId: { eq: ClaimType.Warranty } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const warranties = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const repairs = resp['@odata.count'];

        runInAction(() => {
            this.numCasesOverSevenDays = casesOver7Days;
            this.numInFabrication = inFabrication;
            this.numShipped = shipped;
            this.numProductionReady = productionReady;
            this.numNewPrescriptions = newRx;
            this.numWarranties = warranties;
            this.numRepairs = repairs;
        });
    }
    loadPage = async () => {
        runInAction(() => {
            this.isLoadingData = true;
        });
        let skip = this.currentPage * this.pageSize;
        let query = '';
        const textSearch = [{ or: [{ rxId: { contains: this.searchParam.toLowerCase() } }, { patient: { contains: this.searchParam.toLowerCase() } }, { provider: { contains: this.searchParam.toLowerCase() } }] }];
        switch (this.currentFilter) {
            case LabManagementFilters.CasesOver7Days:
                const sevenDays = new Date();
                sevenDays.setDate(sevenDays.getDate() - 7);
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ submissionDate: { le: sevenDays } }, ...textSearch] } :
                        { and: [{ submissionDate: { le: sevenDays } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.InFabrication:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ productionStatus: { eq: 'In Fabrication' } }, ...textSearch] } :
                        { and: [{ productionStatus: { eq: 'In Fabrication' } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.Shipped:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ productionStatus: { eq: 'Shipped' } }, ...textSearch] } :
                        { and: [{ productionStatus: { eq: 'Shipped' } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.ProductionReady:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ productionStatus: { eq: 'Ready' } }, textSearch] } :
                        { and: [{ productionStatus: { eq: 'Ready' } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.NewPrescriptions:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ rxStatus: { eq: 'Pending' } }, textSearch] } :
                        { and: [{ rxStatus: { eq: 'Pending' } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.Warranty:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ or: [{ claimTypeId: { eq: ClaimType.Warranty } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] }, textSearch] } :
                        { or: [{ claimTypeId: { eq: ClaimType.Warranty } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.Repair:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] }, textSearch] } :
                        { or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] },
                    count: this.didInitLabManagement,
                });
                break;
            case LabManagementFilters.AllClaims:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }, { claimTypeId: { eq: ClaimType.Warranty } }] }, textSearch] } :
                        { or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }, { claimTypeId: { eq: ClaimType.Warranty } }] },
                    count: this.didInitLabManagement
                });
                break;
            default:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ? textSearch : {},
                    count: this.didInitLabManagement,
                    orderBy: ['productionStatusId', 'modifiedOn asc', 'createdOn asc']
                });
                break;
        }
        const url = `odata/labDashboard/${this.userId}?${query}`;
        const resp = await Gateway.get(url);
        const totalRows = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;
        let data = resp['value'];
        data.forEach((item: any) => {
            const today = new Date();
            const submission = new Date(item.submissionDate)
            const daysInQueue = Math.ceil((Math.abs(today.getTime() - submission.getTime())) / (1000 * 60 * 60 * 24)) - 1;
            item.daysInQueue = daysInQueue;
        });
        runInAction(() => {
            if (this.didInitLabManagement) {
                this.didInitLabManagement = false;
            }
            this.isLoadingData = false;
            this.totalPages = totalRows;
            this.labManagementData = data;
            this.origLabManagementData = this.labManagementData;
        });
    };

    loadClaimData = async (claimId: string) => {
        runInAction(() => { this.isLoadingData = true; });
        const query = buildQuery({ filter: { id: { eq: claimId } } });
        const url = `odata/reworkClaims/${this.userId}?${query}`;
        const resp = await Gateway.get(url);
        let data = resp['value'];
        runInAction(() => { this.isLoadingData = false; });
        return data[0];
    };

    clearData = () => {
        runInAction(() => {
            this.searchParam = '';
            this.currentFilter = -1;
            this.loadPage();
        });
    };

    onSubNavClick = (filter: LabManagementFilters, index: number) => {
        runInAction(() => {
            this.currentFilter = filter;
            this.currentPage = 0;
            this.loadPage();
            this.currentSubNavItem = index;
        });
    };

    getSearchData = (searchParam: string): any => {
        runInAction(() => {
            this.searchParam = searchParam;
        });
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.loadPage();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.loadPage();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.labManagementData;
    };

    onFilter = (value, filter: LabManagementFilters) => {
        if (value) {
            this.currentFilter = filter;
        } else {
            this.currentFilter = -1;
            this.labManagementData = this.origLabManagementData;
            this.filter = -1;
        }
        this.currentPage = 0;
        this.loadPage();
    };
}