import React, { FC, useEffect } from 'react';

import { ModalContainer } from '../ModalContainer';
import { AirReportSteps } from './AirReportSteps';
import { useMainStoreContext } from '../../stores/MainStore';

interface IAiReportModal {
    isOpen: boolean;
    onClose: () => void;
    patient?: any;
    submission?: any;
    visit?: any;
}

export const AiReportModal: FC<IAiReportModal> = ({ isOpen, patient, submission, visit, onClose }) => {
    const { resetVisitRecord } = useMainStoreContext().aiReportOrder;

    return (
        <ModalContainer
            show={isOpen}
            onClose={() => {
                onClose();
                resetVisitRecord();
            }}
            title={`Create AI Report Order - ${patient?.firstName} ${patient?.lastName} - ${patient?.vivosId}`}

            onClick={() => {
                onClose();
                resetVisitRecord();
            }}
        >
            <AirReportSteps patient={patient} submission={submission} visit={visit} onClosed={onClose} />
        </ModalContainer >
    );
};
