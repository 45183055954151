import { AddCircleOutline, Close, ThirtyFpsSelect } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem } from "@mui/material";
import { GridColDef, GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";
import { makeObservable, observable, toJS } from "mobx";
import * as _ from "lodash";
import { observer } from "mobx-react";
import {  FC, useEffect } from "react";
import DictionaryList from "../../../entities/DictionaryList";
//import DictionaryListItem from "../../../entities/DictionaryListItem";
import CssTextField from "../../CssTextField";
import DataGrid from "../../DataGrid/DataGrid";
import Item from "../../Item";
import CssSelectField from "../../CssSelectField";
import React from "react";
import DictionaryListItem from "../../../entities/DictionaryListItem";
//import { DictionaryListItemModal } from "./DictionaryListItemModal";


interface IModalProps {
    bl: any;
    isOpen: boolean;
    title: string;
    onSave(value: DictionaryList): any;
    onClose: (onClose: boolean) => void;
}

const defaultList = {
    name: "",
    listKey: "",
    dictionaryListItems: [],
    isActive: true,
    createdBy: 0
}


export const PropertyTablesModal: FC<IModalProps> = observer(({ bl, title, isOpen, onSave, onClose }) => {
    const [showPropertyModel, setShowPropertyModal] = React.useState(false);
    const [primaryCategories, setPrimaryCategories] = React.useState<DictionaryListItem[]>([]);
    const [secondaryCategories, setSecondaryCategories] = React.useState<DictionaryListItem[]>([]);

    const saveListItem = async () => {
        // await this.props.bl.saveItem(this.props.bl.currentProperty);
    }

    const handleOnClick = () => {
        bl.setCurrentList(defaultList);
        onClose(true);
    };

    useEffect(() => {
        setPrimaryCategories(bl.loadPrimaryCategories());
        setSecondaryCategories(bl.loadSecondaryCategories());
    }, [])
    
    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid>
                        <Button onClick={() => {
                            bl.setCurrentProperty(params.row);
                            setShowPropertyModal(true);
                        }}>
                            {params.row.name}
                        </Button>
                    </Grid>
                )
            }
        },
        {
            field: 'primaryCategory', headerName: 'Primary Category', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'aiReportStatus.dashboard.cell.statusId.' + params.row.ID}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Status"
                            value={params.row.primaryCategory ? params.row.primaryCategory : 0}
                            onChange={e => {
                                params.row.statusId = e.target.value;
                               // handleStatusChange(params.row.id, e.target.value);
                            }}
                        >
                            {primaryCategories.map((item: any) => (
                                <MenuItem key={item.id + item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            }
        },
        {
            field: 'secondaryCategory', headerName: 'Secondary Category', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'aiReportStatus.dashboard.cell.statusId.' + params.row.ID}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Status"
                            value={params.row.secondaryCategory ? params.row.secondaryCategory : 0}
                            onChange={e => {
                                params.row.statusId = e.target.value;
                              //  handleStatusChange(params.row.id, e.target.value);
                            }}
                        >
                            {secondaryCategories.map((item: any) => (
                                <MenuItem key={item.id + item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            }
        },
        {
            field: 'isActive', headerName: 'Active', flex: 1, type: 'boolean', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (
                    <Grid xs container alignContent={"center"}>
                        <FormControl>
                            <Item>
                                <Grid className="formLabel" id="hb">{""}</Grid>
                                <FormControlLabel
                                    onChange={(event: React.SyntheticEvent<Element>, checked) => params.row.isActive == checked}
                                    control={<Checkbox checked={params.row.isActive} />}
                                    label="Is Active"
                                />
                            </Item>
                        </FormControl>
                    </Grid>
                )
            }
        }

    ];

    const DictListCustomFooter = () => {
        return (
            <GridFooterContainer>
                <IconButton id="admin.btn.addDictionaryList" onClick={() => {
                    //this.props.bl.createNewListItem();
                    setShowPropertyModal(true);
                }}><AddCircleOutline /></IconButton>
                <GridFooter sx={{ border: 'none' }} />
            </GridFooterContainer>
        )
    }


    return (
        <Grid>
            <Dialog open={isOpen} fullWidth={true} maxWidth={"xl"} >
                <DialogTitle id='form-dialog-title' className='Title'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            sx={{ height: '30px' }}
                            flexGrow={1}
                            fontSize='24px'
                            fontWeight={900}
                            fontFamily='Proxima Nova'>
                            {title}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={handleOnClick}
                                sx={{
                                    fontSize: 25,
                                    backgroundColor: 'transparent',
                                    color: 'blue'
                                }}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <Grid xs container direction="column" className="formBasics" sx={{ margin: "25px 0px 0px 0px" }}>
                        <Grid container direction="row">
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Id</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="propertyTable.txt.id"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            disabled={true}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            }}
                                            value={bl.currentPropertyTable ? bl.currentPropertyTable.id : -1}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Schema</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="propertyTable.txt.id"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            disabled={true}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            }}
                                            value={bl.currentPropertyTable ? bl.currentPropertyTable.tableSchema : ""}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Property Table Name</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="propertyTable.txt.name"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                bl.currentPropertyTable.propertyTableName = event.target.value;
                                            }}
                                            value={bl.currentPropertyTable.propertyTableName}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Value Table Name</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="propertyTable.txt.valueTableName"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: any) => {
                                                bl.currentPropertyTable.valueTableName = event.target.value;
                                            }}
                                            value={bl.currentPropertyTable ? bl.currentPropertyTable.valueTableName : ""}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container alignContent={"center"}>
                                <FormControl>
                                    <Item>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <FormControlLabel
                                            onChange={(event: any) => {
                                                bl.currentList.isActive = event.target.checked;
                                            }}
                                            control={<Checkbox checked={bl.currentPropertyTable ? bl.currentPropertyTable.isActive : false} />}
                                            label="Is Active"
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid sx={{ padding: "25px" }}>
                            <DataGrid
                                columns={columns}
                                data={toJS(bl.currentPropertyTable.properties)}
                                pageSize={5}
                            />
                        </Grid>
                        <Grid container direction="row" xs justifyContent={"flex-end"} >
                            <Grid><Button className="vivButton large invert" onClick={handleOnClick}>Cancel</Button></Grid>
                            <Grid><Button className="vivButton large invert" onClick={() => { onSave(bl.currentList); handleOnClick(); }}>Save</Button></Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog >
            {showPropertyModel &&
                <></>
                // <DictionaryListItemModal title={"Add/Edit List Item"}
                //     bl={this.props.bl}
                //     isOpen={this.showDictListItemModal}
                //     onSave={this.saveListItem}
                //     onClose={() => {
                //         this.props.bl.currentItem = {};
                //         this.setShowListItemModal(false);
                //     }} />
            }
        </Grid>
    )
});