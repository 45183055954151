import { useEffect } from "react";
import { observer } from "mobx-react";
import moment from 'moment';
import * as _ from 'lodash';
import ProviderPatient from "../../../entities/ProviderPatient";
import PatientEditBL from "../PatientEditBL";
import DataGrid from "../../DataGrid/DataGrid";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React from "react";
import LinkIcon from '@mui/icons-material/Link';
import { AiReportModal } from "../../AiReportOrder";
import { useMainStoreContext } from "../../../stores/MainStore";
import { VisitModal } from "../../VisitRecords/VisitModal";
import "../../../scss/_PatientEdit.scss";
import { GlobalUtils } from "../../../api/GlobalUtils";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Button,
    Grid,
    IconButton,
    Link,
    Tooltip
} from "@mui/material";

interface IGIProps {
    patient: ProviderPatient;
}

export const PatientVisitRecords: React.FC<IGIProps> = observer(({ patient }) => {
    const { visitRecords, topUpperImages, topLowerImages, bottomUpperImages, bottomLowerImages, setVisitPatientId, loadVisits, setNewCompliance, resetVisit } = useMainStoreContext().visitRecordStore;

    const [openVisitRecordModal, setOpenVisitRecordModal] = React.useState(false);
    const [openAIReportModal, setOpenAIReportModal] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState<any>({})
    const [patientVisitStatus, setPatientVisitStatus] = React.useState<{ id: number, name: string }[]>([]);
    useEffect(() => {
        setVisitPatientId(patient.id);
        loadVisits().then(() => {
            setHasLoaded(true);
        });
        GlobalUtils.getPatientVisitStatus().then((list) => {
            setPatientVisitStatus(list);
        });

    }, [])

    const handleOnVisitRecordClick = () => {

    }

    const handleOnAIReportClick = () => {

    }

    const hasForms = (row: any): boolean => {

        if ((row.patientSleepStudies && row.patientSleepStudies.length > 0) ||
            (row.patientEpworths && row.patientEpworths.length > 0) ||
            (row.patientHeadNecks && row.patientHeadNecks.length > 0) ||
            (row.patientHeadacheImpacts && row.patientHeadacheImpacts.length > 0) ||
            (row.patientPerceivedStresses && row.patientPerceivedStresses.length > 0) ||
            (row.patientStopBangs && row.patientStopBangs.length > 0)
        ) {
            return true;
        }
        return false;
    }

    const hasImages = (row: any): boolean => {
        if (row.patientImages && row.patientImages.length > 0) {
            _.forEach(Object.keys(row.patientImages[0]), (key) => {
                if (row.patientImages[0][key] != "") {
                    return true;
                }
            });
        }
        return false;
    }

    const hasCBCT = (patientImages: any): boolean => {
        if (patientImages && patientImages.length > 0) {
            if (patientImages[0].cbctScan != null &&
                patientImages[0].cbctScan != "") {
                return true;
            }
        }
        return false;
    }

    const hasModels = (patientImages: any): boolean => {
        if (patientImages && patientImages.length > 0) {
            if ((patientImages[0].occlusion3dlowerArch && patientImages[0].occlusion3dlowerArch != "")
                || (patientImages[0].occlusion3dupperArch && patientImages[0].occlusion3dupperArch != "")
                || (patientImages[0].occlusion3dconstructedBite && patientImages[0].occlusion3dconstructedBite != "")) {
                return true;
            }
        }
        return false;
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Visit Record Id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button id={"visitrecords.dashboard.cell.toggleOpenModal." + params.row.ID} variant="text" onClick={() => {
                            setCurrentRow(params.row);
                            setOpenVisitRecordModal(true);
                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                var status = patientVisitStatus.find(x => x.id === params.row.statusId);
                return (
                    <Grid>
                        {status?.name}
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Visit Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button id={"visitrecords.dashboard.cell.toggleOpenModal." + params.row.ID} variant="text" onClick={() => {
                            //  setViewOnly(true);
                            //  setNoteDetails(params.row);
                            // setIsOpen(true);
                        }}>{moment(params.row.createdOn).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'visitTypeId',
            headerName: 'Visit Type',
            flex: 1.0,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                <Grid>
                    <CheckBoxIcon className="lightgreen" />
                </Grid>
            }
        },
        {
            field: 'forms',
            headerName: 'Forms',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasTheForms = hasForms(params.row);
                return (
                    <Grid>
                        {
                            hasTheForms &&
                            <CheckBoxIcon className="lightgreen" />
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'images',
            headerName: 'Images',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasTheImages = hasImages(params.row);
                return (
                    <Grid>
                        {
                            hasTheImages &&
                            <CheckBoxIcon className="lightgreen" />
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'cbct',
            headerName: 'CBCT',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasIt = hasCBCT(params.row.patientImages);
                {
                    hasIt &&
                        <Grid>
                            <CheckBoxIcon className="green" />
                        </Grid>
                }
            }
        },
        {
            field: 'models',
            headerName: 'Models',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var hasIt = hasModels(params.row.patientImages);
                {
                    hasIt &&
                        <Grid>
                            <CheckBoxIcon className="green" />
                        </Grid>
                }
            }
        },
        {
            field: 'aiReportType',
            flex: 1,
            headerName: 'AI Report',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                const onClick = () => {

                    setCurrentRow(params.row);
                    setOpenAIReportModal(true);
                };
                return (
                    <Grid>
                        {params.row.aiReportType ? (
                            <>
                                <IconButton onClick={onClick}>
                                    <GridActionsCellItem
                                        icon={
                                            <AddCircleOutlineIcon
                                                id={'visitrecords.dashboard.cell.patientsAiReport' + params.row.ID}
                                            />
                                        }
                                        label="Ai Report Order"
                                    />
                                </IconButton>
                                /&nbsp;
                                <Link
                                    id={'patients.dashboard.cell.patientsAiReport' + params.row.ID}
                                    href={params.row.aiReport}
                                >
                                    <LinkIcon id={'visitrecords.dashboard.cell.patientsAiReport' + params.row.ID} />
                                </Link>

                            </>
                        ) : (
                            <Tooltip title={'Request New AI Report'} placement="right" >
                                <IconButton onClick={onClick}>
                                    <GridActionsCellItem
                                        icon={
                                            <AddCircleOutlineIcon
                                                id={
                                                    'visitrecords.dashboard.cell.myocorrectinternalnotes_' +
                                                    params.row.ID
                                                }
                                            />
                                        }
                                        label="Ai Report Order"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'AiGenerated',
            headerName: 'Generated AI Report',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Grid> {params.row.hasAIReportGenerated && <CheckBoxIcon className="lightgreen" />} </Grid>
                )
            }
        }
        // {
        //     field: 'radiologyReporType',
        //     flex: 1,
        //     headerName: 'Radiology Report',
        //     type: 'boolean',
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     renderCell: params => {
        //         const onClick = () => {

        //             setCurrentRow(params.row);
        //             setOpenAIReportModal(true);
        //         };
        //         return (
        //             <Grid>
        //                 {params.row.aiReportType ? (
        //                     <>
        //                         <IconButton onClick={onClick}>
        //                             <GridActionsCellItem
        //                                 icon={
        //                                     <AddCircleOutlineIcon
        //                                         id={'visitrecords.dashboard.cell.patientsAiReport' + params.row.ID}
        //                                     />
        //                                 }
        //                                 label="Ai Report Order"
        //                             />
        //                         </IconButton>
        //                         /&nbsp;
        //                         <Link
        //                             id={'patients.dashboard.cell.patientsAiReport' + params.row.ID}
        //                             href={params.row.aiReport}
        //                         >
        //                             <LinkIcon id={'visitrecords.dashboard.cell.patientsAiReport' + params.row.ID} />
        //                         </Link>
        //                     </>
        //                 ) : (
        //                     <IconButton onClick={onClick}>
        //                         <GridActionsCellItem
        //                             icon={
        //                                 <AddCircleOutlineIcon
        //                                     id={
        //                                         'visitrecords.dashboard.cell.myocorrectinternalnotes_' +
        //                                         params.row.ID
        //                                     }
        //                                 />
        //                             }
        //                             label="Ai Report Order"
        //                         />
        //                     </IconButton>
        //                 )}
        //             </Grid>
        //         );
        //     },
        // },
    ];

    return (
        <Grid container>
            <Grid xs={12} padding={1}>
                <Button
                    variant="contained"
                    disabled={!hasLoaded}
                    onClick={() => {
                        setCurrentRow({});
                        setOpenVisitRecordModal(true);
                        setNewCompliance(true);
                    }
                    } >
                    Add Visit Record
                </Button>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    columns={columns}
                    data={visitRecords}
                    loading={!hasLoaded}

                    size={"small"}
                    pageSize={10}
                />
            </Grid>
            <Grid>
                <VisitModal patient={patient}
                    isOpen={openVisitRecordModal}
                    visit={patient.isProspect ? null : JSON.stringify(currentRow) === '{}' ? null : currentRow}
                    onClose={() => {
                        topUpperImages.length = topLowerImages.length = bottomUpperImages.length = bottomLowerImages.length = 0;
                        resetVisit();
                        setNewCompliance(false);
                        setOpenVisitRecordModal(false);
                        setHasLoaded(false);
                        loadVisits().then(() => {
                            setHasLoaded(true);
                        });
                    }} />
            </Grid>
            <Grid>
                <AiReportModal
                    isOpen={openAIReportModal}
                    patient={patient}
                    visit={currentRow}
                    onClose={() => {
                        setOpenAIReportModal(false);
                        setHasLoaded(false);
                        loadVisits().then(() => {
                            setHasLoaded(true);
                        });
                    }}
                />
            </Grid>
        </Grid>
    )

})
