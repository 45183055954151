import { getTableSortLabelUtilityClass } from '@mui/material';
import * as _ from '../api/GlobalUtils';
// interface IDictionaryList{
//     name: string;
//     value: number;
//     listName: string;
// }
// interface DictionaryList extends IDictionaryList{}
// class DictionaryList {
//     constructor({name, value, listName}: IDictionaryList) {
//         this.name = name;
//         this.value= value;
//         this.listName = listName;
//     }
//     toObject(): IDictionaryList {
//         return {...this};
//     }
// } export default DictionaryList;

export enum MyoCorrectFilters {
    TodayAppts,
    AllActive,
    NewEvals,
    Unassigned,
    MissingNotes,
    Completed,
    Scheduled,
    Unscheduled,
    AllCases,
    MyCases,
    MyTeamCases,
    FrenectomyCases,
    InitalEvalNotScheduled,
    Within7Days
}

export enum ReworkClaimFilters {
    PendingApproval,
    Approved,
    AllClaims
}

export enum LabCasesFilters {
    Pending,
    Ready,
    Claims,
    AllRXs
}

export enum NumberingSystem {
    UNIVERSAL = 'UNIVERSAL',
    PALMER = 'PALMER',
    FDI = 'FDI',
}

export enum TeethSet {
    DECIDUOUS = 'DECIDUOUS',
    ADULT = 'ADULT',
    ALL = 'ALL',
}

export enum AIReportsFilters {
    PendingCases,
    CasesInProgress,
    Assigned,
    ToBeAssigned,
    Completed,
}

export enum AIReportsStatus {
    'Pending Information',
    'Pending/New/Unassigned',
    'Submitted',
    'Assigned',
    'Processing',
    'Completed',
    'Completed - Incomplete',
}

export enum MyoCorrectAppointmentType {
    '-',
    'Session 1',
    'Session 2',
    'Session 3',
    'Session 4',
    'Session 5',
    'Session 6',
    'Pre Frenectomy',
    'Post Frenectomy',
}

export enum MyoCorrectTherapistType {
    'Admin',
    'Advisor',
    'Advocate',
}

export enum MyoCorrectEvaluationType {
    'Initial Evaluation' = 21,
    'Mid Evaluation',
    'Final Evaluation',
}

export enum MyoCorrectSessionStatus {
    'Cancelled' = 32,
    'Completed',
    'No Show',
    'Pending',
    'Rescheduled',
    'Scheduled',
}

export enum MyoCorrectPlan {
    'Legacy' = 1,
    'MyoCorrect',
    'MyoCorrect+',
}
export enum TreatmentStatus {
    'New' = 4,
    'Active',
    'Complete',
}

export enum AIReportStatus {
    'Pending Information',
    'Pending/New/Unassigned',
    'Submitted',
    'Assigned',
    'Processing',
    'Completed',
    'Completed - Incomplete',
}

export enum MyoCorrectStatus {
    'Not Applicable' = 11,
    'Enrolled' = 7,
    'In Progress' = 9,
    'Complete' = 10,
    'Refunded' = 102,
    'Cancelled' = 101,
}

export enum FrenectomyStatus {
    'None Needed' = 0,
    'Lip Frenectomy' = 1,
    'Buccal Frenectomy' = 2,
    'Lingual Frenectomy' = 4,
    'Completed' = 8,
}

export enum PresentConditionsEnum {
    None = 0,
    Fractured = 2,
    Caries = 4,
    Implants = 8,
    Gingivitis = 16,
    Periodontitis = 32,
    Anklyosed = 64,
    Ectopic = 128,
    Abutment = 256,
    Pontic = 512,
    Partially_Erupted = 1024,
}

export const PresentConditions = [
    PresentConditionsEnum[0],
    PresentConditionsEnum[2],
    PresentConditionsEnum[4],
    PresentConditionsEnum[8],
    PresentConditionsEnum[16],
    PresentConditionsEnum[32],
    PresentConditionsEnum[64],
    PresentConditionsEnum[128],
    PresentConditionsEnum[256],
    PresentConditionsEnum[512],
    PresentConditionsEnum[1024],
];

export enum Ethnicity {
    'Caucasian' = 24,
    'Hispanic',
    'Other',
    'Asian',
    'African American',
    'Pacific Islander',
}

export enum Gender {
    'Female' = 22,
    'Male' = 23,
    'Other' = 24,
}

export enum UploadType {
    Image,
    File,
}

export enum AddOnType {
    Radiology = 105,
    Sassouni,
    FrontalAnalysis,
    Printed3DAirway,
}

export enum UneruptedConditions {
    'None' = 109,
    'Poor Eruption Path' = 110,
    'Impacted' = 111,
    'Embedded' = 112,
}

export const MissingReasons = [
    'N/A',
    'Unerupted',
    'Congential',
    'Exfoliated',
    'Extraction',
    'Ortho - Limited Space',
    'Trauma',
    'Perio',
    'Caries',
];

export const SupernumeraryPositions = ['inline', 'Labial', 'Lingual'];

export const countryLabelValues = [
    { label: 'Canada', value: 'CA' },
    { label: 'United States', value: 'US' },
];

export const stateLabelValues = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'States of Micronesia', value: 'FM' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export enum IntraoralCondition {
    INTRAORALCROWDING = 'INTRAORALCROWDING',
    SPACING = 'SPACING',
    CROSSBITE = 'CROSSBITE',
    EDGETOEDGE = 'EDGETOEDGE',
    OVERBITE = 'OVERBITE',
    OVERJET = 'OVERJET',
    ANTERIOROPENBITE = 'ANTERIOROPENBITE',
    POSTERIOROPENBITE = 'POSTERIOROPENBITE',
    CLEFTHARDPALATE = 'CLEFTHARDPALATE',
    EXOSTOSES = 'EXOSTOSES',
    ANOMALIESINTERARCHDIST = 'ANOMALIESINTERARCHDIST',
    MAXILARYASYMMETRY = 'MAXILARYASYMMETRY',
    LIPTIE = 'LIPTIE',
    TONGUETIE = 'TONGUETIE',
    REVERSESWALLOW = 'REVERSESWALLOW',
    ANTERIORTONGUETHRUST = 'ANTERIORTONGUETHRUST',
    INTRAORALOTHER = 'INTRAORALOTHER',
    CLASSI = 'CLASSI',
    CLASSII = 'CLASSII',
    CLASSIITENDENCY = 'CLASSIITENDENCY',
    CLASSIII = 'CLASSIII',
    CLASSIIITENDENCY = 'CLASSIIITENDENCY',
}

export enum ExtraoralCondition {
    HEADACHE = 'HEADACHE',
    ATYPFACEPAIN = 'ATYPFACEPAIN',
    MACROGENIA = 'MACROGENIA',
    TEMPOMANDJOINTDIS = 'TEMPOMANDJOINTDIS',
    OTHERSPECDIS = 'OTHERSPECDIS',
    LIMMANDIROM = 'LIMMANDIROM',
    DEVOPENCLOSEMAND = 'DEVOPENCLOSEMAND',
    MOUTHBREATHER = 'MOUTHBREATHER',
    EXTRAORALOTHER = 'EXTRAORALOTHER',
}

export enum SleepCondition {
    OBSSLEEPAP = 'OBSSLEEPAP',
    SLEEPRELBRU = 'SLEEPRELBRU',
    OTHERSLEEPDIS = 'OTHERSLEEPDIS',
}

export enum CbctConditions {
    MAXILIAHYPERPLASI = 'MAXILIAHYPERPLASI',
    MAXILIAHYPOPLASIA = 'MAXILIAHYPOPLASIA',
    MANDIBLEHYPERPLASIA = 'MANDIBLEHYPERPLASIA',
    MANDIBLEHYPOPLASIA = 'MANDIBLEHYPOPLASIA',
    TMJBEAKING = 'TMJBEAKING',
    EXCESSIVETUBEROSITY = 'EXCESSIVETUBEROSITY',
    ATROPHICBONE = 'ATROPHICBONE',
    CBCTOTHER = 'CBCTOTHER',
}

export enum ClaimStatus {
    'Pending Approval' = 592,
    'Approved' = 593,
    'Cancelled' = 594,
    'Completed' = 830
}

export enum ClaimType {
    'Pending' = 589,
    'Warranty' = 590,
    'Repair' = 591,
    'Warranty & Repair' = 817
}

export enum ProductionStatus {
    'Pending' = 595,
    'Hold' = 596,
    'In Fabrication' = 597,
    'Shipped' = 598,
    'Cancelled' = 599,
    'Ready' = 823
}

export enum InitialImpressionIssue {
    'Manufacturing Issue' = 613,
    'Material Issue' = 614,
    'Provider Issue' = 615,
    'Patient Issue' = 616,
    'Other' = 617
}
export enum LabManagementFilters {
    All,
    NewPrescriptions,
    AllClaims,
    Warranty,
    Repair,
    ProductionReady,
    InFabrication,
    Shipped,
    CasesOver7Days
}
export enum ProdStatus {
    'Pending Approval' = 592,
    'Approved' = 593,
    'Denied' = 594
}

export enum ErfStatus {
    'Not Applicable' = 609,
    'Pending Approval' = 610,
    'Approved by Provider' = 611,
    'Cancelled' = 612
}

export enum CcrStatus {
    'CCR Status 1' = 600,
    'CCR Status 2' = 601,
    'Completed' = 853,
    'In Progress' = 854,
    'Manager Review' = 855,
    'N/A' = 856
}

export enum ActionItemStatus {
    'Not Applicable' = 826,
    'Required' = 827,
    'Completed' = 828,
    'Dismissed' = 829
}

export enum Roles {
    AISADMIN = 'AISAdmin',
    EXTERNALLAB = 'ExternalLab'
}

export enum RxAppliances {
    GUIDEVG = 'Guide VG',
    GUIDEVGX = 'Guide VGx',
    GUIDEVW = 'Guide VW',
    VIDA = 'Vida',
    SLEEPVIDA = 'SleepVida',
    VERSA = 'Versa',
    DNAmRNAmmRNA = 'DNA/mRNA/mmRNA',
}

export enum VivosAppliances {
    'Versa Oral Appliance' = 539,
    'Vivos Single Arch DNA Upper' = 540,
    'Vivos Single Arch DNA Lower' = 541,
    'Vivos Dual DNA System' = 542,
    'Vivos mRNA System' = 543,
    'Vivos mRNA Single Arch Upper' = 544,
    'Vivos mRNA Single Arch Lower' = 545,
    'Vivos mmRNA System' = 546,
    'Vivos mmRNA Single Arch Upper' = 547,
    'Vivos mmRNA Single Arch Lower' = 548,
    'Vivos System Guide' = 549,
    'Other' = 550,
    'Versa-9' = 689,
    'Versa-EMA' = 690,
    'DNA-U' = 691,
    'DNA-L' = 692,
    'DNA-D' = 693,
    'mRNA-U' = 694,
    'mRNA-L' = 695,
    'mRNA' = 696,
    'mmRNA-U' = 697,
    'mmRNA-L' = 698,
    'mmRNA' = 699,
    'Pex-U' = 701,
    'PEx-L' = 702,
    'PEx-D' = 703,
    'GuideVG' = 886,
    'GuideVGx' = 887,
    'GuideVW' = 888,
    'Vida-Pod' = 903,
    'VidaSleep-Pod' = 904
}

export enum ApplianceSizes {
    '25mm' = 868,
    '30mm' = 869,
    '70mm' = 877
}

export enum ApplianceColors {
    'Blue' = 878,
    'Green' = 879,
    'Red' = 880
}

export enum Labs {
    VIVOS = 'Vivos'
}

export enum VersaTypes {
    VERSA9 = 'Versa 9',
    VERSAEMA = 'Versa Nylon EMA'
}

export enum Allergies {
    'Acrylic' = 1,
    'Metals' = 2,
    'Plastic' = 4,
    'Latex' = 8,
    'Food' = 16,
    'Pain Medications' = 32,
    'Other' = 64
}

export enum OrderStatus {
    'Placed' = 831,
    'Under Review' = 832,
    'Manufacturing' = 833,
    'Shipped' = 834,
    'Delivered' = 835,
    'Canceled' = 836,
    'On Hold' = 837,
    'Paid' = 885
}

export enum OcclusionModelType {
    'Physical' = 906,
    'Digital' = 907
}