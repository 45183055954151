import { useState, useRef } from 'react';

import {
    Box,
    Menu,
    IconButton,
    Button,
    ListItemText,
    ListItem,
    List,
    Typography,
    styled
} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';

const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

function BulkActions() {
    const [onMenuOpen, menuOpen] = useState<boolean>(false);
    const moreRef = useRef<HTMLButtonElement | null>(null);

    const openMenu = (): void => {
        menuOpen(true);
    };

    const closeMenu = (): void => {
        menuOpen(false);
    };

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography variant="h5" color="text.secondary">
                        Bulk actions:
                    </Typography>
                    <ButtonError
                        sx={{
                            ml: 1
                        }}
                        startIcon={<DeleteTwoToneIcon />}
                        variant="contained"
                    >
                        Delete
                    </ButtonError>
                </Box>
                <IconButton
                    color="primary"
                    onClick={openMenu}
                    ref={moreRef}
                    sx={{
                        ml: 2,
                        p: 1
                    }}
                >
                    <MoreVertTwoToneIcon />
                </IconButton>
            </Box>
            <Menu
                disableScrollLock
                keepMounted
                anchorEl={moreRef.current}
                open={onMenuOpen}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
            >
                <List
                    sx={{
                        p: 1
                    }}
                    component="nav"
                >
                    <ListItem button>
                        <ListItemText primary='Bulk delete selected' />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary='Resend email notification' />
                    </ListItem>
                </List>
            </Menu>
        </>
    );
}

export default BulkActions;
