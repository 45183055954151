import { Grid } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CssAutoComplete from "../../CssAutoComplete";
import { useMainStoreContext } from "../../../stores/MainStore";
import CssTextField from "../../CssTextField";
import { filter } from "lodash";

interface AppointmentTypeProps {
	params: GridRenderCellParams
}
interface AppointmentTypeOptionType {
    inputValue?: string;
    name: string;
}

export const AppointmentType = ({params} : AppointmentTypeProps) => {
	const {
        handleAppointmentTypeChange,
        setCurrentRow,
        sessions,
    } = useMainStoreContext().sessionsStore;
	var appointmentTypeOptions = [{ name: params.row.appointmentType }]
	if (params.row.sessionIndexNumber > 0) {
		if (sessions.find(x => x.appointmentType === 'Pre Frenectomy') === undefined) {
			appointmentTypeOptions.push({ name: 'Pre Frenectomy' });
		}
		if (sessions.find(x => x.appointmentType === 'Post Frenectomy') === undefined) {
			appointmentTypeOptions.push({ name: 'Post Frenectomy' });
		}
		if ((appointmentTypeOptions[0].name === 'Pre Frenectomy') || (appointmentTypeOptions[0].name === 'Post Frenectomy')) {
			appointmentTypeOptions.shift();
		}
	}
	return (
		<Grid id={"appointments.cell.appointmentType" + params.row.id} >
			<CssAutoComplete
				id={"appointments.text.appointmentType" + params.row.id}
				freeSolo
				options={appointmentTypeOptions}
				value={params.row.appointmentType}
				sx={{ width: 300, border: "0px" }}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				onChange={(event) => {
					setCurrentRow(params.row);
					handleAppointmentTypeChange(params.row, event.target);
				}}
				renderInput={(params) => <CssTextField {...params}
					variant="standard"
					sx={{
						border: 'none',
						width: '200px'
					}}
				/>}
				getOptionLabel={(opt: any) => {
					if (typeof opt === 'string') {
						return opt;
					}
					if (opt.inputValue) {
						return opt.inputValue;
					}
					return opt.name;
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options as AppointmentTypeOptionType[], params);
					const { inputValue } = params;
					const isExisting = options.some((option: any) => inputValue === option.name);
					if (inputValue !== '' && !isExisting) {
						filtered.push({
							inputValue,
							name: `Add "${inputValue}"`,
						});
					}
					return filtered;
				}}
				onKeyDown={(event) => {
					if (event.key === ' ') {
						event.stopPropagation();
					}
				}}
			/></Grid>)
}