import { observer } from 'mobx-react';
import React, { FC, Fragment, useEffect } from 'react';
import * as _ from 'lodash';
import Moment from 'moment';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  styled
} from '@mui/material';
import {
  GridActionsCellItem,
  GridFilterItem,
  gridVisibleRowCountSelector,
  useGridApiContext,
  useGridSelector,
  GridFilterInputValue
} from '@mui/x-data-grid';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { useParams } from "react-router-dom";
import { AppointmentModal, ChangeSessionDateCalendly } from '../../components/AppointmentModal';
import { MyoCorrectFilters, MyoCorrectPlan, MyoCorrectStatus } from '../../entities/Enums';
import DataGrid from '../../components/DataGrid/DataGrid';
import CssSelectField from '../../components/CssSelectField';
import { useMainStoreContext } from '../../stores/MainStore';
import ModalContainer from "../../components/ModalContainer";
import AppointmentNotes from '../../components/AppointmentNotes/AppointmentNotes'
import DetailPanelContent from '../../components/DetailPanel';
import moment from 'moment';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from './components/PageHeader';
import DashboardInfoCard from '../../components/UI/Cards/DashboardInfoCard';
import PermContactCalendarTwoToneIcon from '@mui/icons-material/PermContactCalendarTwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';
import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';
import SearchBar from '../../components/UI/SearchBar';
import Label from '../../components/UI/Label';

interface MyoCorrectAdminProps { }

const MyoCorrectAdminComponent: FC<MyoCorrectAdminProps> = (props: MyoCorrectAdminProps) => {
  let params = useParams();

  const [dataRowCount, setDataRowCount] = React.useState(true);
  const numResults = 0;
  const {
    isLeftNavExpanded,
    openCalendly,
    openCalendlyChangeSession,
    pageTitle,
    setOpenCalendly,
    setOpenCalendlyChangeSession,
    setPreviousDateReschedule,
    setSchedulerLink,
    setSelectedVivosId,
    toggleFilterResultsExpand,
    toggleLeftNav,
  } = useMainStoreContext().myoCorrectAdminStore;

  let store = useMainStoreContext().myoCorrectStore;
  const defaultPage = "CASES";
  const { loadCurrentSessions, loadPlan, setSelectedFrenectomy, patientIdentification, setIsLoadingSessions } = useMainStoreContext().sessionsStore

  const { showModal, getCurrentSessionId, toggleOpenSessionNotesModal } = useMainStoreContext().createAppointmentNotesStore;

  useEffect(() => {
    store.didInitPatients = true;
    store.getFilterNums();
    store.loadPage();
    store.loadAdvocates();
  }, [store.loadPage, store.loadAdvocates]);

  useEffect(() => {
    store.loadPage();
  }, [store.filter])

  const [currentButton, setState] = React.useState(1);
  const onButtonClicked = (newIcon) => {
    setState(newIcon);
  };

  if (params.myoCorrectFilter !== undefined) {
    store.setFilterNav(params.myoCorrectFilter);
  }

  const handleNotesModalToggle = async () => {
    const newPatient = await store.loadPatient(store.currentRow.vivosId);
    store.setCurrentRow(newPatient);
    loadPlan(store.currentRow.myoCorrectPlanId);
    loadCurrentSessions(store.currentRow.id);
    if (store.currentRow.frenectomyStatuses) {
      setSelectedFrenectomy(JSON.parse(store.currentRow.frenectomyStatuses).map(th => th.Status));
    }
    toggleOpenSessionNotesModal();
  }

  const getLabelColor = (status) => {
    switch (status) {
      case 'Enrolled':
        return 'warning';
      case 'In Progress':
        return 'primary';
      case 'Complete':
        return 'success';
      case 'Refunded':
        return 'error';
      case 'Cancelled':
        return 'error';
      default:
        return 'info';
    }
  };

  const columns = [
    {
      field: 'vivosId', headerName: 'Patient ID', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {

        if ((_.isNull(params.row.assignedTherapistId) || params.row.assignedTherapistId == 0) &&
          (_.isNull(params.row.advisorId) || params.row.advisorId == 0)) {
          return <Grid>{params.row.vivosId}</Grid>
        }
        return (<Grid>
          <Button id={"myocorrecttherapist.dashboard.cell.toggleEditPatientModal." + params.row.id} variant="text" onClick={() => {
            setSelectedVivosId(params.row.vivosId);
            if (store.currentRow === null || store.currentRow.id !== params.row.id) {
              const rowItem = store.getPatientDataItem(params.row.id);
              store.setCurrentRow(rowItem);
            }
            store.toggleDetailsModal()
          }}>{params.row.vivosId}</Button>
        </Grid>)
      }
    },
    {
      field: 'name', headerName: 'Patient', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        return (<Grid id={"myocorrecttherapist.dashboard.cell.name." + params.row.id}>{params.row.firstName + " " + params.row.lastName}</Grid>)
      }
    },
    {
      field: 'provider', headerName: 'Provider', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        return (<Grid id={"myocorrecttherapist.dashboard.cell.provider." + params.row.id}>{params.row.provider}</Grid>)
      }
    },
    {
      field: 'advisorId', headerName: 'Advisor', flex: 1, headerAlign: "center", headerClassName: "colHeader", type: 'singleselect', align: "left",
      sortComparator: (v1, v2, param1, param2) => {
        var a1 = _.find(store.advisors, (a) => { return a.userID == param1.value });
        var a2 = _.find(store.advisors, (a) => { return a.userID == param2.value });
        var ad1 = (a1?.firstName + " " + a1?.lastName);
        var ad2 = (a2?.firstName + " " + a2?.lastName);
        return ad1.localeCompare(ad2);
      },
      valueOptions: store.advisors.map((a) => { return a.firstName + " " + a.lastName }),
      filterOperators: [
        {
          value: 'is',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value == '') {
              return null;
            }

            return ({ value }): boolean => {
              var advisor = _.find(store.advisors, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
              if (!_.isNull(advisor) && !_.isUndefined(advisor)) {
                return advisor.userID === value;
              }
              return false;
            };
          },
          InputComponent: GridFilterInputValue,
          InputComponentProps: { type: 'singleSelect' },
        }],
      renderCell: (params) => {

        if (store.advisors.filter(x => x.userID === params.row.advisorId).length === 0) {
          params.row.advisorId = 0;
        }
        const currentAdvocate = store.advisors.find((x) => x.userID === params.row.advisorId);
        return (
          <Grid>
            {store.isMyoCorrectAdmin &&
              <CssSelectField
                id={"myocorrecttherapist.dashboard.cell.advisorId." + params.row.id}
                sx={{ border: "0px" }}
                defaultValue='0'
                label='Select an Advisor'
                value={(params.row.advisorId ?? 0)}
                onChange={(event) => {
                  params.row.advisorId = event.target.value;
                  store.handleAdvisorChange(params.row, event)
                }}
              >
                <MenuItem key={0} value={0}>{"Select an Advisor.."}</MenuItem>
                {store.advisors.map((th) =>
                  (<MenuItem key={th.userID + th.lastName} value={th.userID}>{th.firstName + ' ' + th.lastName}</MenuItem>))}
              </CssSelectField>}
            {!store.isMyoCorrectAdmin &&
              <Grid id={"myocorrecttherapist.dashboard.cell.advisorId." + params.row.id}>
                {currentAdvocate !== undefined &&
                  currentAdvocate.firstName + ' ' + currentAdvocate.lastName
                }
              </Grid>
            }
          </Grid>
        )
      }
    },
    { field: 'myoCorrectEnrollmentDate', headerName: 'Enrollment Date', flex: 1, headerAlign: "center", headerClassName: "colHeader", type: "date", align: "center", renderCell: (params) => { return (<Grid id={"myocorrecttherapist.dashboard.cell.patientenrollmentdate." + params.row.id}>{params.row.myoCorrectEnrollmentDate && moment(params.row.myoCorrectEnrollmentDate).format('MM/DD/yyyy')}</Grid>) } },
    {
      field: "myoCorrectPlanId", headerName: 'MyoCorrect Plan', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center", renderCell: (params) => { return (<Grid id={"myocorrecttherapist.dashboard.cell.myocrrectplanid." + params.row.id}>{!_.isNull(params.row) ? MyoCorrectPlan[params.row.myoCorrectPlanId] : ""}</Grid>) },
      valueOptions: Object.keys(MyoCorrectPlan).filter((v) =>
        isNaN(Number(v)),
      ),
      filterOperators: [
        {
          value: 'is',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value == '') {
              return null;
            }

            return ({ value }): boolean => {
              return MyoCorrectPlan[value] !== undefined;
            };
          },
          InputComponent: GridFilterInputValue,
          InputComponentProps: { type: 'singleSelect' },
        }],
    },
    {
      field: 'myoCorrectStatusId', hide: false, headerName: 'Status', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (
          <Grid id={"myocorrecttherapist.dashboard.cell.mycorrectsatusid" + params.row.id}>
            {!_.isNull(params.row) ?
              <Label color={getLabelColor(MyoCorrectStatus[params.row.myoCorrectStatusId])}>
                {MyoCorrectStatus[params.row.myoCorrectStatusId]}
              </Label>
              : ""}
          </Grid>)
      }
    },
    { field: 'nextSessionType', hide: true, headerName: 'Next Session Type', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => { return (<Grid id={"myocorrecttherapist.dashboard.cell.nextsessiontype" + params.row.id}>{!_.isNull(params.row) ? params.row.nextSessionType : ""}</Grid>) } },
    {
      field: 'nextSessionDate', headerName: 'Next Appt.', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", type: "dateTime", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (
          <Grid container direction="row" justifyContent={'center'}>
            <Grid id={"myocorrecttherapist.dashboard.cell.nextsessiondate." + params.row.id} item>{!_.isNull(params.row.nextSessionDate) ? Moment(params.row.nextSessionDate).format('MM/DD/yyyy') : ""}</Grid>
            <IconButton id={"myocorrecttherapist.dashboard.cell.nextsessiondate." + params.row.id} onClick={() => toggleOpen(params.row.schedulerLink)} />
          </Grid>)
      }
    },
    {
      field: 'assignedTherapistId', headerName: 'Therapist', sortable: true, flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
      sortComparator: (v1, v2, param1, param2) => {
        var a1 = _.find(store.advocates, (a) => { return a.userID == param1.value });
        var a2 = _.find(store.advocates, (a) => { return a.userID == param2.value });
        var ad1 = (a1?.firstName + " " + a1?.lastName);
        var ad2 = (a2?.firstName + " " + a2?.lastName);
        return ad1.localeCompare(ad2);
      },
      valueOptions: store.advocates.map((a) => { return a.firstName + " " + a.lastName }),
      filterOperators: [
        {
          value: 'is',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value == '') {
              return null;
            }

            return ({ value }): boolean => {
              var advocate = _.find(store.advocates, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
              if (!_.isNull(advocate) && !_.isUndefined(advocate)) {
                return advocate.userID === value;
              }
              return false;
            };
          },
          InputComponent: GridFilterInputValue,
          InputComponentProps: { type: 'singleSelect' },
        }],
      renderCell: (params) => {
        if (store.advocates.filter(x => x.userID === params.row.assignedTherapistId).length === 0) {
          params.row.assignedTherapistId = 0;
        }
        const disabled = (store.isMyoCorrectAdvocate) || ((store.isMyoCorrectAdmin || store.isMyoCorrectAdvisor) && store.advisors.filter(x => x.userID === params.row.advisorId).length === 0);
        return (
          <Grid>
            <CssSelectField
              id={"myocorrecttherapist.dashboard.cell.assignedTherapistId." + params.row.id}
              sx={{ border: "0px" }}
              defaultValue='0'
              label="Select a Therapist"
              disabled={disabled}
              value={(params.row.assignedTherapistId ? params.row.assignedTherapistId : 0)}
              onChange={(event) => {
                params.row.assignedTherapistId = event.target.value;
                store.handleChange(params.row, event)
              }}
            >
              <MenuItem key={0} value={0}>{"Select a Therapist..."}</MenuItem>
              {store.advocates.map((th) =>
                (<MenuItem key={th.userID + th.lastName} value={th.userID}>{th.firstName + ' ' + th.lastName}</MenuItem>))}
            </CssSelectField>

          </Grid>
        )
      }
    },
    {
      field: 'actions', flex: .5, headerAlign: "center", headerClassName: "colHeader", type: 'actions', align: "center",
      getActions: (params) => [
        <GridActionsCellItem icon={currentButton === 0 ? < ConnectWithoutContactIcon id={"myocorrecttherapist.dashboard.cell.myocorrectinternalnotes." + params.row.id} /> : <AddCircleOutlineIcon id={"myocorrecttherapist.dashboard.cell.myocorrectinternalnotes_" + params.row.id} />} onClick={() => onButtonClicked(0)} label="Myo Internal Notes" />
      ]
    }
  ]
  const toggleOpen = (schedulerLink: string | null) => {
    setTimeout(() => {
      setSchedulerLink(schedulerLink);
      setOpenCalendly(!openCalendly);
      if (patientIdentification) {
        setIsLoadingSessions(true);
        setTimeout(() => loadCurrentSessions(patientIdentification), 2000);
      }
    }, 200);
  };
  const closeCalendlyChangeSession = () => {
    setTimeout(() => {
      setOpenCalendlyChangeSession(false);
      setPreviousDateReschedule('');
      if (patientIdentification) {
        setIsLoadingSessions(true);
        setTimeout(() => loadCurrentSessions(patientIdentification), 2000);
      }
    }, 200);
  };

  var hdrButtons: any = [];
  hdrButtons.push(<Button id={"myocorrecttherapist.header.btn.filterPatients." + store.userID} className='vivButton large' onClick={toggleFilterResultsExpand}>FILTER RESULTS</Button>);

  var infoBoxes: any = [];
  if (store.isMyoCorrectAdvocate === true) {
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Today's Appointments"} cardNumberValue={store.numTodaysAppts} icon={<PermContactCalendarTwoToneIcon />} filter={MyoCorrectFilters.TodayAppts} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.TodayAppts) }} />)
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Frenectomy Patients"} cardNumberValue={store.numFrenectomyCases} icon={<MedicalServicesTwoToneIcon />} filter={MyoCorrectFilters.FrenectomyCases} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.FrenectomyCases) }} />)
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Missing Notes"} cardNumberValue={store.numMissingNotes} icon={<PermContactCalendarTwoToneIcon />} filter={MyoCorrectFilters.MissingNotes} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.MissingNotes) }} />)
  }
  if (store.isMyoCorrectAdvocate === false) {
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Today's Appointments"} cardNumberValue={store.numTodaysAppts} icon={<PermContactCalendarTwoToneIcon />} filter={MyoCorrectFilters.TodayAppts} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.TodayAppts) }} />)
    infoBoxes.push(<DashboardInfoCard cardLabelText={"New Evaluations"} cardNumberValue={store.numNewEvals} icon={<PendingActionsTwoToneIcon />} filter={MyoCorrectFilters.NewEvals} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.NewEvals) }} />)
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Unassigned Patients"} cardNumberValue={store.numUnassigned} icon={<AssignmentLateTwoToneIcon />} filter={MyoCorrectFilters.Unassigned} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.Unassigned) }} />)
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Frenectomy Patients"} cardNumberValue={store.numFrenectomyCases} icon={<MedicalServicesTwoToneIcon />} filter={MyoCorrectFilters.FrenectomyCases} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.FrenectomyCases) }} />)
    infoBoxes.push(<DashboardInfoCard cardLabelText={"Missing Notes"} cardNumberValue={store.numMissingNotes} icon={<PermContactCalendarTwoToneIcon />} filter={MyoCorrectFilters.MissingNotes} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, MyoCorrectFilters.MissingNotes) }} />)
  }

  return (
    <Fragment>
      <PageTitleWrapper>
        <PageHeader
          numActivePatients={store.numActivePatients}
          numCompletedPatients={store.numCompleted}
          numWithin7Days={store.numWithin7Days}
          currentFilter={store.currentFilter}
          newFilter={MyoCorrectFilters.Within7Days}
          activeFilter={MyoCorrectFilters.AllActive}
          completedFilter={MyoCorrectFilters.Completed}
          onNewFilterClick={(value) => { store.onFilter(value, MyoCorrectFilters.Within7Days) }}
          onActiveFilterClick={(value) => { store.onFilter(value, MyoCorrectFilters.AllActive) }}
          onCompletedFilterClick={(value) => { store.onFilter(value, MyoCorrectFilters.Completed) }}
        />
      </PageTitleWrapper>
      <Grid container>
        {/* <Grid >
          <MyoCorrectGridHeader
            parentId={"myocorrecttherapist.dashboard"}
            id={store.userID}
            onChange={store.getSearchData}
            onClear={store.clearData}
            title={pageTitle}
            numResults={numResults}
            buttons={hdrButtons}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Grid container spacing={2} padding={2}>
            {infoBoxes}
          </Grid>
        </Grid>
        <Grid item xs={12} padding={2}>
          <SearchBar
            onChange={store.getSearchData}
            onClear={store.loadData}
            onCancel={store.clearData}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid id={"myocorrecttherapist.dashboard" + store.userID}>
            {store.isLoadingPatients &&
              <LinearProgress variant="determinate" value={store.loadingPatientsProgress} />
            }
            <DataGrid
              data={store.patientData}
              columns={columns}
              pagination={true}
              pageSize={store.pageSize}
              loading={store.isLoadingPatients}
              rowCount={store.totalPages}
              onPageChange={(pageNumber) => {
                store.currentPage = pageNumber;
                store.loadPage();
              }}
              serverSidePagination={true}
              onFilterChange={(filterObj) => {
                store.onFilterChange(filterObj);
              }}
            //customFooter={CustomFooter}
            />
          </Grid>
        </Grid>

        <ModalContainer show={showModal} title='Add Notes' onClose={toggleOpenSessionNotesModal} onClick={handleNotesModalToggle} >
          <AppointmentNotes isInitialEval={getCurrentSessionId() === 0} />
        </ModalContainer>

        <ModalContainer show={store.toggleShowDetailsPanelModal} title='Patient Details' onClose={store.toggleDetailsModal} onClick={store.toggleDetailsModal} >
          <DetailPanelContent therapists={store.advocates} onClose={store.closeDetailsModal} />
        </ModalContainer>
      </Grid>
      <AppointmentModal open={openCalendly} onClose={() => toggleOpen(null)} />

    </Fragment>
  )
}
const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const totalRows = useGridSelector(apiRef, gridVisibleRowCountSelector);
  return (<Box sx={{ p: 1, display: 'flex-end', textAlign: 'right' }}>Total MyoCorrect Patients: {totalRows} </Box>)
}
export const MyoCorrectAdmin = observer(MyoCorrectAdminComponent);