// React
import { observer } from "mobx-react";
import { FC, Fragment } from "react";
import React from "react";


import { LeftNav } from "../../components/LeftNav/LeftNav";
import { useMainStoreContext } from "../../stores/MainStore";
import { MyoCorrectGridHeader } from "../../components/MyoCorrectGridHeader";
import SidebarLayout from '../../layouts/SidebarLayout/SidebarLayout'
import { Grid, Box, Card, CardActions, CardContent, Button, Typography, AppBar, Tabs, Tab, Container } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import PageHeader from "./components/PageHeader";
import PageTitleWrapper from "../../components/UI/PageTitleWrapper";
import Block4 from "./components/Block4";
import BannerCard from "./components/BannerCard";
import Banners from "./components/Banner";
import CardNotifications from "../../components/Notifications";
import HotSpotMenu from "./components/HotspotMenu";
import Statistics from "./components/Statistics";

interface IGIProps {
}

export const MainDashboard: FC<IGIProps> = observer(() => {
    const store = useMainStoreContext().mainDashboardStore;

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="left"
                // alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Banners />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                    <CardNotifications />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <HotSpotMenu />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Statistics />
                </Grid> */}
            </Grid>
        </Fragment>
    )
})