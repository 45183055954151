import { FC } from 'react';
import {
    // FormControl, 
    // FormHelperText, 
    Grid,
    IconButton,
    // MenuItem,
    // SelectChangeEvent, 
    Typography,
    TextField,
    Autocomplete
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { observer } from 'mobx-react';
// import CssTextField from './CssTextField';
// import Item from './Item';
import React, { useEffect } from 'react';
import { useMainStoreContext } from '../stores/MainStore';
import LegalGuardianData from '../entities/LegalGuardian';
// import CssSelectField from './CssSelectField';
import { Formik } from 'formik';
import * as Yup from 'yup';

interface ILegalGuardianProps {
    index: any;
    guardian?: any;
    onDelete(index: number): any;
}
const LegalGuardianForm: FC<ILegalGuardianProps> = ({ index, guardian, onDelete }) => {
    const { setGuardian, removeGuardian, getGuardians,
        helperFirstNameText, helperLastNameText, helperEmailText, helperPhoneText,
        error, setError } = useMainStoreContext().createLegalGuardianStore;
    const [cIndex, setCIndex] = React.useState(-1);
    const [lg_firstName, setFirstName] = React.useState('');
    const [lg_lastName, setLastName] = React.useState('');
    const [lg_relationship, setRelationship] = React.useState(147);
    const [lg_emailAddress, setEmailAddress] = React.useState('');
    const [lg_primaryPhone, setPrimaryPhone] = React.useState('');
    const [lg_altPhone, setAltPhone] = React.useState('');

    const handleonBlur = () => {
        var guardian = new LegalGuardianData({
            firstName: lg_firstName,
            lastName: lg_lastName,
            relationshipTypeId: lg_relationship,
            emailAddress: lg_emailAddress,
            primaryPhone: lg_primaryPhone,
            altPhone: lg_altPhone,
        });
        setGuardian(index, guardian);
    };

    useEffect(() => {
        setCIndex(index);
        if (guardian != null) {
            setGuardian(index, guardian);
        }
    }, []);

    const relationships = [
        { id: 147, label: "Parent" },
        { id: 148, label: "Guardian" },
        { id: 282, label: "Other" },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Legal Guardian Information</Typography>
                    <IconButton
                        color='error'
                        hidden={getGuardians().length <= 1}
                        onClick={() => {
                            onDelete(index);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{
                        firstName: lg_firstName,
                        lastName: lg_lastName,
                        relationship: '',
                        emailAddress: lg_emailAddress,
                        primaryPhone: lg_primaryPhone,
                        altPhone: lg_altPhone,
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string()
                            .max(255)
                            .notRequired(),
                        lastName: Yup.string()
                            .max(255)
                            .notRequired(),
                        relationship: Yup.string()
                            .notRequired(),
                        emailAddress: Yup.string()
                            .email('The email provided should be a valid email address')
                            .max(255)
                            .notRequired(),
                        primaryPhone: Yup.string()
                            .notRequired(),
                        altPhone: Yup.string()
                            .notRequired(),
                    })}
                    onSubmit={() => { }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        // handleSubmit,
                        // isSubmitting,
                        touched,
                        values
                    }) => (
                        <form>
                            <Grid container rowSpacing={1} columnSpacing={3} padding={3} direction="row">
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.firstName && errors.firstName
                                        )}
                                        fullWidth
                                        helperText={touched.firstName && errors.firstName}
                                        label='First Name'
                                        name="firstName"
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setFirstName(event.target.value);
                                        }}
                                        value={values.firstName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.lastName && errors.lastName
                                        )}
                                        fullWidth
                                        helperText={touched.lastName && errors.lastName}
                                        label='Last Name'
                                        name="lastName"
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setLastName(event.target.value);
                                        }}
                                        value={values.lastName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        id="relationship"
                                        autoComplete={false}
                                        freeSolo
                                        autoSelect={false}
                                        options={relationships}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, value: unknown) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Call patient creation store update
                                            var val = value as any;
                                            setRelationship(val.id);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label='Relationship'
                                                name="relationship"
                                                required={false}
                                                variant="outlined"
                                                placeholder='Select Relationship'
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.emailAddress && errors.emailAddress)}
                                        fullWidth
                                        helperText={touched.emailAddress && errors.emailAddress}
                                        label='Email address'
                                        name="emailAddress"
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setEmailAddress(event.target.value);
                                        }}
                                        value={values.emailAddress}
                                        type="email"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                                        helperText={touched.primaryPhone && errors.primaryPhone}
                                        id="home-phone"
                                        label="Primary Phone"
                                        name="primaryPhone"
                                        type='tel'
                                        fullWidth
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setPrimaryPhone(event.target.value);
                                        }}
                                        value={values.primaryPhone} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        error={Boolean(touched.altPhone && errors.altPhone)}
                                        helperText={touched.altPhone && errors.altPhone}
                                        id="mobile-phone"
                                        label="Mobile Phone"
                                        name="altPhone"
                                        type='tel'
                                        fullWidth
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setAltPhone(event.target.value);
                                        }}
                                        value={values.altPhone} />
                                </Grid>
                            </Grid>
                        </form>
                    )}

                </Formik>
            </Grid>
        </Grid>

        // <Grid sx={{ marginBottom: '25px' }}>
        //     <Grid item xl={12}>
        //         <Grid
        //             container
        //             direction="row"
        //             sx={{ paddingRight: '25px' }}
        //             alignItems="center"
        //             justifyContent="space-between"
        //         >
        //             <Grid className="formSectionLabel">Legal Guardian Information</Grid>

        //         </Grid>
        //         <Grid
        //             container
        //             xs={12}
        //             direction="row"
        //             className="formBasics"
        //         >
        //             <Grid item md={4}>
        //                 <Item>
        //                     <FormControl sx={{ width: '90%' }} error={error}>
        //                         <Grid container direction={'row'} className="formLabel">
        //                             <Grid>First Name</Grid>
        //                         </Grid>
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             size="small"
        //                             placeholder=""
        //                             required={false}
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                 setFirstName(event.target.value);
        //                             }}
        //                             value={lg_firstName}
        //                             onBlur={handleonBlur}
        //                         />
        //                         <FormHelperText>{helperFirstNameText}</FormHelperText>
        //                     </FormControl>
        //                 </Item>
        //             </Grid>
        //             <Grid item md={4}>
        //                 <Item>
        //                     <FormControl sx={{ width: '90%' }} error={error}>
        //                         <Grid container direction={'row'} className="formLabel">
        //                             <Grid>Last Name</Grid>
        //                         </Grid>
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             size="small"
        //                             placeholder=""
        //                             required={false}
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                 setLastName(event.target.value);
        //                             }}
        //                             value={lg_lastName}
        //                             onBlur={handleonBlur}
        //                         />
        //                         <FormHelperText>{helperLastNameText}</FormHelperText>
        //                     </FormControl>
        //                 </Item>
        //             </Grid>
        //             <Grid item xs={4}>
        //                 <Item>
        //                     <FormControl sx={{ width: '90%' }}>
        //                         <Grid container direction={'row'} className="formLabel">
        //                             <Grid>Relationship To Patient</Grid>
        //                         </Grid>
        //                         <CssSelectField
        //                             className="DateBox"
        //                             labelId="relationsnip"
        //                             label="Relationship To Patient"
        //                             font-size="10px/13px"
        //                             size="small"
        //                             defaultValue={147}
        //                             value={lg_relationship}
        //                             onChange={event => {
        //                                 setRelationship(event.target.value as number);
        //                             }}
        //                         >
        //                             <MenuItem value={147}>Parent</MenuItem>
        //                             <MenuItem value={148}>Guardian</MenuItem>
        //                             <MenuItem value={282}>Other</MenuItem>
        //                         </CssSelectField>
        //                     </FormControl>
        //                 </Item>
        //             </Grid>
        //         </Grid>
        //     </Grid>
        //     <Grid
        //         container
        //         xl={12}
        //         direction="row"
        //         className="formBasics"
        //     >
        //         <Grid item md={4}>
        //             <Item>
        //                 <FormControl sx={{ width: '90%' }} error={error}>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>E-mail</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         placeholder=""
        //                         required={false}
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             setEmailAddress(event.target.value);
        //                         }}
        //                         value={lg_emailAddress}
        //                         onBlur={handleonBlur}
        //                     />
        //                     <FormHelperText>{helperEmailText}</FormHelperText>
        //                 </FormControl>
        //             </Item>
        //         </Grid>
        //         <Grid item md={4}>
        //             <Item>
        //                 <FormControl sx={{ width: '90%' }} error={error}>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Primary Phone</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         placeholder=""
        //                         required={false}
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             setprimaryPhone(event.target.value);
        //                         }}
        //                         value={lg_primaryPhone}
        //                         onBlur={handleonBlur}
        //                     />
        //                     <FormHelperText>{helperPhoneText}</FormHelperText>
        //                 </FormControl>
        //             </Item>
        //         </Grid>
        //         <Grid item xs={4}>
        //             <Item>
        //                 <FormControl sx={{ width: '90%' }}>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Alternate Phone</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         sx={{ width: '50px' }}
        //                         size="small"
        //                         placeholder=""
        //                         required={false}
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             setAltPhone(event.target.value);
        //                         }}
        //                         value={lg_altPhone}
        //                         onBlur={handleonBlur}
        //                     />
        //                 </FormControl>
        //             </Item>
        //         </Grid>
        //     </Grid>
        // </Grid>
    );
};

export default observer(LegalGuardianForm);
