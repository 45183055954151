import { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { action, makeObservable, observable } from "mobx";
import '../scss/_GridHeader.scss';
import close from '../imgs/tmp/bigx.png';
import { AppBar, Toolbar, IconButton, Typography, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CssTextField from "./CssTextField";

interface MyoCorrectGridHeaderProps {
    parentId: string;
    id: number;
    title: string;
    numResults: number;
    buttons: JSX.Element[];
    onChange(value: string): any;
    onClear(): any;
}
@observer
export class MyoCorrectGridHeader extends Component<MyoCorrectGridHeaderProps> {
    @observable searchHeaderExpanded: boolean = false;
    @observable gridHeaderExpanded: boolean = false;
    @observable numResults: number = 0;
    @observable textSearch: string = '';
    searchText: any;

    constructor(props: any) {
        super(props);
        makeObservable(this);
        this.searchText = createRef();
    }

    @action clearTextInput = () => {
        this.textSearch = "";
        this.searchText.current.value = "";
        this.props.onClear();
    }

    k = 0;

    render() {

        return (
            <Grid container direction="column" alignItems="center" className="gridHeader" xl={12}>
                <Grid container className="placeholder" alignItems="center" alignContent="center" xl={12}>
                    <AppBar position="static" className="modalHeader" color="inherit">
                        <Toolbar>
                            <Typography id={this.props.parentId + ".hdr.lbl.title"}  variant="h6" component="div" sx={{ flexGrow: 1 }} className="title">
                                {this.props.title.toUpperCase()}
                            </Typography>
                            <div className='seperator'></div>
                            <IconButton id={this.props.parentId + ".hdr.btn.expandSearch"} className="icon" size="large" onClick={this.expandSearch.bind(this)}><SearchIcon fontSize="inherit" className="filled" /></IconButton>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid container direction="row" alignItems={"center"} justifyContent="space-between" className={"searchHeader" + (this.searchHeaderExpanded === true ? " expanded" : "")}>
                    <Grid item md={1} className="searchImg"> <IconButton  id={this.props.parentId + ".hdr.btn.togglesearch"} className="icon" size="large" onClick={this.expandSearch.bind(this)}><SearchIcon fontSize="inherit" className="filled" /></IconButton> </Grid>
                    <Grid item md={7} className="searchTextDiv" alignSelf="center">
                        <CssTextField  id={this.props.parentId + ".hdr.fld..searchText"}  ref={this.searchText} className="searchText" value={this.textSearch} sx={{padding:"0px", lineHeight:"30px", font:"$brand_font_xl_italic"}} placeholder="SEARCH (Name, ID)" onChange={this.onChange} /></Grid>
                    <Grid item md={2} className="clearResults"  id={this.props.parentId + "hdr.btn.clearSearchResults"} onClick={this.clearTextInput}>CLEAR RESULTS </Grid>
                    <Grid item md={1} className="close"><img id={this.props.parentId + "hdr.btn.closeSearch" } src={close} alt="Search" height="24px" width="24px" onClick={this.expandSearch.bind(this)} /></Grid>
                </Grid>
            </Grid>
        );
    }

    @action onChange = (event: any) => {
        this.textSearch = event.target.value;
        this.props.onChange(event.target.value);
    }

    @action expandSearch = () => {
        this.searchHeaderExpanded = !this.searchHeaderExpanded;
    }
}

//PATIENTS PENDING ACTION