// Vendors
import * as React from 'react';
import { FC, useEffect } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    SelectChangeEvent,
    IconButton,
    styled,
    Typography,
    Avatar,
    TextField,
    Divider,
    Card,
    Tabs,
    Tab
} from "@mui/material";

// Components
import { Oval } from 'react-loader-spinner';

// Entities
import Patient from "../../../entities/Patient";
import { Gender, Ethnicity } from "../../../entities/Enums";

// API
import { observer } from "mobx-react";
import moment from "moment";
import * as _ from 'lodash';

const RootWrapper = styled(Box)(
    ({ theme }) => `
          margin-top: ${theme.spacing(2)};
          background: ${theme.colors.gradients.black2};
          border-radius: ${theme.general.borderRadius};
  `
);


const TabsContainerWrapper = styled(Box)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      justifyContent: 'space-evenly',
    `
);

interface HeaderProps {
    patient: Patient;
    patientImageUrl: any;
    provider: string;
    providerPhone: any;
    providerEmail: string;
    statusColumnContent: any;
    buttonColumnContent: any;
    tabs: any;
    activeTab: number;
    handleTabChange: any;
    isLoading: boolean;
}

const PatientHeaderLayout: FC<HeaderProps> = (props: HeaderProps) => {
    const [tabs, setTabs] = React.useState<any>();

    return (
        props.isLoading ? (
            <Grid style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        ) :
            <RootWrapper>
                <Grid container minHeight={'250px'} paddingX={4}>
                    {/* Profile Image and Details */}
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: 175,
                                    height: 175,
                                    border: 2,
                                    borderColor: 'white',
                                    alignSelf: 'center'
                                }}
                                src={props.patientImageUrl}
                            />
                            <Grid container direction={'column'} paddingLeft={2}>
                                <Typography variant="h5" color={'white'}>
                                    {props.patient.firstName}
                                </Typography>
                                <Typography gutterBottom variant="h3" color={'white'}>
                                    {props.patient.lastName}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" color={'white'}>
                                    {props.patient.vivosId}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" color={'white'}>
                                    {moment(props.patient.dateOfBirth).format('MM/DD/yyyy')} | {Gender[props.patient.genderId]} | {Ethnicity[props.patient.ethnicityId]}
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" color={'white'}>
                                    [Phone Number] | [Email]
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Status Column Content Available as a Prop into this UI component*/}
                    <Grid item xs={4}>
                        {props.statusColumnContent}
                    </Grid>

                    {/* Provider Information */}
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'} borderRight={2} borderColor="white">
                            <Grid container direction={'column'} paddingRight={2}>
                                <Typography gutterBottom variant="h4" ml={3} color={'white'} textAlign={'right'}>
                                    Provider Info
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'}>
                                    {/* {props.provider} */}
                                    [Provider Name]
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'}>
                                    {/* {props.providerPhone} */}
                                    [Phone Number]
                                </Typography>
                                <Typography gutterBottom variant="subtitle1" ml={3} color={'white'} textAlign={'right'}>
                                    {/* {props.providerEmail} */}
                                    [Email]
                                </Typography>
                            </Grid>
                        </Box>

                    </Grid>

                    {/* Action Button Content Available as a Prop into this UI component*/}
                    <Grid item xs={2} >
                        {props.buttonColumnContent}
                    </Grid>


                    {/* Tabs Bar */}
                    <Grid item xs={12}>
                        <TabsContainerWrapper paddingY={2}>
                            <Card sx={{
                                p: 1
                            }}>
                                <Grid container justifyContent="space-evenly">
                                    <Tabs
                                        onChange={props.handleTabChange}
                                        value={props.activeTab}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                    >
                                        {_.map(props.tabs, (t, key: number) => (
                                            <Tab key={key} label={props.tabs[key]} />
                                        ))}
                                    </Tabs>
                                </Grid>
                            </Card>
                        </TabsContainerWrapper>
                    </Grid>

                </Grid>
            </RootWrapper>
    )
}

export default observer(PatientHeaderLayout);