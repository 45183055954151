import { Grid } from "@mui/material"
import { observer } from "mobx-react";
import { FC } from "react"
import LabCase from "../../entities/LabCases";

interface IGIProps {
    labCase: LabCase;
}

export const LabCaseInformation: FC<IGIProps> = observer(({labCase}) => {
    return (
        <Grid className="viewBody" xs={12} container direction={'column'}>
        <Grid sx={{ padding: "20px" }}>
            <Grid xs={12} container direction={'row'} className="box-container">
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Provider Name</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.provider} </Grid>
                </Grid>
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>RX ID</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.rxId} </Grid>
                </Grid>
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Appliance</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.appliance} </Grid>
                </Grid>
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Lab</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.lab} </Grid>
                </Grid>
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Bite Src</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.biteSrc} </Grid>
                </Grid>
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Days in Queue</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.daysInQueue} </Grid>
                </Grid>
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Model src</Grid>
                    </Grid>
                    <Grid className="claim-data"> {labCase.modelSrc} </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} container direction={'row'} className="box-container">
                <Grid item xs={3} className="claim-container">
                    <Grid container direction={'row'} className="claim-info">
                        <Grid>Provider Comment</Grid>
                    </Grid>
                    <Grid className="claim-data" sx={{ fontSize: "large" }}> 
                        {labCase.providerComment}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    );
});