import jwt_decode from 'jwt-decode';
import { action, makeObservable, observable } from "mobx";
import { Gateway } from '../api/Gateway';
import { GlobalUtils } from '../api/GlobalUtils';
import DictionaryListItem from '../entities/DictionaryListItem';
import { MainStore } from "./MainStore";

export default class AIReportDataLogMeasurementsStore {
    mainStore: MainStore;
    @observable submissionId = 0;
    @observable userId = 0;
    @observable dataLogValues = [];
    @observable loading = false;
    @observable vivosPropertyNames: DictionaryListItem[] = [];

    @action SetDataLogValues = (value: any) => { this.dataLogValues = value; }
    @action setDatalogLoading = (value: boolean) => { this.loading = !value; }
    @action setMeasurementSubmissionId = (value: number) => { this.submissionId = value; }
    @action setVivosPropertyNames = (value: DictionaryListItem[]) => { this.vivosPropertyNames = value; }

    constructor(mainstore: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    @action loadDataValues = (submissionId) => {
        const url = `aireports/measurmentpropertyvalues/${submissionId}`;
        Gateway.get(url).then(resp => {
            this.SetDataLogValues(resp);
            this.setDatalogLoading(false);
        })
    };

    @action loadVivosPropValues = () => {
        GlobalUtils.getVivosMeasurementProperties().then(resp => {
            this.setVivosPropertyNames(resp);
        })
    };

    @action saveDataValues = ( propertyId, column, newValue) => {
        console.log(this.submissionId + " " + propertyId + " " + column + " " + newValue);
        const url = `aireports/measurementpropertyvalue/${this.submissionId}`;
        if (column == "vivosPropertyId") {
            Gateway.post(url, { "propertyId": propertyId, "vivosPropertyId": newValue, "IsActive": true });

        } else if (column.toLowerCase() == "isactive") {
            Gateway.post(url, { "propertyId": propertyId, "IsActive": false });

        } else {
            Gateway.post(url, { "propertyId": propertyId, "column": column, "newValue": newValue, "IsActive": true  });
        }
    };

};
