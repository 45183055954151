import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    FormControl,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import Item from '../../../Item';
import CssTextField from '../../../CssTextField';
import "../../../../scss/_TreatmentPlanning.scss"

import { useMainStoreContext } from '../../../../stores/MainStore';

import { CbctConditions } from '../../../../entities/Enums';

interface ISleepConditionProps {
    sleepCondition?: string;
}

const radios = [
    { name: 'Improve', value: 497, key: 1 },
    { name: 'Maintain', value: 498, key: 2 },
    { name: 'N/A', value: 499, key: 3 },
];

interface IRow {
    name: string;
    callback: (id: any, value: string) => void;
    treatmentObjectiveId: number;
}

const Row = observer(({ name, callback, treatmentObjectiveId }: IRow) => {
    const [value, setValue] = useState<any[]>([]);

    const [newChoice, setNewChoice] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setNewChoice(Number(value));

        callback(Number(value), name);
    };

    useEffect(() => {
        setNewChoice(treatmentObjectiveId);

        setValue(radios);
    }, [treatmentObjectiveId, value]);

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {name}
            </TableCell>
            {value.map(radio => (
                <TableCell align="right">
                    <FormControl component="fieldset">
                        <RadioGroup value={newChoice} key={radio.value} onChange={handleChange}>
                            <Radio value={radio.value} name={radio.name} />
                        </RadioGroup>
                    </FormControl>
                </TableCell>
            ))}
        </TableRow>
    );
});

export const DiagnosisFromCBCT: FC<ISleepConditionProps> = observer(() => {
    const { cbctDiagnosis, cbctDiagnosisNotes, setCbctDiagnosis, setCbctDiagnosisNotes } =
        useMainStoreContext().patientTreatmentStore;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer className="treatmentTable">
                    <Table sx={{ minWidth: '50%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Diagnosis from the CBCT</TableCell>

                                <TableCell align="right">Improve</TableCell>
                                <TableCell align="right">Maintain</TableCell>
                                <TableCell align="right">N/A</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(cbctDiagnosis.some(el => el.listItemKey === CbctConditions.MAXILIAHYPERPLASI) ||
                                cbctDiagnosis.some(el => el.conditionId === 274)) && (
                                <Row
                                    name="Maxilla Hyperplasia"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.MAXILIAHYPERPLASI)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 274)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.MAXILIAHYPERPLASI) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}
                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.MAXILIAHYPOPLASIA) && (
                                <Row
                                    name="Maxilla Hypoplasia"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.MAXILIAHYPOPLASIA)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 275)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.MAXILIAHYPOPLASIA) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.MANDIBLEHYPERPLASIA) && (
                                <Row
                                    name="Mandible Hyperplasia"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.MANDIBLEHYPERPLASIA)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 276)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.MANDIBLEHYPERPLASIA) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.MANDIBLEHYPOPLASIA) && (
                                <Row
                                    name="Mandible Hypoplasia"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.MANDIBLEHYPOPLASIA)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 277)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.MANDIBLEHYPOPLASIA) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.TMJBEAKING) && (
                                <Row
                                    name="TMJ Beaking"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.TMJBEAKING)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 278)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.TMJBEAKING) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.EXCESSIVETUBEROSITY) && (
                                <Row
                                    name="Excessive Tuberosity	"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.EXCESSIVETUBEROSITY)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 279)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.EXCESSIVETUBEROSITY) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.ATROPHICBONE) && (
                                <Row
                                    name="Atrophic Bone	"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.ATROPHICBONE)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 280)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.ATROPHICBONE) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}

                            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.CBCTOTHER) && (
                                <Row
                                    name="Other"
                                    treatmentObjectiveId={
                                        cbctDiagnosis.find(el => el.listItemKey === CbctConditions.CBCTOTHER)
                                            ?.treatmentObjective ||
                                        cbctDiagnosis.find(el => el.conditionId === 281)?.treatmentObjective
                                    }
                                    callback={(id, name) => {
                                        setCbctDiagnosis(
                                            cbctDiagnosis.map(el => {
                                                if (el.listItemKey === CbctConditions.CBCTOTHER) {
                                                    return {
                                                        ...el,
                                                        treatmentObjective: id,
                                                        treatmentObjectiveName: name,
                                                    };
                                                }
                                                return el;
                                            }),
                                        );
                                    }}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {cbctDiagnosis.some(el => el.listItemKey === CbctConditions.CBCTOTHER) && (
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                placeholder="Other Sleep Disorders Notes"
                                required={true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCbctDiagnosisNotes(event.target.value);
                                }}
                                value={cbctDiagnosisNotes}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
});
