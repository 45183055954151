// Vendors
import React, { useEffect } from "react";
import { Grid, styled, Box, Typography, IconButton } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';

// Components
import UploadRotateCropper from "./UploadRotateCropper";
import IntraoralIcon from '../../imgs/png/Intraoral-Images.png';
import ExtraoralIcon from '../../imgs/png/Extraoral-Images.png';
import OcclusionIcon from '../../imgs/png/Impressions.png'
import ApplianceIcon from '../../imgs/png/Vivos-Appliance-1.png'
import BoxUploadWrapper from "../UI/UploadWrappers/BoxUploadWrapper";

// Entities
import PatientImage from "../../entities/PatientImage";

// API
import { observer } from "mobx-react";
import { FileUploader } from "react-drag-drop-files";
import _ from "lodash";
import { GlobalUtils } from "../../api/GlobalUtils";

const fileTypes = ["JPG", "PNG"];
interface IUploaderProps {
    type: string;
    typeOptions: any[];
    vivosId: string;
    visitId: number;
    extImages: any[];
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    deleteCallback(itemOption: number): any;
}

const UploadImageDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback, deleteCallback, typeOptions, type, vivosId, visitId, extImages }) => {

        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [images, setImages] = React.useState<any[]>([]);
        const [options, setOptions] = React.useState<JSX.Element[]>([]);
        const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            setOptions(typeOptions);
            if (extImages && extImages.length > 0) {
                _.forEach(extImages, (i, index) => {
                    i["extIndex"] = index;
                    if (i.imageType) {
                        selectedOptions.push(i.imageType.id);
                    }
                })
                setImages(extImages);
                setSelectedOptions(selectedOptions);
            }
            setHasLoaded(true);
        }, [])

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var img = {} as PatientImage;
            var image = URL.createObjectURL(file);
            img.data64String = await convertBase64(file) as unknown as string;
            img.data64Url = decodeURI(image);
            img.pathUrl = decodeURI(file.name);
            img["new"] = true;
            img["extIndex"] = extImages.length;
            extImages.push(img);
            parentCallback(img.data64String, img.data64Url, "");

        }

        const handleImageSet = (files) => {
            setIsPreview(false);
            var imglist: PatientImage[] = images;
            var promises: any[] = [];
            _.forEach(files, async (file) => {
                const promise = doWork(file, imglist);
                promises.push(promise);
            })
            Promise.all(promises).then(() => {
                setImages(imglist.length > 0 ? imglist : extImages);
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (image: any, itemOption) => {
            if (!image.new) {
                deleteCallback(itemOption);
            }
            var extImgIndex = image["extIndex"];
            if (extImgIndex) {
                extImages.splice(extImgIndex, 1);
            }
            images.splice(image.id, 1);
            setImages(images);
        }

        const handleOptionSelect = (event: any, oldOption: number, index: number) => {
            var selOpts = selectedOptions;
            _.remove(selOpts, (o) => { return o == oldOption });
            selOpts.push(event.target.value);
            setSelectedOptions(selOpts);
            var type = _.find(typeOptions, (t) => { return t.id == event.target.value });
            extImages[images[index]["extIndex"]].imageType = type;
            images[index].imageType = type;
            images[index]["edited"] = true;
            setKey(Math.floor(Math.random() * 42));
            if (!images[index].data64String) {
                doImagesWork(index);
            }
            setImages(images);
            return selOpts;
        }

        const doImagesWork = async (index) => {
            await fetch(GlobalUtils.getVisitRecordUrl(images[index].pathUrl, vivosId, visitId)).then(r => {
                r.blob().then(async blobFile => {
                    var image = URL.createObjectURL(blobFile);
                    await convertBase64(blobFile).then(resp => {
                        images[index].data64String = resp as unknown as string;
                    });
                });
            });
        }

        return (
            hasLoaded ?
                <Grid xs={12} className="formBasics">
                    <Grid xs={12} container direction="row" alignItems={"center"} justifyContent={"flext-start"} justifyItems="flex-start">
                        <Grid xs={4} item>
                            <Grid container justifyContent={'center'}>
                                <FileUploader label="Upload or drop a image here" handleChange={handleImageSet} types={fileTypes}
                                    multiple={true}
                                    children={
                                        <BoxUploadWrapper>
                                            <Box width={'400px'}>
                                                <Grid container direction="column" alignItems={'center'} justifyItems={'center'}>
                                                    {type == "intraoral" &&
                                                        <Grid item><img src={IntraoralIcon} className="uploadImg" /></Grid>
                                                    }
                                                    {type == "extraoral" &&
                                                        <Grid item><img src={ExtraoralIcon} className="uploadImg" /></Grid>
                                                    }
                                                    {type == "occlusion" &&
                                                        <Grid item><img src={OcclusionIcon} className="uploadImg" /></Grid>
                                                    }
                                                    {type == "appliance" &&
                                                        <Grid item><img src={ApplianceIcon} className="uploadImg" /></Grid>
                                                    }
                                                    {type == "multi" &&
                                                        <Grid item><IconButton><CollectionsIcon className="uploadImg" /></IconButton></Grid>
                                                    }
                                                    <Typography>Drag & Drop or</Typography>
                                                    <Typography>Click to Select Image(s)</Typography>
                                                </Grid >
                                            </Box>
                                        </BoxUploadWrapper>
                                    } name="file" /></Grid>
                        </Grid>
                        {
                            _.map(images, (img, i) => {
                                return <Grid xs={4} item sx={{ height: '100%', minHeight: '250px', paddingBottom: '100px' }}>
                                    <UploadRotateCropper
                                        index={key + i}
                                        cropperIndex={i}
                                        parentCallback={parentCallback}
                                        handleRemove={handleRemove}
                                        handleOptionSelect={handleOptionSelect}
                                        typeOptions={options}
                                        selectedOptions={selectedOptions}
                                        images={images}
                                        vivosId={vivosId}
                                        container={process.env.REACT_APP_VISITRECORD_CONTAINER as string}
                                        containerId={visitId}
                                        setImages={() => { }}
                                    />
                                </Grid>
                            })
                        }
                    </Grid>
                </Grid> : null
        )
    }

export default observer(UploadImageDragDrop);