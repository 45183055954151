interface ITeethChartTooth {
    id: number,
    chartId: number,
    toothNumber: number,
    isDeciduous: boolean,
    notes: string,
    supernumeraryPosition: number,
    missingReason?: number,
    uneruptedReason?: number,
    presentConditionsId?: number,
    presentConditionsArray?: any[],
    isActive: boolean
}

interface TeethChartTooth extends ITeethChartTooth {}

class TeethChartTooth{
    constructor({id,chartId,notes,toothNumber,isDeciduous,supernumeraryPosition, missingReason,presentConditionsId,uneruptedReason,presentConditionsArray,isActive}: ITeethChartTooth){
        this.id = id;
        this.chartId = chartId;
        this.notes = notes;
        this.toothNumber = toothNumber;
        this.isDeciduous = isDeciduous;
        this.missingReason = missingReason;
        this.presentConditionsId = presentConditionsId;
        this.supernumeraryPosition = supernumeraryPosition;
        this.uneruptedReason = uneruptedReason;
        this.presentConditionsArray = presentConditionsArray;
        this.isActive = isActive;
    }
}

export default TeethChartTooth;