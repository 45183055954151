import { makeStyles } from "@material-ui/core/styles";

export const boxStyles = makeStyles((theme) => ({
	leftBox:{
		border: '1px black solid',
		borderRadius: '20px',
		padding:'30px 10px 155px 10px',
		boxShadow: '3px 3px 3px 3px rgba(0,0,0, 0.5)',
		minHeight: '560px'
	},
	rightBox: {
		border: '1px black solid',
		borderRadius: '10px',
		padding:'30px 10px',
		boxShadow: '3px 3px 3px 3px rgba(0,0,0, 0.5)',
		minHeight: '560px'
	},
}));