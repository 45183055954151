import { action, computed, makeObservable, observable } from "mobx";
import { MainStore } from "./MainStore";
import Client, { Product } from 'shopify-buy';

export default class ShopifyStore {
    mainStore?: MainStore;
    constructor(mainstore?: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
		const storefrontAccessToken = process.env.REACT_APP_SHOPIFY_API_TOKEN ?? "";
		const domain = process.env.REACT_APP_SHOPIFY_STORE_DOMAIN ?? "";
		const client = Client.buildClient({
			storefrontAccessToken,
			domain,
			apiVersion: '2023-04',
		  });
		this.client = client;
    }
	@observable isCartModalOpen = false;
	@action setCartModalOpen = (open: boolean) => {
		this.isCartModalOpen = open;
	};

	@observable client: Client;

	@observable cart: Cart = {
		lineItems: [],
		subtotalPrice: 0,
		webUrl: "",
		id: "",
		order: {},
		ready: false
	};

	@computed get cartCount() {
		if (!this.cart.lineItems) return 0;
		return this.cart.lineItems.reduce((total: number, item: any) => {
			return total + item.quantity;
		}, 0);
	}

	@observable isCartOpen = false;
	@action setCartOpen = async (open: boolean) => {
		if(open)
			this.cart = await this.client.checkout.create();
		this.isCartOpen = open;
	};

	@action decrementQuantity = async (lineItem: any) => {
		const updatedQuantity = lineItem.quantity - 1;
		await this.updateQuantityInCart(lineItem.id, updatedQuantity);
	};
	@action incrementQuantity = async (lineItem: any) => {
		const updatedQuantity = lineItem.quantity + 1;
		await this.updateQuantityInCart(lineItem.id, updatedQuantity);
	};

	@action updateQuantityInCart = async (lineItemId: string, quantity: number) => {
		if(this.client && this.cart && this.cart.id) {
			const lineItemsToUpdate = [{ id: lineItemId, quantity }];
			this.cart = await this.client.checkout.updateLineItems(this.cart.id, lineItemsToUpdate);
		}
	};

	@action removeLineItemFromCart = async (lineItemId: any) => {
		if(this.client && this.cart && this.cart.id) {
			this.cart = await this.client.checkout.removeLineItems(this.cart.id, [lineItemId]);
		}
	};
	@action addVariantToCart = async (variantId: string, quantity: number) => {
		const lineItemsToAdd = [{ variantId, quantity}];
		if(this.client && this.cart && this.cart.id) {
			this.cart = await this.client.checkout.addLineItems(this.cart.id, lineItemsToAdd);
		}
	};

	@observable selectedVariant: Variant | null = null;
	@action setSelectedVariant = (variant: Variant | null) => {
		this.selectedVariant = variant;
	}

	@computed get selectedVariantImage() {
		if (!this.selectedVariant) return null;
		return this.selectedVariant.image.originalSrc;
	}
	@computed get selectedVariantQuantity() {
		if (!this.selectedVariant) return null;
		return this.selectedVariant.quantity;
	}

	@observable products: Product[] = []
	@action setProducts = (products: Product[]) => {
		this.products = products;
	}
}

export interface Variant {
	id: string;
	title: string;
	price: string;
	availableForSale: boolean;
	quantity: number;
	image: {
		originalSrc: string;
	};
};

export interface Cart {
	lineItems: any[];
	subtotalPrice: any;
	webUrl: string;
	id: string;
	order: {};
	ready: boolean;
}