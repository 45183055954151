interface VitalsAndMeasurmentsData {
    id: number, 
	patientId: number,
    visitId: number,
    heartbeats: number,
    bloodPressure: string,
    temperature:  number,
    respirations: number,
    oxygenSaturation:  number,
    height: number,
    weight: number,
    headCircumference: number,
    neckCircumference: number,
    waistCircumference: number,
    notes: string,
    unitOfMeasure: boolean,
    isActive: boolean
}

interface VitalsAndMeasurments extends VitalsAndMeasurmentsData {}

class VitalsAndMeasurments {
    constructor({id,patientId,visitId,heartbeats,bloodPressure,temperature,respirations,oxygenSaturation,height,weight,
        headCircumference,neckCircumference,waistCircumference,notes, unitOfMeasure, isActive}: VitalsAndMeasurmentsData) {
            this.id = id; 
            this.patientId = patientId;
            this.visitId = visitId;
            this.heartbeats = heartbeats;
            this.bloodPressure = bloodPressure;
            this.temperature = temperature;
            this.respirations = respirations;
            this.oxygenSaturation = oxygenSaturation;
            this.height = height;
            this.weight = weight;
            this.headCircumference = headCircumference;
            this.neckCircumference =neckCircumference ;
            this.waistCircumference = waistCircumference;
            this.notes = notes;
            this.unitOfMeasure = unitOfMeasure;
            this.isActive =  isActive;
    }

    toObject(): VitalsAndMeasurmentsData {
        return {...this};
    }
}
export default VitalsAndMeasurments;