import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import '../../src/scss/main.scss';
import { Gateway } from '../api/Gateway';
import { GlobalUtils } from '../api/GlobalUtils';
import { FileUploader } from 'react-drag-drop-files';
import CssSelectField from '../components/CssSelectField';
import DictionaryListItem from '../entities/DictionaryListItem';

interface ComponentProps {
    filePath: any;
    fileName?: any;
    blobUrl?: (data: any) => void;
    parentCallback?: (data: any) => void;
}

export const UploadPDF = (props: ComponentProps) => {
    const { filePath, blobUrl, parentCallback } = props;

    const [file, setFile] = useState<File>();
    const [reportType, setReportType] = useState<any>(0);
    const [isUpload, setIsUpload] = React.useState(false);
    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
    const [reportId, setReportId] = useState<any>(0);

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            setReportTypes(prevState => [...prevState, item]);
        });
    }, []);

    const handleChange = file => {
        setFile(file);
    };

    const uploadFile = () => {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                let fileUpload = {
                    fileName: process.env.NODE_ENV + '/' + filePath,
                    fileData: reader.result as string,
                };
                Gateway.post('/global/upload/file', fileUpload).then(resp => {
                    const call = {
                        fileName: resp,
                        reportType: reportType,
                        reportId: reportId,
                    };

                    parentCallback?.(call);
                });
            };
            setIsUpload(true);
        }
    };

    useEffect(() => {
        if (isUpload) {
            setFile(undefined);
            setReportType(0);
        }
    }, [isUpload]);

    return (
        <div
            style={{
                height: '100vh',
                width: '50vw',
                position: 'relative',
                borderColor: 'black',
            }}
        >
            <FileUploader
                label="Upload or drop a PDF here"
                handleChange={handleChange}
                name="file"
                types={['PDF']}
                id="pdfFileUploader"
            />
            <div id="pdf.fileName">{file ? file.name : null}</div>
            <br />
            <CssSelectField
                id={'select.intakeReportType'}
                label={'Select a Report Type'}
                sx={{ border: '0px' }}
                defaultValue="0"
                value={reportType ? reportType : '0'}
                onChange={event => {
                    let foundTypeId = reportTypes.find(item => item.name === event.target.value);
                    setReportId(foundTypeId?.id);
                    setReportType(event.target.value);
                }}
            >
                <MenuItem key={0} value={0}>
                    {'Select a Report Type'}
                </MenuItem>
                {reportTypes.map((item, index) => (
                    <MenuItem key={item.id} value={item.name}>
                        {item.name}
                    </MenuItem>
                ))}
            </CssSelectField>
            <Button
                className="vivButton normal"
                color="primary"
                variant="contained"
                component="span"
                disabled={!file || reportType === 0}
                onClick={uploadFile}
            >
                Upload
            </Button>
        </div>
    );
};
