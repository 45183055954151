import { MainStore } from './MainStore';
import { observable, action, makeObservable, computed } from 'mobx';
import ScanData from '../entities/ScanData';
import ImageItem from '../entities/ImageItems'
import DictionaryListItem from '../entities/DictionaryListItem';
import { Gateway } from '../api/Gateway';
import jwt_decode from 'jwt-decode';

const defaultDictionaryListItem = new DictionaryListItem({
  id: 0,
  name: "",
  listItemKey: "",
  value: "",
  isActive: true,
  dictionaryListId: 0
});
const chunkSize = 1048576 * 10;//its 10MB, increase the number measure in mb

export default class OcclusionStore {
  mainStore: MainStore;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;

    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      this.userId = tokenData.id;
    }
  }

  @observable userId = 0;
  @observable patientId = 0;
  @observable visitId = 0;
  @observable rmrelation = -1;
  @observable lmrelation = -1;
  @observable staffmember = '';
  @observable lg_3Dscantype = defaultDictionaryListItem;
  @observable lg_machinetype = defaultDictionaryListItem;
  @observable lg_2dImages = defaultDictionaryListItem;
  @observable impressiondate: Date = new Date(Date.now());
  @observable scansdata: ScanData[] = [];
  @observable imageitem3D: ImageItem[] = [];
  @observable occlusionModelTypeId: number = 0;
  @action setStaffMember = (staffmember: string) => { this.staffmember = staffmember };
  @action setLMRelation = (lmrelation: number) => { this.lmrelation = lmrelation };
  @action setRMRelation = (rmrelation: number) => { this.rmrelation = rmrelation };
  @action set3DScanType = (lg_3Dscantype: DictionaryListItem) => { this.lg_3Dscantype = lg_3Dscantype };
  @action setMachineType = (lg_machinetype: DictionaryListItem) => { this.lg_machinetype = lg_machinetype };
  @action set2DImages = (lg_2dImages: DictionaryListItem) => { this.lg_2dImages = lg_2dImages };
  @action setScansdata = (newScanValue: ScanData[]) => { this.scansdata = newScanValue };
  @action setImageItem3D = (newImageItem3D: ImageItem[]) => { this.imageitem3D = newImageItem3D };
  @action setPatientId = (newValue: number) => { this.patientId = newValue };
  @action setVisitId = (newValue: number) => { this.visitId = newValue };
  @action setOcclusionModelTypeId = (newValue: number) => { this.occlusionModelTypeId = newValue };


  @action addScan = (scan: ScanData) => { this.scansdata = [...this.scansdata, scan]; }
  @action addImage = (imageitem3D: ImageItem) => {
    imageitem3D.imgtype = this.lg_3Dscantype.listItemKey;
    imageitem3D.machine = this.lg_machinetype.listItemKey;

    if (imageitem3D.imgtype.length > 0 && imageitem3D.machine.length > 0) {
      this.imageitem3D = [...this.imageitem3D, imageitem3D];
    }
  }
  @action setScanData = (index: number, scan: ScanData) => { this.scansdata[index] = scan; }
  @action setImageItems = (index: number, image: ImageItem) => { this.imageitem3D[index] = image; }
  @action setImpressionDate = (impressiondate: Date) => { this.impressiondate = impressiondate };

  @computed get getScans(): ScanData[] {
    return this.scansdata;
  }

  @computed get getImageItem(): ImageItem[] {
    return this.imageitem3D;
  }

  @action updateOcclusionDetails = (visitId: number) => {
    return new Promise((resolve, reject) => {
      var promises: any[] = [];
      var details = {
        machineBrandId: this.lg_machinetype.id,
        leftMolarRelationship: this.lmrelation === -1 ? null : this.lmrelation,
        rightMolarRelationship: this.rmrelation === -1 ? null : this.rmrelation,
        impressionDate: this.impressiondate,
        staffWhoTookImpression: this.staffmember

      }
      promises.push(Gateway.post(`visitrecord/${visitId}/occlusiondetails`, details).then(() => { }));

      Promise.all(promises).then(() => {
        resolve(true);
      })

    });
  }

  @action resetScan = () => {
    new ScanData({
      title: ' ',
      scantype: ' ',
      notes: '',
      imageUrl: '',
      rdbVenus: '',
      rdbSelera: '',
      rdbHead: '',
      rdbOpen: '',
      rdbIncomp: '',
      rdbMicrogenia: '',
      rdbMacrogenia: ''
    })
  }

  @action
  updateOcclusionModelType = async () => {
    await Gateway.put(`/patient/${this.patientId}/occlusionModelType/${this.occlusionModelTypeId}/${this.userId}`, {}).then(() => {
    });
  }

}
