import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid, Button, SelectChangeEvent, styled, Box, Typography } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import StlIcon from '../../imgs/png/stl.png';
import _ from "lodash";
import Stl3DViewer from "./Stl3DViewer";
import PatientImage from "../../entities/PatientImage";
import DictionaryListItem from "../../entities/DictionaryListItem";

const fileTypes = ["STL"];
interface IUploaderProps {
    parentCallback(data64string: string, dataPathUrl: string): any;
    deleteCallback(itemOption: number): any;
    disabled?: boolean;
    imageOption?: any;
    allowMultiple: boolean;
    extImages: any[];
    vivosId: string;
    visitId: number;
}

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);

const Upload3DImgDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback, deleteCallback, allowMultiple, disabled, imageOption, extImages, vivosId, visitId }) => {

        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [images, setImages] = React.useState<any[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            if (extImages && extImages.length > 0) {
                setImages(extImages);
            }
            setHasLoaded(true);
        }, [])

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var img = {} as PatientImage;
            var type = {} as DictionaryListItem;
            if (imageOption) {
                type = imageOption;
            } else {
                type.dictionaryListId = 0;
                type.id = 0;
                type.isActive = true;
                type.listItemKey = "";
                type.name = "";
            }
            var image = URL.createObjectURL(file);
            img.data64String = await convertBase64(file) as unknown as string;
            img.data64Url = decodeURI(image);
            img.pathUrl = decodeURI(file.name);
            img.imageType = type;
            img["new"] = true;
            imglist.push(img);
        }

        const handleImageSet = (files) => {
            setIsPreview(false);
            var imglist: any[] = images;
            var promises: any[] = [];
            if (allowMultiple) {
                _.forEach(files, async (file) => {
                    promises.push(doWork(file, imglist));
                })
            } else {
                promises.push(doWork(files, imglist));
            }

            Promise.all(promises).then(() => {
                setImages(imglist);
                _.forEach(imglist, (i) => {
                    i["extIndex"] = extImages.length;
                    extImages.push(i)
                });
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (index: number) => {
            let deleted = images.splice(index, 1);
            if (!deleted[0].new) {
                deleteCallback(imageOption ? imageOption.id : 187);
            }
            setImages(images);
            setKey(Math.floor(Math.random() * 42));
            if (!allowMultiple) {
                setIsPreview(false);
            }
        }

        return (
            <Grid xs={12} className="formBasics">
                <Grid xs={12} container direction="row" alignItems={"center"} justifyContent={"flext-start"} justifyItems="flex-start">
                    <Grid xs={4} item>
                        <FileUploader label="Upload or drop a image here"
                            handleChange={handleImageSet} types={["STL"]} multiple={allowMultiple ? true : false}
                            children={
                                <BoxUploadWrapper>
                                    <Grid container direction="column" alignItems={'center'} justifyItems={'center'}>
                                        <img src={StlIcon} className="uploadImg" style={{ height: "124px", width: "94px" }} />
                                        <Typography>Drag & Drop or</Typography>
                                        <Typography>Click to Select Stl File(s)</Typography>
                                    </Grid >
                                </BoxUploadWrapper>
                            }
                            name="file" disabled={disabled ? disabled : false} />
                    </Grid>
                    {
                        hasLoaded ?
                            // allowMultiple ?
                            _.map(images, (img, i) => {
                                return <Grid xs={4} item >
                                    <Stl3DViewer index={i}
                                        key={key + i} image={img} parentCallback={parentCallback} handleRemove={handleRemove}
                                        container={process.env.REACT_APP_VISITRECORD_CONTAINER as string} containerId={visitId} vivosId={vivosId} />
                                </Grid>
                            })
                            // :
                            //isPreview ?
                            // <Grid xs={4} item >
                            //     <Stl3DViewer index={0}
                            //         key={key + 0} image={(images && images.length > 0) ? images[0].data64Url : ""} parentCallback={parentCallback} handleRemove={handleRemove} 
                            //         container={process.env.REACT_APP_VISITRECORD_CONTAINER as string} containerId={visitId} vivosId={vivosId}/>
                            // </Grid>
                            : <></>

                    }
                </Grid>
            </Grid>
        )
    }

export default observer(Upload3DImgDragDrop);