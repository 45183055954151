import React, { Component, RefObject } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import {
    Grid,
    Typography,
    Box,
    Divider,
    useTheme,
    styled,
    CardActionArea,
    Card,
    alpha,
    Avatar,
    PaperProps,
} from '@mui/material';

interface SelectionProp extends PaperProps {
    isSelected?: boolean;
}

const DividerPrimary = styled(Divider)<SelectionProp>(
    ({ theme, isSelected }) => `
        width: 50px;
        background: ${isSelected ? theme.colors.alpha.white[100] : theme.colors.primary.main} !important;
        height: 6px !important;
        border-radius: 50px;
    `
);

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
        background: ${theme.colors.gradients.blue1};
        color:  ${theme.palette.primary.contrastText};
  `
);

const CardWrapper = styled(Card)<SelectionProp>(
    ({ theme, isSelected }) => `
      transition: ${theme.transitions.create(['transform', 'box-shadow'])};
      transform: scale(1);
      position: relative;
      z-index: 5;
      background-color: ${isSelected ? theme.colors.primary.main : 'inherit'};
  
      &:hover {
          z-index: 6;
          box-shadow: 
              0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
              0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
              0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
              0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
          transform: scale(1.01);
      }
    `
);

const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
  
          .MuiTouchRipple-root {
            opacity: .2;
          }
    
          .MuiCardActionArea-focusHighlight {
            background: transparent;
          }
    
          &:hover {
            .MuiCardActionArea-focusHighlight {
              opacity: 0;
            }
          }
    `
);

interface DashboardProps {
    cardLabelText: string;
    cardNumberValue: number;
    onFilter: any;
    filter: number;
    currentFilter: number;
    icon: any;
}

@observer
class DashboardInfoCard extends Component<DashboardProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Grid item xs={12} sm={6} md={4} lg={12 / 5} xl={12 / 5} >
                <CardWrapper onClick={this.toggleSelected} isSelected={this.props.filter === this.props.currentFilter}>
                    <CardActionAreaWrapper
                        sx={{
                            p: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h1"
                                sx={(theme) => ({
                                    fontSize: `${theme.typography.pxToRem(40)}`,
                                    color: `${this.props.filter === this.props.currentFilter && theme.colors.alpha.white[100]}`
                                })}
                            >
                                {this.props.cardNumberValue}
                            </Typography>
                            <DividerPrimary
                                isSelected={this.props.filter === this.props.currentFilter}
                                sx={{
                                    my: 2,
                                }}
                            />
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                sx={(theme) => ({
                                    fontSize: `${theme.typography.pxToRem(14)}`,
                                    textTransform: 'uppercase',
                                    color: `${this.props.filter === this.props.currentFilter && theme.colors.alpha.white[100]}`
                                })}
                            >
                                {this.props.cardLabelText}
                            </Typography>
                        </Box>
                        <AvatarWrapper>
                            {this.props.icon}
                        </AvatarWrapper>
                    </CardActionAreaWrapper>
                </CardWrapper>
            </Grid>
        );
    }

    @action
    toggleSelected = () => {
        this.props.onFilter(!(this.props.filter === this.props.currentFilter));
    }
};

export default DashboardInfoCard;
