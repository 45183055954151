// Vendors
import * as React from 'react';
import { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  CircularProgress,
  CircularProgressProps,
  Checkbox,
  FormControlLabel,
  Card,
  styled,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Components
import PatientPersonalInformation from './PatientPersonalInformation';
import ScanCBCT from '../ImagesAndDocs/ScanCbct';
import ScanOcclusion from '../ImagesAndDocs/ScanOcclusion';
import IntraoralImages from '../ImagesAndDocs/IntraoralImages';
import { TeethCharting } from '../TeethCharting/TeethCharting';
import PatientHealthInfo from './PatientHealthInfo';
import ExtraoralImages from '../ImagesAndDocs/ExtraoralImages';
import Documents from '../ImagesAndDocs/Documents';
import SleepTest from '../ImagesAndDocs/SleepTest';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

// Entities
import PatientHistory from '../../entities/PatientHistory';
import PatientVisit from '../../entities/PatientVisit';
import LegalGuardianData from '../../entities/LegalGuardian';
import { OcclusionModelType } from '../../entities/Enums';

// API
import { observer } from "mobx-react";
import * as _ from "lodash";
import { GlobalUtils } from '../../api/GlobalUtils';
import { Gateway } from '../../api/Gateway';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { isMinor } from '../../api/getAge';

// Styling
import '../../scss/main.scss';


//'Sleep Test',
const steps = ['General Information', 'Patient Forms', 'Clinical Forms', 'Extraoral Images', 'Intraoral Images', 'CBCT Upload', 'Patient Occlusion', 'Teeth Charting', 'Sleep Test'];

const BoxBorderBottom = styled(Box)(
  () => `
        border-bottom: transparent 5px solid;
  `
);

interface ICreatePatientProps {
  onClose(): any;
}


function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const CreatePatientWizard: React.FC<ICreatePatientProps> = observer(({ onClose }) => {
  const { createPatientReset, getPatient, setIntraoralImageType, setExtraoralImageType, dateOfBirth, getLegalGuardians,
    setPatient, setPatientId, validatePatient, setCbctMachineBrandType, setMachineBrand3DType, setpatientOcclusionImageType,
    setpatientImageType, softValidateExtraoralImages, softValidateIntraoralImages, softValidateCBCTUploadImages, uploadImages, setPatientImages,
    softValidateOcclusionImages, softValidateOcclusion3DImages, isLoading, loadingText, setIsLoading, setIsLoadingText, setMolarRelationshipType, setDateOfBirth, setLegalGuardians,
    patientExtraoral3DImages, patientExtraoralImages, patientIntraoralImages, patientOcclusionImages, patientDocuments, setEthnicities } = useMainStoreContext().createPatientStore;
  const { addGuardian, setGuardians, validateGuardians, guardiansReset, legalGuardians, getGuardians } = useMainStoreContext().createLegalGuardianStore;
  const { validateExtraoralImages } = useMainStoreContext().createPatientStore;
  const { getVitalsAndMeasurements, setVisitId, setVitalsPatientId, vitalsAndMeasurementsReset } = useMainStoreContext().vitalsAndMeasurementsStore;
  const { getPatientHistory, patientHistoryReset, setMedicalList, setDentalList, setAllergyList, setHistory,
    setCpapList, setPatientHistory, softValidatePatientHistory } = useMainStoreContext().patientEditStore;
  const { saveTeethChart, resetTeethChart, softValidateTeethChart } = useMainStoreContext().teethChartStore;
  const scanStore = useMainStoreContext().scanStore;
  const { updateOcclusionDetails, updateOcclusionModelType, occlusionModelTypeId } = useMainStoreContext().occlusionStore;
  const { id, getVisitId, setPatientVitalsAndMeasurements, setVisitPatientId, saveVisit, saveVitals, setVisit, uploadFiles, setPatientUploadFiles } = useMainStoreContext().visitRecordStore;
  const { validateTreatmentPlan, resetTreatmentPlanStore, saveTreatmentPlan } = useMainStoreContext().treatmentPlanStore;

  const [userID, setUserId] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [toggleValidateModal, setToggleValidateModal] = React.useState(false);
  const [toggleSoftValidateModal, setToggleSoftValidateModal] = React.useState(false);
  const [togglePatientFormModal, setTogglePatientFormModal] = React.useState(false);
  const [toggleProviderFormModal, setToggleProviderFormModal] = React.useState(false);
  const [validationList, setValidationList] = React.useState<any[]>([]);
  const [softValidationList, setSoftValidationList] = React.useState<any[]>([]);

  const isStepOptional = (step: number) => {
    return step === step + 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const validate = (): any[] => {
    let validations = validatePatient();
    validations = validations.concat(validateGuardians());
    setValidationList(validations);
    return validations;
  }

  const softValidate = (validationFunction): any[] => {
    let softValidations: string[] = [];
    softValidations = softValidations.concat(validationFunction());
    setSoftValidationList(softValidations);
    return softValidations;
  }

  useEffect(() => {
    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      setUserId(tokenData.id);
    }
    handleReset();
    populateLists();

    if (moment(dateOfBirth).isSame(new Date(), "day")) {
      setDateOptions(dateOfBirth);
    }
  }, []);

  const GENERALINFOSTEP = 0;
  const PATIENTDOCUMENTSSTEP = 1;
  const CLINICALDOCUMENTSSTEP = 2;
  // const PATIENTHISTORYSTEP = 2;
  const EXTRAORALIMAGESSTEP = 3;
  const INTRAORALIMAGESSTEP = 4;
  const CBCTIMAGESSTEP = 5;
  const OCCLUSIONSTEP = 6;
  const TEETHCHARTINGSTEP = 7;
  const SLEEPTESTSTEP = 8;
  const populateLists = () => {
    var medhxopts: any[] = [];
    GlobalUtils.getMedicalHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        medhxopts[s.listItemKey] = s.value;
      });
      setMedicalList(medhxopts);
    });

    var dopts: any[] = [];
    GlobalUtils.getDentalHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        dopts[s.listItemKey] = s.value;
      });
      setDentalList(dopts);
    });

    var copts: any[] = [];
    GlobalUtils.getCpapMadHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        copts[s.listItemKey] = s.value;
      });
      setCpapList(copts);
    });

    var aopts: any[] = [];
    GlobalUtils.getAllergyHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        aopts[s.listItemKey] = s.value;
      });
      setAllergyList(aopts);
    });
  }

  const handleNext = (isProspect?: boolean) => {
    const patient = getPatient();
    if (activeStep === GENERALINFOSTEP) {
      if (isProspect) {
        patient.isProspect = true;
      }
      const validations = validate();
      if (validations.length <= 0) {
        setIsLoadingText("Saving Patient...Please Standby");
        setIsLoading(true);
        Gateway.post(`/patient`, patient).then((resp) => {
          if (resp) {
            setPatient(resp);
            if (resp["legalGuardians"]) {
              setGuardians(resp["legalGuardians"]);
            }
            setPatientId(resp["patients"][0].id);
            saveTreatmentPlan(resp["patients"][0].id);
            setIsLoading(false);
            if (isProspect) {
              onClose();
            } else {
              doNextStep();
            }
          }
        });
      } else {
        setToggleValidateModal(true);
        return false;
      }
    } else if (activeStep === PATIENTDOCUMENTSSTEP) {
      setIsLoadingText("Saving Documents...Please Standby");
      setIsLoading(true);
      uploadFiles(patient.id, getVisitId()).then(() => {
        setPatientUploadFiles([]);
        setIsLoading(false);
        doNextStep();
      });

    } else if (activeStep === CLINICALDOCUMENTSSTEP) {
      setIsLoadingText("Saving Documents...Please Standby");
      setIsLoading(true);
      uploadFiles(patient.id, getVisitId()).then(() => {
        setPatientUploadFiles([]);
        setIsLoading(false);
        doNextStep();
      });

    }
    // else if (activeStep === PATIENTHISTORYSTEP) {
    //   const validations = softValidate(softValidatePatientHistory);
    //   if (validations.length > 0 && !toggleSoftValidateModal) {
    //     setToggleSoftValidateModal(true);
    //   }
    //   else {
    //     setToggleSoftValidateModal(false);
    //     setIsLoadingText("Saving Patient History...Please Standby");
    //     setIsLoading(true);
    //     let history = getPatientHistory();
    //     setPatientHistory(history as unknown as PatientHistory);
    //     setHistory(history);
    //     history["patientId"] = patient.id;
    //     Gateway.post(`/patient/${patient.id}/history`, history);
    //     setVisitPatientId(patient.id);
    //     let vitals = getVitalsAndMeasurements();
    //     vitals["patientId"] = patient.id;
    //     setPatientVitalsAndMeasurements(vitals);
    //     saveVisit().then((resp) => {
    //       var visit = resp as unknown as PatientVisit;
    //       if (visit) {
    //         setVisit(visit);
    //         setVisitId(visit.id);
    //         saveVitals().then((resp) => {
    //           setVisit(resp);
    //         });
    //       }
    //     });

    //     setIsLoading(false);
    //     doNextStep();
    //   }
    // } 
    else if (activeStep === EXTRAORALIMAGESSTEP) {
      setPatientImages(patientExtraoralImages.concat(patientExtraoral3DImages));
      const softValidations = softValidate(softValidateExtraoralImages);
      const validations = validateExtraoralImages();
      if (softValidations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else if (validations.length > 0) {
        setValidationList(validations);
        setToggleValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading Extraoral Images....Please Standby")
        setIsLoading(true);
        uploadImages(getVisitId(), patient.providerId).then(() => {
          setPatientImages([]);
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === INTRAORALIMAGESSTEP) {
      setPatientImages(patientIntraoralImages);
      const validations = softValidate(softValidateIntraoralImages);
      if (validations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading Intraoral Images...Please Standby")
        setIsLoading(true);
        uploadImages(getVisitId(), patient.providerId).then(() => {
          setPatientImages([]);
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === CBCTIMAGESSTEP) {
      const validations = softValidate(softValidateCBCTUploadImages);
      if (validations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading CBCT Scans...Please Standby");
        setIsLoading(true);
        scanStore.setVisitId(getVisitId());
        scanStore.updateCbctDetails().then(() => {
          setPatientImages([]);
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === OCCLUSIONSTEP) {
      setPatientImages(patientOcclusionImages);
      const validations = softValidate(softValidateOcclusion3DImages);
      if (validations.length > 0 && !toggleSoftValidateModal && occlusionModelTypeId !== OcclusionModelType.Physical) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading Occlusion Images...Please Standby");
        setIsLoading(true);
        uploadImages(getVisitId(), patient.providerId).then(() => {
          updateOcclusionDetails(getVisitId()).then(() => {
            updateOcclusionModelType().then(() => {
              setPatientImages([]);
              setIsLoading(false);
              doNextStep();
            })
          });
        });
      }
    } else if (activeStep === TEETHCHARTINGSTEP) {
      const validations = softValidate(softValidateTeethChart);
      if (validations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Saving TeethCharting...Please Standby");
        setIsLoading(true);
        saveTeethChart().then(() => {
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === SLEEPTESTSTEP) {
      setIsLoadingText("Saving Documents...Please Standby");
      setIsLoading(true);
      uploadFiles(patient.id, getVisitId()).then(() => {
        setPatientUploadFiles([]);
        setIsLoading(false);
        doNextStep();
      });
      onClose();
    }
  };

  const doNextStep = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      //createPatientReset();
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setIsLoading(false);
    setActiveStep(0);
    setValidationList([]);
    createPatientReset();
    vitalsAndMeasurementsReset();
    patientHistoryReset();
    resetTeethChart();
    guardiansReset();
    resetTreatmentPlanStore();
  };

  const LGDEFAULTVALUEPARENT = 18;
  const defaultLGValues = {
    firstName: "",
    lastName: "",
    relationshipTypeId: LGDEFAULTVALUEPARENT,
    emailAddress: "",
    primaryPhone: "",
    altPhone: ""
  }

  const setDateOptions = (newValue: any) => {
    if (isMinor(newValue) && legalGuardians.length === 0) {
      addGuardian(new LegalGuardianData(
        defaultLGValues
      ));
    } else if (!isMinor(newValue)) {
      guardiansReset();
    }
    setLegalGuardians(legalGuardians);
    setGuardians(legalGuardians);
  }

  React.useMemo(() => {
    setIntraoralImageType(GlobalUtils.getIntraOralImages());
    setExtraoralImageType(GlobalUtils.getExtraOralImages());
    setMachineBrand3DType(GlobalUtils.get3DMachineBrands());
    setCbctMachineBrandType(GlobalUtils.getCBCTMachineBrands());
    setpatientOcclusionImageType(GlobalUtils.getPatientOcclusionImageTypes());
    setpatientImageType(GlobalUtils.getPatientImageTypes());
    setMolarRelationshipType(GlobalUtils.getMolarRelationships());
    setEthnicities(GlobalUtils.getEthnicities());
  }, [])

  return (
    <div style={{
      width: "100%",
      height: "100%"
    }}>
      {isLoading &&
        <div style={{
          // do your styles depending on your needs.
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}>
          {
            <Grid container direction="row" justifyContent="center">
              <Grid container justifyContent="center"><Grid>{loadingText}</Grid></Grid>

              <CircularProgress sx={{
                width: "90%",
                height: "90%"
              }} />
            </Grid>
          }
        </div>
      }
      {
        !isLoading &&
        <Box minHeight={'100%'} >

          <Dialog
            open={toggleValidateModal}
            onClose={() => { }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <Grid display="flex" alignItems="center">
                <Grid flexGrow={1} >Invalid Patient Data</Grid>
                <Grid>
                  {<IconButton className="closeButton" onClick={() => { setToggleValidateModal(false) }}>
                    <CloseIcon />
                  </IconButton>}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid>
                <Grid>Please correct the following:</Grid>
                <Grid>{_.map(validationList, (vl) => {
                  return <Grid>{vl}</Grid>
                })}</Grid>
              </Grid>
              <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setToggleValidateModal(false) }}>OK</Button></Grid>
            </DialogContent>
          </Dialog>
          <Dialog
            open={toggleSoftValidateModal}
            onClose={() => { }}
            aria-labelledby="form-dialog-soft"
          >
            <DialogTitle id="form-dialog-soft">
              <Grid display="flex" alignItems="center">
                <Grid flexGrow={1} >Invalid Patient Data</Grid>
                <Grid>
                  {<IconButton className="closeButton" onClick={() => { setToggleSoftValidateModal(false) }}>
                    <CloseIcon />
                  </IconButton>}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid marginBottom={3}>
                <Grid marginBottom={1}>Please correct the following:</Grid>
                <Grid>{_.map(softValidationList, (vl) => {
                  return <Grid>{vl}</Grid>
                })}</Grid>
              </Grid>
              <Grid container sx={{ justifyContent: "end" }}>
                <Button variant="contained" onClick={() => { setToggleSoftValidateModal(false) }}>Fix Now</Button>&nbsp;&nbsp;
                <Button variant="contained" onClick={() => { handleNext(false); setToggleSoftValidateModal(false); }}>Complete Later</Button>
              </Grid>
            </DialogContent>
          </Dialog>
          <Stepper activeStep={activeStep}
            alternativeLabel={true}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) { }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps} className="stepperStep">
                  <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                  {/* <StepConnector className="line"></StepConnector> */}
                </Step>
              );
            })}
          </Stepper>
          <hr />
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button className='vivButton large' onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box>
                {activeStep === GENERALINFOSTEP ? (<Box>
                  <PatientPersonalInformation />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === PATIENTDOCUMENTSSTEP ? (<Box>
                  <Grid><Documents isIntake={true} visit={undefined} isPatientFormStep={true} /></Grid>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === CLINICALDOCUMENTSSTEP ? (<Box>
                  <Grid><Documents isIntake={true} visit={undefined} isPatientFormStep={false} /></Grid>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {/* {activeStep === PATIENTHISTORYSTEP ? (<Box>
                  <PatientHealthInfo />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                } */}
                {activeStep === EXTRAORALIMAGESSTEP ? (<Box>
                  <ExtraoralImages isIntake={true} isVisit={false} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === INTRAORALIMAGESSTEP ? (<Box>
                  <IntraoralImages isIntake={true} isVisit={false} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === CBCTIMAGESSTEP ? (<Box>
                  <ScanCBCT isIntake={true} visit={undefined} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === OCCLUSIONSTEP ? (<Box padding={3}>
                  <ScanOcclusion isIntake={true} visit={undefined} isRework={false} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === TEETHCHARTINGSTEP ? (<Box>
                  <Grid><TeethCharting /></Grid>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === SLEEPTESTSTEP ? (<Box>
                  <Grid><SleepTest isIntake={true} visit={undefined} /></Grid>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }

                <BoxBorderBottom
                  padding={1}
                  sx={(theme) => ({
                    borderBottomColor: theme.palette.primary.main,
                    width: '100%',
                    backgroundColor: theme.colors.alpha.black[5],
                    position: 'absolute',
                    bottom: '0',
                  })}>
                  <Grid container justifyContent={'right'} alignContent={'center'}>
                    {activeStep !== 0 && <Button
                      variant="contained"
                      color='inherit'
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{
                        mt: { xs: 2, sm: 0, margin: 5 }
                      }}
                    >
                      Back
                    </Button>}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                      </Button>
                    )}
                    {activeStep === GENERALINFOSTEP &&
                      <Tooltip title={'Quickly create patient profile and add visit records at a later time.'} arrow placement='top'>
                        <Button sx={{
                          mt: { xs: 2, sm: 0, margin: 5 }
                        }} variant="contained" color="info" onClick={() => { handleNext(true) }}>
                          Save & Finish Later
                        </Button></Tooltip>
                    }
                    <Button sx={{
                      mt: { xs: 2, sm: 0, margin: 5 }
                    }} variant="contained" color="primary" onClick={() => { handleNext(false) }}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Save & Continue'}
                    </Button>
                  </Grid>
                </BoxBorderBottom>
              </Box>
            </React.Fragment>
          )}
        </Box>
      }
    </div>
  );
});