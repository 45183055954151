import { FC } from 'react';
import * as React from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { AlertDialog } from '../../components/AlertDialog';

import { Grid, Tab, Tabs, Typography } from '@mui/material';

import { useMainStoreContext } from '../../stores/MainStore';
import AIReport from '../../entities/AIReports';
import AIReportEntryBL from './AIReportEntryBL';
import { AIReportHeader } from './components/AIReportHeader';
import { AIReportImages } from './components/AIReportImages';
import { AIReportProcessedImages } from './components/AIReportProcessedImages';
import { AIReportDatalog } from './components/AIReportDatalog';
import { AIReportDownloadUpload } from './components/AIReportDownloadUpload';
import AIReportInfo from './components/AIReportInfo';
import { AiReportAddons } from '../../components/AiReportOrder/AiReportAddons';
import { AIReportAddons } from './components/AIReportAddons';
import { PatientHeaderTwo } from '../../components/PatientProfile/PatientDetailComponents/PatientHeaderTwo';
import PatientHeaderLayout from '../../components/PatientProfile/PatientDetailComponents/PatientHeaderLayout';
import { AIReportActionButtons } from './components/AIReportActionButtons';
import { AIReportHeaderDetails } from './components/AIReportHeaderDetails';

interface IGIProps {
    submissionId: number;
    aiReport: AIReport;
    onClose(): any;
    showCSVModal(submissionId: number);
}

interface AppointmentTypeOptionType {
    inputValue?: string;
    name: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const ALLIMAGESTAB = 0;
const PROCESSEDIMAGETAB = 1;
const REPORTINFOTAB = 2;
const DATALOG = 3;
const AIREPORTDOWNUPLOAD = 4;
const ADDONS = 5;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid>
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}
const reportTabs = [
    'Submitted Images',
    'Processed Images',
    'Report Info',
    'Data Log',
    'Download / Upload',
    'Add-Ons',
];


// function a11yProps(index: number) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

export const AIReportEntry: FC<IGIProps> = observer(({ submissionId, aiReport, showCSVModal, onClose }) => {
    const [editBL, setEditBL] = React.useState(new AIReportEntryBL());
    const {
        loadReport,
        submission,
        visit,
        patient,
        isLoading,
        setSubmissionId,
        resetSubmission,
        triggerReportGenerate,
        validationMessage,
        setTriggerReportGenerate,
        setTriggerShowAiReportModal,
        setMeasurements,
    } = useMainStoreContext().aiReportEntryStore;
    //const [store] = React.useState(new PatientStore());
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(0);
    const { dataLogValues, loadDataValues, setMeasurementSubmissionId } = useMainStoreContext().measurementsData;

    React.useEffect(() => {
        resetSubmission().then(() => {
            setSubmissionId(submissionId);
            setMeasurementSubmissionId(submissionId);
            loadReport().then(() => {
                setHasLoaded(true);
                loadDataValues(submissionId);
            });
        })
    }, []);

    React.useMemo(() => {
        setMeasurements(dataLogValues);
    }, [dataLogValues, setMeasurements]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setActiveTab(newValue);
    };

    return hasLoaded ? (
        <Grid container>
            <Grid item xs={12}>
                <PatientHeaderLayout
                    patientImageUrl={undefined}
                    patient={patient}
                    provider={''}
                    providerPhone={undefined}
                    providerEmail={''}
                    tabs={reportTabs}
                    activeTab={activeTab}
                    handleTabChange={handleChange}
                    isLoading={isLoading}
                    statusColumnContent={
                        <AIReportHeaderDetails
                            submission={submission}
                            aiReport={aiReport}
                            patient={patient}
                        />
                    }
                    buttonColumnContent={
                        <AIReportActionButtons
                            submission={submission}
                            aiReport={aiReport}
                            patient={patient}
                            showCSVModal={showCSVModal}
                        />
                    }
                />
            </Grid>
            {/* <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', fontFamily: "Proxima Nova" }}>
                <Tabs value={value} className="tabs" onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Submitted Images" {...a11yProps(ALLIMAGESTAB)} />
                    <Tab label="Processed Images" {...a11yProps(PROCESSEDIMAGETAB)} />
                    <Tab label="Report Info" {...a11yProps(REPORTINFOTAB)} />
                    <Tab label="Data Log" {...a11yProps(DATALOG)} />
                    <Tab label="Download / Upload" {...a11yProps(AIREPORTDOWNUPLOAD)} />
                    <Tab label="Add-Ons" {...a11yProps(ADDONS)} />
                </Tabs>
            </Grid> */}
            <Grid item xs={12}>
                {/* <Grid className={"tabContent"} style={{ overflowY: 'hidden' }}> */}
                <TabPanel value={value} index={PROCESSEDIMAGETAB}>
                    <AIReportProcessedImages />
                </TabPanel>
                <TabPanel value={value} index={ALLIMAGESTAB}>
                    <AIReportImages />
                </TabPanel>
                <TabPanel value={value} index={REPORTINFOTAB}>
                    <AIReportInfo visitId={visit.id} />
                </TabPanel>
                <TabPanel value={value} index={DATALOG}>
                    <AIReportDatalog submissionId={submission.id} dataLogValues={dataLogValues} />
                </TabPanel>
                <TabPanel value={value} index={AIREPORTDOWNUPLOAD}>
                    <AIReportDownloadUpload submissionId={submission.id} />
                </TabPanel>
                <TabPanel value={value} index={ADDONS}>
                    <AIReportAddons submissionId={submission.id} />
                </TabPanel>
            </Grid>
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
            <Grid>
                <AlertDialog
                    close={() => {
                        setTriggerReportGenerate(false);
                        // setTriggerShowAiReportModal(false);
                    }}
                    open={triggerReportGenerate}
                    title="AI Report"
                    multipleMessage={validationMessage}
                />
            </Grid>
        </Grid>
    ) : (
        <></>
    );
});
