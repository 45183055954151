import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, RadioGroup, Radio } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';

import CssTextField from "../CssTextField";
import Item from "../Item";
import { useMainStoreContext } from "../../stores/MainStore";
import { isNull } from "lodash";
import { AlertDialog } from '../AlertDialog';

interface ComponentProps {
    visitId: number
}

const PerceivedStress = (props: ComponentProps) => {
    const { visitId } = props;
    const [hasLoaded, setHasLoaded] = useState(false);
    const { loadReportPropertiesData, saveReportInfo } = useMainStoreContext().intakeReportsStore;
    const [reportValues, setReportValues] = useState<any>([]);
    const [generalScore, setGeneralScore] = useState<number>(0);
    const [recentScore, setRecentScore] = useState<number>(0);
    const [disabled, setDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);

    useEffect(() => {
        loadReportPropertiesData('perceivedstress').then(resp => {
            resp.map(r => reportValues.push({ PropertyId: r.id, Name: r.name, Value: "1" }));
            setHasLoaded(true);
        })
    }, []);

    const questions = (index) => {
        return (<>
            <Grid item xs={4}>
                <FormGroup>
                    <FormControlLabel id="restedLabel" className="sectionHdr" control={<Grid>{reportValues[index].Name.split('|')[1]}</Grid>} label="" />
                </FormGroup>
            </Grid>
            <Grid item xs={8}>
                <FormControl>
                    <Item>
                        <RadioGroup
                            name={`PSQRadio${reportValues[index].Name}`}
                            row
                            defaultValue="1"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                var value = event.target.value;
                                reportValues[index].Value = value;
                            }}>
                            <Grid container direction="row" gap={2}>
                                <FormControlLabel
                                    font-size="5px"
                                    value="1"
                                    name="almost"
                                    control={<Radio />}
                                    label="1"
                                />
                                <FormControlLabel
                                    font-size="5px"
                                    value="2"
                                    name="sometimes"
                                    control={<Radio />}
                                    label="2"
                                />
                                <FormControlLabel
                                    font-size="5px"
                                    value="3"
                                    name="often"
                                    control={<Radio />}
                                    label="3"
                                />
                                <FormControlLabel
                                    font-size="5px"
                                    value="4"
                                    name="usually"
                                    control={<Radio />}
                                    label="4"
                                />
                            </Grid>
                        </RadioGroup>
                    </Item>
                </FormControl>
            </Grid>
        </>);
    }

    const handleClick = () => {
        var gScoreIndex = reportValues.findIndex(r => { return (r.Name.includes('General')) && (r.Name.includes('Score')) });
        var rScoreIndex = reportValues.findIndex(r => { return (r.Name.includes('Recent')) && (r.Name.includes('Score')) });
        reportValues[gScoreIndex].Value = generalScore.toString();
        reportValues[rScoreIndex].Value = recentScore.toString();
        setDisabled(true);
        saveReportInfo(visitId, reportValues, 'perceivedstress').then((resp) => {
            if (resp === true) {
                setAlertTitle("Report saved succesfully.");
                setAlertMessage("Report saved succesfully.");
                setOpenAlertMessage(true);
            } else {
                setDisabled(false);
                setAlertTitle("Report not saved.");
                setAlertMessage("There was an error saving the report. Please try again.");
                setOpenAlertMessage(true);
            }
        });
    }

    return (
        <Grid >
            {hasLoaded ? <>
                <Grid container direction={"row"} className="formSectionLabel" sx={{ margin: "25px 25px" }}><Grid>The Perceived Stress Questionnaire</Grid>
                    <Grid sx={{ margin: "25px 25px", padding: "0px 35px" }} >
                        <Grid  >
                            <Grid xs={12} container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4}></Grid>
                                <Grid item xs={8}>
                                    <FormControlLabel id="restedLabel" className="sectionHdr" control={<Grid> Almost | </Grid>} label="" />
                                    <FormControlLabel id="restedLabel" className="sectionHdr" control={<Grid> Sometimes |</Grid>} label="" />
                                    <FormControlLabel id="restedLabel" className="sectionHdr" control={<Grid> Often |</Grid>} label="" />
                                    <FormControlLabel id="restedLabel" className="sectionHdr" control={<Grid> Usually</Grid>} label="" />
                                </Grid>
                                <Grid xs={12} container direction={"row"} justifyContent="flex-start">
                                    <FormGroup>
                                        <FormControlLabel className="formLabel" control={<Grid>General</Grid>} label="" />
                                    </FormGroup>
                                </Grid>
                                {reportValues.map((r, i) => { if (r.Name.includes('General') && !(r.Name.includes('Total Score'))) return questions(i); })}
                                <Grid item xs={4}></Grid>
                                <Grid item xs={2} className="formLabel" >General Total Score</Grid>
                                <CssTextField
                                    variant="outlined"
                                    className="TextBox"
                                    size="medium"
                                    type="number"
                                    disabled={false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        var value = parseFloat(event.target.value);
                                            setGeneralScore(value);
                                    }}
                                    value={generalScore}
                                />
                                <Grid xs={12} container direction={"row"} justifyContent="flex-start">
                                    <FormGroup>
                                        <FormControlLabel className="formLabel" control={<Grid>Recent</Grid>} label="" />
                                    </FormGroup>
                                </Grid>
                                {reportValues.map((r, i) => { if (r.Name.includes('Recent') && !(r.Name.includes('Total Score'))) return questions(i); })}
                                <Grid item xs={4}></Grid>
                                <Grid item xs={2} className="formLabel" >Recent Total Score</Grid>
                                <CssTextField
                                    variant="outlined"
                                    className="TextBox"
                                    size="medium"
                                    type="number"
                                    disabled={false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        var value = parseFloat(event.target.value);
                                            setRecentScore(value);
                                    }}
                                    value={recentScore}
                                />
                                <Grid>
                                    <Grid container direction={"row"} justifyContent={"flex-end"}><Button className="vivButton large invert" onClick={handleClick}>Save</Button></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> </> : <></>}
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    )
};

export default observer(PerceivedStress);
