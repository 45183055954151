import _, { forEach } from 'lodash';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../api/Gateway';
import React, { RefObject } from 'react';
import { MainStore } from './MainStore';
import { GlobalUtils } from '../api/GlobalUtils';
import DictionaryList from '../entities/DictionaryList';

export class CustomFormStore {
    @observable _questionnairesData: any[] = [];
    @observable _sectionsData: any[] = [];
    @observable _questionsData: any[] = [];
    @observable _sectionsDataQuestionnaire: any = [];
    @observable private _questionCategories: any[] = [];
    @observable private _categories: any[] = [];
    @observable private _detailedQuestionnaireData = {};
    @observable private _detailedSectionData = {};
    @observable private _detailedQuestion = {};
    @observable private _toggleShowAddQuestionnaireModal: boolean = false;
    @observable private _toggleShowAddSectionModal: boolean = false;
    @observable private _toggleShowAddQuestionModal: boolean = false;
    @observable private _toggleShowEditSectionModal: boolean = false;
    @observable private _toggleShowEditQuestionnaireModal: boolean = false;
    @observable private _toggleShowEditQuestionModal: boolean = false;
    @observable private _toggleShowCustomFormViewModal: boolean = false;
    @observable _isAdmin: boolean = false;
    @observable private _isLeftNavExpanded: boolean = false;
    @observable private _totalPages: number = 1;
    @observable private _isAlertDialog: boolean = false;
    @observable _toggleShowCancelWarningModal: boolean = false;
    @observable private _currentRow = {};
    @observable private _addQuestionnaireForm: RefObject<HTMLDivElement>;
    @observable private _addSectionForm: RefObject<HTMLDivElement>;
    @observable private _saveQuestionnaireBtn: RefObject<HTMLButtonElement>;
    @observable private _saveSectionBtn: RefObject<HTMLButtonElement>;
    @observable private _questionsSurvey = {};
    @observable private _mappingTables = [];
    userId: number = 0;
    pageSize: number = 25;
    @observable didInitQuestionnaires = false;
    @observable isLoadingQuestionnaires = false;
    @observable loadingQuestionnairesProgress = 0;
    @observable didInitSections = false;
    @observable isLoadingSections = false;
    @observable loadingSectionsProgress = 0;
    @observable loadSectionsData = 0;
    @observable didInitQuestions = false;
    @observable isLoadingQuestions = false;
    @observable isLoadingScreen = false;
    @observable loadingQuestionsProgress = 0;
    @observable private _alertMessage: string = '';
    @observable private _alertTitle: string = '';
    @observable private _viewQuestionaireId: string = '';
    @observable private _viewResponseQuestionaireId: string = '';
    @observable private _selectedCategories: number[] = []
    @observable private _selectedForms: string[] = []

    toggleShowCustomFormToProvider: boolean = false;

    @observable mainStore: MainStore;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        this._addQuestionnaireForm = React.createRef();
        this._addSectionForm = React.createRef();
        this._saveQuestionnaireBtn = React.createRef();
        this._saveSectionBtn = React.createRef();
        const token = sessionStorage.getItem('token');
        if (token) {
            const tokenData: { roles; id } = jwt_decode(token);
            forEach(JSON.parse(tokenData.roles), (role: any) => {
                if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                    this.isAdmin = true;
                }
            });

            this.userId = tokenData.id;
        }
    }


    @action
    resetQuestionData = () => {
        this._questionsData = [];
    };

    @action
    resetSectionData = () => {
        this._sectionsData = [];
    };

    @action
    resetQuestionnaireData = () => {
        this._questionnairesData = [];
    };

    @computed
    get detailQuestion() {
        return this._detailedQuestion;
    }

    set detailQuestion(value: any) {
        this._detailedQuestion = value;
    }

    @computed
    get detailedSectionData() {
        return this._detailedSectionData;
    }

    set detailedSectionData(value: any) {
        this._detailedSectionData = value;
    }

    @computed
    get questionsSurvey() {
        return this._questionsSurvey;
    }

    set questionsSurvey(value: any) {
        this._questionsSurvey = value;
    }

    @computed
    get questionsData(): any[] {
        return this._questionsData;
    }

    set questionsData(value: any[]) {
        this._questionsData = value;
    }

    @computed
    get questionCategories(): any[] {
        return this._questionCategories;
    }

    set questionCategories(value: any[]) {
        this._questionCategories = value;
    }

    @computed
    get categories(): any[] {
        return this._categories;
    }

    set categories(value: any[]) {
        this._categories = value;
    }

    @computed
    get sectionsData(): any[] {
        return this._sectionsData;
    }

    set sectionsData(value: any[]) {
        this._sectionsData = value;
    }

    @computed
    get questionnairesData(): any[] {
        return this._questionnairesData;
    }

    set questionnairesData(value: any[]) {
        this._questionnairesData = value;
    }

    @computed
    get toggleShowAddQuestionnaireModal() {
        return this._toggleShowAddQuestionnaireModal;
    }

    set toggleShowAddQuestionnaireModal(value: any) {
        this._toggleShowAddQuestionnaireModal = value;
    }

    @computed
    get toggleShowAddQuestionModal() {
        return this._toggleShowAddQuestionModal;
    }

    set toggleShowAddQuestionModal(value: any) {
        this._toggleShowAddQuestionModal = value;
    }

    @computed
    get toggleShowAddSectionModal() {
        return this._toggleShowAddSectionModal;
    }

    set toggleShowAddSectionModal(value: any) {
        this._toggleShowAddSectionModal = value;
    }

    @computed
    get toggleShowEditSectionModal() {
        return this._toggleShowEditSectionModal;
    }

    set toggleShowEditSectionModal(value: any) {
        this._toggleShowEditSectionModal = value;
    }

    @computed
    get isLeftNavExpanded() {
        return this._isLeftNavExpanded;
    }

    set isLeftNavExpanded(value: any) {
        this._isLeftNavExpanded = value;
    }

    @computed
    get addQuestionnaireForm() {
        return this._addQuestionnaireForm;
    }

    set addQuestionnaireForm(value: any) {
        this._addQuestionnaireForm = value;
    }

    @computed
    get addSectionForm() {
        return this._addSectionForm;
    }

    set addSectionForm(value: any) {
        this._addSectionForm = value;
    }

    @computed
    get saveQuestionnaireBtn() {
        return this._saveQuestionnaireBtn;
    }

    set saveQuestionnaireBtn(value: any) {
        this._saveQuestionnaireBtn = value;
    }

    @computed
    get saveSectionBtn() {
        return this._saveSectionBtn;
    }

    set saveSectionBtn(value: any) {
        this._saveSectionBtn = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: any) {
        this._totalPages = value;
    }

    @computed
    get isAlertDialog() {
        return this._isAlertDialog;
    }

    set isAlertDialog(value: any) {
        this._isAlertDialog = value;
    }

    @computed
    get alertTitle() {
        return this._alertTitle;
    }

    set alertTitle(value: any) {
        this._alertTitle = value;
    }

    @computed
    get alertMessage() {
        return this._alertMessage;
    }

    set alertMessage(value: any) {
        this._alertMessage = value;
    }

    @computed
    get selectedCategories() {
        return this._selectedCategories;
    }

    set selectedCategories(value: number[]) {
        this._selectedCategories = value;
    }

    @computed
    get selectedForms() {
        return this._selectedForms;
    }

    set selectedForms(value: string[]) {
        this._selectedForms = value;
    }

    get currentRow() {
        return this._currentRow;
    }

    @action
    set currentRow(value: any) {
        this._currentRow = value;
    }

    @action
    private setAlertDialog(value: any) {
        this.isAlertDialog = value;
    }

    get isAdmin() {
        return this._isAdmin;
    }

    @action
    set isAdmin(value: any) {
        this._isAdmin = value;
    }

    get sectionsDataQuestionnaire() {
        return this._sectionsDataQuestionnaire;
    }

    @action
    set sectionsDataQuestionnaire(value: any[]) {
        this._sectionsDataQuestionnaire = value;
    }

    get detailedQuestionnaireData() {
        return this._detailedQuestionnaireData;
    }

    @action
    set detailedQuestionnaireData(value: any) {
        this._detailedQuestionnaireData = value;
    }

    get toggleShowEditQuestionnaireModal() {
        return this._toggleShowEditQuestionnaireModal;
    }

    @action
    set toggleShowEditQuestionnaireModal(value: any) {
        this._toggleShowEditQuestionnaireModal = value;
    }

    get toggleShowEditQuestionModal() {
        return this._toggleShowEditQuestionModal;
    }

    @action
    set toggleShowEditQuestionModal(value: any) {
        this._toggleShowEditQuestionModal = value;
    }

    get mappingTables() {
        return this._mappingTables;
    }

    @action
    set mappingTables(value: any) {
        this._mappingTables = value;
    }

    @computed
    get toggleShowCustomFormViewModal() {
        return this._toggleShowCustomFormViewModal;
    }

    set toggleShowCustomFormViewModal(value: any) {
        this._toggleShowCustomFormViewModal = value;
    }

    @action
    loadMappingTables = () => {
        GlobalUtils.getMappingTables().then(res => { this.mappingTables = res });
    }

    @computed
    get viewQuestionaireId() {
        return this._viewQuestionaireId;
    }

    set viewQuestionaireId(value: any) {
        this._viewQuestionaireId = value;
    }

    @computed
    get viewResponseQuestionaireId() {
        return this._viewResponseQuestionaireId;
    }

    set viewResponseQuestionaireId(value: any) {
        this._viewResponseQuestionaireId = value;
    }

    @action
    toggleCustomFormModal = () => {
        runInAction(() => {
            this._toggleShowCustomFormViewModal = !this._toggleShowCustomFormViewModal;
        });
    };

    @action
    loadDetailedQuestionnaireData = async sectionId => {
        runInAction(() => {
            this.isLoadingQuestions = true;
        });
        const url = `questionnaire/${sectionId}`;
        this.detailedQuestionnaireData = await Gateway.get(url);

        runInAction(() => {
            this.isLoadingQuestions = false;
        });
        return this.detailedQuestionnaireData;
    };

    @action
    loadResponseQuestionaireData = async idResponse => {
        const url = `Questionnaire/ResponseInstanceResponses/${idResponse}`;
        let data = await Gateway.get(url);

        return data;
    };

    @action
    loadResponsesQuestionaireData = async idPatient => {
        const url = `Questionnaire//ResponseInstances/${idPatient}`;
        let data = await Gateway.get(url);

        return data;
    };

    @action
    loadDetailQuestion = async questionId => {
        const url = `questionnaire/questions/${questionId}`;
        this.detailQuestion = await Gateway.get(url);

        return this.detailQuestion;
    };

    @action
    setIsloadindQuestion = (value: boolean) => {
        this.isLoadingQuestions = value;
    };

    @action
    setIsloadindSection = (value: boolean) => {
        this.isLoadingSections = value;
    };

    @action
    setIsloadindQuestionnaire = (value: boolean) => {
        this.isLoadingQuestionnaires = value;
    };

    @action
    closeAlertDialog = () => {
        runInAction(() => {
            this.isAlertDialog = !this.isAlertDialog;
            if (this.alertTitle === 'Success') {
                window.location.reload();
            }
        });
    };

    @action
    closeModalCustomFormToProvider = () => {
        this.toggleShowCustomFormToProvider = false;
    };

    @action
    showModalCustomFormToProvider = () => {
        this.toggleShowCustomFormToProvider = true;
    };

    @computed
    get toggleShowCancelWarningModal() {
        return this._toggleShowCancelWarningModal;
    }

    set toggleShowCancelWarningModal(value: any) {
        this._toggleShowCancelWarningModal = value;
    }

    @action
    handleCancelWarningModal = () => {
        runInAction(() => {
            this.toggleShowCancelWarningModal = !this.toggleShowCancelWarningModal;
        });
    };

    @action
    handleCancelClose = (isClosed: any) => {
        this.detailedSectionData = '';
        this.detailQuestion = {};
        this.handleCancelWarningModal();
        if (isClosed) {
            this.toggleShowAddQuestionnaireModal = false;
            this.toggleShowAddQuestionModal = false;
            this.toggleShowAddSectionModal = false;
            this.toggleShowEditSectionModal = false;
            this.toggleShowEditQuestionnaireModal = false;
            this.toggleShowEditQuestionModal = false;
        }
    };

    @action
    setCurrentRow = (newRow: any) => {
        this.currentRow = newRow;
    };

    @action
    loadSectionData = async () => {
        runInAction(() => {
            this.isLoadingSections = true;
        });

        const url = 'questionnaire/section/get/' + this.userId + '/' + 0 + '/' + this.pageSize;
        const sections: any[] = [];
        const resp = await Gateway.get(url);
        const totalSections = resp ? resp[0].maxRows : 0;
        sections.push(...resp);
        if (totalSections !== 0) {
            runInAction(() => {
                this.loadingSectionsProgress = (sections.length / totalSections) * 100;
            });
        }

        const promises: Promise<any[]>[] = [];
        let currentPage = 1;
        const pages = Math.trunc(totalSections / this.pageSize);
        while (currentPage <= pages) {
            promises.push(
                Gateway.get(`questionnaire/section/get/${this.userId}/${currentPage}/${this.pageSize}`).then(
                    response => {
                        sections.push(...response);
                        runInAction(() => {
                            this.loadingSectionsProgress = (sections.length / totalSections) * 100;
                        });
                        return response;
                    },
                ),
            );
            currentPage++;
        }
        await Promise.all(promises);

        runInAction(() => {
            this.isLoadingSections = false;
            this.sectionsData = sections.sort((a, b) => {
                return a.sectionTitle.localeCompare(b.sectionTitle);
            });
        });
        return this.sectionsData;
    };

    @action
    loadDetailedSectionData = async sectionId => {
        const url = 'questionnaire/section/' + sectionId;
        this.detailedSectionData = await Gateway.get(url);
        return this.detailedSectionData;
    };

    @action
    loadQuestionCategories = async () => {
        var categories: DictionaryList[];
        this.categories = [];
        GlobalUtils.getDictionaryLists().then(resp => {
            var data = resp as unknown as DictionaryList[];
            categories = data.filter(x => x.listKey.includes('CATEGORY'));
            categories.forEach(category => {
                this.categories.push({
                    id: category.id,
                    value: category.name
                });
                category.dictionaryListItems.forEach(subcategory => {
                    this.questionCategories.push({
                        id: subcategory.id,
                        value: category.name + ' - ' + subcategory.name,
                    });
                });
            });
        });
    };

    @action
    loadQuestionnaireData = async () => {
        runInAction(() => {
            this.isLoadingQuestionnaires = true;
        });

        const url = 'questionnaire/get/' + this.userId + '/' + 0 + '/' + this.pageSize;
        const questionnaires: any[] = [];
        await Gateway.get(url).then(async resp => {
            const totalQuestionnaires = resp ? resp[0].maxRows : 0;
            questionnaires.push(...resp);
            if (totalQuestionnaires !== 0) {
                runInAction(() => {
                    this.loadingQuestionnairesProgress = (questionnaires.length / totalQuestionnaires) * 100;
                });
            }

            const promises: Promise<any[]>[] = [];
            let currentPage = 1;
            const pages = Math.trunc(totalQuestionnaires / this.pageSize);
            while (currentPage <= pages) {
                promises.push(
                    Gateway.get(`questionnaire/get/${this.userId}/${currentPage}/${this.pageSize}`).then(response => {
                        questionnaires.push(...response);
                        runInAction(() => {
                            this.loadingQuestionnairesProgress = (questionnaires.length / totalQuestionnaires) * 100;
                        });
                        return response;
                    }),
                );
                currentPage++;
            }
            await Promise.all(promises);

            runInAction(() => {
                this.isLoadingQuestionnaires = false;
                this.questionnairesData = questionnaires.sort((a, b) => {
                    return a.questionnaireName.localeCompare(b.questionnaireName);
                });
            });
            return this.questionnairesData;
        });
    };

    @action
    loadQuestionData = async () => {
        runInAction(() => {
            this.isLoadingQuestions = true;
        });

        const url = 'questionnaire/questions/users/' + this.userId + '/' + 0 + '/' + this.pageSize;
        const questions: any[] = [];
        const categories = this.questionCategories;
        const resp = await Gateway.get(url);
        const totalQuestions = resp[0].recordCount;
        questions.push(...resp);
        if (totalQuestions !== 0) {
            runInAction(() => {
                this.loadingQuestionsProgress = (questions.length / totalQuestions) * 100;
            });
        }

        const promises: Promise<any[]>[] = [];
        let currentPage = 1;
        const pages = Math.ceil(totalQuestions / this.pageSize);
        while (currentPage <= pages) {
            promises.push(
                Gateway.get(`questionnaire/questions/users/${this.userId}/${currentPage}/${this.pageSize}`).then(
                    response => {
                        questions.push(...response);
                        runInAction(() => {
                            this.loadingQuestionsProgress = (questions.length / totalQuestions) * 100;
                        });
                        return response;
                    },
                ),
            );
            currentPage++;
        }
        await Promise.all(promises);

        runInAction(() => {
            this.isLoadingQuestions = false;

            questions.forEach(question => {
                const category = categories.find(x => x.id === question.categoryId);
                question.displayTypeGrid = `${question.displayType ? question.displayType : ''} ${question.inputType && question.displayType ? '-' : ''
                    } ${question.inputType ? question.inputType : ''} `;

                return (question.category = category ? category.value : '');
            });

            questions.sort((a, b) => {
                return a.question.localeCompare(b.question);
            });

            this.questionsData = questions;
        });
        return this.questionsData;
    };

    @action
    loadQuestionnaireCustomForm = async () => {
        runInAction(() => {
            this.isLoadingQuestions = true;
        });
        const newUrl = 'questionnaire/sections/json';

        const sectionData: any[] = [];

        await Gateway.get(newUrl).then(resp => {

            sectionData.push(resp);
        });

        runInAction(() => {
            this.sectionsDataQuestionnaire = sectionData;
            this.isLoadingQuestions = false;
        });

        return this.sectionsDataQuestionnaire;
    };

    @action
    loadQuestionnaireCustomFormSingle = async (sectionid: number) => {
        const newUrl = `questionnaire/section/${sectionid}`;

        const sectionData: any[] = [];

        await Gateway.get(newUrl).then(resp => {
            sectionData.push(resp);
        });


        return sectionData;
    };

    @action
    loadMapping = (table: string): any[] => {
        var promises: any[] = [];
        var choices: any[] = [];
        var url = `form/properties/${table}`;
        promises.push(Gateway.get(url).then((resp) => {
            _.forEach(resp, (item) => {
                choices.push(item);
            });
        }));
        Promise.all(promises).then(() => {
            return choices;
        });
        return [];
    }

    @action
    toggleAddQuestionnaireModal = () => {
        this.detailedQuestionnaireData = {};
        runInAction(() => {
            this.toggleShowAddQuestionnaireModal = !this.toggleShowAddQuestionnaireModal;
        });
    };
    @action
    toggleEditQuestionnaireModal = () => {
        runInAction(() => {
            this.toggleShowEditQuestionnaireModal = !this.toggleShowEditQuestionnaireModal;
        });
    };

    @action
    toggleAddSectionModal = () => {
        runInAction(() => {
            this.toggleShowAddSectionModal = !this.toggleShowAddSectionModal;
        });
    };

    @action
    toggleAddQuestionModal = () => {
        runInAction(() => {
            this.toggleShowAddQuestionModal = !this.toggleShowAddQuestionModal;
        });
    };

    @action
    toggleEditQuestionModal = () => {
        runInAction(() => {
            this.toggleShowEditQuestionModal = !this.toggleShowEditQuestionModal;
        });
    };

    @action
    toggleEditSectionModal = () => {
        runInAction(() => {
            this.toggleShowEditSectionModal = !this.toggleShowEditSectionModal;
        });
    };

    @action
    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this._isLeftNavExpanded = isLeftNavExpanded;
    };

    @action
    saveQuestionnaire = async customForm => {
        runInAction(() => {
            this.setIsloadindQuestionnaire(true);
        });

        const newQuestionnarieDto = {
            description: customForm.survey.description,
            name: customForm.survey.title,
            pages: customForm.JSON.pages,
            categoryId: this.categories.find(x => x.value === customForm.JSON.category)?.id,
            title: customForm.survey.title,
            id: this.detailedQuestionnaireData.id,
            userId: this.userId,
        };
        let url = `questionnaire/${this.userId}`;
        if (this.detailedQuestionnaireData && this.detailedQuestionnaireData.id > 0) {
            await Gateway.put(url, newQuestionnarieDto)
                .then(resp => {
                    runInAction(() => {
                        this.setIsloadindQuestionnaire(false);
                        this.resetQuestionnaireData();
                        this.loadQuestionnaireData();
                    });
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            await Gateway.post(url, newQuestionnarieDto)
                .then(resp => {
                    runInAction(() => {
                        this.setIsloadindQuestionnaire(false);
                        this.resetQuestionnaireData();
                        this.loadQuestionnaireData();
                    });
                })
                .catch(err => {
                    console.log(err);
                });

        }


    };

    @action
    saveSection = async section => {
        var elements = section.JSON.elements;
        for (var i = 0; i < elements.length; i++) {
            if (isNaN(elements[i].id)) {
                elements[i].id = null;
            }
        }

        var newSectionDto = {
            name: section.currentPage.title,
            description: section.currentPage.description,
            categoryId: this.categories.find(x => x.value === section.JSON.category)?.id,
            elements: elements,
        };

        const url = `/questionnaire/${this.userId}/section`;
        await Gateway.post(url, newSectionDto)
            .then(resp => {
                runInAction(() => {
                    this.setIsloadindSection(false);
                    this.resetSectionData();
                    this.loadSectionData();
                    this.loadQuestionnaireCustomForm();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    @action
    editSection = async section => {
        var elements = section.JSON.elements;
        for (var i = 0; i < elements.length; i++) {
            if (isNaN(elements[i].id)) {
                elements[i].id = null;
            }
        }

        var editSectionDto = {
            id: section.currentPage.id,
            name: section.currentPage.title,
            description: section.currentPage.description,
            categoryId: this.categories.find(x => x.value === section.JSON.category)?.id,
            elements: elements,
        };

        const url = `/questionnaire/${this.userId}/section`;

        await Gateway.put(url, editSectionDto)
            .then(resp => {
                runInAction(() => {
                    this.setIsloadindSection(false);
                    this.resetSectionData();
                    this.loadSectionData();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    @action
    deleteSection = (section: any) => {
        const url = `/questionnaire/section/${section.sectionId}`;
        let countRowsActiveToFalse = 0;
        const confirmDeletion = window.confirm(
            `You are going to delete "${section.sectionTitle}" section. Are you sure?`,
        );
        if (confirmDeletion) {
            Gateway.delete(url, {})
                .then(resp => {
                    alert('Section deleted successfully.');
                    runInAction(() => {
                        this.setIsloadindSection(false);
                        this.resetSectionData();
                        this.loadSectionData();
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    @action
    saveQuestion = async value => {
        let newQuestionDto = value;

        for (let i = 0; i < newQuestionDto.length; i++) {
            if (isNaN(newQuestionDto[i].id)) {
                newQuestionDto[i].id = null;
            }
        }
        const url = `/questionnaire/${this.userId}/questions`;
        await Gateway.post(url, newQuestionDto)
            .then(resp => {
                runInAction(() => {
                    this.setIsloadindQuestion(true);
                    this.resetQuestionData();
                    this.loadQuestionData();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    @action
    updateQuestion = async value => {
        let newQuestionDto = value;

        const url = `/questionnaire/${this.userId}/questions`;
        await Gateway.put(url, newQuestionDto)
            .then(resp => {
                runInAction(() => {
                    this.setIsloadindQuestion(true);
                    this.resetQuestionData();
                    this.loadQuestionData();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    @action
    deleteQuestion = (question: any) => {
        const url = `/questionnaire/questions/${question.questionId}`;

        let confirmDeletion = window.confirm(`You are going to delete "${question.question}" question. Are you sure?`);

        if (confirmDeletion) {
            Gateway.delete(url, {})
                .then(resp => {
                    alert(`Question ${question.question} deleted successfully.`);
                    this.setIsloadindQuestion(true);
                    this.resetQuestionData();
                    this.loadQuestionData();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    @action checkCategorySendCustomForm = (id) => {
        this.selectedCategories.includes(id) ? this.selectedCategories.splice(this.selectedCategories.indexOf(id), 1) : this.selectedCategories.push(id);
    }

    @action checkFormsSendCustomForm = (id) => {
        this.selectedForms.includes(id) ? this.selectedForms.splice(this.selectedForms.indexOf(id), 1) : this.selectedForms.push(id);
    }

    @action clearSelected = () => {
        this.selectedCategories = [];
        this.selectedForms = [];
    }

}
