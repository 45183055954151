// Vendor
import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Typography,
    IconButton,
    Link
} from '@mui/material';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

// Components
import { AiReportModal } from '../../AiReportOrder';
import DataGrid from "../../DataGrid/DataGrid";

// Entities
import ProviderPatient from '../../../entities/ProviderPatient';

// Store
import { PatientStore } from '../../../api/PatientStore';
import PatientEditBL from '../PatientEditBL';

// API
import { observer } from 'mobx-react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import moment from "moment";
import { report } from 'process';
import reportWebVitals from '../../../reportWebVitals';

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
}

const NoDocument = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item className="noDocument">
                Ai Report has not yet been generated
            </Grid>
        </Grid>
    );
};

const MyLoadingRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || '';

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item>Loading AiReport...</Grid>
        </Grid>
    );
};

export const PatientAIReports = observer(({ patient, bl }: IGIProps) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [reports, setReports] = useState<any>([]);
    const [store] = useState(new PatientStore());
    const [openAIReportModal, setOpenAIReportModal] = useState(false);

    useEffect(() => {
        bl.loadAllReports().then(resp => {
            setReports(resp);
            setHasLoaded(true);
        });
    }, [])

    const handleonClick = () => {
        store.toggleAiReportOrderModal();
    }

    const handleChange = (current: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? current : false);
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Report ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button variant="text" onClick={() => {

                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'aiReportType',
            flex: 1,
            headerName: 'Report Type',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        <Typography variant='h5'>
                            Standard
                        </Typography>
                    </Grid>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                // var status = patientVisitStatus.find(x => x.id === params.row.statusId);
                return (
                    <Grid>
                        <Typography variant='subtitle1'>
                            In Progress
                        </Typography>
                    </Grid>
                )
            }

        },
        {
            field: 'requestedDate',
            headerName: 'Requested Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button variant="text" onClick={() => {
                        }}>{moment(params.row.createdOn).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Completed Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button variant="text" onClick={() => {
                            //  setViewOnly(true);
                            //  setNoteDetails(params.row);
                            // setIsOpen(true);
                        }}>{moment(params.row.createdOn).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'radiologyReport',
            headerName: 'Radiology Report',
            flex: 1.0,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                <Grid>
                    <CheckBoxIcon className="lightgreen" />
                </Grid>
            }
        },
        {
            field: 'AiGenerated',
            headerName: 'Generated AI Report',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Grid>
                        <IconButton >
                            <GridActionsCellItem
                                icon={
                                    <DownloadTwoToneIcon />
                                }
                                label="Ai Report Order"
                            />
                        </IconButton>
                        /&nbsp;
                        <IconButton>
                            <Link
                                href={params.row.aiReport}
                            >
                                <LinkTwoToneIcon />
                            </Link>
                        </IconButton></Grid>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                {
                    <Grid>
                        <CheckBoxIcon className="green" />
                    </Grid>
                }
            }
        },

    ];

    const accordionReports = (index) => {
        var uri = decodeURI(
            `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${patient.vivosId}/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${reports[index].submissionId}/${reports[index].link}`,
        );

        return (
            <>
                <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid xs={12} container direction={"row"} className="headerLine ">
                            <Grid item xs={8} className="label">
                                <Grid>{reports[index].link}</Grid>
                            </Grid>
                            <Grid item xs={4} className="label">
                                <Grid>{moment(reports[index].createdOn).format("MM/DD/yyyy hh:mm:ss")}</Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Grid className="viewBody" xs={12} container>
                            {patient.aiReport ? (
                                <DocViewer
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        loadingRenderer: {
                                            overrideComponent: MyLoadingRenderer,
                                        },
                                        noRenderer: {
                                            overrideComponent: MyLoadingRenderer,
                                        },
                                    }}
                                    documents={[
                                        {
                                            uri: uri,
                                        },
                                    ]}
                                />
                            ) : (
                                <NoDocument />
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <AiReportModal
                    isOpen={store.toggleShowAiReportOrderModal}
                    patient={patient}
                    submission={reports[0].submission}
                    onClose={store.toggleAiReportOrderModal.bind(this)}
                />
            </>
        )
    }

    return (
        <Grid container>
            <Grid xs={12} padding={1}>
                <Button
                    variant="contained"
                    disabled={!hasLoaded}
                    onClick={() => {
                        setOpenAIReportModal(true);
                    }}
                >
                    Request New AI Report
                </Button>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    columns={columns}
                    data={reports}
                    loading={!hasLoaded}

                    size={"small"}
                    pageSize={10}
                />
            </Grid>
            <AiReportModal
                isOpen={openAIReportModal}
                patient={patient}
                // submission={reports[0].submission}
                // onClose={store.toggleAiReportOrderModal.bind(this)}
                onClose={() => {
                    setOpenAIReportModal(false);
                    // setHasLoaded(false);
                    // loadVisits().then(() => {
                    //     setHasLoaded(true);
                    // });
                }}
            />
            <Grid container xs={12} style={{ maxHeight: "calc(100vh - 600px)", minHeight: "100px", overflowY: "auto", overflowX: "hidden" }}>
                {hasLoaded ? (reports.length > 0 ? reports.map((r, i) => { return accordionReports(i); }) :
                    <NoDocument />) : <></>}
            </Grid>
        </Grid>


    );
});
