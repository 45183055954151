import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
    Box,
    Divider,
    styled
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define a constant list of question-answer pairs
const faqList = [
    {
        question: "What type of Appliances does Vivos offer?",
        answer: `Vivos offers a variety of appliances including corrective airway
        reposition and expansion (CARE) devices, guided growth and
        development, sleep/MAD related appliances, and TMJ/migraine/nasal
        dilation appliances. For a more comprehensive look into all the appliances
        Vivos offers`
    },
    {
        question: "What are the recommended wear time and expansion protocols for CARE appliances (DNA, mRNA, mmRNA)?",
        answer: `CARE device protocols vary according to each patient, their needs,
                 and their unique craniofacial complex. The average recommended wear
                 time with CARE appliances ranges from 10-12 hours a day. Turning ratios for expansion are
                 completely dependent on wear time. If you are unsure what protocols
                 are right for your patient, please schedule a consultation with us.`
    },
    {
        question: "When should I use a VG vs VW Guide appliance?",
        answer: `Generally, the Vivos “VG” series is used for ages 6-12 years of age,
                 once 6-year molars are fully erupted, and after centrals and
                 laterals erupt. The Vivos “VW” series focuses on children 12 years
                 and older, into adulthood. These are worn after 12-year molars erupt. For more
                 information, please schedule a consultation with us.`
    },
    {
        question: "What is the warranty/repair process for Vivos Products?",
        answer: `Providers can submit warranty/repair claims on eligible Vivos
                 products by filling out the following <a
                 href="https://forms.office.com/pages/responsepage.aspx?id=cqIr
                 tcRXDU6CGGl_Iu5AdBFbeOer2g5BgAzHELIdqmdUMTdTODNLVTkyQkdJWVZYQklCVFpQTjhTSi4u" target="_blank"
                 class="text-primary"><u>digital form</u></a>.
                 This digital form will include information about warranty time
                 frames for each Vivos appliance. For more information about the warranty/repair process <a
                 href="https://vivoscdns3.s3.amazonaws.com/vivos/files/cclibraryfiles/filename/000000000314/Repairs%20and%20Warranty%20Submission%20Process.pdf"
                 target="_blank" class="text-primary"><u>click HERE</u></a> or
                 contact rx@vivoslife.com.`
    },
    {
        question: "How do I schedule a Clinical Consultation?",
        answer: `<a href="https://vivoscdns3.s3.amazonaws.com/vivos/files/cclibraryfiles/filename/000000000309/How%20to%20Schedule%20a%20Clinical%20Consultation.mp4"
                 target="_blank" class="text-primary"><u>Click HERE</u></a> to
                 learn how to schedule a clinical consultation. Please verify that all
                 requirements are met before scheduling consultations.`
    },
    {
        question: "How do I schedule an AIS (Appliance Design) Consultation?",
        answer: `<a href="https://vivoscdns3.s3.amazonaws.com/vivos/files/cclibraryfiles/filename/000000000308/How%20to%20Schedule%20an%20Appliance%20Design%20Consultation.mp4"
                 target="_blank" class="text-primary"><u>Click HERE</u></a>
                 to learn how to schedule an AIS (Appliance Design) appointment.
                 Please verify that all requirements are met before scheduling consultations.`
    },
    {
        question: "How do I measure a patient for the Vivos VG and VW appliances?",
        answer: `Begin by measuring the mesial to distal widths of the front four
                 upper incisors in millimeters. Combine these 4 individual widths to determine a total
                 sum. Using this sum, follow the measurement guide attached <a
                 href="https://vivoscdns3.s3.amazonaws.com/vivos/files/cclibraryfiles/filename/000000000316/Measurement%20Guide%20for%20Guides.png"
                 target="_blank" class="text-primary"><u>HERE</u></a> to
                 determine which size is best for your patient. (If your patient is
                 in between sizes, we recommend sizing up).`
    },
    {
        question: "How do I export my digital models and bite registration?",
        answer: `The process of exporting digital models and bite registrations
                 varies depending on the scanner type.
                 <div class="btn text-primary" data-toggle="modal"
                 data-target="#modalSTLMachine2"><u>Click HERE </u></div>
                 to select your scanner type for specific instructions.`
    },
    {
        question: "What is MyoCorrect, and how do I enroll my patients?",
        answer: `MyoCorrect is a service Vivos offers to incorporate Myofunctional
                 therapy into a patient’s treatment plan through trained Myofunctional therapists and
                 advocates.
                 <a href="https://vivoscdns3.s3.amazonaws.com/vivos/files/cclibraryfiles/filename/000000000287/How%20To%20Enroll%20Patients%20in%20Myofunctional%20Therapy.pdf"
                 target="_blank" class="text-primary"><u>Click HERE</u></a>
                 to learn more about this service and its benefits, and how to enroll patients.`
    },
    {
        question: "What is a construction bite and how do I take one?",
        answer: `<a href="https://vivoscdns3.s3.amazonaws.com/vivos/files/cclibraryfiles/filename/000000000299/Records%20101%20-%20Construction%20bites.mp4"
                 target="_blank" class="text-primary"><u>Click HERE</u></a> to
                 watch a video on what a construction bite is and how to properly take one.`
    }
];

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);

function TopFaqContent() {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
            {faqList.map((faq, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                    >
                        <Box borderBottom={.5} width={'100%'} borderColor={'grey'}>
                            <Typography variant='h4'>{faq.question}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BoxWrapper>
                            {/* <Box > */}
                            <Typography variant='body1' textAlign={'center'}>{faq.answer}</Typography>
                            {/* </Box>     */}
                        </BoxWrapper>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Card>
    );
}

export default TopFaqContent;
