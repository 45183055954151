import { MainStore } from '../../stores/MainStore';
import { action, makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';

export class CommentStore {
    mainStore: MainStore;

    constructor(mainStore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    userId = 0;
    roles: any[] = [];
    significantId = 0;
    permissions = "";
    commentText = "";

    @action setSignificantId = (significantId: any) => {
        this.significantId = significantId;
    };

    @action setPermissions = (permissions: any) => {
        this.permissions = permissions;
    };

    @action setCommentText = (commentText: any) => {
        this.commentText = commentText;
    };

    @action setCommentData = (
        significantId: number,
        permissions: string,
        commentText: string,
    ) => {
        this.setSignificantId(significantId);
        this.setPermissions(permissions);
        this.setCommentText(commentText);
    };

    @action resetCommentData = () => {
        this.significantId = 0;
        this.permissions = "";
        this.commentText = "";
    };

    @action saveCommentData = async(): Promise<any> => {
        let body = {
            significantId: this.significantId,
            permissions: this.permissions,
            commentText: this.commentText,
            commentType: 41,
            createdBy: this.userId,
            createdOn: new Date(),
            isActive: true,
        };

        await Gateway.post('/comments', body);
    };

    @action getCommentsBySignificant = (significantId: number, userId: number): Promise<any> => {
        return Gateway.get('/comments/significant/' + significantId + '?userId=' + userId);
    };
}
