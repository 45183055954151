import jwt_decode from "jwt-decode";

export function GetAuthToken({ children }) {

    var token = sessionStorage.getItem('token');
    if (token) {
        const decode = jwt_decode(token)
        return children(decode)
    }
    return <div />
}
