import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import { observable } from "mobx";
import * as React from 'react';
import { observer } from "mobx-react";
import { GlobalUtils } from "../api/GlobalUtils";
import CssTextField from "./CssTextField";
import Item from "./Item";

interface IModalProps {
    title: string;
    progress: number;
    isOpen: boolean;
    onClose: (onClose: boolean) => void;
}

export const ProgressModal: React.FC<IModalProps> = observer(
    ({ isOpen, onClose, title, progress }) => {
        const handleOnClick = () => {

            onClose(false);
        };
        const [theProgress, setTheProgress] = React.useState(0);
        React.useEffect(() => {
            setTheProgress(progress)
        }, [progress]);

        return (
            <Dialog open={isOpen}>
                <DialogTitle id='form-dialog-title' className='Title'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            sx={{
                                height: '30px'
                            }}
                            flexGrow={1}
                            fontSize='24px'
                            fontWeight={900}
                            fontFamily='Proxima Nova'
                        >
                            {title}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={handleOnClick}
                                sx={{
                                    fontSize: 25,
                                    backgroundColor: 'transparent',
                                    color: 'blue'
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid className={"formBasics"} sx={{ margin: "25px" }}>
                            <Grid xs={12} className="center" container direction="column"  >
                                <Grid xs item>
                                    <CircularProgress variant="indeterminate" color="primary" value={Math.round(progress)} sx={{
                                        width: "90%",
                                        height: "90%"
                                    }} />
                                </Grid>
                                <Grid xs item>
                                    <Typography
                                        component="div"
                                        color="text.secondary"
                                        sx={{ textJustify: "center", fontSize: "20px" }}
                                    >{`${Math.round(progress)}%`}</Typography>
                                </Grid>
                            </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
);