import { Button, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react";
import { FC } from "react";
import { useMainStoreContext } from "../../stores/MainStore";
import React from "react";

interface IGIProps {
}

export const ApprovalCode: FC<IGIProps> = observer(() => {
    
    const store = useMainStoreContext().patientLincareStore;

    const handleOnSubmit = () => {
        let inTreatment = store.lincareStatusValues.find(x => x.listItemKey === 'INTREATMENT');
        store.handleLincareStatusChange(inTreatment?.id);
        store._toggleShowApprovalCode = false;
    };

  return (
    <Grid xs={12} container direction={'row'} padding={'30px 30px 30px 30px'}>
    <Grid className="formLabel" fontWeight={'bold'}>
        <Grid paddingBottom={'10px'}>Approval Code:</Grid>
        <TextField
                variant="outlined"
                size="small"
                placeholder=""
                required={true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    store.setPatientAppovalCode(event.target.value);
                }}
                value={store.patientApprovalCode}
            /> 
    </Grid>
        <Grid container direction={'row'} justifyContent={'flex-end'}>
            <Button className="vivButton large invert" onClick={() => {handleOnSubmit()}} disabled={!(store.patientApprovalCode.length > 0)}>
                Save
            </Button>
        </Grid>
    </Grid>
  )
})