import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { Fragment, useState } from "react";
import { LabCaseReview } from "../components/LabCaseReview/LabCaseReview";
import { DashboardInfoBox } from "../components/DashboardInfoBox/DashboardInfoBox";
import DataGrid from "../components/DataGrid/DataGrid";
import { LeftNav } from "../components/LeftNav/LeftNav";
import ModalContainer from "../components/ModalContainer";
import { MyoCorrectGridHeader } from "../components/MyoCorrectGridHeader";
import { useMainStoreContext } from "../stores/MainStore";

export const LaboratoryComponent = () => {
    const {
        toggleLeftNav,
        getSearchData,
        clearData,
        toggleFilterResultsExpand,
        isLeftNavExpanded,
        pageTitle,
        userId
    } = useMainStoreContext().laboratoryStore;

    const {
        triggerShowClaimModal,
        setTriggerShowClaimModal
    } = useMainStoreContext().labCaseReviewStore;

    const numResults = 0;
    const hdrButtons: any = [];
    const infoBoxes: any = [];
    const [selectedRow, setSelectedRow] = useState<any>({});

    hdrButtons.push(
        <Button
            id={'myocorrecttherapist.header.btn.filterPatients.' + userId}
            className="vivButton large"
            onClick={toggleFilterResultsExpand}
        >
            FILTER RESULTS
        </Button>,
    );
    
    infoBoxes.push(
        <Grid key={'dib0'}>
            <DashboardInfoBox
                title={'Cases on QC'} 
                value={12} 
                onFilter={undefined} 
                filter={0} 
                currentFilter={0}/>
        </Grid>,
    );
    infoBoxes.push(
        <Grid key={'dib1'}>
            <DashboardInfoBox
                title={'Bites On Hold'} 
                value={4} 
                onFilter={undefined} 
                filter={0} 
                currentFilter={0}/>
        </Grid>,
    );
    infoBoxes.push(
        <Grid key={'dib2'}>
            <DashboardInfoBox
                title={'Models On Hold'} 
                value={10} 
                onFilter={undefined} 
                filter={0} 
                currentFilter={0}/>
        </Grid>,
    );
    infoBoxes.push(
        <Grid key={'dib3'}>
            <DashboardInfoBox
                title={'Cases over 7 days in Queue'} 
                value={1} 
                onFilter={undefined} 
                filter={0} 
                currentFilter={0}/>
        </Grid>,
    );
    infoBoxes.push(
        <Grid key={'dib4'} xs={2} container direction="column" className="buttons">
            <Button className="vivButton large invert">
                Run Report 
            </Button>
        </Grid>,
    );
    const columns = [
        {
            field: 'dateSubmitted',
            headerName: 'Date Submitted',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'daysInQueue',
            headerName: 'Days in Queue',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'rxId',
            headerName: 'RX ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'aireport.dashboard.cell.toggleAIReportModel.' + 1}
                                variant="text"
                                onClick={() => {
                                    setSelectedRow(mockData[0]);
                                    setTriggerShowClaimModal(true);
                                }}
                            >
                                {mockData[0].rxId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'provider',
            headerName: 'Provider',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patient',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'lab',
            headerName: 'Lab',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'qcStatus',
            headerName: 'QC Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'biteSrc',
            headerName: 'Bite Src',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'biteStatus',
            headerName: 'Bite Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'modelSrc',
            headerName: 'Model Src',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'modelStatus',
            headerName: 'Model Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'vivosId13',
            headerName: 'Notes',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        }
    ]

    const mockData = [{
        id:1,
        dateSubmitted: '2022-11-11',
        daysInQueue: 7,
        rxId: 'VIV-23421-01',
        provider: 'Tara Friffin',
        patient: 'Jane Doe',
        lab: 'Apex',
        appliance: 'mRNA',
        qcStatus: 'Pending',
        biteSrc: 'Dig',
        biteStatus: 'Pending',
        modelSrc: 'Dig',
        modelStatus: 'Pending',
        providerComment:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
    }];

    return (
        <Fragment>
            <Grid xs={12} item container direction="row" className="page">
                <LeftNav onToggle={toggleLeftNav} />
                <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')}>
                    <Grid>
                        <MyoCorrectGridHeader
                            parentId={'aireport.dashboard'}
                            id={userId}
                            onChange={getSearchData}
                            onClear={clearData}
                            title={pageTitle}
                            numResults={numResults}
                            buttons={hdrButtons}
                        />
                    </Grid>
                    <Grid container direction="row" sx={{ marginBottom: '15px;' }} justifyContent={'space-between'}>
                        {infoBoxes}
                    </Grid>
                    <Grid className="grid" id={'aireporttech.dashboard' + userId}>
                        {/* {isLoading && <LinearProgress variant="determinate" value={loadingProgress} />} */}
                        <DataGrid columns={columns} loading={false} data={mockData} />
                    </Grid>
                </Grid>
            </Grid>
            <ModalContainer
            noScroll={true} 
            show={triggerShowClaimModal} 
            title={"Case Review"} 
            onClick={()=> {}} 
            onClose={() => {
                setTriggerShowClaimModal(false);
            }}>
                <LabCaseReview labCase={selectedRow}/>
            </ModalContainer>
        </Fragment>
)};
export const Laboratory = observer(LaboratoryComponent);