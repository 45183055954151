import { observer } from 'mobx-react';
import { FC } from 'react';

import { CollaborationReferral } from './CollaborationReferral';
import { DentalTreatmentPlan } from './Dental';
import { OtherTreatmentPlan } from './Other';
import { PrimaryTherapy } from './PrimaryTherapy';
import { SuplementalTherapies } from './SuplementalTherapies';
import { Surgery } from './Surgery';

interface ITreatmentPlanPage {
    patient?: any;
}

export const TreatmentPlanPage: FC<ITreatmentPlanPage> = observer(({ patient }) => {
    return (
        <>
            <PrimaryTherapy />

            <SuplementalTherapies />

            <DentalTreatmentPlan />

            <CollaborationReferral />

            <Surgery />

            <OtherTreatmentPlan />
        </>
    );
});
