import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import * as _ from 'lodash';

import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';
import EditPatient from '../../entities/EditPatient';

export default class AIReportEntryBL {
    @observable private _patientData = {};
    @observable private _patientId = 0;
    @observable private _submissionId = 0;
    @observable private _activeTab = 0;
    @observable private _isEditMode = false;
    @observable private _isOpenModal = false;
    @observable private _images: any[]  = [];
    userID: any;
    roles: any;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.clearPatient();
    }

    get patientData() {
        return this._patientData;
    }

    @action
    set patientData(value: any) {
        this._patientData = value;
    }

    get patientId() {
        return this._patientId;
    }

    @action
    set patientId(value: any) {
        this._patientId = value;
    }

    
    get submissionId() {
        return this._submissionId;
    }

    @action
    set submissionId(value: any) {
        this._submissionId = value;
    }

    get images() {
        return this._images;
    }

    @action
    set images(value: any[]) {
        this._images = value;
    }

    get activeTab() {
        return this._activeTab;
    }

    @action
    set activeTab(value: number) {
        this._activeTab = value;
    }

    get isEditMode() {
        return this._isEditMode;
    }

    @action
    set isEditMode(value: boolean) {
        this._isEditMode = value;
    }

    get isOpenModal() {
        return this._isOpenModal;
    }

    @action
    set isOpenModal(value: boolean) {
        this._isOpenModal = value;
    }

    @action
    handleClickModal = () => {
        this.isOpenModal = !this.isOpenModal;
    };

    handleCloceModal = () => {
        this.isOpenModal = false;
    };

    @action
    loadDetails = async () => {
        var url = '/patient/' + this.patientData.personId + '/details';
        await Gateway.get(url).then(data => {
            var patient = data as unknown as EditPatient;
        });
    };

    @action
    loadReport = async() => {
        var url = `/aireports/${this.submissionId}`;
        await Gateway.get(url).then(data => {
            this.images = data;
        });
    }

    @action
    getItemFromList = (list: any[], id: string, comparetor: number, responseField: string) => {
        var item = _.findIndex(list, f => {
            return f[id] == comparetor;
        });
        return item >= 0 ? list[item][responseField] : '';
    };

    @action
    setActiveTab = (index: number) => {
        this.activeTab = index;
    };

    @action
    onEditChange = (event: any) => {
        this.isEditMode = event.target.checked;
    };

    @action
    onEdit = () => {
        var url = 'patient/' + this.patientData.id;
        var data = this.patientData;
        Gateway.post(url, this.patientData).then();
    }

    @action
    healthOptionExists = ( options: number, option: number): boolean =>{
        return (options | option) === 1 ? true : false;        
    }

    @action
    clearPatient = () => {
        this.patientData = null;
    };

}
