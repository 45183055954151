import { useEffect } from "react";
import { observer } from "mobx-react";
import { Box, Divider, FormControl, Grid, Typography } from "@mui/material";

import ProviderPatient from "../../entities/ProviderPatient";
import React from "react";
import CssTextField from "../CssTextField";
import Item from "../Item";
import UploadImgDragDrop from "../UploadDragAndDrop/UploadImgDragDrop";
import PatientVisit from "../../entities/PatientVisit";
import { useMainStoreContext } from "../../stores/MainStore";
import Compliance from "../../entities/Compliance";
import _ from "lodash";

interface IGIProps {
    patient: ProviderPatient;
    visit: PatientVisit;
    parentCallback(data64string: string, dataPathUrl: string): any;
}

const defaultCompliance = {
    id: 0,
    visitId: -1,
    prescribedDaytimeHours: 0,
    prescribedNighttimeHours: 0,
    actualDaytimeHours: 0,
    actualNighttimeHours: 0,
    topViewUpper: "",
    topViewLower: "",
    bottomViewUpper: "",
    bottomViewLower: "",
    images: [] as any[],
    isActive: true,
}


export const PatientCompliance: React.FC<IGIProps> = observer(
    ({ patient, visit, parentCallback }) => {

        const [hasLoaded, setHasLoaded] = React.useState(false);
        const { topUpperImages, topLowerImages, bottomUpperImages, bottomLowerImages, patientCompliances, 
            complianceImagesSaved, complianceImagesToDelete, getVisit, setComplianceImagesSaved, 
            setPatientCompliances } = useMainStoreContext().visitRecordStore;

        useEffect(() => {
            if(visit?.patientCompliances[0]){
                patientCompliances[0].visitId = visit.id; 
                patientCompliances[0].images = []; 
            }
            else{
                setPatientCompliances([defaultCompliance as Compliance]);
            }
            setComplianceImagesSaved(false);
            setHasLoaded(true);
        }, [])

        const handleTopUpper = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "topUpper",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(patientCompliances[0].images, (x) => {
                    return x.name === "topUpper";
                })
                if (i >= 0) {
                    patientCompliances[0].images[i] = img;
                }
                else {
                    patientCompliances[0].images.push(img);
                }
            }
        }

        const handleTopLower = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "topLower",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(patientCompliances[0].images, (x) => {
                    return x.name === "topLower";
                })
                if (i >= 0) {
                    patientCompliances[0].images[i] = img;
                }
                else {
                    patientCompliances[0].images.push(img);
                }

            }
        }

        const handleBottomUpper = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "bottomUpper",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(patientCompliances[0].images, (x) => {
                    return x.name === "bottomUpper";
                })
                if (i >= 0) {
                    patientCompliances[0].images[i] = img;
                }
                else {
                    patientCompliances[0].images.push(img);
                }

            }
        }

        const handleBottomLower = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "bottomLower",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(patientCompliances[0].images, (x) => {
                    return x.name === "bottomLower";
                })
                if (i >= 0) {
                    patientCompliances[0].images[i] = img;
                }
                else {
                    patientCompliances[0].images.push(img);
                }

            }
        }

        const deleteTopUpperCallback = () => {
            complianceImagesToDelete.push("TOPUPPER");
            patientCompliances[0].topViewUpper = "";
        } 
        const deleteTopLowerCallback = () => {
            complianceImagesToDelete.push("TOPLOWER");
            patientCompliances[0].topViewLower = "";
        } 
        const deleteBottomUpperCallback = () => {
            complianceImagesToDelete.push("BOTTOMUPPER");
            patientCompliances[0].bottomViewUpper = "";
        } 
        const deleteBottomLowerCallback = () => {
            complianceImagesToDelete.push("BOTTOMLOWER");
            patientCompliances[0].bottomViewLower = "";
        } 
        return (
            <Grid className="formBasics">
                <Grid container direction="row" xs={12}>
                    <Grid xs={12} paddingLeft={3} paddingBottom={2} paddingTop={1} container direction="row">
                        <Grid xs={6}>
                            <Typography variant="h6">Prescribed Wear Time</Typography>
                            <Typography variant="h6" style={{ fontSize: "small" }}>(since the last record)</Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Typography variant="h6">Actual Wear Time</Typography>
                            <Typography variant="h6" style={{ fontSize: "small" }}>(since the last record)</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction="row" style={{ padding: "0px 0px 25px 50px" }}>
                        <Grid xs={3} >
                            <Item>
                                <FormControl >
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Daytime Hours</Grid>
                                        <Grid sx={{ color: 'red' }}>*</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        type="number"
                                        placeholder=""
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if ((visit && patientCompliances)) {
                                                patientCompliances[0].prescribedDaytimeHours = Number.parseInt(event.target.value);
                                            }
                                        }}
                                        value={patientCompliances[0] ? patientCompliances[0].prescribedDaytimeHours : 0}
                                    // onBlur={handleonBlur}
                                    />
                                </FormControl>
                            </Item>
                        </Grid>
                        <Grid xs={3}>
                            <Item>
                                <FormControl>
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Nighttime Hours</Grid>
                                        <Grid sx={{ color: 'red' }}>*</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        type="number"
                                        placeholder=""
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (patientCompliances) {
                                                patientCompliances[0].prescribedNighttimeHours = Number.parseInt(event.target.value);
                                            }
                                        }}
                                        value={patientCompliances[0] ? patientCompliances[0].prescribedNighttimeHours : 0}
                                    //  onBlur={handleonBlur}
                                    />
                                </FormControl>
                            </Item>
                        </Grid>
                        <Grid xs={3}>
                            <Item>
                                <FormControl >
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Daytime Hours</Grid>
                                        <Grid sx={{ color: 'red' }}>*</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        type="number"
                                        placeholder=""
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (patientCompliances) {
                                                patientCompliances[0].actualDaytimeHours = Number.parseInt(event.target.value);
                                            }
                                        }}
                                        value={patientCompliances[0] ? patientCompliances[0].actualDaytimeHours : 0}
                                    // onBlur={handleonBlur}
                                    />
                                </FormControl>
                            </Item>
                        </Grid>
                        <Grid xs={3}>
                            <Item>
                                <FormControl >
                                    <Grid container direction={'row'} className="formLabel">
                                        <Grid>Nighttime Hours</Grid>
                                        <Grid sx={{ color: 'red' }}>*</Grid>
                                    </Grid>
                                    <CssTextField
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        placeholder=""
                                        type="number"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (patientCompliances[0]) {
                                                patientCompliances[0].actualNighttimeHours = Number.parseInt(event.target.value);
                                            }
                                        }}
                                        value={patientCompliances[0] ? patientCompliances[0].actualNighttimeHours : 0}
                                    // onBlur={handleonBlur}
                                    />
                                </FormControl>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className="modalPageHeight">
                        <Box paddingTop={2} sx={{ flexGrow: 1 }}>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Top View Upper Appliance</Typography>
                                <UploadImgDragDrop type="appliance" parentCallback={handleTopUpper} typeOptions={[]} vivosId={patient.vivosId} visitId={visit?.id} extImages={topUpperImages} deleteCallback={deleteTopUpperCallback}/>
                            </Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Top View Lower Appliance</Typography>
                                <UploadImgDragDrop type="appliance" parentCallback={handleBottomUpper} typeOptions={[]} vivosId={patient.vivosId} visitId={visit?.id} extImages={topLowerImages} deleteCallback={deleteTopLowerCallback}/>
                            </Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Bottom View Upper Appliance</Typography>
                                <UploadImgDragDrop type="appliance" parentCallback={handleTopLower} typeOptions={[]} vivosId={patient.vivosId} visitId={visit?.id} extImages={bottomUpperImages} deleteCallback={deleteBottomUpperCallback}/>                                     
                            </Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Bottom View Lower Appliance</Typography>
                                <UploadImgDragDrop type="appliance" parentCallback={handleBottomLower} typeOptions={[]} vivosId={patient.vivosId} visitId={visit?.id} extImages={bottomLowerImages} deleteCallback={deleteBottomLowerCallback}/> 
                            </Grid>
                        </Box>
                        {complianceImagesSaved && <Grid container direction={'row'} justifyContent={'flex-end'} className="formLabel">
                            <Grid>Images uploaded successfully.</Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
);
