import { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import '../../scss/_GridHeader.scss';
import close from '../../imgs/tmp/bigx.png';
import * as _ from 'lodash';
import { AppBar, Toolbar, IconButton, Typography, Button, Grid, FormControlLabel, Radio } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CssTextField from "../CssTextField";
import {GridHeaderBL} from './GridHeaderBL';

interface GridHeaderProps {
    title: string;
    filterClass: string;
    numResults: number;
    buttons: JSX.Element[];
    onChange(value: string): any;
    onClear(): any;
    onCancel(): any;
    onFilter(filters: any): any;
}

@observer
export class GridHeaderTitle extends Component<GridHeaderProps> {
    @observable store = new GridHeaderBL();
    k = 0;

    constructor(props: any) {
        super(props);
        makeObservable(this);
        this.store.searchText = createRef();
    }

    render() {
        var buttons = _.map(this.props.buttons, (b, key) => {
            return <Grid key={key}>{b}</Grid>
        })
        return (
            <Grid container direction="column" alignItems="center" className="gridHeader" xl={12}>
                <Grid container className="placeholder" alignItems="center" alignContent="center" xl={12}>
                    <AppBar position="static" className="modalHeader" color="inherit">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="title">
                                {this.props.title.toUpperCase()}
                            </Typography>
                            {buttons}
                            <div className='seperator'></div>
                            <IconButton className="icon" size="large" onClick={this.store.expandSearch.bind(this)}><SearchIcon fontSize="inherit" className="filled" /></IconButton>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid container direction="row" alignItems={"center"} justifyContent="space-between" className={"searchHeader" + (this.store.searchHeaderExpanded === true ? " expanded" : "")}>
                    <Grid item md={1} className="searchImg"> <IconButton className="icon" size="large" onClick={this.store.expandSearch.bind(this)}><SearchIcon fontSize="inherit" className="filled" /></IconButton> </Grid>
                    <Grid item md={7} className="searchTextDiv" alignSelf="center">
                        <CssTextField  ref={this.store.searchText} className="searchText" value={this.store.textSearch} sx={{padding:"0px", lineHeight:"30px", font:"$brand_font_xl_italic"}} placeholder="SEARCH (Name, ID)" 
                        onChange={(event) => {
                            this.props.onChange(event.target.value);
                            this.store.onChange(event);}} /></Grid>
                    <Grid item md={2} className="clearResults" 
                        onClick={() => {
                                this.store.clearTextInput();
                                this.props.onClear()}
                                }>CLEAR RESULTS </Grid>
                    <Grid item md={1} className="close"><img src={close} alt="Search" height="24px" width="24px" onClick={this.store.expandSearch.bind(this)} /></Grid>
                </Grid>
            </Grid>
        );
    }
}

//PATIENTS PENDING ACTION