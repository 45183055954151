import React, { Component, useState } from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    SelectChangeEvent,
    Switch,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField
} from '@mui/material';
import moment from 'moment';
import { observer } from 'mobx-react';
import * as _ from 'lodash';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { makeObservable } from 'mobx';

import { countryLabelValues, stateLabelValues, Gender, Ethnicity } from '../../../entities/Enums';
import { GlobalUtils } from '../../../api/GlobalUtils';
import CssSelectField from '../../CssSelectField';
import CssTextField from '../../CssTextField';
import ProviderPatient from '../../../entities/ProviderPatient';
import Item from '../../Item';
import PatientEditBL from '../PatientEditBL';

const stateMap = (): any => {
    var states = _.map(stateLabelValues, (s, i) => {
        return (
            <MenuItem key={i} value={s.value}>
                {s.label}
            </MenuItem>
        );
    });
    return states;
};

const countryMap = (): any => {
    var countries = _.map(countryLabelValues, (s, i) => {
        return (
            <MenuItem key={i} value={s.value}>
                {s.label}
            </MenuItem>
        );
    });
    return countries;
};

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
}

export const PatientGeneralInfoTwo: React.FC<IGIProps> = observer(({ bl, patient }) => {
    const [toggleOnEdit, setToggleOnEdit] = useState(bl.isEditMode);

    let genderValues = [];
    const genderMap = (): any => {
        genderValues = GlobalUtils.getGenders();
        var genders = _.map(genderValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return genders;
    };

    let ethnicityValues = [];
    const ethnicityMap = (): any => {
        ethnicityValues = GlobalUtils.getEthnicities();
        var ethnicities = _.map(ethnicityValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return ethnicities;
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.firstName = event.target.value;
    };
    const handleMiddleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.middleName = event.target.value;
    };
    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.lastName = event.target.value;
    };
    const handleDateOfBirthChange = (value: Date | null) => {
        if (!value) return;
        bl.patientData.dateOfBirth = value;

    };
    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.genderId = event.target.value as unknown as number;
    };
    const handleEthnicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.ethnicityId = event.target.value as unknown as number;
    };
    const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.primaryPhone = event.target.value;
    };
    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.altPhone = event.target.value;
    };
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        bl.patientData.emailAddress = event.target.value;
    };
    const handleAddressOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.addresses[0] != null) {
            bl.addresses[0].address1 = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].address1 = event.target.value;
        }
    };
    const handleAddressTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.addresses[0] != null) {
            bl.addresses[0].address2 = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].address2 = event.target.value;
        }
    };
    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.addresses[0] != null) {
            bl.addresses[0].city = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].city = event.target.value;
        }
    };
    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.addresses[0]) {
            bl.addresses[0].state = event.target.value;
        }
    };
    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.addresses[0] != null) {
            bl.addresses[0].postalCode = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].postalCode = event.target.value;
        }
    };
    const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.addresses[0] != null) {
            bl.addresses[0].country = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].country = event.target.value;
        }
    };

    return (
        <List
            disablePadding
            sx={{
                my: 1.5
            }}
        >
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='First Name:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={patient.firstName}
                        variant="outlined"
                        onChange={handleFirstNameChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{patient.firstName}</Typography>}
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Middle Name:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={patient.middleName}
                        variant="outlined"
                        onChange={handleMiddleNameChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{patient.middleName}</Typography>}
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Last Name:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={patient.lastName}
                        variant="outlined"
                        onChange={handleLastNameChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{patient.lastName}</Typography>}
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Date of Birth:'
                />
                {bl.isEditMode
                    ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={moment(patient.dateOfBirth).format('MM/DD/yyyy')}
                            openTo="day"
                            disableFuture={true}
                            views={['year', 'month', 'day']}
                            onChange={handleDateOfBirthChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    sx={{
                                        width: '70%',
                                    }}
                                    variant="outlined"
                                    id="date-of-birth"
                                    fullWidth
                                    value={moment(patient.dateOfBirth).format('MM/DD/yyyy')}
                                />}

                        />
                    </LocalizationProvider>
                    : <Typography width={'70%'} variant="subtitle1">{moment(patient.dateOfBirth).format('MM/DD/yyyy')}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Gender:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={patient.genderId}
                        variant="outlined"
                        onChange={handleGenderChange}
                    >
                        {genderMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{patient.genderId ? Gender[patient.genderId] : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Ethnicity:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={patient.ethnicityId}
                        variant="outlined"
                        onChange={handleEthnicityChange}
                    >
                        {ethnicityMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{patient.ethnicityId ? Ethnicity[patient.ethnicityId] : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Mobile Phone:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={patient.primaryPhone}
                        variant="outlined"
                        onChange={handleCellPhoneChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{patient.primaryPhone}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Alternate Phone:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={patient.altPhone}
                        variant="outlined"
                        onChange={handlePhoneChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{patient.altPhone}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='E-mail:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={patient.emailAddress}
                        variant="outlined"
                        onChange={handleEmailChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{patient.emailAddress ? patient.emailAddress : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Address 1:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={bl.addresses[0] ? bl.addresses[0].address1 : ''}
                        variant="outlined"
                        onChange={handleAddressOneChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{bl.addresses[0] ? bl.addresses[0].address1 : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Address 2:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={bl.addresses[0] ? bl.addresses[0].address2 : ''}
                        variant="outlined"
                        onChange={handleAddressTwoChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{bl.addresses[0] ? bl.addresses[0].address2 : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='City:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={bl.addresses[0] ? bl.addresses[0].city : ''}
                        variant="outlined"
                        onChange={handleCityChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{bl.addresses[0] ? bl.addresses[0].city : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='State:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={bl.addresses[0]
                            ? _.find(stateLabelValues, st => {
                                return st.value == bl.addresses[0].state;
                            })?.value
                            : ''}
                        variant="outlined"
                        onChange={handleStateChange}
                    >
                        {stateMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{bl.addresses[0]
                        ? _.find(stateLabelValues, st => {
                            return st.value == bl.addresses[0].state;
                        })?.label
                        : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Postal Code:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={bl.addresses[0] ? bl.addresses[0].postalCode : ''}
                        variant="outlined"
                        onChange={handlePostalCodeChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{bl.addresses[0] ? bl.addresses[0].postalCode : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Country:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={bl.addresses[0]
                            ? _.find(countryLabelValues, st => {
                                return st.value == bl.addresses[0].country;
                            })?.value
                            : ''}
                        variant="outlined"
                        onChange={handleCountryChange}
                    >
                        {countryMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{bl.addresses[0]
                        ? _.find(countryLabelValues, st => {
                            return st.value == bl.addresses[0].country;
                        })?.label
                        : ''}</Typography>}

            </ListItem>

        </List>
        // <Grid>
        //     <Grid xs={12} container direction={'row'}>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>First Name</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         required={true}
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             bl.patientData.firstName = event.target.value;
        //                         }}
        //                         value={patient.firstName}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Middle Name</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             bl.patientData.middleName = event.target.value;
        //                         }}
        //                         value={patient.middleName}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Last Name</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         required={true}
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             bl.patientData.lastName = event.target.value;
        //                         }}
        //                         value={patient.lastName}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Birth Date</Grid>
        //                     </Grid>
        //                     {!bl.isEditMode && (
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             size="small"
        //                             disabled={!bl.isEditMode}
        //                             placeholder=""
        //                             required={true}
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                 bl.patientData.dateOfBirth = event.target.value;
        //                             }}
        //                             value={moment(patient.dateOfBirth).format('MM/DD/yyyy')}
        //                         />
        //                     )}
        //                     {bl.isEditMode && (
        //                         <LocalizationProvider dateAdapter={AdapterDateFns}>
        //                             <DatePicker
        //                                 className="DateBox"
        //                                 openTo="day"
        //                                 views={['year', 'month', 'day']}
        //                                 value={patient.dateOfBirth}
        //                                 onChange={newValue => {
        //                                     if (newValue != null) {
        //                                         bl.patientData.dateOfBirth = moment(newValue).toDate();
        //                                     }
        //                                 }}
        //                                 renderInput={params => (
        //                                     <CssTextField
        //                                         disabled={bl.isEditMode}
        //                                         {...params}
        //                                         sx={{
        //                                             '& input': {
        //                                                 backgroundColor: 'white',
        //                                                 color: '#050505',
        //                                                 fontFamily: 'Proxima Nova',
        //                                                 fontSize: '16px',
        //                                             },
        //                                         }}
        //                                         className="TextBox"
        //                                         size="small"
        //                                     />
        //                                 )}
        //                             />
        //                         </LocalizationProvider>
        //                     )}
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //     </Grid>
        //     <Grid xs={12} container direction={'row'}>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Gender</Grid>
        //                     </Grid>
        //                     {!bl.isEditMode && (
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             size="small"
        //                             disabled={!bl.isEditMode}
        //                             placeholder=""
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                 bl.patientData.genderId = event.target.value as unknown as number;
        //                             }}
        //                             value={patient.genderId ? Gender[patient.genderId] : ''}
        //                         />
        //                     )}
        //                     {bl.isEditMode && (
        //                         <CssSelectField
        //                             size="small"
        //                             className="DateBox"
        //                             sx={{ width: '100%' }}
        //                             onChange={(event: any) => {
        //                                 bl.patientData.genderId = event.target.value as unknown as number;
        //                             }}
        //                             value={patient.genderId ? patient.genderId : ''}
        //                         >
        //                             {genderMap()}
        //                         </CssSelectField>
        //                     )}
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Ethnicity</Grid>
        //                     </Grid>
        //                     {!bl.isEditMode && (
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             size="small"
        //                             sx={{ width: '100%' }}
        //                             disabled={!bl.isEditMode}
        //                             placeholder=""
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                 bl.patientData.ethnicityId = event.target.value as unknown as number;
        //                             }}
        //                             value={patient.ethnicityId ? Ethnicity[patient.ethnicityId] : ''}
        //                         />
        //                     )}
        //                     {bl.isEditMode && (
        //                         <CssSelectField
        //                             size="small"
        //                             className="DateBox"
        //                             sx={{ width: '100%' }}
        //                             onChange={(event: any) => {
        //                                 bl.patientData.ethnicityId = event.target.value as unknown as number;
        //                             }}
        //                             value={patient.ethnicityId ? patient.ethnicityId : ''}
        //                         >
        //                             {ethnicityMap()}
        //                         </CssSelectField>
        //                     )}
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //     </Grid>
        //     <Grid xs={12} container direction={'row'}>
        //         <Grid item xs={6}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Address 1</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         sx={{ width: '90%', marginRight: '25px' }}
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             if (bl.addresses[0] != null) {
        //                                 bl.addresses[0].address1 = event.target.value;
        //                             } else {
        //                                 const address = {};
        //                                 bl.addresses[0] = address;
        //                                 bl.addresses[0].address1 = event.target.value;
        //                             }
        //                         }}
        //                         value={bl.addresses[0] ? bl.addresses[0].address1 : ''}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Address 2</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         sx={{ width: '90%', marginRight: '25px' }}
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             if (bl.addresses[0] != null) {
        //                                 bl.addresses[0].address2 = event.target.value;
        //                             } else {
        //                                 const address = {};
        //                                 bl.addresses[0] = address;
        //                                 bl.addresses[0].address2 = event.target.value;
        //                             }
        //                         }}
        //                         value={bl.addresses[0] ? bl.addresses[0].address2 : ''}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //     </Grid>
        //     <Grid xs={12} container direction={'row'}>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>City</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             if (bl.addresses[0] != null) {
        //                                 bl.addresses[0].city = event.target.value;
        //                             } else {
        //                                 const address = {};
        //                                 bl.addresses[0] = address;
        //                                 bl.addresses[0].city = event.target.value;
        //                             }
        //                         }}
        //                         value={bl.addresses[0] ? bl.addresses[0].city : ''}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>State</Grid>
        //                     </Grid>
        //                     {!bl.isEditMode && (
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             size="small"
        //                             disabled={!bl.isEditMode}
        //                             placeholder=""
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => { }}
        //                             value={
        //                                 bl.addresses[0]
        //                                     ? _.find(stateLabelValues, st => {
        //                                         return st.value == bl.addresses[0].state;
        //                                     })?.label
        //                                     : ''
        //                             }
        //                         />
        //                     )}
        //                     {bl.isEditMode && (
        //                         <CssSelectField
        //                             size="small"
        //                             className="DateBox"
        //                             sx={{ width: '100%' }}
        //                             onChange={(event: any) => {
        //                                 if (bl.addresses[0]) {
        //                                     bl.addresses[0].state = event.target.value;
        //                                 }
        //                             }}
        //                             value={
        //                                 bl.addresses[0]
        //                                     ? _.find(stateLabelValues, st => {
        //                                         return st.value == bl.addresses[0].state;
        //                                     })?.value
        //                                     : ''
        //                             }
        //                         >
        //                             {stateMap()}
        //                         </CssSelectField>
        //                     )}
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Postal Code</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             if (bl.addresses[0] != null) {
        //                                 bl.addresses[0].postalCode = event.target.value;
        //                             } else {
        //                                 const address = {};
        //                                 bl.addresses[0] = address;
        //                                 bl.addresses[0].postalCode = event.target.value;
        //                             }
        //                         }}
        //                         value={bl.addresses[0] ? bl.addresses[0].postalCode : ''}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Country</Grid>
        //                     </Grid>
        //                     {!bl.isEditMode && (
        //                         <CssTextField
        //                             variant="outlined"
        //                             className="TextBox"
        //                             disabled={!bl.isEditMode}
        //                             size="small"
        //                             placeholder=""
        //                             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                 if (bl.addresses[0] != null) {
        //                                     bl.addresses[0].country = event.target.value;
        //                                 } else {
        //                                     const address = {};
        //                                     bl.addresses[0] = address;
        //                                     bl.addresses[0].country = event.target.value;
        //                                 }
        //                             }}
        //                             value={
        //                                 bl.addresses[0]
        //                                     ? _.find(countryLabelValues, st => {
        //                                         return st.value == bl.addresses[0].country;
        //                                     })?.label
        //                                     : ''
        //                             }
        //                         />
        //                     )}
        //                     {bl.isEditMode && (
        //                         <CssSelectField
        //                             size="small"
        //                             className="DateBox"
        //                             sx={{ width: '100%' }}
        //                             onChange={(event: any) => {
        //                                 if (bl.addresses[0] != null) {
        //                                     bl.addresses[0].country = event.target.value;
        //                                 } else {
        //                                     const address = {};
        //                                     bl.addresses[0] = address;
        //                                     bl.addresses[0].country = event.target.value;
        //                                 }
        //                             }}
        //                             value={
        //                                 bl.addresses[0]
        //                                     ? _.find(countryLabelValues, st => {
        //                                         return st.value == bl.addresses[0].country;
        //                                     })?.value
        //                                     : ''
        //                             }
        //                         >
        //                             {countryMap()}
        //                         </CssSelectField>
        //                     )}
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //     </Grid>
        //     <Grid xs={12} container direction={'row'}>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Phone</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         disabled={!bl.isEditMode}
        //                         size="small"
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             bl.patientData.primaryPhone = event.target.value;
        //                         }}
        //                         value={patient.primaryPhone}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={3}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>Mobile</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         disabled={!bl.isEditMode}
        //                         size="small"
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             bl.patientData.altPhone = event.target.value;
        //                         }}
        //                         value={patient.altPhone}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <FormControl>
        //                 <Item>
        //                     <Grid container direction={'row'} className="formLabel">
        //                         <Grid>E-mail</Grid>
        //                     </Grid>
        //                     <CssTextField
        //                         variant="outlined"
        //                         className="TextBox"
        //                         size="small"
        //                         sx={{ width: '100%' }}
        //                         disabled={!bl.isEditMode}
        //                         placeholder=""
        //                         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                             bl.patientData.emailAddress = event.target.value;
        //                         }}
        //                         value={patient.emailAddress ? patient.emailAddress : ''}
        //                     />
        //                 </Item>
        //             </FormControl>
        //         </Grid>
        //         {/* <Grid item xs={3}>
        //                     <FormControl>
        //                         <Item>
        //                             <Grid container direction={"row"} className="formLabel" ><Grid>Other</Grid></Grid>
        //                             <CssTextField
        //                                 variant="outlined"
        //                                 className="TextBox"
        //                                 disabled={!bl.isEditMode}
        //                                 size="small"
        //                                 placeholder=""
        //                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //                                     //setFirstName(event.target.value);
        //                                 }}
        //                                 value={patient.}
        //                             />
        //                         </Item>
        //                     </FormControl> 
        //                 </Grid>*/}
        //     </Grid>
        //     {bl.isEditMode && (
        //         <Grid>
        //             <Grid container direction={'row'} justifyContent={'flex-end'}>
        //                 <Button className="vivButton large invert" onClick={bl.onEdit}>
        //                     Save
        //                 </Button>
        //             </Grid>
        //         </Grid>
        //     )}
        // </Grid>
    );
});
