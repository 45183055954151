// Vendors
import React, { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    alpha,
    Divider,
    Button,
    Link,
    TableCell,
    TableBody,
    Table,
    TableContainer,
    styled,
    useTheme,
    Card,
    TableRow,
    IconButton,
} from '@mui/material';

// Components
import { VisitRecordSectionModule } from '../VisitRecords/VisitRecordSelectionModule';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

// API
import { GlobalUtils } from '../../api/GlobalUtils';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as _ from 'lodash';


const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const BoxComposedImage = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      filter: grayscale(80%);
      background-size: cover;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const BoxComposedBg = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      top: ${theme.spacing(2)};
      z-index: 7;
    `
);

const Label = styled(Box)(
    ({ theme }) => `
      background: ${theme.palette.info.main};
      color: ${theme.palette.info.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 1.2)};
      border-radius: 50px;
    `
);

const LabelWarning = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.warning.main};
      color: ${theme.palette.warning.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

const LabelError = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.error.main};
      color: ${theme.palette.error.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

const LabelSuccess = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

const BoxOverlineButton = styled(Box)(
    ({ theme }) => `
      text-align: center;
      position: relative;
      z-index: 7;
  
      .MuiButton-root {
          border-radius: 50px;
          height: ${theme.spacing(7.5)};
          margin-top: -${theme.spacing(3.75)};
          padding: ${theme.spacing(0, 4)};
          border: 3px solid ${theme.colors.alpha.white[100]};
      }
    `
);

const CardWrapper = styled(Card)(
    ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[10], 0.05)};
    `
);

interface IGIProps {
    // patient: ProviderPatient;
    visits?: any;
}

export const AiReportAddons: React.FC<IGIProps> = observer(({ visits }) => {
    const [addons, setAddons] = useState({
        sassouni: false,
        radiology: false,
        printed: false,
    });
    const { addOns, setAddOns } = useMainStoreContext().aiReportOrder;
    const [currentRow, setCurrentRow] = React.useState<any>({})

    const handleAddons = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddons({ ...addons, [event.target.name]: event.target.checked });
    };

    const { sassouni, radiology, printed } = addOns;

    useEffect(() => {
        GlobalUtils.getAddOnTypes();
        setAddOns(addons);
    }, [addons, setAddOns]);

    return (
        <Grid container padding={1}>
            <Card>
                <BoxComposed
                    sx={(theme) => ({
                        background: `${theme.colors.gradients.purple3}`
                    })}
                >
                    <BoxComposedBg
                        sx={(theme) => ({
                            opacity: 0.1,
                            background: `${theme.colors.gradients.black1}`
                        })}
                    />
                    <BoxComposedImage
                        sx={{
                            opacity: 0.1,
                            backgroundImage: 'url("/static/images/placeholders/covers/2.jpg")'
                        }}
                    />
                    <BoxComposedContent pb={9} pt={7}>
                        <Typography
                            textAlign="center"
                            sx={{
                                pb: 1
                            }}
                            variant="h3"
                        >
                            Airway Intelligence Report
                        </Typography>
                        <Typography textAlign="center" fontWeight="normal" variant="h4">
                            Our team of Airway Intelligence Technicians are on standby waiting to complete your report!

                        </Typography>
                    </BoxComposedContent>
                </BoxComposed>
                <BoxOverlineButton>
                    <Button variant="contained" size="large" color="success">
                        Let's Get Started
                    </Button>
                </BoxOverlineButton>
                <Box p={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={4}>
                            <Box height={'250px'} paddingBottom={1}>
                                <CardWrapper
                                    elevation={0}
                                    sx={{
                                        textAlign: 'center',
                                        pb: 2.5
                                    }}
                                >
                                    <Grid container>
                                        <Grid xs={12}>
                                            <Box
                                                sx={(theme) => ({
                                                    position: `relative`,
                                                    left: `0`,
                                                    top: `0`,
                                                    height: `75px`,
                                                    borderRadius: `${theme.general.borderRadius}`,
                                                    background: `${theme.colors.gradients.black2}`
                                                })}>
                                                <BoxComposedContent py={2}>
                                                    <Typography variant='h3'>
                                                        Select Airway Intelligence Report Type
                                                    </Typography>
                                                </BoxComposedContent>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} padding={1}>
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow hover>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    sx={{
                                                                        padding: 0.5
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    pl: 0
                                                                }}
                                                            >
                                                                <Box pb={0.5} display="flex" alignItems="center">
                                                                    <Typography
                                                                        sx={{
                                                                            pl: 0.5
                                                                        }}
                                                                        variant="h4"
                                                                        noWrap
                                                                    >
                                                                        Standard
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography variant="h4">$149.00</Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow hover>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    disabled
                                                                    sx={{
                                                                        padding: 0.5
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    pl: 0
                                                                }}
                                                            >
                                                                <Box pb={0.5} display="flex" alignItems="center">
                                                                    <Typography
                                                                        sx={{
                                                                            pl: 0.5
                                                                        }}
                                                                        variant="h4"
                                                                        noWrap
                                                                    >
                                                                        Comparison
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography variant="h4">$0.00</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </CardWrapper>
                            </Box>
                            <VisitRecordSectionModule visits={visits} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardWrapper
                                elevation={0}
                                sx={{
                                    textAlign: 'center',
                                    pb: 2.5
                                }}
                            >
                                <Grid container>
                                    <Grid xs={12}>
                                        <Box
                                            sx={(theme) => ({
                                                position: `relative`,
                                                left: `0`,
                                                top: `0`,
                                                height: `75px`,
                                                borderRadius: `${theme.general.borderRadius}`,
                                                background: `${theme.colors.gradients.black2}`
                                            })}>
                                            <BoxComposedContent py={2}>
                                                <Typography variant='h3'>
                                                    Customize Report
                                                </Typography>
                                            </BoxComposedContent>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} padding={1}>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={sassouni} onChange={handleAddons} name="sassouni"
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Sassouni+ Analysis
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>

                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Faciometric Findings
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Cranial Base Measurements
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Cervical Measurements
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    RG and RP Anterior to Posterior Distance
                                                                </Typography>

                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    RG and RP Area Measurements
                                                                </Typography>

                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Posterior Nasal Aperture Measurements
                                                                </Typography>

                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Inferior Nasal Concha Space Measurements
                                                                </Typography>

                                                            </Box>

                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={radiology} onChange={handleAddons} name="radiology"
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    Radiology Report
                                                                </Typography>

                                                            </Box>
                                                            <LabelSuccess>Recommended</LabelSuccess>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$109.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={printed} onChange={handleAddons} name="printed"
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    3D Printed Airway
                                                                </Typography>

                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$19.00</Typography>
                                                        </TableCell>

                                                    </TableRow>



                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <FormControl>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={sassouni} onChange={handleAddons} name="sassouni" />}
                                                    label="Sassouni"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={radiology} onChange={handleAddons} name="radiology" />}
                                                    label="Radiology"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox checked={printed} onChange={handleAddons} name="printed" />}
                                                    label="3d Printed Airway"
                                                />
                                            </FormGroup>
                                        </FormControl> */}
                                    </Grid>


                                </Grid>

                            </CardWrapper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardWrapper
                                elevation={0}
                                sx={{
                                    textAlign: 'center',
                                    pb: 2.5
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={(theme) => ({
                                                position: `relative`,
                                                left: `0`,
                                                top: `0`,
                                                height: `75px`,
                                                borderRadius: `${theme.general.borderRadius}`,
                                                background: `${theme.colors.gradients.black2}`
                                            })}>
                                            <BoxComposedContent py={2}>
                                                <Typography variant='h3'>
                                                    Medical Billing Radiographic Images
                                                </Typography>
                                            </BoxComposedContent>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} padding={1}>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    70140 AP Skull
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                disabled
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    70220 Paranasal Sinuses
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                disabled
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    70350 Lateral Skull (Cephalogram)
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                disabled
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    70250 Sumentovertex
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                disabled
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    70355 Orthopantogram
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                disabled
                                                                sx={{
                                                                    padding: 0.5
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                pl: 0
                                                            }}
                                                        >
                                                            <Box pb={0.5} display="flex" alignItems="center">
                                                                <Typography
                                                                    sx={{
                                                                        pl: 0.5
                                                                    }}
                                                                    variant="h4"
                                                                    noWrap
                                                                >
                                                                    76100 TMJ Tomography, Unilateral
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell>
                                                    </TableRow>


                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardWrapper>
                        </Grid>
                    </Grid>
                </Box >
                <Divider />

            </Card >
        </Grid >
        // <Grid container paddingLeft={3} paddingRight={3} paddingTop={3} sx={{ border: '1px solid #ccc', }}>
        //     <Grid xs={12}>
        //         <Typography variant='h3'>Select Report Type</Typography>
        //     </Grid>

        // </Grid>
    );
});
