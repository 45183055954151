import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';

import { useMainStoreContext } from '../../../stores/MainStore';

export const SearchProvider = observer(() => {
    const { searchProviders } = useMainStoreContext().customFormToProviderStore;

    const [searchValue, setSearchValue] = useState<string>('');

    const search = (value: string) => {
        searchProviders(value);
    };

    return (
        <Grid>
            <TextField
                id="search-bar"
                className="text"
                label="Enter a Provider Name"
                variant="outlined"
                placeholder="Search..."
                size="small"
                onChange={(e: any) => {
                    e.preventDefault();
                    setSearchValue(e.target.value);
                }}
            />
            <IconButton
                aria-label="search"
                onClick={(e: any) => {
                    search(searchValue);
                }}
            >
                <SearchIcon style={{ fill: 'blue' }} />
            </IconButton>
        </Grid>
    );
});
