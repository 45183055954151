import React, { useState } from 'react';
import { Grid, Radio, Paper, Typography, styled } from '@mui/material';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

interface SelectableImageProps {
    imageUrl: string;
    imageIndex: number;
    selected: boolean;
    onSelect: () => void;
    markAsEdited: boolean;
}

const ImageContainer = styled(Paper)({
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.8,
    },
});

const SelectableImage: React.FC<SelectableImageProps> = ({ imageUrl, imageIndex, selected, onSelect, markAsEdited }) => {
    return (
        <ImageContainer elevation={3} onClick={onSelect} style={{ opacity: selected ? 0.8 : 1 }}>
            <img src={imageUrl} alt="Selectable" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            {markAsEdited && (
                <ErrorOutlineTwoToneIcon
                    style={{ position: 'absolute', top: -10, right: -10, color: 'blue' }}
                />
            )}
            {selected && (
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <Typography variant="h6" style={{ color: 'white', textAlign: 'center', paddingTop: '50%' }}>
                        Selected
                    </Typography>
                </div>
            )}
            <Radio checked={selected} style={{ position: 'absolute', top: 0, right: 0, color: 'white' }} />
            <Typography variant='h4' style={{ position: 'absolute', bottom: 10, left: 10, color: 'black' }}>
                {`Level ${imageIndex}`}
            </Typography>
        </ImageContainer>
    );
};

export default SelectableImage;