import { Button, Grid, MenuItem } from "@mui/material";
import { observer } from "mobx-react";
import { FC, Fragment } from "react";
import DataGrid from "../../components/DataGrid/DataGrid";
import { LeftNav } from "../../components/LeftNav/LeftNav";
import { MyoCorrectGridHeader } from "../../components/MyoCorrectGridHeader";
import { useMainStoreContext } from "../../stores/MainStore";
import React from "react";
import moment from "moment";
import ModalContainer from "../../components/ModalContainer";
import { PatientEdit } from "../../components/PatientProfile/PatientEdit";
import { TrackOrder } from "../../components/Orders/TrackOrder";
import CssSelectField from "../../components/CssSelectField";
import PageHeader from "./components/PageHeader";
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import OrdersTable from "./components/OrdersTable";
import { Appliance } from "../../models/appliance";
import { subHours } from 'date-fns';

// The following is temporary data.
let applianceOrders: Appliance[] = [
    {
        id: '1',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Jane Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'Apex',
        labLogo: '/logos/labs/Apex.jpg',
        appliance: 'DNA',
        applianceLogo: '/logos/appliances/DNA.png',
        status: 'submitted'
    },
    {
        id: '2',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Josh Doe',
        patientAvatar: '/avatars/4.jpg',
        lab: 'AI Center',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'Vida',
        applianceLogo: '/logos/appliances/Vida.png',
        status: 'in-fabrication'
    },
    {
        id: '3',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Billy Doe',
        patientAvatar: '/avatars/3.jpg',
        lab: 'Gergens',
        labLogo: '/logos/labs/Gergens.jpeg',
        appliance: 'mmRNA',
        applianceLogo: '/logos/appliances/mmRNA.png',
        status: 'submitted'
    },
    {
        id: '4',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Samantha Doe',
        patientAvatar: '/avatars/2.jpg',
        lab: 'JDL',
        labLogo: '/logos/labs/JDL.png',
        appliance: 'DNA',
        applianceLogo: '/logos/appliances/DNA.png',
        status: 'submitted'
    },
    {
        id: '5',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Sophia Doe',
        patientAvatar: '/avatars/5.jpg',
        lab: 'AI Center',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'Guide',
        applianceLogo: '/logos/appliances/Guide.png',
        status: 'in-fabrication'
    },
    {
        id: '6',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Blake Doe',
        patientAvatar: '/avatars/4.jpg',
        lab: 'AI Center',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'Guide',
        applianceLogo: '/logos/appliances/Guide.png',
        status: 'shipped'
    },
    {
        id: '7',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Julio Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'Versa',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'Versa',
        applianceLogo: '/logos/appliances/Versa.png',
        status: 'shipped'
    },
    {
        id: '8',
        rxId: 'VIV-11111-02',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Jane Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'Apex',
        labLogo: '/logos/labs/Apex.jpg',
        appliance: 'mRNA',
        applianceLogo: '/logos/appliances/mRNA.png',
        status: 'in-fabrication'
    },
    {
        id: '9',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Josh Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: '5 Star',
        labLogo: '/logos/labs/FiveStar.jpeg',
        appliance: 'DNA',
        applianceLogo: '/logos/appliances/DNA.png',
        status: 'submitted'
    },
    {
        id: '10',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Anne Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'AI Center',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'PEx',
        applianceLogo: '/logos/appliances/PEx.png',
        status: 'shipped'
    },
    {
        id: '11',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Brock Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'Apex',
        labLogo: '/logos/labs/Apex.jpg',
        appliance: 'DNA',
        applianceLogo: '/logos/appliances/DNA.png',
        status: 'submitted'
    },
    {
        id: '12',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Rick Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: '5 Star',
        labLogo: '/logos/labs/FiveStar.jpeg',
        appliance: 'mmRNA',
        applianceLogo: '/logos/appliances/mmRNA.png',
        status: 'shipped'
    },
    {
        id: '13',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Carl Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'AI Center',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'Vida',
        applianceLogo: '/logos/appliances/Vida.png',
        status: 'on-hold'
    },
    {
        id: '14',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Betsy Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: 'AI Center',
        labLogo: '/logos/labs/AIC.png',
        appliance: 'Vida',
        applianceLogo: '/logos/appliances/Vida.png',
        status: 'on-hold'
    },
    {
        id: '15',
        rxId: 'VIV-11111-01',
        submittedDate: subHours(new Date(), 18).getTime(),
        dueDate: subHours(new Date(), 15).getTime(),
        patientName: 'Frank Doe',
        patientAvatar: '/avatars/1.jpg',
        lab: '5 Star',
        labLogo: '/logos/labs/FiveStar.jpeg',
        appliance: 'mRNA',
        applianceLogo: '/logos/appliances/mRNA.png',
        status: 'submitted'
    },
];

interface IGIProps {
}

export const Orders: FC<IGIProps> = observer(() => {
    const store = useMainStoreContext().orderStore;
    const [selectedOrderStatus, setSelectedOrderStatus] = React.useState(0);

    React.useEffect(() => {
        store.getOrders();
        store.loadOrderStatusValues();
    }, []);

    const numResults = 0;
    const hdrButtons: any = [];

    const columns = [
        {
            field: 'ordRxId',
            headerName: 'Rx Order ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {<Button
                            onClick={() => {
                                store.toggleOrderStatusModal()
                                setSelectedOrderStatus(params.row.ordStatusId)
                            }
                            }>
                            {params.row.ordRxId}
                        </Button>}
                    </Grid>
                );
            },
        },
        {
            field: 'providerName',
            headerName: 'Provider',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.providerName}
                    </Grid>
                );
            },
        },
        {
            field: 'ordStatus',
            headerName: 'Order Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const disabled = !store.isAISAdmin;

                return (
                    <Grid>
                        <CssSelectField
                            id={"orders.dashboard.cell.ordStatus." + params.row.id}
                            sx={{ border: "0px" }}
                            defaultValue="0"
                            label={params.row.ordStatus}
                            disabled={disabled}
                            value={(params.row.ordStatusId ? params.row.ordStatusId : 0)}
                            onChange={(event) => {
                                params.row.ordStatusId = event.target.value;
                                store.handleOrderStatusChange(params.row, event)
                            }}
                        >
                            {
                                store.orderStatusValues.map((tm) =>
                                    (<MenuItem key={tm.id} value={tm.id}>{tm.name}</MenuItem>))
                            }
                        </CssSelectField>
                    </Grid>
                )
            }
        },
        {
            field: 'labName',
            headerName: 'Laboratory',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.labName}
                    </Grid>
                );
            },
        },
        {
            field: 'patientVivosId',
            headerName: 'Patient Vivos ID ',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    store.getPatientData(params.row.patientId);
                                    store.currentPatientId = params.row.patientId;
                                    store.toggleEditPatientModal();
                                }}
                            >
                                {params.row.patientVivosId}
                            </Button>}
                    </Grid>
                );
            },
        },
        {
            field: 'patientName',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.patientName}
                    </Grid>
                );
            },
        },
        {
            field: 'ordCreatedOn',
            headerName: 'Create Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>{params.row.ordCreatedOn && moment(params.row.ordCreatedOn).format('MM/DD/yyyy')}</Grid>
                );
            },
        }
    ]

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>
            <Grid
                sx={{
                    pt: 4
                }}
                container
                direction="row"
                justifyContent="center"
                // alignItems="stretch"
                spacing={4}
            >
                <Grid xs={11}>

                    <DataGrid columns={columns} loading={store.isLoadingOrders} data={store.ordersData} />
                    {/* The below table displays table how we would like it to be UI wise. */}
                    <OrdersTable applianceOrders={applianceOrders} />
                </Grid>
            </Grid>

        </Fragment>

        // <Fragment>
        //     <Grid xs={12} item container direction="row" className="page">
        //         {/* <LeftNav onToggle={store.toggleLeftNav} /> */}
        //         <Grid className={'content' + (store.isLeftNavExpanded ? ' small' : ' big')}>
        //             <Grid>
        //                 <MyoCorrectGridHeader
        //                     parentId={'aireport.dashboard'}
        //                     id={store.userID}
        //                     onChange={store.getSearchData}
        //                     onClear={store.clearData}
        //                     title={store.pageTitle}
        //                     numResults={numResults}
        //                     buttons={hdrButtons}
        //                 />
        //             </Grid>
        //             <Grid className="grid">
        //                 <DataGrid columns={columns} loading={store.isLoadingOrders} data={store.ordersData} />
        //             </Grid>
        //         </Grid>
        //     </Grid>
        //     <ModalContainer
        //         show={store.toggleShowPatientEditModal}
        //         noScroll={true}
        //         title=""
        //         hideTitlebar={true}
        //         onClose={() => { }}
        //         onClick={store.toggleEditPatientModal.bind(this)}
        //     >
        //         {!store.isLoadingPatient && <PatientEdit
        //             asProvider={true}
        //             patientID={store.currentPatientId}
        //             patient={store.patientData}
        //             onClose={() => {
        //                 store.toggleEditPatientModal();
        //             }}
        //         />}
        //     </ModalContainer>
        //     <ModalContainer
        //         show={store.toggleShowOrderStatusModal}
        //         noScroll={true}
        //         title="Track Order"
        //         hideTitlebar={false}
        //         smallScreen={true}
        //         onClose={() => { store.toggleOrderStatusModal() }}
        //         onClick={store.toggleOrderStatusModal.bind(this)}
        //     >
        //         <TrackOrder orderStatusId={selectedOrderStatus} />
        //     </ModalContainer>
        // </Fragment>
    )
})