import { Close } from "@mui/icons-material";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { FC } from "react";
import CssTextField from "../../CssTextField";
import Item from "../../Item";
import User from "../../../entities/UserMV";
import CssSelectField from "../../CssSelectField";
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton , MenuItem } from "@material-ui/core";


interface IModalProps {
    bl: any;
    isOpen: boolean;
    title: string;
    onSave(value: User): any;
    onClose: (onClose: boolean) => void;
}

const defaultUser = {
    name: "",
    userName: "",
    isActive: true,
    createdBy: 0
}

export const UserModal: FC<IModalProps> = observer(({ bl, title, isOpen, onSave, onClose }) => {

    const handleOnClick = () => {
        bl.setCurrentUser(defaultUser);
        onClose(true);
    };

    const handlePasswordResetClick = () => {
        bl.setCurrentUser(defaultUser);
        onClose(true);
    };

    const handleCloseClick = () => {
        onClose(true);
    };

    const handleOnSelect = (user: any) => {
        bl.setCurrentUser(defaultUser);
        onClose(true);
    };

    const getRoleIds = (user: any): number[] => {
        var roles: number[] = [];
        _.forEach(user.roles, (r) => { roles.push(r.roleId) });
        return roles;
    }

    return (
        <Grid>
            <Dialog open={isOpen} fullWidth={true} maxWidth={"xl"} >
                <DialogTitle id='form-dialog-title' className='Title'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            sx={{ height: '30px' }}
                            flexGrow={1}
                            fontSize='24px'
                            fontWeight={900}
                            fontFamily='Proxima Nova'>
                            {title}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={handleOnClick}
                                style={{
                                    fontSize: 25,
                                    backgroundColor: 'transparent',
                                    color: 'blue'
                                }}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <Grid xs container direction="column" className="formBasics" style={{ margin: "25px 0px 0px 0px" }}>
                        <Grid container direction="row">
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Id</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="user.txt.id"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            disabled={true}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            }}
                                            value={bl.currentUser ? bl.currentUser.id : -1}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>First Name</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="user.txt.name"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                bl.currentUser.name = event.target.value;
                                                bl.currentUser.listKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                            }}
                                            value={bl.currentUser.firstName}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Last Name</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="user.txt.name"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                bl.currentUser.name = event.target.value;
                                                bl.currentUser.listKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                            }}
                                            value={bl.currentUser.lastName}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>User Name</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="user.txt.username"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: any) => {
                                                bl.currentUser.listKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                            }}
                                            value={bl.currentUser ? bl.currentUser.userName : ""}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container alignContent={"center"}>
                                <FormControl>
                                    <Item>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <FormControlLabel
                                            onChange={(event: any) => {
                                                bl.currentUser.isActive = event.target.checked;
                                            }}
                                            control={<Checkbox checked={bl.currentUser ? bl.currentUser.isActive : false} />}
                                            label="Is Active"
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>User Roles</Grid></Grid>
                                        <Grid alignContent={"center"}>
                                            <CssSelectField
                                                id={'usermodal.select.userrole'}
                                                defaultValue="0"
                                                label="Status"
                                                multiple={true}
                                                value={bl.currentUser.roles ? getRoleIds(bl.currentUser) : 0}
                                                onChange={e => {
                                                    // handleStatusChange(bl.currentUser.id, e.target.value);
                                                }} >
                                                {bl.roles.map((item: any) => (
                                                    <MenuItem key={item.id + item.name} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </CssSelectField>
                                        </Grid>
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" xs justifyContent={"flex-end"} >
                            <Grid><Button className="vivButton large invert" onClick={handlePasswordResetClick}>Reset Password</Button></Grid>
                            <Grid><Button className="vivButton large invert" onClick={handleCloseClick}>Cancel</Button></Grid>
                            <Grid><Button className="vivButton large invert" onClick={() => { onSave(bl.currentUser); handleOnClick(); }}>Save</Button></Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >
        </Grid>
    )
})