
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Typography, TextareaAutosize } from "@mui/material";
import { useMainStoreContext } from "../../../../stores/MainStore";
import { observer } from "mobx-react";
import { boxStyles } from "../styles";
import { RxAppliances, VersaTypes, VivosAppliances } from "../../../../entities/Enums";
import Dropzone from "../ApplianceDiagramDropzone";
import _ from 'lodash';

const styles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
	},
	vivosButton: {
		height: '100px',
		marginBottom: '-30px',
		marginTop: '-30px'
	}
}));
const StepFour = () => {
	const classes = styles();
	const boxClasses = boxStyles();
	const {
		
		currentPatient,
		selectedLab,
		selectedAppliance,
		versaTypeSelected,
		isUpdating,
		applianceConstructionDiagramFilename,
		fabricationTime,
		constructionComments,
		setVersaTypeSelected,
		setStep,
		setConstructionComments,
		setSelectedApplianceType
	} = useMainStoreContext().prescriptionStore;
	const { selectedAllergyList } = useMainStoreContext().patientEditStore;

	const handleBack = (): void => {
		isUpdating ? setStep(1) : setStep(2);
	};
	const handleNext = (): void => {
		setStep(4);
	};

	const handleVersaChange = (event: any) => {
        setVersaTypeSelected(event.target.textContent);
		setSelectedApplianceType(event.target.value);
    };

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<Box className={boxClasses.leftBox}>
						<Typography variant="h6">
							Select Mandibular Advancement Option
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={2}>
								<Button variant={versaTypeSelected === 'Versa 9' ? 'contained' : 'outlined'} color="primary" onClick={handleVersaChange} value={VivosAppliances['Versa-9']}>Versa 9</Button>
							</Grid>
							<Grid item xs={3}>
								<Button variant={versaTypeSelected === 'Versa Nylon EMA' ? 'contained' : 'outlined'} color="primary" onClick={handleVersaChange} value={VivosAppliances['Versa-EMA']}>Versa Nylon EMA</Button>
							</Grid>
							<Grid item xs={7}>
								<Box display='flex' justifyContent='right'>
									<Dropzone />
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6">
									Construction Comments
								</Typography>
							</Grid>
							<Grid item xs={12} paddingTop='100px'>
								<TextareaAutosize minRows={5} style={{ width: '80%', marginInline: '10%' }}
								onChange={(e) => {setConstructionComments(e.target.value)}} value={constructionComments}></TextareaAutosize>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="h6">
									RX Overview
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Allergies:</b> {selectedAllergyList.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Appliance:</b> {selectedAppliance}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Lab:</b> {selectedLab.name}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Fabrication Time:</b> {fabricationTime}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									<b>Teeth Charting:</b> Confirmed
								</Typography>
							</Grid>
							{selectedAppliance === RxAppliances.VERSA && <Grid item xs={12}>
								<Typography>
									<b>Versa Type Selected:</b> {versaTypeSelected}
								</Typography>
							</Grid>}
						</Grid>
						<Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
							<Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}>
								Back
							</Button>
							<Button
								variant="contained"
								color="primary"
								disabled={!applianceConstructionDiagramFilename || constructionComments === ''}
								onClick={handleNext}
								sx={{ borderRadius: '30px', margin: '20px' }}>
								Save + Continue
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
}

export default observer(StepFour);