import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useMainStoreContext } from "../../stores/MainStore";

const ROUND_ROBIN = process.env.REACT_APP_ROUNDROBIN;

const InitialEvaluationCalendly = () => {
	const { patientConsultation } = useMainStoreContext().consultationData;

	let calendlyURL = `${ROUND_ROBIN}?hide_gdpr_banner=1`;
	calendlyURL += `&name=${patientConsultation.firstName}%20${patientConsultation.lastName}`;
	calendlyURL += `&email=${patientConsultation.emailAddress}`;
	calendlyURL += `&a1=${patientConsultation.vivosId}`
	calendlyURL += `&utm_term=0`
	useEffect(()=> {
		const head = document.querySelector('head');
			const script = document.createElement('script');
			script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
			script.setAttribute('id', 'calendly-script');
			if(head) {
				head.appendChild(script);
			}
	},[]);

	return (
	<>
		<div>
			<div className="calendly-inline-widget" data-url={calendlyURL} style={{minWidth:'1000px',height:'700px'}}></div>
		</div>
	</>);
}
export default observer(InitialEvaluationCalendly);