// Vendors
import { useRef, useState } from 'react';
import {
  Box,
  useTheme,
  Grid,
  Divider,
  Card,
  styled,
  CardHeader,
  Tabs,
  Tab,
} from '@mui/material';

// Components
import HotSpotMenuItem from './HotspotMenuItem';


const CardBorderTop = styled(Card)(
  () => `
        border-top: transparent 5px solid;
  `
);

function HotSpotMenu() {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const hotspotItems = [
    {
      image: "/logos/vivos.png",
      tagValue: 'Vivos Essentials',
      title: 'Vivos Therapuetics',
      subTitle: 'In the news',
      buttonText: 'Find out more!',
      url: 'https://vivos.com/blog/',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/logos/ODO.png",
      tagValue: 'Vivos Essentials',
      title: 'On Demand Orthodontist',
      subTitle: '',
      buttonText: 'Log In',
      url: 'https://ondemandorthodontist.monday.com/',
      buttonTextTwo: 'Get Started',
      isSecondButton: true,
      urlTwo: 'https://forms.monday.com/forms/49a8a1cd119710766242197fbc03fc26?r=use1'
    },
    {
      image: "/logos/Spark.png",
      tagValue: 'Vivos Essentials',
      title: 'Spark Aligners',
      subTitle: '',
      buttonText: 'Launch',
      url: '',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/logos/Aire02.png",
      tagValue: 'Vivos Essentials',
      title: 'Aire 02',
      subTitle: 'Billing Intelligence Service',
      buttonText: 'Log In',
      url: 'https://vivosaireo2.com/public/',
      buttonTextTwo: 'Learn More',
      isSecondButton: true,
      urlTwo: 'https://vivos.com/billing-intelligence-service/'
    },
    {
      image: "/images/general/collaboration.jpg",
      tagValue: 'Practice Support',
      title: 'In Office Support Request',
      subTitle: '',
      buttonText: 'Get Started',
      url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=cqIrtcRXDU6CGGl_Iu5AdBFbeOer2g5BgAzHELIdqmdUMTFFMVVPQkxQME4yMlFRNFpGV1pDSEJEQS4u',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/images/general/forms.jpg",
      tagValue: 'Practice Support',
      title: 'Provider & Practice Forms',
      subTitle: '',
      buttonText: 'Launch',
      url: '',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/images/general/supply.jpg",
      tagValue: 'Practice Support',
      title: 'Marketing Materials Portal',
      subTitle: '',
      buttonText: 'Launch',
      url: 'https://craftsignsco.presencehost.net/portal/',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/images/general/integratedPracticeTile.png",
      tagValue: 'Clinical Support',
      title: 'Clinical Support Request',
      subTitle: '',
      buttonText: 'Get Started!',
      url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=cqIrtcRXDU6CGGl_Iu5AdBFbeOer2g5BgAzHELIdqmdUOUwxTllQSlhSSUNMUFk0TFgwV0NIUjA1SC4u',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/logos/VivosInstituteLogo.jpg",
      tagValue: 'Vivos Institute',
      title: 'The Vivos Institute',
      subTitle: 'Vivos Provider Courses and Registration',
      buttonText: 'Launch',
      url: 'https://tvi.zohobackstage.com/events',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/images/general/myabsorb.png",
      tagValue: 'Vivos Institute',
      title: 'MyAbsorb',
      subTitle: 'Vivos Learning Management',
      buttonText: 'Launch',
      url: 'https://vivos.myabsorb.com/',
      buttonTextTwo: '',
      isSecondButton: false,
      urlTwo: ''
    },
    {
      image: "/images/general/CEZoom.png",
      tagValue: 'Vivos Institute',
      title: 'CE Zoom',
      subTitle: '',
      buttonText: 'Launch',
      url: 'https://www.cezoom.com/',
      buttonTextTwo: 'Support Request',
      isSecondButton: true,
      urlTwo: 'https://forms.office.com/Pages/ResponsePage.aspx?id=cqIrtcRXDU6CGGl_Iu5AdBFbeOer2g5BgAzHELIdqmdUQ0RONzNXUU05T1k2QVBGRFZKVzFCSVowUi4u'
    },
  ];

  const filteredItems = hotspotItems.filter(item => {
    if (activeTab === 0) {
      // Vivos Essentials tab
      return item.tagValue === 'Vivos Essentials';
    } else if (activeTab === 1) {
      // Practice Support tab
      return item.tagValue === 'Practice Support';
    } else if (activeTab === 2) {
      // Clinical Support tab
      return item.tagValue === 'Clinical Support';
    } else if (activeTab === 3) {
      // Vivos Institute tab
      return item.tagValue === 'Vivos Institute';
    }

    return false;
  });

  return (
    <>
      <CardBorderTop
        sx={{
          textAlign: 'left',
          borderTopColor: `${theme.colors.primary.main}`
        }}
      >
        <Card sx={{ height: '30vw' }}>
          <CardHeader
            title={
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Vivos Essentials" />
                <Tab label="Practice Support" />
                <Tab label="Clinical Support" />
                <Tab label="Vivos Institute" />
              </Tabs>
            }
          />
          <Divider />
          <Box
            px={3}
            pb={2}
          >
            {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <HotSpotMenuItem
                  image="/logos/ODO.png"
                  tagValue='Vivos Essentials'
                  title='On Demand Orthodontist'
                  subTitle=''
                  buttonText='Log In'
                  url='https://ondemandorthodontist.monday.com/' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HotSpotMenuItem
                  image="/logos/Spark.png"
                  tagValue='Vivos Essentials'
                  title='Spark Aligners'
                  subTitle=''
                  buttonText='Launch'
                  url='' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HotSpotMenuItem
                  image="/logos/VivosInstituteLogo.jpg"
                  tagValue='Vivos Essentials'
                  title='The Vivos Institute'
                  subTitle='Vivos Provider Courses and Registration'
                  buttonText='Launch'
                  url='https://tvi.zohobackstage.com/events' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <HotSpotMenuItem
                  image="/logos/Aire02.png"
                  tagValue='Vivos Essentials'
                  title='Aire 02'
                  subTitle=''
                  buttonText='Log In'
                  url='' />
              </Grid>
            </Grid> */}
            <Grid container spacing={2}>
              {filteredItems.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <HotSpotMenuItem
                    image={item.image}
                    tagValue={item.tagValue}
                    title={item.title}
                    subTitle={item.subTitle}
                    buttonText={item.buttonText}
                    url={item.url}
                    buttonTextTwo={item.buttonTextTwo}
                    isSecondButton={item.isSecondButton}
                    urlTwo={item.urlTwo}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider />
        </Card>
      </CardBorderTop>
    </>
  );
}

export default HotSpotMenu;
