// Vendor
import React, { useMemo, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

// Components
import UploadSleepDocDragDrop from '../UploadDragAndDrop/UploadSleepDocDragDrop';
import DataGrid from '../DataGrid/DataGrid';

// Store
import { useMainStoreContext } from '../../stores/MainStore';

// Entities
import DictionaryListItem from '../../entities/DictionaryListItem';
import PatientFile from '../../entities/PatientFIle';
import PatientVisit from '../../entities/PatientVisit';

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from "../../api/GlobalUtils";
import moment from 'moment';

interface IProps {
    isIntake: boolean;
    visit: PatientVisit | undefined;
}

const SleepTest: React.FC<IProps> = ({ isIntake, visit }) => {
    const { patient, patientDocuments } = useMainStoreContext().createPatientStore;

    const { id, setSleepTestFiles, setPatientUploadFiles, patientFiles, deleteFile, patientUploadFiles, sleepTestFiles, setPatientFiles } = useMainStoreContext().visitRecordStore;
    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
    const [fileName, setFileName] = useState<string>('');
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [loadFiles, setLoadFiles] = React.useState<any>([]);

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            if (item.listItemKey === 'SLEEPTEST') {
                setReportTypes(prevState => [...prevState, item]);
            }
        });
    }, []);

    React.useEffect(() => {
        setSleepTestFiles(patientFiles.filter(x => x.fileDescriptorId === 191));
    }
        , [patientFiles]);

    const handleUpload = data => {
        let patientFileToSet: PatientFile = {
            id: 0,
            patientId: patient.id,
            visitId: id,
            fileDescriptorId: data.reportId,
            fileUrl: data.fileName,
            displayName: data.reportType,
            isActive: true,
        } as PatientFile;

        let currentPatientFileArray = patientUploadFiles.filter(x => x.displayName !== data.reportType);
        currentPatientFileArray.push(patientFileToSet);
        setFileName(data.reportType);
        setPatientUploadFiles(currentPatientFileArray);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'Document Id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.id}
                    </Grid>
                )
            }

        },
        {
            field: 'displayName',
            headerName: 'File Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.displayName}
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.createdOn ? moment(params.row.createdOn).format('MM/DD/yyyy') : ""}
                    </Grid>
                )
            }

        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button onClick={() => handleDownloadFile(params.row.fileUrl)}>
                            <DownloadIcon />
                        </Button>
                        <Button onClick={() => handleDeleteFile(params.row.id)}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                )
            }

        },
    ]

    const handleDownloadFile = (url) => {
        window.open(url, '_blank');
    }

    const handleDeleteFile = (fileId) => {
        deleteFile(fileId, visit?.id).then(resp => {
            setPatientFiles(resp);
        });
    }

    return (
        <Grid container >
            <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                Sleep Test
            </Typography>
            {/* <Box sx={{ flexGrow: 1 }}> */}
            <Grid xs={12}>
                <UploadSleepDocDragDrop parentCallback={handleUpload} extFiles={patientUploadFiles} typeOptions={reportTypes} />
            </Grid>
            {!isIntake && <Grid xs={12}>
                <Grid container direction="column" >
                    <Divider />
                    <Typography variant="h6" paddingLeft={3} paddingTop={1}>Uploaded Files</Typography>
                </Grid>
                <Grid xs={12} padding={4}>
                    <DataGrid
                        columns={columns}
                        data={sleepTestFiles}
                        loading={hasLoaded}
                        size={"small"}
                        pageSize={10}
                    />
                </Grid>
            </Grid>}
            {/* </Box> */}
        </Grid>
    );
};

export default observer(SleepTest);
