import axios from 'axios';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';

//const mainAxios = axios.create({ baseURL: "https://localhost:8001" + '/api/v1/' });
const mainAxios = axios.create({ baseURL: process.env.REACT_APP_GATEWAY_URL + '/api/v1/' });
const airAxios = axios.create({ baseURL: process.env.REACT_APP_AIREPORTCREATION_SVC })
const identityAxios = axios.create({ baseURL: process.env.REACT_APP_AUTHORITY_URL + '/api/' });
//axios.defaults.baseURL = "https://localhost:8001" + "/api/v1/";
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Accept'] = 'application/json;charset=utf-8';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Method'] = '*';
let aconfig = {};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    aconfig = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "*"
        },
    };
}



const token = sessionStorage.getItem('token');
var tokenData: { id };
if (token) {
    tokenData = jwt_decode(token);
}
export class Gateway {
    static get = (url: string) => {
        var data: any[] | PromiseLike<any[]> = [];
        return mainAxios
            .get(url, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };
    static getOne = (url: string) => {
        var data: any | PromiseLike<any> = null;
        return mainAxios
            .get<any>(url, {})
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = null;
            })
            .then(() => {
                return data;
            });
    };

    static post = (url: string, body: any) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any[] | PromiseLike<any[]> = [];
        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return mainAxios
            .post(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static postWithOptions = (url: string, body: any, config) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any;
        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return mainAxios.post(url, body, config)
            .then(response => {
                data = response;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static postNonAudit = (url: string, body: any) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any[] | PromiseLike<any[]> | any = [];
        return mainAxios
            .post(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static putNonAudit = (url: string, body: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        return mainAxios
            .put(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static put = (url: string, body: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        body['userId'] = tokenData.id;
        return mainAxios
            .put(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static delete = (url: string, body: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        return mainAxios
            .delete(url, {
                data: body,
            },)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static fileUploader = async (url: string, fileName: string, file: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        const postData = new FormData();
        postData.append('formFile', file);
        postData.append('fileName', fileName);
        postData.append('success', 'false');
        return mainAxios
            .post(url, postData)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static calendlyGet = (url: string) => {
        // var fullurl = "https://api.calendly.com/scheduled_events/" + url;
        var token = 'Bearer ' + process.env.REACT_APP_CALENDLY_AUTH_TOKEN;
        var config = { headers: { Authorization: token } };

        var data: any[] | PromiseLike<any[]> = [];
        return mainAxios
            .get(url, config)
            .then(response => {
                data = response.data.resource;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static generatedAiReport = (url: string, body: any) => {
        let token = process.env.REACT_APP_GENERATE_AIREPORT_AUTH_TOKEN!;
        let config = {
            headers: {
                authToken: token,
            },
        };

        let data: any[] | PromiseLike<any[]> = [];
        return airAxios
            .post(url, body, config)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static identityPost = (url: string, body: any) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any[] | PromiseLike<any[]> = [];
        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return identityAxios
            .post(url, body, {})
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };
}
