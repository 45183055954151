import { styled, Select } from "@mui/material";
import "../scss/_brand.scss";

const CssSelectField = styled(Select)({
    '& label.Mui-focused': {
      border:"0px",
    },
    // '& .Mui-focused': {
    //   borderColor: '#1B4070',
    // },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
      border:"0px",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
      border:"0px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        border:"0px",
        width:"100%",
      },
      // '&:hover fieldset': {
      //   borderColor: 'white',
      //   border:"0px",
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: 'white',
      //   border:"0px",
      // },
    },
  });

  export default CssSelectField;