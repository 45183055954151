import { Close } from '@material-ui/icons';
import { Box, Button, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Gateway } from "../../api/Gateway";

interface ComponentProps {
	open: boolean
	onClose: () => void
	schedulerLink: string
}

const AppModal = (props: ComponentProps) => {
	const { open, onClose, schedulerLink } = props;
	const [ranOnce, setRanOnce] = useState(false);

	let calendlyURL = `${schedulerLink}?hide_gdpr_banner=1`;

	useEffect(() => {
		const head = document.querySelector('head');
		if (open) {
			const script = document.createElement('script');
			script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
			script.setAttribute('id', 'calendly-script');
			if (head) {
				head.appendChild(script);
			}

			window.addEventListener("message", async function (e) {
				if (isCalendlyEvent(e)) {
					if (e.data.event == "calendly.event_scheduled" && ranOnce === false) {
						await Gateway.calendlyGet(e.data.payload.event.uri)
							.then((resource) => {
								console.log("Event Resource:", resource);
							});
						setRanOnce(true);
					};
				}
			});
		}
		else {
			if (head) {
				const script = head.querySelector('#calendly-script');
				if (script) {
					head.removeChild(script);
				}
			}
		}
	}, [open]);

	function isCalendlyEvent(e) {
		return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box className="box-calendly">
					<Grid container>
						<Grid item xs={11} display='flex' alignItems='center'>
							<Typography variant='h5' component='h5'>Schedule Consultation</Typography>
						</Grid>
						<Grid item xs={1} >
							<IconButton onClick={onClose} >
								<Close />
							</IconButton>
						</Grid>
					</Grid>
					<div className="calendly-inline-widget" data-url={calendlyURL} style={{ height: '700px' }}></div>
				</Box>
			</Fade>
		</Modal>)
}

export default AppModal;
