import { observer } from 'mobx-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, Divider, FormControl, FormControlLabel, Grid } from '@mui/material';

import Item from '../../../Item';
import CssTextField from '../../../CssTextField';

import { useMainStoreContext } from '../../../../stores/MainStore';
import { GlobalUtils } from '../../../../api/GlobalUtils';

const OTHER = 16;

export const PrimaryTherapy = observer(() => {
    const {
        primaryThearapy,
        setPrimaryThearapy,
        primaryThearapyNotes,
        setPrimaryThearapyNotes,
        primaryBitwise,
        setPrimaryBitwise,
        primaryData,
    } = useMainStoreContext().patientTreatmentStore;

    const complainsChange = (event: ChangeEvent<HTMLInputElement>, options: number) => {
        let value = primaryBitwise;
        if (event.target.checked) {
            value = value | options;
        } else {
            value = value & ~options;
        }
        setPrimaryBitwise(value);

        if (event.target.checked) {
            setPrimaryThearapy([
                ...primaryThearapy,
                {
                    id: options,
                    name: event.target.name,
                    planId: Number(event.target.value),
                },
            ]);
        } else {
            setPrimaryThearapy(primaryThearapy.filter((item: any) => item.id !== options));
        }
    };

    const optionChecked = (options: number, option: number): boolean => {
        return (options & option) === option;
    };

    return (
        <Grid>
            <Grid container xs={12} paddingLeft={4} columnSpacing={0} className="formBasics" marginTop={5}>
                <Grid container direction={'row'} className="formSectionLabel">
                    Primary Therapy
                </Grid>

                <Divider variant="middle" />

                <FormControl>
                    <Item>
                        <Grid
                            container
                            display="grid"
                            gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
                        >
                            {primaryData.map((item, index) => (
                                <Grid item key={index}>
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={optionChecked(primaryBitwise, Number(item.value))}
                                                onChange={event => {
                                                    complainsChange(event, Number(item.value));
                                                }}
                                                name={item.name}
                                                color="primary"
                                                value={item.id}
                                            />
                                        }
                                        label={item.name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Item>
                </FormControl>
            </Grid>

            <Grid
                container
                xs={12}
                direction="row"
                paddingLeft={4}
                columnSpacing={0}
                className="formBasics"
                marginTop={2}
            >
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                disabled={!optionChecked(primaryBitwise, OTHER)}
                                placeholder="Other Primary Therapy Notes"
                                name=""
                                required={false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setPrimaryThearapyNotes(event.target.value);
                                }}
                                value={primaryThearapyNotes}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
});
