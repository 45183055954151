import { observer } from 'mobx-react';
import { FC } from 'react';
import { Checkbox, Divider, FormControl, FormControlLabel, Grid } from '@mui/material';

import Item from '../../../Item';
import CssTextField from '../../../CssTextField';

import { useMainStoreContext } from '../../../../stores/MainStore';

interface IDiagnosisPage {
    patient?: any;
}

export const SleepConditions: FC<IDiagnosisPage> = observer(() => {
    const { sleepDiagnosis, setSleepDiagnosis, sleepNotesDiagnosis, setSleepNotesDiagnosis, sleepCondition } =
        useMainStoreContext().patientTreatmentStore;

    return (
        <Grid>
            <Grid
                container
                xs={12}
                direction="row"
                paddingLeft={4}
                columnSpacing={0}
                className="formBasics"
                marginTop={5}
            >
                <Grid container direction={'row'} className="formSectionLabel">
                    Sleep Conditions
                </Grid>

                <Divider variant="middle" />

                <FormControl>
                    <Item>
                        <Grid
                            container
                            display="grid"
                            gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
                        >
                            {sleepCondition.map(item => {
                                return (
                                    <Grid item key={item.id}>
                                        <FormControlLabel
                                            key={item.id}
                                            control={
                                                <Checkbox
                                                    checked={sleepDiagnosis.some(el => el.conditionId === item.id)}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setSleepDiagnosis([
                                                                ...sleepDiagnosis,
                                                                {
                                                                    id: item.id,
                                                                    name: item.name,
                                                                    conditionId: item.id,
                                                                    listItemKey: item.listItemKey,
                                                                    treatmentObjective: 499,
                                                                    treatmentObjectiveName: 'N/A',
                                                                    treatmentPlan: [],
                                                                    treatmentOptionIds: 0,
                                                                },
                                                            ]);
                                                        } else {
                                                            setSleepDiagnosis(
                                                                sleepDiagnosis.filter(
                                                                    (el: any) => el.conditionId !== item.id,
                                                                ),
                                                            );
                                                        }
                                                    }}
                                                    name={item.listItemKey}
                                                    color="primary"
                                                />
                                            }
                                            label={item.name}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Item>
                </FormControl>
            </Grid>
            <Grid
                container
                xs={12}
                direction="row"
                paddingLeft={4}
                columnSpacing={0}
                className="formBasics"
                marginTop={2}
            >
                <Grid container xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                        <Item>
                            <CssTextField
                                variant="outlined"
                                className="TextBox"
                                autoComplete="new-password"
                                size="medium"
                                disabled={!sleepDiagnosis.some(el => el.conditionId === 244)}
                                placeholder="Other Sleep Disorders Notes"
                                required={false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSleepNotesDiagnosis(event.target.value);
                                }}
                                value={sleepNotesDiagnosis}
                            />
                        </Item>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
});
