import { makeAutoObservable } from "mobx";
import { MainStore } from "./MainStore";

export default class ClaimStore{
    mainStore: MainStore;
    triggerShowClaimModal: boolean = false;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
    }
    
    setTriggerShowClaimModal = (value: boolean) => {
        this.triggerShowClaimModal = value;
    };
}
