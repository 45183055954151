import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { DiagnosisFromCBCT } from './DiagnosisFromCBCT';
import { ExtraoralObjectives } from './ExtraoralObjectives';
import { IntraoralObjectives } from './IntraoralConditions';
import { SleepObjectives } from './SleepObjectives';
import { useMainStoreContext } from '../../../../stores/MainStore';
import { toJS } from 'mobx';

interface ITreatmentObjectivesPage {
    patient?: any;
}

export const TreatmentObjectivesPage: FC<ITreatmentObjectivesPage> = observer(({ patient }) => {
    const {
        sleepDiagnosis,
        extraoralDiagnosis,
        intraoralDiagnosis,
        cbctDiagnosis,
        molarRelationshipsRight,
        molarRelationshipsLeft,
    } = useMainStoreContext().patientTreatmentStore;

    return (
        <Grid>
            <Grid paddingLeft={4} marginTop={5}>
                <Grid>{sleepDiagnosis.length > 0 && <SleepObjectives />}</Grid>

                <Grid>{extraoralDiagnosis.length > 0 && <ExtraoralObjectives />}</Grid>

                <Grid>
                    {(intraoralDiagnosis.length > 0 ||
                        molarRelationshipsRight.length > 0 ||
                        molarRelationshipsLeft.length > 0) && <IntraoralObjectives />}
                </Grid>

                <Grid>{cbctDiagnosis.length > 0 && <DiagnosisFromCBCT />}</Grid>
            </Grid>
        </Grid>
    );
});
