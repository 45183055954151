import react, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Typography, TextField, MenuItem } from "@mui/material";
import { useMainStoreContext } from "../../../../stores/MainStore";
import { observer } from "mobx-react";
import { boxStyles } from "../styles";
import 'react-dropzone-uploader/dist/styles.css'
import { RxAppliances, VivosAppliances } from "../../../../entities/Enums";
import React from "react";
import _ from "lodash";

const styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
    },
    vivosButton: {
        height: '100px',
        marginBottom: '-30px',
        marginTop: '-30px'
    }
}));
const StepFour = () => {

    const classes = styles();
    const boxClasses = boxStyles();
    const { currentPatient, selectedLab, selectedAppliance, fabricationTime, selectedVerticalThickness, selectedCustomThickness, selectedBiteLocation, verticalThickness, unilateralBiteBlock ,isUpdating,
        setStep, setSelectedVerticalThickness, setSelectedApplianceType, setSelectedBiteLocation, loadVidaConstructionValues, setConstructionAttributes, setSelectedCustomThickness } = useMainStoreContext().prescriptionStore;
    
    const { selectedAllergyList } = useMainStoreContext().patientEditStore;
    const [isCustomVertical, setIsCustomVertical] = react.useState(false);

    useEffect(() => {
        if (isUpdating) {
            loadVidaConstructionValues().then(() => {
                setConstructionAttributes();
            });
        }
        setIsCustomVertical(selectedVerticalThickness?.listItemKey === 'CUSTOMVERTICAL' ? true : false);
        setSelectedApplianceType(VivosAppliances["Vida-Pod"]);
    }, []);

    const handleBack = (): void => {
        setStep(0);
    };
    const handleNext = (): void => {
        setStep(4);
    };
    const handleVerticalThicknessChange = (event: any) => {
        let result = verticalThickness.find(x => x.id === event.target.value);
        setSelectedVerticalThickness(result);
        setIsCustomVertical(result.listItemKey === 'CUSTOMVERTICAL' ? true : false);
    };

    const handleBiteLocationChange = (event: any) => {
        setSelectedBiteLocation(unilateralBiteBlock.find(x => x.id === event.target.value));
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    {(selectedAppliance === RxAppliances.VIDA) && <Box className={boxClasses.leftBox}>
                        <Typography variant="h6">
                            Vertical Thickness to be based on
                        </Typography>

                        <TextField
                            id="GuideSelection"
                            fullWidth
                            value={selectedVerticalThickness.id}
                            onChange={handleVerticalThicknessChange}
                            select>
                            {verticalThickness.map((vg) => (
                                <MenuItem key={vg.id} value={vg.id}>
                                    {vg.name}
                                </MenuItem>
                            ))}
                        </TextField> 
                        {isCustomVertical && 
                        <Grid>
                            <Typography variant="h6">
                                Custom thickness (mm)
                            </Typography>
                            <TextField
                                variant="outlined"
                                type="number"
                                size="small"
                                placeholder=""
                                required={true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSelectedCustomThickness(event.target.value);
                                }}
                                value={selectedCustomThickness}
                            /> 
                            <Grid>Standard thickness is 5mm </Grid>
                        </Grid>}
                        <Typography variant="h6">
                            Unilateral BiteBlock Location
                        </Typography>
                        <TextField
                            id="ColorSelection"
                            fullWidth
                            value={selectedBiteLocation?.id}
                            onChange={handleBiteLocationChange}
                            select>
                            {unilateralBiteBlock.map((vg) => (
                                <MenuItem key={vg.id} value={vg.id}>
                                    {vg.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>}
                </Grid>
                <Grid item xs={4}>
                    <Box className={boxClasses.rightBox} display='flex' flexDirection='column'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    RX Overview
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Patient:</b> {currentPatient.firstName} {currentPatient.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Allergies:</b> {selectedAllergyList.join(', ')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Appliance:</b> {selectedAppliance}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Lab:</b> {selectedLab.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Fabrication Time:</b> {fabricationTime}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Teeth Charting:</b> Confirmed
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box alignSelf='flex-end' sx={{ textAlign: 'center', width: '100%', justifyItems: 'center' }}>
                            <Button variant="contained" color="primary" onClick={handleBack} sx={{ borderRadius: '30px', margin: '20px' }}
                                disabled={isUpdating}>
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                disabled={_.isUndefined(selectedVerticalThickness?.id) || _.isUndefined(selectedBiteLocation?.id)}
                                sx={{ borderRadius: '30px', margin: '20px' }}>
                                Save + Continue
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}


export default observer(StepFour);