import MyoCorrectSessionEvaluation from "./MyoCorrectSessionEvaluation";

interface IMyoCorrectSession {
    id: number,
    patientId: number, 
    patientRegistrationId: number, 
    sessionIndexNumber: number, 
    appointmentType: string, 
    evaluationType: number, 
    scheduleStatus: string, 
    scheduleDateTime: Date, 
    assignedTherapistId: number,
    sessionEvaluation: MyoCorrectSessionEvaluation,
    additionalNotes: string,
    sessionNotesPdf: string,
    sessionNotesCreatedOn: Date,
    oldNotesUrl: string,
}

interface MyoCorrectSession extends IMyoCorrectSession{}

class MyoCorrectSession{
    constructor({id, patientId, patientRegistrationId, sessionIndexNumber, 
        appointmentType, evaluationType, scheduleStatus, scheduleDateTime, assignedTherapistId,
        sessionEvaluation, additionalNotes, sessionNotesPdf, sessionNotesCreatedOn, oldNotesUrl}: IMyoCorrectSession) {
        this.id = id;
        this.patientId = patientId;
        this.patientRegistrationId = patientRegistrationId;
        this.sessionIndexNumber = sessionIndexNumber;
        this.evaluationType = evaluationType;
        this.appointmentType = appointmentType;
        this.scheduleStatus = scheduleStatus;
        this.scheduleDateTime = scheduleDateTime;
        this.assignedTherapistId = assignedTherapistId;
        this.sessionEvaluation = sessionEvaluation;
        this.additionalNotes = additionalNotes;
        this.sessionNotesPdf = sessionNotesPdf;
        this.sessionNotesCreatedOn = sessionNotesCreatedOn;
        this.oldNotesUrl = oldNotesUrl;
    }
    toObject(): IMyoCorrectSession {
        return {...this};
    }
}

export default MyoCorrectSession;