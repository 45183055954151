import { Box, Grid, Typography } from '@mui/material';

import { FC, useEffect, useState } from 'react';
import { Gateway } from '../../../../api/Gateway';
import { useMainStoreContext } from '../../../../stores/MainStore';

interface ComponentProps {
    schedulerLink: string;
}

export const P2PCalendlyStep: FC<ComponentProps> = ({ schedulerLink }) => {
    const { setScheduleDateTime, setCalendlyUri, setCalendlyRescheduleLink } =
        useMainStoreContext().calendlyConsultationsStore;

    let calendlyURL = `${schedulerLink}?hide_gdpr_banner=1`;
    const [ranOnce, setRanOnce] = useState(false);

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        script.setAttribute('id', 'calendly-script');
        if (head) {
            head.appendChild(script);
        }
    }, []);

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        script.setAttribute('id', 'calendly-script');
        if (head) {
            head.appendChild(script);
        }

        window.addEventListener('message', async function (e) {
            if (isCalendlyEvent(e)) {
                if (e.data.event === 'calendly.event_scheduled' && ranOnce === false) {
                    await Gateway.calendlyGet(e.data.payload.event.uri).then(resource => {
                        setScheduleDateTime(resource['start_time']);
                        setCalendlyUri(resource['uri']);
                        setCalendlyRescheduleLink(resource['reschedule_url']);
                    });
                    setRanOnce(true);
                }
            }
        });
    }, []);

    function isCalendlyEvent(e) {
        return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
    }

    return (
        <Box className="box-calendlyInModal">
            <Grid>
                <Grid item xs={11} display="flex" alignItems="center">
                    <Typography variant="h5" component="h5">
                        Schedule Consultation
                    </Typography>
                </Grid>
                <div className="calendly-inline-widget" data-url={calendlyURL} style={{ height: '700px' }} />
            </Grid>
        </Box>
    );
};
