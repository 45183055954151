import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import * as _ from 'lodash';

import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';
import EditPatient from '../../entities/EditPatient';
import LegalGuardianData from '../../entities/LegalGuardian';
import PatientHistory from '../../entities/PatientHistory';
import ProviderPatient from '../../entities/ProviderPatient';

export default class PatientEditBL {
    @observable private _patientData: ProviderPatient = {} as ProviderPatient;
    @observable private _patientMyocorrectRegistration = {};
    @observable private _patientId = 0;
    @observable private _addresses: any[] = [];
    @observable private _legalGuardians: any[] = [];
    @observable private _patientHistory: any = {};
    @observable private _activeTab = 0;
    @observable private _isEditMode = false;
    @observable private _isOpenModal = false;

    isloading = false;
    userID: any;
    roles: any;
    providerId: any;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; providerId } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
            this.providerId = tokenData.providerId;
        }
        this.clearPatient();
    }

    firstName = '';
    middleName = '';
    lastName = '';
    dateOfBirth = new Date(2022, 12, 12, 12, 12, 12, 0);
    gender = 22;

    setisloading(value: boolean) {
        this.isloading = value;
    }

    get patientData() {
        return this._patientData;
    }

    @action
    set patientData(value: any) {
        this._patientData = value;
    }

    get patientMyocorrectRegistration() {
        return this._patientMyocorrectRegistration;
    }

    @action
    set patientMyocorrectRegistration(value: any) {
        this._patientMyocorrectRegistration = value;
    }

    get patientId() {
        return this._patientId;
    }

    @action
    set patientId(value: any) {
        this._patientId = value;
    }

    get addresses() {
        return this._addresses;
    }

    @action
    set addresses(value: any) {
        this._addresses = value;
    }

    get patientHistory(): PatientHistory {
        return this._patientHistory;
    }

    @action
    set patientHistory(value: PatientHistory) {
        this._patientHistory = value;
    }

    get legalGuardians() {
        return this._legalGuardians;
    }

    @action
    set legalGuardians(value: any) {
        this._legalGuardians = value;
    }

    get activeTab() {
        return this._activeTab;
    }

    @action
    set activeTab(value: number) {
        this._activeTab = value;
    }

    get isEditMode() {
        return this._isEditMode;
    }

    @action
    set isEditMode(value: boolean) {
        this._isEditMode = value;
    }

    get isOpenModal() {
        return this._isOpenModal;
    }

    @action
    set isOpenModal(value: boolean) {
        this._isOpenModal = value;
    }

    setToggleOnEdit(value: boolean) {
        this._isEditMode = !this._isEditMode;
    }

    @action
    handleClickModal = () => {
        this.isOpenModal = !this.isOpenModal;
    };

    handleCloseModal = () => {
        this.isOpenModal = false;
    };

    setPatientId = () => {
        this.patientId = this.patientData.id || this.patientData.personId;

        return this.patientId;
    };

    @action
    loadDetails = async () => {
        this.patientId = this.patientData.personId || this.patientData.id;
        var url = '/patient/' + this.patientId + '/details';
        await Gateway.get(url).then(data => {
            var patient = data as unknown as EditPatient;
            this._addresses = patient.addresses;
            this._legalGuardians = patient.legalGuardians;
            this._patientHistory = patient.patientHistory;
        });
    };

    @action
    loadAllReports = async (): Promise<any> => {
        var url = '/aireports/patient/' + this.patientData.id;
        return await Gateway.get(url);
    };

    @action
    loadMyocorrectRegistration = async (id: number): Promise<any> => {
        await Gateway.get(`myocorrect/patients/registration/${id}`).then(data => {
            this._patientMyocorrectRegistration = data;
        });
    };

    @action
    getItemFromList = (list: any[], id: string, comparetor: number, responseField: string) => {
        var item = _.findIndex(list, f => {
            return f[id] == comparetor;
        });
        return item >= 0 ? list[item][responseField] : '';
    };

    @action
    setActiveTab = (index: number) => {
        this.activeTab = index;
    };

    @action
    onEditChange = (event: any) => {
        this.isEditMode = event.target.checked;
    };

    @action
    onEdit = async () => {
        var url = 'patient/' + this.patientData.id;
        this.patientData.addresses = this.addresses;
        this.setToggleOnEdit(false);
        await Gateway.post(url, this.patientData).then();
    };

    @action
    healthOptionExists = (options: number, option: number): boolean => {
        return (options | option) === 1 ? true : false;
    };

    @action
    onSaveGuardians = () => { };

    @action
    onGuardianDelete = (id: number) => { };

    @action
    onGuardianAdd = (guardianToAdd: LegalGuardianData) => { };

    @action
    clearPatient = () => {
        this.patientData = null;
        this.legalGuardians = [];
        this.addresses = [];
    };

    @action
    sendCustomForm = async (patientForms: any) => {
        this.setisloading(true);
        var url = `Patient/${patientForms.patientId}/customforms/send`;

        await Gateway.post(url, patientForms).then(res => {
            runInAction(() => {
                this.setisloading(false);
            });
        });
    };
}
