import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import CssTextField from "./CssTextField";
import moment from "moment";
import Item from "./Item";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Close } from "@mui/icons-material";


interface IDTProps {
    title: string;
    currentObj: any;
    fieldName: string;
    isOpen: boolean;
    onSave(value: Date): any;
    onClose: (onClose: boolean) => void;
}

    export const DateTimeEditModal: React.FC<IDTProps> = observer(
        ({ isOpen, onClose, title, onSave, currentObj, fieldName }) => {
            const handleOnClick = () => {
                onClose(false);
            };

            return (
                <Dialog open={isOpen}>
                    <DialogTitle id='form-dialog-title' className='Title'>
                        <Box display='flex' alignItems='center'>
                            <Box
                                sx={{
                                    height: '30px'
                                }}
                                flexGrow={1}
                                fontSize='24px'
                                fontWeight={900}
                                fontFamily='Proxima Nova'
                            >
                                {title}
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={handleOnClick}
                                    sx={{
                                        fontSize: 25,
                                        backgroundColor: 'transparent',
                                        color: 'blue'
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Grid xs className={"formBasics"} sx={{ margin: "25px" }}>
                            <Grid xs>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid sx={{color:"red", paddingBottom:"10px"}}>NOTE -- Manually changing the Session Date and Time will NOT be reflected in your external calendars.</Grid></Grid>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                onChange={(newValue) => {
                                                    if (newValue != null) {
                                                        currentObj[fieldName] = moment(newValue).toDate();
                                                    };
                                                }}
                                                value={moment.utc(currentObj[fieldName]).toDate()}
                                                renderInput={(params) => <CssTextField {...params} sx={{
                                                    '& input': {
                                                        backgroundColor: 'white',
                                                        color: '#050505',
                                                        fontFamily: 'Proxima Nova',
                                                        fontSize: '16px'
                                                    }
                                                }} className="TextBox" size="small" value={currentObj[fieldName]} />}
                                            >

                                            </DateTimePicker>
                                        </LocalizationProvider>
                                    </Item>
                                </FormControl>
                            </Grid>

                            <Grid container direction="row" xs justifyContent={"flex-end"} sx={{padding:"15px"}}>
                                <Grid><Button className="vivButton large invert" onClick={handleOnClick}>Cancel</Button></Grid>
                                <Grid><Button className="vivButton large invert" onClick={() => { onSave(moment(currentObj[fieldName]).toDate()) }}>Save</Button></Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog >
            )
        }
    );