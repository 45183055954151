// @vendors
import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { areEqual } from 'react-window';

// @components
import { CardProvider } from './CardProvider';

// interface
import { Provider } from '../../../entities/Provider';

type RowProps = {
    data: Provider[];
    index: number;
    style: any;
};

export const RowList = React.memo(({ data, index, style }: RowProps) => {
    const rows: Provider = data[index];

    if (!rows) {
        return null;
    }

    const patchedStyle = {
        ...style,
        left: style.left + 8,
        top: style.top + 8,
        width: `calc(${style.width} - ${8 * 2}px)`,
        height: style.height - 8,
    };

    return (
        <Draggable draggableId={String(rows.id || rows.providerID)} index={index} key={rows.id || rows.providerID}>
            {(provided: DraggableProvided, snapshot) => (
                <CardProvider item={rows} provided={provided} style={patchedStyle} />
            )}
        </Draggable>
    );
}, areEqual);
