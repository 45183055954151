import { MainStore } from './MainStore';
import { observable, action,  makeObservable, computed } from 'mobx';
import Team from '../entities/Team';

const defaultTeam={
    id: 0,
    organizationId: 0,
    name: '',
    description: '',
    isActive: true
}

export default class CreateTeamStore {
    mainStore: MainStore;
    constructor(mainstore: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
    }

    @observable newTeam = new Team(defaultTeam);
    
}