import { Component, useState, ChangeEvent } from 'react';
import React from 'react';
import {
    Button,
    Grid,
    IconButton,
    styled,
    Box,
    Avatar,
    Typography,
    Card,
    Divider,
    CardMedia,
    Tabs,
    Tab,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Checkbox,
    FormControlLabel,

} from '@mui/material';
import { Close } from '@mui/icons-material';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as _ from 'lodash';


import MyoCorrectPatient from '../../../entities/MyCorrectPatient';
import PatientEditBL from '../PatientEditBL';
import '../../../scss/_PatientEdit.scss';
import '../../../scss/_global.scss';
import { Ethnicity, Gender } from '../../../entities/Enums';
import { PatientCustomFormModal } from '../PatientModalCustomForm';
import { GlobalUtils } from '../../../api/GlobalUtils';
import { CommentsPanel } from '../../CommentsPanel/CommentsPanel';
import CommentsEditStore from '../../CommentsPanel/CommentsEditStore';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';
import { useDropzone } from 'react-dropzone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);
const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const RootWrapper = styled(Box)(
    ({ theme }) => `
          margin-top: ${theme.spacing(0)};
          margin-bottom: ${theme.spacing(3)};
          background: ${theme.colors.gradients.black2};
          border-radius: ${theme.general.borderRadius};
  `
);

// background: ${theme.colors.gradients.blue2};

// const RootWrapper = styled(Box)(
//     ({ theme }) => `
//           margin-top: ${theme.spacing(0)};
//           background: ${theme.colors.gradients.purple1};
//           padding: ${theme.spacing(3)};
//           border-radius: ${theme.general.borderRadius};
//           @media (min-width: ${theme.breakpoints.values.lg}px) {
//             height: ${theme.spacing(38)};
//             margin-bottom: ${theme.spacing(13)};
//           }
//   `
// );

const TabsContainerWrapper = styled(Box)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(2)};
      justifyContent: 'space-evenly',
    `
);

// const [currentTab, setCurrentTab] = useState<string>('general');

// const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
//     setCurrentTab(value);
// };

interface IGIProps {
    patient: MyoCorrectPatient;
    tabs: any;
    activeTab: number;
    onClick(index: number);
    onClose();
    isClinicalAdvocate: boolean;
}

// const [toggleUploadModal, setToggleUploadModal] = React.useState(false);

@observer
export class PatientHeaderTwo extends Component<IGIProps> {
    headerBL = new PatientEditBL();
    commentsEdit = new CommentsEditStore();
    activeTab: number = 0;

    state = {
        toggleUploadModal: false,
    };

    openDialog = () => {
        this.setState({ toggleUploadModal: true });
    };

    closeDialog = () => {
        this.setState({ toggleUploadModal: false });
    }


    handleTabChange = (_event: ChangeEvent<{}>, newValue): void => {
        // Handle the tab click event here, for example, call this.props.onClick(newValue)
        this.props.onClick(newValue);
    }



    render() {
        return (
            <RootWrapper>
                <Grid container justifyContent="right">
                    <IconButton className="vivButton invert" onClick={this.props.onClose}>
                        <Close />
                    </IconButton>
                </Grid>
                <Grid container spacing={3} px={4}>
                    <Grid item xs={1} height={165} alignItems="center" >
                        <Box display="flex" flex='2' alignItems="center" ml={3}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: 134,
                                    height: 134
                                }}
                                src={this.props.patient.profileImageUrl}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={11}>
                        <Grid container>
                            <Grid item xs={10} direction={'column'}>
                                <Grid container direction={'row'} spacing={1}>
                                    <Grid item>
                                        <Typography variant="h5" ml={3} color={'white'}>
                                            {this.props.patient.firstName.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" ml={3}>
                                            {this.props.patient.middleName
                                                ? this.props.patient.middleName.toUpperCase()
                                                : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Typography gutterBottom variant="h3" ml={3} color={'white'}>
                                    {this.props.patient.lastName.toUpperCase()}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} direction={'column'}>
                                {/* <ButtonWrapper>
                                    <Button startIcon={<CommentTwoToneIcon />} variant="contained" >
                                        'Patient Settings'
                                    </Button>
                                </ButtonWrapper>
                                <Button
                                    startIcon={<CommentTwoToneIcon />}
                                    variant="contained"
                                    sx={{
                                        width: '100%', textAlign: 'left', mb: 1,
                                        mx: 2
                                    }}
                                >
                                    'Print Reports'
                                </Button> */}
                                <Button
                                    startIcon={<CommentTwoToneIcon />}
                                    variant="contained"
                                    onClick={this.commentsEdit.handleClickModal}
                                    sx={{
                                        width: '85%', textAlign: 'left', mb: 1,
                                        mx: 2
                                    }}
                                >
                                    Activity Summary
                                </Button>
                                <Button
                                    startIcon={<DynamicFormTwoToneIcon />}
                                    variant="contained"
                                    onClick={this.headerBL.handleClickModal}
                                    sx={{
                                        width: '85%', textAlign: 'left', mb: 1,
                                        mx: 2
                                    }}
                                >
                                    Send Forms
                                </Button>
                                <Button
                                    startIcon={<DynamicFormTwoToneIcon />}
                                    variant="contained"
                                    onClick={this.openDialog}
                                    sx={{
                                        width: '85%', textAlign: 'left', mb: 1,
                                        mx: 2
                                    }}
                                >
                                    Upload Vivos Forms
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TabsContainerWrapper>
                            <Card sx={{
                                p: 1
                            }}>
                                <Grid container justifyContent="space-evenly">

                                    <Tabs
                                        onChange={this.handleTabChange}
                                        value={this.props.activeTab}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                    >
                                        {_.map(this.props.tabs, (t, key: number) => (
                                            <Tab key={key} label={this.props.tabs[key]} />
                                        ))}
                                    </Tabs>

                                </Grid>
                            </Card>
                        </TabsContainerWrapper>
                    </Grid>

                    <PatientCustomFormModal
                        isOpen={this.headerBL.isOpenModal}
                        onClose={this.headerBL.handleCloseModal.bind(this)}
                        patientId={this.props.patient.id}
                        providerId={this.headerBL.providerId}
                    />

                    <CommentsPanel
                        significantId={this.props.patient.personId}
                        isOpen={this.commentsEdit.isOpenModal}
                        onClose={this.commentsEdit.handleCloseModal.bind(this)}
                    />
                </Grid>

                <Dialog open={this.state.toggleUploadModal} onClose={this.closeDialog}>
                    <DialogTitle
                        sx={{
                            p: 3
                        }}
                    >
                        <Typography variant="h3" gutterBottom>
                            Upload Vivos Forms
                        </Typography>
                        <Typography variant="subtitle2">
                            Upload your forms and click 'Parse' to automatically populate all patient fields by having the system read the data.
                        </Typography>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        sx={{
                            p: 3,
                        }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Box width={'100%'}>
                                        <BoxUploadWrapper >


                                            <AvatarWrapper variant="rounded">
                                                <CloudUploadTwoToneIcon />
                                            </AvatarWrapper>
                                            <Typography
                                                sx={{
                                                    mt: 2
                                                }}
                                            >
                                                Drag & drop files here
                                            </Typography>


                                        </BoxUploadWrapper>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>

                        <Grid container direction="row" xs justifyContent={'space-between'}>
                            <Grid item xs={2}>
                                <Button id="cancel.form.button" variant="contained" color="inherit"
                                    onClick={this.closeDialog}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container spacing={2} justifyContent={'right'}>

                                    <Grid item>
                                        <Button
                                            id="send.form.button"
                                            variant="contained"
                                        >
                                            Parse Forms
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </RootWrapper>
        );
    }
}
