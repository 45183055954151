// @vendors
import { Checkbox, Divider, FormControl, FormControlLabel, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { ChangeEvent, FC, useEffect, useState } from 'react';

// @components
import CssTextField from '../../../CssTextField';
import Item from '../../../Item';

// @stores
import { GlobalUtils } from '../../../../api/GlobalUtils';
import { useMainStoreContext } from '../../../../stores/MainStore';

// @interfaces
import { PatientInformation } from '../../../../entities/AiReportOrder';

interface IInfo {
    topics?: number;
    questions?: [];
}

interface ITopicsAndQuestions {
    patient: PatientInformation;
    consultationsData?: any;
    isViewOnly?: boolean;
    info?: IInfo;
}

const OTHER = 1024;

export const P2PTopicsAndQuestionsStep: FC<ITopicsAndQuestions> = observer(
    ({ patient, consultationsData, isViewOnly, info }) => {
        const {
            primaryBitwise,
            setPrimaryBitwise,
            otherNotes,
            setOtherNotes,
            questions,
            setQuestions,
            setPatientId,
            setConsultantId,
        } = useMainStoreContext().calendlyConsultationsStore;

        const [primaryData, setPrimaryData] = useState<any>([]);

        useEffect(() => {
            setPatientId(patient.id);
            setConsultantId(consultationsData.clinicalAdvocate.id);
            setPrimaryData(GlobalUtils.getCalendlyTopics());
        }, []);

        const valuesChange = (event: ChangeEvent<HTMLInputElement>, options: number) => {
            let value = primaryBitwise;
            if (event.target.checked) {
                value = value | options;
            } else {
                value = value & ~options;
            }
            setPrimaryBitwise(value);
        };

        const optionChecked = (options, option): boolean => {
            return (options & option) === option;
        };

        return (
            <Grid>
                <Grid container xs={12} className="formBasics" marginTop={5}>
                    <Grid container direction={'row'} className="formSectionLabel">
                        Topics
                    </Grid>

                    <Divider variant="middle" />

                    <FormControl>
                        <Item>
                            <Grid
                                container
                                display="grid"
                                gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
                            >
                                {primaryData.map((item, index) => (
                                    <Grid item key={index}>
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    disabled={isViewOnly}
                                                    checked={optionChecked(
                                                        primaryBitwise ? primaryBitwise : info?.topics,
                                                        Number(item.value),
                                                    )}
                                                    onChange={event => {
                                                        valuesChange(event, Number(item.value));
                                                    }}
                                                    name={item.name}
                                                    color="primary"
                                                    value={item.id}
                                                />
                                            }
                                            label={item.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Item>
                    </FormControl>
                </Grid>

                <Grid
                    container
                    xs={12}
                    direction="row"
                    paddingLeft={4}
                    columnSpacing={0}
                    className="formBasics"
                    marginTop={2}
                >
                    <Grid container xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <Item>
                                <CssTextField
                                    variant="outlined"
                                    className="TextBox"
                                    autoComplete="new-password"
                                    size="medium"
                                    disabled={!optionChecked(primaryBitwise, OTHER)}
                                    placeholder="Other Notes"
                                    name=""
                                    required={false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setOtherNotes(event.target.value);
                                    }}
                                    value={otherNotes}
                                />
                            </Item>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid
                    container
                    xs={12}
                    direction="row"
                    paddingLeft={4}
                    columnSpacing={0}
                    className="formBasics"
                    marginTop={2}
                >
                    <Grid container direction={'row'} className="formSectionLabel">
                        Questions
                    </Grid>
                    <Grid container xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <Item>
                                <CssTextField
                                    disabled={isViewOnly}
                                    variant="outlined"
                                    className="TextBox"
                                    autoComplete="new-password"
                                    size="medium"
                                    multiline={true}
                                    rows={5}
                                    placeholder="Questions"
                                    name=""
                                    required={false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setQuestions(event.target.value);
                                    }}
                                    value={questions ? questions : info?.questions}
                                />
                            </Item>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        );
    },
);
