import { Close } from "@material-ui/icons";
import { Box, Fade, Grid, IconButton, Modal, Typography } from "@mui/material"
import Backdrop from '@mui/material/Backdrop';
import moment from "moment";
import React from "react";
import * as _ from "lodash";
import { useEffect } from "react";
import { Gateway } from "../../api/Gateway";
import { useMainStoreContext } from "../../stores/MainStore";

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1100,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
};

interface ComponentProps {
	open: boolean
	onClose: () => void;
	loadData: () => void
}

const AppModal = (props: ComponentProps) => {
	const { open, onClose, loadData } = props;

	const { schedulerLink, selectedSessionNumber, previousDateReschedule } = useMainStoreContext().myoCorrectAdminStore;
	const { currentRow, saveEvent, updateEvent } = useMainStoreContext().myoCorrectStore;
	const [ranOnce, setRanOnce] = React.useState(false);

	function isCalendlyEvent(e) {
		return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
	};

	useEffect(() => {
		window.addEventListener("message", async function (e) {
			if (isCalendlyEvent(e)) {
				if (e.data.event == "calendly.event_scheduled" && ranOnce === false) {
					await Gateway.calendlyGet(e.data.payload.invitee.uri)
					.then((resource) => {
						console.log("Event Resource:", resource);
						var date = moment(resource["start_time"]).toDate();
						var time = moment(resource["start_time"]).format("hh:mm:ss");
						var question = _.find(resource['questions_and_answers'], (r) => {
							return(r.question.indexOf("VivosId") === 0)
						})
						saveEvent(question.answer, date, time, resource['email'], resource['tracking']['utm_term'], "",""); 
					});
					setRanOnce(true);
				};
			}
		});
	}, []);

	let calendlyURL = `${schedulerLink}?hide_gdpr_banner=1`;
	calendlyURL += `&name=${currentRow.firstName}%20${currentRow.lastName}`;
	calendlyURL += `&email=${currentRow.emailAddress}`;
	calendlyURL += `&a1=${currentRow.vivosId}`
	calendlyURL += `&utm_term=${selectedSessionNumber}`
	calendlyURL += `&utm_source='reschedule'`

	useEffect(() => {
		const head = document.querySelector('head');
		if (open) {
			const script = document.createElement('script');
			script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
			script.setAttribute('id', 'calendly-script');
			if (head) {
				head.appendChild(script);
			}
		}
		else {
			if (head) {
				const script = head.querySelector('#calendly-script');
				if (script) {
					head.removeChild(script);
				}
			}
		}
	}, [open]);

	const closeModal = async () => {
		loadData();
		onClose();
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box className="box-calendly">
					<Grid container>
						<Grid item xs={11} display='flex' alignItems='center'>
							<Typography variant='h5' component='h5'>
								{previousDateReschedule ? `Reschedule Session ${selectedSessionNumber} - ${previousDateReschedule}` : `Schedule Session ${selectedSessionNumber}`}
							</Typography>
						</Grid>
						<Grid item xs={1} >
							<IconButton onClick={closeModal} >
								<Close />
							</IconButton>
						</Grid>
					</Grid>
					<div className="calendly-inline-widget" data-url={calendlyURL} style={{ height: '700px' }}></div>
				</Box>
			</Fade>
		</Modal>)
}

export default AppModal;