import { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import '../../scss/_GridHeader.scss';
import close from '../../imgs/tmp/bigx.png';
import * as _ from 'lodash';
import { AppBar, Toolbar, IconButton, Typography, Button, Grid, FormControlLabel, Radio } from '@mui/material';
import { FilterOption } from '../FilterOpton';
import SearchIcon from '@mui/icons-material/Search';
import CssTextField from "../CssTextField";
import { GridHeaderBL } from './GridHeaderBL';

interface GridHeaderProps {
    title: string;
    filterClass: string;
    numResults: number;
    buttons: JSX.Element[];
    onChange(value: string): any;
    onClear(): any;
    onCancel(): any;
    onFilter(filters: any): any;
}

@observer
export class GridHeader extends Component<GridHeaderProps> {
    @observable store = new GridHeaderBL();
    k = 0;

    constructor(props: any) {
        super(props);
        makeObservable(this);
        this.store.searchText = createRef();
    }

    render() {
        var prospectiveStatus: any = [];
        prospectiveStatus.push(<Grid key="1" container direction="row"><FormControlLabel value="1" control={<Radio />} label="Prospective" /></Grid>);
        prospectiveStatus.push(<Grid key="2" container direction="row"><FormControlLabel value="2" control={<Radio />} label="Non Prospective" /></Grid>);



        var treatmentStatusRdoOptions: any = [];
        treatmentStatusRdoOptions.push(<Grid key="0" container direction="row"><FormControlLabel value="0" control={<Radio />} label="New" /></Grid>);
        treatmentStatusRdoOptions.push(<Grid key="1" container direction="row"><FormControlLabel value="1" control={<Radio />} label="Active" /></Grid>);
        treatmentStatusRdoOptions.push(<Grid key="2" container direction="row"><FormControlLabel value="2" control={<Radio />} label="Complete" /></Grid>);

        var aiReportStatusRdoOptions: any = [];
        aiReportStatusRdoOptions.push(<Grid key="4" container direction="row"><FormControlLabel value="0" control={<Radio />} label="Not Requested" /></Grid>);
        aiReportStatusRdoOptions.push(<Grid key="5" container direction="row"><FormControlLabel value="1" control={<Radio />} label="Requested" /> </Grid>);
        aiReportStatusRdoOptions.push(<Grid key="6" container direction="row"><FormControlLabel value="2" control={<Radio />} label="Technician Review" /></Grid>);
        aiReportStatusRdoOptions.push(<Grid key="7" container direction="row"><FormControlLabel value="3" control={<Radio />} label="Complete" /></Grid>);

        var myoCorrectOptions: any = [];
        myoCorrectOptions.push(<Grid key="7" container direction="row"><FormControlLabel value="11" control={<Radio />} label="Not Applicable" /></Grid>);
        myoCorrectOptions.push(<Grid key="9" container direction="row"><FormControlLabel value="7" control={<Radio />} label="Enrolled" /></Grid>);
        myoCorrectOptions.push(<Grid key="10" container direction="row"><FormControlLabel value="9" control={<Radio />} label="In Progress" /></Grid>);
        myoCorrectOptions.push(<Grid key="12" container direction="row"><FormControlLabel value="102" control={<Radio />} label="Refunded" /></Grid>);
        myoCorrectOptions.push(<Grid key="13" container direction="row"><FormControlLabel value="101" control={<Radio />} label="Cancelled" /></Grid>);
        myoCorrectOptions.push(<Grid key="14" container direction="row"><FormControlLabel value="10" control={<Radio />} label="Complete" /></Grid>);

        var buttons = _.map(this.props.buttons, (b, key) => {
            return <Grid key={key}>{b}</Grid>
        })
        return (
            <Grid container direction="column" alignItems="center" className="gridHeader" xl={12}>
                <Grid container className="placeholder" alignItems="center" alignContent="center" xl={12}>
                    <AppBar position="static" className="modalHeader" color="inherit">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="title">
                                {this.props.title.toUpperCase()}
                            </Typography>
                            {buttons}
                            <div className='seperator'></div>
                            <IconButton className="icon" size="large" onClick={this.store.expandSearch.bind(this)}><SearchIcon fontSize="inherit" className="filled" /></IconButton>
                        </Toolbar>
                    </AppBar>
                </Grid>
                <Grid container direction="row" alignItems={"center"} justifyContent="space-between" className={"searchHeader" + (this.store.searchHeaderExpanded === true ? " expanded" : "")}>
                    <Grid item md={1} className="searchImg"> <IconButton className="icon" size="large" onClick={this.store.expandSearch.bind(this)}><SearchIcon fontSize="inherit" className="filled" /></IconButton> </Grid>
                    <Grid item md={7} className="searchTextDiv" alignSelf="center">
                        <CssTextField ref={this.store.searchText} className="searchText" value={this.store.textSearch} sx={{ padding: "0px", lineHeight: "30px", font: "$brand_font_xl_italic" }} placeholder="SEARCH (Name, ID)"
                            onChange={(event) => {
                                this.props.onChange(event.target.value);
                                this.store.onChange(event);
                            }} /></Grid>
                    <Grid item md={2} className="clearResults"
                        onClick={() => {
                            this.store.clearTextInput();
                            this.props.onClear()
                        }
                        }>CLEAR RESULTS </Grid>
                    <Grid item md={1} className="close"><img src={close} alt="Search" height="24px" width="24px" onClick={this.store.expandSearch.bind(this)} /></Grid>
                </Grid>

                <Grid container direction="row" alignContent="space-between" className={this.props.filterClass}>
                    <Grid container direction="row" className="filters" xl={12}>
                        <Grid><FilterOption key={++this.k} filterName="Is Prospective" defaultValue="0" radioOptions={prospectiveStatus} onCheck={this.store.onFilterCheck.bind(this)} status={this.store.isProspectiveStatus} checked={this.store.isProspectiveChecked} onChange={this.store.onFilterChange.bind(this)} /></Grid>
                        <Grid><FilterOption key={++this.k} filterName="Treatment Status" defaultValue="0" radioOptions={treatmentStatusRdoOptions} onCheck={this.store.onFilterCheck.bind(this)} status={this.store.treatmentStatus} checked={this.store.treatmentChecked} onChange={this.store.onFilterChange.bind(this)} /></Grid>
                        <Grid><FilterOption key={++this.k} filterName="MyoCorrect Status" defaultValue="0" radioOptions={myoCorrectOptions} onCheck={this.store.onFilterCheck.bind(this)} status={this.store.myoCorrectStatus} checked={this.store.myCorrectChecked} onChange={this.store.onFilterChange.bind(this)} /></Grid>
                        <Grid><FilterOption key={++this.k} filterName="AI Report Status" defaultValue="0" radioOptions={aiReportStatusRdoOptions} onCheck={this.store.onFilterCheck.bind(this)} status={this.store.aiReportStatus} checked={this.store.aiReportChecked} onChange={this.store.onFilterChange.bind(this)} /></Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="flex-end" className="buttons" xl={12}>
                        <Grid item><Button className="vivButton green" onClick={(event) => { this.props.onFilter(this.store.onFilter(event)) }}>SHOW RESULTS ({this.props.numResults})</Button></Grid>
                        <Grid item><Button className="vivButton red" onClick={() => { this.props.onCancel(); this.store.onFilterCancel(); }}>CANCEL</Button></Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

//PATIENTS PENDING ACTION