import { Component } from 'react';
import { Button, FormControlLabel, FormGroup, Grid, IconButton, Switch } from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as _ from 'lodash';

import MyoCorrectPatient from '../../entities/MyCorrectPatient';
import PatientEditBL from '../PatientProfile/PatientEditBL';
import '../../scss/_PatientEdit.scss';
import '../../scss/_global.scss';
import { Gender, Ethnicity } from '../../entities/Enums';
import { GlobalUtils } from '../../api/GlobalUtils';

interface IGIProps {
    patient: MyoCorrectPatient;
    tabs: any;
    activeTab: number;
    onClick(index: number);
    onClose();
}

@observer
export class VisitHeader extends Component<IGIProps> {
    headerBL = new PatientEditBL();
    activeTab: number = 0;
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Grid xs={12} >
                <Grid xs={12} className="tabSection">
                    <Grid xs={11.654} className="tabs" container direction={'row'}>
                        {_.map(this.props.tabs, (t, key: number) => {
                            return (
                                <Grid
                                    key={key}
                                    className={'tab' + (this.props.activeTab === key ? ' active' : '')}
                                    onClick={() => {
                                        this.props.onClick(key);
                                    }}
                                >
                                    {t}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
