import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid, Divider, Typography, Box } from '@mui/material';
import { useMainStoreContext } from "../../stores/MainStore";
import DictionaryListItem from "../../entities/DictionaryListItem";
import UploadImgDragDrop from "../UploadDragAndDrop/UploadImgDragDrop";
import { GlobalUtils } from "../../api/GlobalUtils";

interface IProps {
    isIntake: boolean;
    isVisit: boolean;
}

const IntraoralImage: React.FC<IProps> = ({ isIntake, isVisit }) => {

    const { patient, intraoralImageType, setIntraoralImageType, patientIntraoralImages } = useMainStoreContext().createPatientStore;
    const { getVisitId, visitIntraoral2dimages, imagesToDelete } = useMainStoreContext().visitRecordStore;
    const { intraoral2dimages, vivosId } = useMainStoreContext().aiReportOrder;
    const [currentSelectedType, setCurrentSelectedType] = React.useState<DictionaryListItem>(new DictionaryListItem({
        id: 0,
        name: "",
        listItemKey: "",
        value: "",
        isActive: true,
        dictionaryListId: 0
    }));
    const [hasLoaded, setHasLoaded] = React.useState(false);

    const handleonClick = (data64string, dataPathUrl, imageType,) => {

    }

    const deleteCallback = (itemOption: number) => {
        imagesToDelete.push(itemOption);
    }

    useEffect(() => {
        setIntraoralImageType(GlobalUtils.getIntraOralImages());
        setHasLoaded(true);
    }, [setIntraoralImageType]);

    return (
        <Grid paddingBottom={10}>
            <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                Intaoral Images
            </Typography>
            <Box paddingTop={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12} container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                    <Grid xs={12} item direction="column">
                        {hasLoaded && <Grid container xs={12} direction="column">
                            <UploadImgDragDrop
                                extImages={isIntake ? patientIntraoralImages : (isVisit) ? visitIntraoral2dimages : intraoral2dimages}
                                type="intraoral" parentCallback={handleonClick}
                                typeOptions={intraoralImageType}
                                vivosId={isIntake ? patient.vivosId : vivosId}
                                visitId={getVisitId()}
                                deleteCallback={deleteCallback} />
                        </Grid>}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default observer(IntraoralImage);