
import { Component } from "react";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";

import ProviderPatient from "../../../entities/ProviderPatient";
import PatientEditBL from "../PatientEditBL";
import DataGrid from "../../DataGrid/DataGrid";
import { GridColDef } from "@mui/x-data-grid-pro";

interface IGIProps {
    patient: ProviderPatient;
}

@observer
export class PatientOpenOrders extends Component<IGIProps> {

    editBl: PatientEditBL;
    constructor(props: any) {
        super(props);
        this.editBl = new PatientEditBL();
        this.editBl.patientData = this.props.patient;
    }

    componentDidMount() {
    }

    columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Order Number', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left"
        },
        {
            field: 'orderDate', headerName: 'Order Date', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left"
        },
        {
            field: 'Description', headerName: 'Description', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left"
        },
        {
            field: 'OrderShippedOn', headerName: 'Order Shipped', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left"
        }
    ]

    render() {
        return (
            <Grid className="tabContent">
                <DataGrid
                    columns={this.columns}
                    hideFooter={false}
                    data={[]}
                />
            </Grid>
        )
    }
}
