
import React from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    Avatar,
    MenuList,
    MenuItem,
    ListItemText,
    useTheme
} from "@mui/material";
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';

const MenuListWrapperRounded = styled(MenuList)(
    ({ theme }) => `
      & .MuiMenuItem-root {
          border-radius: 50px;
          padding: ${theme.spacing(1, 1, 1, 2.5)};
          margin-bottom: 2px;
          position: relative;
          color: ${theme.colors.alpha.black[70]};
  
          &.Mui-selected,
          &:hover,
          &.MuiButtonBase-root:active {
              background: ${theme.colors.alpha.black[10]};
              color: ${theme.colors.alpha.black[100]};
          }
  
          &:last-child {
              margin-bottom: 0;
          }
        }
    `
);


function DNACard() {
    const theme = useTheme();
    return (
        <Card>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
            >
                <Box>
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                        py={5}
                        px={2}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                mx: 'auto',
                                mb: 2,
                                width: 'auto',
                                height: 100,
                                '& img': {
                                    objectFit: 'contain'
                                }
                            }}
                            src="/logos/appliances/DNA.png"
                        />
                        <Typography
                            sx={{
                                pt: 1
                            }}
                            variant="subtitle2"
                            textAlign="center"
                        >
                            The Vivos DNA® has 510(k) clearance as a Class II medical device for the treatment
                            of snoring, mild-moderate sleep OSA in adults, and jaw repositioning.
                        </Typography>
                    </Box>
                    <MenuListWrapperRounded
                        disablePadding
                        sx={{
                            mb: 3,
                            mx: 3
                        }}
                    >
                        <MenuItem selected>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={'What is the Vivos DNA Appliance?'}
                            />
                            <ChevronRightTwoToneIcon
                                sx={{
                                    color: `${theme.colors.alpha.black[30]}`,
                                    opacity: 0.8
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={'Predelivery Checklist'}
                            />
                            <Box display="flex" alignItems="center">
                                <ChevronRightTwoToneIcon
                                    sx={{
                                        ml: 1,
                                        color: `${theme.colors.alpha.black[30]}`,
                                        opacity: 0.8
                                    }}
                                />
                            </Box>
                        </MenuItem>
                        <MenuItem disabled>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={'Common Adjustments'}
                            />
                            <ChevronRightTwoToneIcon
                                sx={{
                                    color: `${theme.colors.alpha.black[30]}`,
                                    opacity: 0.8
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={'Appliance Design Tips'}
                            />
                            <ChevronRightTwoToneIcon
                                sx={{
                                    color: `${theme.colors.alpha.black[30]}`,
                                    opacity: 0.8
                                }}
                            />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h5'
                                }}
                                primary={'Commonly Asked Questions'}
                            />
                            <ChevronRightTwoToneIcon
                                sx={{
                                    color: `${theme.colors.alpha.black[30]}`,
                                    opacity: 0.8
                                }}
                            />
                        </MenuItem>
                    </MenuListWrapperRounded>
                </Box>
            </Box>
        </Card>
    )
}

export default DNACard