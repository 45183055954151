// Vendors
import { useEffect, FC, useState } from "react";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Button, Grid, MenuItem } from "@mui/material";

// Components
import DataGrid from '../../../components/DataGrid/DataGrid';

// Store
import { useMainStoreContext } from '../../../stores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';

// Styling
import CssSelectField from '../../../components/CssSelectField';
import CssTextField from "../../../components/CssTextField";

interface IGIProps {
    submissionId: number;
    dataLogValues: never[];
}

export const AIReportDatalog: FC<IGIProps> = observer(
    ({ submissionId, dataLogValues }) => {
        const [hasLoaded, setHasLoaded] = useState(false);
        const { saveDataValues, loadVivosPropValues, setMeasurementSubmissionId, vivosPropertyNames } = useMainStoreContext().measurementsData;
        useEffect(() => {
            // setMeasurementSubmissionId(submissionId);
            loadVivosPropValues();
            setHasLoaded(true);
        }, [])

        useEffect(() => {
            setHasLoaded(true);
        }, [dataLogValues])

        const rowEditCommit = (id, column, value) => {
            saveDataValues(id, column, value);
        }

        const handleChange = (id, value) => {
            saveDataValues(id, "vivosPropertyId", value);
        }

        const handleDelete = (propertyId, value) => {
            saveDataValues(propertyId, "IsActive", value);
        }

        const columns = [
            {
                field: 'delete',
                headerName: '',
                flex: .25,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: (params) => {
                    return (
                        <Grid><Button onClick={event => { handleDelete(params.row.propertyId, event.currentTarget.value) }}><DoNotDisturbOnIcon color="disabled" /></Button></Grid>
                    )
                }
            },
            {
                field: 'name',
                headerName: 'Dolphin Measurement Name',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'left',
            },
            {
                field: 'valueX',
                headerName: 'Value',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                editable: true,
                renderCell: (params) => {
                    return (
                        <Grid>
                            <CssTextField
                                onChange={e => {
                                    params.row.valueX = e.target.value;
                                    rowEditCommit(params.row.Id, "valueX", e.target.value);
                                }}
                                value={params.row.valueX}
                            >

                            </CssTextField>
                        </Grid>
                    )
                }
            },
            {
                field: 'vivosName',
                headerName: 'Vivos Measurement Name',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: (params) => {
                    var menuItems: any = vivosPropertyNames;
                    if (params.row.name.includes("2D Area") && params.row.name.includes("Axial")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Area") && p.value.includes("Axial")) });
                    } else if (params.row.name.includes("2D Area") && params.row.name.includes("Coronal")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Area") && p.value.includes("Coronal")) });
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Sagittal")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Sagittal")) });
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Volume")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Volume")) });
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Axial")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Axial")) });
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Coronal")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Coronal")) });
                    } else if (params.row.name.includes("2D Angle") && params.row.name.includes("Sagittal")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Angle") && p.value.includes("Sagittal")) });
                    } else if (params.row.name.includes("2D Angle") && params.row.name.includes("Volume")) {
                        menuItems = _.filter(vivosPropertyNames, (p) => { return (p.value.includes("2D Angle") && p.value.includes("Volume")) });
                    }
                    return (
                        <Grid>
                            <CssSelectField
                                id={'aiReportStatus.dashboard.cell.vivosPropertyName.' + params.row.ID}
                                sx={{ border: '0px' }}
                                defaultValue="0"
                                label="Status"
                                value={params.row.vivosPropertyId ? params.row.vivosPropertyId : 0}
                                onChange={e => {
                                    params.row.vivosPropertyId = e.target.value;
                                    handleChange(params.row.propertyId, e.target.value);
                                }}
                            >
                                {menuItems.map((item: any) => (
                                    <MenuItem key={item.id + item.name} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </CssSelectField>
                        </Grid>
                    );
                }
            },
        ];

        return (
            <Grid className="modalPageHeight autoScroll" container direction="column" padding={2} >
                <Grid item xs={12}>
                    <DataGrid
                        getRowId={row => row["propertyId"]}
                        columns={columns}
                        data={dataLogValues}
                        loading={!hasLoaded}
                        pageSize={100}
                    //rowEditCommit={rowEditCommit}
                    />
                </Grid>
            </Grid>

        );
    });


