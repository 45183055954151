import { observable, action, computed, makeObservable } from 'mobx';
import * as _ from "lodash";

import LegalGuardianData from '../entities/LegalGuardian';
import { isMinor } from '../api/getAge';
import { GlobalUtils } from '../api/GlobalUtils';
import { MainStore } from './MainStore';
import Patient from '../entities/Patient';
import LegalGuardian from '../entities/LegalGuardian';
import jwt_decode from 'jwt-decode';
import DictionaryListItem from '../entities/DictionaryListItem';
import PatientImage from '../entities/PatientImage';
import moment from "moment"
import { Gateway } from '../api/Gateway';

export default class CreatePatientStore {
  mainStore: MainStore;
  userID: number = 0;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;
    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      this.userID = tokenData.id;
    }

  }

  defaultPatient = {
    id: 0,
    orgId: 1,
    providerId: -1,
    vivosId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: new Date(),
    ethnicityId: -1,
    isProspect: false,
    isLincare: false,
    genderId: 22,
    ehrsId: -1,
    primaryPhone: "",
    altPhone: "",
    emailAddress: "",
    isActive: true
  }

  @observable id = 0;
  @observable patient = new Patient(this.defaultPatient);
  @observable firstName = '';
  @observable lastName = '';
  @observable dateOfBirth: Date = new Date(Date.now());
  @observable genderId = 22;
  @observable ethnicityId = -1;
  @observable isProspect = false;
  @observable isLincare = false;
  @observable addressId = 0;
  @observable address1 = "";
  @observable address2 = "";
  @observable city = "";
  @observable state = "";
  @observable postalCode = "";
  @observable country = "";
  @observable isPrimary = false;
  @observable emailAddress = "";
  @observable primaryPhone = "";
  @observable altPhone = "";
  @observable isActive = true;
  @observable legalGuardians: LegalGuardianData[] = [];
  @observable intraoralImageType: DictionaryListItem[] = [];
  @observable extraoralImageType: DictionaryListItem[] = [];
  @observable cbctMachineBrandType: DictionaryListItem[] = [];
  @observable machineBrand3DType: DictionaryListItem[] = [];
  @observable patientOcclusionImageType: DictionaryListItem[] = [];
  @observable molarRelationshipType: DictionaryListItem[] = [];
  @observable patientImageType: DictionaryListItem[] = [];
  @observable patientImages: PatientImage[] = [];
  @observable patientExtraoralImages: PatientImage[] = [];
  @observable patientExtraoral3DImages: PatientImage[] = [];
  @observable patientIntraoralImages: PatientImage[] = [];
  @observable patientScanCBCTImages: PatientImage[] = [];
  @observable patientOcclusionImages: PatientImage[] = [];
  @observable patientDocuments: PatientImage[] = [];
  @observable isLoading: boolean = false;
  @observable loadingText: string = "";
  @observable occlusion3dMachineBrandId: number = 0;
  @observable occlusionMemberTookImpression: string = "";
  @observable occlusionRmolarRelationship: number = 0;
  @observable occlusionLmolarRelationship: number = 0;
  @observable occlusionImpressionDate: Date = new Date();
  @observable ethnicities: any[] = [];

  @observable helperFirstNameText = "";
  @observable helperLastText = "";
  @observable helperGenderText = "";
  @observable helperDOBText = "";
  @observable helperEthnicityText = "";
  @observable helperAddress1Text = "";
  @observable helperAddress2Text = "";
  @observable helperCityText = "";
  @observable helperStateText = "";
  @observable helperPostalCodeText = "";
  @observable helperEmailText = "";
  @observable helperPrimaryPhoneText = "";
  @observable helperAltPhoneText = "";

  @observable error = true;

  @action setFirstName = (newName: string) => { this.firstName = newName; };
  @action setLastName = (newName: string) => { this.lastName = newName };
  @action setDateOfBirth = (newValue: Date) => { this.dateOfBirth = newValue };
  @action setGenderId = (newValue: number) => { this.genderId = newValue };
  @action setEthnicityId = (newValue: number) => { this.ethnicityId = newValue };
  @action setIsProspect = (newValue: boolean) => { this.isProspect = newValue };
  @action setIsLincare = (newValue: boolean) => { this.isLincare = newValue };
  @action setIsLoading = (newValue: boolean) => { this.isLoading = newValue };
  @action setIsLoadingText = (newValue: string) => { this.loadingText = newValue };

  @action setAddressId = (newValue: number) => { this.addressId = newValue }
  @action setAddress1 = (newValue: string) => { this.address1 = newValue }
  @action setAddress2 = (newValue: string) => { this.address2 = newValue }
  @action setCity = (newValue: string) => { this.city = newValue }
  @action setState = (newValue: string) => { this.state = newValue }
  @action setPostalCode = (newValue: string) => { this.postalCode = newValue }
  @action setCountry = (newValue: string) => { this.country = newValue }
  @action setIsPrimary = (newValue: boolean) => { this.isPrimary = newValue }
  @action setEmailAddress = (newValue: string) => { this.emailAddress = newValue }
  @action setPrimaryPhone = (newValue: string) => { this.primaryPhone = newValue }
  @action setAltPhone = (newValue: string) => { this.altPhone = newValue }
  @action setLegalGuardians = (newValue: LegalGuardianData[]) => { this.legalGuardians = newValue }
  @action setIntraoralImageType = (newvalue: DictionaryListItem[]) => { this.intraoralImageType = newvalue; }
  @action setExtraoralImageType = (newvalue: DictionaryListItem[]) => { this.extraoralImageType = newvalue; }
  @action setCbctMachineBrandType = (newvalue: DictionaryListItem[]) => { this.cbctMachineBrandType = newvalue; }
  @action setMachineBrand3DType = (newvalue: DictionaryListItem[]) => { this.machineBrand3DType = newvalue; }
  @action setpatientOcclusionImageType = (newvalue: DictionaryListItem[]) => { this.patientOcclusionImageType = newvalue; }
  @action setMolarRelationshipType = (newvalue: DictionaryListItem[]) => { this.molarRelationshipType = newvalue; }
  @action setpatientImageType = (newvalue: DictionaryListItem[]) => { this.patientImageType = newvalue; }
  @action setPatientImages = (newPatientImages: PatientImage[]) => { this.patientImages = newPatientImages; }
  @action setPatientDocuments = (newPatientDocuments: any) => { this.patientDocuments = newPatientDocuments; }
  @action setOcclusion3dMachineBrandId = (newValue: number) => { this.occlusion3dMachineBrandId = newValue }
  @action setOcclusionMemberTookImpression = (newValue: string) => { this.occlusionMemberTookImpression = newValue }
  @action setOcclusionRmolarRelationship = (newValue: number) => { this.occlusionRmolarRelationship = newValue }
  @action setOcclusionLmolarRelationship = (newValue: number) => { this.occlusionLmolarRelationship = newValue }
  @action setOcclusionImpressionDate = (newValue: Date) => { this.occlusionImpressionDate = newValue }
  @action setEthnicities = (newValue: any[]) => { this.ethnicities = newValue }

  @action setHelperFirstNameText = (newValue: string) => { this.helperFirstNameText = newValue }
  @action setHelperLastNameText = (newValue: string) => { this.helperLastText = newValue }
  @action setHelperGenderText = (newValue: string) => { this.helperGenderText = newValue }
  @action setHelperDOBText = (newValue: string) => { this.helperDOBText = newValue }
  @action setHelperEthnicityText = (newValue: string) => { this.helperEthnicityText = newValue }
  @action setHelperAddress1Text = (newValue: string) => { this.helperAddress1Text = newValue }
  @action setHelperAddress2Text = (newValue: string) => { this.helperAddress2Text = newValue }
  @action setHelperCityText = (newValue: string) => { this.helperCityText = newValue }
  @action setHelperStateText = (newValue: string) => { this.helperStateText = newValue }
  @action setHelperPostalCodeText = (newValue: string) => { this.helperPostalCodeText = newValue }
  @action setHelperEmailText = (newValue: string) => { this.helperEmailText = newValue }
  @action setHelperPrimaryPhoneText = (newValue: string) => { this.helperPrimaryPhoneText = newValue }
  @action setHelperAltPhoneText = (newValue: string) => { this.helperAltPhoneText = newValue }
  @action setPatientId = (newvalue: number) => { this.patient.id = newvalue; }

  @action setError = (error: boolean) => { this.error = error }

  @computed get getFullName() {
    return `${this.firstName} ${this.lastName} ${this.dateOfBirth} ${this.genderId} ${this.ethnicityId} ${this.isProspect}`
  }

  @action getPatient = () => {
    this.patient.firstName = this.firstName;
    this.patient.lastName = this.lastName;
    this.patient.dateOfBirth = this.dateOfBirth;
    this.patient.genderId = this.genderId;
    this.patient.ethnicityId = this.ethnicityId;
    this.patient.isProspect = this.isProspect;
    this.patient.isLincare = this.isLincare;
    this.patient.emailAddress = this.emailAddress;
    this.patient.primaryPhone = this.primaryPhone;
    this.patient.altPhone = this.altPhone;
    this.patient.providerId = this.userID;
    this.patient["address"] = this.getAddress();
    if (this.legalGuardians.length > 0) {
      this.patient["legalGuardians"] = this.getLegalGuardians();
    }
    return this.patient;
  }

  @action setPatient = (patient) => {
    if (patient) {
      this.id = patient.id;
      this.setFirstName(patient.firstName);
      this.setLastName(patient.lastName);
      this.setEthnicityId(patient.ethnicityId);
      this.setGenderId(patient.genderId);
      if (patient.patientPeople && patient.patientPeople.length > 0) {
        this.setIsProspect(patient.patientPeople[0].isProspect);
      }
      this.setEmailAddress(patient.emailAddress);
      this.setPrimaryPhone(patient.primaryPhone);
      this.setAltPhone(patient.altPhone);
      this.setDateOfBirth(patient.dateOfBirth);

      if (patient.patientPeople && patient.patientPeople.length > 0) {
        this.setPatientId(patient.patientPeople[0].id);
      }
      if (patient.addresses && patient.addresses.length > 0) {
        var address = patient.addresses[0];
        this.setAddressId(address.id);
        this.setAddress1(address.address1);
        this.setAddress2(address.address2);
        this.setCity(address.city);
        this.setCountry(address.country);
        this.setPostalCode(address.postalCode);
        this.setIsPrimary(address.isPrimary);
      }
    }
  }

  @action
  getAddress = () => {
    return {
      id: this.addressId,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      postalCode: this.postalCode,
      isPrimary: this.isPrimary
    }
  }

  @action
  getLegalGuardians = () => {
    var guardians: LegalGuardianData[] = [];
    _.forEach(this.legalGuardians, (guardian) => {

      var lg: LegalGuardian = new LegalGuardian({
        firstName: guardian.firstName,
        lastName: guardian.lastName,
        relationshipTypeId: guardian.relationshipTypeId,
        emailAddress: guardian.emailAddress,
        primaryPhone: guardian.primaryPhone,
        altPhone: guardian.altPhone
      });
      guardians.push(lg);
    })
    return guardians;
  }

  @action createPatientReset = () => {
    this.patient = new Patient(this.defaultPatient);
    this.id = 0;
    this.firstName = '';
    this.lastName = '';
    this.dateOfBirth = new Date();
    this.genderId = 22;
    this.ethnicityId = 0;
    this.isProspect = false;
    this.isLincare = false;
    this.addressId = 0;
    this.address1 = "";
    this.address2 = "";
    this.city = "";
    this.state = "";
    this.postalCode = "";
    this.isPrimary = false;
    this.country = "";
    this.emailAddress = "";
    this.primaryPhone = "";
    this.altPhone = "";
    this.isActive = true;
    this.legalGuardians = [];
    this.error = true;
    this.helperFirstNameText = "";
    this.helperLastText = "";
    this.helperGenderText = "";
    this.helperDOBText = "";
    this.helperEthnicityText = "";
    this.helperAddress1Text = "";
    this.helperAddress2Text = "";
    this.helperCityText = "";
    this.helperStateText = "";
    this.helperPostalCodeText = "";
    this.helperEmailText = "";
    this.helperPrimaryPhoneText = "";
    this.helperAltPhoneText = "";
  }


  @action
  uploadImages = async (visitId, providerId) => {
    if (!visitId || !providerId) {
      return;
    }
    return Gateway.post(`/patient/${this.patient.id}/${visitId}/${providerId}/addpatientimages`, this.patientImages);
  }

  @action
  prepOcclusionImagesForUpload = () => {
    var occlusionImages: PatientImage[] = _.filter(this.patientImages, (img) => { return img.imageType.dictionaryListId == 37 });
    _.forEach(occlusionImages, (occImg) => {
      occImg.occlusion3DMachineBrandId = this.occlusion3dMachineBrandId;
      occImg.occlusionLMolarRelationship = this.occlusionLmolarRelationship;
      occImg.occlusionRMolarRelationship = this.occlusionRmolarRelationship;
      occImg.occlusionImpressionDate = this.occlusionImpressionDate;
      occImg.occlusionMemberTookImpression = this.occlusionMemberTookImpression;
    })
  }

  @action
  validatePatient = (): string[] => {
    let fieldValidationList: string[] = [];
    let isOfAge = !isMinor(this.dateOfBirth)

    if (this.firstName === "") {
      fieldValidationList.push("First Name is required.");
      this.setHelperFirstNameText("Required Field");
    }
    else if (!GlobalUtils.validateAlpha(this.firstName)) {
      fieldValidationList.push("First Name has invalid characters.");
      this.setHelperFirstNameText("Invalid characters");
    }

    if (this.lastName === "") {
      fieldValidationList.push("Last Name is required.");
      this.setHelperLastNameText("Required Field");
    } else if (!GlobalUtils.validateAlpha(this.lastName)) {
      fieldValidationList.push("Last Name has invalid characters.");
      this.setHelperLastNameText("Invalid characters");
    }

    if (moment(this.dateOfBirth).format("YYYYMMDD") === moment(new Date()).format("YYYYMMDD")) {
      fieldValidationList.push("Date of Birth is required.");
    }

    if (this.emailAddress !== "" && !GlobalUtils.validateEmail(this.emailAddress)) {
      fieldValidationList.push("Invalid Email Format");
      this.setHelperEmailText("Invalid Email Format");
    }

    if (this.primaryPhone !== "" && !GlobalUtils.validateNumeric(this.primaryPhone)) {
      fieldValidationList.push("Primary Phone may contain only numbers.");
      this.setHelperPrimaryPhoneText("Invalid characters");

    } if (this.altPhone !== "" && !GlobalUtils.validateNumeric(this.altPhone)) {
      fieldValidationList.push("Alternate Phone may contain only numbers.");
      this.setHelperAltPhoneText("Invalid characters");
    }

    if (isOfAge) {
      if (this.firstName === "") {
        fieldValidationList.push("First Name is required.");
        this.setHelperFirstNameText("Required Field");
      }
      else if (!GlobalUtils.validateAlpha(this.firstName)) {
        fieldValidationList.push("First Name has invalid characters.");
        this.setHelperFirstNameText("Invalid characters");
      }

      if (this.lastName === "") {
        fieldValidationList.push("Last Name is required.");
        this.setHelperLastNameText("Required Field");
      } else if (!GlobalUtils.validateAlpha(this.lastName)) {
        fieldValidationList.push("Last Name has invalid characters.");
        this.setHelperLastNameText("Invalid characters");
      }
    }
    else {
      this.setHelperAddress1Text("");
      this.setHelperCityText("");
      this.setHelperStateText("");
      this.setHelperPostalCodeText("");
      this.setHelperEmailText("");
      this.setHelperPrimaryPhoneText("");
      this.setHelperAltPhoneText("");
    }

    return fieldValidationList;
  }

  @action
  softValidateExtraoralImages = (): string[] => {
    let fieldValidationList: string[] = [];

    if ((this.patientImages.filter(x => x.imageType?.listItemKey === "BIGSMILE").length === 0)) {
      fieldValidationList.push("Big Smile image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType?.listItemKey === "FRONTRELAXED").length === 0)) {
      fieldValidationList.push("Front-Relaxed image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType?.listItemKey === "PROFILEFACINGRIGHT").length === 0)) {
      fieldValidationList.push("Profile-Facing Right image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType?.listItemKey === "SUBMENTOVERTEX").length === 0)) {
      fieldValidationList.push("Submento-Vertex image is required.");
    }

    return fieldValidationList;
  }

  @action
  validateExtraoralImages = (): string[] => {
    let fieldValidationList: string[] = [];
    const imageTypeCount = this.patientImages.filter(x => x.imageType?.listItemKey.length > 0);
    if (this.patientImages.length > imageTypeCount.length) {
      fieldValidationList.push("All images require an Image Type to be selected.");
    }
    return fieldValidationList;
  }

  @action
  softValidateIntraoralImages = (): string[] => {
    let fieldValidationList: string[] = [];

    if ((this.patientImages.filter(x => x.imageType.listItemKey === "ANTERIOROCCLUSION").length === 0)) {
      fieldValidationList.push("Anterior Occlusion image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType.listItemKey === "LEFTOCCLUSION").length === 0)) {
      fieldValidationList.push("Left Buccal Occlusion image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType.listItemKey === "RIGHTOCCLUSION").length === 0)) {
      fieldValidationList.push("Right Buccal Occlusion Right image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType.listItemKey === "UPPERARCH").length === 0)) {
      fieldValidationList.push("Upper Arch image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType.listItemKey === "LOWERARCH").length === 0)) {
      fieldValidationList.push("Lower Arch image is required.");
    }

    if ((this.patientImages.filter(x => x.imageType.listItemKey === "LINGUALFRENUM").length === 0)) {
      fieldValidationList.push("Lingual Frenum image is required.");
    }

    return fieldValidationList;
  }

  @action
  softValidateCBCTUploadImages = (): string[] => {
    let fieldValidationList: string[] = [];

    const image = this.patientImages.filter(x => x.imageType.listItemKey === "CBCTSCAN");
    if (image.length > 0) {
      if (image[0].cbctFov01! <= 0 || image[0].cbctFov02! <= 0) {
        fieldValidationList.push("Both FOV sizes are required.");
      }

      if (image[0].machineBrandId === 0) {
        fieldValidationList.push("Machine Brand is required.");
      }
    }
    // else {
    //   fieldValidationList.push("CBCT Upload image is required.");
    // }

    return fieldValidationList;
  }

  @action
  softValidateOcclusionImages = (): string[] => {
    let fieldValidationList: string[] = [];

    const images = this.patientImages.filter(x => (x.imageType.listItemKey.includes('STONE')));
    if (images.length > 0) {
      if (images.filter(x => x.imageType.listItemKey === "STONEUPPERARCHMODEL").length === 0) {
        fieldValidationList.push("2D Upper Arch Model image is required.");
      }

      if (images.filter(x => x.imageType.listItemKey === "STONELOWERARCHMODEL").length === 0) {
        fieldValidationList.push("2D Lower Arch Model image is required.");
      }

      if (images.filter(x => x.imageType.listItemKey === "STONEANTERIOROCCLUSIONMODEL").length === 0) {
        fieldValidationList.push("2D Anterior Occlusion Model image is required.");
      }

      if (images.filter(x => x.imageType.listItemKey === "STONELEFTOCCLUSIONMODEL").length === 0) {
        fieldValidationList.push("2D Left Occlusion Model image is required.");
      }

      if (images.filter(x => x.imageType.listItemKey === "STONERIGHTOCCLUSIONMODEL").length === 0) {
        fieldValidationList.push("2D Rich Occlusion Model image is required.");
      }
    }

    return fieldValidationList;
  }

  @action
  softValidateOcclusion3DImages = (): string[] => {
    let fieldValidationList: string[] = [];

    if (this.occlusion3dMachineBrandId === 0) {
      fieldValidationList.push("Machine Brand is required.");
    }

    const images = this.patientImages.filter(x => (x.imageType?.listItemKey?.includes('3D')));

    if (images.length > 0) {
      if (images.filter(x => x.imageType.listItemKey === "3DUPPERARCH").length === 0) {
        fieldValidationList.push("3D Upper Arch image is required.");
      }

      if (images.filter(x => x.imageType.listItemKey === "3DLOWERARCH").length === 0) {
        fieldValidationList.push("3D Lower Arch image is required.");
      }
    } else {
      fieldValidationList.push("3D Upper Arch image is required.");
      fieldValidationList.push("3D Lower Arch image is required.");
    }

    return fieldValidationList;
  }
}
