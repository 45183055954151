import { Button, FormControlLabel, Grid, MenuItem, Switch } from "@mui/material";
import { observer } from "mobx-react";
import { FC, Fragment } from "react";
import DataGrid from "../components/DataGrid/DataGrid";
import { LeftNav } from "../components/LeftNav/LeftNav";
import { MyoCorrectGridHeader } from "../components/MyoCorrectGridHeader";
import { useMainStoreContext } from "../stores/MainStore";
import React from "react";
import ModalContainer from "../components/ModalContainer";
import { PatientEdit } from "../components/PatientProfile/PatientEdit";
import CssSelectField from "../components/CssSelectField";
import DictionaryListItem from "../entities/DictionaryListItem";
import { ApprovalCode } from "./Lincare/ApprovalCode";

interface IGIProps {
}

export const PatientLincare: FC<IGIProps> = observer(() => {
    
    const store = useMainStoreContext().patientLincareStore;

    React.useEffect(() => {
        store.getPatients();
        store.loadLincareStatusValues();
    }, []);
    
    const numResults = 0;
    const hdrButtons: any = [];

    const posibleStatusChange = (statusId) => {
        let result = new Array;
        let findIndex = store.lincareStatusValues.find(x => x.id === statusId);
        if(findIndex?.listItemKey === 'NEW') {
            result.push(findIndex);
        }
        else if(findIndex?.listItemKey === 'PENDINGAPPROVAL'){
            let findResult = store.lincareStatusValues.filter(x => x.listItemKey === 'PENDINGAPPROVAL'|| x.listItemKey === 'INTREATMENT');
            result = result.concat(findResult);
        }
        else if(findIndex?.listItemKey === 'INTREATMENT'){
            let findResult = store.lincareStatusValues.filter(x => x.listItemKey === 'INTREATMENT'|| x.listItemKey === 'COMPLETE');
            result = result.concat(findResult);
        }
        else if(findIndex?.listItemKey === 'COMPLETE'){
            let findResult = store.lincareStatusValues.filter(x => x.listItemKey === 'INTREATMENT'|| x.listItemKey === 'COMPLETE');
            result = result.concat(findResult);
        }
        return result;
    }

    const handleStatusChange = (params, event) => {
        store.currentPatientId = params.row.id;
        let inTreatment = store.lincareStatusValues.find(x => x.listItemKey === 'INTREATMENT');
        let pendingApproval = store.lincareStatusValues.find(x => x.listItemKey === 'PENDINGAPPROVAL');
        if(params.row.lincareStatusId === pendingApproval?.id && event.target.value === inTreatment?.id){
            store._toggleShowApprovalCode = true;
        }
        else {
            store.handleLincareStatusChange(event.target.value)
        }
    };

    const columns = [
        {
            field: 'vivosId',
            headerName: 'Patient Vivos ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.id}
                                variant="text"
                                onClick={() => {
                                    store.currentPatientId = params.row.id;
                                    store.patientData = params.row;
                                    store.toggleEditPatientModal();
                                }}
                            >
                                {params.row.vivosId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'firstName',
            headerName: 'Patient First Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.firstName}
                    </Grid>
                );
            },
        },
        {
            field: 'lastName',
            headerName: 'Patient Last Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.lastName}
                    </Grid>
                );
            },
        },
        {
            field: 'provider',
            headerName: 'Provider',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                return (
                <Grid>
                    {params.row.provider}
                </Grid>
                )}
        },
        {
            field: 'lincareStatus',
            headerName: 'Lincare Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                    <CssSelectField
                    id={"patientLincare.dashboard.cell.linStatusId." + params.row.id}
                    sx={{ border: "0px" }}
                    defaultValue="0"
                    label={params.row.lincareStatusId}
                    value={(params.row.lincareStatusId ? params.row.lincareStatusId : 0)}
                    onChange={(event) => { handleStatusChange(params, event)}}
                    >
                    {
                        posibleStatusChange(params.row.lincareStatusId).map((tm) =>
                        (<MenuItem key={tm.id} value={tm.id}>{tm.name}</MenuItem>))
                    }
                    </CssSelectField>
                </Grid>
                ); 
            },
        },
        {
            field: 'isLincareActive',
            headerName: 'Active Lincare Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <FormControlLabel
                            className="switch"
                            control={<Switch onChange={() => {store.handleLincareActivation(params.row)}} checked={params.row.isLincareActive} />} 
                            label={''}/>                    
                    </Grid>
                );
            },
        }
    ]

  return (
    <Fragment>
        <Grid xs={12} item container direction="row" className="page">
            <LeftNav onToggle={store.toggleLeftNav} />
            <Grid className={'content' + (store.isLeftNavExpanded ? ' small' : ' big')}>
                <Grid>
                    <MyoCorrectGridHeader
                        parentId={'lincare.dashboard'}
                        id={store.userID}
                        onChange={store.getSearchData}
                        onClear={store.clearData}
                        title={store.pageTitle}
                        numResults={numResults}
                        buttons={hdrButtons}
                    />
                </Grid>
                <Grid className="grid">
                    <DataGrid columns={columns} loading={store.isLoadingPatients} data={store.patientsData} />
                </Grid>
            </Grid>
        </Grid>
        <ModalContainer
                    show={store.toggleShowPatientEditModal}
                    noScroll={true}
                    title=""
                    hideTitlebar={true}
                    onClose={() => {}}
                    onClick={store.toggleEditPatientModal.bind(this)}
                >
                    <PatientEdit
                        asProvider={true}
                        patientID={store.currentPatientId}
                        patient={store.patientData}
                        onClose={() => {
                            store.toggleEditPatientModal();
                            store.getPatients();
                        }}
                    />
        </ModalContainer>
        <ModalContainer
                    show={store.toggleShowApprovalCodeModal}
                    noScroll={true}
                    title="Enter Stripe Approval Code"
                    onClose={() => {store._toggleShowApprovalCode = false}}
                    onClick={() => {}}
                    smallScreen={true}
                    >
            <ApprovalCode/>
        </ModalContainer>
    </Fragment>
  )
})