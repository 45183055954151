import { Button, CircularProgress, Divider, Grid, TextField } from "@mui/material"
import { observer } from "mobx-react-lite"
import { FC, useEffect, useState } from "react"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VivosApplianceImg from '../../imgs/thumbnails/Vivos-Appliance-1_tn.jpg';
import { useMainStoreContext } from "../../stores/MainStore";
import moment from "moment";

interface IGIProps {
    claimData: any;
}

export const ClaimInformation: FC<IGIProps> = observer(({ claimData }) => {
    const [uri, setUri] = useState('');
    const store = useMainStoreContext().reworkClaimStore;

    useEffect(() => {
        setUri(
            decodeURI(
                `/${claimData.vivosId}/${process.env.REACT_APP_PRESCRIPTIONS_CONTAINER}/${claimData.prescriptionId}/${process.env.REACT_APP_REWORKCLAIMS_CONTAINER}/${claimData.id}/`,
            ),
        );
    },
        [claimData.vivosId, claimData.prescriptionId, claimData.id]);

    const onDownload = () => {
        const link = document.createElement('a');
        if (claimData.prescriptionUrl) {
            link.download = claimData.prescriptionUrl;
            link.href = uri + claimData.prescriptionUrl;
            link.click();
        }
    };

    return (
        <Grid xs={12} container direction={'row'} className="principal-claim-container">
            <Divider flexItem={true} orientation="horizontal" />
            <Grid xs={5.3} direction={'column'}>
                <Grid className="box-claim-container">
                    <Grid className="claim-data-title">Claim Information</Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={6}>
                            <Grid>Patient First Name</Grid>
                            <Grid className="claim-data">{claimData.patientName.split(' ')[0]}</Grid>
                        </Grid>
                        <Grid xs={6}>
                            <Grid>Patient Last Name</Grid>
                            <Grid className="claim-data">{claimData.patientName.split(' ')[1]}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={6}>
                            <Grid>RX ID</Grid>
                            <Grid className="claim-data">{claimData.rxClaimId}</Grid>
                        </Grid>
                        <Grid xs={6}>
                            <Grid>Appliance</Grid>
                            <Grid className="claim-data">{claimData.appliance}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={6}>
                            <Grid>Lab</Grid>
                            <Grid className="claim-data">{claimData.labName}</Grid>
                        </Grid>
                        <Grid xs={6}>
                            <Grid>Date Original RX Shipped</Grid>
                            <Grid className="claim-data">{claimData.prescriptionShippedDate ? moment(claimData.prescriptionShippedDate).format('yyyy-MM-DD') : ''}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={6}>
                            <Grid>Days since RX Shipped</Grid>
                            <Grid className="claim-data">{(claimData.daysRXShipped === -1) ? '' : claimData.daysRXShipped}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={6}>
                            <Grid>Request</Grid>
                            {store.reworkRequestValues.map(rr =>
                                <Grid className="claim-data">{rr.name}</Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={6.3} direction={'column'}>
                <Grid className="box-claim-container">
                    <Grid className="claim-data-title">Provider Description</Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid xs={12}>
                            <TextField
                                className="claim-textarea"
                                onChange={() => { }}
                                style={{ width: "100%" }}
                                minRows={4}
                                maxRows={4}
                                disabled={true}
                                value={claimData.providerNotes}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="box-claim-container">
                    <Grid className="claim-data-title">Provider Documentation</Grid>
                    <Grid container direction={'row'} className="claim-info">
                        <Grid container direction="column" className="claimuploadImgContainer">

                            <Button
                                onClick={() => {
                                    store.downloadImages(claimData, uri);
                                }}
                                disabled={store.isLoadingAllClaimDocuments}>
                                <PictureAsPdfIcon style={{ width: "50px", height: "50px" }} />
                            </Button>
                            {store.isLoadingAllClaimDocuments &&
                                <CircularProgress
                                    sx={{
                                        position: 'absolute',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            }
                        </Grid>
                        <Grid container direction="column" className="claimuploadImgContainer">
                            <Button
                                onClick={() => {
                                    onDownload();
                                }}>
                                <img alt="" style={{ width: "50px", height: "50px" }} src={VivosApplianceImg} />
                            </Button>

                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})