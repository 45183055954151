import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import { settings as sett1, Serializer } from 'survey-core';
import { settings } from 'survey-creator-core';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { useMainStoreContext } from '../../../stores/MainStore';
import React, { useEffect, useMemo } from 'react';
import { runInAction, toJS } from 'mobx';
import { convertDisplayType, convertTypes } from '../../../lib';
import * as _ from 'lodash';
import { CustomFormDto } from '../../../entities/CustomForm';

interface SurveyCreatorWidgetProps {
    sections: any;
    section: any;
    onClose: (onClose: boolean) => void;
}

export const SurveyCreatorCustomForm: React.FC<SurveyCreatorWidgetProps> = ({ sections, section, onClose }) => {
    let { saveQuestionnaire, loadQuestionnaireCustomFormSingle, categories } = useMainStoreContext().customFormStore;
    const [sectionData, setSectionData] = React.useState<any>([]);
    const [detailCustomForm, setDetailCustomForm] = React.useState<CustomFormDto>({ pages: [], title: '', description: '', id: '', elements: [] });
    useMemo(() => {
        var sectionData = sections.flatMap(section => toJS(section));
        setSectionData(_.orderBy(sectionData, ['categoryId'], ['asc']));
    }, [])
    useEffect(() => {
        setDetailCustomForm(toJS(section));
    }, [section])

    useEffect(() => {
        creator.JSON = defaultJson();
    }, [detailCustomForm])


    settings.designer.showAddQuestionButton = false;
    sett1.allowShowEmptyTitleInDesignMode = false;

    const creator = useMemo(() => {
        const options = {
            showLogicTab: false,
            showJSONEditorTab: false,
            questionTypes: [''],
            showSidebar: false,
            isAutoSave: false,
            showSurveyTitle: true,
            showAddQuestionButton: false,
            showTestSurveyTab: true,
            showPageSelectorInToolbar: true,
        };

        return new SurveyCreator(options,);
    }, []);


    creator.toolbox.forceCompact = false;
    creator.pageEditMode = 'standard';

    var propertyStopList = ['category'];

    creator.onShowingProperty.add(function (sender, options) {
        options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
    });

    useEffect(() => {
        let showPanel = creator.toolbarItems.filter(x => x.id === 'svd-settings')[0];
        if (showPanel) {
            creator.toolbarItems.splice(creator.toolbarItems.indexOf(showPanel), 1);
        }
    })

    Serializer.addProperty('question', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('panel', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('page', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('survey', {
        name: 'category:dropdown',
        category: 'general',
        choices: categories,
        default: section.categoryId ? _.find(categories, (c) => { return c.id === section.categoryId }).value : null
    });

    Serializer.removeProperty('page', 'category');
    Serializer.removeProperty('question', 'category');

    Serializer.findProperty("checkbox", "hasSelectAll").visible = false;
    Serializer.findProperty("checkbox", "hasNone").visible = false;
    Serializer.findProperty("checkbox", "hasOther").visible = false;
    Serializer.findProperty("radiogroup", "hasNone").visible = false;
    Serializer.findProperty("radiogroup", "hasOther").visible = false;
    useEffect(() => {
        sectionData.forEach((section: any) => {
            return creator.toolbox.addItem({
                category: section.categoryId ? _.find(categories, (c) => { return c.id === section.categoryId }).value : 'Others',
                id: section.id.toString(),
                name: section.text,
                title: section.title,
                isCopied: true,
                iconName: 'icon-default',
                json: {
                    type: section.type,
                    title: section.title,
                    name: section.text,
                    id: section.id.toString(),
                    description: section.description,
                    titleLocation: 'left',
                    elements: section.elements.map((question: any) => {
                        return {
                            type: convertTypes(question.type),
                            name: question.text,
                            title: question.title,
                            id: question.id,
                            inputType: convertDisplayType(question.type),
                            isRequired: question.isRequired,
                            showLabel: question.showLabel,
                            titleLocation: 'left',
                            startWithNewLine: question.startWithNewLine,
                            choices: question.choices,
                        };
                    }),
                },
            });
        });
    }, [creator.toolbox, sectionData]);

    useEffect(() => {
        creator.onPanelAdded.add((sender, object) => {
            const json = sender.JSON;
            const addedOnPage = json.pages[object.page.num - 1];
            const lastElementAdded = addedOnPage.elements.pop();
            loadQuestionnaireCustomFormSingle(lastElementAdded.id).then((returndata) => {
                json.pages = [...json.pages.filter(page => page.elements.length > 0)];
                const isSectionAlreadyInForm = json.pages.filter(page => page.id === lastElementAdded.id).length > 0;

                if (!isSectionAlreadyInForm) {
                    var element = returndata.pop();

                    runInAction(() => {
                        json.pages.push({
                            id: element.id,
                            name: element.title,
                            description: element.title,
                            elements: element.elements,
                        });
                        sender.changeText(JSON.stringify(json), true);
                    })
                } else {
                    //TODO: Notify Error
                }

            });

        });
    })

    creator.onElementAllowOperations.add(function (_, options) {

        if (options.obj?.getType() !== "page") {
            options.allowDelete = false;
        }
        //enable edit
        options.allowEdit = false;

        //disable change require
        options.allowChangeRequired = false;
        //disable change show/hide title
        options.allowShowHideTitle = false;

        options.allowCopy = false;
        //disable changing type
        options.allowChangeType = false;

        options.allowDragging = true;
    });

    const defaultJson = () => {
        if (!_.isUndefined(detailCustomForm.pages) && detailCustomForm.pages.length > 0) {
            return detailCustomForm;
        }
    };


    creator.saveSurveyFunc = (saveNo: any, callback: any) => {
        saveQuestionnaire(creator).then(() => {
            onClose(false);
        });
        callback(saveNo, true);
    };

    return <SurveyCreatorComponent creator={creator} />;
};
