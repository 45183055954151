import { RoomPreferencesSharp } from "@mui/icons-material";
import { Grid } from "@mui/material";
import * as _ from "lodash";
import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component, FC, RefObject, useEffect } from "react";
import TeethChartTooth from "../../entities/TeethChartTooth";
import "../../scss/_Tooth.scss";
import { useMainStoreContext } from "../../stores/MainStore";
import { NumberingSystem, TeethSet } from '../../entities/Enums';

interface ToothProps {
    index: number;
    onClick(index: number, isDeciduous: boolean, isUpdate: boolean): any;
    tooth: TeethChartTooth | null;
    currentPresentConditions: any[] | undefined;
}
const TOPDECIDUOUSMIN = 4;
const TOPDECIDUOUSMAX = 13;
const TOPMAX = 16;
const BOTTOMDECIDUOUSMIN = 20;
const BOTTOMDECIDUOUSMAX = 29;
const MISSINGREASONDEFAULT = 113;
const PRESENTCONDITIONDEFAULT = 122;

export const Tooth: React.FC<ToothProps> = observer(
    ({ index, onClick, tooth, currentPresentConditions }) => {
        const [isMidSelected, setIsMidSelected] = React.useState(false);
        const [isTopSelected, setIsTopSelected] = React.useState(false);
        const [isBottomSelected, setBottomSelected] = React.useState(false);
        const [isSelected, setIsSelected] = React.useState(false);
        const [isDeciduous, setIsDeciduous] = React.useState(false);
        const [hasDeciduous, setHasDeciduous] = React.useState(false);
        const [invert, setInvert] = React.useState(false);
        const { currentNumberingSystem, currentTeethSet, onDeleteTooth, getIndexByNumberingSystem, getDeciduousToothIndex } = useMainStoreContext().teethChartStore;
        useEffect(() => {
            setIsMidSelected(false);
            setIsTopSelected(false);
            setBottomSelected(false);
            setHasDeciduous(false);
            setInvert(false);
        }, []);

        useEffect(() => {
            loadTooth();
        }, [tooth, tooth?.isDeciduous, tooth?.missingReason, tooth?.presentConditionsId])

        const loadTooth = () => {
            if ((index >= TOPDECIDUOUSMIN && index <= TOPDECIDUOUSMAX)
                || (index >= BOTTOMDECIDUOUSMIN && index <= BOTTOMDECIDUOUSMAX)) {
                setHasDeciduous(true);
            }

            if (index > TOPMAX) {
                setInvert(true);
            }

            if (tooth) {
                if (tooth.isDeciduous) {
                    setIsDeciduous(true);
                    setIsSelected(false);
                } else {
                    setIsSelected(true);
                    setIsDeciduous(false);
                }
            }
        }

        const onStatusChange = (event: any) => {
           var tId = event.target.id;
            if (tId.includes("topBox")) {
                if (tooth ? tooth.isDeciduous : isDeciduous) {
                    onClick(index, tooth ? tooth.isDeciduous : isDeciduous, false);
                } else {
                    if (isSelected) {
                        onClick(index, true, true);
                    } else {
                        onClick(index, true, false);
                    }
                }
            } else if (tId.includes("midBox")) {
                if (isSelected === true) {
                    onClick(index, false, false);
                } else {
                    setIsSelected(true);
                    if (isDeciduous) {
                        onClick(index, false, true);
                    } else {
                        onClick(index, false, false);
                    }
                }
            }
            else if (tId.includes("bottomBox")) {
                if (tooth ? tooth.isDeciduous : isDeciduous) {
                    onClick(index, tooth ? tooth.isDeciduous : isDeciduous, false);
                } else {
                    if (isSelected) {
                        onClick(index, true, true);
                    } else {
                        onClick(index, true, false);
                    }
                }
            }
        }

        // const onStatusChange = (event: any) => {
        //     var tId = event.target.id;
        //     if (tId.includes("topBox")) {
        //         if (isDeciduous) {
        //             onClick(index, false, false);
        //         } else {
        //             if (isSelected) {
        //                 onClick(index, true, true);
        //             } else {
        //                 onClick(index, true, false);
        //             }
        //         }
        //     } else if (tId.includes("midBox")) {
        //         if (isSelected === true) {
        //             onClick(index, false, false);
        //         } else {
        //             setIsSelected(true);
        //             if (isDeciduous) {
        //                 onClick(index, false, true);
        //             } else {
        //                 onClick(index, false, false);
        //             }
        //         }
        //     }
        //     else if (tId.includes("bottomBox")) {
        //         if (isDeciduous) {
        //             onClick(index, false, false);
        //         } else {
        //             if (isSelected) {
        //                 onClick(index, true, true);
        //             } else {
        //                 onClick(index, true, false);
        //             }
        //         }
        //     }
        // }


        return (
            <Grid >
                {(hasDeciduous && !invert && (currentTeethSet !== TeethSet.ADULT)) && <Grid container direction="row">
                    <div className="toothBox">
                        <div id="topBox-d" className={"toothSelector baby" + (invert ? " invert" : "") + ((tooth && tooth?.isDeciduous) ? " selected" + ((!_.isNaN(tooth?.missingReason)) ? "" : " presentConditions") : "")} onClick={onStatusChange.bind(this)}>+</div>
                        <div id="topBox" className={"tooth baby" + (invert ? " invert" : "") + ((tooth && tooth?.isDeciduous) ? " selected" + ((!_.isNaN(tooth?.missingReason)) ? "" : " presentConditions") : "")} onClick={onStatusChange.bind(this)}>{getDeciduousToothIndex(index)}</div>
                    </div>
                </Grid>}
                {(currentTeethSet !== TeethSet.DECIDUOUS) &&
                    <Grid container direction="row">
                        <div className="toothBox">
                            <div id="midBox-d" className={"toothSelector" + (invert ? " invert" : "") + ((tooth && !tooth?.isDeciduous) ? " selected" + ((_.first(tooth?.presentConditionsArray) === PRESENTCONDITIONDEFAULT) ? "" : " presentConditions") : "")} onClick={onStatusChange.bind(this)}>+</div>
                            <div id="midBox" className={"tooth" + (invert ? " invert" : "") + ((tooth && !tooth?.isDeciduous) ? " selected" + ((_.first(tooth?.presentConditionsArray) === PRESENTCONDITIONDEFAULT) ? "" : " presentConditions") : "")} onClick={onStatusChange.bind(this)}>{getIndexByNumberingSystem(index)}</div>
                        </div>
                    </Grid>}
                {(hasDeciduous && invert && (currentTeethSet !== TeethSet.ADULT)) && <Grid container direction="row">
                    <div className="toothBox">
                        <div id="bottomBox" className={"tooth baby" + (invert ? " invert" : "") + ((tooth && tooth?.isDeciduous) ? " selected" + ((!_.isNaN(tooth?.missingReason)) ? "" : " presentConditions") : "")} onClick={onStatusChange.bind(this)}>{getDeciduousToothIndex(index)}</div>
                        <div id="bottomBox-d" className={"toothSelector baby" + (invert ? " invert" : "") + ((tooth && tooth?.isDeciduous) ? " selected" + ((!_.isNaN(tooth?.missingReason)) ? "" : " presentConditions") : "")} onClick={onStatusChange.bind(this)}>+</div>
                    </div>
                </Grid>}
            </Grid>
        );

    }
)

