import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { FC } from "react";
import LabCase from "../../entities/LabCases";
import { LabCaseHeader } from "./LabCaseHeader";
import { LabCaseInformation } from "./LabCaseInformation";

interface IGIProps {
    labCase: LabCase;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CASEINFORMATION = 0;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid>
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const LabCaseReview: FC<IGIProps> = observer(({labCase}) => {
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Grid xs={12} className="aiReportEntryModal" sx={{ overflowY: 'hidden' }}>
         <Grid xs={12} sx={{ padding: '0px 0px 15px 0px' }}>
            <LabCaseHeader labCase={labCase}/>
            <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} className="tabs" onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Case Information" {...a11yProps(CASEINFORMATION)} />
                </Tabs>
            </Grid>
            <Grid>
                <TabPanel index={value} value={CASEINFORMATION}>
                    <LabCaseInformation labCase={labCase}/>
                </TabPanel>
            </Grid>
         </Grid>
    </Grid>
});