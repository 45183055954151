// Vendors
import React, { useEffect } from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    selectClasses,
    Button,
    IconButton,
    Typography
} from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';

// Components
import UploadRotateCropper from "../../../components/UploadDragAndDrop/UploadRotateCropper";
import IntraoralIcon from '../../../imgs/png/Intraoral-Images.png';
import ExtraoralIcon from '../../../imgs/png/Extraoral-Images.png';
import BoxUploadWrapper from "../../../components/UI/UploadWrappers/BoxUploadWrapper";

// Entities
import PatientImage from "../../../entities/PatientImage";
import DictionaryListItem from "../../../entities/DictionaryListItem";
import Submission from "../../../entities/Submission";
import ProcessedImages from "../../../entities/ProcessedImages";

// Store
import { useMainStoreContext } from "../../../stores/MainStore";

// API
import { observer } from "mobx-react";
import _ from "lodash";
import { FileUploader } from "react-drag-drop-files";

// Styling
import '../../../scss/_AIReportEntry.scss';


const fileTypes = ["JPG", "PNG"];
interface IUploaderProps {
    type: string;
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    typeOptions: any[];
}

const UploadProcessedImageDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback, typeOptions, type }) => {

        const { patient, processedImages, submission, getOptionFromFileName, removeImage, setProcessedImages, uploadProcessedImages, validate, loadReport } = useMainStoreContext().aiReportEntryStore;
        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [images, setImages] = React.useState<ProcessedImages[]>([]);
        const [options, setOptions] = React.useState<DictionaryListItem[]>([]);
        const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [validationList, setValidationList] = React.useState<string[]>([]);
        const [toggleValidationModel, setToggleValidationModel] = React.useState(false);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            if (typeOptions) {
                setOptions(typeOptions);
            }

        }, [])

        useEffect(() => {
            _.forEach(processedImages, (img) => {
                selectedOptions.push(img.imageType.id);
            })
            setSelectedOptions(selectedOptions);
            setImages(processedImages);
            setHasLoaded(true);
        }, [processedImages])

        useEffect(() => {
            setOptions(typeOptions);
            setHasLoaded(true);
        }, [typeOptions])

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var img = {} as PatientImage;
            img.id = 0;
            var image = URL.createObjectURL(file);
            img.data64String = await convertBase64(file) as unknown as string;
            img.data64Url = decodeURI(image);
            img.pathUrl = decodeURI(file.name);
            var opt: DictionaryListItem | undefined = getOptionFromFileName(file.name);
            if (opt) {
                img.imageType = opt;
                selectedOptions.push(opt.id);
            }

            imglist.push(img);
        }

        const handleImageSet = (files) => {
            setIsPreview(false);
            var imglist: ProcessedImages[] = processedImages;
            var promises: any[] = [];
            _.forEach(files, async (file) => {
                const promise = doWork(file, imglist);
                promises.push(promise);
            })
            Promise.all(promises).then(() => {
                setImages(imglist);
                setProcessedImages(imglist);
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (index: number) => {
            var img = images[index];
            if (img.id > 0) {
                images[index]["edited"] = true;
                removeImage(img);
            }
            images.splice(index, 1);
            setProcessedImages(images);
        }

        const handleOptionSelect = (event: any, oldOption: number, index: number, isRemoval: boolean) => {
            var selOpts = selectedOptions;
            _.remove(selOpts, (o) => { return o == oldOption });
            selOpts.push(event.target.value);
            setSelectedOptions(selOpts);
            setKey(Math.floor(Math.random() * 42));

            if (!isRemoval) {
                images[index].imageType = _.find(typeOptions, (o) => { return o.id == event.target.value });
            }
            images[index]["edited"] = true;
            setImages(images);
            setProcessedImages(images);
            return selOpts;
        }

        const handleValidateAndSave = () => {
            const validations = validate();
            setValidationList(validations);
            if (validations.length <= 0) {
                uploadProcessedImages().then(() => {
                    loadReport();
                });

            } else {
                setToggleValidationModel(true);
            }
        }

        const handleSetImages = (images) => {
            setProcessedImages(images);
        }

        return (
            hasLoaded ?
                <Grid container paddingX={2} >

                    <Grid item xs={12} paddingBottom={2}>
                        <Box textAlign="right">
                            <Button variant="contained" onClick={handleValidateAndSave}>Save Changes</Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"} >
                            <FileUploader handleChange={handleImageSet} types={fileTypes}
                                multiple={true}
                                children={
                                    <BoxUploadWrapper>
                                        <Box width={"60vw"} >
                                            <Grid container direction="column" alignItems={'center'} justifyItems={'center'}>
                                                {type == "intraoral" &&
                                                    <Grid item><img src={IntraoralIcon} className="uploadImg" /></Grid>
                                                }
                                                {type == "extraoral" &&
                                                    <Grid item><img src={ExtraoralIcon} className="uploadImg" /></Grid>
                                                }
                                                {type == "occlusion" &&
                                                    <Grid item><img src={ExtraoralIcon} className="uploadImg" /></Grid>
                                                }
                                                {type == "multi" &&
                                                    <Grid item><IconButton><CollectionsIcon /></IconButton></Grid>
                                                }
                                                <Typography>Drag & Drop or</Typography>
                                                <Typography>Click to Select Image(s)</Typography>
                                            </Grid >
                                        </Box>
                                    </BoxUploadWrapper>
                                } name="file" />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} paddingTop={2}>
                        <Grid container>
                            {
                                _.map(processedImages, (img, i) => {
                                    return <Grid xs={4} item paddingBottom={10}>
                                        <UploadRotateCropper
                                            key={key + i}
                                            index={key + i}
                                            cropperIndex={i}
                                            parentCallback={parentCallback}
                                            handleRemove={() => { handleRemove(i) }}
                                            handleOptionSelect={handleOptionSelect}
                                            typeOptions={options}
                                            selectedOptions={selectedOptions}
                                            imageOption={(img && img.imageType) ? img.imageType.id : -1}
                                            images={processedImages}
                                            vivosId={patient.vivosId}
                                            container={process.env.REACT_APP_AIREPORTS_CONTAINER as string}
                                            containerId={submission.id}
                                            setImages={handleSetImages}
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            hasLoaded &&
                            <Box className='BoxDialog'>
                                <Dialog
                                    open={toggleValidationModel}
                                    onClose={() => { }}
                                    aria-labelledby="form-dialog-title"
                                >
                                    <DialogTitle id="form-dialog-title">
                                        <Grid display="flex" alignItems="center">
                                            <Grid flexGrow={1} >Missing Required Fields</Grid>
                                            <Grid>
                                                {<IconButton className="closeButton" onClick={() => { setToggleValidationModel(false) }}>
                                                    <CloseIcon />
                                                </IconButton>}
                                            </Grid>
                                        </Grid>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid>
                                            <Grid>Please correct the following:</Grid>
                                            <Grid>{_.map(validationList, (vl) => {
                                                return <Grid>{vl}</Grid>
                                            })}</Grid>
                                        </Grid>
                                        <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setToggleValidationModel(false) }}>OK</Button></Grid>
                                    </DialogContent>
                                </Dialog>
                            </Box>
                        }
                    </Grid>
                </Grid > : null
        )
    }

export default observer(UploadProcessedImageDragDrop);