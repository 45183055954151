import * as React from 'react';
import _ from "lodash";
import { observer } from "mobx-react";
import { FormControl, MenuItem, SelectChangeEvent, FormHelperText, Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { isMinor, getAgeDisplayString } from '../../api/getAge';
import { useMainStoreContext } from '../../stores/MainStore';
import CssTextField from "../CssTextField";
import { stateLabelValues } from "../../entities/Enums"
import Item from "../Item";
import LegalGuardian from "../LegalGuardian";
import LegalGuardianData from "../../entities/LegalGuardian";
import CssAutoComplete from '../CssAutoComplete';
import moment from 'moment';

const LGDEFAULTVALUEPARENT = 18;
const defaultLGValues = {
  firstName: "",
  lastName: "",
  relationship: LGDEFAULTVALUEPARENT,
  emailAddress: "",
  primaryPhone: "",
  altPhone: ""
}

interface AutoCompleteOptionType {
  inputValue?: string;
  name: string;
}

const QuickAddPersonalInfo = () => {
  const { firstName, lastName, dateOfBirth, genderId, ethnicityId, isProspect, address1, address2, city, state, postalCode,
    emailAddress, primaryPhone, altPhone, legalGuardians, error,
    setFirstName, setLastName, setDateOfBirth, setGenderId, setEthnicityId, setIsProspect, setAddress1, setAddress2,
    helperFirstNameText, helperLastText, helperGenderText, helperDOBText, helperEthnicityText, helperAddress1Text, helperAddress2Text,
    helperCityText, helperStateText, helperPostalCodeText, helperEmailText, helperPrimaryPhoneText, helperAltPhoneText,
    setCity, setState, setPostalCode, setEmailAddress, setPrimaryPhone, setAltPhone, setLegalGuardians,
    setError } = useMainStoreContext().createPatientStore;

  const { areasOfConcern, areasOfConcernNotes, helperAOCText, helperAOCNoteText, areasOfConcernChange,
    optionChecked, setAreasOfConcern, setAreasOfConcernNotes } = useMainStoreContext().treatmentPlanStore;
  const { addGuardian, getGuardians, removeGuardian, guardiansReset } = useMainStoreContext().createLegalGuardianStore;

  const filter = createFilterOptions<AutoCompleteOptionType>();
  const [otherConcernText, setOtherConcernText] = React.useState('');
  const [errorLast, setErrorLast] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);
  const LEGAL_ADULT_AGE: number = 18;
  const MAX_LEGAL_GUARDIAN: number = 3;
  const CONCERN_SLEEP: number = 1;
  const CONCERN_FUNCTION: number = 2;
  const CONCERN_PAIN: number = 4;
  const CONCERN_COSMETICS: number = 8;
  const CONCERN_OTHER: number = 16;
  const [constructorHasRun, setConstructorHasRun] = React.useState(false);
  const [legalGuardianElems, setLegalGuardiansElems] = React.useState([]);

  const [isAdultValidation, setIsAdultValidation] = React.useState(false);

  React.useEffect(() => {
    constructor();
    if (moment(dateOfBirth).isSame(new Date(), "day")) {
      setDateOptions(dateOfBirth);
    }
  }, [])

  const constructor = () => {
    if (constructorHasRun) { return; }

    setConstructorHasRun(true);
  }

  const stateMap = (): any => {
    var states = _.map(stateLabelValues, (s, i) => {
      return { id: s.value, label: s.label }
      //return <MenuItem key={i} value={s.value}>{s.label}</MenuItem>
    });
    return states;
  }

  const setDateOptions = (newValue: any) => {
    if(isMinor(newValue))
    {
      setIsAdultValidation(false);
      setCity("");
      setState("");
      setPostalCode(""); 
      setEmailAddress("")
      setPrimaryPhone("");
      setAltPhone("");
    }
    else
    {
      setIsAdultValidation(true);
    }
    setDateOfBirth(newValue as Date);
  }

  return (
    <Grid className="">
      <form autoComplete='new-password'>
        <input autoComplete="false" name="hidden" type="text" className="hidden"></input>
        <Grid paddingTop={2} sx={{ flexGrow: 1 }}>
          <Grid className="formSectionLabel">Patient Information</Grid>
          <Grid container xs={12} spacing={0} direction="row" marginBottom={"25px"} paddingLeft={4} justifyContent="flex-start" className="formBasics">
            <Grid item xs={4}>
              <FormControl sx={{ width: '90%', height: '65px' }} error={error}>
                <Item>
                  <Grid container direction={"row"} className="formLabel" ><Grid>First Name</Grid><Grid sx={{ color: "red" }} >*</Grid></Grid>
                  <CssTextField
                    variant="outlined"
                    className="TextBox"
                    autoComplete='new-password'
                    size="small"
                    placeholder=""
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(event.target.value);
                    }}
                    value={firstName}
                  />
                </Item>
                <FormHelperText>{helperFirstNameText}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: '90%', height: '65px' }} error={error} >
                <Item>
                  <Grid container direction={"row"} className="formLabel" ><Grid>Last Name</Grid><Grid sx={{ color: "red" }} >*</Grid></Grid>
                  <CssTextField
                    InputLabelProps={{ shrink: true }}
                    className="TextBox" autoComplete='new-password'
                    id="outlined-basic"
                    size="small"
                    required={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(event.target.value);
                    }}
                    value={lastName}
                  />
                </Item>
                <FormHelperText>{helperLastText}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: '90%', height: '65px' }} error={error}>
                <Item>
                  <Grid container direction={"row"} className="formLabel" ><Grid>Gender</Grid><Grid sx={{ color: "red" }}>*</Grid></Grid>
                  <RadioGroup
                    row
                    aria-labelledby="gender-group-label"
                    defaultValue={22}
                    name="radio-buttons-gender"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setGenderId(parseInt(event.target.value));
                    }}
                    value={genderId}  >
                    <Grid container direction="row">
                      <Grid item><FormControlLabel font-size='5px' value={22} control={<Radio />} label="Female" /></Grid>
                      <Grid item><FormControlLabel font-size='5px' value={23} control={<Radio />} label="Male" /></Grid>
                      <Grid item><FormControlLabel font-size='5px' value={57} control={<Radio />} label="Other" /></Grid>
                    </Grid>
                  </RadioGroup>
                </Item>
                <FormHelperText>{helperGenderText}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid>
            <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
              <Grid item md={4}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container direction={"row"} className="formLabel" ><Grid>Date Of Birth</Grid><Grid sx={{ color: "red" }}>*</Grid></Grid>
                      <DatePicker
                        className="DateBox"
                        openTo="day"
                        disableFuture={true}
                        views={['year', 'month', 'day']}
                        value={dateOfBirth}
                        onChange={(newValue) => {
                          setDateOptions(newValue);
                        }}

                        renderInput={(params) => <CssTextField {...params} sx={{
                          '& input': {
                            backgroundColor: 'white',
                            color: '#050505',
                            fontFamily: 'Proxima Nova',
                            fontSize: '16px'
                          }
                        }} className="TextBox" size="small" />}
                      />
                    </LocalizationProvider>
                  </Item>
                  <FormHelperText>{helperDOBText}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <Grid className="formLabel" id="age">Age</Grid>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <CssTextField
                      className="TextBox"
                      variant="outlined" autoComplete='new-password'
                      disabled
                      id="outlined-basic"
                      value={getAgeDisplayString(dateOfBirth)}
                      size="small" />
                  </Item>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>Ethnicity</Grid><Grid sx={{ color: "red" }}>*</Grid></Grid>
                    <CssAutoComplete
                      id="patientadd.cbo.ethnicityid"
                      autoComplete={false}
                      freeSolo
                      aria-autocomplete='none'
                      className="TextBox"
                      placeholder='Select an Ethnicity...'
                      options={[
                        { id: 24, label: "American Indian or Alaska Native" },
                        { id: 25, label: "Asian" },
                        { id: 26, label: "Black or African America" },
                        { id: 27, label: "Caucasian (European, Middle Eastern, or North African Ancestry)" },
                        { id: 28, label: "Hispanic or Latino" },
                        { id: 29, label: "Native Hawaiian or Other Pacific Islander" },
                        { id: 30, label: "Prefer Not To Say" },
                      ]}
                      sx={{ width: 300, border: "0px" }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      onChange={(event, value: unknown) => {
                        var val = value as any;
                        setEthnicityId(val.id);
                      }}
                      renderInput={(params) => <CssTextField {...params}
                        id="patientadd.txt.ethnicityid"
                        inputProps={{
                          ...params.inputProps,
                          "autoComplete": 'new-password'
                        }}
                        placeholder="Select an Ethnicity..."
                        autoComplete='new-password'
                        variant="standard"
                        sx={{
                          border: 'none',
                          padding: "4px 14px"
                        }}
                      />}
                      onKeyDown={(event) => {
                        if (event.key === ' ') {
                          event.stopPropagation();
                        }
                      }}
                    />
                  </Item>
                  <FormHelperText>{helperEthnicityText}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
              <Grid item xs={6}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>Street Address</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                    <CssTextField
                      variant="outlined"
                      className="TextBox" autoComplete='new-password'
                      size="small"
                      placeholder=""
                      required={true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAddress1(event.target.value);
                      }}
                      value={address1}
                    />
                  </Item>
                  <FormHelperText>{helperAddress1Text}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ width: '103%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>Address 2</Grid></Grid>
                    <CssTextField
                      variant="outlined"
                      className="TextBox" autoComplete='new-password'
                      size="small"
                      placeholder=""
                      required={true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAddress2(event.target.value);
                      }}
                      value={address2}
                    />
                  </Item>
                  <FormHelperText>{helperAddress2Text}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
              <Grid item xs={6}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>City</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                    <CssTextField
                      variant="outlined"
                      className="TextBox" autoComplete='new-password'
                      size="small"
                      placeholder=""
                      required={true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCity(event.target.value);
                      }}
                      value={city}
                    />
                  </Item>
                  <FormHelperText>{helperCityText}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Grid container xs={12} direction="row">
                  <Grid xs={6}>
                    <FormControl sx={{ width: '95%' }} error={error}>
                      <Item>
                        <Grid container direction={"row"} className="formLabel" ><Grid>State</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                        <CssAutoComplete
                          id={"patientadd.cbo.state"}
                          freeSolo
                          className="TextBox"
                          placeholder='Select an State...'
                          options={stateMap()}
                          sx={{ width: 300, border: "0px" }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          onChange={(event, value: unknown) => {
                            var val = value as any;
                            setState(val.id);
                          }}
                          renderInput={(params) => <CssTextField {...params}
                            inputProps={{
                              ...params.inputProps,
                              "autoComplete": 'new-password'
                            }}
                            placeholder="Select an State..."
                            autoComplete='new-password'
                            variant="standard"
                            sx={{
                              border: 'none',
                              padding: "4px 14px"
                            }}
                          />}
                          onKeyDown={(event) => {
                            if (event.key === ' ') {
                              event.stopPropagation();
                            }
                          }}
                        />
                      </Item>
                      <FormHelperText>{helperStateText}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ width: '95%' }} error={error}>
                      <Item>
                        <Grid container direction={"row"} className="formLabel" ><Grid>Postal Code</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                        <CssTextField
                          variant="outlined"
                          className="TextBox" autoComplete='new-password'
                          size="small"
                          placeholder=""
                          required={true}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPostalCode(event.target.value);
                          }}
                          value={postalCode}
                        />
                      </Item>
                      <FormHelperText>{helperPostalCodeText}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
              <Grid item md={4}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>E-mail</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                    <CssTextField
                      variant="outlined"
                      className="TextBox" autoComplete='new-password'
                      size="small"
                      placeholder=""
                      type="email"
                      required={true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmailAddress(event.target.value);
                      }}
                      value={emailAddress}
                    />
                  </Item>
                  <FormHelperText>{helperEmailText}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>Primary Phone</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                    <CssTextField
                      variant="outlined"
                      className="TextBox" autoComplete='new-password'
                      size="small"
                      placeholder=""
                      required={true}
                      type="tel"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPrimaryPhone(event.target.value);
                      }}
                      value={primaryPhone}
                    />
                  </Item>
                  <FormHelperText>{helperPrimaryPhoneText}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: '90%' }} error={error}>
                  <Item>
                    <Grid container direction={"row"} className="formLabel" ><Grid>Alternate Phone</Grid>{(!isMinor(dateOfBirth))}<Grid sx={{ color: "red" }} style={{visibility: isAdultValidation ? 'visible' : 'hidden' }}>*</Grid></Grid>
                    <CssTextField
                      variant="outlined"
                      className="TextBox" autoComplete='new-password'
                      sx={{ width: "50px" }}
                      size="small"
                      placeholder=""
                      type="tel"
                      required={true}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAltPhone(event.target.value);
                      }}
                      value={altPhone}
                    />
                  </Item>
                  <FormHelperText>{helperAltPhoneText}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form >
    </Grid>
  );
};
export default observer(QuickAddPersonalInfo);


