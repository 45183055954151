interface DictionaryListItemData {
    id: number, 
	name: string,
	listItemKey: string, 
    value: string,
    dictionaryListId: number,
	isActive: boolean
}

interface DictionaryListItem extends DictionaryListItemData {}

class DictionaryListItem {
    constructor({id,name,listItemKey,isActive,value, dictionaryListId}: DictionaryListItemData) {
            this.id = id;
            this.name = name;
            this.listItemKey = listItemKey;
            this.value = value;
            this.isActive = isActive;
            this.dictionaryListId = dictionaryListId;
    }

    toObject(): DictionaryListItemData {
        return {...this};
    }
}
export default DictionaryListItem;